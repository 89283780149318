export class Country {
    public id;
    public country;

    constructor({ id, country }: { id: number; country: string }) {
        this.id = id;
        this.country = country;
    }

    private toObject() {
        return {
            id: this.id,
            country: this.country,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): Country {
        if (json == null) {
            throw new Error(`Error parsing Country!`);
        }

        return new Country({
            id: json["id"],
            country: json["country"],
        });
    }
}
