export class CyclesReport {
    public orderId;
    public databoxName;
    public databoxSerialNumber;
    public databoxStatus;
    public shipmentDateDelivery;
    public shipmentDateReturn;
    public trackingNumberDelivery;
    public trackingNumberReturn;
    public outboundTicket;
    public smartHandsTicketDelivery;
    public inboundTicket;
    public smartHandsTicketIngest;
    public smartHandsTicketPostIngest;
    public ingestLocation;
    public destination;
    public requestor;
    public orderCreated;
    public uploadCompleted;

    constructor({
        orderId,
        databoxName,
        databoxSerialNumber,
        databoxStatus,
        shipmentDateDelivery,
        shipmentDateReturn,
        trackingNumberDelivery,
        trackingNumberReturn,
        outboundTicket,
        smartHandsTicketDelivery,
        inboundTicket,
        smartHandsTicketIngest,
        smartHandsTicketPostIngest,
        ingestLocation,
        destination,
        requestor,
        orderCreated,
        uploadCompleted,
    }: {
        orderId: number;
        databoxName: string;
        databoxSerialNumber: string;
        databoxStatus: string;
        shipmentDateDelivery: string;
        shipmentDateReturn: string;
        trackingNumberDelivery: string;
        trackingNumberReturn: string;
        outboundTicket: string;
        smartHandsTicketDelivery: string;
        inboundTicket: string;
        smartHandsTicketIngest: string;
        smartHandsTicketPostIngest: string;
        ingestLocation: string;
        destination: string;
        requestor: string;
        orderCreated: string;
        uploadCompleted: string;
    }) {
        this.orderId = orderId;
        this.databoxName = databoxName;
        this.databoxSerialNumber = databoxSerialNumber;
        this.databoxStatus = databoxStatus;
        this.shipmentDateDelivery = shipmentDateDelivery;
        this.shipmentDateReturn = shipmentDateReturn;
        this.trackingNumberDelivery = trackingNumberDelivery;
        this.trackingNumberReturn = trackingNumberReturn;
        this.outboundTicket = outboundTicket;
        this.smartHandsTicketDelivery = smartHandsTicketDelivery;
        this.inboundTicket = inboundTicket;
        this.smartHandsTicketIngest = smartHandsTicketIngest;
        this.smartHandsTicketPostIngest = smartHandsTicketPostIngest;
        this.ingestLocation = ingestLocation;
        this.destination = destination;
        this.requestor = requestor;
        this.orderCreated = orderCreated;
        this.uploadCompleted = uploadCompleted;
    }

    private toObject() {
        return {
            orderId: this.orderId,
            databoxName: this.databoxName,
            databoxSerialNumber: this.databoxSerialNumber,
            databoxStatus: this.databoxStatus,
            shipmentDateDelivery: this.shipmentDateDelivery,
            shipmentDateReturn: this.shipmentDateReturn,
            trackingNumberDelivery: this.trackingNumberDelivery,
            trackingNumberReturn: this.trackingNumberReturn,
            outboundTicket: this.outboundTicket,
            smartHandsTicketDelivery: this.smartHandsTicketDelivery,
            inboundTicket: this.inboundTicket,
            smartHandsTicketIngest: this.smartHandsTicketIngest,
            smartHandsTicketPostIngest: this.smartHandsTicketPostIngest,
            ingestLocation: this.ingestLocation,
            destination: this.destination,
            requestor: this.requestor,
            orderCreated: this.orderCreated,
            uploadCompleted: this.uploadCompleted,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): CyclesReport {
        if (json == null) {
            throw new Error("Something went wrong during the Cycles Report creation!");
        }

        return new CyclesReport({
            orderId: json["orderId"],
            databoxName: json["databoxName"],
            databoxSerialNumber: json["databoxSerialNumber"],
            databoxStatus: json["databoxStatus"],
            shipmentDateDelivery: json["shipmentDateDelivery"],
            shipmentDateReturn: json["shipmentDateReturn"],
            trackingNumberDelivery: json["trackingNumberDelivery"],
            trackingNumberReturn: json["trackingNumberReturn"],
            outboundTicket: json["outboundTicket"],
            smartHandsTicketDelivery: json["smartHandsTicketDelivery"],
            inboundTicket: json["inboundTicket"],
            smartHandsTicketIngest: json["smartHandsTicketIngest"],
            smartHandsTicketPostIngest: json["smartHandsTicketPostIngest"],
            ingestLocation: json["ingestLocation"],
            destination: json["destination"],
            requestor: json["requestor"],
            orderCreated: json["orderCreated"],
            uploadCompleted: json["uploadCompleted"],
        });
    }
}
