import { IngestStation } from "./ingestStation";
import { MediaState } from "./mediaState";
import { Tracker } from "./tracker";

export class Databox {
    private _id;
    public name;
    public serialNumber;
    public owner;
    public storageCapacity;
    public mediaStateType;
    public ingestStation;
    public tracker;
    public purchaseOrderNumber;
    public purchaseOrderDate;
    public opxNumber;
    public serviceContractId;
    public serviceStartDate;
    public serviceEndDate;

    // TODO: Obsolete?
    public databoxPortTypes;

    constructor({
        id,
        name,
        serialNumber,
        owner,
        storageCapacity,
        mediaStateType,
        ingestStation,
        tracker,
        purchaseOrderNumber,
        purchaseOrderDate,
        opxNumber,
        serviceContractId,
        serviceStartDate,
        serviceEndDate,
        databoxPortTypes,
    }: {
        id: number;
        name: string;
        serialNumber: string;
        owner: string;
        storageCapacity: number;
        mediaStateType: MediaState;
        ingestStation: IngestStation | null;
        tracker?: Tracker | null;
        purchaseOrderNumber: string;
        purchaseOrderDate: string;
        opxNumber: string;
        serviceContractId: string;
        serviceStartDate: string;
        serviceEndDate: string;
        databoxPortTypes?: string;
    }) {
        this._id = id;
        this.name = name;
        this.serialNumber = serialNumber;
        this.storageCapacity = storageCapacity;
        this.owner = owner;
        this.mediaStateType = mediaStateType;
        this.ingestStation = ingestStation;
        this.tracker = tracker;
        this.purchaseOrderNumber = purchaseOrderNumber;
        this.purchaseOrderDate = purchaseOrderDate;
        this.opxNumber = opxNumber;
        this.serviceContractId = serviceContractId;
        this.serviceStartDate = serviceStartDate;
        this.serviceEndDate = serviceEndDate;
        this.databoxPortTypes = databoxPortTypes;
    }

    public get id() {
        return this._id;
    }

    public get storageCapacityDisplayName() {
        return this.storageCapacity + " TB";
    }

    private toObject() {
        return {
            id: this.id,
            name: this.name,
            serialNumber: this.serialNumber,
            owner: this.owner,
            storageCapacity: this.storageCapacity,
            mediaStateType: this.mediaStateType,
            ingestStation: this.ingestStation,
            tracker: this.tracker,
            purchaseOrderNumber: this.purchaseOrderNumber,
            purchaseOrderDate: this.purchaseOrderDate,
            opxNumber: this.opxNumber,
            serviceContractId: this.serviceContractId,
            serviceStartDate: this.serviceStartDate,
            serviceEndDate: this.serviceEndDate,
            databoxPortTypes: this.databoxPortTypes,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): Databox {
        if (json == null) {
            throw new Error(`Error parsing Databox!`);
        }

        return new Databox({
            id: json["id"],
            name: json["name"],
            serialNumber: json["serialNumber"],
            owner: json["owner"],
            storageCapacity: json["storageCapacity"],
            mediaStateType: MediaState.fromSerialized(json["mediaStateType"]),
            ingestStation: IngestStation.fromSerialized(json["ingestStation"]),
            tracker: Tracker.fromSerialized(json["tracker"]),
            purchaseOrderNumber: json["purchaseOrderNumber"],
            purchaseOrderDate: json["purchaseOrderDate"],
            opxNumber: json["opxNumber"],
            serviceContractId: json["serviceContractId"],
            serviceStartDate: json["serviceStartDate"],
            serviceEndDate: json["serviceEndDate"],
            databoxPortTypes: json["databoxPortTypes"],
        });
    }
}
