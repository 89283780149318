import React, { useContext, useEffect, useState } from "react";
import {
    GroupuiButton,
    GroupuiIcon,
    GroupuiLoadingSpinner,
    GroupuiTooltip,
} from "@group-ui/group-ui-react";

import GenericTable, { columnDefinition } from "../generics/GenericTable";
import useTitle from "../../hooks/useTitle";
import GenericHeadline from "../generics/GenericHeadline";
import { useNavigate } from "react-router-dom";
import { Databox } from "../../models/databox";
import { ApiService } from "../../services/apiService";
import GenericTag from "../generics/GenericTag";
import AddDataboxModal from "./modals/AddDataboxModal";
import { RightsWrapper } from "../container/RightsWrapper";
import UserGroupContext from "../context/UserGroupContext";
import ErrorContext from "../context/ErrorContext";
import { handleError } from "../context/ErrorContextUtils";
import GenericMoreButton from "../generics/GenericMoreButton";
import { RightsManagement } from "../../config/rightsManagement";
import DownloadReport from "../report/databoxesStatusReport/DownloadReport";
import { DataboxesStatusReport } from "../../models/reports/databoxesStatusReport";
import { isInUserGroup } from "../../utils/RightsUtils";
import { GraphApiService, RequestType } from "../../services/graphApi/graphApiService";
import { GraphApiEndpoints } from "../../constants/graphApiEndpoints";
import { parseFullDateWithoutSeconds, parseDateOnly } from "../../utils/DateUtil";

const styles = {
    magnifyingGlass: {
        cursor: "pointer",
        color: "var(--groupui-sys-color-action-basic-default)",
    },
};

const columns: columnDefinition[] = [
    {
        key: "name",
        header: "Name",
        width: "auto",
    },
    {
        key: "serialNumber",
        header: "Serial Number",
        width: "auto",
    },
    {
        key: "storageCapacity",
        header: "Storage Capacity",
        width: "auto",
    },
    {
        key: "connectorType",
        header: "Connector Type",
        width: "auto",
    },
    {
        key: "mediaState",
        header: "Current State",
        width: "auto",
    },
    {
        key: "ingestStation",
        header: "Ingest Station",
        width: "auto",
    },
    {
        key: "owner",
        header: "Owner",
        width: "auto",
    },
    {
        key: "button",
        width: "4rem",
    },
];

const searchColumns = [
    { column: "name", displayName: "Name" },
    { column: "serialNumber", displayName: "Serial Number" },
];

function Databoxes() {
    useTitle("Databoxes");
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [databoxData, setDataboxData] = useState<Databox[]>();
    const [dropDownState, setDropDownState] = useState(false);
    const [modal, setModal] = useState(false);
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const { userGroups } = useContext(UserGroupContext);
    const [databoxesStatusReportData, setDataboxesStatusReportData] = useState<any[] | undefined>(
        []
    );

    useEffect(() => {
        getAllDataboxData();
        getAllDataboxStatus();
    }, []);

    const allDataboxesData =
        databoxData?.map((databox: Databox) => {
            return {
                id: databox.id,
                name: databox.name,
                serialNumber: databox.serialNumber,
                storageCapacity: databox.storageCapacityDisplayName,
                connectorType: databox.databoxPortTypes ?? "",
                mediaState: (
                    <GenericTag
                        text={databox.mediaStateType.mediaStateType}
                        variant="primary"
                        mediaStateTarget="databox"
                    />
                ),
                ingestStation: databox.ingestStation?.name ?? "",
                owner: databox.owner,
                button: (
                    <GroupuiTooltip position="left">
                        <GroupuiIcon
                            name="search-plus-32"
                            style={styles.magnifyingGlass}
                            onClick={() => navigate(`/databox-pool/${databox.id}`)}
                        />
                        <div slot="tooltip">Show Details</div>
                    </GroupuiTooltip>
                ),
            };
        }) ?? [];

    const getAllDataboxData = () => {
        ApiService.getAllDataboxes()
            .then((response) => {
                setDataboxData(response);
                setIsLoading(false);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
                setIsLoading(false);
            });
    };

    const getAllDataboxStatus = async () => {
        const databoxes: DataboxesStatusReport[] | void | null = await getAllDataboxStatusData();

        if (!databoxes) return;

        const userIdNameMapping = new Map<string, string>();
        await Promise.all(
            databoxes.map(async (databoxesStatus: DataboxesStatusReport) => {
                let result = undefined;
                if (databoxesStatus.requestor != null) {
                    if (!userIdNameMapping.has(databoxesStatus.requestor)) {
                        result = await GraphApiService.callMsGraph(
                            GraphApiEndpoints.userById(databoxesStatus.requestor),
                            RequestType.GET
                        ).catch((error) => {
                            handleError(error, changeErrorMessage, changeShowError);
                        });
                        userIdNameMapping[databoxesStatus.requestor] = result.displayName;
                    }
                }
            })
        );

        setDataboxesStatusReportData(
            databoxes
                .sort((a: DataboxesStatusReport, b: DataboxesStatusReport) => {
                    return a.databoxId - b.databoxId;
                })
                .map((databoxStatus: DataboxesStatusReport, index) => {
                    return {
                        databoxName: databoxStatus.databoxName,
                        databoxSerialNumber: databoxStatus.databoxSerialNumber,
                        storageCapacity: databoxStatus.storageCapacityDisplayName,
                        databoxOwner: databoxStatus.databoxOwner,
                        orderId: databoxStatus.orderId ? "OR" + databoxStatus.orderId : "",
                        databoxStatus: databoxStatus.databoxStatus,
                        tracker: databoxStatus.tracker,
                        battery: databoxStatus.battery ? databoxStatus.battery + " %" : "",
                        lastTrackerUpdate: parseFullDateWithoutSeconds(
                            databoxStatus.lastTrackerUpdate
                        ),
                        ingestLocation: databoxStatus.ingestLocation,
                        destination: databoxStatus.destination,
                        requestor: userIdNameMapping[databoxStatus.requestor] ?? "",
                        lastComment: databoxStatus.lastComment,
                        internalProject: databoxStatus.internalProject,
                        currentHolder: databoxStatus.currentHolder,
                        purchaseOrderNumber: databoxStatus.purchaseOrderNumber,
                        purchaseOrderDate: parseDateOnly(databoxStatus.purchaseOrderDate),
                        opxNumber: databoxStatus.opxNumber,
                        serviceContractId: databoxStatus.serviceContractId,
                        serviceStartDate: parseDateOnly(databoxStatus.serviceStartDate),
                        serviceEndDate: parseDateOnly(databoxStatus.serviceEndDate),
                        longitude: databoxStatus.longitude,
                        latitude: databoxStatus.latitude,
                    };
                })
        );
    };

    const getAllDataboxStatusData = async () => {
        if (
            isInUserGroup([RightsManagement.ADMINISTRATOR], userGroups) &&
            !isInUserGroup([RightsManagement.SUPPORT], userGroups)
        ) {
            return await ApiService.getDataboxesStatusReportsForCustomer().catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
            });
        }

        return await ApiService.getDataboxesStatusReports().catch((error) => {
            handleError(error, changeErrorMessage, changeShowError);
        });
    };

    const handleModalClose = () => {
        setIsLoading(true);
        setModal(false);
        // const timeout = setTimeout(() => {
        //     getAllDataboxData();
        //     getAllDataboxStatus();
        // }, 3000);
    };

    return (
        <div
            style={{
                padding: "var(--groupui-sys-spacing-1200) var(--groupui-sys-spacing-1400)",
                scrollBehavior: "smooth",
                whiteSpace: "pre-wrap",
            }}
        >
            <GroupuiLoadingSpinner displayed={isLoading} />
            <RightsWrapper
                authorizedUserGroup={RightsManagement.ADD_DATABOX}
                userGroups={userGroups}
            >
                <AddDataboxModal
                    modal={modal}
                    setModal={() => setModal(false)}
                    onModalClose={handleModalClose}
                />
            </RightsWrapper>
            <GenericHeadline
                title="Databox Pool"
                actionButtons={[
                    <GenericMoreButton
                        key={"More-Button-1"}
                        dropDownState={dropDownState}
                        setDropDownState={() => {
                            setDropDownState(!dropDownState);
                        }}
                        options={[
                            <RightsWrapper
                                key={"rights-first"}
                                authorizedUserGroup={RightsManagement.ADD_DATABOX}
                                userGroups={userGroups}
                            >
                                <GroupuiButton
                                    key={"first"}
                                    variant="primary"
                                    icon="add-32"
                                    fullwidth={true}
                                    alignment="left"
                                    onClick={() => {
                                        setModal(true);
                                        setDropDownState(false);
                                    }}
                                >
                                    Add Databox
                                </GroupuiButton>
                            </RightsWrapper>,
                            <DownloadReport
                                key={"databox-report-option-1"}
                                data={databoxesStatusReportData}
                                title={"Databox Status Report"}
                                setDropDownState={setDropDownState}
                            />,
                        ]}
                    />,
                ]}
            />
            <div>
                <GenericTable
                    columns={columns}
                    data={allDataboxesData}
                    header={true}
                    pageSize={20}
                    searchColumns={[
                        { column: "name", displayName: "Databox" },
                        { column: "serialNumber", displayName: "Serial Number" },
                    ]}
                    filterColumns={[
                        {
                            column: "ingestStation",
                            displayName: "Ingest Location",
                            doSort: "asc",
                        },
                        {
                            column: "mediaState",
                            displayName: "Current State",
                            doSort: "predefined",
                            predefinedList: [
                                "Free",
                                "Reserved",
                                "Delivery Preparation",
                                "In Transit (Delivery)",
                                "In Use",
                                "Waiting for Pickup",
                                "Waiting for drop off",
                                "In Transit (Return)",
                                "Preparation for Ingest",
                                "Waiting for Ingest Slot",
                                "Pre-Upload",
                                "Uploading",
                                "Error",
                                "Upload Completed",
                                "Preparing for next use",
                                "Order Completed",
                                "Maintenance",
                                "In Stock",
                                "Decommissioned",
                            ],
                        },
                        {
                            column: "storageCapacity",
                            displayName: "Storage Capacity",
                            doSort: "predefined",
                            predefinedList: ["0 TB", "60 TB", "90 TB", "120 TB"],
                            selectedByDefault: ["60 TB", "90 TB", "120 TB"],
                        },
                    ]}
                    filtersAndSearchWidth={{ filters: "200px", search: "400px" }}
                />
            </div>
        </div>
    );
}

export default Databoxes;
