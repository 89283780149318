import { GroupuiInput } from "@group-ui/group-ui-react";
import React, { useState } from "react";

const styles = {
    title: {
        marginBottom: "var(--groupui-sys-spacing-400)",
        display: "flex",
        flexDirection: "row" as const,
        justifyContent: "space-between",
    },
};

interface GenericInputProps {
    label?: string;
    value?: string;
    description?: string;
    type?:
        | "number"
        | "hidden"
        | "time"
        | "text"
        | "email"
        | "hidden"
        | "month"
        | "password"
        | "tel"
        | "url"
        | undefined;
    pattern?: string;
    required?: boolean;
    disabled?: boolean;
    maxLength?: number;
    severity?: "none" | "danger" | "success" | "warning";
    placeholder?: string;
    typingInfoTemplate?: string;
    onGroupuiChange?: any;
    name?: string;
    readOnly?: boolean;
}

/**
 * A Input-Field-Component, which allows users to type in text and is provided with an optional label and description
 *
 * @param {string} label - sets the label which is displayed above the input field
 * @param {string} [value] - sets the value which is displayed inside the input field
 * @param {string} [description] - sets the description which is displayed below the input field
 * @param {"number" | "time" | "text" | "email" | "hidden" | "month" | "password" | "tel" | "url" | undefined} [type] - specifies the type of input element
 * @param {string} [pattern]  - a pattern which will check for validity
 * @param {boolean} [required] - specifies if the input must be filled out before submitting the form
 * @param {boolean} [disabled] - sets input-field to disabled
 * @param {number} [maxLength] - limits the maximum amount of characters of the input-field
 * @param {"none" | "danger" | "success" | "warning"} [severity] - changes the styling of the input and description fitting the severity
 * @param {boolean} [readOnly] - sets input-field to readonly
 *
 * @returns Input-Field
 *
 */
const GenericInput: React.FC<GenericInputProps> = ({
    label,
    value,
    description,
    type,
    pattern,
    required,
    disabled,
    maxLength = 16,
    severity = "none",
    placeholder = "",
    typingInfoTemplate = "",
    onGroupuiChange,
    name,
    readOnly = false,
}) => {
    return (
        <div style={styles.title}>
            <GroupuiInput
                placeholder={placeholder}
                maxlength={maxLength}
                pattern={pattern}
                severity={severity}
                type={type}
                disabled={disabled}
                value={value}
                required={required}
                typingInfoTemplate={typingInfoTemplate}
                readonly={readOnly}
                // onGroupuiChange={(event) => onGroupuiChange(event.target.value)}
                onGroupuiChange={(event) => onGroupuiChange(event)}
                name={name}
            >
                <span slot="label">{label}</span>
                <span slot="description">{description}</span>
            </GroupuiInput>
        </div>
    );
};

export default GenericInput;
