import { Address } from "./address";

export class DataboxOrderResponse {
    public address;
    public success;

    constructor({ address, success = false }: { address?: Address; success: boolean }) {
        this.address = address;
        this.success = success;
    }

    private toObject() {
        return {
            address: this.address,
            success: this.success,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): DataboxOrderResponse | null {
        if (json == null) {
            return null;
        }

        return new DataboxOrderResponse({
            address: json["address"],
            success: json["success"],
        });
    }
}
