import { Address } from "./address";
import { Databox } from "./databox";
import { MediaState } from "./mediaState";
import { UpsPickup } from "./upsPickup";
import { UpsShipmentLabel } from "./upsShipmentLabel";

export class NonStandardRequest {
    private id;
    public requester;
    public pickupAddress;
    public deliveryAddress;
    public databox;
    public jiraTicketId;
    public type;
    public created;
    public lastUpdated;
    public mediaState;
    public upsShipmentLabel;
    public upsPickup;
    public completed;
    public cancelled;

    constructor({
        id,
        requester,
        pickupAddress,
        deliveryAddress,
        databox,
        jiraTicketId,
        type,
        created,
        lastUpdated,
        mediaState,
        upsShipmentLabel,
        upsPickup,
        completed,
        cancelled,
    }: {
        id?: number;
        requester: string;
        pickupAddress: Address;
        deliveryAddress: Address;
        databox: Databox;
        jiraTicketId: string;
        type: string;
        created: string;
        lastUpdated: string;
        mediaState: MediaState;
        upsShipmentLabel?: UpsShipmentLabel | null;
        upsPickup: UpsPickup | null;
        completed: string;
        cancelled: string;
    }) {
        this.id = id;
        this.requester = requester;
        this.pickupAddress = pickupAddress;
        this.deliveryAddress = deliveryAddress;
        this.databox = databox;
        this.jiraTicketId = jiraTicketId;
        this.type = type;
        this.created = created;
        this.lastUpdated = lastUpdated;
        this.mediaState = mediaState;
        this.upsShipmentLabel = upsShipmentLabel;
        this.upsPickup = upsPickup;
        this.completed = completed;
        this.cancelled = cancelled;
    }

    public get ID() {
        return this.id;
    }

    public getNSRMediaState(): string {
        if (this.completed) return "Request completed";
        if (this.cancelled) return "Request cancelled";
        return this.mediaState.mediaStateType;
    }

    private toObject() {
        return {
            id: this.id,
            requester: this.requester,
            pickupAddress: this.pickupAddress,
            deliveryAddress: this.deliveryAddress,
            databox: this.databox,
            jiraTicketId: this.jiraTicketId,
            type: this.type,
            created: this.created,
            lastUpdated: this.lastUpdated,
            mediaState: this.mediaState,
            upsShipmentLabel: this.upsShipmentLabel,
            upsPickup: this.upsPickup,
            completed: this.completed,
            cancelled: this.cancelled,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): NonStandardRequest {
        if (json == null) {
            throw new Error(`Error parsing Company!`);
        }

        return new NonStandardRequest({
            id: json["id"],
            requester: json["requester"],
            pickupAddress: json["pickupAddress"],
            deliveryAddress: json["deliveryAddress"],
            databox: Databox.fromSerialized(json["databox"]),
            jiraTicketId: json["jiraTicketId"],
            type: json["type"],
            created: json["created"],
            lastUpdated: json["lastUpdated"],
            mediaState: MediaState.fromSerialized(json["mediaState"]),
            upsShipmentLabel: UpsShipmentLabel.fromSerialized(json["upsShipmentLabel"]),
            upsPickup: UpsPickup.fromSerialized(json["upsPickup"]),
            completed: json["completed"],
            cancelled: json["cancelled"],
        });
    }
}
