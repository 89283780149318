import React from "react";
import { Address } from "../../../models/address";

const AddressComparisonTable: React.FC<{
    address: Address;
    suggestedAddress: Address | undefined;
}> = ({ address, suggestedAddress }) => (
    <table cellPadding={"4px"}>
        <tr>
            <td>Street:</td>
            <td
                style={{
                    fontWeight:
                        address.street.toLowerCase() !== suggestedAddress?.street.toLowerCase()
                            ? "bold"
                            : "normal",
                }}
            >
                {suggestedAddress?.street}
            </td>
        </tr>
        <tr>
            <td>ZIP Code:</td>
            <td
                style={{
                    fontWeight:
                        address.zipCode.toLowerCase() !== suggestedAddress?.zipCode.toLowerCase()
                            ? "bold"
                            : "normal",
                }}
            >
                {suggestedAddress?.zipCode}
            </td>
        </tr>
        <tr>
            <td>City:</td>
            <td
                style={{
                    fontWeight:
                        address.city.toLowerCase() !== suggestedAddress?.city.toLowerCase()
                            ? "bold"
                            : "normal",
                }}
            >
                {suggestedAddress?.city}
            </td>
        </tr>
        <tr>
            <td>State:</td>
            <td
                style={{
                    fontWeight:
                        address.state?.toLowerCase() !== suggestedAddress?.state?.toLowerCase()
                            ? "bold"
                            : "normal",
                }}
            >
                {suggestedAddress?.state}
            </td>
        </tr>
        <tr>
            <td>Country:</td>
            <td
                style={{
                    fontWeight:
                        address?.country?.country.toLowerCase() !==
                        suggestedAddress?.country?.country.toLowerCase()
                            ? "bold"
                            : "normal",
                }}
            >
                {suggestedAddress?.country.country}
            </td>
        </tr>
    </table>
);

export default AddressComparisonTable;
