import React, { useState, createContext } from "react";

interface IThemeContext {
    userGroups: string[];
    changeUserGroup?: (user: string[]) => void;
    addUserGroup?: (user: string) => void;
}

const defaultState = {
    userGroups: ["Admin"],
};

const UserGroupContext = createContext<IThemeContext>(defaultState);

interface Props {
    children?: React.ReactNode;
    userGroup: string[];
}

export default UserGroupContext;

/**
 * A UserGroupProvider Component, which allows the usage of the UserGroupContext
 * Best used high up in the hierarchy
 *
 * @param {React.ReactNode} [children]  - child components, for which this components provides the context
 *
 * @returns UserGroupProvider
 *
 */
export const UserGroupProvider: React.FC<Props> = ({ children, userGroup }) => {
    const [userGroups, setUserGroup] = useState<string[]>(userGroup);

    const changeUserGroup = (newUsers: string[]) => {
        setUserGroup(newUsers);
    };

    const addUserGroup = (newUser: string) => {
        setUserGroup((userGroups) => [...userGroups, newUser]);
    };

    return (
        <UserGroupContext.Provider
            value={{
                userGroups,
                changeUserGroup,
                addUserGroup,
            }}
        >
            {children}
        </UserGroupContext.Provider>
    );
};
