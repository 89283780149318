import React, { useContext } from "react";
import { GroupuiBreadcrumb, GroupuiBreadcrumbs } from "@group-ui/group-ui-react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { OrderContext } from "../context/provider/OrderContext";

const styles = {
    contentContainer: {
        padding: "var(--groupui-sys-spacing-1200) var(--groupui-sys-spacing-1400)",
        scrollBehavior: "smooth",
        whiteSpace: "pre-wrap",
    } as React.CSSProperties,
};

/**
 * Returns an IngestJobDetailsWrapper Component, which positions the Breadcrumbs on the detail view of each ingest station
 *
 * @returns IngestJobDetailsWrapper Component
 *
 */
const IngestJobDetailsWrapper = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { currentIngestJob } = useContext(OrderContext);

    return (
        <div style={styles.contentContainer}>
            <GroupuiBreadcrumbs>
                <GroupuiBreadcrumb onClick={() => navigate("/ingest-jobs")}>
                    Ingest Jobs
                </GroupuiBreadcrumb>
                <GroupuiBreadcrumb
                    onClick={() =>
                        navigate(location.pathname.substring(0, location.pathname.lastIndexOf("/")))
                    }
                >
                    {currentIngestJob
                        ? `Ingest Job Details - ${currentIngestJob.displayName}`
                        : `Ingest Job Details`}
                </GroupuiBreadcrumb>
            </GroupuiBreadcrumbs>
            {/* Needed to display the content given by the Nested Route */}
            <Outlet />
        </div>
    );
};

export default IngestJobDetailsWrapper;
