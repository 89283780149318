export class IngestStationNameAndId {
    private _id;
    public name;
    public softDeleted;

    constructor({ id, name, softDeleted }: { id: number; name: string; softDeleted: boolean }) {
        this._id = id;
        this.name = name;
        this.softDeleted = softDeleted;
    }

    public get id() {
        return this._id;
    }

    private toObject() {
        return {
            id: this._id,
            name: this.name,
            softDeleted: this.softDeleted,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): IngestStationNameAndId {
        if (json == null) {
            throw Error(
                `Error parsing Ingest Station Name and id model for Ingest Station ${this.name}`
            );
        }

        return new IngestStationNameAndId({
            id: json["id"],
            name: json["name"],
            softDeleted: json["softDeleted"],
        });
    }
}
