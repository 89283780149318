import React, { useEffect, useState } from "react";
import { Tracker } from "../../models/tracker";
import { Databox } from "../../models/databox";
import { IngestStation } from "../../models/ingestStation";
import GenericHeadline from "../generics/GenericHeadline";
import {
    GroupuiButton,
    GroupuiDivider,
    GroupuiLoadingSpinner,
    GroupuiSelect,
    GroupuiSelectOption,
} from "@group-ui/group-ui-react";
import { databoxOwners } from "../../constants/databoxOwner";
import { storageCapacityValues } from "../../constants/storageCapacity";
import GenericInput from "../generics/GenericInput";
import TrackerListModal from "./modals/TrackerListModal";
import { DataboxEditRequest } from "../../models/databoxEditRequest";
import { ApiService } from "../../services/apiService";
import { useParams } from "react-router-dom";
import Snackbar from "../generics/Snackbar";

const styles = {
    magnifyingGlass: {
        cursor: "pointer",
        color: "var(--groupui-sys-color-action-basic-default)",
    },
    select: {
        marginBottom: "var(--groupui-sys-spacing-400)",
    },
    button: {
        marginTop: "var(--groupui-sys-spacing-400)",
    },
};

interface Props {
    databoxData?: Databox;
    ingestLocations?: IngestStation[] | null;
    handleEditMode: () => void;
    onDataUpdate: () => void;
}

const GeneralInformationEdit: React.FC<Props> = ({
    databoxData,
    ingestLocations,
    handleEditMode,
    onDataUpdate,
}) => {
    const [showSnackbar, setShowSnackBar] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "danger" | "warning">(
        "success"
    );
    const { databoxID } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [showTrackerList, setShowTrackerList] = useState(false);
    const [owner, setOwner] = useState<string | undefined>("");
    const [storageCapacity, setStorageCapacity] = useState<number | undefined>();
    const [ingestLocation, setIngestLocation] = useState<IngestStation | null>();
    const [newSerialNumber, setNewSerialNumber] = useState<string | undefined>("");
    const [newPurchaseOrderNumber, setNewPurchaseOrderNumber] = useState<string | undefined>("");
    const [newOpxNumber, setNewOpxNumber] = useState<string | undefined>("");
    const [newServiceContractId, setNewServiceContractId] = useState<string | undefined>("");
    const [newTracker, setNewTracker] = useState<Tracker | null>();

    const toggleTrackerList = () => {
        setShowTrackerList((prevState) => !prevState);
    };

    const handleTrackerSelect = (tracker: Tracker) => {
        if (tracker) {
            setNewTracker(tracker);
        }
        setShowTrackerList(false);
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        if (
            !owner ||
            !ingestLocation ||
            storageCapacity == undefined ||
            storageCapacity == null ||
            storageCapacity < 0 ||
            !newSerialNumber
        ) {
            console.error("Missing required fields");
            setIsLoading(false);
            return;
        }

        const updatedDataboxRequest = new DataboxEditRequest({
            owner: owner,
            storageCapacity: storageCapacity,
            serialNumber: newSerialNumber,
            purchaseOrderNumber: newPurchaseOrderNumber,
            opxNumber: newOpxNumber,
            serviceContractId: newServiceContractId,
            ingestStationId: ingestLocation.id,
            trackerId: newTracker?.id,
        });

        if (databoxID) {
            ApiService.updateDataboxInformationById(parseInt(databoxID), updatedDataboxRequest)
                .then((response) => {
                    if (response === "success") {
                        setSnackbarMessage(`Databox updated successfully`);
                        setSnackbarSeverity("success");
                        setShowSnackBar(true);
                        setTimeout(() => {
                            setIsLoading(false);
                            handleEditMode();
                            window.location.reload();
                        }, 2000);
                    } else {
                        console.error("Failed to update databox");
                        setSnackbarMessage("Failed to update databox");
                        setSnackbarSeverity("danger");
                        setShowSnackBar(true);
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    setSnackbarMessage("Unknown error!");
                    setSnackbarSeverity("danger");
                    setShowSnackBar(true);
                    setIsLoading(false);
                });
        }
    };

    const handleCancleButton = () => {
        setOwner(databoxData?.owner);
        setIngestLocation(databoxData?.ingestStation);
        setStorageCapacity(databoxData?.storageCapacity);
        setNewSerialNumber(databoxData?.serialNumber);
        setNewPurchaseOrderNumber(databoxData?.purchaseOrderNumber);
        setNewOpxNumber(databoxData?.opxNumber);
        setNewServiceContractId(databoxData?.serviceContractId);
        setNewTracker(databoxData?.tracker);
    };

    useEffect(() => {
        if (databoxData) {
            setOwner(databoxData.owner ?? "");
            setStorageCapacity(databoxData.storageCapacity ?? undefined);
            setNewSerialNumber(databoxData.serialNumber ?? "");
            setNewPurchaseOrderNumber(databoxData.purchaseOrderNumber ?? "");
            setNewOpxNumber(databoxData.opxNumber ?? "");
            setNewServiceContractId(databoxData.serviceContractId ?? "");
            setIngestLocation(databoxData.ingestStation ?? null);
            setNewTracker(databoxData.tracker ?? null);
        }
    }, [databoxData]);

    return (
        <div>
            <GroupuiLoadingSpinner displayed={isLoading} />
            {showSnackbar && (
                <Snackbar
                    severity={snackbarSeverity}
                    message={snackbarMessage}
                    onClose={() => setShowSnackBar(false)}
                />
            )}
            <GenericHeadline
                title={"General Information"}
                titleHeadingType="h4"
                actionButtons={[
                    <GroupuiButton
                        key={"c1"}
                        icon="close-24"
                        variant="tertiary"
                        alignment="left"
                        onClick={() => {
                            handleEditMode();
                            handleCancleButton();
                        }}
                    >
                        Cancel
                    </GroupuiButton>,
                    <GroupuiButton
                        key={"c4"}
                        icon="save-24"
                        alignment="left"
                        disabled={
                            !owner ||
                            !ingestLocation ||
                            storageCapacity === undefined ||
                            !newSerialNumber
                        }
                        onClick={handleSubmit}
                    >
                        Save
                    </GroupuiButton>,
                ]}
            />
            <div style={{ display: "flex", gap: "var(--groupui-sys-spacing-1000)" }}>
                <div style={{ width: "50%" }}>
                    <div style={styles.select}>
                        <GroupuiSelect
                            value={owner ?? ""}
                            required={true}
                            onGroupuiChange={(event) => setOwner(event.target.value)}
                        >
                            {databoxOwners.map((e) => (
                                <GroupuiSelectOption value={e} key={e}>
                                    {e}
                                </GroupuiSelectOption>
                            ))}
                            <span slot="label">Owner</span>
                        </GroupuiSelect>
                    </div>
                    <div style={styles.select}>
                        <GroupuiSelect
                            value={ingestLocation?.name ?? ""}
                            required
                            onGroupuiChange={(event) => {
                                const selectedIngestLocationName = event.target.value;
                                const selectedIngestLocation = ingestLocations?.find(
                                    (location) => location.name === selectedIngestLocationName
                                );
                                setIngestLocation(selectedIngestLocation);
                            }}
                        >
                            {ingestLocations?.map((e) => (
                                <GroupuiSelectOption value={e.name} key={`"ingest-id" + ${e.id}`}>
                                    {e.name}
                                </GroupuiSelectOption>
                            ))}
                            <span slot="label">Ingest Location</span>
                        </GroupuiSelect>
                    </div>
                    <div style={styles.select}>
                        <GroupuiSelect
                            value={
                                storageCapacity !== undefined && storageCapacity !== null
                                    ? `${storageCapacity} TB`
                                    : ""
                            }
                            required
                            onGroupuiChange={(event) => {
                                const selectedStorageCapacity = parseFloat(event.target.value);
                                setStorageCapacity(selectedStorageCapacity);
                            }}
                        >
                            {storageCapacityValues.map((e) => (
                                <GroupuiSelectOption value={`${e} TB`} key={e}>
                                    {`${e} TB`}
                                </GroupuiSelectOption>
                            ))}
                            <span slot="label">Storage Capacity (TB)</span>
                        </GroupuiSelect>
                    </div>
                </div>
                <div style={{ width: "50%" }}>
                    <GenericInput
                        label={"Serial Number"}
                        value={newSerialNumber ?? ""}
                        onGroupuiChange={(event: any) => setNewSerialNumber(event.target.value)}
                        maxLength={50}
                        required
                    />
                </div>
            </div>
            <GroupuiDivider />
            <div>
                <GenericHeadline
                    title="Purchase and Service Information"
                    titleHeadingType="h4"
                    actionButtons={[]}
                />
                <GenericInput
                    label={"Purchase Order Number"}
                    value={newPurchaseOrderNumber ?? ""}
                    onGroupuiChange={(event: any) => setNewPurchaseOrderNumber(event.target.value)}
                    maxLength={50}
                />
                <GenericInput
                    label={"OPX Number"}
                    value={newOpxNumber ?? newOpxNumber}
                    onGroupuiChange={(event: any) => setNewOpxNumber(event.target.value)}
                    maxLength={50}
                />
                <GenericInput
                    label={"Service Contract ID"}
                    value={newServiceContractId ?? ""}
                    onGroupuiChange={(event: any) => setNewServiceContractId(event.target.value)}
                    maxLength={50}
                />
            </div>
            <div>
                <GenericHeadline title="Tracker" titleHeadingType="h4" actionButtons={[]} />
                <GenericInput
                    label={"Tracker Name"}
                    value={newTracker ? newTracker.name : ""}
                    maxLength={50}
                    disabled={true}
                    required
                />
                <GroupuiButton
                    style={styles.button}
                    icon="clipboard-24"
                    onClick={toggleTrackerList}
                >
                    Select Tracker from List
                </GroupuiButton>
                {showTrackerList && (
                    <TrackerListModal
                        showModal={showTrackerList}
                        onSelectTracker={handleTrackerSelect}
                        onModalClose={toggleTrackerList}
                    />
                )}
            </div>
        </div>
    );
};

export default GeneralInformationEdit;
