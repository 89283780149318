import { GroupuiLoadingSpinner } from "@group-ui/group-ui-react";
import React, { useContext } from "react";
import { Contact } from "../../models/contact";
import GenericHeadline from "../generics/GenericHeadline";
import GenericTable, { columnDefinition } from "../generics/GenericTable";
import { IngestStationContext } from "../context/provider/IngestStationContext";

const columns: columnDefinition[] = [
    {
        key: "role",
        header: "Role",
        width: "auto",
    },
    {
        key: "firstName",
        header: "First Name",
        width: "auto",
    },
    {
        key: "lastName",
        header: "Last Name",
        width: "auto",
    },
    {
        key: "company",
        header: "Company",
        width: "auto",
    },
    {
        key: "email",
        header: "E-Mail",
        width: "auto",
    },
    {
        key: "phone",
        header: "Phone",
        width: "auto",
    },
];

const ContactTab = () => {
    const { currentIngestStation } = useContext(IngestStationContext);

    const data =
        currentIngestStation?.ingestStationContacts?.map((contactData: Contact) => {
            return {
                firstName: contactData.firstName,
                lastName: contactData.lastName,
                role: contactData.role?.roleType ?? "",
                company: contactData.company?.company ?? "",
                email: contactData.email,
                phone: contactData.phone,
            };
        }) ?? [];

    return (
        <div>
            <GenericHeadline title="Contacts" titleHeadingType="h4" actionButtons={[]} />
            <div>
                <GenericTable columns={columns} data={data} header={true} />
            </div>
        </div>
    );
};
export default ContactTab;
