import React from "react";
import GenericTable, { columnDefinition } from "../generics/GenericTable";
import GenericHeadline from "../generics/GenericHeadline";
import { IngestJob } from "../../models/ingestJob";
const smartHandsTableColumns: columnDefinition[] = [
    { key: "taskName", header: "Task" },
    { key: "description", header: "Description" },
    { key: "smartHandsTicket", header: "Equinix Smart Hands Ticket" },
    { key: "ticketStatus", header: "Ticket Status", width: "12rem" },
];

const SmartHandsTasksTable: React.FC<{
    ingestJob?: IngestJob | null;
    smartHandsTicket1?: string;
    smartHandsTicket2?: string;
    databoxName: string;
    ingestSlotSerialNumber: string;
    ingestStation?: string;
}> = ({
    ingestJob,
    smartHandsTicket1 = "",
    smartHandsTicket2 = "",
    databoxName,
    ingestSlotSerialNumber,
    ingestStation,
}) => {
    function createTableData() {
        /*
        Map the task types to the eight tasks.
        */

        ingestSlotSerialNumber =
            ingestSlotSerialNumber === "" ? "<No ingest slot assigned>" : ingestSlotSerialNumber;

        const tasks = [
            {
                taskName: "Task1",
                description: `Get databox (${databoxName})`,
                smartHandsTicket: smartHandsTicket1,
                ticketStatus: ingestJob?.smartHandsTicket1Status ?? "",
            },
            {
                taskName: "Task2",
                description: `Remove old shipping labels from the inside and outside of the case (${databoxName})`,
                smartHandsTicket: smartHandsTicket1,
                ticketStatus: ingestJob?.smartHandsTicket1Status ?? "",
            },
            {
                taskName: "Task3",
                description:
                    ingestStation == "SE3"
                        ? `Check databox (check also if the inlay label has been changed) (${databoxName})`
                        : `Ingest databox (${databoxName} in ${ingestSlotSerialNumber}) and turn on the copy station `,
                smartHandsTicket: smartHandsTicket1,
                ticketStatus: ingestJob?.smartHandsTicket1Status ?? "",
            },
            {
                taskName: "Task4",
                description:
                    ingestStation == "SE3"
                        ? `Ingest databox (${databoxName} in ${ingestSlotSerialNumber})`
                        : "Charge tracker",
                smartHandsTicket: smartHandsTicket1,
                ticketStatus: ingestJob?.smartHandsTicket1Status ?? "",
            },
            {
                taskName: "Task5",
                description:
                    ingestStation == "SE3"
                        ? `Charge tracker`
                        : `Remove databox (${databoxName} from ${ingestSlotSerialNumber})`,
                smartHandsTicket: ingestStation == "SE3" ? smartHandsTicket1 : smartHandsTicket2,
                ticketStatus:
                    ingestStation == "SE3"
                        ? ingestJob?.smartHandsTicket1Status ?? ""
                        : ingestJob?.smartHandsTicket2Status ?? "",
            },
            {
                taskName: "Task6",
                description:
                    ingestStation == "SE3"
                        ? `Remove databox (${databoxName} from ${ingestSlotSerialNumber})`
                        : `Store databox for next cycle (${databoxName})`,
                smartHandsTicket: smartHandsTicket2,
                ticketStatus: ingestJob?.smartHandsTicket2Status ?? "",
            },
        ];
        if (ingestStation == "SE3")
            tasks.push({
                taskName: "Task7",
                description: `Remove tracker from charger and inserting in databox (${databoxName})`,
                smartHandsTicket: smartHandsTicket2,
                ticketStatus: ingestJob?.smartHandsTicket2Status ?? "",
            });
        if (ingestStation == "SE3")
            tasks.push({
                taskName: "Task8",
                description: `Store databox for next cycle (${databoxName})`,
                smartHandsTicket: smartHandsTicket2,
                ticketStatus: ingestJob?.smartHandsTicket2Status ?? "",
            });

        return tasks;
    }

    return (
        <div>
            <GenericHeadline title={"Tasks"} titleHeadingType="h4" actionButtons={[]} />
            <GenericTable columns={smartHandsTableColumns} data={createTableData()} header={true} />
        </div>
    );
};

export default SmartHandsTasksTable;
