import React, { useContext, useEffect, useState } from "react";
import {
    GroupuiButton,
    GroupuiDatePicker,
    GroupuiDivider,
    GroupuiInlineNotification,
    GroupuiLoadingSpinner,
    GroupuiModal,
    GroupuiText,
} from "@group-ui/group-ui-react";
import Snackbar from "../../generics/Snackbar";
import GenericHeadline from "../../generics/GenericHeadline";
import { ApiService } from "../../../services/apiService";
import { Country } from "../../../models/country";
import ErrorContext from "../../context/ErrorContext";
import { Order } from "../../../models/order";
import { UpsPickup } from "../../../models/upsPickup";
import { Address } from "../../../models/address";
import { USStatesList } from "../../../enums/USStatesList";
import { Countries } from "../../../enums/Countries";
import { handleError } from "../../context/ErrorContextUtils";
import {
    GenericAddressInformationFields,
    Structure,
} from "../../generics/GenericAddressInformationFields";
import ProposedAddresses from "../../ProposedAddresses";
import { ProposedAddress } from "../../../models/proposedAddress";

interface RequestPickupProps {
    modal: boolean;
    setModal: (value: boolean) => void;
    orders: Order[];
    onModalClose: () => void;
}

const RequestMultiplePickupModal: React.FC<RequestPickupProps> = ({
    modal,
    setModal,
    orders,
    onModalClose,
}) => {
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
    const [severity, setSeverity] = useState<"danger" | "success" | "warning">();
    const [message, setMessage] = useState<string>();
    const [isLoading, setIsLoading] = useState(false);

    const [pickupAddress, setPickupAddress] = useState<Address | undefined>(undefined);
    const [countries, setCountries] = useState<Country[] | null>();
    const [pickupDate, setPickupDate] = useState<string | undefined>();

    useEffect(() => {
        ApiService.getAllCountries()
            .then((response) => {
                setCountries(response);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
            });
    }, []);

    const validate = () => {
        const pickupDay = new Date(pickupDate!);
        const currentDate = new Date();
        if (pickupDay.getDay() === 6 || pickupDay.getDay() === 0) {
            setShowSnackBar(true);
            setSeverity("danger");
            setMessage("Selected Pickup Date is on the weekend!");
            return false;
        }
        if (pickupDay <= currentDate) {
            setShowSnackBar(true);
            setSeverity("danger");
            setMessage("Selected Pickup Date is today or in the past!");
            return false;
        }
        if (pickupDay >= new Date(currentDate.setDate(currentDate.getDate() + 183))) {
            setShowSnackBar(true);
            setSeverity("danger");
            setMessage(
                "Selected Pickup Date is too far in the future. Maximum 183 days from today!"
            );
            return false;
        }
        return true;
    };

    const handleModalClose = () => {
        setModal(false);
        setIsLoading(false); // Ensure spinner is reset
        setPickupDate(undefined);
        setPickupAddress(undefined);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();

        if (pickupDate && pickupAddress?.country && pickupAddress.company && validate()) {
            setIsLoading(true);

            const pickupRequests = orders.map((order) => {
                return ApiService.addUpsPickup(
                    Number(order.id),
                    new UpsPickup({
                        pickupDate: new Date(pickupDate).toISOString(),
                        created: new Date().toISOString(),
                        address: pickupAddress!,
                    })
                );
            });

            Promise.all(pickupRequests)
                .then((responses) => {
                    const failed = responses.some(
                        (response) => response.toLowerCase() === "failed"
                    );

                    if (failed) {
                        setShowSnackBar(true);
                        setSeverity("danger");
                        setMessage("Some pickups couldn’t be scheduled!");
                    } else {
                        setShowSnackBar(true);
                        setSeverity("success");
                        setMessage("Successfully scheduled pickups!");
                    }

                    const timeout = setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                })
                .catch((error) => {
                    console.log("Error in API request:", error);
                    handleError(error, changeErrorMessage, changeShowError);
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
        }
    };

    const checkStateForUSCountries = () => {
        return (
            pickupAddress?.country?.id ===
                countries?.find((country) => country.country === Countries.UNITED_STATES)?.id &&
            (!USStatesList.filter((usState) => usState.toLowerCase()).includes(
                pickupAddress?.state ?? ""
            ) ||
                pickupAddress?.state === "")
        );
    };

    const checkDeliveryInputs = () => {
        return (
            !pickupAddress ||
            pickupAddress.fullName.length > 22 ||
            pickupAddress.fullName.trim() === "" ||
            pickupAddress.street.trim() === "" ||
            pickupAddress.zipCode.trim() === "" ||
            pickupAddress.city.trim() === "" ||
            !pickupAddress.country ||
            !pickupAddress.company ||
            checkStateForUSCountries() ||
            pickupAddress.email.trim() === "" ||
            pickupAddress.phone.trim() === "" ||
            pickupAddress.phone.trim().length < 7
        );
    };

    return (
        <GroupuiModal
            closeButton={true}
            padding="var(--groupui-sys-spacing-600)"
            overflow={"auto"}
            displayed={modal}
            maxWidth="70vw"
            l={12}
            xl={12}
            xxl={7}
            style={{
                justifyContent: "center",
                alignItems: "center",
            }}
            onGroupuiModalClose={handleModalClose}
        >
            <GroupuiLoadingSpinner displayed={isLoading} />
            {showSnackBar && (
                <Snackbar
                    severity={severity ?? "danger"}
                    message={message ?? "Some error occurred!"}
                    onClose={() => {
                        setShowSnackBar(false);
                    }}
                />
            )}
            <GenericHeadline
                title="Request Multiple Pickup for:"
                actionButtons={[]}
                titleHeadingType="h3"
                type="modal"
            />
            <GroupuiText>
                <strong>
                    <i>
                        {orders.map((order, index) => (
                            <React.Fragment key={order.id}>
                                {order.databox.name}
                                {index < orders.length - 1 ? ", " : ""}
                            </React.Fragment>
                        ))}
                    </i>
                </strong>
            </GroupuiText>
            <br />
            <form style={{ gap: "1px" }} onSubmit={handleSubmit}>
                <GroupuiDatePicker
                    style={{ whiteSpace: "normal" }}
                    severity="none"
                    value={pickupDate}
                    required={true}
                    dateFormat="d.m.Y"
                    placeholder="dd.mm.yyyy"
                    minDate={(() => {
                        const date = new Date();
                        date.setDate(date.getDate() + 1);
                        return (
                            date.getDate() +
                            "." +
                            (date.getMonth() + 1).toLocaleString("de-DE", {
                                minimumIntegerDigits: 2,
                            }) +
                            "." +
                            date.getFullYear()
                        );
                    })()}
                    maxDate={(() => {
                        const date = new Date();
                        date.setDate(date.getDate() + 183);
                        return (
                            date.getDate() +
                            "." +
                            (date.getMonth() + 1).toLocaleString("de-DE", {
                                minimumIntegerDigits: 2,
                            }) +
                            "." +
                            date.getFullYear()
                        );
                    })()}
                    disableWeekends={true}
                    onGroupuiChange={(event) => setPickupDate(event.target.value)}
                >
                    <span slot="label">Pickup Date *</span>
                </GroupuiDatePicker>
                <br />
                <GroupuiDivider />
                <br />
                {pickupAddress?.country.country != "" &&
                    orders.filter(
                        (order) => order.address?.country.country !== pickupAddress?.country.country
                    ).length > 0 && (
                        <div>
                            <GroupuiInlineNotification
                                visible={true}
                                hideIcon={false}
                                severity="warning"
                                style={{
                                    // Added because color from the component isn´t working
                                    color: "var(--groupui-sys-color-warning-basic-default)",
                                    backgroundColor:
                                        "var(--groupui-sys-color-warning-weak-default)",
                                    // Added to avoided dropdowns to be hidden
                                    zIndex: "0",
                                }}
                            >
                                <div
                                    slot="headline"
                                    // Added because color from the component isn´t working
                                    style={{ color: "var(--groupui-sys-color-text-normal)" }}
                                >
                                    Return Label
                                </div>
                                <div
                                    slot="content"
                                    // Added because color from the component isn´t working
                                    style={{ color: "var(--groupui-sys-color-text-normal)" }}
                                >
                                    A new return label will be created due to the changed country
                                    for Databox(es):{" "}
                                    {orders
                                        .filter(
                                            (order) =>
                                                order.address?.country.country !==
                                                pickupAddress?.country.country
                                        )
                                        .map((order) => `${order.databox.name} (OR${order.id}); `)}
                                </div>
                            </GroupuiInlineNotification>
                            <br />
                        </div>
                    )}
                <GenericAddressInformationFields
                    disabled={false}
                    address={pickupAddress}
                    setAddress={setPickupAddress}
                    structure={Structure.REQUEST_PICKUP}
                />
                <br />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "var(--groupui-sys-spacing-400)",
                        marginTop: "var(--groupui-sys-spacing-600)",
                    }}
                >
                    <GroupuiButton variant={"secondary"} onClick={handleModalClose}>
                        Cancel
                    </GroupuiButton>
                    <GroupuiButton
                        icon="service-bell-32"
                        disabled={checkDeliveryInputs() || pickupDate === undefined}
                        type={"submit"}
                    >
                        Request
                    </GroupuiButton>
                </div>
            </form>
        </GroupuiModal>
    );
};

export default RequestMultiplePickupModal;
