import { GroupuiLoadingSpinner, GroupuiButton } from "@group-ui/group-ui-react";
import GenericHeadline from "../../generics/GenericHeadline";
import GenericMoreButton from "../../generics/GenericMoreButton";
import GenericTable, { columnDefinition } from "../../generics/GenericTable";
import DownloadReportModal from "../DownloadReportModal";
import React, { useContext, useEffect, useState } from "react";
import { ApiService } from "../../../services/apiService";
import ErrorContext from "../../context/ErrorContext";
import useTitle from "../../../hooks/useTitle";
import { Order } from "../../../models/order";
import { GraphApiEndpoints } from "../../../constants/graphApiEndpoints";
import { GraphApiService, RequestType } from "../../../services/graphApi/graphApiService";
import { parseFullDate, parseFullDateWithoutSeconds } from "../../../utils/DateUtil";
import GenericTag from "../../generics/GenericTag";
import { handleError } from "../../context/ErrorContextUtils";

const columns: columnDefinition[] = [
    {
        key: "orderId",
        header: "Order ID",
        width: "auto",
    },
    {
        key: "databoxName",
        header: "Databox Name",
        width: "auto",
    },
    {
        key: "ingestLocation",
        header: "Ingest Location",
        width: "auto",
    },
    {
        key: "destinationCountry",
        header: "Destination",
        width: "auto",
    },
    {
        key: "currentState",
        header: "Current State",
        width: "auto",
    },
    {
        key: "accessPoint",
        header: "Access Point",
        width: "auto",
    },
    {
        key: "requestor",
        header: "Requestor",
        width: "auto",
    },
    {
        key: "created",
        header: "Created",
        width: "auto",
    },
    {
        key: "updated",
        header: "Updated",
        width: "auto",
    },
];

const ShipmentReport = () => {
    const [title, setTitle] = useState<string>();
    useTitle(title);
    const [isLoading, setIsLoading] = useState(true);
    const [modal, setModal] = useState(false);
    const [dropDownState, setDropDownState] = useState(false);
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);

    const [data, setData] = useState<any>([]);
    const [databoxOrders, setDataboxOrders] = useState<Order[] | null>();

    useEffect(() => {
        setTitle("Shipment Report");
        ApiService.getAllOrdersOverview()
            .then((response) => {
                setDataboxOrders(response);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
            });
    }, []);

    useEffect(() => {
        getDataWithResolvedRequestor().then((result) => {
            if (result != undefined) {
                setData(result);
                setIsLoading(false);
            }
        });
    }, [databoxOrders]);

    const getDataWithResolvedRequestor = async () => {
        let dataEntries;
        if (databoxOrders != undefined) {
            const userIdNameMapping = new Map<string, string>();
            await Promise.all(
                databoxOrders
                    .filter((order, index, orderArray) => {
                        return (
                            orderArray.findIndex(
                                (value) => value.azureUserId === order.azureUserId
                            ) === index
                        );
                    })
                    .map(async (order: Order) => {
                        let result = undefined;
                        if (order.azureUserId != null) {
                            if (!userIdNameMapping.has(order.azureUserId)) {
                                result = await GraphApiService.callMsGraph(
                                    GraphApiEndpoints.userById(order.azureUserId),
                                    RequestType.GET
                                ).catch((error) => {
                                    handleError(error, changeErrorMessage, changeShowError);
                                });
                                userIdNameMapping[order.azureUserId] = result.displayName;
                            }
                        }
                    })
            );
            dataEntries = databoxOrders
                .filter((order) => {
                    return order.databox.owner !== "DXC";
                })
                .sort((a: Order, b: Order) => {
                    return Date.parse(b.created) - Date.parse(a.created);
                })
                .map((order: Order) => {
                    return {
                        orderId: "OR" + order.id,
                        databoxName: order.databox.name,
                        ingestLocation: order.databox.ingestStation?.name,
                        destinationCountry: order.address?.city,
                        currentState: (
                            <GenericTag
                                text={order.currentMediaState}
                                variant="primary"
                                mediaStateTarget="databox"
                            />
                        ),
                        accessPoint: order.packageDropOff ? "Yes" : "",
                        requestor: userIdNameMapping[order.azureUserId] ?? "",
                        created: parseFullDateWithoutSeconds(order.created),
                        updated: parseFullDateWithoutSeconds(order.updated),
                    };
                });
            return dataEntries;
        }
    };

    return (
        <div
            style={{
                padding: "var(--groupui-sys-spacing-1200) var(--groupui-sys-spacing-1400)",
                scrollBehavior: "smooth",
                whiteSpace: "pre-wrap",
            }}
        >
            <GroupuiLoadingSpinner displayed={isLoading} />
            {modal && (
                <DownloadReportModal
                    reportType={title ?? ""}
                    data={data}
                    modal={modal}
                    setModal={(value: boolean) => setModal(value)}
                />
            )}
            <GenericHeadline
                title={title ?? ""}
                actionButtons={[
                    <GenericMoreButton
                        key={"More-Button-1"}
                        dropDownState={dropDownState}
                        setDropDownState={() => {
                            setDropDownState(!dropDownState);
                        }}
                        options={[
                            <GroupuiButton
                                key={"Option-1"}
                                variant="primary"
                                icon="download-32"
                                fullwidth={true}
                                alignment="left"
                                // disabled={true}
                                onClick={() => {
                                    setModal(true);
                                    setDropDownState(false);
                                }}
                            >
                                Download {title}
                            </GroupuiButton>,
                        ]}
                    />,
                ]}
            />
            <div>
                <GenericTable columns={columns} data={data ?? []} header={true} pageSize={20} />
            </div>
        </div>
    );
};

export default ShipmentReport;
