export class PortType {
    private _id;
    public portType;

    constructor({ id, portType }: { id: number; portType: string }) {
        this._id = id;
        this.portType = portType;
    }

    public get id() {
        return this._id;
    }

    private toObject() {
        return {
            id: this._id,
            portType: this.portType,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): PortType | null {
        if (json == null) {
            return null;
        }

        return new PortType({
            id: json["id"],
            portType: json["portType"],
        });
    }
}
