export class SusNameAndId {
    private _id;
    public name;

    constructor({ id, name }: { id: number; name: string }) {
        this._id = id;
        this.name = name;
    }

    public get id() {
        return this._id;
    }

    private toObject() {
        return {
            id: this._id,
            name: this.name,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): SusNameAndId {
        if (json == null) {
            throw Error(`Error parsing SUS Name and id model for Self Upload Station ${this.name}`);
        }

        return new SusNameAndId({
            id: json["id"],
            name: json["name"],
        });
    }
}
