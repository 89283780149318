import { GroupuiButton, GroupuiLoadingSpinner, GroupuiModal } from "@group-ui/group-ui-react";
import React, { useContext, useEffect, useState } from "react";
import GenericHeadline from "../../../generics/GenericHeadline";
import { ApiService } from "../../../../services/apiService";
import GenericInput from "../../../generics/GenericInput";
import Snackbar from "../../../generics/Snackbar";
import ErrorContext from "../../../context/ErrorContext";
import { handleError } from "../../../context/ErrorContextUtils";
import { IngestSlotMonitorData } from "../../../../models/ingestSlotMonitorData";

interface Props {
    modal: boolean;
    setModal: (value: boolean) => void;
    ingestSlotMonitorData: IngestSlotMonitorData;
    mode?: string;
}

const AddDetailsMessageModal: React.FC<Props> = ({
    modal,
    setModal,
    ingestSlotMonitorData,
    mode = "Add",
}) => {
    const [detailsMessage, setDetailsMessage] = useState<string>("");
    const [validityError, setValidityError] = useState<boolean>(false);

    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
    const [severity, setSeverity] = useState<"success" | "danger">("success");
    const [message, setMessage] = useState<string>("");

    const [loading, setLoading] = useState<boolean>(false);

    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);

    useEffect(() => {
        if (detailsMessage.length > 80) {
            setValidityError(true);
            return;
        }
        setValidityError(false);
    }, [detailsMessage]);

    const sendRequest = () => {
        if (ingestSlotMonitorData?.ingestId) {
            setLoading(true);

            ApiService.addDetailsMessageToIngest(
                ingestSlotMonitorData?.ingestId,
                detailsMessage,
                false
            )
                .then((response) => {
                    if (response.startsWith("success")) {
                        setShowSnackbar(true);
                        setMessage("Successfully adjusted the details message!");
                        setTimeout(() => {
                            setLoading(false);
                            setModal(false);
                            window.location.reload();
                        }, 2000);
                    } else {
                        setShowSnackbar(true);
                        setMessage("An error occurred during adjustment of the details message!");
                        setSeverity("danger");

                        setShowSnackbar(true);
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    handleError(error, changeErrorMessage, changeShowError);
                });
        }
    };

    return (
        <GroupuiModal
            displayed={modal}
            onGroupuiModalClose={() => setModal(false)}
            padding="var(--groupui-sys-spacing-1000)"
            margin="200px"
            closeButton={true}
            overflow="visible"
        >
            <GroupuiLoadingSpinner displayed={loading} />
            {showSnackbar && (
                <Snackbar
                    severity={severity}
                    message={message}
                    onClose={() => setShowSnackbar(false)}
                />
            )}
            <GenericHeadline
                title={mode + " Details Message"}
                titleHeadingType="h3"
                actionButtons={[]}
                type="modal"
            />

            <GenericInput
                label="Details Message"
                value={detailsMessage}
                onGroupuiChange={(event: any) => setDetailsMessage(event.target.value)}
                description={
                    validityError
                        ? "A maximum of 80 characters allowed"
                        : "This message will be displayed inside the Details column in the dashboard"
                }
                severity={validityError ? "danger" : "none"}
                maxLength={80}
                typingInfoTemplate={"{characters-used}/{characters-max}"}
            />
            <div
                style={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "var(--groupui-sys-spacing-400)",
                    marginTop: "var(--groupui-sys-spacing-600)",
                }}
            >
                <GroupuiButton variant={"secondary"} onClick={() => setModal(false)}>
                    Cancel
                </GroupuiButton>
                <GroupuiButton
                    icon="check-32"
                    disabled={detailsMessage === "" || validityError}
                    onClick={sendRequest}
                >
                    Confirm
                </GroupuiButton>
            </div>
        </GroupuiModal>
    );
};

export default AddDetailsMessageModal;
