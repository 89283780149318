import { GroupuiModal } from "@group-ui/group-ui-react";
import React, { useEffect, useState, useContext } from "react";
import GenericHeadline from "../generics/GenericHeadline";
import { ApiService } from "../../services/apiService";
import { handleError } from "../context/ErrorContextUtils";
import ErrorContext from "../context/ErrorContext";
import { ReleaseTask } from "../../models/releaseTask";
import {
    GroupuiSelect,
    GroupuiSelectOption,
    GroupuiText,
    GroupuiList,
    GroupuiHeadline,
} from "@group-ui/group-ui-react/dist/components";
import { parseDateOnly } from "../../utils/DateUtil";
interface Version {
    releaseVersion: string;
    releaseDate: string;
    description: string;
}
interface Props {
    showModal: boolean;
    setModal: (value: boolean) => void;
}

const ShowReleaseNotesModal: React.FC<Props> = ({ showModal, setModal }) => {
    const [releaseTaskData, setReleaseTaskData] = useState<ReleaseTask[]>();
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const [sortedUniqueVersions, setSortedUniqueVersions] = useState<Version[]>([]);
    const [selectedVersion, setSelectedVersion] = useState<Version>();
    const [filteredTasks, setFilteredTasks] = useState<ReleaseTask[]>([]);
    const [newFeatures, setNewFeatures] = useState<ReleaseTask[]>([]);
    const [improvedFeatures, setImprovedFeatures] = useState<ReleaseTask[]>([]);
    const [fixedIssues, setFixedIssues] = useState<ReleaseTask[]>([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        ApiService.getAllReleaseTasks()
            .then((response) => {
                setReleaseTaskData(response);
                processAndSortVersions(response);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
            });
        return;
    };
    useEffect(() => {
        if (selectedVersion) {
            filterTasksByVersion(selectedVersion);
        } else filterTasksByVersion(sortedUniqueVersions[0]);
    }, [selectedVersion, releaseTaskData]);

    useEffect(() => {
        if (filteredTasks.length > 0) {
            const newFeatures = filteredTasks.filter((task) => task.taskType === "feature");
            const improvedFeatures = filteredTasks.filter(
                (task) => task.taskType === "improvement"
            );
            const fixedIssues = filteredTasks.filter((task) => task.taskType === "bug");

            setNewFeatures(newFeatures);
            setImprovedFeatures(improvedFeatures);
            setFixedIssues(fixedIssues);
        }
    }, [filteredTasks]);

    useEffect(() => {
        if (sortedUniqueVersions.length > 0 && !selectedVersion) {
            setSelectedVersion(sortedUniqueVersions[0]);
        }
    }, [sortedUniqueVersions]);

    const filterTasksByVersion = (version: Version) => {
        const filtered = releaseTaskData?.filter(
            (task) => task.releaseVersion === version.releaseVersion
        );
        setFilteredTasks(filtered ? filtered : []);
    };

    const processAndSortVersions = (tasks: ReleaseTask[]) => {
        const versionsMap = new Map<string, Version>();

        tasks.forEach((task) => {
            if (!versionsMap.has(task.releaseVersion)) {
                versionsMap.set(task.releaseVersion, {
                    releaseVersion: task.releaseVersion,
                    releaseDate: task.releaseDate,
                    description: `CSI Portal  ${task.releaseVersion} - ${parseDateOnly(
                        task.releaseDate
                    )}`,
                });
            }
        });
        const uniqueVersions: Version[] = Array.from(versionsMap.values());

        uniqueVersions.sort(
            (a, b) => new Date(b.releaseDate).getTime() - new Date(a.releaseDate).getTime()
        );

        if (uniqueVersions.length > 0) {
            uniqueVersions[0].description += " (current)";
        }

        setSortedUniqueVersions(uniqueVersions);
    };

    return (
        <GroupuiModal
            displayed={showModal}
            onGroupuiModalClose={() => setModal(false)}
            overflow="auto"
            style={{ justifyContent: "center", alignItems: "center" }}
            closeButton={true}
            margin="center"
            padding="var(--groupui-sys-spacing-800)"
        >
            <GenericHeadline
                title={"Release Notes"}
                titleHeadingType="h4"
                type="modal"
                actionButtons={[]}
            />
            <GroupuiText
                size="body-2"
                weight="light"
                marketing={false}
                style={{
                    color: "var(--groupui-sys-color-action-basic-disabled)",
                    width: "32rem",
                }}
            >
                Version
            </GroupuiText>

            <GroupuiSelect
                value={selectedVersion?.description}
                onGroupuiChange={(e) => {
                    const selectedValue = e.target.value;
                    const selected = sortedUniqueVersions.find(
                        (v) => v.description === selectedValue
                    );
                    setSelectedVersion(selected);
                }}
                severity="none"
                placeholder="Versions"
            >
                {sortedUniqueVersions.map((version, index) => (
                    <GroupuiSelectOption
                        key={`release-select-option-${index}`}
                        value={version.description}
                    >
                        {version.description}
                    </GroupuiSelectOption>
                ))}
            </GroupuiSelect>
            <br />
            <GroupuiText
                size="body-3"
                weight="light"
                marketing={false}
                style={{
                    color: "var(--groupui-sys-color-action-basic-disabled)",
                    width: "32rem",
                }}
            >
                New Features
            </GroupuiText>
            <GroupuiList>
                <ul>
                    {newFeatures.map((task, index) => (
                        <li key={index}>{task.taskTitle}</li>
                    ))}
                </ul>
            </GroupuiList>
            <br />
            <GroupuiText
                size="body-3"
                weight="light"
                marketing={false}
                style={{
                    color: "var(--groupui-sys-color-action-basic-disabled)",
                    width: "32rem",
                }}
            >
                Improved Features
            </GroupuiText>
            <GroupuiList>
                <ul>
                    {improvedFeatures.map((task, index) => (
                        <li key={index}>{task.taskTitle}</li>
                    ))}
                </ul>
            </GroupuiList>
            <br />
            <GroupuiText
                size="body-3"
                weight="light"
                marketing={false}
                style={{
                    color: "var(--groupui-sys-color-action-basic-disabled)",
                    width: "32rem",
                }}
            >
                Fixed Issues
            </GroupuiText>
            <GroupuiList>
                <ul>
                    {fixedIssues.map((task, index) => (
                        <li key={index}>{task.taskTitle}</li>
                    ))}
                </ul>
            </GroupuiList>
            <br />
            <GroupuiText
                size="body-2"
                weight="light"
                marketing={false}
                style={{
                    color: "var(--groupui-sys-color-action-basic-disabled)",
                    width: "32rem",
                }}
            >
                We are constantly striving to improve the portal to provide the best experiance
                possible. We appriciate your feedback and suggestions, and are committed to
                addressing any issues that ariese in a timely manner.
            </GroupuiText>
        </GroupuiModal>
    );
};

export default ShowReleaseNotesModal;
