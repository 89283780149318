import { SmartHandsTaskShipping } from "./smartHandsTaskShipping";

/**
 * A model class to describe a shipmentJobs, which is used to map the received json on an usable object
 */
export class ShipmentJob {
    public id;
    public commentId;
    public jobStatus;
    public smartHandsTicketId;
    public smartHandsTasks;
    public outboundTicket;
    public created;
    public updated;
    public smartHandsTicketCreated;
    public outboundTicketCreated;
    public outboundTicketStatus;

    constructor({
        id,
        commentId,
        jobStatus,
        smartHandsTicketId,
        smartHandsTasks,
        outboundTicket,
        created,
        updated,
        smartHandsTicketCreated,
        outboundTicketCreated,
        outboundTicketStatus,
    }: {
        id: number;
        commentId: number;
        jobStatus: string;
        smartHandsTicketId: string;
        smartHandsTasks: SmartHandsTaskShipping[];
        outboundTicket: string | null;
        created: string | null;
        updated: string | null;
        smartHandsTicketCreated: string | null;
        outboundTicketCreated: string | null;
        outboundTicketStatus: string | null;
    }) {
        this.id = id;
        this.commentId = commentId;
        this.jobStatus = jobStatus;
        this.smartHandsTicketId = smartHandsTicketId;
        this.smartHandsTasks = smartHandsTasks;
        this.outboundTicket = outboundTicket;
        this.created = created;
        this.updated = updated;
        this.smartHandsTicketCreated = smartHandsTicketCreated;
        this.outboundTicketCreated = outboundTicketCreated;
        this.outboundTicketStatus = outboundTicketStatus;
    }

    public get displayName() {
        return `SJ${this.id}`;
    }

    private toObject() {
        return {
            id: this.id,
            commentId: this.commentId,
            jobStatus: this.jobStatus,
            smartHandsTicketId: this.smartHandsTicketId,
            smartHandsTasks: this.smartHandsTasks,
            outboundTicket: this.outboundTicket,
            created: this.created,
            updated: this.updated,
            smartHandsTicketCreated: this.smartHandsTicketCreated,
            outboundTicketCreated: this.outboundTicketCreated,
            outboundTicketStatus: this.outboundTicketStatus,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): ShipmentJob | null {
        if (json == null) {
            return null;
        }

        return new ShipmentJob({
            id: json["id"],
            commentId: json["commentId"],
            jobStatus: json["jobStatus"],
            smartHandsTicketId: json["smartHandsTicketId"],
            smartHandsTasks: json["smartHandsTasks"]?.map((t: any) =>
                SmartHandsTaskShipping.fromSerialized(t)
            ),
            outboundTicket: json["outboundTicket"],
            created: json["created"],
            updated: json["updated"],
            smartHandsTicketCreated: json["smartHandsTicketCreated"],
            outboundTicketCreated: json["outboundTicketCreated"],
            outboundTicketStatus: json["outboundTicketStatus"],
        });
    }
}
