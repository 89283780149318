export class Pause {
    private _id;
    public reason;
    public jiraTicketId;
    public created;

    constructor({
        id,
        reason,
        jiraTicketId,
        created,
    }: {
        id: number;
        reason: string;
        jiraTicketId: string;
        created: string;
    }) {
        this._id = id;
        this.reason = reason;
        this.jiraTicketId = jiraTicketId;
        this.created = created;
    }

    private toObject() {
        return {
            id: this._id,
            reason: this.reason,
            jiraTicketId: this.jiraTicketId,
            created: this.created,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): Pause | null {
        if (json == null) {
            return null;
        }

        return new Pause({
            id: json["id"],
            reason: json["reason"],
            jiraTicketId: json["jiraTicketId"],
            created: json["created"],
        });
    }
}
