import React, { createContext, useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ApiService } from "../../../services/apiService";
import ErrorContext from "../ErrorContext";
import { handleError } from "../ErrorContextUtils";
import { MaintenanceSchedule } from "../../../models/maintenanceSchedule";

interface IMaintenanceScheduleContext {
    isLoading: boolean;
    currentMaintenanceMessage: MaintenanceSchedule | null;
    completeCurrentMaintenanceMessage: () => Promise<void>;
}

const defaultState = {
    isLoading: false,
    currentMaintenanceMessage: null,
    completeCurrentMaintenanceMessage: async () => {
        await Promise<void>;
    },
};

export const MaintenanceScheduleContext = createContext<IMaintenanceScheduleContext>(defaultState);

interface MaintenanceScheduleProviderProps {
    children?: React.ReactNode;
}

export const MaintenanceScheduleProvider: React.FC<MaintenanceScheduleProviderProps> = ({
    children,
}) => {
    const location = useLocation();
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);

    const [isLoading, setIsLoading] = useState(true);
    const [currentMaintenanceMessage, setCurrentMaintenanceMessage] =
        useState<MaintenanceSchedule | null>(null);

    useEffect(() => {
        loadData();
    }, [location.pathname]);

    const loadData = async () => {
        setIsLoading(true);
        await Promise.all([
            ApiService.getCurrentMaintenanceMessage()
                .then((maintenanceSchedule: MaintenanceSchedule | null) => {
                    setCurrentMaintenanceMessage(maintenanceSchedule);
                })
                .catch((error) => {
                    handleError(error, changeErrorMessage, changeShowError);
                }),
        ]).then(() => setIsLoading(false));
    };

    const completeCurrentMaintenanceMessage = async () => {
        if (!currentMaintenanceMessage) return;
        setIsLoading(true);

        ApiService.completeCurrentMaintenanceMessage(currentMaintenanceMessage.id!)
            .then((maintenanceSchedule: MaintenanceSchedule | null) => {
                loadData();
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <MaintenanceScheduleContext.Provider
            value={{
                isLoading,
                currentMaintenanceMessage,
                completeCurrentMaintenanceMessage,
            }}
        >
            {children}
        </MaintenanceScheduleContext.Provider>
    );
};
