import React, { useContext, useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import ErrorContext from "../context/ErrorContext";
import Snackbar from "../generics/Snackbar";
import SideNavigation from "./SideNavigation";
import TopBar from "./TopBar";
import { GroupuiInlineNotification } from "@group-ui/group-ui-react";
import { MaintenanceScheduleContext } from "../context/provider/MaintenanceScheduleContext";

/**
 * Returns an ContentWrapper Component, which defines the SideNavigation and the TopBar for each following website
 *
 * @returns ContentWrapper Component
 *
 */
function ContentWrapper() {
    const [iconOnly, setIconOnly] = useState(true);
    // Needed to run the first useEffect hook just once
    const dataFetchedRef = useRef(false);
    const { showError, errorMessage, changeShowError } = useContext(ErrorContext);
    const { currentMaintenanceMessage } = useContext(MaintenanceScheduleContext);

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        const data = window.localStorage.getItem("SIDE_NAVIGATION_STATE");
        if (data != null) setIconOnly(JSON.parse(data));
    }, []);

    useEffect(() => {
        window.localStorage.setItem("SIDE_NAVIGATION_STATE", JSON.stringify(iconOnly));
    }, [iconOnly]);

    const styles = {
        allContent: {
            display: "flex",
            flexDirection: "row" as const,
            overflow: "visible",
        },
        content: {
            display: "flex",
            flexDirection: "column" as const,
            width: iconOnly ? "calc(100% - 4.5rem)" : "calc(100% - 20rem)",
            flex: "wrap",
            overflow: "visible",
            overflowWrap: "break-word",
            scrollbarWidth: "none",
        } as React.CSSProperties,
        headerPlaceholder: { height: "4rem" },
        errorContainer: {
            display: "flex",
            flexDirection: "row",
            width: "100%",
        } as React.CSSProperties,
        icon: {
            paddingRight: "5%",
            color: "white",
        },
        message: {
            color: "white",
        },
    };

    function handleChange() {
        setIconOnly(!iconOnly);
    }

    return (
        <div className="App-structur" style={styles.allContent} data-testid="app-container">
            {showError && (
                <Snackbar
                    severity="danger"
                    message={errorMessage}
                    onClose={() => {
                        if (changeShowError) changeShowError(false);
                    }}
                />
            )}
            <SideNavigation handleChange={handleChange} iconOnly={iconOnly} />
            <div style={styles.content} data-testid="content-container">
                <div style={styles.headerPlaceholder} />
                <TopBar iconOnly={iconOnly} />
                {currentMaintenanceMessage && (
                    <GroupuiInlineNotification
                        visible={true}
                        hideIcon={false}
                        severity="warning"
                        style={{
                            // Added because color from the component isn´t working
                            color: "var(--groupui-sys-color-warning-basic-default)",
                            backgroundColor: "var(--groupui-sys-color-warning-weak-default)",
                            // Added to avoided dropdowns to be hidden
                            zIndex: "0",
                        }}
                    >
                        <div
                            slot="headline"
                            // Added because color from the component isn´t working
                            style={{ color: "var(--groupui-sys-color-text-normal)" }}
                        >
                            {currentMaintenanceMessage.eventTitle}
                        </div>
                        <div
                            slot="content"
                            // Added because color from the component isn´t working
                            style={{ color: "var(--groupui-sys-color-text-normal)" }}
                        >
                            {currentMaintenanceMessage.eventMessage}
                        </div>
                    </GroupuiInlineNotification>
                )}
                {/* Needed to display the content given by the Nested Route */}
                <Outlet />
            </div>
        </div>
    );
}

export default ContentWrapper;
