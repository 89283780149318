import { GroupuiTag, GroupuiTooltip } from "@group-ui/group-ui-react";
import React from "react";

interface GenericTagProps {
    text: string;
    variant: "primary" | "secondary" | string;
    square?: boolean;
    mediaStateTarget: "ingest-slot" | "databox";
    hasSmartHands?: boolean;
}

/**
 * A Tag-Component, which allows you to display a tag
 *
 * @param {string} text - sets the displayed text inside the tag, if the text matches Free, Reserved, Uploading or Defect the backgroundcolor changes
 * @param {"primary" | "secondary" | string} variant - changes styling
 * @param {boolean} [square] - toggles if the tag is round or square-like
 * @param {string} mediaStateTarget - if require specialized rendering for special objects, you can define the target type
 * @param {boolean} hasSmartHands - only works with mediaStateTarget = "ingest-slot", if set to true media state free displayed as ready to use
 *
 * @returns Tag
 *
 */
const GenericTag: React.FC<GenericTagProps> = ({
    text = "",
    variant,
    square = false,
    mediaStateTarget = "",
    hasSmartHands = false,
}) => {
    const colorMap = {
        free: "var(--groupui-sys-color-text-weakest)",
        reserved: "var(--groupui-sys-color-warning-basic-hover)",
        maintenance: "var(--groupui-sys-color-warning-basic-hover)",
        defective: "var(--groupui-sys-color-danger-basic-pressed)",
        decommissioned: "var(--groupui-sys-color-danger-basic-pressed)",
        alliance: "var(--groupui-sys-color-warning-basic-hover)",
        uploading: "var(--groupui-sys-color-success-basic-default)",
        upload: "var(--groupui-sys-color-success-basic-default)",
        error: "var(--groupui-sys-color-danger-basic-default)",
        "pre-upload": "var(--groupui-sys-color-success-basic-default)",
        "in stock": "var(--groupui-sys-color-warning-basic-hover)",
        "upload completed": "var(--groupui-sys-color-success-basic-default)",
        "preparation for next use": "var(--groupui-sys-color-warning-basic-hover)",
        "patching data": "var(--groupui-sys-color-warning-basic-hover)",
        "mounting issue": "var(--groupui-sys-color-danger-basic-default)",
        "hardware issue databox": "var(--groupui-sys-color-danger-basic-default)",
        "hardware issue slot": "var(--groupui-sys-color-danger-basic-default)",
        unknown: "var(--groupui-sys-color-text-weakest)",
        "upload cancelled": "var(--groupui-sys-color-text-weak)",
        "order cancelled": "var(--groupui-sys-color-text-weak)",
        "request completed": "var(--groupui-sys-color-success-basic-default)",
        "request cancelled": "var(--groupui-sys-color-warning-basic-hover)",
        "pickup not successful": "var(--groupui-sys-color-text-weak)",
    };

    const styles = {
        tag: {
            width: "160px",
            height: "24px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            fontSize: text.length > 19 ? "14px" : "15px",
            backgroundColor: colorMap[text.toLowerCase()],
            borderColor: colorMap[text.toLowerCase()],
        },
        tooltip: {
            display: "flex",
            position: "relative",
            whiteSpace: "nowrap",
        } as React.CSSProperties,
    };

    const tooltip = (mediaStates[text.toLowerCase()] || mediaStates["default"])[mediaStateTarget]
        .tooltip;

    return (
        <div style={styles.tooltip}>
            {tooltip && (
                <GroupuiTooltip position="auto">
                    <GroupuiTag square={square} variant={variant} style={styles.tag}>
                        {mediaStateTarget === "ingest-slot" &&
                        hasSmartHands === false &&
                        text === "Free"
                            ? "Ready for use"
                            : text}
                    </GroupuiTag>
                    <div slot="tooltip">
                        {/* Use default fallback if state isn´t present in map */}
                        {tooltip}
                    </div>
                </GroupuiTooltip>
            )}
            {!tooltip && (
                <GroupuiTag square={square} variant={variant} style={styles.tag}>
                    {mediaStateTarget === "ingest-slot" &&
                    hasSmartHands === false &&
                    text === "Free"
                        ? "Ready for use"
                        : text}
                </GroupuiTag>
            )}
        </div>
    );
};

export default GenericTag;

// Tooltip definition
const mediaStates: {
    [name: string]: {
        [category: string]: {
            tooltip: string;
            description: string;
        };
    };
} = {
    free: {
        databox: {
            tooltip: "The databox can be ordered",
            description: "",
        },
        "ingest-slot": {
            tooltip: "The ingest slot is switched off and empty",
            description: "",
        },
    },
    "in stock": {
        databox: {
            tooltip: "The databox is in stock and not visible for the customer",
            description: "",
        },
        "ingest-slot": {
            tooltip: "",
            description: "",
        },
    },
    reserved: {
        databox: {
            tooltip:
                "The databox is available only for a user who has reserved the corresponding capacity",
            description: "",
        },
        "ingest-slot": {
            tooltip: "The ingest slot is reserved for a planned ingest",
            description: "",
        },
    },
    maintenance: {
        databox: {
            tooltip: "The databox is currently being serviced",
            description: "",
        },
        "ingest-slot": {
            tooltip: "The ingest slot is currently being serviced",
            description: "",
        },
    },
    decommissioned: {
        databox: {
            tooltip: "The databox is no longer in service",
            description: "",
        },
        "ingest-slot": {
            tooltip: "The ingest slot is no longer in service",
            description: "",
        },
    },
    "delivery preparation": {
        databox: {
            tooltip: "The databox is prepared for handover to the delivery service",
            description: "",
        },
        "ingest-slot": {
            tooltip: "",
            description: "",
        },
    },
    "in transit (delivery)": {
        databox: {
            tooltip: "The databox is on its way to the requested location",
            description: "",
        },
        "ingest-slot": {
            tooltip: "",
            description: "",
        },
    },
    "in use": {
        databox: {
            tooltip: "The databox is currently in use by the customer",
            description: "",
        },
        "ingest-slot": {
            tooltip: "",
            description: "",
        },
    },
    "waiting for pickup": {
        databox: {
            tooltip: "The databox will soon be collected by the delivery provider",
            description: "",
        },
        "ingest-slot": {
            tooltip: "",
            description: "",
        },
    },
    "in transit (return)": {
        databox: {
            tooltip: "The databox is on its way back to the ingest station",
            description: "",
        },
        "ingest-slot": {
            tooltip: "",
            description: "",
        },
    },
    "preparation for ingest": {
        databox: {
            tooltip: "The databox is currently being prepared for the ingest",
            description: "",
        },
        "ingest-slot": {
            tooltip: "",
            description: "",
        },
    },
    "waiting for ingest slot": {
        databox: {
            tooltip: "Currently no free ingest slots available for the ingest",
            description: "",
        },
        "ingest-slot": {
            tooltip: "",
            description: "",
        },
    },
    "pre-upload": {
        databox: {
            tooltip:
                "The ingest has started and performs\n\\n the preparatory steps until the actual upload begins",
            description: "",
        },
        "ingest-slot": {
            tooltip:
                "The ingest has started and performs the preparatory steps until the actual upload begins",
            description: "",
        },
    },
    uploading: {
        databox: {
            tooltip: "The actual upload process is in progress",
            description: "",
        },
        "ingest-slot": {
            tooltip: "The actual upload process is in progress",
            description: "",
        },
    },
    "upload completed": {
        databox: {
            tooltip: "The ingest finished successfully",
            description: "",
        },
        "ingest-slot": {
            tooltip: "The ingest finished successfully",
            description: "",
        },
    },
    error: {
        databox: {
            tooltip: "An error occurred during the ingest process",
            description: "",
        },
        "ingest-slot": {
            tooltip: "An error occurred during the ingest process",
            description: "",
        },
    },
    "hardware issue databox": {
        databox: {
            tooltip: "Databox requires intervention by DXC and hardware vendor",
            description: "",
        },
        "ingest-slot": {
            tooltip: "",
            description: "",
        },
    },
    "preparing for next use": {
        databox: {
            tooltip: "The databox is prepared to be available for order again soon",
            description: "",
        },
        "ingest-slot": {
            tooltip: "",
            description: "",
        },
    },
    "upload cancelled": {
        databox: {
            tooltip: "The ingest was manually cancelled on portal side",
            description: "",
        },
        "ingest-slot": {
            tooltip: "The ingest was manually cancelled on portal side",
            description: "",
        },
    },
    "ready for use": {
        databox: {
            tooltip: "",
            description: "",
        },
        "ingest-slot": {
            tooltip:
                "The ingest slot is switched off and possibly empty, if not databox can be removed",
            description: "",
        },
    },
    "patching data": {
        databox: {
            tooltip:
                "Manual intervention by DXC and databox owner is needed to get the ingest started",
            description: "",
        },
        "ingest-slot": {
            tooltip:
                "Manual intervention by DXC and databox owner is needed to get the ingest started",
            description: "",
        },
    },
    "mounting issue": {
        databox: {
            tooltip: "Databox couldn´t be mounted in ingest slot, analysis ongoing by DXC",
            description: "",
        },
        "ingest-slot": {
            tooltip: "Databox couldn´t be mounted in ingest slot, analysis ongoing by DXC",
            description: "",
        },
    },
    "hardware issue slot": {
        databox: {
            tooltip: "Ingest Slot requires intervention by DXC and hardware vendor",
            description: "",
        },
        "ingest-slot": {
            tooltip: "Ingest Slot requires intervention by DXC and hardware vendor",
            description: "",
        },
    },
    "databox not registered": {
        databox: {
            tooltip: "The databox isn´t onboarded in the Data Transport Portal",
            description: "",
        },
        "ingest-slot": {
            tooltip: "",
            description: "",
        },
    },
    "order completed": {
        databox: {
            tooltip: "The order is done",
            description: "",
        },
        "ingest-slot": {
            tooltip: "",
            description: "",
        },
    },
    "order cancelled": {
        databox: {
            tooltip: "The order was manually cancelled",
            description: "",
        },
        "ingest-slot": {
            tooltip: "",
            description: "",
        },
    },
    alliance: {
        databox: {
            tooltip: "The databox is used by alliance",
            description: "",
        },
        "ingest-slot": {
            tooltip: "The ingest slot is used by alliance",
            description: "",
        },
    },
    unknown: {
        databox: {
            tooltip: "The value for media state was null during parsing",
            description: "",
        },
        "ingest-slot": {
            tooltip: "The value for media state was null during parsing",
            description: "",
        },
    },
    default: {
        databox: {
            tooltip: "Manual status set by DXC",
            description: "",
        },
        "ingest-slot": {
            tooltip: "Manual status set by DXC",
            description: "",
        },
    },
    "request completed": {
        databox: {
            tooltip: "The request is done",
            description: "",
        },
        "ingest-slot": {
            tooltip: "",
            description: "",
        },
    },
    "request cancelled": {
        databox: {
            tooltip: "The request was cancelled",
            description: "",
        },
        "ingest-slot": {
            tooltip: "",
            description: "",
        },
    },
    "pickup not successful": {
        databox: {
            tooltip: "The Pickup was not successful",
            description: "The requested Pickup Date is over.",
        },
        "ingest-slot": {
            tooltip: "",
            description: "",
        },
    },
    "waiting for drop off": {
        databox: {
            tooltip: "Waiting to be hand over to UPS",
            description: "",
        },
        "ingest-slot": {
            tooltip: "",
            description: "",
        },
    },
};
