import React, { useContext } from "react";
import { GroupuiBreadcrumb, GroupuiBreadcrumbs } from "@group-ui/group-ui-react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { OrderContext } from "../context/provider/OrderContext";

const styles = {
    contentContainer: {
        padding: "var(--groupui-sys-spacing-1200) var(--groupui-sys-spacing-1400)",
        scrollBehavior: "smooth",
        whiteSpace: "pre-wrap",
    } as React.CSSProperties,
};

/**
 * Returns an MyDataboxesDetailsWrapper Component, which positions the Breadcrumbs on the detail view of each order
 *
 * @returns MyDataboxesDetailsWrapper Component
 *
 */
const MyDataboxesDetailsWrapper = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { currentOrder } = useContext(OrderContext);

    return (
        <div style={styles.contentContainer}>
            <GroupuiBreadcrumbs>
                <GroupuiBreadcrumb onClick={() => navigate("/my-databoxes")}>
                    My Databoxes
                </GroupuiBreadcrumb>
                <GroupuiBreadcrumb
                    onClick={() =>
                        navigate(location.pathname.substring(0, location.pathname.lastIndexOf("/")))
                    }
                >
                    {currentOrder ? `My Databox - ${currentOrder.displayName}` : `My Databox`}
                </GroupuiBreadcrumb>
            </GroupuiBreadcrumbs>
            {/* Needed to display the content given by the Nested Route */}
            <Outlet />
        </div>
    );
};

export default MyDataboxesDetailsWrapper;
