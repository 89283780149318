import React, { useEffect, useState } from "react";
import { Project } from "../../models/project";
import GenericTable, { columnDefinition } from "./GenericTable";

interface ProjectTableProps {
    project: Project | undefined;
    numberOfLastEntriesToDisplay?: number;
    entriesPerTablePage?: number;
}

const columns: columnDefinition[] = [
    {
        key: "name",
        header: "Internal Project",
        width: "auto",
    },
    {
        key: "responsiblePerson",
        header: "Current Holder",
        width: "auto",
    },
    {
        key: "updated",
        header: "Updated",
        width: "auto",
    },
];
const ProjectTable: React.FC<ProjectTableProps> = ({
    project,
    numberOfLastEntriesToDisplay = 4,
    entriesPerTablePage = 5,
}) => {
    const data = project
        ? [
              {
                  name: project.name || "",
                  responsiblePerson: project.responsiblePerson || "",
                  updated: project.updated || "",
              },
          ]
        : [];

    return (
        <>
            <GenericTable
                columns={columns}
                data={data}
                header={true}
                pageSize={entriesPerTablePage}
            />
        </>
    );
};

export default ProjectTable;
