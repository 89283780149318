import React, { useContext, useState } from "react";
import { GroupuiModal, GroupuiLoadingSpinner, GroupuiButton } from "@group-ui/group-ui-react";
import GenericHeadline from "./generics/GenericHeadline";
import Snackbar from "./generics/Snackbar";
import { MaintenanceScheduleContext } from "./context/provider/MaintenanceScheduleContext";

interface CompleteMaintenanceMessageProps {
    showModal: boolean;
    setModal: (value: boolean) => void;
}

export const CompleteMaintenanceMessageModal: React.FC<CompleteMaintenanceMessageProps> = ({
    showModal,
    setModal,
}) => {
    // Parameter to show errors
    const { isLoading, currentMaintenanceMessage, completeCurrentMaintenanceMessage } = useContext(
        MaintenanceScheduleContext
    );
    const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
    const [severity, setSeverity] = useState<"danger" | "success" | "warning">();
    const [message, setMessage] = useState<string>();

    return (
        <GroupuiModal
            displayed={showModal}
            onGroupuiModalClose={() => setModal(false)}
            overflow="visible"
            style={{ justifyContent: "center", alignItems: "center" }}
            closeButton={true}
        >
            <GroupuiLoadingSpinner displayed={isLoading} />
            {showSnackBar && (
                <Snackbar
                    severity={severity ?? "danger"}
                    message={message ?? "Some error occurred!"}
                    onClose={() => setShowSnackBar(false)}
                />
            )}
            <GenericHeadline
                title={"Complete Maintenance Message"}
                titleHeadingType="h3"
                actionButtons={[]}
                type="modal"
            />
            <div
                style={{
                    display: "flex",
                    gap: "var(--groupui-sys-spacing-400)",
                    flexDirection: "column",
                }}
            >
                Do you want to complete the current Maintenance Message?
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "var(--groupui-sys-spacing-400)",
                    marginTop: "var(--groupui-sys-spacing-600)",
                }}
            >
                <GroupuiButton variant={"secondary"} onClick={() => setModal(false)}>
                    Cancel
                </GroupuiButton>
                <GroupuiButton
                    icon="gears-32"
                    disabled={!currentMaintenanceMessage}
                    onClick={() => completeCurrentMaintenanceMessage().then(() => setModal(false))}
                >
                    Complete
                </GroupuiButton>
            </div>
        </GroupuiModal>
    );
};
