import {
    GroupuiButton,
    GroupuiIcon,
    GroupuiLoadingSpinner,
    GroupuiTooltip,
} from "@group-ui/group-ui-react";
import React, { useContext, useEffect, useState } from "react";
import GenericHeadline from "../generics/GenericHeadline";
import GenericTable, { columnDefinition } from "../generics/GenericTable";
import useTitle from "../../hooks/useTitle";
import { ApiService } from "../../services/apiService";
import ErrorContext from "../context/ErrorContext";
import { handleError } from "../context/ErrorContextUtils";
import { CyclesReport } from "../../models/reports/cyclesReport";
import { GraphApiService, RequestType } from "../../services/graphApi/graphApiService";
import { GraphApiEndpoints } from "../../constants/graphApiEndpoints";
import { IngestsReport } from "../../models/reports/ingestsReport";
import { parseDateOnly, parseFullDateWithoutSeconds } from "../../utils/DateUtil";
import { formatBytes } from "../../utils/DataVolumeUtil";
import { useLocation, useNavigate } from "react-router-dom";
import GenericMoreButton from "../generics/GenericMoreButton";
import DownloadReportModal from "./DownloadReportModal";
import UserGroupContext from "../context/UserGroupContext";
import { DataboxesStatusReport } from "../../models/reports/databoxesStatusReport";
import DataboxLocationModal from "../databoxes/modals/DataboxLocationModal";
import DownloadReport from "./databoxesStatusReport/DownloadReport";
import { IngestsReportContext } from "../context/provider/IngestsReportContext";

export enum ReportType {
    CYCLES_REPORT,
    DATABOXES_STATUS_REPORT,
    INGESTS_REPORTS,
    INGESTS_STATISTICS_ROUTES,
}

const styles = {
    contentWrapper: {
        padding: "var(--groupui-sys-spacing-1200) var(--groupui-sys-spacing-1400)",
        scrollBehavior: "smooth",
        whiteSpace: "pre-wrap",
    } as React.CSSProperties,
    magnifyingGlass: {
        cursor: "pointer",
        color: "var(--groupui-sys-color-action-basic-default)",
    } as React.CSSProperties,
};

interface ReportProps {
    reportType: ReportType;
    columns: columnDefinition[];
}

const Report: React.FC<ReportProps> = ({ reportType, columns }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { ingestsReports } = useContext(IngestsReportContext);
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);

    const [title, setTitle] = useState<string>();
    useTitle(title);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [cyclesReport, setCyclesReport] = useState<CyclesReport[] | null | undefined>(undefined);

    const [databoxesStatusReport, setDataboxesStatusReport] = useState<
        DataboxesStatusReport[] | null | undefined
    >(undefined);
    const [data, setData] = useState<any[] | undefined>([]);

    const [modal, setModal] = useState(false);
    const [dropDownState, setDropDownState] = useState(false);

    const [mapLat, setMapLat] = useState(0);
    const [mapLon, setMapLon] = useState(0);

    useEffect(() => {
        switch (reportType) {
            case ReportType.CYCLES_REPORT:
                ApiService.getAllCyclesReport()
                    .then((response) => {
                        setCyclesReport(response);
                    })
                    .catch((error) => {
                        handleError(error, changeErrorMessage, changeShowError);
                    });
                break;
            case ReportType.DATABOXES_STATUS_REPORT:
                if (location.pathname === "/reports/databoxes-status-report") {
                    ApiService.getDataboxesStatusReportsForCustomer()
                        .then((response) => {
                            setDataboxesStatusReport(response);
                        })
                        .catch((error) => {
                            handleError(error, changeErrorMessage, changeShowError);
                        });
                    break;
                }
                ApiService.getDataboxesStatusReports()
                    .then((response) => {
                        setDataboxesStatusReport(response);
                    })
                    .catch((error) => {
                        handleError(error, changeErrorMessage, changeShowError);
                    });
                break;
        }
    }, []);

    useEffect(() => {
        switch (reportType) {
            case ReportType.CYCLES_REPORT:
                setTitle("Cycles Report");
                break;
            case ReportType.DATABOXES_STATUS_REPORT:
                setTitle("Databoxes Status Report");
                break;
            case ReportType.INGESTS_REPORTS:
                setTitle("Ingests Reports");
                break;
        }
    }, [reportType]);

    useEffect(() => {
        switch (reportType) {
            case ReportType.CYCLES_REPORT:
                getDataWithResolvedRequestor().then((result) => {
                    if (result != undefined) {
                        setData(result);
                        setIsLoading(false);
                    }
                });
                break;
            case ReportType.DATABOXES_STATUS_REPORT:
                getDataWithResolvedRequestor().then((result) => {
                    if (result != undefined) {
                        setData(result);
                        setIsLoading(false);
                    }
                });
                break;
            case ReportType.INGESTS_REPORTS:
                setData(
                    ingestsReports
                        ?.sort(
                            (a: IngestsReport, b: IngestsReport) =>
                                Date.parse(b.created) - Date.parse(a.created)
                        )
                        .map((ingestsReport: IngestsReport) => {
                            return {
                                reportId: ingestsReport.displayName,
                                from: parseDateOnly(ingestsReport.from),
                                to: parseDateOnly(ingestsReport.to),
                                successfulIngests: ingestsReport.successfulIngests,
                                uploadedVolume: formatBytes(ingestsReport.volume),
                                created: parseFullDateWithoutSeconds(ingestsReport.created),
                                downloadButton: <></>,
                                detailsButton: (
                                    <GroupuiTooltip position="right">
                                        <GroupuiIcon
                                            name="search-plus-32"
                                            style={styles.magnifyingGlass}
                                            onClick={() =>
                                                navigate(
                                                    `/ingests-report/${ingestsReport.calenderWeek}`
                                                )
                                            }
                                        />
                                        <div slot="tooltip">Show Details</div>
                                    </GroupuiTooltip>
                                ),
                            };
                        })
                );
                setIsLoading(false);
                break;
        }
    }, [cyclesReport, ingestsReports, databoxesStatusReport]);

    const getDataWithResolvedRequestor = async () => {
        let dataEntries;
        if (cyclesReport != undefined) {
            const userIdNameMapping = new Map<string, string>();
            await Promise.all(
                cyclesReport.map(async (cyclesReport: CyclesReport) => {
                    let result = undefined;
                    if (cyclesReport.requestor != null) {
                        if (!userIdNameMapping.has(cyclesReport.requestor)) {
                            result = await GraphApiService.callMsGraph(
                                GraphApiEndpoints.userById(cyclesReport.requestor),
                                RequestType.GET
                            ).catch((error) => {
                                handleError(error, changeErrorMessage, changeShowError);
                            });
                            userIdNameMapping[cyclesReport.requestor] = result.displayName;
                        }
                    }
                })
            );
            dataEntries = cyclesReport?.map((cyclesReport: CyclesReport) => {
                return {
                    orderId: "OR" + cyclesReport.orderId,
                    databoxName: cyclesReport.databoxName,
                    databoxSerialNumber: cyclesReport.databoxSerialNumber,
                    databoxStatus: cyclesReport.databoxStatus,
                    shipmentDateDelivery: parseFullDateWithoutSeconds(
                        cyclesReport.shipmentDateDelivery
                    ),
                    shipmentDateReturn: parseFullDateWithoutSeconds(
                        cyclesReport.shipmentDateReturn
                    ),
                    trackingNumberDelivery: cyclesReport.trackingNumberDelivery,
                    trackingNumberReturn: cyclesReport.trackingNumberReturn,
                    outboundTicket: cyclesReport.outboundTicket,
                    smartHandsTicketDelivery: cyclesReport.smartHandsTicketDelivery,
                    inboundTicket: cyclesReport.inboundTicket,
                    smartHandsTicketIngest: cyclesReport.smartHandsTicketIngest,
                    smartHandsTicketPostIngest: cyclesReport.smartHandsTicketPostIngest,
                    ingestLocation: cyclesReport.ingestLocation,
                    destination: cyclesReport.destination,
                    requestor: userIdNameMapping[cyclesReport.requestor] ?? "",
                    orderCreated: parseFullDateWithoutSeconds(cyclesReport.orderCreated),
                    uploadCompleted: parseFullDateWithoutSeconds(cyclesReport.uploadCompleted),
                };
            });

            return dataEntries;
        } else if (databoxesStatusReport != undefined) {
            const userIdNameMapping = new Map<string, string>();
            await Promise.all(
                databoxesStatusReport.map(async (databoxesStatus: DataboxesStatusReport) => {
                    let result = undefined;
                    if (databoxesStatus.requestor != null) {
                        if (!userIdNameMapping.has(databoxesStatus.requestor)) {
                            result = await GraphApiService.callMsGraph(
                                GraphApiEndpoints.userById(databoxesStatus.requestor),
                                RequestType.GET
                            ).catch((error) => {
                                handleError(error, changeErrorMessage, changeShowError);
                            });
                            userIdNameMapping[databoxesStatus.requestor] = result.displayName;
                        }
                    }
                })
            );
            dataEntries = databoxesStatusReport
                .sort((a: DataboxesStatusReport, b: DataboxesStatusReport) => {
                    return a.databoxId - b.databoxId;
                })
                .map((databoxStatus: DataboxesStatusReport, index) => {
                    return {
                        databoxName: databoxStatus.databoxName,
                        databoxSerialNumber: databoxStatus.databoxSerialNumber,
                        storageCapacity: databoxStatus.storageCapacityDisplayName,
                        databoxOwner: databoxStatus.databoxOwner,
                        orderId: databoxStatus.orderId ? "OR" + databoxStatus.orderId : "",
                        databoxStatus: databoxStatus.databoxStatus,
                        tracker: databoxStatus.tracker,
                        battery: databoxStatus.battery ? databoxStatus.battery + " %" : "",
                        lastTrackerUpdate: parseFullDateWithoutSeconds(
                            databoxStatus.lastTrackerUpdate
                        ),
                        ingestLocation: databoxStatus.ingestLocation,
                        destination: databoxStatus.destination,
                        requestor: userIdNameMapping[databoxStatus.requestor] ?? "",
                        lastComment: databoxStatus.lastComment,
                        internalProject: databoxStatus.internalProject,
                        currentHolder: databoxStatus.currentHolder,
                        purchaseOrderNumber: databoxStatus.purchaseOrderNumber,
                        purchaseOrderDate: parseDateOnly(databoxStatus.purchaseOrderDate),
                        opxNumber: databoxStatus.opxNumber,
                        serviceContractId: databoxStatus.serviceContractId,
                        serviceStartDate: parseDateOnly(databoxStatus.serviceStartDate),
                        serviceEndDate: parseDateOnly(databoxStatus.serviceEndDate),
                        mapIcon: databoxStatus.tracker ? (
                            <GroupuiTooltip key={`tooltip-${index}`} position="right">
                                <GroupuiIcon
                                    key={`icon-${index}`}
                                    name="location-32"
                                    style={styles.magnifyingGlass}
                                    onClick={() =>
                                        openModal(databoxStatus.longitude, databoxStatus.latitude)
                                    }
                                />
                                <div key={`tooltip-content-${index}`} slot="tooltip">
                                    Show Map
                                </div>
                            </GroupuiTooltip>
                        ) : (
                            <div key={`empty-div-${index}`} />
                        ),
                        longitude: databoxStatus.longitude,
                        latitude: databoxStatus.latitude,
                    };
                });
            return dataEntries;
        }
    };

    const openModal = (lon: number, lat: number) => {
        setMapLat(lat);
        setMapLon(lon);
        setModal(true);
    };

    return (
        <div>
            {modal && reportType == ReportType.CYCLES_REPORT && (
                <DownloadReportModal
                    reportType={title ?? ""}
                    data={data}
                    modal={modal}
                    setModal={(value: boolean) => setModal(value)}
                />
            )}
            {modal && reportType == ReportType.DATABOXES_STATUS_REPORT && (
                <DataboxLocationModal
                    modal={modal}
                    setModal={() => setModal}
                    onModalClose={() => setModal(false)}
                    lon={mapLon}
                    lat={mapLat}
                />
            )}
            <GroupuiLoadingSpinner displayed={isLoading} />
            <div style={styles.contentWrapper}>
                <GenericHeadline
                    title={title ?? ""}
                    actionButtons={
                        reportType == ReportType.CYCLES_REPORT ||
                        reportType == ReportType.DATABOXES_STATUS_REPORT
                            ? [
                                  <GenericMoreButton
                                      key={"More-Button-1"}
                                      dropDownState={dropDownState}
                                      setDropDownState={() => {
                                          setDropDownState(!dropDownState);
                                      }}
                                      options={[
                                          reportType == ReportType.DATABOXES_STATUS_REPORT ? (
                                              <DownloadReport
                                                  key={"databox-report-option-1"}
                                                  data={data}
                                                  title={title ?? ""}
                                                  setDropDownState={setDropDownState}
                                              />
                                          ) : (
                                              <GroupuiButton
                                                  key={"Option-1"}
                                                  variant="primary"
                                                  icon="download-32"
                                                  fullwidth={true}
                                                  alignment="left"
                                                  // disabled={true}
                                                  onClick={() => {
                                                      setModal(true);
                                                      setDropDownState(false);
                                                  }}
                                              >
                                                  Download {title}
                                              </GroupuiButton>
                                          ),
                                      ]}
                                  />,
                              ]
                            : []
                    }
                />
                <div>
                    <GenericTable
                        columns={columns}
                        data={data ?? []}
                        header={true}
                        pageSize={20}
                        scrollX={true}
                    />
                </div>
            </div>
        </div>
    );
};

export default Report;
