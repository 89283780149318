import React, { useState, createContext } from "react";

interface IThemeContext {
    showError: boolean;
    errorMessage: string;
    changeShowError?: (show: boolean) => void;
    changeErrorMessage?: (message: string) => void;
}

const defaultState = {
    showError: false,
    errorMessage: "Something went wrong",
};

const ErrorContext = createContext<IThemeContext>(defaultState);

interface Props {
    children?: React.ReactNode;
}

export default ErrorContext;

/**
 * A ErrorContextProvider Component, which allows the usage of the ErrorContext
 * Best used high up in the hierarchy
 *
 * @param {React.ReactNode} [children]  - child components, for which this components provides the context
 *
 * @returns UserGroupProvider
 *
 */
export const ErrorMessageProvider: React.FC<Props> = ({ children }) => {
    const [showError, setShowError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const changeShowError = (show: boolean) => {
        setShowError(show);
    };

    const changeErrorMessage = (message: string) => {
        setErrorMessage(message);
    };

    return (
        <ErrorContext.Provider
            value={{
                showError,
                errorMessage,
                changeShowError,
                changeErrorMessage,
            }}
        >
            {children}
        </ErrorContext.Provider>
    );
};
