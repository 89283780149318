import React, { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import IngestJobs from "../ingestJobs/IngestJobs";
import IngestJobDetailsWrapper from "../ingestJobs/IngestJobDetailsWrapper";
import IngestJobDetails from "../ingestJobs/IngestJobDetails";
import { RightsWrapper } from "../container/RightsWrapper";
import UserGroupContext from "../context/UserGroupContext";
import { RightsManagement } from "../../config/rightsManagement";
import { OrderProvider } from "../context/provider/OrderContext";

/**
 * Returns an MyDataboxesRoutes Component, which defines the Nested Routes nested under the parent
 *
 * @returns MyDataboxesRoutes Component
 *
 */
const IngestJobRoutes = () => {
    const { userGroups } = useContext(UserGroupContext);
    return (
        <RightsWrapper
            key={"first"}
            authorizedUserGroup={RightsManagement.INGEST_JOBS_ROUTES}
            userGroups={userGroups}
            section={true}
        >
            <OrderProvider>
                <Routes>
                    <Route index element={<IngestJobs />} />
                    <Route path=":orderID/*" element={<IngestJobDetailsWrapper />}>
                        <Route index element={<IngestJobDetails />} />
                        {/* Redirect the user if the Route doesn´t exist */}
                        <Route path="*" element={<Navigate to="/ingest-jobs" />} />
                    </Route>
                    <Route path="*" element={<Navigate to="/ingest-jobs" />} />
                </Routes>
            </OrderProvider>
        </RightsWrapper>
    );
};

export default IngestJobRoutes;
