import {
    GroupuiModal,
    GroupuiLoadingSpinner,
    GroupuiDivider,
    GroupuiText,
    GroupuiButton,
    GroupuiIcon,
    GroupuiNotification,
} from "@group-ui/group-ui-react";
import React, { useContext, useState } from "react";
import GenericHeadline from "../generics/GenericHeadline";
import Snackbar from "../generics/Snackbar";
import GenericInput from "../generics/GenericInput";
import { ApiService } from "../../services/apiService";
import { CancellationRequest } from "../../models/cancellationRequest";
import ErrorContext from "../context/ErrorContext";
import { handleError } from "../context/ErrorContextUtils";

interface CancelModalProps {
    orderId: number;
    modal: boolean;
    setModal: (value: boolean) => void;
}

const styles = {
    notificationWrapper: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        gap: "var(--groupui-sys-spacing-300)",
    } as React.CSSProperties,
    notificationBulletPoints: {
        display: "flex",
        flexDirection: "column",
    } as React.CSSProperties,
};

export const CancelOrderModal: React.FC<CancelModalProps> = ({ orderId, modal, setModal }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [jiraTicketReasoning, setJiraTicketReasoning] = useState<string>("");
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
    const [severity, setSeverity] = useState<"success" | "danger">("success");
    const [message, setMessage] = useState<string>("");

    const sendRequest = () => {
        setLoading(true);
        ApiService.cancelOrder(
            new CancellationRequest({ orderId: orderId, reasonForTermination: jiraTicketReasoning })
        )
            .then((response) => {
                if (response.startsWith("success")) {
                    setLoading(false);
                    setMessage("Successfully cancelled the order!");
                    setSeverity("success");
                    setShowSnackbar(true);
                    setTimeout(() => {
                        setModal(false);
                        window.location.reload();
                    }, 2000);
                } else {
                    setShowSnackbar(true);
                    setMessage("An error occurred during cancellation of ticket!");
                    setSeverity("danger");

                    setShowSnackbar(true);
                    setLoading(false);
                }
            })
            .catch((error) => {
                setLoading(false);
                handleError(error, changeErrorMessage, changeShowError);
            });
    };

    return (
        <GroupuiModal
            displayed={modal}
            onGroupuiModalClose={() => setModal(false)}
            closeButton={true}
            overflow="visible"
            margin="200px"
            padding="2rem"
        >
            <GroupuiLoadingSpinner displayed={loading} />
            {showSnackbar && (
                <Snackbar
                    severity={severity}
                    message={message}
                    onClose={() => setShowSnackbar(false)}
                />
            )}
            <GenericHeadline
                title={"Cancel Databox Order?"}
                titleHeadingType="h4"
                marginBottom="var(--groupui-sys-spacing-300)"
                actionButtons={[]}
            />

            <div
                style={{
                    display: "flex",
                    gap: "var(--groupui-sys-spacing-300)",
                    flexDirection: "column",
                }}
            >
                <GroupuiText>Are you sure you want to cancel this databox order?</GroupuiText>
                <div>
                    <GroupuiNotification severity="danger" dismissible={false}>
                        <div style={styles.notificationWrapper}>
                            <GroupuiIcon name={"warning-filled-32"} height={25} />
                            <div style={styles.notificationBulletPoints}>
                                Cancel Databox Order will only handle the portal internal status:
                                <div style={{ textIndent: "30px" }}>
                                    <li>Order will be shown as &quot;Order Cancelled&quot;</li>
                                    <li>Databox will be &quot;Free&quot; again</li>
                                </div>
                                Equinix Tickets must be cancelled manually!
                            </div>
                        </div>
                    </GroupuiNotification>
                </div>
                <GenericInput
                    label="Jira Ticket & Reasoning *"
                    value={jiraTicketReasoning}
                    onGroupuiChange={(event: any) => setJiraTicketReasoning(event.target.value)}
                    maxLength={8000}
                />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "var(--groupui-sys-spacing-400)",
                        marginTop: "var(--groupui-sys-spacing-600)",
                    }}
                >
                    <GroupuiButton variant={"secondary"} onClick={() => setModal(false)}>
                        Cancel
                    </GroupuiButton>
                    <GroupuiButton
                        size="m"
                        severity="danger"
                        icon="close-32"
                        disabled={jiraTicketReasoning ? false : true}
                        onClick={sendRequest}
                    >
                        Cancel Order
                    </GroupuiButton>
                </div>
            </div>
        </GroupuiModal>
    );
};
