import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ApiService } from "../../services/apiService";
import ErrorContext from "../context/ErrorContext";
import { handleError } from "../context/ErrorContextUtils";
import { Databox } from "../../models/databox";
import { OrderProjectHistory } from "../../models/orderProjectHistory";
import { IngestStation } from "../../models/ingestStation";
import GeneralInformationEdit from "./GeneralInformationEdit";
import GeneralInformationView from "./GeneralInformationView";
import { GroupuiLoadingSpinner } from "@group-ui/group-ui-react";
interface Props {
    editMode: boolean;
    handleEditMode: () => void;
}

/**
 * A GeneralInformationTab Component, which displays the general-information of the given databox
 *
 * @returns GeneralInformationTab Component
 *
 */
const GeneralInformationTab: React.FC<Props> = ({ editMode, handleEditMode }) => {
    const { databoxID } = useParams();
    const [databoxData, setDataboxData] = useState<Databox>();
    const [isLoading, setIsLoading] = useState(false);
    const [orderProjectHistory, setOrderProjectHistory] = useState<OrderProjectHistory | null>();
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);

    const [ingestLocations, setIngestLocations] = useState<IngestStation[]>();

    useEffect(() => {
        getData();
    }, [databoxID]);

    const getData = async () => {
        setIsLoading(true);
        try {
            const [databoxResponse, orderProjectResponse, ingestStationsResponse] =
                await Promise.all([
                    ApiService.getDataboxById(Number(databoxID)),
                    ApiService.getCurrentOrderProjectHistoryByDataboxId(Number(databoxID)),
                    ApiService.getAllIngestStations(),
                ]);
            setDataboxData(databoxResponse);
            setOrderProjectHistory(orderProjectResponse);
            setIngestLocations(ingestStationsResponse);
        } catch (error) {
            handleError("", changeErrorMessage, changeShowError);
        }
        setIsLoading(false);
    };

    const handleDataUpdate = async () => {
        await getData();
    };

    return (
        <div>
            <GroupuiLoadingSpinner displayed={isLoading} />
            {editMode ? (
                <GeneralInformationEdit
                    databoxData={databoxData}
                    ingestLocations={ingestLocations}
                    handleEditMode={handleEditMode}
                    onDataUpdate={handleDataUpdate}
                />
            ) : (
                <GeneralInformationView
                    databoxData={databoxData}
                    orderProjectHistory={orderProjectHistory}
                />
            )}
        </div>
    );
};

export default GeneralInformationTab;
