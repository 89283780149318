import React from "react";

export function handleError(
    error: string,
    changeErrorMessage: ((message: string) => void) | undefined,
    changeShowError: ((show: boolean) => void) | undefined,
    differentErrorDisplay = false
) {
    if (!String(error).includes("SPECIALCASE")) {
        const errorMessage = differentErrorDisplay
            ? String(error)
            : String(error) + " - " + "Contact site Administrator.";

        if (changeShowError) changeShowError(true);
        if (changeErrorMessage) changeErrorMessage(errorMessage);
    }
}
