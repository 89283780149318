import { Address } from "./address";

export class NonStandardRequestResponse {
    private createdRequestId;
    public error;
    public suggestedDeliveryAddress;
    public suggestedPickupAddress;

    constructor({
        createdRequestId,
        error,
        suggestedDeliveryAddress,
        suggestedPickupAddress,
    }: {
        createdRequestId?: number;
        error?: string;
        suggestedDeliveryAddress?: Address;
        suggestedPickupAddress?: Address;
    }) {
        this.createdRequestId = createdRequestId;
        this.error = error;
        this.suggestedDeliveryAddress = suggestedDeliveryAddress;
        this.suggestedPickupAddress = suggestedPickupAddress;
    }

    public get ID() {
        return this.createdRequestId;
    }

    private toObject() {
        return {
            id: this.createdRequestId,
            error: this.error,
            suggestedDeliveryAddress: this.suggestedDeliveryAddress,
            suggestedPickupAddress: this.suggestedPickupAddress,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): NonStandardRequestResponse {
        if (json == null) {
            throw new Error(`Error parsing Company!`);
        }

        return new NonStandardRequestResponse({
            createdRequestId: json["createdRequestId"] ?? undefined,
            error: json["error"] ?? undefined,
            suggestedDeliveryAddress:
                (json["suggestedDeliveryAddress"] !== null &&
                json["suggestedDeliveryAddress"] !== undefined
                    ? Address.fromSerialized(json["suggestedDeliveryAddress"])
                    : undefined) ?? undefined,
            suggestedPickupAddress:
                (json["suggestedPickupAddress"] !== null &&
                json["suggestedPickupAddress"] !== undefined
                    ? Address.fromSerialized(json["suggestedPickupAddress"])
                    : undefined) ?? undefined,
        });
    }
}
