import React, { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { RightsWrapper } from "../../container/RightsWrapper";
import UserGroupContext from "../../context/UserGroupContext";
import { columnDefinition } from "../../generics/GenericTable";
import Report, { ReportType } from "../../report/Report";
import { RightsManagement } from "../../../config/rightsManagement";
import ShipmentReport from "../../report/shipmentReport/ShipmentReport";

/**
 * A ReportRoutes Component, which defines the Nested Routes nested under the parent for report-content
 *
 * @returns ReportRoutes
 *
 */
const ReportRoutes = () => {
    const { userGroups } = useContext(UserGroupContext);
    return (
        <RightsWrapper
            key={"first"}
            authorizedUserGroup={RightsManagement.REPORT_ROUTES}
            userGroups={userGroups}
            section={true}
        >
            <Routes>
                <Route
                    path="/databoxes-status-report"
                    element={
                        <Report
                            reportType={ReportType.DATABOXES_STATUS_REPORT}
                            columns={databoxesTableColumns}
                        />
                    }
                />
                <Route path="/shipment-report" element={<ShipmentReport />} />
                {/* Redirect the user if the Route doesn´t exist */}
                <Route path="*" element={<Navigate to="/not-found" />} />
            </Routes>
        </RightsWrapper>
    );
};

const databoxesTableColumns: columnDefinition[] = [
    {
        key: "databoxName",
        header: "Databox Name",
        width: "auto",
    },
    {
        key: "databoxSerialNumber",
        header: "Databox Serial Number",
        width: "auto",
    },
    {
        key: "storageCapacity",
        header: "Storage Capacity (TB)",
        width: "auto",
    },
    {
        key: "databoxOwner",
        header: "Databox Owner",
        width: "auto",
    },
    {
        key: "orderId",
        header: "Order ID",
        width: "auto",
    },
    {
        key: "databoxStatus",
        header: "Databox Status",
        width: "auto",
    },
    // {
    //     key: "lastStatusUpdate",
    //     header: "Last Status Update",
    //     width: "auto",
    // },
    {
        key: "tracker",
        header: "Tracker",
        width: "auto",
    },
    {
        key: "battery",
        header: "Battery",
        width: "auto",
    },
    {
        key: "lastTrackerUpdate",
        header: "Last Tracker Update",
        width: "auto",
    },
    {
        key: "ingestLocation",
        header: "Ingest Location",
        width: "auto",
    },
    {
        key: "destination",
        header: "Destination",
        width: "auto",
    },
    {
        key: "requestor",
        header: "Requestor",
        width: "auto",
    },
    {
        key: "lastComment",
        header: "Last Comment",
        width: "auto",
    },
    {
        key: "internalProject",
        header: "Internal Project",
        width: "auto",
    },
    {
        key: "currentHolder",
        header: "Current Holder",
        width: "auto",
    },
    {
        key: "mapIcon",
        header: "",
        width: "4rem",
    },
];

export default ReportRoutes;
