import React from "react";
import { Order } from "../../models/order";
import GenericInput from "../generics/GenericInput";
import { parseFullDate } from "../../utils/DateUtil";
import { GroupuiIcon } from "@group-ui/group-ui-react";

const styles = {
    icon: { cursor: "pointer" },
};

const ShipmentJobsDetailsGeneralTabGeneralInformation: React.FC<{ order?: Order }> = ({
    order,
}) => {
    return (
        <div style={{ display: "flex", gap: 40 }}>
            <div style={{ width: "50%" }}>
                <GenericInput
                    label={"Shipment Job ID"}
                    value={`SJ${order?.shipmentJob?.id}` ?? ""}
                    disabled={true}
                />
                <GenericInput
                    label={"Databox Name"}
                    value={order?.databox.name ?? ""}
                    disabled={true}
                />
                <GenericInput
                    label={"Databox Status"}
                    value={order?.currentMediaState}
                    disabled={true}
                />
                <GenericInput
                    label={"Job Status"}
                    value={order?.shipmentJob?.jobStatus}
                    disabled={true}
                />
                <GenericInput
                    label={"Equinix Outbound Ticket"}
                    value={order?.shipmentJob?.outboundTicket ?? ""}
                    disabled={true}
                />
                <GenericInput
                    label={"Associated Order"}
                    value={`OR${order?.id}` ?? ""}
                    disabled={true}
                />
            </div>
            <div style={{ width: "50%" }}>
                <GenericInput
                    label={"Delivery Provider"}
                    value={order?.upsShipmentLabel ? "UPS" : ""}
                    disabled={true}
                />
                <GenericInput
                    label={"Tracking Number"}
                    value={order?.upsShipmentLabel?.trackingNumber ?? ""}
                    disabled={true}
                />
                <div style={{ position: "relative" }}>
                    <GenericInput
                        label={"Delivery Label"}
                        value={
                            order?.upsShipmentLabel !== undefined
                                ? `delivery_label_${order.upsShipmentLabel?.shipmentIdenfificationNumber}.png`
                                : ""
                        }
                        disabled={true}
                    />
                    {order?.upsShipmentLabel !== undefined && (
                        <div style={{ position: "absolute", right: 8, bottom: 8 }}>
                            <GroupuiIcon
                                name="download-32"
                                onClick={() => {
                                    const base64png = order.upsShipmentLabel?.label;
                                    if (base64png === undefined) {
                                        return;
                                    }
                                    const a = document.createElement("a");
                                    a.href =
                                        "data:image/png;base64," + order.upsShipmentLabel?.label;
                                    a.download = `delivery_label_${order.upsShipmentLabel?.shipmentIdenfificationNumber}.png`;
                                    a.click();
                                }}
                                style={styles.icon}
                            />
                        </div>
                    )}
                </div>
                <div style={{ position: "relative" }}>
                    <GenericInput
                        label={"Return Label"}
                        value={
                            order?.upsReturnShipmentLabel !== undefined
                                ? `return_label_${order.upsReturnShipmentLabel?.shipmentIdenfificationNumber}.png`
                                : ""
                        }
                        disabled={true}
                    />
                    {order?.upsReturnShipmentLabel !== undefined && (
                        <div style={{ position: "absolute", right: 8, bottom: 8 }}>
                            <GroupuiIcon
                                name="download-32"
                                onClick={() => {
                                    const base64png = order.upsReturnShipmentLabel?.label;
                                    if (base64png === undefined) {
                                        return;
                                    }
                                    const a = document.createElement("a");
                                    a.href =
                                        "data:image/png;base64," +
                                        order.upsReturnShipmentLabel?.label;
                                    a.download = `return_label_${order.upsReturnShipmentLabel?.shipmentIdenfificationNumber}.png`;
                                    a.click();
                                }}
                                style={styles.icon}
                            />
                        </div>
                    )}
                </div>
                <GenericInput
                    label={"Created"}
                    value={parseFullDate(order?.shipmentJob?.created)}
                    disabled={true}
                />
                <GenericInput
                    label={"Last Updated"}
                    value={
                        order?.shipmentJob?.updated
                            ? parseFullDate(order.shipmentJob.updated)
                            : "Not yet updated"
                    }
                    disabled={true}
                />
            </div>
        </div>
    );
};

export default ShipmentJobsDetailsGeneralTabGeneralInformation;
