import { IngestSlotMonitorData } from "../models/ingestSlotMonitorData";
import { timeSince } from "./DateUtil";

export function getStepLabelForDatabaseName(databaseColumnName: string): string {
    switch (databaseColumnName) {
        case "ingest_started":
            return "Ingest started";
        case "pre_upload_started":
            return "Pre-Upload started";
        case "upload_data_file_started":
            return "Upload data file started";
        case "upload_data_file_progress":
            return "Upload data file progress";
        case "upload_data_file_finished":
            return "Upload data file finished";
        case "ingest_finished":
            return "Ingest finished";
    }

    return databaseColumnName;
}

export function getNextStepLabelForDatabaseName(databaseColumnName: string): string {
    switch (databaseColumnName) {
        case "ingest_started":
            return "Pre-Upload started";
        case "pre_upload_started":
            return "Upload data file started";
        case "upload_data_file_started":
            return "Upload data file progress";
        case "upload_data_file_progress":
            return "Upload data file finished";
        case "upload_data_file_finished":
            return "Ingest finished";
        case "ingest_finished":
            return "Ingest finished";
    }

    return databaseColumnName;
}

export function getTimePassed(entry: IngestSlotMonitorData) {
    if (entry.state.toLowerCase() === "uploading") {
        return timeSince(entry.uploadStart);
    }

    if (entry.state.toLowerCase() === "pre-upload") {
        return timeSince(entry.ingestStart);
    }

    if (entry.state.toLowerCase() === "error") {
        return timeSince(entry.lastStepTimestamp);
    }
    return "";
}
