export class ReleaseTask {
    public id;
    public taskTitle;
    public taskType;
    public releaseVersion;
    public releaseDate;

    constructor({
        id,
        taskTitle,
        taskType,
        releaseVersion,
        releaseDate,
    }: {
        id: number;
        taskTitle: string;
        taskType: string;
        releaseVersion: string;
        releaseDate: string;
    }) {
        this.id = id;
        this.taskTitle = taskTitle;
        this.taskType = taskType;
        this.releaseVersion = releaseVersion;
        this.releaseDate = releaseDate;
    }

    private toObject() {
        return {
            id: this.id,
            taskTitle: this.taskTitle,
            taskType: this.taskType,
            releaseVersion: this.releaseVersion,
            releaseDate: this.releaseDate,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject);
    }

    static fromSerialized(json: object): ReleaseTask {
        if (json == null) {
            throw new Error(`Error parsing ReleaseTask!`);
        }

        return new ReleaseTask({
            id: json["id"],
            taskTitle: json["taskTitle"],
            taskType: json["taskType"],
            releaseVersion: json["releaseVersion"],
            releaseDate: json["releaseDate"],
        });
    }
}
