import { GroupuiSearchField } from "@group-ui/group-ui-react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export type searchColumns = { column: string; displayName: string }[];

interface Props {
    searchColumns: searchColumns;
    searchQuery: object;
    setSearchQuery: React.Dispatch<React.SetStateAction<any>>;
    width?: string;
}

/**
 * A Generic-Search-Component, which uses given uses a search-query in the given data in the specified searchColumns.
 * This component needs a state in the parent component to safe the search-query to be used in
 * Also needs the search-bar-utils file since this component only extracts the search-query and fills the state
 *
 * Usage example:
 * let filteredData = searchData(filteredData, searchQuery);
 * setFilteredData(filteredData);
 *
 * @param {string[]} searchColumns  - columns to search in (in the given data)
 * @param {string[]} displayName  - needed for the display of the placeholder text
 * @param {any[]} initialData  - needed for showing data when no search-query is used
 * @param {React.Dispatch<React.SetStateAction<any>>} setData  - sets the data with filtered (or initial-data) to be displayed in the table
 *
 * @returns Search bar with a placeholder text
 *
 */
const GenericSearchBar: React.FC<Props> = ({
    searchColumns,
    searchQuery,
    setSearchQuery,
    width = "20rem",
}) => {
    const location = useLocation();
    const [currentSearchQuery, setCurrentSearchQuery] = useState<string>(() =>
        location.state?.ingestId ? location.state.ingestId.toString() : ""
    );
    useEffect(() => {
        const newSearchQuery = { ...searchQuery };
        newSearchQuery["query"] = currentSearchQuery;
        newSearchQuery["columns"] = searchColumns;
        setSearchQuery(newSearchQuery);
    }, [currentSearchQuery]);

    return (
        <GroupuiSearchField
            placeholder={"Search for " + searchColumns.map((e) => e.displayName).join(" and ")}
            severity="none"
            hideClearIcon={false}
            subStringHighlighting={true}
            key={"first"}
            style={{ width: width }}
            onGroupuiInput={(event) => setCurrentSearchQuery(event.target.value)}
            onGroupuiClearClicked={() => setCurrentSearchQuery("")}
            value={currentSearchQuery}
        />
    );
};

export default GenericSearchBar;
