import React, { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import CreateRequest from "../requests/CreateRequest";
import OrderDataboxes from "../requests/OrderDataboxes";
import OrderDataboxesWrapper from "../requests/OrderDataboxesWrapper";
import { RightsWrapper } from "../container/RightsWrapper";
import UserGroupContext from "../context/UserGroupContext";
import { RightsManagement } from "../../config/rightsManagement";

/**
 * Returns an RequestRoutes Component, which defines the Nested Routes nested under the parent
 *
 * @returns RequestRoutes Component
 *
 */
function RequestRoutes() {
    const { userGroups } = useContext(UserGroupContext);
    return (
        <RightsWrapper
            key={"first"}
            authorizedUserGroup={RightsManagement.REQUEST_ROUTES}
            userGroups={userGroups}
            section={true}
        >
            <Routes>
                <Route index element={<CreateRequest />} />
                <Route path="order-databoxes/*" element={<OrderDataboxesWrapper />}>
                    <Route index element={<OrderDataboxes />} />
                    <Route path="*" element={<Navigate to="/create-request/order-databoxes" />} />
                </Route>
                <Route path="*" element={<Navigate to="/create-request" />} />
            </Routes>
        </RightsWrapper>
    );
}

export default RequestRoutes;
