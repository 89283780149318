export class Project {
    public id;
    public name;
    public responsiblePerson;
    public department;
    public created;
    public updated;

    constructor({
        id,
        name,
        responsiblePerson,
        department,
        created,
        updated,
    }: {
        id: number;
        name: string;
        responsiblePerson: string;
        department: string;
        created: string;
        updated: string | null;
    }) {
        this.id = id;
        this.name = name;
        this.responsiblePerson = responsiblePerson;
        this.department = department;
        this.created = created;
        this.updated = updated;
    }

    private toObject() {
        return {
            id: this.id,
            name: this.name,
            responsiblePerson: this.responsiblePerson,
            department: this.department,
            created: this.created,
            updated: this.updated,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): Project | null {
        if (json == null) {
            return null;
        }

        return new Project({
            id: json["id"],
            name: json["name"],
            responsiblePerson: json["responsiblePerson"],
            department: json["department"],
            created: json["created"],
            updated: json["updated"],
        });
    }
}
