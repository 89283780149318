import { MediaState } from "../mediaState";
import { Project } from "../project";

export class IngestSlotBooking {
    public id;
    public ingestSlotId;
    public created;
    public started;
    public finished;
    public mediaState;
    public by;
    public comment;
    public orderId;
    public projectsId;
    public project;
    public ticketId;

    constructor({
        id,
        ingestSlotId,
        created,
        started,
        finished,
        mediaState,
        by,
        comment,
        orderId,
        projectsId,
        project,
        ticketId,
    }: {
        id: number;
        ingestSlotId: number;
        created: string;
        started: string;
        finished: string | null;
        mediaState: MediaState;
        by: string;
        comment: string | null;
        orderId: number | null;
        projectsId: number | null;
        project: Project | null;
        ticketId: string | null;
    }) {
        this.id = id;
        this.ingestSlotId = ingestSlotId;
        this.created = created;
        this.started = started;
        this.finished = finished;
        this.mediaState = mediaState;
        this.by = by;
        this.comment = comment;
        this.orderId = orderId;
        this.projectsId = projectsId;
        this.project = project;
        this.ticketId = ticketId;
    }

    private toObject() {
        return {
            id: this.id,
            ingestSlotId: this.ingestSlotId,
            created: this.created,
            started: this.started,
            finished: this.finished,
            mediaState: this.mediaState,
            by: this.by,
            comment: this.comment,
            orderId: this.orderId,
            projectsId: this.projectsId,
            project: this.project,
            ticketId: this.ticketId,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): IngestSlotBooking | null {
        if (json == null) {
            return null;
        }

        return new IngestSlotBooking({
            id: json["id"],
            ingestSlotId: json["ingestSlotId"],
            created: json["created"],
            started: json["started"],
            finished: json["finished"],
            mediaState: json["mediaState"],
            by: json["by"],
            comment: json["comment"],
            orderId: json["orderId"],
            projectsId: json["projectsId"],
            project: Project.fromSerialized(json["project"]),
            ticketId: json["ticketId"],
        });
    }
}
