import React, { useContext, useEffect, useState } from "react";
import { GroupuiIcon, GroupuiText, GroupuiTooltip } from "@group-ui/group-ui-react";
import useTitle from "../../hooks/useTitle";
import GenericHeadline from "../generics/GenericHeadline";
import IngestSlotMonitor from "./IngestSlotMonitor";
import MonitoringWidget from "../monitoringWidget/MonitoringWidget";
import { RightsWrapper } from "../container/RightsWrapper";
import { RightsManagement } from "../../config/rightsManagement";
import UserGroupContext from "../context/UserGroupContext";
import SusHubMonitor from "./susMonitor/SusHubMonitor";

/**
 * Returns an Dashboard Component, which holds information about running uploads with the connected ingest-slots and databoxes
 * depending on the ingest station
 *
 * @returns Dashboard Component
 *
 */
function Dashboard() {
    useTitle("Dashboard");
    const { userGroups } = useContext(UserGroupContext);
    const [lastUpdated, setLastUpdated] = useState<string>("");
    const [refresh, setRefresh] = useState<boolean>();

    const styles = {
        content: {
            padding: "var(--groupui-sys-spacing-1200) var(--groupui-sys-spacing-1400)",
        },
        title: {
            display: "flex",
            flexDirection: "row" as const,
            justifyContent: "space-between",
            alignItems: "center",
        },
        icon: {
            cursor: "pointer",
            animation: refresh ? "spin-animation 0.5s" : "",
            display: "inline-block",
        },
    };

    useEffect(() => {
        updateTimestamp();
    }, []);

    const updateTimestamp = () => {
        const timestamp = Date.now();
        setLastUpdated(
            "Last Update: " +
                new Intl.DateTimeFormat("de-DE", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                }).format(timestamp)
        );
        setTimeout(() => {
            setRefresh(false);
        }, 1000);
    };

    return (
        <div style={styles.content}>
            <div style={styles.title}>
                <GenericHeadline
                    title={"Dashboard"}
                    titleHeadingType="h2"
                    titleWeight="bold"
                    titleSerif={false}
                    actionButtons={[]}
                />
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "var(--groupui-sys-spacing-200)",
                    }}
                >
                    <GroupuiText
                        size="body-2"
                        weight="light"
                        marketing={false}
                        style={{ color: "var(--groupui-sys-color-text-weakest" }}
                    >
                        {lastUpdated}
                    </GroupuiText>
                    <GroupuiTooltip position="top" zIndex={100}>
                        <GroupuiIcon
                            name={"update-24"}
                            style={styles.icon}
                            onClick={() => {
                                setRefresh(true);
                                updateTimestamp();
                            }}
                        />
                        <div slot="tooltip">Refresh</div>
                    </GroupuiTooltip>
                </div>
            </div>
            <div>
                <SusHubMonitor lastUpdated={lastUpdated} />
            </div>
            <div>
                <IngestSlotMonitor lastUpdated={lastUpdated} />
            </div>
            <RightsWrapper
                authorizedUserGroup={RightsManagement.DATABOX_MONITOR}
                userGroups={userGroups}
            >
                <div>
                    <MonitoringWidget lastUpdated={lastUpdated} />
                </div>
            </RightsWrapper>
        </div>
    );
}

export default Dashboard;
