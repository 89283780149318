import { Address } from "./address";

export class UpsPickup {
    private _id;
    public pickupDate;
    public dispatchNumber;
    public created;
    public updated;
    public address;

    constructor({
        id,
        pickupDate,
        dispatchNumber,
        created,
        updated,
        address,
    }: {
        id?: number;
        pickupDate: string;
        dispatchNumber?: string;
        created: string;
        updated?: string;
        address: Address | null;
    }) {
        this._id = id;
        this.pickupDate = pickupDate;
        this.dispatchNumber = dispatchNumber;
        this.created = created;
        this.updated = updated;
        this.address = address;
    }

    public get id() {
        return this._id;
    }

    private toObject() {
        return {
            id: this.id,
            pickupDate: this.pickupDate,
            dispatchNumber: this.dispatchNumber,
            created: this.created,
            updated: this.updated,
            address: this.address,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): UpsPickup | null {
        if (json == null) {
            return null;
        }

        return new UpsPickup({
            id: json["id"],
            pickupDate: json["pickupDate"],
            dispatchNumber: json["dispatchNumber"],
            created: json["created"],
            updated: json["updated"],
            address: Address.fromSerialized(json["address"]),
        });
    }
}
