import { MediaState } from "./mediaState";
import { Tracker } from "./tracker";

export class DataboxDashboardOverview {
    public id;
    public name;
    public storageCapacity;
    public mediaState;
    public ingestStationId;
    public owner;
    public tracker;

    constructor({
        id,
        name,
        storageCapacity,
        mediaState,
        ingestStationId,
        owner,
        tracker,
    }: {
        id: number;
        name: string;
        storageCapacity: number;
        mediaState: MediaState;
        ingestStationId: number;
        owner: string;
        tracker: string;
    }) {
        this.id = id;
        this.name = name;
        this.storageCapacity = storageCapacity;
        this.mediaState = mediaState;
        this.ingestStationId = ingestStationId;
        this.owner = owner;
        this.tracker = tracker;
    }

    private toObject() {
        return {
            id: this.id,
            name: this.name,
            storageCapacity: this.storageCapacity,
            mediaState: this.mediaState,
            ingestStationId: this.ingestStationId,
            owner: this.owner,
            tracker: this.tracker,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): DataboxDashboardOverview {
        if (json == null) {
            throw new Error(`Error parsing IngestStationOverview!`);
        }
        return new DataboxDashboardOverview({
            id: json["id"],
            name: json["name"],
            storageCapacity: json["storageCapacity"],
            mediaState: MediaState.fromSerialized(json["mediaState"]),
            ingestStationId: json["ingestStationId"],
            owner: json["owner"],
            tracker: json["tracker"],
        });
    }
}
