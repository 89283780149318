import React, { useContext } from "react";
import { GroupuiTab, GroupuiTabs } from "@group-ui/group-ui-react";
import GeneralInformationTab from "./GeneralInformationTab";
import IngestSlotsTab from "./IngestSlotsTab";
import GenericHeadline from "../generics/GenericHeadline";
import ContactTab from "./ContactTab";
import { IngestStationContext } from "../context/provider/IngestStationContext";

/**
 * Returns an IngestStationDetails Component, which defines the different tabs displayed on the ingest station details page
 *
 * @returns IngestStationDetails Component
 *
 */
const IngestStationDetails = () => {
    const { currentIngestStation } = useContext(IngestStationContext);

    return (
        <>
            <GenericHeadline
                title={
                    currentIngestStation
                        ? `Ingest Station Details - ${currentIngestStation?.name}`
                        : `Ingest Station Details`
                }
                actionButtons={[]}
            />
            <GroupuiTabs>
                <GroupuiTab tab="firstTabId" name="General">
                    <GeneralInformationTab />
                </GroupuiTab>
                <GroupuiTab tab="secondTabId" name="Ingest Slots">
                    <IngestSlotsTab />
                </GroupuiTab>
                <GroupuiTab tab="thirdTabId" name="Contact">
                    <ContactTab />
                </GroupuiTab>
            </GroupuiTabs>
        </>
    );
};

export default IngestStationDetails;
