import React, { useEffect, useRef, ReactNode } from "react";

interface OutsideClickHandlerProps {
    children: ReactNode;
    onOutsideClick: () => void;
}

const OutsideClickHandler: React.FC<OutsideClickHandlerProps> = ({ children, onOutsideClick }) => {
    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event: Event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
                onOutsideClick();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("keydown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("keydown", handleClickOutside);
        };
    }, [wrapperRef, onOutsideClick]);

    return <div ref={wrapperRef}>{children}</div>;
};

export default OutsideClickHandler;
