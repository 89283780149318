export enum MediaStateType {
    FREE = "Free",
    RESERVED = "Reserved",
    IN_STOCK = "In Stock",
    UPLOADING = "Uploading",
    PRE_UPLOAD = "Pre-Upload",
    ERROR = "Error",
    UPLOAD = "Upload",
    MAINTENANCE = "Maintenance",
    DECOMMISSIONED = "Decommissioned",
    ALLIANCE = "Alliance",
    DELIVERY_PREPARATION = "Delivery Preparation",
    IN_TRANSIT_DELIVERY = "In Transit (Delivery)",
    IN_USE = "In Use",
    WAITING_FOR_PICKUP = "Waiting for Pickup",
    IN_TRANSIT_RETURN = "In Transit (Return)",
    PREPARATION_FOR_INGEST = "Preparation for Ingest",
    UPLOAD_COMPLETED = "Upload Completed",
    PREPARATION_FOR_NEXT_USE = "Preparation for next use",
    UPLOAD_CANCELLED = "Upload Cancelled",
    WAITING_FOR_INGEST_SLOT = "Waiting for Ingest Slot",
    PICKUP_NOT_SUCCESSFUL = "Pickup not successful",
    WAITING_FOR_DROP_OFF = "Waiting for drop off",
}
