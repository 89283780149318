import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { RightsWrapper } from "../../container/RightsWrapper";
import UserGroupContext from "../../context/UserGroupContext";
import { RightsManagement } from "../../../config/rightsManagement";
import IngestsStatistics from "../../report/ingestsStatistics/IngestsStatistics";

/**
 * A IngestsStatisticsRoutes Component, which defines the routes for IngestsStatistics component
 *
 * @returns IngestsStatisticsRoutes
 *
 */
const IngestsStatisticsRoutes = () => {
    const { userGroups } = useContext(UserGroupContext);
    return (
        <RightsWrapper
            key={"first"}
            authorizedUserGroup={RightsManagement.REPORT_ROUTES}
            userGroups={userGroups}
            section={true}
        >
            <Routes>
                <Route index element={<IngestsStatistics />} />
            </Routes>
        </RightsWrapper>
    );
};

export default IngestsStatisticsRoutes;
