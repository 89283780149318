/**
 *
 * @param bytes Number of uploaded bytes
 * @param from start time interval
 * @param to end time interval
 * @returns human readable string representation of upload speed in Xbit/s
 */

import { deltaInMilliseconds } from "./DateUtil";

export function getUploadSpeed(
    bytes: number,
    from: string | null,
    to: string | null,
    totalIngestTimeInSeconds: number | null = null
): number {
    if (bytes === 0) return 0;

    let seconds;
    if (totalIngestTimeInSeconds !== null) {
        seconds = totalIngestTimeInSeconds;
    } else if (from && to) {
        const milliseconds = deltaInMilliseconds(from, to);
        seconds = (milliseconds - (milliseconds % 1000)) / 1000;
    } else {
        return 0;
    }

    const bitsPerSecond = (bytes * 8) / seconds;
    return bitsPerSecond;
}

export function formatSpeed(bitsPerSecond: number): string {
    if (bitsPerSecond < 1000) return `${bitsPerSecond.toFixed(0)} bit/s`;

    const kilobitsPerSecond = bitsPerSecond / 1000;
    if (kilobitsPerSecond < 1000) return `${kilobitsPerSecond.toFixed(1)} Kbit/s`;

    const megabitsPerSecond = kilobitsPerSecond / 1000;
    if (megabitsPerSecond < 1000) return `${megabitsPerSecond.toFixed(1)} Mbit/s`;

    const gigabitsPerSecond = megabitsPerSecond / 1000;
    return `${gigabitsPerSecond.toFixed(2)} Gbit/s`;
}

/**
 * Formats bits in bytes, KB, MB, etc.
 * @param a Bits to format
 * @param b Number of fraction digits
 * @returns formated string
 */
export function formatBytes(a: number, b = 2): string {
    if (!+a) return "0 Bytes";
    const c = 0 > b ? 0 : b,
        d = Math.floor(Math.log(a) / Math.log(1000));
    return `${parseFloat((a / Math.pow(1000, d)).toFixed(c))} ${
        ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
    }`;
}
