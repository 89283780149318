import React, { useContext, useState } from "react";
import {
    GroupuiButton,
    GroupuiLoadingSpinner,
    GroupuiTab,
    GroupuiTabs,
} from "@group-ui/group-ui-react";
import { RightsWrapper } from "../../container/RightsWrapper";
import UserGroupContext from "../../context/UserGroupContext";
import GenericHeadline from "../../generics/GenericHeadline";
import GenericMoreButton from "../../generics/GenericMoreButton";
import { RightsManagement } from "../../../config/rightsManagement";
import { IngestSlotGeneralInformation } from "./IngestSlotGeneralInformation";
import { IngestSlotStatusInformation } from "./IngestSlotStatusInformation";
import { IngestSlotStateModal, IngestSlotStateMode } from "./modals/IngestSlotStateModal";
import { IngestStationContext } from "../../context/provider/IngestStationContext";

export const IngestSlotDetails = () => {
    const { userGroups } = useContext(UserGroupContext);
    const { isLoading, currentIngestSlot, loadData } = useContext(IngestStationContext);

    const [activeTabId, setActiveTabId] = useState("generalInformationId");
    const [dropDownState, setDropDownState] = useState(false);
    const [addStateModal, setAddStateModal] = useState(false);

    const handleModalClose = () => {
        setAddStateModal(false);
        setDropDownState(false);
        loadData();
        setActiveTabId("statusInformationId");
    };

    return (
        <>
            <GroupuiLoadingSpinner displayed={isLoading} />
            <RightsWrapper
                authorizedUserGroup={RightsManagement.ADD_INGEST_SLOT_STATE}
                userGroups={userGroups}
            >
                <IngestSlotStateModal
                    showModal={addStateModal}
                    setShowModal={setAddStateModal}
                    mode={IngestSlotStateMode.ADD}
                    onModalClose={handleModalClose}
                />
            </RightsWrapper>
            <GenericHeadline
                title={
                    currentIngestSlot
                        ? `Ingest Slot Details - ${currentIngestSlot?.name}`
                        : `Ingest Slot Details`
                }
                actionButtons={[
                    <RightsWrapper
                        key={"rights-first"}
                        authorizedUserGroup={RightsManagement.OPEN_MODAL_INGEST_SLOT_STATE}
                        userGroups={userGroups}
                    >
                        <GenericMoreButton
                            key={"More-Button-1"}
                            dropDownState={dropDownState}
                            setDropDownState={() => {
                                setDropDownState(!dropDownState);
                            }}
                            options={[
                                <GroupuiButton
                                    key={"Option-1"}
                                    variant="primary"
                                    icon="add-32"
                                    fullwidth={true}
                                    alignment="left"
                                    onClick={() => {
                                        setAddStateModal(true);
                                        setDropDownState(false);
                                    }}
                                >
                                    Add State
                                </GroupuiButton>,
                            ]}
                        />
                    </RightsWrapper>,
                ]}
            />
            <GroupuiTabs
                activeTabId={activeTabId}
                onGroupuiTabChange={(event) => {
                    setActiveTabId(event.detail.tab);
                    setDropDownState(false);
                }}
            >
                <GroupuiTab tab="generalInformationId" name="General">
                    <IngestSlotGeneralInformation />
                </GroupuiTab>
                <GroupuiTab tab="statusInformationId" name="Status">
                    <IngestSlotStatusInformation
                        setActiveTabId={setActiveTabId}
                        refreshIngestSlot={handleModalClose}
                    />
                </GroupuiTab>
            </GroupuiTabs>
        </>
    );
};
