import React, { useState } from "react";
import { GroupuiIcon } from "@group-ui/group-ui-react";
import { NonStandardRequest } from "../../models/nonStandardRequest";
import GenericHeadline from "../generics/GenericHeadline";
import GenericInput from "../generics/GenericInput";
import { parseDate, parseDateOnly, parseFullDate } from "../../utils/DateUtil";

interface GeneralInformationProps {
    nonStandardRequest: NonStandardRequest | undefined;
}

export const GeneralInformation: React.FC<GeneralInformationProps> = ({ nonStandardRequest }) => {
    const [disabled, setDisabled] = useState<boolean>(true);
    return (
        <div>
            <GenericHeadline
                title={"General Information"}
                titleHeadingType="h4"
                actionButtons={[]}
            />
            <div style={{ display: "flex", gap: 40 }}>
                <div style={{ width: "50%" }}>
                    <GenericInput
                        label={"Request ID"}
                        disabled={true}
                        value={"NSR" + nonStandardRequest?.ID}
                    />
                    <GenericInput
                        label={"Request Type"}
                        disabled={disabled}
                        value={nonStandardRequest?.type}
                    />
                    <GenericInput
                        label={"Databox Name"}
                        disabled={disabled}
                        value={nonStandardRequest?.databox.name}
                    />
                    <GenericInput
                        label={"Current State"}
                        disabled={disabled}
                        value={nonStandardRequest?.mediaState.mediaStateType}
                    />
                    <GenericInput
                        label={"Jira Ticket ID"}
                        disabled={disabled}
                        value={nonStandardRequest?.jiraTicketId}
                    />
                    <GenericInput
                        label={"Delivery Provider"}
                        disabled={disabled}
                        value={nonStandardRequest?.databox.ingestStation?.deliveryProvider ?? "UPS"}
                    />
                </div>
                <div style={{ width: "50%" }}>
                    {nonStandardRequest?.type.toLowerCase().trim() === "shipment" && (
                        <div>
                            <GenericInput
                                label={"Tracking Number"}
                                disabled={disabled}
                                value={nonStandardRequest?.upsShipmentLabel?.trackingNumber}
                            />
                            <div style={{ position: "relative" }}>
                                <GenericInput
                                    label={"Delivery Label"}
                                    value={
                                        nonStandardRequest?.upsShipmentLabel
                                            ? `delivery_label_${nonStandardRequest.upsShipmentLabel.trackingNumber}.png`
                                            : ""
                                    }
                                    disabled={true}
                                />
                                {nonStandardRequest?.upsShipmentLabel && (
                                    <div style={{ position: "absolute", right: 8, bottom: 8 }}>
                                        <GroupuiIcon
                                            name="download-32"
                                            onClick={() => {
                                                const base64png =
                                                    nonStandardRequest.upsShipmentLabel?.label;
                                                if (base64png === undefined) {
                                                    return;
                                                }
                                                const a = document.createElement("a");
                                                a.href =
                                                    "data:image/png;base64," +
                                                    nonStandardRequest.upsShipmentLabel?.label;
                                                a.download = `return_label_${nonStandardRequest.upsShipmentLabel?.shipmentIdenfificationNumber}.png`;
                                                a.click();
                                            }}
                                            style={{ cursor: "pointer" }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {nonStandardRequest?.type.toLowerCase().trim() === "pickup" && (
                        <div>
                            <GenericInput
                                label={"Dispatch Number"}
                                disabled={disabled}
                                value={nonStandardRequest.upsPickup?.dispatchNumber}
                            />
                            <GenericInput
                                label={"Requested Pickup Date"}
                                disabled={disabled}
                                value={parseDateOnly(nonStandardRequest.upsPickup?.pickupDate)}
                            />
                        </div>
                    )}
                    <GenericInput
                        label={"Requestor"}
                        disabled={disabled}
                        value={nonStandardRequest?.requester}
                    />
                    <GenericInput
                        label={"Created"}
                        disabled={disabled}
                        value={parseFullDate(nonStandardRequest?.created)}
                    />
                    <GenericInput
                        label={"Last updated"}
                        disabled={disabled}
                        value={
                            nonStandardRequest?.lastUpdated
                                ? parseFullDate(nonStandardRequest.lastUpdated)
                                : "Not updated yet."
                        }
                    />
                </div>
            </div>
        </div>
    );
};
