import React, { useEffect, useMemo, useState } from "react";
import {
    GroupuiButton,
    GroupuiLoadingSpinner,
    GroupuiText,
    GroupuiSwitch,
    GroupuiSelect,
    GroupuiSelectOption,
} from "@group-ui/group-ui-react";
import GenericHeadline from "../../generics/GenericHeadline";
import GenericTable, { columnDefinition } from "../../generics/GenericTable";
import { IngestsStatisticsData } from "../../../models/IngestsStatisticsData";
import { ApiService } from "../../../services/apiService";
import GenericFilterBar from "../../generics/GenericFilter/GenericFilterBar";
import { formatBytes } from "../../../utils/DataVolumeUtil";
import { CSVLink } from "react-csv";
import IngestsStatisticsGraphView from "./IngestsStatisticsGraphView";
import GenericMoreButton from "../../generics/GenericMoreButton";

const columns: columnDefinition[] = [
    {
        key: "ingestLocation",
        header: "Ingest Location",
        width: "auto",
    },
    {
        key: "date",
        header: "Date",
        width: "auto",
    },
    {
        key: "ingestProceeded",
        header: "Ingest Proceeded",
        width: "auto",
    },
    {
        key: "volumeUploaded",
        header: "Volume Uploaded",
        width: "auto",
    },
];

interface Filters {
    startDate: string;
    endDate: string;
    ingestLocation: string;
}

const formatDateRange = (startDate: string, endDate: string): string => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const options: Intl.DateTimeFormatOptions = {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
    };
    return `${start.toLocaleDateString("en-GB", options)} - ${end.toLocaleDateString(
        "en-GB",
        options
    )}`;
};

const IngestsStatistics: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [dropDownState, setDropDownState] = useState(false);
    const [statisticsData, setStatisticsData] = useState<IngestsStatisticsData[]>([]);
    const [filters, setFilters] = useState<Filters>({
        startDate: "",
        endDate: "",
        ingestLocation: "",
    });
    const [graphViewEnabled, setGraphViewEnabled] = useState(false);
    const [selectedGraphType, setSelectedGraphType] = useState("ingests-proceeded");
    const [noData, setNoData] = useState(false);

    const fetchStatistics = async (filters: Filters) => {
        setIsLoading(true);
        try {
            const data = graphViewEnabled
                ? await ApiService.getAllIngestsStatisticsByTimeRange(
                      filters.startDate,
                      filters.endDate
                  )
                : await ApiService.getIngestsStatisticsByTimeRangeAndLocation(
                      filters.startDate,
                      filters.endDate,
                      filters.ingestLocation
                  );

            const filteredData = data.filter(
                (entry: any) => entry !== null && entry.ingestLocation
            );

            const validData = filteredData
                .map((entry: any) => {
                    try {
                        return IngestsStatisticsData.fromSerialized(entry);
                    } catch (error) {
                        console.error("Error parsing IngestsStatisticsData:", error, entry);
                        return null;
                    }
                })
                .filter(
                    (entry: IngestsStatisticsData | null): entry is IngestsStatisticsData =>
                        entry !== null
                );

            setStatisticsData(validData);
            setNoData(validData.length === 0);
        } catch (error) {
            console.error("Error fetching statistics data:", error);
            setNoData(true);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (filters.startDate && filters.endDate && (filters.ingestLocation || graphViewEnabled)) {
            fetchStatistics(filters);
        }
    }, [filters, graphViewEnabled]);

    const statisticsForTable = useMemo(
        () =>
            statisticsData.map((entry) => ({
                ...entry,
                date: formatDateRange(entry.fromDate, entry.toDate),
                volumeUploaded: formatBytes(entry.volumeUploaded),
            })),
        [statisticsData]
    );

    const statisticsForGraph = useMemo(
        () =>
            statisticsData.map((entry) => ({
                rawDate: entry.fromDate,
                ingestProceeded: entry.ingestProceeded,
                volumeUploaded: entry.volumeUploaded,
                location: entry.ingestLocation,
                fromDate: entry.fromDate,
                toDate: entry.toDate,
                totalIngestTimeInSeconds: entry.totalIngestTimeInSeconds,
                month: entry.month,
            })),
        [statisticsData]
    );

    const handleReset = () => {
        setFilters({
            startDate: "",
            endDate: "",
            ingestLocation: "",
        });
        setStatisticsData([]);
        setNoData(false);
    };

    return (
        <div style={{ padding: "3rem 3.5rem", scrollBehavior: "smooth", whiteSpace: "pre-wrap" }}>
            <GroupuiLoadingSpinner displayed={isLoading} />
            <GenericHeadline
                title="Ingests Statistics"
                actionButtons={
                    !graphViewEnabled
                        ? [
                              <GenericMoreButton
                                  key={"More-Button-1"}
                                  dropDownState={dropDownState}
                                  setDropDownState={() => {
                                      setDropDownState(!dropDownState);
                                  }}
                                  options={[
                                      statisticsForTable.length > 0 ? (
                                          <CSVLink
                                              key={"CSV-Link-1"}
                                              data={statisticsForTable}
                                              separator={";"}
                                              filename={`Ingests_Statistics_${
                                                  filters.ingestLocation
                                              }_${formatDateRange(
                                                  filters.startDate,
                                                  filters.endDate
                                              )}.csv`}
                                          >
                                              <GroupuiButton
                                                  key={"Option-1-1"}
                                                  variant="tertiary"
                                                  icon="download-32"
                                                  fullwidth={true}
                                                  alignment="left"
                                                  onClick={() => {
                                                      setDropDownState(false);
                                                  }}
                                              >
                                                  Download {"Ingests Statistics"}
                                              </GroupuiButton>
                                          </CSVLink>
                                      ) : (
                                          <GroupuiButton
                                              key={"Option-1-2"}
                                              variant="tertiary"
                                              icon="download-32"
                                              fullwidth={true}
                                              alignment="left"
                                              disabled={true}
                                          >
                                              Download {"Ingests Statistics"}
                                          </GroupuiButton>
                                      ),
                                  ]}
                              />,
                          ]
                        : []
                }
            />
            <GenericFilterBar
                filterColumns={[
                    {
                        column: "ingestLocation",
                        displayName: "Ingests Locations",
                        doSort: "predefined",
                        predefinedList: ["AM5", "SE3", "BER"],
                        useOnlyPredefinedList: true,
                        isSingleSelect: true,
                        hidden: graphViewEnabled,
                    },
                    {
                        column: "startDate",
                        displayName: "From",
                        isDatePicker: true,
                    },
                    {
                        column: "endDate",
                        displayName: "To",
                        isDatePicker: true,
                    },
                ]}
                filters={filters}
                setFilters={setFilters}
                data={statisticsData || []}
                showResetButton={true}
                onReset={handleReset}
            >
                <>
                    {graphViewEnabled && (
                        <GroupuiSelect
                            style={{ width: "auto", minWidth: "150px", marginLeft: "10px" }}
                            value={selectedGraphType}
                            onGroupuiChange={(e) => setSelectedGraphType(e.target.value)}
                        >
                            <GroupuiSelectOption value="ingests-proceeded">
                                Ingests Proceeded
                            </GroupuiSelectOption>
                            <GroupuiSelectOption value="volume-uploaded">
                                Volume Uploaded
                            </GroupuiSelectOption>
                            <GroupuiSelectOption value="average-bandwidth">
                                Average Bandwidth
                            </GroupuiSelectOption>
                        </GroupuiSelect>
                    )}
                    <GroupuiSwitch
                        style={{ marginLeft: "10px" }}
                        value={graphViewEnabled ? "on" : "off"}
                        onGroupuiChange={() => setGraphViewEnabled(!graphViewEnabled)}
                        labelPosition="right"
                    >
                        Graph View
                    </GroupuiSwitch>
                </>
            </GenericFilterBar>

            {graphViewEnabled ? (
                statisticsForGraph.length > 0 ? (
                    <IngestsStatisticsGraphView
                        graphType={selectedGraphType}
                        statisticsData={statisticsForGraph}
                    />
                ) : (
                    <GroupuiText
                        weight="bold"
                        style={{
                            textAlign: "center",
                            color: "var(--groupui-vwag-color-grey-600)",
                            fontSize: "1rem",
                            margin: "1.5rem",
                            justifyContent: "center",
                        }}
                    >
                        No ingests in this time period. Please select another time range.
                    </GroupuiText>
                )
            ) : filters.ingestLocation ? (
                statisticsForTable.length > 0 ? (
                    <GenericTable
                        columns={columns}
                        data={statisticsForTable}
                        header={true}
                        pageSize={20}
                    />
                ) : (
                    <GroupuiText
                        weight="bold"
                        style={{
                            textAlign: "center",
                            color: "var(--groupui-vwag-color-grey-600)",
                            fontSize: "1rem",
                            margin: "1.5rem",
                            justifyContent: "center",
                        }}
                    >
                        No ingests in this location for this time period:{" "}
                        {formatDateRange(filters.startDate, filters.endDate)}
                    </GroupuiText>
                )
            ) : (
                <GroupuiText
                    weight="bold"
                    style={{
                        textAlign: "center",
                        color: "var(--groupui-vwag-color-grey-600)",
                        fontSize: "1rem",
                        margin: "1.5rem",
                        justifyContent: "center",
                    }}
                >
                    Please select a location to display data.
                </GroupuiText>
            )}
        </div>
    );
};

export default IngestsStatistics;
