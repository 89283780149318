export class Company {
    public id;
    public company;

    constructor({ id, company }: { id: number; company: string }) {
        this.id = id;
        this.company = company;
    }

    private toObject() {
        return {
            id: this.id,
            company: this.company,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): Company {
        if (json == null) {
            throw new Error(`Error parsing Company!`);
        }

        return new Company({
            id: json["id"],
            company: json["company"],
        });
    }
}
