import { GroupuiModal, GroupuiHeadline, GroupuiText } from "@group-ui/group-ui-react";
import React from "react";
import { parseDate } from "../../utils/DateUtil";
import { IngestSlotMonitorData } from "../../models/ingestSlotMonitorData";
interface Props {
    showDetails: boolean;
    onClose: () => void;
    ingestSlotMonitorData: IngestSlotMonitorData;
}

const BookingDetails: React.FC<Props> = ({ showDetails, onClose, ingestSlotMonitorData }) => {
    function renderSwitch(e: IngestSlotMonitorData) {
        switch (e.state.toLowerCase()) {
            case "reserved":
                return ` ${e.name} is reserved ${
                    e.bookingStarted != null ? " from " + parseDate(new Date(e.bookingStarted)) : ""
                } ${
                    e.bookingFinished != null ? "to " + parseDate(new Date(e.bookingFinished)) : ""
                } ${e.reservedBy != null ? "by " + e.reservedBy : ""}${
                    e.projectName != null ? " for the project " + e.projectName : ""
                }.${
                    e.orderId != null && e.orderId != undefined
                        ? " The related order is OR" + e.orderId + "."
                        : ""
                }`;
            case "defective":
                return `${e.name} was marked as defective from ${parseDate(
                    new Date(e.ingestStart)
                )} ${
                    e.bookingFinished != null ? "to " + parseDate(new Date(e.bookingFinished)) : ""
                } by ${e.reservedBy}.`;
            case "maintenance":
                return `${e.name} is in maintenance from ${parseDate(new Date(e.ingestStart))} ${
                    e.bookingFinished != null ? "to " + parseDate(new Date(e.bookingFinished)) : ""
                } by ${e.reservedBy}. ${
                    e.ticketId != null ? "The related ticket ID is " + e.ticketId + "." : ""
                } `;

            case "decommissioned":
                return `${e.name} was marked as decommissioned from ${parseDate(
                    new Date(e.ingestStart)
                )} ${
                    e.bookingFinished != null ? "to " + parseDate(new Date(e.bookingFinished)) : ""
                } by ${e.reservedBy}.`;
        }
    }

    return (
        <GroupuiModal
            closeButton={true}
            padding="40px"
            displayed={showDetails}
            onGroupuiModalClose={onClose}
            style={{ justifyContent: "center", alignItems: "center" }}
        >
            <GroupuiHeadline
                heading="h3"
                style={{ color: "var(--groupui-vwag-color-petrol-800-primary)" }}
            >
                Status Details
            </GroupuiHeadline>
            <GroupuiText>{renderSwitch(ingestSlotMonitorData)}</GroupuiText>
        </GroupuiModal>
    );
};

export default BookingDetails;
