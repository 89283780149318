import { GroupuiButton, GroupuiLoadingSpinner, GroupuiModal } from "@group-ui/group-ui-react";
import React, { Dispatch, useContext, useState } from "react";
import GenericHeadline from "../../generics/GenericHeadline";
import GenericInput from "../../generics/GenericInput";
import { ApiService } from "../../../services/apiService";
import { handleError } from "../../context/ErrorContextUtils";
import ErrorContext from "../../context/ErrorContext";
import { useNavigate, useLocation } from "react-router-dom";
interface Props {
    enabled: boolean;
    setEnabled: Dispatch<boolean>;
}

const ReturnOtherDataboxModal: React.FC<Props> = ({ enabled, setEnabled }) => {
    const [databoxName, setDataboxName] = useState<string>("");
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const [loading, setLoading] = useState<boolean>(false);

    const navigate = useNavigate();

    const sendRequest = () => {
        setLoading(true);
        ApiService.getOrderNumberByDataboxName(databoxName)
            .then((response) => {
                setLoading(false);
                setEnabled(false);
                navigate(`/my-databoxes/return-other-databox/${response}/pick-up-details`);
            })
            .catch((error) => {
                setLoading(false);
                handleError(error, changeErrorMessage, changeShowError, true);
            });
    };

    return (
        <GroupuiModal
            closeButton={true}
            displayed={enabled}
            padding="var(--groupui-sys-spacing-600)"
            margin="200px"
            overflow={"visible"}
            onGroupuiModalClose={() => setEnabled(false)}
        >
            <GroupuiLoadingSpinner displayed={loading} />
            <GenericHeadline
                title="Return Databox of another User"
                titleHeadingType="h4"
                actionButtons={[]}
                type="modal"
            />
            <GenericInput
                value={databoxName}
                type="text"
                label="Databox Name*"
                required={true}
                onGroupuiChange={(event: any) => setDataboxName(event.target.value)}
            />
            <div
                style={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "var(--groupui-sys-spacing-400)",
                    marginTop: "var(--groupui-sys-spacing-600)",
                }}
            >
                <GroupuiButton variant={"secondary"} onClick={() => setEnabled(false)}>
                    Cancel
                </GroupuiButton>
                <GroupuiButton icon="check-32" disabled={databoxName === ""} onClick={sendRequest}>
                    Confirm
                </GroupuiButton>
            </div>
        </GroupuiModal>
    );
};

export default ReturnOtherDataboxModal;
