import React, { useContext } from "react";
import { GroupuiBreadcrumb, GroupuiBreadcrumbs } from "@group-ui/group-ui-react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { InCarMountContext } from "../context/provider/InCarMountContext";

const styles = {
    contentContainer: {
        padding: "var(--groupui-sys-spacing-1200) var(--groupui-sys-spacing-1400)",
        scrollBehavior: "smooth",
        whiteSpace: "pre-wrap",
    } as React.CSSProperties,
};

/**
 * A DataboxDetailsWrapper Component, which positions the Breadcrumbs on the detail view of each databox
 *
 * @returns DataboxDetailsWrapper Component
 *
 */
function InCarMountDetailsWrapper() {
    const navigate = useNavigate();
    const location = useLocation();
    const { currentInCarMount } = useContext(InCarMountContext);

    return (
        <div style={styles.contentContainer}>
            <GroupuiBreadcrumbs>
                <GroupuiBreadcrumb onClick={() => navigate("/in-car-mounts")}>
                    In Car Mount
                </GroupuiBreadcrumb>
                <GroupuiBreadcrumb
                    onClick={() =>
                        navigate(location.pathname.substring(0, location.pathname.lastIndexOf("/")))
                    }
                >
                    {currentInCarMount
                        ? `In Car Mount Details - ${currentInCarMount.name}`
                        : `In Car Mount Details`}
                </GroupuiBreadcrumb>
                {/* Displays a additional Breadcrumb if the user matches the path of the following Route */}
            </GroupuiBreadcrumbs>
            {/* Needed to display the content given by the Nested Route */}
            <Outlet />
        </div>
    );
}

export default InCarMountDetailsWrapper;
