export class IngestStationOverview {
    public id;
    public name;
    public totalIngestSlots;
    public freeIngestSlots;
    public inUseIngestSlots;
    public databoxesInQueue;

    constructor({
        id,
        name,
        totalIngestSlots,
        freeIngestSlots,
        inUseIngestSlots,
        databoxesInQueue,
    }: {
        id: number;
        name: string;
        totalIngestSlots: number;
        freeIngestSlots: number;
        inUseIngestSlots: number;
        databoxesInQueue: number;
    }) {
        this.id = id;
        this.name = name;
        this.totalIngestSlots = totalIngestSlots;
        this.freeIngestSlots = freeIngestSlots;
        this.inUseIngestSlots = inUseIngestSlots;
        this.databoxesInQueue = databoxesInQueue;
    }

    private toObject() {
        return {
            id: this.id,
            name: this.name,
            totalIngestSlots: this.totalIngestSlots,
            freeIngestSlots: this.freeIngestSlots,
            inUseIngestSlots: this.inUseIngestSlots,
            databoxesInQueue: this.databoxesInQueue,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): IngestStationOverview {
        if (json == null) {
            throw new Error(`Error parsing IngestStationOverview!`);
        }
        return new IngestStationOverview({
            id: json["id"],
            name: json["name"],
            totalIngestSlots: json["totalIngestSlots"],
            freeIngestSlots: json["freeIngestSlots"],
            inUseIngestSlots: json["inUseIngestSlots"],
            databoxesInQueue: json["databoxesInQueue"],
        });
    }
}
