import { Databox } from "../databox";
import { PackageDropOff } from "./packageDropOff";

export class PackageDropOffResponse {
    public error;
    public packageDropOff;

    constructor({
        error,
        packageDropOff,
    }: {
        error: string;
        packageDropOff: PackageDropOff | null;
    }) {
        this.error = error;
        this.packageDropOff = packageDropOff;
    }

    private toObject() {
        return {
            error: this.error,
            packageDropOff: this.packageDropOff,
        };
    }

    static fromSerialized(json: object): PackageDropOffResponse | null {
        if (json == null) {
            return null;
        }

        return new PackageDropOffResponse({
            error: json["error"],
            packageDropOff: PackageDropOff.fromSerialized(json["packageDropOff"]),
        });
    }
}
