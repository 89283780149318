const TITLE = "Data Transport Portal";

/**
 * React hook to define a title displayed in the browser tab
 *
 * @param string
 * @returns A title as string
 */
export default function useTitle(string?: string) {
    const title = string ? `${string} - ${TITLE}` : TITLE;
    document.title = title;
    return title;
}
