export class DataboxesStatusReport {
    public databoxId;
    public databoxName;
    public databoxSerialNumber;
    public storageCapacity;
    public databoxOwner;
    public orderId;
    public databoxStatus;
    // public lastStatusUpdate;
    public tracker;
    public battery;
    public lastTrackerUpdate;
    public latitude;
    public longitude;
    public ingestLocation;
    public destination;
    public requestor;
    public lastComment;
    public internalProject;
    public currentHolder;
    public purchaseOrderNumber;
    public purchaseOrderDate;
    public opxNumber;
    public serviceContractId;
    public serviceStartDate;
    public serviceEndDate;

    constructor({
        databoxId,
        databoxName,
        databoxSerialNumber,
        storageCapacity,
        databoxOwner,
        orderId,
        databoxStatus,
        // lastStatusUpdate,
        tracker,
        battery,
        lastTrackerUpdate,
        latitude,
        longitude,
        ingestLocation,
        destination,
        requestor,
        lastComment,
        internalProject,
        currentHolder,
        purchaseOrderNumber,
        purchaseOrderDate,
        opxNumber,
        serviceContractId,
        serviceStartDate,
        serviceEndDate,
    }: {
        databoxId: number;
        databoxName: string;
        databoxSerialNumber: string;
        storageCapacity: number;
        databoxOwner: string;
        orderId: number;
        databoxStatus: string;
        // lastStatusUpdate: string,
        tracker: string;
        battery: number;
        lastTrackerUpdate: string;
        latitude: number;
        longitude: number;
        ingestLocation: string;
        destination: string;
        requestor: string;
        lastComment: string;
        internalProject: string;
        currentHolder: string;
        purchaseOrderNumber: string;
        purchaseOrderDate: string;
        opxNumber: string;
        serviceContractId: string;
        serviceStartDate: string;
        serviceEndDate: string;
    }) {
        this.databoxId = databoxId;
        this.databoxName = databoxName;
        this.databoxSerialNumber = databoxSerialNumber;
        this.storageCapacity = storageCapacity;
        this.databoxOwner = databoxOwner;
        this.orderId = orderId;
        this.databoxStatus = databoxStatus;
        // this.lastStatusUpdate = lastStatusUpdate;
        this.tracker = tracker;
        this.battery = battery;
        this.lastTrackerUpdate = lastTrackerUpdate;
        this.latitude = latitude;
        this.longitude = longitude;
        this.ingestLocation = ingestLocation;
        this.destination = destination;
        this.requestor = requestor;
        this.lastComment = lastComment;
        this.internalProject = internalProject;
        this.currentHolder = currentHolder;
        this.purchaseOrderNumber = purchaseOrderNumber;
        this.purchaseOrderDate = purchaseOrderDate;
        this.opxNumber = opxNumber;
        this.serviceContractId = serviceContractId;
        this.serviceStartDate = serviceStartDate;
        this.serviceEndDate = serviceEndDate;
    }

    public get storageCapacityDisplayName() {
        return this.storageCapacity + " TB";
    }

    private toObject() {
        return {
            databoxId: this.databoxId,
            databoxName: this.databoxName,
            databoxSerialNumber: this.databoxSerialNumber,
            storageCapacity: this.storageCapacity,
            databoxOwner: this.databoxOwner,
            orderId: this.orderId,
            databoxStatus: this.databoxStatus,
            // lastStatusUpdate: this.lastStatusUpdate,
            tracker: this.tracker,
            battery: this.battery,
            lastTrackerUpdate: this.lastTrackerUpdate,
            latitude: this.latitude,
            longitude: this.longitude,
            ingestLocation: this.ingestLocation,
            destination: this.destination,
            requestor: this.requestor,
            lastComment: this.lastComment,
            internalProject: this.internalProject,
            currentHolder: this.currentHolder,
            purchaseOrderNumber: this.purchaseOrderNumber,
            purchaseOrderDate: this.purchaseOrderDate,
            opxNumber: this.opxNumber,
            serviceContractId: this.serviceContractId,
            serviceStartDate: this.serviceStartDate,
            serviceEndDate: this.serviceEndDate,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): DataboxesStatusReport | null {
        if (json == null) {
            return null;
        }

        return new DataboxesStatusReport({
            databoxId: json["databoxId"],
            databoxName: json["databoxName"],
            databoxSerialNumber: json["databoxSerialNumber"],
            storageCapacity: json["storageCapacity"],
            databoxOwner: json["databoxOwner"],
            orderId: json["orderId"],
            databoxStatus: json["databoxStatus"],
            // lastStatusUpdate: json["lastStatusUpdate"],
            tracker: json["tracker"],
            battery: json["battery"],
            lastTrackerUpdate: json["lastTrackerUpdate"],
            latitude: json["latitude"],
            longitude: json["longitude"],
            ingestLocation: json["ingestLocation"],
            destination: json["destination"],
            requestor: json["requestor"],
            lastComment: json["lastComment"],
            internalProject: json["internalProject"],
            currentHolder: json["currentHolder"],
            purchaseOrderNumber: json["purchaseOrderNumber"],
            purchaseOrderDate: json["purchaseOrderDate"],
            opxNumber: json["opxNumber"],
            serviceContractId: json["serviceContractId"],
            serviceStartDate: json["serviceStartDate"],
            serviceEndDate: json["serviceEndDate"],
        });
    }
}
