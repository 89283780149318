import { GroupuiModal } from "@group-ui/group-ui-react";
import React from "react";
import GenericHeadline from "../generics/GenericHeadline";
import GenericInput from "../generics/GenericInput";
interface Props {
    showModal: boolean;
    setModal: (value: boolean) => void;
}

const ShowAboutModal: React.FC<Props> = ({ showModal, setModal }) => {
    return (
        <GroupuiModal
            displayed={showModal}
            onGroupuiModalClose={() => setModal(false)}
            overflow="visible"
            closeButton={true}
            margin="16rem"
            padding="var(--groupui-sys-spacing-800)"
        >
            <GenericHeadline
                title={"About"}
                titleHeadingType="h3"
                type="modal"
                actionButtons={[]}
            />
            <GenericInput label={`Version`} value={process.env.REACT_APP_VERSION} readOnly={true} />
        </GroupuiModal>
    );
};

export default ShowAboutModal;
