import { formatBytes } from "../utils/DataVolumeUtil";
import { Databox } from "./databox";
import { IngestSlot } from "./ingestSlot/ingestSlot";
import { IngestJob } from "./ingestJob";

// This is the ingest model for the aggregated Ingest entitiy coming fro portal.view_ingests
export class Ingest {
    private id;
    public databox;
    public ingestSlot;
    public uploadedBytes;
    public dataVolume;
    public uploadProgress;
    public uploadDuration;
    public totalDuration;
    public numberOfFiles;
    public error;
    public markedAborted;
    public ingestJob;
    public ingestType;

    public overwrittenSince;
    public overwriteState;
    public detailsMessage;

    public ingestStarted;
    public preUploadStarted;
    public uploadDataFileStarted;
    public uploadDataFileProgress;
    public uploadDataFileFinished;
    public ingestFinished;

    public ingestUuid;
    public discoveryId;
    public lastUpdatedRow;

    public numberOfChunks;
    public chunkNumber;
    public uploadedVolume;
    public numberOfUploadedFiles;
    public numberOfMeasurements;

    public lastMessageInserted;
    public ingestVersion;
    public messageUuid;
    public messageTimestamp;
    public uploadId;
    public csCallRetries;

    public dataId;
    public ingestAttempt;
    public ingestAttempts;

    constructor({
        id,
        databox,
        ingestSlot,
        uploadedBytes,
        dataVolume,
        uploadProgress,
        uploadDuration,
        totalDuration,
        numberOfFiles,
        error,
        markedAborted,
        ingestJob,
        overwrittenSince,
        overwriteState,
        detailsMessage,
        ingestType,

        ingestStarted,
        preUploadStarted,
        uploadDataFileStarted,
        uploadDataFileProgress,
        uploadDataFileFinished,
        ingestFinished,

        ingestUuid,
        discoveryId,
        lastUpdatedRow,

        numberOfChunks,
        chunkNumber,
        uploadedVolume,
        numberOfUploadedFiles,
        numberOfMeasurements,

        lastMessageInserted,
        ingestVersion,
        messageUuid,
        messageTimestamp,
        uploadId,
        csCallRetries,

        dataId,
        ingestAttempt,
        ingestAttempts,
    }: {
        id: number;
        databox: Databox | null;
        ingestSlot: IngestSlot | null;
        uploadedBytes: number;
        dataVolume: number;
        uploadProgress: number;
        uploadDuration: number;
        totalDuration: number;
        numberOfFiles: number;
        error: string;
        markedAborted: string;
        ingestJob: IngestJob | null;
        overwrittenSince: string;
        overwriteState: string;
        detailsMessage: string;
        ingestType: string;

        ingestStarted: string;
        preUploadStarted: string;
        uploadDataFileStarted: string;
        uploadDataFileProgress: string;
        uploadDataFileFinished: string;
        ingestFinished: string | null;

        ingestUuid: string;
        discoveryId: string;
        lastUpdatedRow: string;

        numberOfChunks: number;
        chunkNumber: number;
        uploadedVolume: number;
        numberOfUploadedFiles: number;
        numberOfMeasurements: number;

        lastMessageInserted: string;
        ingestVersion: string;
        messageUuid: string;
        messageTimestamp: string;
        uploadId: string;
        csCallRetries: number;

        dataId: string;
        ingestAttempt: number;
        ingestAttempts: number;
    }) {
        this.id = id;
        this.databox = databox;
        this.ingestSlot = ingestSlot;
        this.uploadedBytes = uploadedBytes;
        this.dataVolume = dataVolume;
        this.uploadProgress = uploadProgress;
        this.uploadDuration = uploadDuration;
        this.totalDuration = totalDuration;
        this.numberOfFiles = numberOfFiles;
        this.error = error;
        this.markedAborted = markedAborted;
        this.ingestJob = ingestJob;
        this.overwrittenSince = overwrittenSince;
        this.overwriteState = overwriteState;
        this.detailsMessage = detailsMessage;
        this.ingestType = ingestType;

        this.ingestStarted = ingestStarted;
        this.preUploadStarted = preUploadStarted;
        this.uploadDataFileStarted = uploadDataFileStarted;
        this.uploadDataFileProgress = uploadDataFileProgress;
        this.uploadDataFileFinished = uploadDataFileFinished;
        this.ingestFinished = ingestFinished;

        this.ingestUuid = ingestUuid;
        this.discoveryId = discoveryId;
        this.lastUpdatedRow = lastUpdatedRow;
        this.numberOfChunks = numberOfChunks;
        this.chunkNumber = chunkNumber;
        this.uploadedVolume = uploadedVolume;
        this.numberOfUploadedFiles = numberOfUploadedFiles;
        this.numberOfMeasurements = numberOfMeasurements;

        this.lastMessageInserted = lastMessageInserted;
        this.ingestVersion = ingestVersion;
        this.messageUuid = messageUuid;
        this.messageTimestamp = messageTimestamp;
        this.uploadId = uploadId;
        this.csCallRetries = csCallRetries;

        this.dataId = dataId;
        this.ingestAttempt = ingestAttempt;
        this.ingestAttempts = ingestAttempts;
    }

    public get ID() {
        return this.id;
    }

    public get currentMediaState() {
        return (this.ingestFinished && !this.error) || (this.markedAborted && this.ingestFinished)
            ? "Upload Completed"
            : this.markedAborted
            ? "Upload Cancelled"
            : this.error
            ? "Error"
            : this.databox?.mediaStateType.mediaStateType ?? "Databox not registered";
    }

    public get mapIngestType() {
        switch (this.ingestType) {
            case "default":
                return "CSI";
            case "NT":
                return "Alliance";
            case "LZ":
                return "Landing Zone";
            case "PU":
                return "Partial Upload";
            default:
                return this.ingestType ?? "";
        }
    }

    public get getIngestAttempts() {
        if (!this.ingestAttempt || !this.ingestAttempts) return "";
        return this.ingestAttempt + "/" + this.ingestAttempts;
    }

    private toObject() {
        return {
            id: this.id,
            databox: this.databox,
            ingestSlot: this.ingestSlot,
            uploadedBytes: this.uploadedBytes,
            dataVolume: this.dataVolume,
            uploadProgress: this.uploadProgress,
            uploadDuration: this.uploadDuration,
            totalDuration: this.totalDuration,
            numberOfFiles: this.numberOfFiles,
            error: this.error,
            markedAborted: this.markedAborted,
            ingestJob: this.ingestJob,
            ingestType: this.ingestType,

            overwrittenSince: this.overwrittenSince,
            overwriteState: this.overwriteState,
            detailsMessage: this.detailsMessage,

            ingestStarted: this.ingestStarted,
            preUploadStarted: this.preUploadStarted,
            uploadDataFileStarted: this.uploadDataFileStarted,
            uploadDataFileProgress: this.uploadDataFileProgress,
            uploadDataFileFinished: this.uploadDataFileFinished,
            ingestFinished: this.ingestFinished,

            ingestUuid: this.ingestUuid,
            discoveryId: this.discoveryId,
            lastUpdatedRow: this.lastUpdatedRow,

            numberOfChunks: this.numberOfChunks,
            chunkNumber: this.chunkNumber,
            uploadedVolume: this.uploadedVolume,
            numberOfUploadedFiles: this.numberOfUploadedFiles,
            numberOfMeasurements: this.numberOfMeasurements,

            lastMessageInserted: this.lastMessageInserted,
            ingestVersion: this.ingestVersion,
            messageUuid: this.messageUuid,
            messageTimestamp: this.messageTimestamp,
            uploadId: this.uploadId,
            csCallRetries: this.csCallRetries,

            dataId: this.dataId,
            ingestAttempt: this.ingestAttempt,
            ingestAttempts: this.ingestAttempts,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): Ingest | null {
        if (json == null) {
            return null;
        }

        return new Ingest({
            id: json["id"],
            databox: json["databox"] != null ? Databox.fromSerialized(json["databox"]) : null,
            ingestSlot: IngestSlot.fromSerialized(json["ingestSlot"]),
            uploadedBytes: json["uploadedBytes"],
            dataVolume: json["dataVolume"],
            uploadProgress: json["uploadProgress"],
            uploadDuration: json["uploadDuration"],
            totalDuration: json["totalDuration"],
            numberOfFiles: json["numberOfFiles"],
            error: json["error"],
            markedAborted: json["markedAborted"],
            ingestJob: json["ingestJob"],
            overwrittenSince: json["overwrittenSince"],
            overwriteState: json["overwriteState"],
            detailsMessage: json["detailsMessage"],
            ingestType: json["ingestType"],

            ingestStarted: json["ingestStarted"],
            preUploadStarted: json["preUploadStarted"],
            uploadDataFileStarted: json["uploadDataFileStarted"],
            uploadDataFileProgress: json["uploadDataFileProgress"],
            uploadDataFileFinished: json["uploadDataFileFinished"],
            ingestFinished: json["ingestFinished"],

            ingestUuid: json["ingestUuid"],
            discoveryId: json["discoveryId"],
            lastUpdatedRow: json["lastUpdatedRow"],

            numberOfChunks: json["numberOfChunks"],
            chunkNumber: json["chunkNumber"],
            uploadedVolume: json["uploadedVolume"],
            numberOfUploadedFiles: json["numberOfUploadedFiles"],
            numberOfMeasurements: json["numberOfMeasurements"],

            lastMessageInserted: json["lastMessageInserted"],
            ingestVersion: json["ingestVersion"],
            messageUuid: json["messageUuid"],
            messageTimestamp: json["messageTimestamp"],
            uploadId: json["uploadId"],
            csCallRetries: json["csCallRetries"],

            dataId: json["dataId"],
            ingestAttempt: json["ingestAttempt"],
            ingestAttempts: json["ingestAttempts"],
        });
    }

    public getUploadedVolume(): string {
        return this.ingestFinished ? formatBytes(this.dataVolume) : formatBytes(this.uploadedBytes);
    }
}
