export class DataboxOrderRequest {
    constructor(
        public fullName: string,
        public addressLine1: string,
        public addressLine2: string | null,
        public zipCode: string,
        public state: string | null,
        public city: string,
        public companyID: number | undefined,
        public email: string,
        public phone: string,
        public projectID: number,
        public countryID: number,
        public databoxes60Count: number,
        public databoxes90Count: number,
        public databoxes120Count: number,
        public specificDataboxes: string[],
        public azureUserId: string,
        public addressValidation: boolean
    ) {}
}
