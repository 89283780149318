import React from "react";

interface GenericTableProps {
    color: string;
    completion: number;
}

/**
 * A ProgressBar-Component, which allows you to display progressbar in different colors and different completion-rates
 *
 * @param {string} color - sets the color of the progressBar
 * @param {number} completion - sets the how far the progressBar has progressed
 *
 * @returns ProgressBar
 *
 */
const ProgressBar: React.FC<GenericTableProps> = ({ color, completion }) => {
    const styles = {
        container: {
            height: 23,
            width: "100%",
            background: `linear-gradient(to right, ${color} ${
                completion > 100 ? 100 : completion
            }%, #CACDD3 ${completion > 100 ? 100 : completion}%)`,
            borderRadius: 20,
        } as React.CSSProperties,
        bar: {
            height: "100%",
            width: `${completion > 100 ? 100 : completion}%`,
            borderRadius: "inherit",
            textAlign: "right",
        } as React.CSSProperties,
        text: {
            padding: "var(--groupui-sys-spacing-100)",
            paddingLeft: 0,
            color: "white",
            fontWeight: "bold",
            margin: "var(--groupui-sys-spacing-300)",
            whiteSpace: "nowrap",
        } as React.CSSProperties,
    };

    return (
        <div style={styles.container}>
            <div style={styles.bar}>
                <span style={styles.text}>{`${completion > 100 ? 100 : completion}%`}</span>
            </div>
        </div>
    );
};

export default ProgressBar;
