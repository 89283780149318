import React, { useContext, useEffect, useState } from "react";
import {
    GroupuiModal,
    GroupuiLoadingSpinner,
    GroupuiButton,
    GroupuiCheckbox,
} from "@group-ui/group-ui-react";
import { ApiService } from "../services/apiService";
import { ProposedAddress } from "../models/proposedAddress";
import ErrorContext from "./context/ErrorContext";
import { handleError } from "./context/ErrorContextUtils";
import GenericTable, { columnDefinition } from "./generics/GenericTable";
import GenericHeadline from "./generics/GenericHeadline";
import { msalInstance } from "../config/config";
import UserGroupContext from "./context/UserGroupContext";
import { RightsManagement } from "../config/rightsManagement";

const columns: columnDefinition[] = [
    {
        key: "checkbox",
        header: "",
        width: "2rem",
    },
    {
        key: "firstName",
        header: "First Name",
        width: "auto",
    },
    {
        key: "lastName",
        header: "Last Name",
        width: "auto",
    },
    {
        key: "street",
        header: "Street",
        width: "auto",
    },
    {
        key: "city",
        header: "City",
        width: "auto",
    },
    {
        key: "country",
        header: "Country",
        width: "auto",
    },
];

interface Props {
    showModal: boolean;
    setShowModal: (value: boolean) => void;
    onSelectAddress: (address: ProposedAddress) => void;
    closedProposedAddresses: () => void;
}
const ProposedAddresses: React.FC<Props> = ({
    showModal,
    setShowModal,
    onSelectAddress,
    closedProposedAddresses,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [proposedAddressData, setProposedAddressData] = useState<ProposedAddress[] | null>();
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const { userGroups } = useContext(UserGroupContext);
    const [selectedRow, setSelectedRow] = useState<number | null>(null);
    const [selectedProposedAddress, setSelectedProposedAddress] = useState<ProposedAddress>();

    const accounts = msalInstance.getAllAccounts();
    const fullName = accounts.map((account) => account.name);

    const formattedFullNames = fullName.map((name) => {
        if (name) {
            const [lastName, firstName] = name.split(", ");
            return `${firstName.toLowerCase()} ${lastName.toLowerCase()}`;
        }
    });

    const handleRowSelect = (rowIndex: number) => {
        setSelectedRow(selectedRow === rowIndex ? null : rowIndex);
        if (proposedAddressData) {
            setSelectedProposedAddress(proposedAddressData[rowIndex]);
        }
    };

    const handleSelectButtonClick = () => {
        if (selectedProposedAddress) {
            onSelectAddress(selectedProposedAddress);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const data =
        proposedAddressData?.map((proposedAddress, index) => {
            const [firstName, lastName] = proposedAddress.fullName
                .split(" ")
                .map((name) => name.trim());
            return {
                checkbox: (
                    <GroupuiCheckbox
                        checked={selectedRow === index}
                        onClick={() => handleRowSelect(index)}
                        disabled={selectedRow !== null && selectedRow !== index}
                    />
                ),
                firstName: firstName,
                lastName: lastName,
                street: proposedAddress.street,
                city: proposedAddress.city,
                country: proposedAddress.country.country,
                email: proposedAddress.email,
                phone: proposedAddress.phone,
                state: proposedAddress.state !== undefined ? proposedAddress.state : "",
                company: proposedAddress.company.company,
            };
        }) ?? [];

    const getData = () => {
        setIsLoading(true);
        if (userGroups.includes(RightsManagement.SUPPORT || RightsManagement.ADMINISTRATOR)) {
            ApiService.getAllProposedAddresses()
                .then((response) => {
                    setProposedAddressData(response);
                    setIsLoading(false);
                })
                .catch((error) => {
                    handleError(error, changeErrorMessage, changeShowError);
                    setIsLoading(false);
                });
        } else {
            ApiService.getAllProposedAddresses()
                .then((response) => {
                    const filteredData =
                        response &&
                        response.filter(
                            (item) =>
                                item.fullName != null &&
                                formattedFullNames.includes(item.fullName.toLowerCase())
                        );
                    setProposedAddressData(filteredData);
                    setIsLoading(false);
                })
                .catch((error) => {
                    handleError(error, changeErrorMessage, changeShowError);
                    setIsLoading(false);
                });
        }
    };

    return (
        <GroupuiModal
            closeButton={true}
            padding="var(--groupui-sys-spacing-1000)"
            displayed={showModal}
            onGroupuiModalClose={() => closedProposedAddresses()}
            style={{ justifyContent: "center", alignItems: "center" }}
            maxWidth="1200"
            overflow="visible"
        >
            <GroupuiLoadingSpinner displayed={isLoading} />
            <GenericHeadline
                title="Proposed Locations"
                titleHeadingType="h3"
                actionButtons={[]}
                type="modal"
            />
            {proposedAddressData && (
                <div className="table-live-demo container-border">
                    <GenericTable
                        pageSize={5}
                        pageSizeOptions="[5, 10, 15]"
                        columns={columns}
                        header={true}
                        data={data}
                        searchColumns={[
                            { column: "street", displayName: "Street" },
                            { column: "city", displayName: "City" },
                        ]}
                        filterColumns={[
                            {
                                column: "country",
                                displayName: "Country",
                                doSort: "asc",
                            },
                        ]}
                        filtersAndSearchWidth={{ filters: "200px", search: "300px" }}
                        filterHeight="400px"
                    />
                </div>
            )}
            <div
                style={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "var(--groupui-sys-spacing-400)",
                    marginTop: "var(--groupui-sys-spacing-600)",
                }}
            >
                <GroupuiButton variant={"secondary"} onClick={() => setShowModal(false)}>
                    Cancel
                </GroupuiButton>
                <GroupuiButton
                    icon="check-24"
                    disabled={selectedRow !== null ? false : true}
                    onClick={handleSelectButtonClick}
                >
                    Select
                </GroupuiButton>
            </div>
        </GroupuiModal>
    );
};
export default ProposedAddresses;
