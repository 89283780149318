import { loginRequest, msalInstance } from "../../config/config";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

export enum RequestType {
    GET = "GET",
    POST = "POST",
}

export const GraphApiService = class GraphApiService {
    private static instance = msalInstance;
    private static baseUrl = "https://graph.microsoft.com/v1.0/";

    private static async createHeader(): Promise<HeadersInit> {
        const tokenRequestParams = {
            ...loginRequest,
            account: msalInstance.getAllAccounts()[0],
        };
        const token = await this.instance
            .acquireTokenSilent(tokenRequestParams)
            .then((response) => response.accessToken)
            .catch((error) => {
                // following the error handling faq of the library
                // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md
                if (error instanceof InteractionRequiredAuthError) {
                    this.instance.acquireTokenRedirect(tokenRequestParams);
                }
            });

        const headers: HeadersInit = {
            Authorization: `Bearer ${token}`,
        };
        return headers;
    }

    public static async callMsGraph<T>(
        endpoint: string | undefined,
        requestType: RequestType,
        jsonParser?: (json: any) => T
    ) {
        const url = endpoint != undefined ? this.baseUrl.concat(endpoint) : this.baseUrl;
        const headers = await this.createHeader();
        const response = await fetch(url, {
            method: requestType,
            headers: headers,
        }).catch((error) => {
            // A fetch() promise only rejects when a network error is encountered
            // when server cannot get reached it gets a null object which cannot be put into a Response object
            // --> Throws TypeError (Type Null != Type Response)
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            if (response.status != 200) {
                console.log(`Graph Api request failed: ${response.body}`);
            }

            const jsonResponse = await response.json();
            if (jsonParser == null) {
                return jsonResponse;
            }
            if (jsonResponse instanceof Array) {
                return jsonResponse.map(jsonParser);
            }
            if (jsonResponse instanceof Map) {
                return jsonParser(jsonResponse);
            }
        }
    }
};
