import { GroupuiLoadingSpinner, GroupuiButton } from "@group-ui/group-ui-react";
import GenericHeadline from "../../generics/GenericHeadline";
import GenericMoreButton from "../../generics/GenericMoreButton";
import GenericTable, { columnDefinition } from "../../generics/GenericTable";
import useTitle from "../../../hooks/useTitle";
import React, { useContext, useEffect, useState } from "react";
import { ApiService } from "../../../services/apiService";
import { handleError } from "../../context/ErrorContextUtils";
import ErrorContext from "../../context/ErrorContext";
import { JobReport } from "../../../models/reports/jobReport";
import {
    getDaysAndHoursFromMilliseconds,
    getHoursAndMinutesFromMilliseconds,
    parseFullDate,
    parseDateOnly,
    parseFullDateWithoutSeconds,
} from "../../../utils/DateUtil";
import DownloadReportModal from "../DownloadReportModal";

const styles = {
    contentWrapper: {
        padding: "var(--groupui-sys-spacing-1200) var(--groupui-sys-spacing-1400)",
        scrollBehavior: "smooth",
        whiteSpace: "pre-wrap",
    } as React.CSSProperties,
    magnifyingGlass: {
        cursor: "pointer",
        color: "var(--groupui-sys-color-action-basic-default)",
    } as React.CSSProperties,
};

const columns: columnDefinition[] = [
    {
        key: "orderId",
        header: "Order ID",
        width: "auto",
    },
    {
        key: "databoxName",
        header: "Databox Name",
        width: "auto",
    },
    {
        key: "carId",
        header: "Car ID",
        width: "auto",
    },
    {
        key: "ingestId",
        header: "Ingest ID",
        width: "auto",
    },
    {
        key: "ingestStation",
        header: "Ingest Station",
        width: "auto",
    },
    {
        key: "orderCreation",
        header: "Order Creation",
        width: "auto",
    },
    {
        key: "pickedUpHub",
        header: "Picked Up (Hub)",
        width: "auto",
    },
    {
        key: "deliveredToCustomer",
        header: "Delivered To Customer",
        width: "auto",
    },
    {
        key: "durationTransitDelivery",
        header: "Duration Transit (Delivery)",
        width: "auto",
    },
    {
        key: "durationRequestOrder",
        header: "Duration Request Order",
        width: "auto",
    },
    {
        key: "pickupRequested",
        header: "Pickup Requested",
        width: "auto",
    },
    {
        key: "requestedPickupDate",
        header: "Requested Pickup Date",
        width: "auto",
    },
    {
        key: "droppedOffAccessPoint",
        header: "Dropped Off (Access Point)",
        width: "auto",
    },
    {
        key: "pickedUpCustomer",
        header: "Picked Up (Customer)",
        width: "auto",
    },
    {
        key: "durationInUseByCustomer",
        header: "Duration In Use By (Customer)",
        width: "auto",
    },
    {
        key: "deliveredToHub",
        header: "Delivered To Hub",
        width: "auto",
    },
    {
        key: "durationTransitReturn",
        header: "Duration Transit (Return)",
        width: "auto",
    },
    {
        key: "assignedIngestSlot",
        header: "Assigned Ingest Slot",
        width: "auto",
    },
    {
        key: "creationSmartHandsTicketIngest",
        header: "Creation SmartHands Ticket (Ingest)",
        width: "auto",
    },
    {
        key: "durationSmartHandsTicketIngest",
        header: "Duration SmartHands Ticket (Ingest)",
        width: "auto",
    },
    {
        key: "ingestStartedFirstTry",
        header: "Ingest Started (first try)",
        width: "auto",
    },
    {
        key: "ingestStartedLastSuccessfulTry",
        header: "Ingest Started (last successful try)",
        width: "auto",
    },
    {
        key: "uploadStartedLastSuccessfulTry",
        header: "Upload Started (last successful try)",
        width: "auto",
    },
    {
        key: "uploadCompleted",
        header: "Upload Completed",
        width: "auto",
    },
    {
        key: "durationUpload",
        header: "Duration Upload",
        width: "auto",
    },
    {
        key: "completnessCheckTries",
        header: "Completness Check Tries",
        width: "auto",
    },
    {
        key: "ingestCompleted",
        header: "Ingest Completed",
        width: "auto",
    },
    {
        key: "ingestDuration",
        header: "Ingest Duration",
        width: "auto",
    },
    {
        key: "creationSmartHandsTicketPostIngest",
        header: "Creation SmartHands Ticket (Post-Ingest)",
        width: "auto",
    },
    {
        key: "orderCompleted",
        header: "Order Completed",
        width: "auto",
    },
    {
        key: "durationSmartHandsTicketPostIngest",
        header: "Duration SmartHands Ticket (Post-Ingest)",
        width: "auto",
    },
    {
        key: "durationTillUploadCompleted",
        header: "Duration till Upload Completed",
        width: "auto",
    },
    {
        key: "ingestDetailsMessage",
        header: "Ingest Details Message",
        width: "auto",
    },
    {
        key: "ingestRestarts",
        header: "Ingest Restarts",
        width: "auto",
    },
];

export const JobReports = () => {
    const title = "Job Report";
    useTitle(title);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const [jobReports, setJobReports] = useState<JobReport[] | null>();
    const [modal, setModal] = useState(false);
    const [dropDownState, setDropDownState] = useState(false);

    useEffect(() => {
        ApiService.getJobReports()
            .then((response) => {
                setJobReports(response);
                setIsLoading(false);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
                setIsLoading(false);
            });
    }, []);

    const data = jobReports?.map((jobReport) => {
        return {
            orderId: "OR" + jobReport.orderId,
            databoxName: jobReport.databoxName,
            carId: jobReport.carId,
            ingestId: jobReport.ingestId ? "I" + jobReport.ingestId : "",
            ingestStation: jobReport.ingestStation,
            orderCreation: parseFullDateWithoutSeconds(jobReport.orderCreated),
            pickedUpHub: parseFullDateWithoutSeconds(jobReport.pickedUpHub),
            deliveredToCustomer: parseFullDateWithoutSeconds(jobReport.deliveredToCustomer),
            durationTransitDelivery: getHoursAndMinutesFromMilliseconds(
                jobReport.durationTransitDelivery
            ),
            durationRequestOrder: getHoursAndMinutesFromMilliseconds(
                jobReport.durationRequestOrder
            ),
            pickupRequested: parseFullDateWithoutSeconds(jobReport.pickupRequested),
            requestedPickupDate: parseDateOnly(jobReport.requestedPickupDate),
            droppedOffAccessPoint: parseFullDateWithoutSeconds(jobReport.droppedOffAccessPoint),
            pickedUpCustomer: parseFullDateWithoutSeconds(jobReport.pickedUpCustomer),
            durationInUseByCustomer: getDaysAndHoursFromMilliseconds(
                jobReport.durationInUseByCustomer
            ),
            deliveredToHub: parseFullDateWithoutSeconds(jobReport.deliveredToHub),
            durationTransitReturn: getHoursAndMinutesFromMilliseconds(
                jobReport.durationTransitReturn
            ),
            assignedIngestSlot: parseFullDateWithoutSeconds(jobReport.assignedIngestSlot),
            creationSmartHandsTicketIngest: parseFullDateWithoutSeconds(
                jobReport.creationSmarthandsTicketIngest
            ),
            durationSmartHandsTicketIngest: getHoursAndMinutesFromMilliseconds(
                jobReport.durationSmarthandsTicketIngest
            ),
            ingestStartedFirstTry: parseFullDateWithoutSeconds(jobReport.ingestStartedFirstTry),
            ingestStartedLastSuccessfulTry: parseFullDateWithoutSeconds(
                jobReport.ingestStartedLastSuccessfulTry
            ),
            uploadStartedLastSuccessfulTry: parseFullDateWithoutSeconds(
                jobReport.uploadStartedLastSuccessfulTry
            ),
            uploadCompleted: parseFullDateWithoutSeconds(jobReport.uploadCompleted),
            durationUpload: getHoursAndMinutesFromMilliseconds(jobReport.durationUpload),
            completnessCheckTries: jobReport.completenessCheckTries,
            ingestCompleted: parseFullDateWithoutSeconds(jobReport.ingestCompleted),
            ingestDuration: getHoursAndMinutesFromMilliseconds(jobReport.ingestDuration),
            creationSmartHandsTicketPostIngest: parseFullDateWithoutSeconds(
                jobReport.creationSmarthandsTicketPostIngest
            ),
            orderCompleted: parseFullDateWithoutSeconds(jobReport.orderCompleted),
            durationSmartHandsTicketPostIngest: getHoursAndMinutesFromMilliseconds(
                jobReport.durationSmarthandsTicketPostIngest
            ),
            durationTillUploadCompleted: getHoursAndMinutesFromMilliseconds(
                jobReport.durationTillUploadCompleted
            ),
            ingestDetailsMessage: jobReport.ingestDetailsMessage,
            ingestAttempts: jobReport.ingestRestarts,
        };
    });

    return (
        <div>
            <GroupuiLoadingSpinner displayed={isLoading} />
            {modal && (
                <DownloadReportModal
                    reportType={title ?? ""}
                    data={data ?? []}
                    modal={modal}
                    setModal={(value: boolean) => setModal(value)}
                />
            )}
            <div style={styles.contentWrapper}>
                <GenericHeadline
                    title={title}
                    actionButtons={[
                        <GenericMoreButton
                            key={"More-Button-1"}
                            dropDownState={dropDownState}
                            setDropDownState={() => {
                                setDropDownState(!dropDownState);
                            }}
                            options={[
                                <GroupuiButton
                                    key={"Option-1"}
                                    variant="primary"
                                    icon="download-32"
                                    fullwidth={true}
                                    alignment="left"
                                    onClick={() => {
                                        setModal(true);
                                        setDropDownState(false);
                                    }}
                                >
                                    Download {title}
                                </GroupuiButton>,
                            ]}
                        />,
                    ]}
                />
                <div>
                    <GenericTable
                        columns={columns}
                        data={data ?? []}
                        header={true}
                        pageSize={20}
                        scrollX={true}
                    />
                </div>
            </div>
        </div>
    );
};
