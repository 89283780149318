import { GroupuiDatePicker, GroupuiIcon, GroupuiInput } from "@group-ui/group-ui-react";
import { log } from "console";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

interface GenericDateTimePickerProps {
    label: string;
    value: string | undefined;
    setValue: Dispatch<SetStateAction<string | undefined>>;
    required: boolean;
    severity?: "none" | "danger" | "warning" | "success" | undefined;
}

const GenericDateTimePicker: React.FC<GenericDateTimePickerProps> = ({
    label,
    value,
    setValue,
    required,
    severity,
}) => {
    const [date, setDate] = useState<string>();
    const [time, setTime] = useState<string>();

    useEffect(() => {
        if (date && time) setValue(date + "T" + time);
    }, [date, time]);

    useEffect(() => {
        setDate(value?.split("T")[0] ?? "");
        setTime(value?.split("T")[1] ?? "");
    }, [value]);

    return (
        <div
            style={{
                position: "relative",
                display: "flex",
                flexDirection: "row",
                gap: "var(--groupui-sys-spacing-300)",
            }}
        >
            <GroupuiDatePicker
                style={{ whiteSpace: "normal" }}
                required={required}
                severity={severity}
                value={date}
                onGroupuiChange={(event) => setDate(event.target.value)}
            >
                <span slot="label">
                    {label} - Date {required ? "*" : ""}
                </span>
            </GroupuiDatePicker>
            <GroupuiInput
                required={required}
                severity={severity}
                value={time}
                type="time"
                onGroupuiChange={(event) => setTime(event.target.value)}
            >
                <span slot="label">
                    {label} - Time {required ? "*" : ""}
                </span>
            </GroupuiInput>
        </div>
    );
};

export default GenericDateTimePicker;
