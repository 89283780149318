import React, { useContext, useEffect, useState } from "react";
import { parseFullDateFilename, parseDateOnly } from "../../../utils/DateUtil";
import { GroupuiButton } from "@group-ui/group-ui-react";
import { CSVLink } from "react-csv";
import UserGroupContext from "../../context/UserGroupContext";
import { RightsManagement } from "../../../config/rightsManagement";
import { isInUserGroup } from "../../../utils/RightsUtils";

interface DownloadReportProps {
    data: any;
    title: string;
    setDropDownState: (value: boolean) => void;
}

const DownloadReport: React.FC<DownloadReportProps> = ({ data, title, setDropDownState }) => {
    const [filteredData, setFilteredData] = useState<any[] | undefined>([]);
    const { userGroups } = useContext(UserGroupContext);

    useEffect(() => {
        const createGoogleMapsURL = (latitude: string, longitude: string) => {
            const baseUrl = "https://www.google.com/maps/search/?api=1";

            if (!longitude || !latitude) return "";

            return `${baseUrl}&query=${latitude},${longitude}`;
        };

        setFilteredData(
            data.map((entry: any) => {
                if (isInUserGroup([RightsManagement.SUPPORT], userGroups)) {
                    return {
                        databoxName: entry.databoxName,
                        databoxSerialNumber: entry.databoxSerialNumber,
                        storageCapacity: entry.storageCapacity,
                        databoxOwner: entry.databoxOwner,
                        orderId: entry.orderId,
                        databoxStatus: entry.databoxStatus,
                        tracker: entry.tracker,
                        battery: entry.battery,
                        lastTrackerUpdate: entry.lastTrackerUpdate,
                        ingestLocation: entry.ingestLocation,
                        destination: entry.destination,
                        requestor: entry.requestor ?? "",
                        lastComment: entry.lastComment,
                        internalProject: entry.internalProject,
                        currentHolder: entry.currentHolder,
                        purchaseOrderNumber: entry.purchaseOrderNumber,
                        purchaseOrderDate: parseDateOnly(entry.purchaseOrderDate),
                        opxNumber: entry.opxNumber,
                        serviceContractId: entry.serviceContractId,
                        serviceStartDate: parseDateOnly(entry.serviceStartDate),
                        serviceEndDate: parseDateOnly(entry.serviceEndDate),
                        lastGpsPosition: createGoogleMapsURL(entry.latitude, entry.longitude),
                    };
                }

                return {
                    databoxName: entry.databoxName,
                    databoxSerialNumber: entry.databoxSerialNumber,
                    storageCapacity: entry.storageCapacity,
                    databoxOwner: entry.databoxOwner,
                    orderId: entry.orderId,
                    databoxStatus: entry.databoxStatus,
                    tracker: entry.tracker,
                    battery: entry.battery,
                    lastTrackerUpdate: entry.lastTrackerUpdate,
                    ingestLocation: entry.ingestLocation,
                    destination: entry.destination,
                    requestor: entry.requestor ?? "",
                    lastComment: entry.lastComment,
                    internalProject: entry.internalProject,
                    currentHolder: entry.currentHolder,
                    lastGpsPosition: createGoogleMapsURL(entry.latitude, entry.longitude),
                };
            })
        );
    }, [data]);

    return (
        <CSVLink
            key={"CSV-Link-1"}
            data={filteredData ?? ""}
            separator={";"}
            filename={title + "_" + parseFullDateFilename(new Date()) + ".csv"}
        >
            <GroupuiButton
                key={"Option-1-1"}
                variant="primary"
                icon="download-32"
                fullwidth={true}
                alignment="left"
                // disabled={true}
                onClick={() => {
                    setDropDownState(false);
                }}
            >
                Download {title}
            </GroupuiButton>
        </CSVLink>
    );
};

export default DownloadReport;
