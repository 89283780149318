import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import GenericInput from "./GenericInput";
interface Props {
    input: string;
    setInput: Dispatch<SetStateAction<string>>;
    disabled: boolean;
    name: string;
    displayName: string;
    maxLength: number;
    description?: string;
    mandatory?: boolean;
}

const GenericInputWithValidityCheck: React.FC<Props> = ({
    input,
    setInput,
    disabled,
    name,
    displayName,
    maxLength,
    description = "",
    mandatory = false,
}) => {
    const [validityError, setValidityError] = useState<boolean>(true);

    useEffect(() => {
        if (input?.length > maxLength) {
            setValidityError(true);
        } else {
            setValidityError(false);
        }
    }, [input]);

    return (
        <div>
            <GenericInput
                label={`${displayName}${disabled ? "" : mandatory ? " *" : ""}`}
                value={input}
                disabled={disabled}
                maxLength={maxLength}
                onGroupuiChange={setInput}
                description={
                    validityError ? `A maximum of ${maxLength} characters allowed` : description
                }
                severity={validityError ? "danger" : "none"}
                name={name}
            />
        </div>
    );
};

export default GenericInputWithValidityCheck;
