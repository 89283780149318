import { GroupuiButton, GroupuiModal, GroupuiText } from "@group-ui/group-ui-react";
import React from "react";
import { Address } from "../../models/address";
import GenericHeadline from "../generics/GenericHeadline";
import AddressComparisonTable from "../requests/orderDataboxes/AddressComparisonTable";

interface Props {
    address: Address | undefined;
    suggestedAddress: Address | undefined;
    addressTitle: string;
    onDecision: (isAccepted: boolean) => void;
    show: boolean;
    setShow: (value: { show: boolean; suggestedPickupAddress: Address | undefined }) => void;
}

const AddressValidationModal: React.FC<Props> = ({
    address,
    suggestedAddress,
    addressTitle,
    onDecision,
    show,
    setShow,
}) => (
    <GroupuiModal
        closeButton={true}
        padding="30px"
        maxWidth="1000px"
        overflow={"visible"}
        displayed={show}
        style={{
            justifyContent: "center",
            alignItems: "center",
        }}
    >
        <div>
            <GenericHeadline
                title="Address Validation"
                actionButtons={[]}
                titleHeadingType="h4"
                type="modal"
            />
            <div>
                <GroupuiText>
                    Do you want to accept following <b>changes</b> to your <br />
                    entered addresses?
                </GroupuiText>
            </div>
            {address !== undefined && (
                <div>
                    <GenericHeadline
                        title={addressTitle}
                        actionButtons={[]}
                        titleHeadingType="h6"
                        marginBottom={"var(--groupui-sys-spacing-200)"}
                    />
                    <AddressComparisonTable
                        address={address}
                        suggestedAddress={suggestedAddress ?? address}
                    />
                </div>
            )}
            <div
                style={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "var(--groupui-sys-spacing-400)",
                    marginTop: "var(--groupui-sys-spacing-600)",
                }}
            >
                <GroupuiButton
                    variant={"secondary"}
                    onClick={() =>
                        setShow({ show: false, suggestedPickupAddress: suggestedAddress })
                    }
                >
                    Cancel
                </GroupuiButton>
                <GroupuiButton icon="shopping-cart-32" onClick={() => onDecision(true)}>
                    Accept
                </GroupuiButton>
            </div>
        </div>
    </GroupuiModal>
);

export default AddressValidationModal;
