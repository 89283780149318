import React, { useContext, useEffect, useState } from "react";
import { GroupuiBreadcrumb, GroupuiBreadcrumbs } from "@group-ui/group-ui-react";
import { Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ApiService } from "../../services/apiService";
import ErrorContext from "../context/ErrorContext";
import { handleError } from "../context/ErrorContextUtils";
import { IngestStation } from "../../models/ingestStation";
import { IngestStationContext } from "../context/provider/IngestStationContext";

const styles = {
    contentContainer: {
        padding: "var(--groupui-sys-spacing-1200) var(--groupui-sys-spacing-1400)",
        scrollBehavior: "smooth",
        whiteSpace: "pre-wrap",
    } as React.CSSProperties,
};

/**
 * Returns an IngestStationDetailsWrapper Component, which positions the Breadcrumbs on the detail view of each ingest station
 *
 * @returns IngestStationDetailsWrapper Component
 *
 */
function IngestStationDetailsWrapper() {
    const navigate = useNavigate();
    const location = useLocation();
    const { currentIngestStation, currentIngestSlot } = useContext(IngestStationContext);

    return (
        <div style={styles.contentContainer}>
            <GroupuiBreadcrumbs>
                <GroupuiBreadcrumb onClick={() => navigate("/ingest-stations")}>
                    Ingest Stations
                </GroupuiBreadcrumb>
                <GroupuiBreadcrumb
                    onClick={() =>
                        navigate(location.pathname.substring(0, location.pathname.lastIndexOf("/")))
                    }
                >
                    {currentIngestStation
                        ? `Ingest Station Details - ${currentIngestStation?.name}`
                        : `Ingest Station Details`}
                </GroupuiBreadcrumb>
                {/* Displays a additional Breadcrumb if the user matches the path of the following Route */}
                <Routes>
                    <Route
                        path=":IngestSlot"
                        element={
                            <GroupuiBreadcrumb>
                                {currentIngestSlot
                                    ? `Ingest Slot Details - ${currentIngestSlot?.name}`
                                    : `Ingest Slot Details`}
                            </GroupuiBreadcrumb>
                        }
                    />
                </Routes>
            </GroupuiBreadcrumbs>
            {/* Needed to display the content given by the Nested Route */}
            <Outlet />
        </div>
    );
}

export default IngestStationDetailsWrapper;
