import { GroupuiButton, GroupuiTooltip } from "@group-ui/group-ui-react";
import React from "react";

interface GenericTooltipButtonProps {
    buttonText: string;
    icon: string;
    disabled: boolean;
    tooltipText?: string;
    setModal?: (value: boolean) => void;
    setDropDownState?: (value: boolean) => void;
}

export const GenericTooltipButton: React.FC<GenericTooltipButtonProps> = ({
    buttonText,
    icon,
    disabled,
    tooltipText,
    setModal,
    setDropDownState,
}) => {
    return (
        <div
            style={{
                display: "flex",
                position: "relative",
                overflow: "visible",
                width: "100%",
            }}
        >
            {disabled && (
                <GroupuiTooltip
                    position="auto"
                    style={{
                        width: "100%",
                    }}
                >
                    <GroupuiButton
                        key={"Option-" + tooltipText}
                        variant="primary"
                        icon={icon}
                        disabled={disabled}
                        fullwidth={true}
                        alignment="left"
                        style={{
                            width: "320px",
                        }}
                        onClick={() => {
                            setModal ? setModal(true) : null;
                            setDropDownState ? setDropDownState(false) : null;
                        }}
                    >
                        {buttonText}
                    </GroupuiButton>
                    <div slot="tooltip">{tooltipText}</div>
                </GroupuiTooltip>
            )}
            {!disabled && (
                <div
                    style={{
                        width: "100%",
                    }}
                >
                    <GroupuiButton
                        key={"Option-" + tooltipText}
                        variant="primary"
                        icon={icon}
                        disabled={disabled}
                        fullwidth={true}
                        style={{
                            width: "100%",
                        }}
                        alignment="left"
                        onClick={() => {
                            setModal ? setModal(true) : null;
                            setDropDownState ? setDropDownState(false) : null;
                        }}
                    >
                        {buttonText}
                    </GroupuiButton>
                </div>
            )}
        </div>
    );
};
