import { GroupuiIcon } from "@group-ui/group-ui-react";
import React from "react";
import { Order } from "../../models/order";
import { parseFullDate } from "../../utils/DateUtil";
import GenericHeadline from "../generics/GenericHeadline";
import GenericInput from "../generics/GenericInput";
const styles = {
    icon: {
        cursor: "pointer",
    },
};

interface Props {
    order: Order;
    disabled?: boolean;
}

const DropOffDetailsGeneralInformation: React.FC<Props> = ({ order, disabled = true }) => {
    return (
        <div>
            <GenericHeadline
                title={"General Information"}
                titleHeadingType="h4"
                actionButtons={[]}
            />
            <div style={{ display: "flex", gap: 40 }}>
                <div style={{ width: "50%" }}>
                    <GenericInput
                        label={"Package Drop Off ID"}
                        disabled={true}
                        value={String(order.packageDropOff?.id)}
                    />
                    <GenericInput
                        label={"Databox Name"}
                        disabled={disabled}
                        value={order?.databox?.name}
                    />
                    <GenericInput
                        label={"Current State"}
                        disabled={disabled}
                        value={order.currentMediaState}
                    />
                    <GenericInput
                        label={"Delivery Provider"}
                        disabled={disabled}
                        value={order?.databox?.ingestStation?.deliveryProvider ?? ""}
                    />
                </div>
                <div style={{ width: "50%" }}>
                    <div style={{ position: "relative" }}>
                        <GenericInput
                            label={"Return Label"}
                            disabled={disabled}
                            value={`return_label_${order.upsReturnShipmentLabel?.shipmentIdenfificationNumber}.png`}
                        />
                        {order?.upsShipmentLabel !== undefined && (
                            <div style={{ position: "absolute", right: 8, bottom: 8 }}>
                                <GroupuiIcon
                                    name="download-32"
                                    onClick={() => {
                                        const base64png = order.upsReturnShipmentLabel?.label;
                                        if (base64png === undefined) {
                                            return;
                                        }
                                        const a = document.createElement("a");
                                        a.href =
                                            "data:image/png;base64," +
                                            order.upsReturnShipmentLabel?.label;
                                        a.download = `delivery_label_${order.upsReturnShipmentLabel?.shipmentIdenfificationNumber}.png`;
                                        a.click();
                                    }}
                                    style={styles.icon}
                                />
                            </div>
                        )}
                    </div>
                    <GenericInput
                        label={"Created"}
                        disabled={disabled}
                        value={parseFullDate(order?.packageDropOff?.created)}
                    />
                    <GenericInput
                        label={"Last updated"}
                        disabled={disabled}
                        value={
                            order.updated != null
                                ? parseFullDate(order.updated)
                                : "Not updated yet."
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default DropOffDetailsGeneralInformation;
