import { Country } from "../country";

export class PackageDropOff {
    public addressLine1;
    public addressLine2;
    public zipCode;
    public city;
    public state;
    public country;
    public created;
    private _id?;

    constructor({
        addressLine1,
        addressLine2,
        zipCode,
        city,
        state,
        country,
        created,
        id,
    }: {
        addressLine1: string;
        addressLine2: string;
        zipCode: string;
        city: string;
        state: string;
        country: Country;
        created: string;
        id?: number;
    }) {
        this.addressLine1 = addressLine1;
        this.addressLine2 = addressLine2;
        this.zipCode = zipCode;
        this.city = city;
        this.state = state;
        this.country = country;
        this.created = created;
        this._id = id;
    }

    public get id() {
        return this._id;
    }

    private toObject() {
        return {
            addressLine1: this.addressLine1,
            addressLine2: this.addressLine2,
            zipCode: this.zipCode,
            city: this.city,
            state: this.state,
            country: this.country,
            created: this.created,
            id: this._id,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): PackageDropOff | null {
        if (json == null) {
            return null;
        }
        return new PackageDropOff({
            addressLine1: json["addressLine1"],
            addressLine2: json["addressLine2"],
            zipCode: json["zipCode"],
            city: json["city"],
            state: json["state"],
            country: Country.fromSerialized(json["country"]),
            created: json["created"],
            id: json["id"],
        });
    }
}
