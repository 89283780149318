import {
    GroupuiButton,
    GroupuiDatePicker,
    GroupuiLoadingSpinner,
    GroupuiModal,
    GroupuiSelect,
    GroupuiSelectOption,
    GroupuiTextarea,
} from "@group-ui/group-ui-react";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { msalInstance } from "../../../config/config";
import { DataboxBooking } from "../../../models/databoxBooking";
import { HistoryEntry } from "../../../models/historyEntry";
import { MediaState } from "../../../models/mediaState";
import { ApiService } from "../../../services/apiService";
import { parseDateForDatepicker } from "../../../utils/DateUtil";
import ErrorContext from "../../context/ErrorContext";
import GenericHeadline from "../../generics/GenericHeadline";
import Snackbar from "../../generics/Snackbar";
import { handleError } from "../../context/ErrorContextUtils";
import GenericInput from "../../generics/GenericInput";

interface EditStateModalProps {
    modal: boolean;
    setModal: (value: boolean) => void;
    bookingData: HistoryEntry | null | undefined;
}

const EditStateModal: React.FC<EditStateModalProps> = ({ modal, setModal, bookingData }) => {
    const [selectedMediaState, setSelectedMediaState] = useState<string | undefined>();
    const [started, setStarted] = useState<string>();
    const [finished, setFinished] = useState<string | undefined>();
    const [comment, setComment] = useState<string>();
    const [ticketId, setTicketId] = useState<string>();
    const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
    const [severity, setSeverity] = useState<"danger" | "success" | "warning">();
    const [message, setMessage] = useState<string>();
    const [mediaStateData, setMediaStateData] = useState<MediaState[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const { databoxID } = useParams();
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);

    useEffect(() => {
        ApiService.getMediaStates()
            .then((response: MediaState[]) => {
                setMediaStateData(response);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
            });
        setSelectedMediaState(
            String(
                mediaStateData.find((mediaState: MediaState) => {
                    return mediaState.mediaStateType == bookingData?.mediaStateType.mediaStateType;
                })?.id
            )
        );

        setStarted(parseDateForDatepicker(bookingData?.started));
        setFinished(parseDateForDatepicker(bookingData?.finished));
        setComment(bookingData?.comment ? bookingData?.comment : "");
        setTicketId(bookingData?.ticketId ?? undefined);
    }, [showSnackBar, modal, bookingData]);

    function handleValidation(event: any): boolean {
        const dateRegExp = /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/;
        let isValid;
        if (
            selectedMediaState == undefined ||
            selectedMediaState == null ||
            selectedMediaState == "" ||
            started == undefined ||
            started == null ||
            started == ""
        ) {
            event.preventDefault();
            setSeverity("danger");
            setMessage(
                "The selected State and the start date are either undefined, null or empty!"
            );
            setShowSnackBar(true);
            return false;
        }
        mediaStateData.map((element) => {
            if (element.id == Number(selectedMediaState)) {
                isValid = true;
            }
        });
        if (!dateRegExp.test(started ?? "")) {
            event.preventDefault();
            setSeverity("danger");
            setMessage("The selected start date is invalid!");
            setShowSnackBar(true);
            return false;
        } else {
            isValid = true;
        }
        if (finished !== null && finished !== undefined && finished !== "") {
            isValid = dateRegExp.test(finished);
            if (new Date(started) >= new Date(finished)) {
                event.preventDefault();
                setSeverity("danger");
                setMessage("The selected end date is before the selected start date!");
                setShowSnackBar(true);
                return false;
            }
            if (!dateRegExp.test(finished ?? "")) {
                event.preventDefault();
                setSeverity("danger");
                setMessage("The selected end date is invalid!");
                setShowSnackBar(true);
                return false;
            } else {
                isValid = true;
            }
        }
        if (isValid) {
            setSeverity("success");
        }
        return isValid;
    }

    function handleSubmit(event: any) {
        if (handleValidation(event)) {
            setIsLoading(true);
            ApiService.updateDataboxBookingById(
                bookingData!.id,
                new DataboxBooking({
                    databoxId: databoxID,
                    created: new Date().toISOString(),
                    started: started,
                    finished: finished,
                    mediaStateId: selectedMediaState,
                    reservedBy: msalInstance.getAllAccounts()[0].name,
                    comment: comment,
                    ticketId: ticketId,
                })
            )
                .then((value) => {
                    if (value.toLowerCase() === "success") {
                        setMessage("Successful changed Databox Booking!");
                        setSeverity("success");
                        setShowSnackBar(true);
                        setIsLoading(false);
                        window.location.reload();
                    } else {
                        event.preventDefault();
                        setSeverity("danger");
                        setMessage("Changes couldn´t be saved!");
                        setShowSnackBar(true);
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    handleError(error, changeErrorMessage, changeShowError);
                });
        }
    }

    const getMediaStateId = (mediaState: string) => {
        const filteredData = mediaStateData.find(
            (element) => element.mediaStateType === mediaState
        );
        return filteredData?.id;
    };

    return (
        <GroupuiModal
            closeButton={true}
            padding="var(--groupui-sys-spacing-800)"
            overflow={"visible"}
            displayed={modal}
            style={{ justifyContent: "center", alignItems: "center" }}
            onGroupuiModalClose={() => {
                setModal(false);
                setFinished(undefined);
            }}
        >
            <GroupuiLoadingSpinner
                style={{ position: "static", zIndex: "6" }}
                displayed={isLoading}
            />
            {showSnackBar && (
                <Snackbar
                    severity={severity ?? "danger"}
                    message={message ?? "Some error occurred!"}
                    onClose={() => {
                        setShowSnackBar(false);
                    }}
                />
            )}
            <GenericHeadline
                title="Edit State"
                actionButtons={[]}
                titleHeadingType="h3"
                type="modal"
            />
            <form>
                <GroupuiSelect
                    severity={severity}
                    typeof="input"
                    value={selectedMediaState}
                    onGroupuiChange={(event) => setSelectedMediaState(event.target.value)}
                >
                    {mediaStateData.map((element, index) => {
                        if (
                            element.mediaStateType.toLowerCase() === "reserved" ||
                            element.mediaStateType.toLowerCase() === "in stock" ||
                            element.mediaStateType.toLowerCase() === "maintenance" ||
                            element.mediaStateType.toLowerCase() === "decommissioned"
                        ) {
                            return (
                                <GroupuiSelectOption
                                    value={element.id.toString()}
                                    key={`select-option-${index}`}
                                >
                                    {element.mediaStateType}
                                </GroupuiSelectOption>
                            );
                        }
                    })}
                    <span slot="label">State *</span>
                </GroupuiSelect>
                <br />
                <GroupuiDatePicker
                    style={{ whiteSpace: "normal" }}
                    severity={severity}
                    value={started ?? undefined}
                    dateFormat="d.m.Y"
                    placeholder="DD.MM.YYYY"
                    required={true}
                    onGroupuiChange={(event) => setStarted(event.target.value)}
                >
                    <span slot="label">From *</span>
                </GroupuiDatePicker>
                <br />
                <GroupuiDatePicker
                    style={{ whiteSpace: "normal" }}
                    severity="none"
                    value={finished ?? undefined}
                    dateFormat="d.m.Y"
                    placeholder="DD.MM.YYYY"
                    onGroupuiChange={(event) => setFinished(event.target.value)}
                >
                    <span slot="label">To</span>
                </GroupuiDatePicker>
                <br />
                <br />
                {(selectedMediaState == getMediaStateId("Maintenance") ||
                    selectedMediaState == getMediaStateId("Decommissioned")) && (
                    <GenericInput
                        label="Ticket ID"
                        maxLength={50}
                        value={ticketId}
                        onGroupuiChange={(event: any) => setTicketId(event.target.value)}
                    />
                )}
                <GroupuiTextarea
                    typing-info-template="{characters-left}"
                    maxRows={4}
                    maxlength={200}
                    required={true}
                    rows={3}
                    severity="none"
                    value={comment}
                    onGroupuiChange={(event) => setComment(event.target.value)}
                >
                    <span slot="label">Comment</span>
                </GroupuiTextarea>
                <br />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "var(--groupui-sys-spacing-400)",
                    }}
                >
                    <GroupuiButton onClick={() => setModal(false)} variant={"secondary"}>
                        Cancel
                    </GroupuiButton>
                    <GroupuiButton
                        icon="save-32"
                        disabled={
                            selectedMediaState == null ||
                            started == null ||
                            selectedMediaState == undefined ||
                            started == undefined ||
                            selectedMediaState == "" ||
                            started == ""
                                ? true
                                : false
                        }
                        onClick={handleSubmit}
                    >
                        Save
                    </GroupuiButton>
                </div>
            </form>
        </GroupuiModal>
    );
};

export default EditStateModal;
