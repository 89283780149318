export class CancellationRequest {
    public orderId;
    public reasonForTermination;

    constructor({
        orderId,
        reasonForTermination,
    }: {
        orderId: number;
        reasonForTermination: string;
    }) {
        this.orderId = orderId;
        this.reasonForTermination = reasonForTermination;
    }

    private toObject() {
        return {
            orderId: this.orderId,
            reasonForTermination: this.reasonForTermination,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): CancellationRequest | null {
        if (json == null) {
            return null;
        }
        return new CancellationRequest({
            orderId: json["orderId"],
            reasonForTermination: json["reasonForTermination"],
        });
    }
}
