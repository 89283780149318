import { Ingest } from "./ingest";
import { MediaState } from "./mediaState";
import { Project } from "./project";

/**
 * A model class to discribe one history entry of an ingest slot, which is used to map the received json on an usable object
 */
export class HistoryEntry {
    private _uuid;
    private _id;
    public ingest;
    public created;
    public started;
    public finished;
    public mediaStateType;
    public reservedBy;
    public project;
    public duration;
    public result;
    public comment;
    public ticketId;

    constructor({
        uuid,
        id,
        ingest,
        created,
        started,
        finished,
        mediaStateType,
        reservedBy,
        project,
        duration,
        result,
        comment,
        ticketId,
    }: {
        uuid: number;
        id: number;
        ingest?: Ingest | null;
        created: string;
        started: string;
        finished: string;
        mediaStateType: MediaState;
        reservedBy?: string | null;
        project?: Project | null;
        duration?: number | null;
        result?: string | null;
        comment?: string | null;
        ticketId?: string | null;
    }) {
        this._uuid = uuid;
        this._id = id;
        this.ingest = ingest;
        this.created = created;
        this.started = started;
        this.finished = finished;
        this.mediaStateType = mediaStateType;
        this.reservedBy = reservedBy;
        this.project = project;
        this.duration = duration;
        this.result = result;
        this.comment = comment;
        this.ticketId = ticketId;
    }

    public get uuid(): number {
        return this._uuid;
    }

    public get id(): number {
        return this._id;
    }

    private toObject() {
        return {
            uuid: this._uuid,
            id: this._id,
            ingest: this.ingest,
            created: this.created,
            started: this.started,
            finished: this.finished,
            mediaStateType: this.mediaStateType,
            reservedBy: this.reservedBy,
            project: this.project,
            duration: this.duration,
            result: this.result,
            comment: this.comment,
            ticketId: this.ticketId,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): HistoryEntry | null {
        if (json == null) {
            return null;
        }

        return new HistoryEntry({
            uuid: json["uuid"],
            id: json["id"],
            ingest: Ingest.fromSerialized(json["ingest"]),
            created: json["created"],
            started: json["started"],
            finished: json["finished"],
            mediaStateType: MediaState.fromSerialized(json["mediaState"]),
            reservedBy: json["reservedBy"],
            project: Project.fromSerialized(json["project"]),
            duration: json["duration"],
            result: json["result"],
            comment: json["comment"],
            ticketId: json["ticketId"],
        });
    }
}
