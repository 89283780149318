import { GroupuiInput } from "@group-ui/group-ui-react";
import React from "react";
import {
    getTimeFromMillisecondsWithSeconds,
    parseFullDateWithMilliseconds,
} from "../../../utils/DateUtil";

const styles = {
    rowContainer: {
        display: "flex",
        gap: "var(--groupui-sys-spacing-400)",
        marginTop: "var(--groupui-sys-spacing-300)",
    },
};

interface IngestAdditionalInformationProps {
    databoxSerialNumber: string | undefined;
    databoxName: string | undefined;
    uploadID: string;
    ingestID: string;
    ingestUuid: string;
    ingestStationName: string;
    messageID: string;
    duration: string;
    ingestVersion: string;
    messageTimestamp: string;
    numberOfMeasurements: string;
    slotSerialNumber: string;
    discoveryID: string;
    ingestStart: string;
    csCallRetries: number;
    dataId: string;
    ingestAttempt: number;
    ingestAttempts: number;
    ingestJobId: number;
}

/**
 * A IngestAdditionalInformation Component, which displays additional information for running ingest
 *
 * @returns IngestAdditionalInformation Component
 *
 */
const IngestAdditionalInformation: React.FC<IngestAdditionalInformationProps> = ({
    databoxSerialNumber,
    uploadID,
    ingestID,
    ingestUuid,
    ingestStationName,
    messageID,
    duration,
    ingestVersion,
    messageTimestamp,
    numberOfMeasurements,
    slotSerialNumber,
    discoveryID,
    ingestStart,
    csCallRetries,
    dataId,
    ingestAttempt,
    ingestAttempts,
    ingestJobId,
}) => {
    return (
        <div>
            <div style={styles.rowContainer}>
                <GroupuiInput disabled={true} value={ingestUuid}>
                    <span slot="label">Ingest UUID</span>
                </GroupuiInput>
                <GroupuiInput disabled={true} value={dataId}>
                    <span slot="label">Data ID</span>
                </GroupuiInput>
            </div>
            <div style={styles.rowContainer}>
                <GroupuiInput disabled={true} value={ingestStationName}>
                    <span slot="label">Ingest Station</span>
                </GroupuiInput>
                <GroupuiInput disabled={true} value={databoxSerialNumber}>
                    <span slot="label">Databox Serial Number</span>
                </GroupuiInput>
                <GroupuiInput disabled={true} value={uploadID}>
                    <span slot="label">Upload ID</span>
                </GroupuiInput>
            </div>
            <div style={styles.rowContainer}>
                <GroupuiInput disabled={true} value={"I" + ingestID}>
                    <span slot="label">Ingest ID</span>
                </GroupuiInput>
                <GroupuiInput disabled={true} value={messageID}>
                    <span slot="label">Last Message ID</span>
                </GroupuiInput>
                <GroupuiInput
                    disabled={true}
                    value={getTimeFromMillisecondsWithSeconds(Number(duration))}
                >
                    <span slot="label">Time Passed until last Message</span>
                </GroupuiInput>
            </div>
            <div style={styles.rowContainer}>
                <GroupuiInput disabled={true} value={ingestVersion}>
                    <span slot="label">Ingest Version</span>
                </GroupuiInput>
                <GroupuiInput
                    disabled={true}
                    value={parseFullDateWithMilliseconds(messageTimestamp)}
                >
                    <span slot="label">Last Message Created</span>
                </GroupuiInput>
                <GroupuiInput disabled={true} value={numberOfMeasurements}>
                    <span slot="label">Number of Measurements (gmdm files)</span>
                </GroupuiInput>
            </div>
            <div style={styles.rowContainer}>
                <GroupuiInput disabled={true} value={slotSerialNumber}>
                    <span slot="label">Ingest Slot Serial Number</span>
                </GroupuiInput>
                <GroupuiInput disabled={true} value={discoveryID}>
                    <span slot="label">Discovery ID</span>
                </GroupuiInput>
                <GroupuiInput disabled={true} value={parseFullDateWithMilliseconds(ingestStart)}>
                    <span slot="label">Insertion Date</span>
                </GroupuiInput>
            </div>
            <div style={styles.rowContainer}>
                <GroupuiInput disabled={true} value={String(csCallRetries)}>
                    <span slot="label">CS Call Retries</span>
                </GroupuiInput>
                <GroupuiInput
                    disabled={true}
                    value={
                        ingestAttempt || ingestAttempts
                            ? String(ingestAttempt + "/" + ingestAttempts)
                            : "No data"
                    }
                >
                    <span slot="label">Ingest Attempts</span>
                </GroupuiInput>
                <GroupuiInput disabled={true} value={String("I" + ingestJobId)}>
                    <span slot="label">Releated Ingest Job</span>
                </GroupuiInput>
            </div>
        </div>
    );
};

export default IngestAdditionalInformation;
