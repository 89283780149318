import React from "react";
import { searchColumns } from "./GenericSearchBar";

export function searchData(data: any, searchQuery: { query: string; columns: searchColumns }) {
    if (Object.keys(searchQuery).length === 0) return data;
    const containsNameInSearchColumns = (row: any) => {
        let inRow = false;
        searchQuery["columns"].forEach((column: { column: string; displayName: string }) => {
            const value = row[column.column];
            if (
                value !== undefined &&
                value.toLowerCase().includes(searchQuery["query"].toLowerCase())
            ) {
                inRow = true;
            }
        });

        if (inRow) {
            return row;
        }
    };

    const currentQuery = searchQuery["query"];
    if (currentQuery !== "") {
        const newData = data?.filter(containsNameInSearchColumns);
        return newData;
    } else {
        return data;
    }
}
