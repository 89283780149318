import React from "react";
import { GroupuiDivider } from "@group-ui/group-ui-react";
import { RightsManagement } from "../../config/rightsManagement";
import { RightsWrapper } from "../container/RightsWrapper";
import GenericHeadline from "../generics/GenericHeadline";
import GenericInput from "../generics/GenericInput";
import ProjectTable from "../generics/GenericProjectTable";
import { InCarMount } from "../../models/incarmount";
interface Props {
    inCarMountsData: InCarMount;
    userGroups: string[];
}

const GeneralInformationReadOnly: React.FC<Props> = ({ inCarMountsData, userGroups }) => {
    return (
        <div>
            <div>
                <GenericHeadline
                    title={"General Information"}
                    titleHeadingType="h4"
                    actionButtons={[]}
                />
                <div style={{ display: "flex", gap: "var(--groupui-sys-spacing-1000)" }}>
                    <div style={{ width: "100%" }}>
                        <GenericInput
                            label={"Name"}
                            value={inCarMountsData?.name}
                            disabled={true}
                        />
                        <GenericInput
                            label={"Internal Project"}
                            value={inCarMountsData?.project?.name}
                            disabled={true}
                        />
                        <GenericInput
                            label={"Current Holder"}
                            value={inCarMountsData?.currentHolder}
                            disabled={true}
                        />
                        <GenericInput
                            label={"Version"}
                            value={inCarMountsData?.version}
                            disabled={true}
                        />
                        <GenericInput
                            label={"Status"}
                            value={inCarMountsData?.status}
                            disabled={true}
                        />
                        <GenericInput
                            label={"Owner"}
                            value={inCarMountsData?.owner}
                            disabled={true}
                        />
                        <RightsWrapper
                            authorizedUserGroup={RightsManagement.IN_CAR_MOUNT_DETAILS_INFO}
                            userGroups={userGroups}
                            section={false}
                        >
                            <GenericInput
                                label={"Registration Date"}
                                value={inCarMountsData?.registrationDate}
                                disabled={true}
                            />
                            <GenericInput
                                label={"PO Number"}
                                value={String(
                                    inCarMountsData?.poNumber !== null
                                        ? String(inCarMountsData?.poNumber)
                                        : ""
                                )}
                                disabled={true}
                            />
                            <GenericInput
                                label={"Shielding"}
                                value={inCarMountsData?.shielding ? "Yes" : "No"}
                                disabled={true}
                            />
                        </RightsWrapper>
                    </div>
                </div>
                <GroupuiDivider />
                <div>
                    <GenericHeadline
                        title="Additional Information"
                        titleHeadingType="h4"
                        actionButtons={[]}
                    />
                    <ProjectTable project={inCarMountsData?.project} />
                </div>
            </div>
        </div>
    );
};

export default GeneralInformationReadOnly;
