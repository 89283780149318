import {
    GroupuiButton,
    GroupuiModal,
    GroupuiText,
    GroupuiTextarea,
} from "@group-ui/group-ui-react";
import React, { useContext, useState } from "react";

import GenericHeadline from "../../generics/GenericHeadline";
import Snackbar from "../../generics/Snackbar";
import { msalInstance } from "../../../config/config";
import { InCarMountComment } from "../../../models/inCarMountsComments";
import { ApiService } from "../../../services/apiService";
import ErrorContext from "../../context/ErrorContext";
import { handleError } from "../../context/ErrorContextUtils";

interface AddCommentModalProps {
    modal: boolean;
    setModal: (value: boolean) => void;

    inCarMount: number;
}

const AddCommentModal: React.FC<AddCommentModalProps> = ({ modal, setModal, inCarMount }) => {
    const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
    const [severity, setSeverity] = useState<"danger" | "success" | "warning">();
    const [message, setMessage] = useState<string>();

    const [content, setContent] = useState<string>("");
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);

    const addZero = (time: number): string => {
        return String(time).length == 1 ? "0" + String(time) : String(time);
    };

    const handleSubmit = (event: any) => {
        const accounts = msalInstance.getAllAccounts();
        const userName = accounts[0]["name"];
        const created = new Date().toISOString();
        if (userName) {
            const comments = new InCarMountComment({
                inCarMountId: inCarMount,
                userName: userName,
                comment: content,
                created: created,
            });
            ApiService.addInCarMountComment(comments)
                .then((response) => {
                    if (response.toLowerCase() == "failed") {
                        event.preventDefault();
                        setShowSnackBar(true);
                        setSeverity("danger");
                        setMessage("Comment couldn`t be added!");
                    } else {
                        setSeverity("success");
                        setMessage("Successfully created a Comment!");
                        setShowSnackBar(true);
                    }
                })
                .catch((error) => {
                    handleError(error, changeErrorMessage, changeShowError);
                });
        } else {
            setShowSnackBar(true);
            setSeverity("danger");
            setMessage("An error occurred, while adding a comment");
        }

        setContent("");
    };

    const checkValues = (): boolean => {
        if (content == null || content === "" || content.length > 8000) return true;
        else return false;
    };

    return (
        <GroupuiModal
            closeButton={true}
            padding="20px"
            overflow={"visible"}
            displayed={modal}
            style={{
                justifyContent: "center",
                alignItems: "center",
            }}
            onGroupuiModalClose={() => setModal(false)}
        >
            {showSnackBar && (
                <Snackbar
                    severity={severity ?? "danger"}
                    message={message ?? "Some error occurred!"}
                    onClose={() => {
                        setShowSnackBar(false);
                    }}
                />
            )}
            <GenericHeadline
                title="Add Comment"
                actionButtons={[]}
                titleHeadingType="h4"
                type="modal"
            />
            <form onSubmit={handleSubmit} style={{ gap: "var(--groupui-sys-spacing-25)" }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "start",
                        gap: "var(--groupui-sys-spacing-200)",
                        marginBottom: "var(--groupui-sys-spacing-800)",
                    }}
                >
                    <GroupuiText>Please enter the content of you comment below.</GroupuiText>
                    <GroupuiTextarea
                        typing-info-template="{characters-left}"
                        maxRows={12}
                        maxlength={8000}
                        required={true}
                        rows={8}
                        severity="none"
                        value={content}
                        onGroupuiChange={(event) => setContent(event.target.value)}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "var(--groupui-sys-spacing-400)",
                    }}
                >
                    <GroupuiButton onClick={() => setModal(false)} variant={"secondary"}>
                        Cancel
                    </GroupuiButton>
                    <GroupuiButton
                        icon="add-32"
                        disabled={checkValues()}
                        type={"submit"}
                        style={{}}
                    >
                        Add
                    </GroupuiButton>
                </div>
            </form>
        </GroupuiModal>
    );
};

export default AddCommentModal;
