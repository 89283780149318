import React, { useEffect, useState } from "react";
import GenericHeadline from "../generics/GenericHeadline";
import GenericMoreButton from "../generics/GenericMoreButton";
import {
    GroupuiButton,
    GroupuiIcon,
    GroupuiLoadingSpinner,
    GroupuiTooltip,
} from "@group-ui/group-ui-react";
import NonStandardRequestLabelModal from "./NonStandardRequestLabelModal";
import useTitle from "../../hooks/useTitle";
import { NonStandardRequest } from "../../models/nonStandardRequest";
import { ApiService } from "../../services/apiService";
import GenericTable from "../generics/GenericTable";
import GenericTag from "../generics/GenericTag";
import { parseFullDate } from "../../utils/DateUtil";
import { useNavigate } from "react-router-dom";
import NonStandardRequestPickupModal from "./NonStandardRequestPickupModal";

const title = "Non-Standard Requests";

const NonStandardRequestsOverview: React.FC = () => {
    useTitle(title);
    const [showRequestLabelModal, setShowRequestLabelModal] = useState<boolean>(false);
    const [showRequestPickupModal, setShowRequestPickupModal] = useState<boolean>(false);
    const [dropDownState, setDropDownState] = useState(false);

    const [nonStandardRequests, setNonStandardRequests] = useState<
        NonStandardRequest[] | undefined
    >(undefined);

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        ApiService.getAllNonStandardRequests().then((n) => {
            setNonStandardRequests(n);
            setIsLoading(false);
        });
    }, []);

    return (
        <div
            style={{
                padding: "var(--groupui-sys-spacing-1200) var(--groupui-sys-spacing-1400)",
                scrollBehavior: "smooth",
                whiteSpace: "pre-wrap",
            }}
        >
            {isLoading && (
                <GroupuiLoadingSpinner
                    displayed={isLoading}
                    style={{
                        display: "flex",
                        position: "fixed",
                        height: "100%",
                        width: "100%",
                        zIndex: "1",
                        top: "0px",
                        left: "0px",
                        bottom: "0px",
                    }}
                />
            )}
            <GenericHeadline
                title={title}
                actionButtons={[
                    <GenericMoreButton
                        key={"More-Button-1"}
                        dropDownState={dropDownState}
                        setDropDownState={() => {
                            setDropDownState(!dropDownState);
                        }}
                        options={[
                            <GroupuiButton
                                key={"Option-1"}
                                variant="primary"
                                icon="service-bell-32"
                                fullwidth={true}
                                alignment="left"
                                onClick={() => {
                                    setShowRequestLabelModal(true);
                                    setDropDownState(false);
                                }}
                            >
                                Request Shipment Label
                            </GroupuiButton>,
                            <GroupuiButton
                                key={"Option-2"}
                                variant="primary"
                                icon="service-bell-32"
                                fullwidth={true}
                                alignment="left"
                                onClick={() => {
                                    setShowRequestPickupModal(true);
                                    setDropDownState(false);
                                }}
                            >
                                Request Pickup
                            </GroupuiButton>,
                        ]}
                    />,
                ]}
            />
            <NonStandardRequestLabelModal
                show={showRequestLabelModal}
                setShow={(show: boolean, reload: boolean) => {
                    setShowRequestLabelModal(show);
                    if (reload) {
                        setIsLoading(true);
                        ApiService.getAllNonStandardRequests().then((n) => {
                            setNonStandardRequests(n);
                            setIsLoading(false);
                        });
                    }
                }}
            />
            <NonStandardRequestPickupModal
                show={showRequestPickupModal}
                setShow={(show: boolean, reload: boolean) => {
                    setShowRequestPickupModal(show);
                    if (reload) {
                        setIsLoading(true);
                        ApiService.getAllNonStandardRequests().then((n) => {
                            setNonStandardRequests(n);
                            setIsLoading(false);
                        });
                    }
                }}
            />
            <GenericTable
                columns={[
                    { key: "id", header: "Request ID", width: "auto" },
                    { key: "databox", header: "Databox Name", width: "auto" },
                    { key: "type", header: "Type" },
                    { key: "origin", header: "Origin" },
                    { key: "destination", header: "Destination", width: "auto" },
                    { key: "state", header: "Current State", width: "auto" },
                    { key: "requester", header: "Requestor", width: "auto" },
                    { key: "created", header: "Created", width: "auto" },
                    { key: "updated", header: "Updated", width: "auto" },
                    { key: "details", header: "", width: "auto" },
                ]}
                data={(nonStandardRequests ?? [])
                    .sort((a: any, b: any) => {
                        return Date.parse(b.created) - Date.parse(a.created);
                    })
                    .map((e) => {
                        return {
                            id: `NSR${e.ID}`,
                            databox: e.databox.name,
                            type: e.type.trim(),
                            origin: e.pickupAddress.city,
                            destination: e.deliveryAddress ? e.deliveryAddress.city : "",
                            state: (
                                <GenericTag
                                    text={e.getNSRMediaState()}
                                    variant={"primary"}
                                    mediaStateTarget={"databox"}
                                />
                            ),
                            requester: e.requester.trim(),
                            created: parseFullDate(e.created),
                            updated: parseFullDate(e.lastUpdated),
                            details: (
                                <div
                                    style={{
                                        display: "flex",
                                        position: "relative",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    <GroupuiTooltip position="left">
                                        <GroupuiIcon
                                            name="search-plus-32"
                                            style={{
                                                cursor: "pointer",
                                                color: "var(--groupui-sys-color-action-basic-default)",
                                            }}
                                            onClick={() => {
                                                navigate(`./${e.ID}`);
                                            }}
                                        />
                                        <div slot="tooltip">Show Details</div>
                                    </GroupuiTooltip>
                                </div>
                            ),
                        };
                    })}
                header={true}
                searchColumns={[
                    { column: "id", displayName: "ID" },
                    { column: "databox", displayName: "Databox Name" },
                ]}
            />
        </div>
    );
};

export default NonStandardRequestsOverview;
