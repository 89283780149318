export class JiraTicket {
    public category;
    public subject;
    public description;
    public email;

    constructor({
        category,
        subject,
        description,
        email,
    }: {
        category: string;
        subject: string;
        description: string;
        email: string;
    }) {
        this.category = category;
        this.subject = subject;
        this.description = description;
        this.email = email;
    }
    private toObject() {
        return {
            category: this.category,
            subject: this.subject,
            description: this.description,
            email: this.email,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): JiraTicket | null {
        if (json == null) {
            return null;
        }
        return new JiraTicket({
            category: json["category"],
            subject: json["subject"],
            description: json["description"],
            email: json["email"],
        });
    }
}
