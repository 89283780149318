import React, { useContext, useEffect, useState } from "react";
import GenericHeadline from "../generics/GenericHeadline";
import GenericTable, { columnDefinition } from "../generics/GenericTable";
import useTitle from "../../hooks/useTitle";
import { ApiService } from "../../services/apiService";
import ErrorContext from "../context/ErrorContext";
import { handleError } from "../context/ErrorContextUtils";
import { parseFullDate } from "../../utils/DateUtil";
import { GroupuiTooltip, GroupuiIcon, GroupuiLoadingSpinner } from "@group-ui/group-ui-react";
import { Order } from "../../models/order";
import { useNavigate } from "react-router-dom";

const styles = {
    searchBarContainer: {
        marginBottom: "var(--groupui-sys-spacing-300)",
    },
    magnifyingGlass: {
        cursor: "pointer",
        color: "var(--groupui-sys-color-action-basic-default)",
    },
};

const columns: columnDefinition[] = [
    {
        key: "shipmentJobId",
        header: "Shipment Job ID",
        width: "auto",
    },
    {
        key: "databoxName",
        header: "Databox Name",
        width: "auto",
    },
    {
        key: "associatedOrderId",
        header: "Associated Order ID",
        width: "auto",
    },
    {
        key: "jobStatus",
        header: "Job Status",
        width: "auto",
    },
    {
        key: "created",
        header: "Created",
        width: "auto",
    },
    {
        key: "updated",
        header: "Updated",
        width: "auto",
    },
    {
        key: "button",
        width: "4rem",
    },
];

const ShipmentJobs = () => {
    useTitle("Shipment Jobs");
    const navigate = useNavigate();
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const [databoxOrders, setDataboxOrders] = useState<Order[] | null>();
    const [data, setData] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        ApiService.getAllShipmentJobsOverview()
            .then((response) => {
                setDataboxOrders(response);
                setIsLoading(false);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        if (databoxOrders)
            setData(
                databoxOrders
                    ?.map((order: Order) => {
                        return {
                            shipmentJobId: order.shipmentJob ? "SJ" + order.shipmentJob.id : "",
                            databoxName: order.databox.name,
                            associatedOrderId: "OR" + order.id,
                            jobStatus: order.shipmentJob?.jobStatus,
                            created: parseFullDate(order?.shipmentJob?.created),
                            updated: parseFullDate(order?.shipmentJob?.updated),
                            button: (
                                <GroupuiTooltip position="right">
                                    <GroupuiIcon
                                        name="search-plus-32"
                                        style={styles.magnifyingGlass}
                                        onClick={() =>
                                            navigate(
                                                `/shipment-jobs/${order.shipmentJob?.id ?? ""}`
                                            )
                                        }
                                    />
                                    <div slot="tooltip">Show Details</div>
                                </GroupuiTooltip>
                            ),
                        };
                    })
                    .reverse()
            );
    }, [databoxOrders]);

    return (
        <div
            style={{
                padding: "var(--groupui-sys-spacing-1200) var(--groupui-sys-spacing-1400)",
                scrollBehavior: "smooth",
                whiteSpace: "pre-wrap",
            }}
        >
            <GroupuiLoadingSpinner displayed={isLoading} />
            <GenericHeadline title="Shipment Jobs" actionButtons={[]} />
            <GenericTable
                columns={columns}
                data={data}
                header={true}
                pageSize={20}
                searchColumns={[
                    { column: "shipmentJobId", displayName: "Job ID" },
                    { column: "databoxName", displayName: "Databox" },
                    { column: "associatedOrderId", displayName: "Order" },
                ]}
                filtersAndSearchWidth={{ filters: "200px", search: "400px" }}
            />
        </div>
    );
};

export default ShipmentJobs;
