import React, { useContext } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import useTitle from "../../../hooks/useTitle";
import { GroupuiBreadcrumbs, GroupuiBreadcrumb } from "@group-ui/group-ui-react";
import { IngestsReportContext } from "../../context/provider/IngestsReportContext";

const styles = {
    contentContainer: {
        padding: "var(--groupui-sys-spacing-1200) var(--groupui-sys-spacing-1400)",
        scrollBehavior: "smooth",
        whiteSpace: "pre-wrap",
    } as React.CSSProperties,
};

const IngestsReportDetailsWrapper = () => {
    useTitle("Ingests Report Details");
    const navigate = useNavigate();
    const location = useLocation();
    const { currentIngestsReportCalendarWeek } = useContext(IngestsReportContext);

    return (
        <div style={styles.contentContainer}>
            <GroupuiBreadcrumbs>
                <GroupuiBreadcrumb onClick={() => navigate("/ingests-report")}>
                    Ingests Reports
                </GroupuiBreadcrumb>
                <GroupuiBreadcrumb
                    onClick={() =>
                        navigate(location.pathname.substring(0, location.pathname.lastIndexOf("/")))
                    }
                >
                    {currentIngestsReportCalendarWeek
                        ? `Ingest Report - IR${currentIngestsReportCalendarWeek}`
                        : `Ingest Report`}
                </GroupuiBreadcrumb>
                {/* Displays a additional Breadcrumb if the user matches the path of the following Route */}
            </GroupuiBreadcrumbs>
            {/* Needed to display the content given by the Nested Route */}
            <Outlet />
        </div>
    );
};

export default IngestsReportDetailsWrapper;
