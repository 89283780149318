import React from "react";
import { PhoneInput, defaultCountries } from "react-international-phone";
import "react-international-phone/style.css";

interface GenericPhoneInputProps {
    label: string;
    phoneNumber: string;
    onPhoneNumberChange?: (phone: string) => void;
    disabled?: boolean;
    name?: string;
    country?: string;
}

export const GenericPhoneInput: React.FC<GenericPhoneInputProps> = ({
    phoneNumber,
    onPhoneNumberChange,
    label,
    disabled,
    name,
    country,
}) => {
    const styles = {
        staticPhoneNumberFieldStyle: {
            width: "100%",
            height: "2.5rem",
            fontSize: "1rem",
            border: "0.0625rem solid var(--groupui-sys-color-action-basic-disabled)",
            padding: "var(--groupui-sys-spacing-200) var(--groupui-sys-spacing-300)",
            position: "relative",
            lineHeight: "1.5",
            letterSpacing: "0.0125rem",
            fontFamily: "GroupUI, sans-serif",
            borderRadius: "0.125rem",
            outline: "none",
            display: "flex",
            resize: "none",
            margin: "0px",
            boxSizing: "border-box",
            //marginBottom: "var(--groupui-sys-spacing-400)",
        } as React.CSSProperties,
        phoneNumberField: disabled
            ? ({
                  color: "var(--groupui-sys-color-action-basic-disabled)",
                  background: "var(--groupui-sys-color-action-silent-disabled)",
              } as React.CSSProperties)
            : ({
                  color: "var(--groupui-sys-color-text-weak)",
                  background: "white",
              } as React.CSSProperties),
        phoneNumberFieldLabel: {
            lineHeight: "1.5",
            letterSpacing: "0.0125rem",
            width: "100%",
            fontFamily: "GroupUI, sans-serif",
            position: "relative",
            fontWeight: "bold",
            fontSize: "0.875rem",
            display: "flex",
            alignItems: "center",
            transition: "transform 0.25s ease 0s, -webkit-transform 0.25s ease 0s",
            cursor: "default",
        } as React.CSSProperties,
        phoneNumberFieldDescription: {
            letterSpacing: "0.0125rem",
            width: "100%",
            fontFamily: "GroupUI, sans-serif",
            position: "relative",
            fontSize: "0.875rem",
            display: "flex",
            alignItems: "center",
            transition: "transform 0.25s ease 0s, -webkit-transform 0.25s ease 0s",
            cursor: "default",
        } as React.CSSProperties,
        buttonStyle: {
            height: "2.5rem",
            fontSize: "1rem",
            border: "0.0625rem solid var(--groupui-sys-color-action-basic-disabled)",
            padding: "var(--groupui-sys-spacing-200) var(--groupui-sys-spacing-300)",
            borderRadius: "0.125rem",
        } as React.CSSProperties,
    };

    return (
        <div>
            <div
                style={{
                    marginBottom: "var(--groupui-sys-spacing-100)",
                }}
            >
                <slot
                    name="label"
                    style={{
                        ...styles.phoneNumberFieldLabel,
                        color: disabled
                            ? "var(--groupui-sys-color-action-basic-disabled)"
                            : "var(--groupui-sys-color-text-weak)",
                    }}
                >
                    {label}
                </slot>
            </div>
            {onPhoneNumberChange ? (
                <PhoneInput
                    disabled={disabled}
                    defaultCountry="de"
                    countries={defaultCountries}
                    value={phoneNumber}
                    onChange={(phone) => onPhoneNumberChange(phone)}
                    inputStyle={{
                        ...styles.staticPhoneNumberFieldStyle,
                        ...styles.phoneNumberField,
                    }}
                    countrySelectorStyleProps={{
                        style: {
                            marginBottom: "var(--groupui-sys-spacing-100)",
                        },
                        buttonStyle: styles.buttonStyle,
                    }}
                />
            ) : (
                <PhoneInput
                    disabled={disabled}
                    defaultCountry="de"
                    countries={defaultCountries}
                    value={phoneNumber}
                    inputStyle={{
                        ...styles.staticPhoneNumberFieldStyle,
                        ...styles.phoneNumberField,
                    }}
                    countrySelectorStyleProps={{
                        style: {
                            marginBottom: "var(--groupui-sys-spacing-100)",
                        },
                        buttonStyle: styles.buttonStyle,
                    }}
                />
            )}

            {country === "United States" && phoneNumber.replace(/\D/g, "").length < 10 && (
                <slot
                    name="label"
                    style={{
                        ...styles.phoneNumberFieldDescription,
                        color: disabled
                            ? "var(--groupui-sys-color-action-basic-disabled)"
                            : "var(--groupui-sys-color-text-weak)",
                    }}
                >
                    Phone number needs to be at least 10 characters long!
                </slot>
            )}

            {country !== "United States" && phoneNumber.replace(/\D/g, "").length < 7 && (
                <slot
                    name="label"
                    style={{
                        ...styles.phoneNumberFieldDescription,
                        color: disabled
                            ? "var(--groupui-sys-color-action-basic-disabled)"
                            : "var(--groupui-sys-color-text-weak)",
                    }}
                >
                    Phone number needs to be at least 7 characters long!
                </slot>
            )}
        </div>
    );
};
