import React, { useContext, useEffect, useState } from "react";
import { GroupuiButton, GroupuiDivider } from "@group-ui/group-ui-react";
import GenericHeadline from "../../generics/GenericHeadline";
import GenericInput from "../../generics/GenericInput";
import {
    getHoursAndMinutesFromMilliseconds,
    parseDateOnly,
    parseFullDateFilename,
} from "../../../utils/DateUtil";
import { formatBytes } from "../../../utils/DataVolumeUtil";
import { IngestsReport } from "../../../models/reports/ingestsReport";
import useTitle from "../../../hooks/useTitle";
import { CSVLink } from "react-csv";
import { RightsWrapper } from "../../container/RightsWrapper";
import { RightsManagement } from "../../../config/rightsManagement";
import UserGroupContext from "../../context/UserGroupContext";
import GenericMoreButton from "../../generics/GenericMoreButton";
import GenericFilterBar from "../../generics/GenericFilter/GenericFilterBar";
import { filterData } from "../../generics/GenericFilter/FilterUtils";
import { IngestsReportContext } from "../../context/provider/IngestsReportContext";

const IngestsReportDetails = () => {
    useTitle("Ingests Report Details");
    const { userGroups } = useContext(UserGroupContext);
    const { currentIngestsReports, currentIngestsReportCalendarWeek } =
        useContext(IngestsReportContext);

    const [dropDownState, setDropDownState] = useState(false);
    const [aggregatedIngestsReport, setAggregatedIngestsReport] = useState<
        IngestsReport | undefined
    >();
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [filters, setFilters] = useState({});

    useEffect(() => {
        if (currentIngestsReports && currentIngestsReports.length > 0) {
            const newFilteredData = filterData(currentIngestsReports, filters);
            setFilteredData(newFilteredData);
        }
    }, [filters, currentIngestsReports]);

    useEffect(() => {
        if (filteredData && filteredData.length > 0) {
            const aggregatedData = filteredData.reduce(
                (a, b) =>
                    new IngestsReport({
                        id: 0,
                        from: a.from,
                        to: a.to,
                        ingestLocationName: "",
                        successfulIngests: a.successfulIngests + b.successfulIngests,
                        uploadDuration: a.uploadDuration + b.uploadDuration,
                        volume: a.volume + b.volume,
                        files: a.files + b.files,
                        created: a.created,
                        calenderWeek: a.calenderWeek,
                        ingestType: a.ingestType,
                    })
            );

            setAggregatedIngestsReport(aggregatedData);
        }
    }, [filteredData, filters]);

    const reportData = [
        [
            "reportId",
            "from",
            "to",
            "successfulIngests",
            "uploadedVolume",
            "uploadDuration",
            "uploadedFiles",
        ],
        [
            aggregatedIngestsReport?.displayName,
            parseDateOnly(aggregatedIngestsReport?.from),
            parseDateOnly(aggregatedIngestsReport?.to),
            String(aggregatedIngestsReport?.successfulIngests),
            aggregatedIngestsReport ? formatBytes(aggregatedIngestsReport.volume) : "",
            aggregatedIngestsReport
                ? getHoursAndMinutesFromMilliseconds(aggregatedIngestsReport.uploadDuration)
                : "",
            String(aggregatedIngestsReport?.files) ?? "0",
        ],
        [
            "locationName",
            "successfulIngests",
            "uploadedVolume",
            "uploadDuration",
            "uploadedFiles",
            "ingestType",
        ],
        ...(filteredData
            ? filteredData.map((ingestsReport) => [
                  ingestsReport.ingestLocationName,
                  String(ingestsReport.successfulIngests),
                  formatBytes(ingestsReport.volume),
                  getHoursAndMinutesFromMilliseconds(ingestsReport.uploadDuration),
                  String(ingestsReport.files),
                  ingestsReport.ingestType === "NT" && ingestsReport.ingestType != "NULL"
                      ? "Alliance Ingest"
                      : "CSI Ingest",
              ])
            : []),
    ];

    const groupedLocationData = filteredData.reduce((acc, ingestsReport) => {
        const locationName = ingestsReport.ingestLocationName;

        if (!acc[locationName]) {
            acc[locationName] = {
                successfulIngests: 0,
                volume: 0,
                uploadDuration: 0,
                files: 0,
                data: [],
            };
        }

        acc[locationName].successfulIngests += ingestsReport.successfulIngests || 0;
        acc[locationName].volume += ingestsReport.volume || 0;
        acc[locationName].uploadDuration += ingestsReport.uploadDuration || 0;
        acc[locationName].files += ingestsReport.files || 0;
        acc[locationName].data.push(ingestsReport);

        return acc;
    }, {});

    const hasNonNullType = currentIngestsReports?.some((report) => report.ingestType !== null);

    return (
        <>
            <GenericHeadline
                title={
                    currentIngestsReportCalendarWeek
                        ? `Ingest Report - IR${currentIngestsReportCalendarWeek}`
                        : `Ingest Report`
                }
                actionButtons={[
                    <RightsWrapper
                        key={"rights-first"}
                        authorizedUserGroup={RightsManagement.INGESTS_REPORT_ROUTES}
                        userGroups={userGroups}
                    >
                        <GenericMoreButton
                            key={"More-Button-1"}
                            dropDownState={dropDownState}
                            setDropDownState={() => {
                                setDropDownState(!dropDownState);
                            }}
                            options={[
                                <CSVLink
                                    key={"CSV-Link-1"}
                                    data={reportData ?? ""}
                                    separator={";"}
                                    filename={
                                        "Ingestst_Report" +
                                        "_" +
                                        parseFullDateFilename(new Date()) +
                                        ".csv"
                                    }
                                >
                                    <GroupuiButton
                                        key={"Option-1-1"}
                                        variant="primary"
                                        icon="download-32"
                                        fullwidth={true}
                                        alignment="left"
                                        onClick={() => {
                                            setDropDownState(false);
                                        }}
                                    >
                                        Download {"Ingests Report"}
                                    </GroupuiButton>
                                </CSVLink>,
                            ]}
                        />
                    </RightsWrapper>,
                ]}
            />
            {hasNonNullType && (
                <GenericFilterBar
                    filterColumns={[
                        {
                            column: "ingestType",
                            displayName: "All Ingests Type",
                            doSort: "asc",
                            customLabels: { default: "Alliance Ingest", NT: "CSI Ingest" },
                        },
                    ]}
                    filters={filters}
                    setFilters={setFilters}
                    data={currentIngestsReports || []}
                    showResetButton={false}
                />
            )}
            <GroupuiDivider />
            <GenericHeadline title="General Information" titleHeadingType="h4" actionButtons={[]} />
            <div style={{ display: "flex", gap: 40 }}>
                <div style={{ width: "50%" }}>
                    <GenericInput
                        label={"Report ID"}
                        value={aggregatedIngestsReport?.displayName}
                        disabled={true}
                    />
                    <GenericInput
                        label={"To"}
                        value={parseDateOnly(aggregatedIngestsReport?.to)}
                        disabled={true}
                    />
                </div>
                <div style={{ width: "50%" }}>
                    <GenericInput
                        label={"From"}
                        value={parseDateOnly(aggregatedIngestsReport?.from)}
                        disabled={true}
                    />
                </div>
            </div>
            <GroupuiDivider />
            <GenericHeadline title="Total" titleHeadingType="h4" actionButtons={[]} />
            <div style={{ display: "flex", gap: 40 }}>
                <div style={{ width: "50%" }}>
                    <GenericInput
                        label={"Successful Ingests"}
                        value={String(aggregatedIngestsReport?.successfulIngests)}
                        disabled={true}
                    />
                    <GenericInput
                        label={"Uploaded Volume"}
                        value={
                            aggregatedIngestsReport
                                ? formatBytes(aggregatedIngestsReport.volume)
                                : ""
                        }
                        disabled={true}
                    />
                </div>
                <div style={{ width: "50%" }}>
                    <GenericInput
                        label={"Upload Duration"}
                        value={
                            aggregatedIngestsReport
                                ? getHoursAndMinutesFromMilliseconds(
                                      aggregatedIngestsReport.uploadDuration
                                  )
                                : ""
                        }
                        disabled={true}
                    />
                    <GenericInput
                        label={"Uploaded Files"}
                        value={String(aggregatedIngestsReport?.files) ?? "0"}
                        disabled={true}
                    />
                </div>
            </div>
            <GroupuiDivider />
            <GenericHeadline title="Ingest Locations" titleHeadingType="h4" actionButtons={[]} />
            {Object.keys(groupedLocationData).map((locationName, index) => {
                const group = groupedLocationData[locationName];

                return (
                    <div key={`div-${index}-${Math.random()}`}>
                        <GenericHeadline
                            key={`header-sub-${index}`}
                            title={locationName}
                            titleHeadingType="h5"
                            actionButtons={[]}
                        />
                        <div
                            key={`div-${index}-${Math.random()}`}
                            style={{ display: "flex", gap: 40 }}
                        >
                            <div key={`div-${index}-${Math.random()}`} style={{ width: "50%" }}>
                                <GenericInput
                                    key={`Input-${index}-${Math.random()}`}
                                    label={"Successful Ingests"}
                                    value={String(group.successfulIngests)}
                                    disabled={true}
                                />
                                <GenericInput
                                    key={`Input-${index}-${Math.random()}`}
                                    label={"Uploaded Volume"}
                                    value={formatBytes(group.volume)}
                                    disabled={true}
                                />
                            </div>
                            <div key={`div-${index}-${Math.random()}`} style={{ width: "50%" }}>
                                <GenericInput
                                    key={`Input-${index}-${Math.random()}`}
                                    label={"Upload Duration"}
                                    value={getHoursAndMinutesFromMilliseconds(group.uploadDuration)}
                                    disabled={true}
                                />
                                <GenericInput
                                    key={`Input-${index}-${Math.random()}`}
                                    label={"Uploaded Files"}
                                    value={String(group.files)}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default IngestsReportDetails;
