import React, { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ShipmentJobs from "../shipmentJobs/ShipmentJobs";
import ShipmentJobsDetails from "../shipmentJobs/ShipmentJobsDetails";
import ShipmentJobsDetailsWrapper from "../shipmentJobs/ShipmentJobsDetailsWrapper";
import { RightsWrapper } from "../container/RightsWrapper";
import UserGroupContext from "../context/UserGroupContext";
import { RightsManagement } from "../../config/rightsManagement";
import { OrderProvider } from "../context/provider/OrderContext";

/**
 * A ShipmentJobsRoutes Component, which defines the Nested Routes nested under the parent for shipment-job-content
 *
 * @returns ShipmentJobsRoutes
 *
 */
function ShipmentJobsRoutes() {
    const { userGroups } = useContext(UserGroupContext);
    return (
        <RightsWrapper
            key={"first"}
            authorizedUserGroup={RightsManagement.SHIPMENT_JOBS_ROUTES}
            userGroups={userGroups}
            section={true}
        >
            <OrderProvider>
                <Routes>
                    <Route index element={<ShipmentJobs />} />
                    <Route path=":shipmentJobId/*" element={<ShipmentJobsDetailsWrapper />}>
                        <Route index element={<ShipmentJobsDetails />} />
                        {/* Redirect the user if the Route doesn´t exist */}
                        <Route path="*" element={<Navigate to="/shipment-jobs" />} />
                    </Route>
                </Routes>
            </OrderProvider>
        </RightsWrapper>
    );
}

export default ShipmentJobsRoutes;
