import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import GenericHeadline from "../generics/GenericHeadline";
import { GroupuiTab, GroupuiTabs } from "@group-ui/group-ui-react";
import ShipmentJobsDetailsGeneralTab from "./ShipmentJobsDetailsGeneralTab";
import { OrderContext } from "../context/provider/OrderContext";

const ShipmentJobsDetails = () => {
    const { shipmentJobId } = useParams();
    const { currentShipmentJob } = useContext(OrderContext);

    return (
        <>
            <GenericHeadline
                title={
                    currentShipmentJob
                        ? `Shipment Job Details - ${currentShipmentJob.displayName}`
                        : `Shipment Job Details`
                }
                actionButtons={[]}
            />
            {shipmentJobId !== undefined && (
                <GroupuiTabs>
                    <GroupuiTab tab="firstTabId" name="General">
                        <ShipmentJobsDetailsGeneralTab shipmentJobId={shipmentJobId} />
                    </GroupuiTab>
                </GroupuiTabs>
            )}
            {/* This case should not occur as it is handled in the routing, but lets keep some extra error handling. */}
            {shipmentJobId === undefined && (
                <>Something went wrong. Sorry! The shipment job has no ID, thus there is no data.</>
            )}
        </>
    );
};

export default ShipmentJobsDetails;
