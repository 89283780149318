import React from "react";

import { Databox } from "../../models/databox";
import { OrderProjectHistory } from "../../models/orderProjectHistory";
import GenericHeadline from "../generics/GenericHeadline";
import TrackerDataTable from "../generics/TrackerDataTable";
import GenericInput from "../generics/GenericInput";
import { parseDate, parseDateBasic } from "../../utils/DateUtil";
import { GroupuiDivider } from "@group-ui/group-ui-react";

interface Props {
    databoxData?: Databox;
    orderProjectHistory?: OrderProjectHistory | null;
}

const GeneralInformationView: React.FC<Props> = ({ databoxData, orderProjectHistory }) => {
    return (
        <div>
            <GenericHeadline
                title={"General Information"}
                titleHeadingType="h4"
                actionButtons={[]}
            />
            <div
                style={{
                    display: "flex",
                    gap: "var(--groupui-sys-spacing-800)",
                    paddingBottom: "var(--groupui-sys-spacing-200)",
                }}
            >
                <div style={{ width: "50%" }}>
                    <GenericInput label={"Name"} value={databoxData?.name} disabled={true} />

                    <GenericInput
                        label={"Internal Project"}
                        value={orderProjectHistory?.project?.name ?? ""}
                        disabled={true}
                    />
                    <GenericInput
                        label={"Current Holder"}
                        value={orderProjectHistory?.holderId ?? ""}
                        disabled={true}
                    />
                    <GenericInput label={"Owner"} value={databoxData?.owner} disabled={true} />
                    <GenericInput label={"Service"} value={""} disabled={true} />
                    <GenericInput
                        label={"Ingest Location"}
                        value={databoxData?.ingestStation?.name}
                        disabled={true}
                    />
                    <GenericInput
                        label={"Storage Capacity (TB)"}
                        value={databoxData?.storageCapacityDisplayName}
                        disabled={true}
                    />
                    <GenericInput
                        label={"Connector Type"}
                        value={databoxData?.databoxPortTypes}
                        disabled={true}
                    />
                </div>
                <div style={{ width: "50%" }}>
                    <GenericInput
                        label={"Serial Number"}
                        value={databoxData?.serialNumber}
                        disabled={true}
                    />
                    <GenericInput label={"Assigned Case"} value={""} disabled={true} />
                    <GenericInput
                        label={"Purchase Order Number & Date"}
                        value={`${databoxData?.purchaseOrderNumber ? "-" : ""}${
                            databoxData?.purchaseOrderDate
                                ? parseDateBasic(new Date(databoxData?.purchaseOrderDate))
                                : ""
                        }`}
                        disabled={true}
                    />
                    <GenericInput
                        label={"OPX Number"}
                        value={databoxData?.opxNumber ?? ""}
                        disabled={true}
                    />
                    <GenericInput
                        label={"Service Contract ID"}
                        value={databoxData?.serviceContractId ?? ""}
                        disabled={true}
                    />
                    <GenericInput
                        label={"Service Start Date"}
                        value={
                            databoxData?.serviceStartDate
                                ? parseDate(new Date(databoxData?.serviceStartDate))
                                : ""
                        }
                        disabled={true}
                    />
                    <GenericInput
                        label={"Service End Date"}
                        value={
                            databoxData?.serviceEndDate
                                ? parseDate(new Date(databoxData?.serviceEndDate))
                                : ""
                        }
                        disabled={true}
                    />
                </div>
            </div>
            <GroupuiDivider />
            <div>
                <GenericHeadline title="Tracker" titleHeadingType="h4" actionButtons={[]} />
                <GenericInput
                    label={"Tracker Name"}
                    value={databoxData?.tracker?.name}
                    maxLength={50}
                    disabled={true}
                    required
                />
                <TrackerDataTable trackerData={databoxData?.tracker?.trackerData ?? []} />
            </div>
        </div>
    );
};

export default GeneralInformationView;
