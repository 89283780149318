import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Databox } from "../../models/databox";
import { GroupuiMultiselect, GroupuiMultiselectOption } from "@group-ui/group-ui-react";
import { databox } from "../../models/types";
import { number } from "yargs";
interface Props {
    freeDataboxes: Databox[];
    specificDataboxes: string[];
    setSpecificDataboxes: Dispatch<SetStateAction<string[]>>;
    setSpecifcDataboxDetails: Dispatch<SetStateAction<object>>;
    disabled: boolean;
}

const SpecificDataboxSelect: React.FC<Props> = ({
    freeDataboxes,
    specificDataboxes,
    setSpecificDataboxes,
    setSpecifcDataboxDetails,
    disabled,
}) => {
    useEffect(() => {
        const selectedDataboxes = freeDataboxes.filter((databox) => {
            return specificDataboxes.includes(String(databox.id));
        });

        const databoxVolume = selectedDataboxes.reduce(
            (accumulator, currentValue) => accumulator + currentValue.storageCapacity,
            0
        );

        setSpecifcDataboxDetails({
            numberDataboxes: selectedDataboxes.length,
            databoxVolume: databoxVolume,
        });
    }, [specificDataboxes]);

    const compareDatabox = (a: Databox, b: Databox) => {
        if (
            !Number.isNaN(parseInt(a.name.replace("DX", ""))) &&
            !Number.isNaN(parseInt(b.name.replace("DX", "")))
        ) {
            return parseInt(a.name.replace("DX", "")) > parseInt(b.name.replace("DX", "")) ? 1 : -1;
        } else {
            return -1;
        }
    };

    return (
        <div style={{ width: "100%", paddingBottom: "var(--groupui-sys-spacing-500)" }}>
            <GroupuiMultiselect disabled={disabled} maxHeight="300px">
                {freeDataboxes.sort(compareDatabox).map((databox) => {
                    return (
                        <GroupuiMultiselectOption
                            value={String(databox.id)}
                            key={databox.id}
                            onCheckedChanged={(event) => {
                                if (event.target.checked === true) {
                                    const newDataboxSelection = [...specificDataboxes];

                                    newDataboxSelection.push(String(event.target.value));

                                    setSpecificDataboxes(newDataboxSelection);
                                } else {
                                    let newDataboxSelection = [...specificDataboxes];

                                    newDataboxSelection = newDataboxSelection.filter((databox) => {
                                        return databox !== event.target.value;
                                    });

                                    setSpecificDataboxes(newDataboxSelection);
                                }
                            }}
                        >
                            {databox.name}
                        </GroupuiMultiselectOption>
                    );
                })}
                <span slot="label">Databox Name</span>
                <span slot="description">
                    To be defined only if a specific databox is to be ordered
                </span>
            </GroupuiMultiselect>
        </div>
    );
};

export default SpecificDataboxSelect;
