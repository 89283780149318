export class MaintenanceSchedule {
    public id;
    public eventStartTime;
    public eventEndTime;
    public eventCompleted;
    public eventTitle;
    public eventMessage;

    constructor({
        id,
        eventStartTime,
        eventEndTime,
        eventCompleted,
        eventTitle,
        eventMessage,
    }: {
        id?: number;
        eventStartTime: string;
        eventEndTime: string;
        eventCompleted: boolean;
        eventTitle: string;
        eventMessage: string;
    }) {
        this.id = id;
        this.eventStartTime = eventStartTime;
        this.eventEndTime = eventEndTime;
        this.eventCompleted = eventCompleted;
        this.eventTitle = eventTitle;
        this.eventMessage = eventMessage;
    }

    private toObject() {
        return {
            id: this.id,
            eventStartTime: this.eventStartTime,
            eventEndTime: this.eventEndTime,
            eventCompleted: this.eventCompleted,
            eventTitle: this.eventTitle,
            eventMessage: this.eventMessage,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): MaintenanceSchedule | null {
        if (json == null) {
            return null;
        }
        return new MaintenanceSchedule({
            id: json["id"],
            eventStartTime: json["eventStartTime"],
            eventEndTime: json["eventEndTime"],
            eventCompleted: json["eventCompleted"],
            eventTitle: json["eventTitle"],
            eventMessage: json["eventMessage"],
        });
    }
}
