import React, { useContext, useEffect, useState } from "react";
import {
    GroupuiButton,
    GroupuiInlineNotification,
    GroupuiLoadingSpinner,
    GroupuiModal,
    GroupuiText,
} from "@group-ui/group-ui-react";
import Snackbar from "../../generics/Snackbar";
import GenericHeadline from "../../generics/GenericHeadline";
import { ApiService } from "../../../services/apiService";
import { Country } from "../../../models/country";
import ErrorContext from "../../context/ErrorContext";
import DropOffInformation from "./DropOffInformation";
import { PackageDropOff } from "../../../models/packageDropOff/packageDropOff";
import { handleError } from "../../context/ErrorContextUtils";
import { Order } from "../../../models/order";

interface ConfirmDropOffModalProps {
    modal: boolean;
    setModal: (value: boolean) => void;
    orders: Order[];
    onModalClose: () => void;
}

const ConfirmMultipleDropOffModal: React.FC<ConfirmDropOffModalProps> = ({
    modal,
    setModal,
    orders,
    onModalClose,
}) => {
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
    const [severity, setSeverity] = useState<"danger" | "success" | "warning">();
    const [message, setMessage] = useState<string>();
    const [isLoading, setIsLoading] = useState(false);

    const [addressLine1, setAddressLine1] = useState<string>("");
    const [addressLine2, setAddressLine2] = useState<string>("");
    const [zipCode, setZipCode] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [state, setState] = useState<string>("");
    const [country, setCountry] = useState<Country>();

    const [countries, setCountries] = useState<Country[] | null>();

    useEffect(() => {
        ApiService.getAllCountries()
            .then((response) => {
                setCountries(response);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
            });
    }, []);

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setIsLoading(true);

        try {
            const dropOffRequests = orders.map((order) => {
                if (order.id && country) {
                    const packageDropOff = new PackageDropOff({
                        addressLine1: addressLine1,
                        addressLine2: addressLine2,
                        zipCode: zipCode,
                        state: state,
                        city: city,
                        country: country,
                        created: "",
                    });

                    return ApiService.addPackageDropOff(Number(order.id), packageDropOff);
                } else {
                    return Promise.resolve(null);
                }
            });

            const responses = await Promise.all(dropOffRequests);

            const failed = responses.some((response) => response === null);

            if (failed) {
                setShowSnackBar(true);
                setSeverity("danger");
                setMessage("Some drop-offs couldn’t be added!");
            } else {
                setShowSnackBar(true);
                setSeverity("success");
                setMessage("Successfully added drop-off locations!");
            }

            setIsLoading(false); // Stop loading spinner before closing modal
            onModalClose();
        } catch (error) {
            handleError((error as Error).message, changeErrorMessage, changeShowError, true);
            setIsLoading(false); // Stop loading in case of error
        }
    };
    const checkDeliveryInputs = () => {
        return addressLine1 === "" || zipCode === "" || city === "" || country === undefined;
    };

    return (
        <GroupuiModal
            closeButton={true}
            padding="var(--groupui-sys-spacing-600)"
            overflow={"auto"}
            displayed={modal}
            margin="20px"
            maxWidth="70vw"
            l={12}
            xl={12}
            xxl={7}
            style={{
                justifyContent: "center",
                alignItems: "center",
            }}
            onGroupuiModalClose={() => setModal(false)}
        >
            <GroupuiLoadingSpinner displayed={isLoading} />
            {showSnackBar && (
                <Snackbar
                    severity={severity ?? "danger"}
                    message={message ?? "Some error occurred!"}
                    onClose={() => {
                        setShowSnackBar(false);
                    }}
                />
            )}
            <GenericHeadline
                title="Confirm Multiple Package Drop Off for:"
                actionButtons={[]}
                titleHeadingType="h3"
                type="modal"
            />
            <GroupuiText>
                <strong>
                    <i>
                        {orders.map((order, index) => (
                            <React.Fragment key={order.id}>
                                {order.databox.name}
                                {index < orders.length - 1 ? ", " : ""}
                                {index < orders.length - 1}
                            </React.Fragment>
                        ))}
                    </i>
                </strong>
            </GroupuiText>
            <br />
            <GroupuiText>
                Please Confirm that you have dropped off the package at the following access point:
            </GroupuiText>
            <br />
            {country &&
                orders.filter((order) => order.address?.country.country !== country?.country)
                    .length > 0 && (
                    <div>
                        <GroupuiInlineNotification
                            visible={true}
                            hideIcon={false}
                            severity="warning"
                            style={{
                                // Added because color from the component isn´t working
                                color: "var(--groupui-sys-color-warning-basic-default)",
                                backgroundColor: "var(--groupui-sys-color-warning-weak-default)",
                                // Added to avoided dropdowns to be hidden
                                zIndex: "0",
                            }}
                        >
                            <div
                                slot="headline"
                                // Added because color from the component isn´t working
                                style={{ color: "var(--groupui-sys-color-text-normal)" }}
                            >
                                Return Label
                            </div>
                            <div
                                slot="content"
                                // Added because color from the component isn´t working
                                style={{ color: "var(--groupui-sys-color-text-normal)" }}
                            >
                                A new return label will be created due to the changed country for
                                Databox(es):{" "}
                                {orders
                                    .filter(
                                        (order) =>
                                            order.address?.country.country !== country?.country
                                    )
                                    .map((order) => `${order.databox.name} (OR${order.id}); `)}
                            </div>
                        </GroupuiInlineNotification>
                        <br />
                    </div>
                )}
            <form onSubmit={handleSubmit}>
                <br />
                <DropOffInformation
                    addressLine1={addressLine1}
                    setAddressLine1={setAddressLine1}
                    addressLine2={addressLine2}
                    setAddressLine2={setAddressLine2}
                    zipCode={zipCode}
                    setZipCode={setZipCode}
                    city={city}
                    setCity={setCity}
                    state={state}
                    setState={setState}
                    country={country}
                    setCountry={setCountry}
                    countries={countries ?? null}
                />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "var(--groupui-sys-spacing-400)",
                        marginTop: "var(--groupui-sys-spacing-600)",
                    }}
                >
                    <GroupuiButton variant={"secondary"} onClick={() => setModal(false)}>
                        Cancel
                    </GroupuiButton>
                    <GroupuiButton
                        icon="service-bell-32"
                        disabled={checkDeliveryInputs()}
                        type={"submit"}
                    >
                        Confirm
                    </GroupuiButton>
                </div>
            </form>
        </GroupuiModal>
    );
};

export default ConfirmMultipleDropOffModal;
