import React, { useContext, useState } from "react";
import {
    GroupuiModal,
    GroupuiLoadingSpinner,
    GroupuiTextarea,
    GroupuiButton,
} from "@group-ui/group-ui-react";
import GenericHeadline from "./generics/GenericHeadline";
import GenericInput from "./generics/GenericInput";
import Snackbar from "./generics/Snackbar";
import GenericDateTimePicker from "./generics/GenericDateTimePicker";
import { ApiService } from "../services/apiService";
import { handleError } from "./context/ErrorContextUtils";
import { MaintenanceSchedule } from "../models/maintenanceSchedule";
import ErrorContext from "./context/ErrorContext";

interface AddMaintenanceMessageProps {
    showModal: boolean;
    setModal: (value: boolean) => void;
}

export const AddMaintenanceMessageModal: React.FC<AddMaintenanceMessageProps> = ({
    showModal,
    setModal,
}) => {
    // Parameter to show errors
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
    const [severity, setSeverity] = useState<"danger" | "success" | "warning">();
    const [message, setMessage] = useState<string>();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    // Holds the states of all input fields
    const [formData, setFormData] = useState({
        startTime: undefined,
        endTime: undefined,
        title: undefined,
        message: undefined,
    });

    // Hanfle input change for title & message
    const handleInputChange = (event: any) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    // Handle input change for startTime
    const handleInputChangeStartTime = (dateTime: any) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            ["startTime"]: dateTime,
        }));
    };

    // Handle input change for endTime
    const handleInputChangeEndTime = (dateTime: any) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            ["endTime"]: dateTime,
        }));
    };

    // Validates if all fields are set
    const validation = (): boolean => {
        if (!formData.startTime || !formData.endTime || !formData.title || !formData.message)
            return true;
        return false;
    };

    const submitValidation = (): boolean => {
        const dateRegExp = /^(\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])T\d{2}:\d{2})$/;
        if (validation()) return false;
        if (!formData.startTime || !formData.endTime || !formData.title || !formData.message) {
            showValidationSnackBar(
                "The selected State and the start date are either undefined, null or empty!"
            );
            return false;
        }

        if (!dateRegExp.test(formData.startTime)) {
            showValidationSnackBar("The selected start date is invalid!");
            return false;
        }

        if (!dateRegExp.test(formData.endTime)) {
            showValidationSnackBar("The selected end date is invalid!");
            return false;
        }

        if (new Date(formData.startTime) >= new Date(formData.endTime)) {
            showValidationSnackBar("The selected end date is before the selected start date!");
            return false;
        }

        return true;
    };

    const showValidationSnackBar = (
        message: string,
        severity?: "danger" | "success" | "warning"
    ) => {
        setSeverity(severity ?? "danger");
        setMessage(message);
        setShowSnackBar(true);
    };

    const handleSubmit = (): void => {
        setIsLoading(true);
        if (!submitValidation()) {
            setIsLoading(false);
            return;
        }

        const maintenanceSchedule = new MaintenanceSchedule({
            eventStartTime: new Date(formData.startTime!).toISOString(),
            eventEndTime: new Date(formData.endTime!).toISOString(),
            eventCompleted: false,
            eventTitle: formData.title!,
            eventMessage: formData.message!,
        });

        ApiService.addNewMaintenanceMessage(maintenanceSchedule)
            .then((response) => {
                // If response is null or undefined show as failed
                if (!response) {
                    showValidationSnackBar("New Maintenance Message couldn´t be added!");
                    return;
                }

                showValidationSnackBar("Successfully added a new Maintenance Message!", "success");
                const timeout = setTimeout(() => {
                    setFormData({
                        startTime: undefined,
                        endTime: undefined,
                        title: undefined,
                        message: undefined,
                    });
                    setModal(false);
                    location.reload();
                }, 1000);
            })
            .catch((error) => handleError(error, changeErrorMessage, changeShowError, true))
            .finally(() => setIsLoading(false));
    };

    return (
        <GroupuiModal
            displayed={showModal}
            onGroupuiModalClose={() => setModal(false)}
            overflow="visible"
            style={{ justifyContent: "center", alignItems: "center" }}
            closeButton={true}
        >
            <GroupuiLoadingSpinner displayed={isLoading} />
            {showSnackBar && (
                <Snackbar
                    severity={severity ?? "danger"}
                    message={message ?? "Some error occurred!"}
                    onClose={() => setShowSnackBar(false)}
                />
            )}
            <GenericHeadline
                title={"Add Maintenance Message"}
                titleHeadingType="h3"
                actionButtons={[]}
                type="modal"
            />
            <div
                style={{
                    display: "flex",
                    gap: "var(--groupui-sys-spacing-400)",
                    flexDirection: "column",
                }}
            >
                <GenericDateTimePicker
                    label={"Start Time"}
                    value={formData.startTime}
                    setValue={handleInputChangeStartTime}
                    required={true}
                />
                <GenericDateTimePicker
                    label={"End Time"}
                    value={formData.endTime}
                    setValue={handleInputChangeEndTime}
                    required={true}
                />
                <GenericInput
                    label={`Title *`}
                    name={"title"}
                    value={formData.title}
                    disabled={false}
                    onGroupuiChange={handleInputChange}
                    required={true}
                />
            </div>
            <GroupuiTextarea
                name={"message"}
                value={formData.message}
                disabled={false}
                onGroupuiChange={handleInputChange}
                required={true}
                rows={4}
            >
                <span slot="label">Message *</span>
            </GroupuiTextarea>
            <div
                style={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "var(--groupui-sys-spacing-400)",
                    marginTop: "var(--groupui-sys-spacing-600)",
                }}
            >
                <GroupuiButton variant={"secondary"} onClick={() => setModal(false)}>
                    Cancel
                </GroupuiButton>
                <GroupuiButton icon="gears-32" disabled={validation()} onClick={handleSubmit}>
                    Add
                </GroupuiButton>
            </div>
        </GroupuiModal>
    );
};
