import React from "react";
import "./App.css";
import LandingPage from "./components/LandingPage";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import PortalHeader from "./components/container/PortalHeader";
import RoleWrapper from "./components/container/RoleWrapper";
/**
 * Returns an App Component, which handels the content shown based on the authentication status of the user based on the MsalProvider
 *
 * While unauthenticated, the component returns a static login screen
 * After authentication, a Router manages the different available endpoints and navigation between contents
 * @returns App Component
 *
 */
export default function App() {
    return (
        <div className="App" data-testid="app">
            <AuthenticatedTemplate>
                <RoleWrapper />
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <PortalHeader iconOnly={false} />
                <LandingPage />
            </UnauthenticatedTemplate>
        </div>
    );
}
