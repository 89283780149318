import React, { useState } from "react";
import { GroupuiButton, GroupuiLoadingSpinner, GroupuiModal } from "@group-ui/group-ui-react";
import GenericHeadline from "../generics/GenericHeadline";
import GenericInput from "../generics/GenericInput";
import { Address } from "../../models/address";
import { ApiService } from "../../services/apiService";
import Snackbar from "../generics/Snackbar";
import { GenericAddressInformationFields } from "../generics/GenericAddressInformationFields";
import { Countries } from "../../enums/Countries";
import { USStatesList } from "../../enums/USStatesList";
import DualAddressValidationModal from "./DualAddressValidationModal";
import { Country } from "../../models/country";
import { Company } from "../../models/company";

interface NonStandardRequestLabelModalProps {
    show: boolean;
    setShow: (show: boolean, reload: boolean) => void;
}

const NonStandardRequestLabelModal: React.FC<NonStandardRequestLabelModalProps> = ({
    show,
    setShow,
}) => {
    const [showSnackbar, setShowSnackBar] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "danger" | "warning">(
        "success"
    );

    const [databoxName, setDataboxName] = useState<string>("");
    const [jiraTicket, setJiraTicket] = useState<string>("");

    const [pickupAddress, setPickupAddress] = useState<Address | undefined>(undefined);
    const [deliveryAddress, setDeliveryAddress] = useState<Address | undefined>(undefined);
    const [validatePickupAddress, setValidatePickupAddress] = useState<boolean>(true);
    const [validateDeliveryAddress, setValidateDeliveryAddress] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState(false);

    const [addressValidation, setAddressValidation] = useState<{
        show: boolean;
        suggestedPickupAddress: Address | undefined;
        suggestedDeliveryAddress: Address | undefined;
    }>({ show: false, suggestedPickupAddress: undefined, suggestedDeliveryAddress: undefined });

    const checkDeliveryInputs = (address: Address | undefined) => {
        return !address ||
            address.fullName.length > 22 ||
            address.fullName.trim() === "" ||
            address.street.trim() === "" ||
            address.zipCode.trim() === "" ||
            address.city.trim() === "" ||
            !address.country ||
            !address.company ||
            address.email.trim() === "" ||
            address.phone.trim() === "" ||
            address.phone.trim().length < 7 ||
            address.country.country === Countries.UNITED_STATES
            ? !USStatesList.filter((usState) => usState.toLowerCase()).includes(
                  address?.state?.trim() ?? ""
              ) || address?.state?.trim() == ""
            : false;
    };

    function resetForm() {
        setDataboxName("");
        setJiraTicket("");
        setPickupAddress(
            new Address({
                id: undefined,
                fullName: "",
                street: "",
                addressSupplement: "",
                zipCode: "",
                city: "",
                state: "",
                country: new Country({ id: -1, country: "" }),
                company: new Company({ id: -1, company: "" }),
                email: "",
                phone: "",
            })
        );
        setDeliveryAddress(undefined);
    }

    return (
        <>
            {showSnackbar && (
                <Snackbar
                    severity={snackbarSeverity}
                    message={snackbarMessage}
                    onClose={() => setShowSnackBar(false)}
                />
            )}
            <GroupuiModal
                displayed={show}
                onGroupuiModalClose={() => {
                    resetForm();
                    setShow(false, false);
                }}
                closeButton={true}
                overflow="auto"
                margin="100px"
                padding="2rem"
                l={12}
                xl={12}
                xxl={7}
                style={{
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {isLoading && (
                    <GroupuiLoadingSpinner
                        displayed={isLoading}
                        style={{
                            display: "flex",
                            position: "fixed",
                            height: "100%",
                            width: "100%",
                            zIndex: "1",
                            top: "0px",
                            left: "0px",
                            bottom: "0px",
                        }}
                    />
                )}
                <GenericHeadline
                    title="Request Shipment Label"
                    titleHeadingType="h3"
                    type="modal"
                    actionButtons={[]}
                />
                <div>
                    <GenericHeadline
                        title="Pickup Address"
                        titleHeadingType="h5"
                        actionButtons={[]}
                    />
                    <GenericAddressInformationFields
                        address={pickupAddress}
                        setAddress={setPickupAddress}
                        disabled={false}
                        validateAddress={validatePickupAddress}
                        setValidateAddress={setValidatePickupAddress}
                    />
                </div>
                <div>
                    <GenericHeadline
                        title="Delivery Address"
                        titleHeadingType="h5"
                        actionButtons={[]}
                    />
                    <GenericAddressInformationFields
                        address={deliveryAddress}
                        setAddress={setDeliveryAddress}
                        disabled={false}
                        validateAddress={validateDeliveryAddress}
                        setValidateAddress={setValidateDeliveryAddress}
                    />
                </div>
                <GenericHeadline
                    title="Additional Information"
                    titleHeadingType="h5"
                    actionButtons={[]}
                />
                <GenericInput
                    label={"Databox Name *"}
                    value={databoxName}
                    onGroupuiChange={(event: any) => setDataboxName(event.target.value)}
                />
                <GenericInput
                    label={"Jira Ticket ID *"}
                    value={jiraTicket}
                    onGroupuiChange={(event: any) => setJiraTicket(event.target.value)}
                />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "var(--groupui-sys-spacing-400)",
                        marginTop: "var(--groupui-sys-spacing-600)",
                    }}
                >
                    <GroupuiButton variant={"secondary"} onClick={() => setShow(false, false)}>
                        Cancel
                    </GroupuiButton>
                    <GroupuiButton
                        variant="primary"
                        icon="service-bell-32"
                        fullwidth={false}
                        alignment="left"
                        onClick={() => {
                            if (pickupAddress && deliveryAddress) {
                                const pa = Address.fromSerialized({
                                    ...pickupAddress,
                                    phone: pickupAddress.phone.replaceAll(/[ -()-]+/g, ""),
                                });
                                const da = Address.fromSerialized({
                                    ...deliveryAddress,
                                    phone: deliveryAddress.phone.replaceAll(/[ -()-]+/g, ""),
                                });
                                if (pa !== null && da !== null) {
                                    setIsLoading(true);
                                    ApiService.createNonStandardRequestLabel(
                                        pa,
                                        validatePickupAddress,
                                        da,
                                        validateDeliveryAddress,
                                        databoxName,
                                        jiraTicket
                                    ).then((r) => {
                                        console.log(r);
                                        if (r.ID !== undefined) {
                                            setSnackbarMessage(
                                                `Non-Standard Request was successfully created - NSR${r.ID}`
                                            );
                                            setSnackbarSeverity("success");
                                            setShowSnackBar(true);
                                            resetForm();
                                            setIsLoading(false);
                                            setShow(false, true);
                                        } else if (r.error) {
                                            setSnackbarMessage(r.error);
                                            setSnackbarSeverity("danger");
                                            setIsLoading(false);
                                            setShowSnackBar(true);
                                        } else if (
                                            r.suggestedDeliveryAddress !== undefined ||
                                            r.suggestedPickupAddress !== undefined
                                        ) {
                                            setAddressValidation({
                                                show: true,
                                                suggestedDeliveryAddress:
                                                    r.suggestedDeliveryAddress,
                                                suggestedPickupAddress: r.suggestedPickupAddress,
                                            });
                                            setIsLoading(false);
                                        } else {
                                            setSnackbarMessage("Unknown error!");
                                            setSnackbarSeverity("danger");
                                            setIsLoading(false);
                                            setShowSnackBar(true);
                                        }
                                    });
                                }
                            }
                        }}
                        disabled={
                            (!checkDeliveryInputs(pickupAddress) &&
                                !checkDeliveryInputs(deliveryAddress) &&
                                databoxName.length > 0 &&
                                jiraTicket.length > 0) === false
                        }
                    >
                        Request
                    </GroupuiButton>
                    <DualAddressValidationModal
                        address1={pickupAddress}
                        suggestedAddress1={addressValidation.suggestedPickupAddress}
                        address1Title={"Pickup Address"}
                        address2={deliveryAddress}
                        suggestedAddress2={addressValidation.suggestedDeliveryAddress}
                        address2Title={"Delivery Address"}
                        onDecision={function (isAccepted: boolean): void {
                            if (isAccepted) {
                                if (addressValidation.suggestedDeliveryAddress !== undefined)
                                    setDeliveryAddress(addressValidation.suggestedDeliveryAddress);
                                if (addressValidation.suggestedPickupAddress !== undefined)
                                    setPickupAddress(addressValidation.suggestedPickupAddress);
                            }
                            setAddressValidation({
                                show: false,
                                suggestedDeliveryAddress: undefined,
                                suggestedPickupAddress: undefined,
                            });
                        }}
                        show={addressValidation.show}
                        setShow={setAddressValidation}
                    />
                </div>
            </GroupuiModal>
        </>
    );
};

export default NonStandardRequestLabelModal;
