import {
    GroupuiModal,
    GroupuiLoadingSpinner,
    GroupuiSelect,
    GroupuiSelectOption,
    GroupuiTextarea,
    GroupuiButton,
} from "@group-ui/group-ui-react";
import GenericDateTimePicker from "../../../generics/GenericDateTimePicker";
import GenericHeadline from "../../../generics/GenericHeadline";
import GenericInput from "../../../generics/GenericInput";
import Snackbar from "../../../generics/Snackbar";
import InternalProject from "../../../requests/orderDataboxes/InternalProject";
import React, { useContext, useEffect, useState } from "react";
import { ApiService } from "../../../../services/apiService";
import ErrorContext from "../../../context/ErrorContext";
import { MediaState } from "../../../../models/mediaState";
import { Order } from "../../../../models/order";
import { IngestSlotBooking } from "../../../../models/ingestSlot/ingestSlotBooking";
import { useParams } from "react-router";
import { msalInstance } from "../../../../config/config";
import { parseDateForDatetimeInputField, parseFullDate } from "../../../../utils/DateUtil";
import { handleError } from "../../../context/ErrorContextUtils";

const styles = {
    modal: { justifyContent: "center", alignItems: "center" } as React.CSSProperties,
};

export enum IngestSlotStateMode {
    ADD,
    EDIT,
    EDIT_CURRENT,
}

interface IngestSlotStateProps {
    showModal: boolean;
    setShowModal: (value: boolean) => void;
    mode?: IngestSlotStateMode;
    onModalClose: () => void;
    onSend?: () => void;
    data?: any;
}

export const IngestSlotStateModal: React.FC<IngestSlotStateProps> = ({
    showModal,
    setShowModal,
    mode,
    onModalClose,
    onSend,
    data,
}) => {
    // Page paramenters
    const { ingestSlotId } = useParams();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // Display errors
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
    const [severity, setSeverity] = useState<"danger" | "success" | "warning">();
    const [message, setMessage] = useState<string>();

    // Inital data needed
    const [mediaStatesData, setMediaStatesData] = useState<MediaState[]>([]);
    const [orders, setOrders] = useState<Order[] | null>([]);

    // Ingest Slot Booking Data
    const [selectedMediaState, setSelectedMediaState] = useState<string>();
    const [started, setStarted] = useState<string>();
    const [finished, setFinished] = useState<string>();
    const [orderId, setOrderId] = useState<string>();
    const [ticketId, setTicketId] = useState<string>();
    const [projectId, setProjectId] = useState<string>();
    const [comment, setComment] = useState<string>();

    useEffect(() => {
        ApiService.getMediaStates()
            .then((response) => {
                setMediaStatesData(response);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
            });
        ApiService.getAllOrdersOverview()
            .then((response) => {
                setOrders(response);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
            });
    }, []);

    useEffect(() => {
        if (
            data &&
            (mode === IngestSlotStateMode.EDIT || mode === IngestSlotStateMode.EDIT_CURRENT)
        ) {
            setSelectedMediaState(JSON.stringify(data?.mediaState));
            setStarted(parseDateForDatetimeInputField(data.started));
            setFinished(parseDateForDatetimeInputField(data.finished));
            setOrderId(data.orderId);
            setTicketId(data.ticketId);
            if (mode === IngestSlotStateMode.EDIT) {
                data.projectsId ? setProjectId(data.projectsId) : setProjectId(undefined);
            } else if (mode === IngestSlotStateMode.EDIT_CURRENT) {
                data.project ? setProjectId(data.project.id) : setProjectId(undefined);
            }
            setComment(data.comment);
        } else {
            setSelectedMediaState(undefined);
            setStarted(undefined);
            setFinished(undefined);
            setOrderId(undefined);
            setProjectId(undefined);
            setTicketId(undefined);
            setComment(undefined);
        }
    }, [data, showModal]);

    const handleIngestSlotValidation = (event: any): boolean => {
        const dateRegExp = /^(\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])T\d{2}:\d{2})$/;
        if (!selectedMediaState || !started) {
            event.preventDefault();
            showValidationError(
                "The selected State and the start date are either undefined, null or empty!"
            );
            return false;
        }
        if (!dateRegExp.test(started)) {
            event.preventDefault();
            showValidationError("The selected start date is invalid!");
            return false;
        }
        if (mode === IngestSlotStateMode.ADD) {
            const now = new Date();
            now.setMinutes(new Date().getMinutes() - 1);
            if (now > new Date(started)) {
                event.preventDefault();
                showValidationError("The selected start date is in the past!");
                return false;
            }
        }
        if (finished) {
            if (new Date(started) >= new Date(finished)) {
                event.preventDefault();
                showValidationError("The selected end date is before the selected start date!");
                return false;
            }
            if (!dateRegExp.test(finished)) {
                event.preventDefault();
                showValidationError("The selected end date is invalid!");
                return false;
            }
        }
        if (orderId) {
            if (
                !orders?.some((order) => {
                    if (order.id == Number(orderId)) {
                        return true;
                    }
                })
            ) {
                event.preventDefault();
                showValidationError("The selected Order ID doesn´t exist!");
                return false;
            }
        }
        if (!ticketId && selectedMediaState.includes("Maintenance")) {
            event.preventDefault();
            showValidationError("The Ticket ID is missing!");
            return false;
        }
        return true;
    };

    const showValidationError = (message: string) => {
        setSeverity("danger");
        setMessage(message);
        setShowSnackBar(true);
    };

    const handleSubmit = (event: any) => {
        if (handleIngestSlotValidation(event)) {
            event.preventDefault();
            setIsLoading(true);
            if (mode === IngestSlotStateMode.ADD) {
                const ingestSlotBooking: IngestSlotBooking = createNewIngestSlotBooking();

                ApiService.addIngestSlotBooking(ingestSlotBooking)
                    .then((response: any) => {
                        if (response.toLowerCase() == "failed") {
                            setShowSnackBar(true);
                            setSeverity("danger");
                            setMessage("Booking couldn`t be added!");
                        } else {
                            showSuccessAndClose();
                        }
                    })
                    .catch((error) => {
                        handleError(error, changeErrorMessage, changeShowError);
                        setIsLoading(false);
                    });
            } else if (mode === IngestSlotStateMode.EDIT) {
                const ingestSlotBooking: IngestSlotBooking = createNewIngestSlotBooking();

                ApiService.updateFutureIngestSlotBooking(ingestSlotBooking)
                    .then((response: any) => {
                        if (response.toLowerCase() == "failed") {
                            setShowSnackBar(true);
                            setSeverity("danger");
                            setMessage("Booking couldn`t be updated!");
                        } else {
                            showSuccessAndClose();
                        }
                    })
                    .catch((error) => {
                        handleError(error, changeErrorMessage, changeShowError);
                        setIsLoading(false);
                    });
            } else if (mode === IngestSlotStateMode.EDIT_CURRENT) {
                const ingestSlotBooking: IngestSlotBooking = createNewIngestSlotBooking();

                ApiService.updateCurrentIngestSlotBooking(ingestSlotBooking)
                    .then((response: any) => {
                        if (response.toLowerCase() == "failed") {
                            setShowSnackBar(true);
                            setSeverity("danger");
                            setMessage("Booking couldn`t be updated!");
                        } else {
                            showSuccessAndClose();
                        }
                    })
                    .catch((error) => {
                        handleError(error, changeErrorMessage, changeShowError);
                        setIsLoading(false);
                    });
            }
        }
    };

    const createNewIngestSlotBooking = (): IngestSlotBooking => {
        return new IngestSlotBooking({
            id: mode === IngestSlotStateMode.ADD ? 0 : data.id,
            ingestSlotId: Number(ingestSlotId),
            created: new Date().toISOString(),
            started: started ? new Date(started).toISOString() : "",
            finished: finished ? new Date(finished).toISOString() : "",
            mediaState: MediaState.fromSerialized(JSON.parse(selectedMediaState!)),
            by: msalInstance.getAllAccounts()[0].name ?? "",
            comment: !comment || comment === "" ? null : comment,
            orderId: orderId && selectedMediaState!.includes("Reserved") ? Number(orderId) : null,
            projectsId: projectId ? Number(projectId) : null,
            project: null,
            ticketId:
                !ticketId || ticketId === "" || !selectedMediaState!.includes("Maintenance")
                    ? null
                    : ticketId,
        });
    };

    const showSuccessAndClose = () => {
        setSeverity("success");
        mode === IngestSlotStateMode.ADD
            ? setMessage("Successfully created a Booking!")
            : setMessage("Successfully updated the Booking!");
        setShowSnackBar(true);
        const timeout = setTimeout(() => {
            mode === IngestSlotStateMode.ADD ? onModalClose() : onSend!();
            setShowSnackBar(false);
            setSeverity(undefined);
            setSelectedMediaState(undefined);
            setStarted(undefined);
            setFinished(undefined);
            setOrderId(undefined);
            setProjectId(undefined);
            setTicketId(undefined);
            setComment(undefined);
            setIsLoading(false);
        }, 2000);
    };

    return (
        <GroupuiModal
            closeButton={true}
            padding="var(--groupui-sys-spacing-800)"
            displayed={showModal}
            style={styles.modal}
            onGroupuiModalClose={() => setShowModal(false)}
        >
            <GroupuiLoadingSpinner displayed={isLoading} />
            {showSnackBar && (
                <Snackbar
                    severity={severity ?? "danger"}
                    message={message ?? "Some error occurred!"}
                    onClose={() => {
                        setShowSnackBar(false);
                    }}
                />
            )}
            <GenericHeadline
                title={mode === IngestSlotStateMode.ADD ? "Add State" : "Edit State"}
                actionButtons={[]}
                titleHeadingType="h3"
                type="modal"
            />
            <form onSubmit={handleSubmit}>
                <GroupuiSelect
                    value={selectedMediaState}
                    severity={severity}
                    typeof="input"
                    onGroupuiChange={(event) => setSelectedMediaState(event.target.value)}
                >
                    {mediaStatesData.map((element, index) => {
                        if (
                            element.mediaStateType.toLowerCase() === "reserved" ||
                            element.mediaStateType.toLowerCase() === "maintenance" ||
                            element.mediaStateType.toLowerCase() === "decommissioned" ||
                            element.mediaStateType.toLowerCase() === "alliance"
                        ) {
                            return (
                                <GroupuiSelectOption
                                    value={element.serialize()}
                                    key={`select-option-${index}`}
                                >
                                    {element.mediaStateType}
                                </GroupuiSelectOption>
                            );
                        }
                    })}
                    <span slot="label">State *</span>
                </GroupuiSelect>
                <br />
                <div>
                    <GenericDateTimePicker
                        label={"From"}
                        value={started}
                        setValue={setStarted}
                        required={true}
                    />
                    <br />
                    <GenericDateTimePicker
                        label={"To"}
                        value={finished}
                        setValue={setFinished}
                        required={false}
                    />
                    <br />
                    {selectedMediaState?.includes("Reserved") && (
                        <GenericInput
                            label={"Order ID"}
                            value={orderId}
                            onGroupuiChange={(event: any) => setOrderId(event.target.value)}
                        />
                    )}
                    <InternalProject
                        project={projectId}
                        setProject={setProjectId}
                        disabled={false}
                        required={false}
                    />
                    <br />
                    {selectedMediaState?.includes("Maintenance") && (
                        <GenericInput
                            label={"Ticket ID *"}
                            required={true}
                            value={ticketId}
                            onGroupuiChange={(event: any) => setTicketId(event.target.value)}
                        />
                    )}
                    <GroupuiTextarea
                        value={comment}
                        disabled={false}
                        onGroupuiChange={(event) => setComment(event.target.value)}
                        rows={4}
                        maxlength={200}
                    >
                        <span slot="label">Comment</span>
                    </GroupuiTextarea>
                </div>
                <br />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "var(--groupui-sys-spacing-400)",
                    }}
                >
                    <GroupuiButton onClick={() => setShowModal(false)} variant={"secondary"}>
                        Cancel
                    </GroupuiButton>
                    <GroupuiButton
                        icon={mode === IngestSlotStateMode.ADD ? "add-32" : "save-32"}
                        disabled={
                            !selectedMediaState || !started
                                ? true
                                : false || (selectedMediaState.includes("Maintenance") && !ticketId)
                        }
                        type={"submit"}
                    >
                        {mode === IngestSlotStateMode.ADD ? "Add" : "Save"}
                    </GroupuiButton>
                </div>
            </form>
        </GroupuiModal>
    );
};
