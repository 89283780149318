import React, { useEffect, useState } from "react";
import { GroupuiStep, GroupuiStepperHorizontal } from "@group-ui/group-ui-react";

const styles = {
    stepper: {
        textAlign: "center",
    },
    stepperRow: {
        display: "flex",
    },
    stepperContainer: {
        display: "flex",
        flexDirection: "column",
    } as React.CSSProperties,
};

const steps = [
    "Ingest started",
    "Pre-Upload started",
    "Upload data file started",
    "Upload data file progress",
    "Upload data file finished",
    "Ingest finished",
];

const stepMap = {
    ingest_started: "Ingest started",
    pre_upload_started: "Pre-Upload started",
    upload_data_file_started: "Upload data file started",
    upload_data_file_progress: "Upload data file progress",
    upload_data_file_finished: "Upload data file finished",
    ingest_finished: "Ingest finished",
};

interface IngestStatusProps {
    step: string;
}

/**
 * A IngestSteps Component, which displays all made steps in the ingest-process
 *
 * @returns IngestSteps Component
 *
 */
const IngestSteps: React.FC<IngestStatusProps> = ({ step }) => {
    const [progressStepIndex, setProgressStepIndex] = useState<number>(
        steps.indexOf(stepMap[step], 0)
    );
    const [allActive, setAllActive] = useState<boolean>(false);
    useEffect(() => {
        if (step === "DONE") {
            setProgressStepIndex(steps.indexOf("Ingest finished", 0));
            setAllActive(true);
        }
    }, [step]);

    return (
        <div style={{ display: "grid" }}>
            <div>
                <div>
                    <GroupuiStepperHorizontal style={{ gap: "var(--groupui-sys-spacing-2400)" }}>
                        <div style={styles.stepperContainer}>
                            <div style={styles.stepperRow}>
                                {steps.map((step, index) => {
                                    return (
                                        <GroupuiStep
                                            style={styles.stepper}
                                            key={step}
                                            done={progressStepIndex > index ? true : false}
                                            active={progressStepIndex == index ? true : false}
                                        >
                                            {step}
                                        </GroupuiStep>
                                    );
                                })}
                            </div>
                        </div>
                    </GroupuiStepperHorizontal>
                </div>
            </div>
        </div>
    );
};

export default IngestSteps;
