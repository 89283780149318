import React, { Dispatch, SetStateAction, useState } from "react";
import {
    GroupuiButton,
    GroupuiCheckbox,
    GroupuiTooltip,
    GroupuiIcon,
} from "@group-ui/group-ui-react";
import { Structure } from "./GenericAddressInformationFields";

interface GenericAddressActionButtonsProps {
    disabled: boolean;
    setShowProposedAddress: Dispatch<SetStateAction<boolean>>;
    validateAddress?: boolean;
    setValidateAddress?: Dispatch<SetStateAction<boolean>>;
    structure?: Structure;
}

export const GenericAddressActionButtons: React.FC<GenericAddressActionButtonsProps> = ({
    disabled,
    validateAddress,
    setValidateAddress,
    setShowProposedAddress,
    structure,
}) => {
    const toggleProposedAddress = () => {
        setShowProposedAddress((prevState) => !prevState);
    };
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
            }}
        >
            <GroupuiButton icon="clipboard-24" disabled={disabled} onClick={toggleProposedAddress}>
                Proposed address
            </GroupuiButton>
            {structure !== Structure.REQUEST_PICKUP &&
                validateAddress != undefined &&
                setValidateAddress != undefined && (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "var(--groupui-sys-spacing-100)",
                        }}
                    >
                        <GroupuiCheckbox
                            disabled={disabled}
                            checked={validateAddress}
                            onGroupuiChange={(event) => setValidateAddress(event.target.checked)}
                        >
                            Validate Address
                        </GroupuiCheckbox>
                        <GroupuiTooltip style={{ display: "flex", alignItems: "center" }}>
                            <GroupuiIcon
                                name={"info-24"}
                                style={{
                                    color: validateAddress
                                        ? "var(--groupui-sys-color-action-basic-enabled)"
                                        : "var(--groupui-sys-color-action-basic-disabled)",
                                }}
                            />
                            <div slot="tooltip">
                                You are accountable for any databoxes ordered without address
                                validation.
                            </div>
                        </GroupuiTooltip>
                    </div>
                )}
        </div>
    );
};
