import React, { useState } from "react";
import { TrackerData } from "../../models/trackerData";
import GenericTable, { columnDefinition } from "./GenericTable";
import { GroupuiIcon, GroupuiTooltip } from "@group-ui/group-ui-react";
import { parseFullDate } from "../../utils/DateUtil";
import DataboxLocationModal from "../databoxes/modals/DataboxLocationModal";

interface TrackerDataTableProps {
    trackerData: TrackerData[];
    numberOfLastEntriesToDisplay?: number;
    entriesPerTablePage?: number;
}

const columns: columnDefinition[] = [
    {
        key: "time",
        header: "Time",
        width: "auto",
    },
    {
        key: "latitude",
        header: "Latitude",
        width: "auto",
    },
    {
        key: "longitude",
        header: "Longitude",
        width: "auto",
    },
    {
        key: "temperature",
        header: "Temperature (°C)",
        width: "auto",
    },
    {
        key: "light",
        header: "Light (lux)",
        width: "auto",
    },
    {
        key: "humidity",
        header: "Humidity (%)",
        width: "auto",
    },
    {
        key: "pressure",
        header: "Pressure (psi)",
        width: "auto",
    },
    {
        key: "battery",
        header: "Battery (%)",
        width: "auto",
    },
    {
        key: "button",
        width: "4rem",
    },
];
const styles = {
    magnifyingGlass: {
        cursor: "pointer",
        color: "var(--groupui-sys-color-action-basic-default)",
    },
};

const TrackerDataTable: React.FC<TrackerDataTableProps> = ({
    trackerData,
    numberOfLastEntriesToDisplay = 10,
    entriesPerTablePage = 5,
}) => {
    const [modal, setModal] = useState(false);
    const [mapLat, setMapLat] = useState(0);
    const [mapLon, setMapLon] = useState(0);

    const openModal = (lon: number, lat: number) => {
        setMapLat(lat);
        setMapLon(lon);
        setModal(true);
    };
    const data = trackerData.slice(0, numberOfLastEntriesToDisplay).map((trackerEntry: any) => {
        return {
            time: parseFullDate(trackerEntry.time),
            latitude: trackerEntry.latitude,
            longitude: trackerEntry.longitude,
            temperature: trackerEntry.temperature,
            light: trackerEntry.light,
            humidity: trackerEntry.humidity,
            pressure: trackerEntry.pressure,
            battery: trackerEntry.battery,
            button: (
                <GroupuiTooltip position="right">
                    <GroupuiIcon
                        name="location-32"
                        style={styles.magnifyingGlass}
                        onClick={() => openModal(trackerEntry.longitude, trackerEntry.latitude)}
                    />
                    <div slot="tooltip">Show Map</div>
                </GroupuiTooltip>
            ),
        };
    });

    return (
        <>
            <GenericTable
                columns={columns}
                data={data}
                header={true}
                pageSize={entriesPerTablePage}
            />
            <DataboxLocationModal
                modal={modal}
                setModal={() => setModal}
                onModalClose={() => setModal(false)}
                lon={mapLon}
                lat={mapLat}
            />
        </>
    );
};

export default TrackerDataTable;
