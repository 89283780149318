import {
    GroupuiMultiselectOption,
    GroupuiMultiselect,
    GroupuiSelect,
    GroupuiSelectOption,
} from "@group-ui/group-ui-react";
import React, { useEffect, useState, isValidElement } from "react";

interface Props {
    data?: any[];
    column?: string;
    filters?: object;
    setFilters?: React.Dispatch<React.SetStateAction<any>>;
    displayName: string;
    resetTrigger?: number;
    doSort?: "asc" | "desc" | "predefined" | "no";
    width?: string;
    predefinedList?: string[];
    selectedByDefault?: string[];
    customLabels?: { [key: string]: string };
    isCustomDropdown?: boolean;
    customOptions?: Array<{ value: string; label: string }>;
    onChange?: (selectedValue: string) => void;
    useOnlyPredefinedList?: boolean;
    isSingleSelect?: boolean;
    filterHeight: string;
}

const GenericFilter: React.FC<Props> = ({
    data = [],
    column = "",
    setFilters,
    filters = {},
    displayName,
    resetTrigger,
    doSort = "no",
    width = "250px",
    predefinedList = [],
    selectedByDefault = [],
    customLabels = {},
    isCustomDropdown = false,
    customOptions = [],
    onChange,
    useOnlyPredefinedList = false,
    isSingleSelect = false,
    filterHeight,
}) => {
    const [filterOptions, setFilterOptions] = useState<string[]>([]);
    const [values, setValues] = useState<string[]>(selectedByDefault);
    const [selectedValue, setSelectedValue] = useState<string>("");

    const updateFilters = (newFilters: any) => {
        if (setFilters) {
            setFilters(newFilters);
        }
    };

    useEffect(() => {
        if (data && !isCustomDropdown) {
            const allOptions = data.map((row) => {
                if (isValidElement(row[column])) {
                    return row[column]["props"]["text"];
                }
                return row[column];
            });

            const allUniqueOptions = allOptions.filter(
                (value, index, self) => self.indexOf(value) === index
            );
            if (useOnlyPredefinedList && predefinedList) {
                setFilterOptions(predefinedList);
            } else {
                switch (doSort) {
                    case "asc":
                        setFilterOptions(allUniqueOptions.sort());
                        break;
                    case "desc":
                        setFilterOptions(allUniqueOptions.sort().reverse());
                        break;
                    case "predefined":
                        setFilterOptions([
                            ...predefinedList,
                            ...allUniqueOptions.filter((e) => !predefinedList.includes(e)),
                        ]);
                        break;
                    default:
                        setFilterOptions(allUniqueOptions);
                }
            }
        }
    }, [data]);

    useEffect(() => {
        if (!isCustomDropdown) {
            setValues(selectedByDefault);
            setSelectedValue("");
        }
    }, []);

    if (isCustomDropdown) {
        return (
            <div style={{ width: width, minWidth: width, maxWidth: width }}>
                <GroupuiSelect
                    placeholder={displayName}
                    onGroupuiChange={(e) => onChange && onChange(e.target.value)}
                    style={{ width: width }}
                >
                    {customOptions.map((option, index) => (
                        <GroupuiSelectOption value={option.value} key={`location-option-${index}`}>
                            {option.label}
                        </GroupuiSelectOption>
                    ))}
                </GroupuiSelect>
            </div>
        );
    }

    if (isSingleSelect) {
        return (
            <div style={{ width: width, minWidth: width, maxWidth: width }}>
                <GroupuiSelect
                    placeholder={displayName}
                    onGroupuiChange={(e) => {
                        const newFilters = { ...filters, [column]: e.target.value };
                        updateFilters(newFilters);
                        setSelectedValue(e.target.value);
                    }}
                    value={filters[column] || ""}
                    style={{ width: width }}
                >
                    {filterOptions.map((option, index) => (
                        <GroupuiSelectOption value={option} key={index}>
                            {customLabels[option] || option}
                        </GroupuiSelectOption>
                    ))}
                </GroupuiSelect>
            </div>
        );
    }

    return (
        <div style={{ width: width, minWidth: width, maxWidth: width }}>
            <GroupuiMultiselect
                placeholder={displayName}
                key={resetTrigger}
                maxHeight={filterHeight}
                singleLine={true}
            >
                {filterOptions.map((option, id) => {
                    let displayLabel = option;
                    if (
                        customLabels &&
                        Object.prototype.hasOwnProperty.call(customLabels, option)
                    ) {
                        displayLabel = customLabels[option];
                    }
                    return (
                        <GroupuiMultiselectOption
                            key={id + String(resetTrigger)}
                            value={option}
                            checked={filters[column] ? filters[column].includes(option) : false}
                            onCheckedChanged={(event) => {
                                if (event.target.checked === true) {
                                    const newFilters = { ...filters };
                                    if (newFilters[column] === undefined) {
                                        newFilters[column] = [];
                                    }
                                    newFilters[column].push(event.target.value);
                                    updateFilters(newFilters);
                                    setValues([...values, option].sort());
                                } else {
                                    const newFilters = { ...filters };
                                    newFilters[column] = newFilters[column].filter(
                                        (filter: any) => filter !== event.target.value
                                    );
                                    updateFilters(newFilters);
                                    setValues(values.filter((e) => e !== option).sort());
                                }
                            }}
                        >
                            {displayLabel}
                        </GroupuiMultiselectOption>
                    );
                })}
            </GroupuiMultiselect>
        </div>
    );
};

export default GenericFilter;
