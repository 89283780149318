// import React from "react";
import React, { useContext, useEffect, useState } from "react";
import { ApiService } from "../../services/apiService";
import ErrorContext from "../context/ErrorContext";
import { handleError } from "../context/ErrorContextUtils";

import { Order } from "../../models/order";
import GenericHeadline from "../generics/GenericHeadline";
import { GroupuiIcon } from "@group-ui/group-ui-react";
import { parseFullDate } from "../../utils/DateUtil";
import GenericInput from "../generics/GenericInput";
import { IngestSlotSwitchJob } from "../../models/ingestSlotSwitchJob";
interface Props {
    order: Order | undefined;
    disabled?: boolean;
}

const styles = {
    icon: { cursor: "pointer" },
};

const GeneralInformation: React.FC<Props> = ({ order, disabled = true }) => {
    const [switchSlotData, setSlotSwitchData] = useState<IngestSlotSwitchJob[]>();
    const [modal, setModal] = useState<boolean | undefined>(false);
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const orderId = order && order.id !== undefined ? order.id.toString() : null;

    useEffect(() => {
        if (orderId !== undefined && orderId !== null) {
            ApiService.getAllIngestSlotSwitchJobs(Number(orderId))
                .then((response) => {
                    setSlotSwitchData(response);
                    if (Array.isArray(response) && response.length !== 0) {
                        setModal(true);
                    }
                })
                .catch((error) => {
                    handleError(error, changeErrorMessage, changeShowError);
                });
        }
    }, [orderId]);

    const findOriginalSlot = (
        switchSlotData: IngestSlotSwitchJob[]
    ): { oldestEntry: IngestSlotSwitchJob } | undefined => {
        if (switchSlotData !== undefined) {
            switchSlotData.sort(
                (a, b) => new Date(a.created).getTime() - new Date(b.created).getTime()
            );
            const oldestEntry = switchSlotData[0];
            return {
                oldestEntry,
            };
        }
    };

    const findLatestSlot = (
        switchSlotData: IngestSlotSwitchJob[]
    ): { latestEntry: IngestSlotSwitchJob } | undefined => {
        if (switchSlotData && switchSlotData.length > 0) {
            switchSlotData.sort(
                (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()
            );
            const latestEntry = switchSlotData[0];
            return {
                latestEntry,
            };
        }
        return undefined;
    };

    return (
        <div>
            <GenericHeadline
                title={"General Information"}
                titleHeadingType="h4"
                actionButtons={[]}
            />
            <div style={{ display: "flex", gap: "var(--groupui-sys-spacing-1000)" }}>
                <div style={{ width: "50%" }}>
                    <GenericInput
                        label={"Ingest Job ID"}
                        disabled={true}
                        value={"IJ" + String(order?.ingestJob?.id)}
                    />
                    <GenericInput
                        label={"Databox Name"}
                        disabled={disabled}
                        value={order?.databox?.name}
                    />
                    <GenericInput
                        label={"Databox Status"}
                        disabled={disabled}
                        value={order?.currentMediaState}
                    />
                    <GenericInput
                        label={"Job Status"}
                        disabled={disabled}
                        value={order?.ingestJob?.jobStatus ?? ""}
                    />
                    <GenericInput
                        label={"Equinix Inbound Ticket"}
                        disabled={disabled}
                        value={order?.inboundTicketId ?? ""}
                    />
                    {modal === true && (
                        <div>
                            <GenericInput
                                label={"Original Ingest Slot"}
                                disabled={disabled}
                                value={
                                    (switchSlotData &&
                                        findOriginalSlot(switchSlotData) &&
                                        findOriginalSlot(switchSlotData)?.oldestEntry &&
                                        findOriginalSlot(switchSlotData)?.oldestEntry
                                            ?.oldIngestSlot &&
                                        findOriginalSlot(switchSlotData)?.oldestEntry?.oldIngestSlot
                                            ?.name) ??
                                    order?.ingestJob?.ingestSlot?.name ??
                                    ""
                                }
                            />
                            <GenericInput
                                label={"Current Ingest Slot"}
                                disabled={disabled}
                                value={order?.ingestJob?.ingestSlot?.name ?? ""}
                            />
                            <GenericInput
                                label={"SH Ticket ID"}
                                disabled={disabled}
                                value={
                                    (switchSlotData &&
                                        findLatestSlot(switchSlotData) &&
                                        findLatestSlot(switchSlotData)?.latestEntry &&
                                        findLatestSlot(switchSlotData)?.latestEntry
                                            ?.smartHandsTicketId) ??
                                    ""
                                }
                            />
                        </div>
                    )}
                    {modal !== true && (
                        <GenericInput
                            label={"Ingest Slot"}
                            disabled={disabled}
                            value={order?.ingestJob?.ingestSlot?.name ?? ""}
                        />
                    )}
                </div>
                <div style={{ width: "50%" }}>
                    <GenericInput
                        label={"Associated Order"}
                        disabled={disabled}
                        value={"OR" + order?.id}
                    />
                    <GenericInput
                        label={"Delivery Provider"}
                        disabled={disabled}
                        value={order?.databox?.ingestStation?.deliveryProvider ?? ""}
                    />

                    <GenericInput
                        label={"Tracking Number"}
                        disabled={disabled}
                        value={order?.upsReturnShipmentLabel?.trackingNumber}
                    />
                    <div style={{ position: "relative" }}>
                        <GenericInput
                            label={"Return Label"}
                            value={
                                order?.upsReturnShipmentLabel !== undefined
                                    ? `return_label_${order.upsReturnShipmentLabel?.shipmentIdenfificationNumber}.png`
                                    : ""
                            }
                            disabled={true}
                        />
                        {order?.upsReturnShipmentLabel !== undefined && (
                            <div style={{ position: "absolute", right: 8, bottom: 8 }}>
                                <GroupuiIcon
                                    name="download-32"
                                    onClick={() => {
                                        const base64png = order.upsReturnShipmentLabel?.label;
                                        if (base64png === undefined) {
                                            return;
                                        }
                                        const a = document.createElement("a");
                                        a.href =
                                            "data:image/png;base64," +
                                            order.upsReturnShipmentLabel?.label;
                                        a.download = `return_label_${order.upsReturnShipmentLabel?.shipmentIdenfificationNumber}.png`;
                                        a.click();
                                    }}
                                    style={styles.icon}
                                />
                            </div>
                        )}
                    </div>
                    <GenericInput
                        label={"Created"}
                        disabled={disabled}
                        value={parseFullDate(order?.ingestJob?.created)}
                    />
                    <GenericInput
                        label={"Last updated"}
                        disabled={disabled}
                        value={
                            order?.ingestJob?.updated
                                ? parseFullDate(order.ingestJob?.updated)
                                : "Not updated yet."
                        }
                    />
                    {modal === true && (
                        <GenericInput
                            label={"SH Ticket Status"}
                            disabled={disabled}
                            value={
                                (switchSlotData &&
                                    findLatestSlot(switchSlotData) &&
                                    findLatestSlot(switchSlotData)?.latestEntry &&
                                    findLatestSlot(switchSlotData)?.latestEntry?.status) ??
                                ""
                            }
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default GeneralInformation;
