import React, { useContext, useEffect, useState } from "react";
import {
    GroupuiIcon,
    GroupuiLoadingSpinner,
    GroupuiTooltip,
    GroupuiButton,
} from "@group-ui/group-ui-react";

import GenericTable, { columnDefinition } from "../generics/GenericTable";
import useTitle from "../../hooks/useTitle";
import { useNavigate } from "react-router-dom";
import GenericHeadline from "../generics/GenericHeadline";
import { ApiService } from "../../services/apiService";
import ErrorContext from "../context/ErrorContext";
import { handleError } from "../context/ErrorContextUtils";
import { InCarMount } from "../../models/incarmount";
import UserGroupContext from "../context/UserGroupContext";
import { RightsManagement } from "../../config/rightsManagement";
import GenericMoreButton from "../generics/GenericMoreButton";
import { RightsWrapper } from "../container/RightsWrapper";
import { CSVLink } from "react-csv";
import { parseFullDateFilename, parseFullDateWithoutSeconds } from "../../utils/DateUtil";
import { isInUserGroup } from "../../utils/RightsUtils";

const styles = {
    magnifyingGlass: {
        cursor: "pointer",
        color: "var(--groupui-sys-color-action-basic-default)",
    },
};

const columns: columnDefinition[] = [
    {
        key: "name",
        header: "Name",
        width: "auto",
    },
    {
        key: "updated",
        header: "Updated",
        width: "auto",
    },
    {
        key: "version",
        header: "Version",
        width: "auto",
    },
    {
        key: "status",
        header: "Status",
        width: "auto",
    },
    {
        key: "owner",
        header: "Owner",
        width: "auto",
    },
    {
        key: "lastComment",
        header: "Last Comment",
        width: "auto",
    },
    {
        key: "button",
        width: "4rem",
    },
];

function InCarMounts() {
    useTitle("In Car Mounts");
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [inCarMountsData, setInCarMountsData] = useState<InCarMount[]>();
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const { userGroups } = useContext(UserGroupContext);
    const [dropDownState, setDropDownState] = useState(false);

    useEffect(() => {
        getData();
    }, []);

    const data =
        inCarMountsData?.map((incarmount: InCarMount) => {
            return {
                name: incarmount.name,
                updated: incarmount.updated ? "Yes" : "No",
                owner: incarmount.owner,
                version: incarmount.version,
                status: incarmount.status,
                lastComment: incarmount.lastComment ?? "",
                button: (
                    <GroupuiTooltip position="left">
                        <GroupuiIcon
                            name="search-plus-32"
                            style={styles.magnifyingGlass}
                            onClick={() => navigate(`/in-car-mounts/${incarmount.id}`)}
                        />
                        <div slot="tooltip">Show Details</div>
                    </GroupuiTooltip>
                ),
            };
        }) ?? [];

    const reportData =
        inCarMountsData?.map((incarmount: InCarMount) => {
            return {
                name: incarmount.name,
                updated: incarmount.updated ? "Yes" : "No",
                poNumber: incarmount.poNumber ?? "",
                owner: incarmount.owner,
                version: incarmount.version,
                status: incarmount.status,
                lastComment: incarmount.lastComment ?? "",
                timestampLastComment: parseFullDateWithoutSeconds(incarmount.timestampLastComment),
                internalProject: incarmount.project?.name ?? "",
                currentHolder: incarmount.project?.responsiblePerson ?? "",
            };
        }) ?? [];

    const getData = () => {
        if (isInUserGroup([RightsManagement.SUPPORT], userGroups)) {
            ApiService.getAllInCarMounts()
                .then((response) => {
                    setInCarMountsData(response);
                    setIsLoading(false);
                })
                .catch((error) => {
                    handleError(error, changeErrorMessage, changeShowError);
                    setIsLoading(false);
                });
            return;
        }

        ApiService.getAllInCarMountsForCustomer()
            .then((response) => {
                setInCarMountsData(response);
                setIsLoading(false);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
                setIsLoading(false);
            });
    };

    return (
        <div
            style={{
                padding: "var(--groupui-sys-spacing-1200) var(--groupui-sys-spacing-1400)",
                scrollBehavior: "smooth",
                whiteSpace: "pre-wrap",
            }}
        >
            <GroupuiLoadingSpinner displayed={isLoading} />
            <GenericHeadline
                title="In Car Mounts"
                actionButtons={[
                    <RightsWrapper
                        key={"rights-first"}
                        authorizedUserGroup={RightsManagement.INCARMOUNTS_ROUTES}
                        userGroups={userGroups}
                    >
                        <GenericMoreButton
                            key={"More-Button-1"}
                            dropDownState={dropDownState}
                            setDropDownState={() => {
                                setDropDownState(!dropDownState);
                            }}
                            options={[
                                <CSVLink
                                    key={"CSV-Link-1"}
                                    data={reportData ?? [""]}
                                    separator={";"}
                                    filename={
                                        "In_Car_Mounts" +
                                        "_" +
                                        parseFullDateFilename(new Date()) +
                                        ".csv"
                                    }
                                >
                                    <GroupuiButton
                                        key={"Option-1-1"}
                                        variant="primary"
                                        icon="download-32"
                                        fullwidth={true}
                                        alignment="left"
                                        onClick={() => {
                                            setDropDownState(false);
                                        }}
                                    >
                                        Download {"In Car Mounts"}
                                    </GroupuiButton>
                                </CSVLink>,
                            ]}
                        />
                    </RightsWrapper>,
                ]}
            />
            <div>
                <GenericTable
                    columns={columns}
                    data={data}
                    header={true}
                    pageSize={20}
                    searchColumns={[{ column: "name", displayName: "Name" }]}
                    filterColumns={[
                        {
                            column: "updated",
                            displayName: "Updated",
                            doSort: "asc",
                        },
                        {
                            column: "version",
                            displayName: "Version",
                            doSort: "asc",
                        },
                        {
                            column: "status",
                            displayName: "Status",
                            doSort: "asc",
                        },
                        {
                            column: "owner",
                            displayName: "Owner",
                            doSort: "asc",
                        },
                    ]}
                    filtersAndSearchWidth={{ filters: "200px", search: "300px" }}
                />
            </div>
        </div>
    );
}

export default InCarMounts;
