import React, { useContext, useEffect, useState } from "react";
import { GroupuiButton, GroupuiLoadingSpinner } from "@group-ui/group-ui-react";
import useTitle from "../../hooks/useTitle";
import GenericHeadline from "../generics/GenericHeadline";
import GenericTable, { columnDefinition } from "../generics/GenericTable";
import { ApiService } from "../../services/apiService";
import ErrorContext from "../context/ErrorContext";
import { handleError } from "../context/ErrorContextUtils";
import { Ingest } from "../../models/ingest";
import {
    getTimeFromMilliseconds,
    parseDateOnly,
    parseFullDateWithoutSeconds,
} from "../../utils/DateUtil";
import GenericTag from "../generics/GenericTag";
import GenericMoreButton from "../generics/GenericMoreButton";
import { RightsManagement } from "../../config/rightsManagement";
import { RightsWrapper } from "../container/RightsWrapper";
import UserGroupContext from "../context/UserGroupContext";
import DownloadReportModal from "../report/DownloadReportModal";

const columns: columnDefinition[] = [
    {
        key: "ingestId",
        header: "Ingest ID",
        width: "auto",
    },
    {
        key: "ingestJob",
        header: "Ingest Job",
        width: "auto",
    },
    {
        key: "databoxName",
        header: "Databox Name",
        width: "auto",
    },
    {
        key: "ingestLocation",
        header: "Ingest Location",
        width: "auto",
    },
    {
        key: "copyStation",
        header: "Copy Station",
        width: "auto",
    },
    {
        key: "ingestType",
        header: "Ingest Type",
        width: "auto",
    },
    {
        key: "status",
        header: "Status",
        width: "auto",
    },
    {
        key: "attempts",
        header: "Attempts",
        width: "auto",
    },
    {
        key: "uploadedVolume",
        header: "Uplodaed Volume",
        width: "auto",
    },
    {
        key: "duration",
        header: "Duration",
        width: "auto",
    },
    {
        key: "uploadedFiles",
        header: "Uploaded Files",
        width: "auto",
    },
    {
        key: "ingestStarted",
        header: "Ingest Started",
        width: "auto",
    },
    {
        key: "ingestCompleted",
        header: "Ingest Completed",
        width: "auto",
    },
    {
        key: "button",
        width: "4rem",
    },
];

const Ingests = () => {
    const title = "Ingests";
    useTitle(title);
    const [isLoading, setIsLoading] = useState(true);
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const [ingests, setIngests] = useState<Ingest[] | null>([]);

    const [filteredData, setFilteredData] = useState<any[] | undefined>([]);

    const [dropDownState, setDropDownState] = useState(false);
    const { userGroups } = useContext(UserGroupContext);
    const [modal, setModal] = useState(false);

    useEffect(() => {
        ApiService.getAllIngest()
            .then((response) => {
                setIngests(response);
                setIsLoading(false);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        const trimmedData = ingests?.map((ingest: Ingest) => {
            return {
                ingest_id: "I" + ingest.ID,
                ingestJob: ingest.ingestJob ? `IJ${ingest.ingestJob}` : "",
                ingest_uuid: ingest.ingestUuid ?? "",
                databox_id: ingest?.databox?.id ?? "",
                databox_name: ingest?.databox?.name ?? "",
                ingest_slot_name: ingest?.ingestSlot?.name ?? "",
                ingest_slot_id: ingest?.ingestSlot?.id ?? "",
                ingest_location: ingest.databox?.ingestStation?.name ?? "",
                ingestType: ingest.mapIngestType,
                status: ingest.currentMediaState,
                data_id: ingest.dataId,
                attempts:
                    ingest.ingestAttempt || ingest.ingestAttempts
                        ? ingest.ingestAttempt + " of " + ingest.ingestAttempts
                        : "",
                ingest_started: parseFullDateWithoutSeconds(ingest.ingestStarted),
                pre_uploadStarted: parseFullDateWithoutSeconds(ingest.preUploadStarted),
                upload_data_file_started: parseFullDateWithoutSeconds(ingest.uploadDataFileStarted),
                upload_data_file_progress: parseFullDateWithoutSeconds(
                    ingest.uploadDataFileProgress
                ),
                upload_data_file_finished: parseFullDateWithoutSeconds(
                    ingest.uploadDataFileFinished
                ),
                ingest_finished: parseFullDateWithoutSeconds(ingest.ingestFinished),
                error: ingest.error,
                upload_progress: ingest.uploadProgress,
                last_update: parseFullDateWithoutSeconds(ingest.lastUpdatedRow),
                uploaded_bytes: ingest.uploadedBytes,
                discovery_id: ingest.discoveryId,
                upload_id: ingest.uploadId,
                number_of_chunks: ingest.numberOfChunks,
                chunk_number: ingest.chunkNumber,
                uploaded_volume: ingest.getUploadedVolume(),
                data_volume: ingest.dataVolume,
                uploadDuration: getTimeFromMilliseconds(ingest.uploadDuration),
                totalDuration: getTimeFromMilliseconds(ingest.totalDuration),
                number_of_files: ingest.numberOfFiles,
                number_of_uploaded_files: ingest.numberOfUploadedFiles,
                number_of_measurements: ingest.numberOfMeasurements,
                insert_date: parseDateOnly(ingest.lastMessageInserted),
                ingest_version: ingest.ingestVersion,
                message_uuid: ingest.messageUuid,
                message_timestamp: parseFullDateWithoutSeconds(ingest.messageTimestamp),
                cs_call_retries: ingest.csCallRetries,
            };
        });

        if (trimmedData?.length == 0 || trimmedData == undefined) {
            setFilteredData([
                {
                    Message: `There is no data available!`,
                },
            ]);
        } else {
            setFilteredData(trimmedData);
        }
    }, [ingests]);

    const data = ingests
        ?.sort((a: Ingest, b: Ingest) => {
            return b.ID - a.ID;
        })
        .map((ingest: Ingest) => {
            return {
                ingestId: "I" + ingest.ID,
                ingestJob: ingest.ingestJob ? `IJ${ingest.ingestJob}` : "",
                databoxName: ingest.databox?.name ?? "",
                ingestLocation: ingest.databox?.ingestStation?.name ?? "",
                copyStation: ingest.ingestSlot?.name ?? "",
                ingestType: ingest.mapIngestType,
                status: (
                    <GenericTag
                        text={ingest.currentMediaState}
                        variant="primary"
                        mediaStateTarget="databox"
                    />
                ),
                attempts: ingest.getIngestAttempts,
                uploadedVolume: ingest.getUploadedVolume(),
                duration: getTimeFromMilliseconds(ingest.totalDuration),
                uploadedFiles: ingest.numberOfFiles,
                ingestStarted: parseDateOnly(ingest.ingestStarted),
                ingestCompleted:
                    ingest.uploadDataFileFinished !== null || ingest.markedAborted !== null
                        ? ingest.uploadDataFileFinished
                            ? parseDateOnly(ingest.uploadDataFileFinished)
                            : parseDateOnly(ingest.markedAborted)
                        : "",
            };
        });

    return (
        <div
            style={{
                padding: "var(--groupui-sys-spacing-1200) var(--groupui-sys-spacing-1400)",
                scrollBehavior: "smooth",
                whiteSpace: "pre-wrap",
            }}
        >
            <GroupuiLoadingSpinner displayed={isLoading} />
            {modal && (
                <DownloadReportModal
                    reportType={title}
                    data={filteredData}
                    modal={modal}
                    setModal={(value: boolean) => setModal(value)}
                />
            )}
            <GenericHeadline
                title={"Ingests"}
                actionButtons={[
                    <RightsWrapper
                        key={"rights-first"}
                        authorizedUserGroup={RightsManagement.DOWNLOAD_INGESTS}
                        userGroups={userGroups}
                    >
                        <GenericMoreButton
                            key={"More-Button-1"}
                            dropDownState={dropDownState}
                            setDropDownState={() => {
                                setDropDownState(!dropDownState);
                            }}
                            options={[
                                <GroupuiButton
                                    key={"Option-1"}
                                    variant="primary"
                                    icon="download-32"
                                    fullwidth={true}
                                    alignment="left"
                                    // disabled={true}
                                    onClick={() => {
                                        setModal(true);
                                        setDropDownState(false);
                                    }}
                                >
                                    Download {title}
                                </GroupuiButton>,
                            ]}
                        />
                    </RightsWrapper>,
                ]}
            />
            <div>
                <GenericTable
                    columns={columns}
                    data={data ?? []}
                    header={true}
                    pageSize={20}
                    searchColumns={[
                        { column: "ingestId", displayName: "Ingest ID" },
                        { column: "databoxName", displayName: "Databox Name" },
                    ]}
                    filterColumns={[
                        {
                            column: "ingestLocation",
                            displayName: "Ingest Location",
                            doSort: "asc",
                        },
                        {
                            column: "status",
                            displayName: "Current State",
                            doSort: "predefined",
                            predefinedList: [
                                "Preparation for Ingest",
                                "Pre-Upload",
                                "Uploading",
                                "Error",
                                "Upload Cancelled",
                                "Upload Completed",
                                "Preparing for next use",
                                "Order Completed",
                            ],
                        },
                    ]}
                    filtersAndSearchWidth={{ filters: "200px", search: "375px" }}
                />
            </div>
        </div>
    );
};

export default Ingests;
