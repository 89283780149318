import React, { useContext, useEffect, useState } from "react";
import GenericInput from "../generics/GenericInput";
import { GroupuiDivider, GroupuiIcon, GroupuiNotification } from "@group-ui/group-ui-react";
import GenericHeadline from "../generics/GenericHeadline";
import GenericTable, { columnDefinition } from "../generics/GenericTable";
import { ApiService } from "../../services/apiService";
import { useParams } from "react-router-dom";
import { Order } from "../../models/order";
import ErrorContext from "../context/ErrorContext";
import { handleError } from "../context/ErrorContextUtils";
import { parseFullDate } from "../../utils/DateUtil";
import { GraphApiService, RequestType } from "../../services/graphApi/graphApiService";
import { GraphApiEndpoints } from "../../constants/graphApiEndpoints";
import { OrderProjectHistory } from "../../models/orderProjectHistory";
import { DataboxOrderOverview } from "../generics/pages/GenericDataboxOrderOverview";
import TrackerDataTable from "../generics/TrackerDataTable";
import { GenericAddressInformationFields } from "../generics/GenericAddressInformationFields";
import { Address } from "../../models/address";

const columns: columnDefinition[] = [
    {
        key: "internalProject",
        header: "Internal Project",
        width: "auto",
    },
    {
        key: "currentHolder",
        header: "Current Holder",
        width: "auto",
    },
    {
        key: "updated",
        header: "Updated",
        width: "auto",
    },
];

interface OrderDetailsProps {
    order: Order | undefined;
    disabled?: boolean;
    type: DataboxOrderOverview;
}
const styles = {
    icon: { cursor: "pointer" },
    errorContainer: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        gap: "10px",
    } as React.CSSProperties,
};

const OrderDetails: React.FC<OrderDetailsProps> = ({ order, disabled = true, type }) => {
    const { orderID } = useParams();
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const [isLoading, setIsLoading] = useState(true);
    const [orderProjectHistory, setOrderProjectHistory] = useState<OrderProjectHistory[]>();
    const [requestor, setRequestor] = useState<string>();

    const [deliveryAddress, setDeliveryAddress] = useState<Address | undefined>(undefined);
    const [validateDeliveryAddress, setValidateDeliveryAddress] = useState<boolean>(true);

    useEffect(() => {
        ApiService.getOrderProjectHistoryByOrderId(Number(orderID))
            .then((response) => {
                setOrderProjectHistory(response);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
            });
    }, []);

    useEffect(() => {
        if (order?.azureUserId != null) {
            setIsLoading(true);
            GraphApiService.callMsGraph(
                GraphApiEndpoints.userById(order.azureUserId),
                RequestType.GET
            )
                .then((response) => {
                    setRequestor(response.displayName);
                    // setIsLoading(false);
                })
                .catch((error) => {
                    handleError(error, changeErrorMessage, changeShowError);
                });
        }
    }, [order]);

    const data =
        orderProjectHistory
            ?.sort((a: OrderProjectHistory, b: OrderProjectHistory) => {
                return Date.parse(b.updated) - Date.parse(a.updated);
            })
            .map((orderProjectHistoryEntry: OrderProjectHistory) => {
                return {
                    internalProject: orderProjectHistoryEntry?.project?.name ?? "",
                    currentHolder: orderProjectHistoryEntry.holderId,
                    updated: parseFullDate(orderProjectHistoryEntry.updated),
                };
            }) ?? [];

    return (
        <div>
            {order && (
                <div>
                    <GenericHeadline
                        title={"General Information"}
                        titleHeadingType="h4"
                        actionButtons={[]}
                    />
                    {order.completed && order.cancelled && (
                        <div>
                            <GroupuiNotification severity="default" dismissible={false}>
                                <div style={styles.errorContainer}>
                                    <GroupuiIcon name={"warning-filled-32"} height={25} />
                                    This order has been cancelled: {order.reasonForTermination}
                                </div>
                            </GroupuiNotification>
                            <br />
                        </div>
                    )}
                    <div style={{ display: "flex", gap: 40 }}>
                        <div style={{ width: "50%" }}>
                            <GenericInput
                                label={"Order ID"}
                                disabled={true}
                                value={"OR" + order.id.toString()}
                            />
                            <GenericInput
                                label={"Databox Name"}
                                disabled={disabled}
                                value={order.databox?.name}
                            />
                            <GenericInput
                                label={"Ingest Location"}
                                disabled={disabled}
                                value={order.databox?.ingestStation?.name}
                            />
                            <GenericInput
                                label={"Current State"}
                                disabled={disabled}
                                value={order.currentMediaState}
                            />
                            <GenericInput
                                label={"Storage Capacity (TB)"}
                                disabled={disabled}
                                value={order.databox.storageCapacityDisplayName}
                            />
                            <GenericInput
                                label={"Delivery Provider"}
                                disabled={disabled}
                                value={order.databox?.ingestStation?.deliveryProvider ?? ""}
                            />
                        </div>
                        <div style={{ width: "50%" }}>
                            <GenericInput
                                label={"Tracking Number"}
                                disabled={disabled}
                                value={order.upsShipmentLabel?.trackingNumber}
                            />

                            <div style={{ position: "relative" }}>
                                <GenericInput
                                    label={"Delivery Label"}
                                    value={
                                        order.upsShipmentLabel !== undefined
                                            ? `delivery_label_${order.upsShipmentLabel?.shipmentIdenfificationNumber}.png`
                                            : ""
                                    }
                                    disabled={true}
                                />
                                {order.upsShipmentLabel !== undefined && (
                                    <div style={{ position: "absolute", right: 8, bottom: 8 }}>
                                        <GroupuiIcon
                                            name="download-32"
                                            onClick={() => {
                                                const base64png = order.upsShipmentLabel?.label;
                                                if (base64png === undefined) {
                                                    return;
                                                }
                                                const a = document.createElement("a");
                                                a.href =
                                                    "data:image/png;base64," +
                                                    order.upsShipmentLabel?.label;
                                                a.download = `delivery_label_${order.upsShipmentLabel?.shipmentIdenfificationNumber}.png`;
                                                a.click();
                                            }}
                                            style={styles.icon}
                                        />
                                    </div>
                                )}
                            </div>
                            <div style={{ position: "relative" }}>
                                <GenericInput
                                    label={"Return Label"}
                                    value={
                                        order.upsReturnShipmentLabel !== undefined
                                            ? `return_label_${order.upsReturnShipmentLabel?.shipmentIdenfificationNumber}.png`
                                            : ""
                                    }
                                    disabled={true}
                                />
                                {order.upsReturnShipmentLabel !== undefined && (
                                    <div style={{ position: "absolute", right: 8, bottom: 8 }}>
                                        <GroupuiIcon
                                            name="download-32"
                                            onClick={() => {
                                                const base64png =
                                                    order.upsReturnShipmentLabel?.label;
                                                if (base64png === undefined) {
                                                    return;
                                                }
                                                const a = document.createElement("a");
                                                a.href =
                                                    "data:image/png;base64," +
                                                    order.upsReturnShipmentLabel?.label;
                                                a.download = `return_label_${order.upsReturnShipmentLabel?.shipmentIdenfificationNumber}.png`;
                                                a.click();
                                            }}
                                            style={styles.icon}
                                        />
                                    </div>
                                )}
                            </div>
                            <GenericInput
                                label={"Requestor"}
                                disabled={disabled}
                                value={requestor}
                            />
                            <GenericInput
                                label={"Created"}
                                disabled={disabled}
                                value={parseFullDate(order.created)}
                            />
                            <GenericInput
                                label={"Last updated"}
                                disabled={disabled}
                                value={
                                    order.updated != null
                                        ? parseFullDate(order.updated)
                                        : "Not updated yet."
                                }
                            />
                        </div>
                    </div>
                    <GroupuiDivider />
                    {type == DataboxOrderOverview.MY_DATABOXES && (
                        <div>
                            <GenericHeadline
                                title={"Additional Information"}
                                titleHeadingType="h4"
                                actionButtons={[]}
                            />
                            <GenericTable
                                columns={columns}
                                data={data}
                                header={true}
                                pageSize={5}
                            />
                        </div>
                    )}
                    <GenericHeadline
                        title={"Delivery Address"}
                        titleHeadingType="h4"
                        actionButtons={[]}
                    />
                    <GenericAddressInformationFields
                        address={deliveryAddress ? deliveryAddress : order.address ?? undefined}
                        setAddress={setDeliveryAddress}
                        disabled={disabled}
                        validateAddress={validateDeliveryAddress}
                        setValidateAddress={setValidateDeliveryAddress}
                    />
                    <div>
                        <GroupuiDivider />
                        <GenericHeadline title="Tracker" titleHeadingType="h4" actionButtons={[]} />
                        <TrackerDataTable trackerData={order.databox.tracker?.trackerData ?? []} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default OrderDetails;
