import React, { useContext, useEffect, useState } from "react";
import { GroupuiButton, GroupuiLoadingSpinner, GroupuiPopover } from "@group-ui/group-ui-react";

import GenericTable, { columnDefinition } from "../generics/GenericTable";
import useTitle from "../../hooks/useTitle";
import GenericHeadline from "../generics/GenericHeadline";
import { Project } from "../../models/project";
import { useMsal } from "@azure/msal-react";
import { ApiService } from "../../services/apiService";
import UserGroupContext from "../context/UserGroupContext";
import AddProjectModal from "./modals/AddProjectModal";
import ErrorContext from "../context/ErrorContext";
import { handleError } from "../context/ErrorContextUtils";
import { parseFullDate } from "../../utils/DateUtil";
import GenericMoreButton from "../generics/GenericMoreButton";

const columns: columnDefinition[] = [
    {
        key: "projectName",
        header: "Project Name",
        width: "auto",
    },
    {
        key: "responsiblePerson",
        header: "Responsible Person",
        width: "auto",
    },
    {
        key: "department",
        header: "Department",
        width: "auto",
    },
    {
        key: "created",
        header: "Created",
        width: "auto",
    },
    {
        key: "updated",
        header: "Updated",
        width: "auto",
    },
    {
        key: "button1",
        width: "4rem",
    },
    {
        key: "button2",
        width: "4rem",
    },
];

function ManageProjects() {
    useTitle("Manage Projects");
    const { inProgress } = useMsal();
    const [projects, setProjects] = useState<Project[] | null>();
    const [isLoading, setIsLoading] = useState(true);
    const { userGroups } = useContext(UserGroupContext);
    const [dropDownState, setDropDownState] = useState(false);
    const [modal, setModal] = useState(false);
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);

    useEffect(() => {
        getData();
    }, [inProgress, projects?.length]);

    const handleModalClose = () => {
        setIsLoading(true);
        setModal(false);
        const timeout = setTimeout(() => {
            getData();
        }, 3000);
    };

    const getData = () => {
        ApiService.getAllProjects()
            .then((response) => {
                setProjects(response);
                setIsLoading(false);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
                setIsLoading(false);
            });
        setIsLoading(false);
    };

    const data =
        projects?.map((project: Project) => {
            return {
                projectName: project.name,
                responsiblePerson: project.responsiblePerson ?? "",
                department: project.department ?? "",
                created: parseFullDate(project.created),
                updated: project.updated ? parseFullDate(project.updated) : "Not updated yet",
            };
        }) ?? [];

    return (
        <div
            style={{
                padding: "var(--groupui-sys-spacing-1200) var(--groupui-sys-spacing-1400)",
                scrollBehavior: "smooth",
                whiteSpace: "pre-wrap",
            }}
        >
            <AddProjectModal
                showModal={modal}
                setShowModal={setModal}
                onModalClose={() => setModal(false)}
                onSend={handleModalClose}
                projects={projects ?? []}
            />
            <GroupuiLoadingSpinner displayed={isLoading} />
            <GenericHeadline
                title="Manage Projects"
                actionButtons={[
                    <GenericMoreButton
                        key={"More-Button-1"}
                        dropDownState={dropDownState}
                        setDropDownState={() => {
                            setDropDownState(!dropDownState);
                        }}
                        options={[
                            <GroupuiButton
                                key={"first"}
                                variant="primary"
                                icon="add-32"
                                fullwidth={true}
                                alignment="left"
                                onClick={() => {
                                    setModal(true);
                                    setDropDownState(false);
                                }}
                            >
                                Add Project
                            </GroupuiButton>,
                        ]}
                    />,
                ]}
            />
            <div>
                <GenericTable columns={columns} data={data} header={true} pageSize={20} />
            </div>
        </div>
    );
}

export default ManageProjects;
