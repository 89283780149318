import {
    GroupuiButton,
    GroupuiInlineNotification,
    GroupuiModal,
    GroupuiNotification,
    GroupuiText,
} from "@group-ui/group-ui-react";
import React from "react";
import GenericHeadline from "../../generics/GenericHeadline";

interface Props {
    showModal: boolean;
    setModal: (arg0: boolean) => void;
    onConfirm: () => void;
    addressValidation: boolean;
}

const ConfirmOrderModal: React.FC<Props> = ({
    showModal,
    setModal,
    onConfirm,
    addressValidation,
}) => {
    return (
        <GroupuiModal
            closeButton={true}
            padding="var(--groupui-sys-spacing-800)"
            maxWidth="1000px"
            overflow={"visible"}
            displayed={showModal}
            style={{
                justifyContent: "center",
                alignItems: "center",
            }}
            onGroupuiModalClose={() => setModal(false)}
        >
            <div>
                <GenericHeadline
                    title="Confirm Order"
                    actionButtons={[]}
                    titleHeadingType="h4"
                    type="modal"
                />
                {!addressValidation && (
                    <div>
                        <GroupuiInlineNotification
                            visible={true}
                            hideIcon={false}
                            severity="warning"
                            style={{
                                // Added because color from the component isn´t working
                                color: "var(--groupui-sys-color-warning-basic-default)",
                                backgroundColor: "var(--groupui-sys-color-warning-weak-default)",
                                // Added to avoided dropdowns to be hidden
                                zIndex: "0",
                            }}
                        >
                            <div
                                slot="headline"
                                // Added because color from the component isn´t working
                                style={{ color: "var(--groupui-sys-color-text-normal)" }}
                            >
                                Address Validation
                            </div>
                            <div
                                slot="content"
                                // Added because color from the component isn´t working
                                style={{ color: "var(--groupui-sys-color-text-normal)" }}
                            >
                                You are accountable for any databoxes ordered without address
                                validation.
                            </div>
                        </GroupuiInlineNotification>
                        <br />
                    </div>
                )}
                <div>
                    <GroupuiText>Are you sure you want to proceed with your order?</GroupuiText>
                    <GroupuiText>The order cannot be cancelled afterwards.</GroupuiText>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "var(--groupui-sys-spacing-400)",
                        marginTop: "var(--groupui-sys-spacing-600)",
                    }}
                >
                    <GroupuiButton variant={"secondary"} onClick={() => setModal(false)}>
                        Cancel
                    </GroupuiButton>
                    <GroupuiButton icon="check-32" onClick={onConfirm}>
                        Confirm
                    </GroupuiButton>
                </div>
            </div>
        </GroupuiModal>
    );
};

export default ConfirmOrderModal;
