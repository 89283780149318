import { GroupuiHeadline } from "@group-ui/group-ui-react";
import React from "react";

interface GenericHeadlineProps {
    title: string;
    titleHeadingType?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
    titleSerif?: boolean;
    titleWeight?: "light" | "regular" | "bold" | "italic";
    actionButtons: Array<JSX.Element>;
    marginBottom?: string;
    color?: string;
    type?: "modal" | undefined;
}

/**
 * A Headline-Component, which shows a text in different sizes and styles as well as buttons depending on the given parameters
 *
 * @param {string} [title = "Data Transport Portal"]  - sets the title of the tab in which the component is displayed
 * @param {"h1" | "h2" | "h3" | "h4" | "h5" | "h6"} [titleHeadingType] - changes the style of the heading
 * @param {boolean} [titleSerif] - toggles serif-styling for heading
 * @param {"light" | "regular" | "bold" | "italic"}  [titleWeight]  - changes weight-style of the heading
 * @param {Array<JSX.Element>}  actionButtons - given components will be displayed alongside the heading
 * @param {string}  [color] - Adjusts the color of the headline
 *
 * @returns Headline with buttons, if given
 *
 */
const GenericHeadline: React.FC<GenericHeadlineProps> = ({
    title = "Data Transport Portal",
    titleHeadingType,
    titleSerif,
    titleWeight,
    actionButtons,
    marginBottom,
    color,
    type,
}) => {
    const styles = {
        title: {
            marginBottom:
                marginBottom != undefined ? marginBottom : "var(--groupui-sys-spacing-600)",
            display: "flex",
            flexDirection: "row" as const,
            justifyContent: "space-between",
            marginTop: "1rem",
        },
    };
    return (
        <div style={styles.title}>
            <GroupuiHeadline
                heading={titleHeadingType}
                serif={titleSerif}
                weight={titleWeight}
                style={
                    color
                        ? { color: color }
                        : type === "modal"
                        ? { color: "var(--groupui-sys-color-information-basic-default)" }
                        : {}
                }
            >
                {title}
            </GroupuiHeadline>
            <div
                style={{
                    display: "flex",
                    gap: "var(--groupui-sys-spacing-300)",
                    alignItems: "center",
                }}
            >
                {actionButtons.map((item, index) => (
                    <div key={index}>{item}</div>
                ))}
            </div>
        </div>
    );
};

export default GenericHeadline;
