import React from "react";
import { Order } from "../../../models/order";
import { GroupuiNotification, GroupuiIcon, GroupuiText } from "@group-ui/group-ui-react";
import { parseFullDateWithoutSeconds } from "../../../utils/DateUtil";
interface Props {
    order: Order | undefined;
}

const IngestJobPauseInformation: React.FC<Props> = ({ order }) => {
    return (
        <>
            {order && order.ingestJob?.jobStatus === "On Hold" && order.ingestJob.pause && (
                <div>
                    <GroupuiNotification dismissible={false}>
                        <div style={{ display: "flex", gap: "var(--groupui-sys-spacing-400)" }}>
                            <GroupuiIcon name="warning-filled-24" />
                            <div style={{ display: "flex", gap: "var(--groupui-sys-spacing-200)" }}>
                                <>Ingest Job Paused</> -<strong>Reason:</strong>
                                {order.ingestJob.pause.reason},
                                {order.ingestJob.pause.jiraTicketId ? (
                                    <>
                                        <strong>Jira-Ticket-ID:</strong>
                                        {order.ingestJob.pause.jiraTicketId},
                                    </>
                                ) : (
                                    ""
                                )}
                                <strong>Date:</strong>
                                {parseFullDateWithoutSeconds(order.ingestJob.pause.created)}
                            </div>
                        </div>
                    </GroupuiNotification>
                </div>
            )}
        </>
    );
};

export default IngestJobPauseInformation;
