import { GroupuiButton, GroupuiPopover } from "@group-ui/group-ui-react";
import React, { useEffect, useState } from "react";
import OutsideClickHandler from "./GenericOutsideClickListener";

interface GenericMoreButtonProps {
    dropDownState: boolean;
    setDropDownState: () => void;
    options: Array<JSX.Element | boolean>;
    title?: string;
    icon?: string;
}

const GenericMoreButton: React.FC<GenericMoreButtonProps> = ({
    dropDownState,
    setDropDownState,
    options,
    title = "",
    icon = "",
}) => {
    const [showOptions, setShowOptions] = useState<boolean>(false);

    return title === "" ? (
        <div>
            <OutsideClickHandler onOutsideClick={() => setShowOptions(false)}>
                <GroupuiButton
                    key={"More-Button"}
                    variant="secondary"
                    iconOnly={true}
                    icon="dots-horizontal-filled-32"
                    fullwidth={true}
                    style={{ width: "6rem" }}
                    onClick={() => setShowOptions(!showOptions)}
                />
                <div
                    style={{
                        display: showOptions ? "flex" : "none",
                        flexDirection: "column",
                        gap: "var(--groupui-sys-spacing-50)",
                        position: "absolute",
                        width: "20rem",
                        right: "3.5rem",
                        zIndex: "5",
                        paddingTop: "var(--groupui-sys-spacing-100)",
                    }}
                    onClick={() => setShowOptions(false)}
                    onKeyDown={() => setShowOptions(false)}
                    tabIndex={0}
                    role="button"
                >
                    {options.map((item) => item)}
                </div>
            </OutsideClickHandler>
        </div>
    ) : (
        <OutsideClickHandler onOutsideClick={() => setShowOptions(false)}>
            <GroupuiButton
                key={"More-Button"}
                size="s"
                icon={icon}
                iconOnly={false}
                style={{ width: "12rem" }}
                fullwidth={true}
                onClick={() => setShowOptions(!showOptions)}
                slot="parent"
                variant="secondary"
            >
                {title}
            </GroupuiButton>

            <div
                style={{
                    display: showOptions ? "flex" : "none",
                    flexDirection: "column",
                    gap: "var(--groupui-sys-spacing-100)",
                    position: "absolute",
                    width: "20rem",
                    right: "15.5rem",
                    zIndex: "1000",
                    paddingTop: "var(--groupui-sys-spacing-100)",
                    //background: "white",
                    borderRadius: "30px",
                }}
                onClick={() => setShowOptions(false)}
                onKeyDown={() => setShowOptions(false)}
                tabIndex={0}
                role="button"
            >
                {options.map((item) => item)}
            </div>
        </OutsideClickHandler>
    );
};

export default GenericMoreButton;
