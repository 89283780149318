export class JobReport {
    public orderId;
    public databoxName;
    public carId;
    public ingestId;
    public ingestStation;
    public orderCreated;
    public pickedUpHub;
    public deliveredToCustomer;
    public durationTransitDelivery;
    public durationRequestOrder;
    public pickupRequested;
    public requestedPickupDate;
    public droppedOffAccessPoint;
    public pickedUpCustomer;
    public durationInUseByCustomer;
    public deliveredToHub;
    public durationTransitReturn;
    public assignedIngestSlot;
    public creationSmarthandsTicketIngest;
    public durationSmarthandsTicketIngest;
    public ingestStartedFirstTry;
    public ingestStartedLastSuccessfulTry;
    public uploadStartedLastSuccessfulTry;
    public uploadCompleted;
    public durationUpload;
    public completenessCheckTries;
    public ingestCompleted;
    public ingestDuration;
    public creationSmarthandsTicketPostIngest;
    public orderCompleted;
    public durationSmarthandsTicketPostIngest;
    public durationTillUploadCompleted;
    public ingestDetailsMessage;
    public ingestRestarts;

    constructor({
        orderId,
        databoxName,
        carId,
        ingestId,
        ingestStation,
        orderCreated,
        pickedUpHub,
        deliveredToCustomer,
        durationTransitDelivery,
        durationRequestOrder,
        pickupRequested,
        requestedPickupDate,
        droppedOffAccessPoint,
        pickedUpCustomer,
        durationInUseByCustomer,
        deliveredToHub,
        durationTransitReturn,
        assignedIngestSlot,
        creationSmarthandsTicketIngest,
        durationSmarthandsTicketIngest,
        ingestStartedFirstTry,
        ingestStartedLastSuccessfulTry,
        uploadStartedLastSuccessfulTry,
        uploadCompleted,
        durationUpload,
        completenessCheckTries,
        ingestCompleted,
        ingestDuration,
        creationSmarthandsTicketPostIngest,
        orderCompleted,
        durationSmarthandsTicketPostIngest,
        durationTillUploadCompleted,
        ingestDetailsMessage,
        ingestRestarts,
    }: {
        orderId: string;
        databoxName: string;
        carId: string;
        ingestId: string;
        ingestStation: string;
        orderCreated: string;
        pickedUpHub: string;
        deliveredToCustomer: string;
        durationTransitDelivery: number;
        durationRequestOrder: number;
        pickupRequested: string;
        requestedPickupDate: string;
        droppedOffAccessPoint: string;
        pickedUpCustomer: string;
        durationInUseByCustomer: number;
        deliveredToHub: string;
        durationTransitReturn: number;
        assignedIngestSlot: string;
        creationSmarthandsTicketIngest: string;
        durationSmarthandsTicketIngest: number;
        ingestStartedFirstTry: string;
        ingestStartedLastSuccessfulTry: string;
        uploadStartedLastSuccessfulTry: string;
        uploadCompleted: string;
        durationUpload: number;
        completenessCheckTries: number;
        ingestCompleted: string;
        ingestDuration: number;
        creationSmarthandsTicketPostIngest: string;
        orderCompleted: string;
        durationSmarthandsTicketPostIngest: number;
        durationTillUploadCompleted: number;
        ingestDetailsMessage: string;
        ingestRestarts: number;
    }) {
        this.orderId = orderId;
        this.databoxName = databoxName;
        this.carId = carId;
        this.ingestId = ingestId;
        this.ingestStation = ingestStation;
        this.orderCreated = orderCreated;
        this.pickedUpHub = pickedUpHub;
        this.deliveredToCustomer = deliveredToCustomer;
        this.durationTransitDelivery = durationTransitDelivery;
        this.durationRequestOrder = durationRequestOrder;
        this.pickupRequested = pickupRequested;
        this.requestedPickupDate = requestedPickupDate;
        this.droppedOffAccessPoint = droppedOffAccessPoint;
        this.pickedUpCustomer = pickedUpCustomer;
        this.durationInUseByCustomer = durationInUseByCustomer;
        this.deliveredToHub = deliveredToHub;
        this.durationTransitReturn = durationTransitReturn;
        this.assignedIngestSlot = assignedIngestSlot;
        this.creationSmarthandsTicketIngest = creationSmarthandsTicketIngest;
        this.durationSmarthandsTicketIngest = durationSmarthandsTicketIngest;
        this.ingestStartedFirstTry = ingestStartedFirstTry;
        this.ingestStartedLastSuccessfulTry = ingestStartedLastSuccessfulTry;
        this.uploadStartedLastSuccessfulTry = uploadStartedLastSuccessfulTry;
        this.uploadCompleted = uploadCompleted;
        this.durationUpload = durationUpload;
        this.completenessCheckTries = completenessCheckTries;
        this.ingestCompleted = ingestCompleted;
        this.ingestDuration = ingestDuration;
        this.creationSmarthandsTicketPostIngest = creationSmarthandsTicketPostIngest;
        this.orderCompleted = orderCompleted;
        this.durationSmarthandsTicketPostIngest = durationSmarthandsTicketPostIngest;
        this.durationTillUploadCompleted = durationTillUploadCompleted;
        this.ingestDetailsMessage = ingestDetailsMessage;
        this.ingestRestarts = ingestRestarts;
    }
    private toObject() {
        return {
            orderId: this.orderId,
            databoxName: this.databoxName,
            carId: this.carId,
            ingestId: this.ingestId,
            ingestStation: this.ingestStation,
            orderCreated: this.orderCreated,
            pickedUpHub: this.pickedUpHub,
            deliveredToCustomer: this.deliveredToCustomer,
            durationTransitDelivery: this.durationTransitDelivery,
            durationRequestOrder: this.durationRequestOrder,
            pickupRequested: this.pickupRequested,
            requestedPickupDate: this.requestedPickupDate,
            droppedOffAccessPoint: this.droppedOffAccessPoint,
            pickedUpCustomer: this.pickedUpCustomer,
            durationInUseByCustomer: this.durationInUseByCustomer,
            deliveredToHub: this.deliveredToHub,
            durationTransitReturn: this.durationTransitReturn,
            assignedIngestSlot: this.assignedIngestSlot,
            creationSmarthandsTicketIngest: this.creationSmarthandsTicketIngest,
            durationSmarthandsTicketIngest: this.durationSmarthandsTicketIngest,
            ingestStartedFirstTry: this.ingestStartedFirstTry,
            ingestStartedLastSuccessfulTry: this.ingestStartedLastSuccessfulTry,
            uploadStartedLastSuccessfulTry: this.uploadStartedLastSuccessfulTry,
            uploadCompleted: this.uploadCompleted,
            durationUpload: this.durationUpload,
            completenessCheckTries: this.completenessCheckTries,
            ingestCompleted: this.ingestCompleted,
            ingestDuration: this.ingestDuration,
            creationSmarthandsTicketPostIngest: this.creationSmarthandsTicketPostIngest,
            orderCompleted: this.orderCompleted,
            durationSmarthandsTicketPostIngest: this.durationSmarthandsTicketPostIngest,
            durationTillUploadCompleted: this.durationTillUploadCompleted,
            ingestDetailsMessage: this.ingestDetailsMessage,
            ingestRestarts: this.ingestRestarts,
        };
    }
    static fromSerialized(json: object): JobReport | null {
        if (json == null) {
            return null;
        }
        return new JobReport({
            orderId: json["orderId"],
            databoxName: json["databoxName"],
            carId: json["carId"],
            ingestId: json["ingestId"],
            ingestStation: json["ingestStation"],
            orderCreated: json["orderCreated"],
            pickedUpHub: json["pickedUpHub"],
            deliveredToCustomer: json["deliveredToCustomer"],
            durationTransitDelivery: json["durationTransitDelivery"],
            durationRequestOrder: json["durationRequestOrder"],
            pickupRequested: json["pickupRequested"],
            requestedPickupDate: json["requestedPickupDate"],
            droppedOffAccessPoint: json["droppedOffAccessPoint"],
            pickedUpCustomer: json["pickedUpCustomer"],
            durationInUseByCustomer: json["durationInUseByCustomer"],
            deliveredToHub: json["deliveredToHub"],
            durationTransitReturn: json["durationTransitReturn"],
            assignedIngestSlot: json["assignedIngestSlot"],
            creationSmarthandsTicketIngest: json["creationSmarthandsTicketIngest"],
            durationSmarthandsTicketIngest: json["durationSmarthandsTicketIngest"],
            ingestStartedFirstTry: json["ingestStartedFirstTry"],
            ingestStartedLastSuccessfulTry: json["ingestStartedLastSuccessfulTry"],
            uploadStartedLastSuccessfulTry: json["uploadStartedLastSuccessfulTry"],
            uploadCompleted: json["uploadCompleted"],
            durationUpload: json["durationUpload"],
            completenessCheckTries: json["completenessCheckTries"],
            ingestCompleted: json["ingestCompleted"],
            ingestDuration: json["ingestDuration"],
            creationSmarthandsTicketPostIngest: json["creationSmarthandsTicketPostIngest"],
            orderCompleted: json["orderCompleted"],
            durationSmarthandsTicketPostIngest: json["durationSmarthandsTicketPostIngest"],
            durationTillUploadCompleted: json["durationTillUploadCompleted"],
            ingestDetailsMessage: json["ingestDetailsMessage"],
            ingestRestarts: json["ingestRestarts"],
        });
    }
}
