export class DataboxPin {
    public databoxId;
    public databoxName;
    public databoxSerialNumber;
    public storageCapacity;
    public databoxStatus;
    public battery;
    public latitude;
    public longitude;
    public ingestLocation;
    public internalProject;
    public currnetHolder;

    constructor({
        databoxId,
        databoxName,
        databoxSerialNumber,
        storageCapacity,
        databoxStatus,
        battery,
        latitude,
        longitude,
        ingestLocation,
        internalProject,
        currnetHolder,
    }: {
        databoxId: number;
        databoxName: string;
        databoxSerialNumber: string;
        storageCapacity: number;
        databoxStatus: string;
        battery: number;
        latitude: number;
        longitude: number;
        ingestLocation: string;
        internalProject: string;
        currnetHolder: string;
    }) {
        this.databoxId = databoxId;
        this.databoxName = databoxName;
        this.databoxSerialNumber = databoxSerialNumber;
        this.storageCapacity = storageCapacity;
        this.databoxStatus = databoxStatus;
        this.battery = battery;
        this.latitude = latitude;
        this.longitude = longitude;
        this.ingestLocation = ingestLocation;
        this.internalProject = internalProject;
        this.currnetHolder = currnetHolder;
    }

    public get storageCapacityDisplayName() {
        return this.storageCapacity + " TB";
    }

    private toObject() {
        return {
            databoxId: this.databoxId,
            databoxName: this.databoxName,
            databoxSerialNumber: this.databoxSerialNumber,
            databoxStatus: this.databoxStatus,
            battery: this.battery,
            latitude: this.latitude,
            longitude: this.longitude,
            ingestLocation: this.ingestLocation,
            internalProject: this.internalProject,
            currnetHolder: this.currnetHolder,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): DataboxPin | null {
        if (json == null) {
            return null;
        }

        return new DataboxPin({
            databoxId: json["databoxId"],
            databoxName: json["databoxName"],
            databoxSerialNumber: json["databoxSerialNumber"],
            storageCapacity: json["storageCapacity"],
            databoxStatus: json["databoxStatus"],
            battery: json["battery"],
            latitude: json["latitude"],
            longitude: json["longitude"],
            ingestLocation: json["ingestLocation"],
            internalProject: json["internalProject"],
            currnetHolder: json["currnetHolder"],
        });
    }
}
