import { GroupuiIcon } from "@group-ui/group-ui-react";
import React, { useState } from "react";
import GenericHeadline from "../generics/GenericHeadline";
import GenericTag from "../generics/GenericTag";
import TrackerDataTable from "../generics/TrackerDataTable";
import GeneralInformation from "./GeneralInformation";
import { Order } from "../../models/order";
import SmartHandsTasksTable from "./SmartHandsTasksTable";
import IngestJobPauseInformation from "./pauseIngestJob/IngestJobPauseInformation";
import DashboardDetailsWrapper from "./DashboardDetailsWrapper";
interface Props {
    order: Order | undefined;
    refreshOrder: () => void; // later used for refresh of Status details
}

const GeneralInformationTab: React.FC<Props> = ({ order, refreshOrder }) => {
    const [showDetail, setShowDetail] = useState(false);
    const [overwriteState, setOverwriteState] = useState<string>();
    const [ingestAborted, setIngestAborted] = useState<boolean>(false);

    const checkIngestState = () => {
        return (
            order?.databox.mediaStateType.mediaStateType == "Pre-Upload" ||
            order?.databox.mediaStateType.mediaStateType == "Uploading" ||
            order?.databox.mediaStateType.mediaStateType == "Error" ||
            order?.databox.mediaStateType.mediaStateType == "Upload Completed"
        );
    };

    return (
        <div>
            <IngestJobPauseInformation order={order} />
            <GeneralInformation order={order} />
            {order && (
                <SmartHandsTasksTable
                    ingestJob={order?.ingestJob}
                    databoxName={order?.databox.name ?? ""}
                    ingestSlotSerialNumber={order?.ingestJob?.ingestSlot?.serialNumber ?? ""}
                    smartHandsTicket1={order?.ingestJob?.smartHandsTicket1Id ?? ""}
                    smartHandsTicket2={order?.ingestJob?.smartHandsTicket2Id ?? ""}
                    ingestStation={order.databox.ingestStation?.name}
                />
            )}

            <div
                style={{
                    paddingBottom: "var(--groupui-sys-spacing-400)",
                    paddingTop: "var(--groupui-sys-spacing-400)",
                }}
            >
                <GenericHeadline title={"Upload Status"} titleHeadingType="h4" actionButtons={[]} />

                <div
                    style={{
                        display: "flex",
                        gap: "var(--groupui-sys-spacing-100)",
                        marginTop: "-10px",
                    }}
                >
                    <GenericTag
                        text={
                            order?.completed === true
                                ? "Order Completed"
                                : ingestAborted
                                ? order?.databox.mediaStateType.mediaStateType ?? ""
                                : overwriteState !== undefined
                                ? overwriteState
                                : order?.databox.mediaStateType.mediaStateType ?? ""
                        }
                        variant={""}
                        mediaStateTarget="databox"
                    />
                    {checkIngestState() ? (
                        <GroupuiIcon
                            name="search-plus-32"
                            style={{ cursor: "pointer" }}
                            onClick={() => setShowDetail(true)}
                        />
                    ) : (
                        ""
                    )}
                </div>
            </div>
            <GenericHeadline title={"Tracker"} titleHeadingType="h4" actionButtons={[]} />
            <TrackerDataTable trackerData={order?.databox.tracker?.trackerData ?? []} />
            {checkIngestState() === true && (
                <DashboardDetailsWrapper
                    ingestStationId={order?.databox.ingestStation?.id ?? 0}
                    ingestSlotSerialNumber={order?.ingestJob?.ingestSlot?.serialNumber ?? ""}
                    showDetails={showDetail}
                    onClose={() => setShowDetail(false)}
                />
            )}
        </div>
    );
};

export default GeneralInformationTab;
