import React, { useContext, useState } from "react";
import { GroupuiIcon, GroupuiLoadingSpinner, GroupuiTooltip } from "@group-ui/group-ui-react";
import { useNavigate } from "react-router-dom";
import GenericTable, { columnDefinition } from "../generics/GenericTable";
import useTitle from "../../hooks/useTitle";
import { IngestStation } from "../../models/ingestStation";
import GenericHeadline from "../generics/GenericHeadline";
import Snackbar from "../generics/Snackbar";
import { IngestStationContext } from "../context/provider/IngestStationContext";

const styles = {
    content: {
        padding: "var(--groupui-sys-spacing-1200) var(--groupui-sys-spacing-1400)",
    },
    magnifyingGlass: {
        cursor: "pointer",
        color: "var(--groupui-sys-color-action-basic-default)",
    },
};

const columns: columnDefinition[] = [
    {
        key: "name",
        header: "Name",
        width: "auto",
    },
    {
        key: "country",
        header: "Country",
        width: "auto",
    },
    {
        key: "city",
        header: "City",
        width: "auto",
    },
    {
        key: "type",
        header: "Type",
        width: "auto",
    },
    {
        key: "managedBy",
        header: "Managed By",
        width: "auto",
    },
    {
        key: "smartHands",
        header: "Smart Hands",
        width: "auto",
    },
    {
        key: "logisticService",
        header: "Logistic Service",
        width: "auto",
    },
    {
        key: "ingestSlots",
        header: "Ingest Slots",
        width: "auto",
    },
    {
        key: "button",
        width: "4rem",
    },
];

function IngestStations() {
    useTitle("Ingest Stations");
    const navigate = useNavigate();
    const [snackbarSeverity, setSnackbarSeverity] = useState<"danger" | "warning" | "success">(
        "danger"
    );

    const { isLoading, ingestStations } = useContext(IngestStationContext);

    const [showSnackBar, setShowSnackBar] = useState(false);
    const openSnackbar = () => {
        setShowSnackBar(true);
    };

    const closeSnackbar = () => {
        setShowSnackBar(false);
    };

    const changeSeverity = () => {
        if (snackbarSeverity == "danger") setSnackbarSeverity("warning");
        if (snackbarSeverity == "warning") setSnackbarSeverity("success");
        if (snackbarSeverity == "success") setSnackbarSeverity("danger");
    };

    const demoFunction = () => {
        changeSeverity();
        openSnackbar();
    };

    const data =
        ingestStations?.map((ingestStation: IngestStation) => {
            return {
                name: ingestStation.name,
                country: ingestStation.address?.country.country ?? "",
                city: ingestStation.address?.city ?? "",
                type: ingestStation.ingestStationtype?.ingestStationType ?? "",
                managedBy: ingestStation.address?.company?.company ?? "",
                smartHands: ingestStation.smartHands ? "Yes" : "No",
                logisticService: ingestStation.logisticService ? "Yes" : "No",
                ingestSlots: ingestStation.ingestSlotsCount,
                button: (
                    <GroupuiTooltip position="right">
                        <GroupuiIcon
                            name="search-plus-32"
                            style={styles.magnifyingGlass}
                            onClick={() => navigate(`/ingest-stations/${ingestStation.id}`)}
                        />
                        <div slot="tooltip">Show Details</div>
                    </GroupuiTooltip>
                ),
            };
        }) ?? [];

    return (
        <div
            style={{
                padding: "var(--groupui-sys-spacing-1200) var(--groupui-sys-spacing-1400)",
                scrollBehavior: "smooth",
                whiteSpace: "pre-wrap",
            }}
        >
            {showSnackBar && (
                <Snackbar
                    severity={snackbarSeverity}
                    message={
                        "This feature is not yet implemented! This feature is not yet implemented! This feature is not yet implemented! This feature is not yet implemented! This feature is not yet implemented! This feature is not yet implemented! This feature is not yet implemented! This feature is not yet implemented! "
                    }
                    onClose={closeSnackbar}
                />
            )}
            <GroupuiLoadingSpinner displayed={isLoading} />
            <GenericHeadline
                title="Ingest Stations"
                actionButtons={
                    [
                        // <RightsWrapper
                        //     key={"first"}
                        //     authorizedUserGroup={["VWAG_GROUPMDM_SUPPORT", "VWAG_GROUPMDM_ADMINISTRATOR"]}
                        //     userGroups={userGroups}
                        // >
                        //     <GroupuiButton key={"first"} icon="add-16" onClick={demoFunction}>
                        //         Add Ingest Station
                        //     </GroupuiButton>
                        // </RightsWrapper>,
                    ]
                }
            />

            <div>
                <GenericHeadline title="Hubs" titleHeadingType="h4" actionButtons={[]} />
            </div>
            <div>
                <GenericTable
                    columns={columns}
                    data={data.filter((element) => element.type == "Managed Hub")}
                    header={true}
                />
            </div>
            <div
                style={{
                    margin: "var(--groupui-sys-spacing-1200) var(--groupui-sys-spacing-0) var(--groupui-sys-spacing-0) var(--groupui-sys-spacing-0)",
                }}
            >
                <GenericHeadline
                    title="Local Ingest Stations"
                    titleHeadingType="h4"
                    actionButtons={[]}
                />
            </div>
            <div>
                <GenericTable
                    columns={columns}
                    data={data.filter((element) => element.type !== "Managed Hub")}
                    header={true}
                />
            </div>
        </div>
    );
}

export default IngestStations;
