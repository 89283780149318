import React, { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import DataboxDetailsWrapper from "../container/DataboxDetailsWrapper";
import DataboxDetails from "../databoxes/DataboxDetails";
import Databoxes from "../databoxes/Databoxes";
import { RightsWrapper } from "../container/RightsWrapper";
import UserGroupContext from "../context/UserGroupContext";
import { RightsManagement } from "../../config/rightsManagement";
import { DataboxPoolProvider } from "../context/provider/DataboxPoolContext";

/**
 * A DataboxesRoutes Component, which defines the Nested Routes nested under the parent for databox-content
 *
 * @returns DataboxesRoutes
 *
 */
function DataboxesRoutes() {
    const { userGroups } = useContext(UserGroupContext);
    return (
        <RightsWrapper
            key={"first"}
            authorizedUserGroup={RightsManagement.DATABOXES_ROUTES}
            userGroups={userGroups}
            section={true}
        >
            <DataboxPoolProvider>
                <Routes>
                    <Route index element={<Databoxes />} />
                    <Route path=":databoxID/*" element={<DataboxDetailsWrapper />}>
                        <Route index element={<DataboxDetails />} />
                        {/* Redirect the user if the Route doesn´t exist */}
                        <Route path="*" element={<Navigate to="/databox-pool" />} />
                    </Route>
                </Routes>
            </DataboxPoolProvider>
        </RightsWrapper>
    );
}

export default DataboxesRoutes;
