export class TrackerData {
    private _id;
    private _trackerId;
    public time;
    public latitude;
    public longitude;
    public temperature;
    public light;
    public humidity;
    public pressure;
    public battery;

    constructor({
        id,
        trackerId,
        time,
        latitude,
        longitude,
        temperature,
        light,
        humidity,
        pressure,
        battery,
    }: {
        id: number;
        trackerId: number;
        time: string;
        latitude: number;
        longitude: number;
        temperature: number;
        light: number;
        humidity: number;
        pressure: number;
        battery: number;
    }) {
        this._id = id;
        this._trackerId = trackerId;
        this.time = time;
        this.latitude = latitude;
        this.longitude = longitude;
        this.temperature = temperature;
        this.light = light;
        this.humidity = humidity;
        this.pressure = pressure;
        this.battery = battery;
    }

    private toObject() {
        return {
            id: this._id,
            trackerId: this._trackerId,
            time: this.time,
            latitude: this.latitude,
            longitude: this.longitude,
            temperature: this.temperature,
            light: this.light,
            humidity: this.humidity,
            pressure: this.pressure,
            battery: this.battery,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): TrackerData | null {
        if (json == null) {
            return null;
        }

        return new TrackerData({
            id: json["id"],
            trackerId: json["trackerId"],
            time: json["time"],
            latitude: json["latitude"],
            longitude: json["longitude"],
            temperature: json["temperature"],
            light: json["light"],
            humidity: json["humidity"],
            pressure: json["pressure"],
            battery: json["battery"],
        });
    }
}
