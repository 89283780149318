import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import ManageProjects from "../manageProjects/ManageProjects";
import { RightsWrapper } from "../container/RightsWrapper";
import UserGroupContext from "../context/UserGroupContext";
import { RightsManagement } from "../../config/rightsManagement";

/**
 * A ManageProjectsRoutes Component, which defines the Nested Routes nested under the parent for manage-projects-content
 *
 * @returns ManageProjectsRoutes
 *
 */
function ManageProjectsRoutes() {
    const { userGroups } = useContext(UserGroupContext);
    return (
        <RightsWrapper
            key={"first"}
            authorizedUserGroup={RightsManagement.MANAGE_PROJECTS_ROUTES}
            userGroups={userGroups}
            section={true}
        >
            <Routes>
                <Route index element={<ManageProjects />} />
            </Routes>
        </RightsWrapper>
    );
}

export default ManageProjectsRoutes;
