export enum Countries {
    AUSTRIA = "Austria",
    BELGIUM = "Belgium",
    CROATIA = "Croatia",
    CZECH_REPUBLIC = "Czech Republic",
    DENMARK = "Denmark",
    FINLAND = "Finland",
    FRANCE = "France",
    GERMANY = "Germany",
    GREECE = "Greece",
    HUNGARY = "Hungary",
    IRELAND = "Ireland",
    ITALY = "Italy",
    NETHERLANDS = "Netherlands",
    POLAND = "Poland",
    PORTUGAL = "Portugal",
    ROMANIA = "Romania",
    SLOVAKIA = "Slovakia",
    SLOVENIA = "Slovenia",
    SPAIN = "Spain",
    SWEDEN = "Sweden",
    UNITED_STATES = "United States",
}
