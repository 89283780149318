import React, { Dispatch, Fragment, SetStateAction, useContext, useEffect, useState } from "react";
import {
    GroupuiIcon,
    GroupuiLink,
    GroupuiLoadingSpinner,
    GroupuiTooltip,
} from "@group-ui/group-ui-react";
import GenericHeadline from "../../generics/GenericHeadline";
import GenericTable, { columnDefinition } from "../../generics/GenericTable";
import GenericTag from "../../generics/GenericTag";
import { ApiService } from "../../../services/apiService";
import ErrorContext from "../../context/ErrorContext";
import { parseFullDate } from "../../../utils/DateUtil";
import { RightsManagement } from "../../../config/rightsManagement";
import { RightsWrapper } from "../../container/RightsWrapper";
import UserGroupContext from "../../context/UserGroupContext";
import { IngestSlotHistory } from "../../../models/ingestSlot/ingestSlotHistory";
import { IngestSlotBooking } from "../../../models/ingestSlot/ingestSlotBooking";
import { DeleteStateModal } from "./modals/DeleteStateModal";
import { IngestSlotStateModal, IngestSlotStateMode } from "./modals/IngestSlotStateModal";
import { handleError } from "../../context/ErrorContextUtils";
import DashboardDetailsWrapper from "../../ingestJobs/DashboardDetailsWrapper";
import { IngestStationContext } from "../../context/provider/IngestStationContext";
import { Link, Navigate, NavigateFunction, useNavigate } from "react-router-dom";
import { isInUserGroup } from "../../../utils/RightsUtils";
const styles = {
    magnifyingGlass: {
        cursor: "pointer",
        color: "var(--groupui-sys-color-action-basic-default)",
    },
    stateContainer: {
        marginBottom: "var(--groupui-sys-spacing-400)",
        display: "flex",
        flexDirection: "row",
    } as React.CSSProperties,
};

const bookingColumns: columnDefinition[] = [
    {
        key: "button1",
        width: "4rem",
    },
    {
        key: "button2",
        width: "4rem",
    },
];

const historyColumns: columnDefinition[] = [
    {
        key: "created",
        header: "Created",
        width: "15rem",
    },
    {
        key: "activity",
        header: "Activity",
        width: "20rem",
    },
    {
        key: "details",
        header: "Details",
        width: "auto",
    },
    {
        key: "comment",
        header: "Comment",
        width: "auto",
    },
];

interface IngestSlotStatusInformationProps {
    setActiveTabId: Dispatch<SetStateAction<string>>;
    refreshIngestSlot: () => void;
}

export const IngestSlotStatusInformation: React.FC<IngestSlotStatusInformationProps> = ({
    setActiveTabId,
    refreshIngestSlot,
}) => {
    // page related parameter
    const { userGroups } = useContext(UserGroupContext);
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const { currentIngestStation, currentIngestSlot } = useContext(IngestStationContext);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showDetail, setShowDetail] = useState<boolean>(false);

    const [deleteStateModal, setDeleteStateModal] = useState<boolean>(false);
    const [editStateModal, setEditStateModal] = useState<boolean>(false);
    const [editStateData, setEditStateData] = useState<any>();
    const [editStateModalMode, setEditStateModalMode] = useState<IngestSlotStateMode>();

    // data related parameter
    const [ingestSlotHistoryData, setIngestSlotHistoryData] = useState<
        IngestSlotHistory[] | null
    >();
    const [ingestSlotBookingData, setIngestSlotBookingData] = useState<
        IngestSlotBooking[] | null
    >();
    const [selectedEntryId, setSelectedEntryId] = useState<number>(-1);

    const navigate = useNavigate();

    // Fetch bookings and history
    useEffect(() => {
        getData();
    }, [currentIngestSlot]);

    const getData = async () => {
        if (currentIngestSlot?.id) {
            setIsLoading(true);
            await Promise.all([
                // GET Request for History data
                ApiService.getAllIngestSlotHistoriesByIngestSlotId(currentIngestSlot.id)
                    .then((ingestSlotHistory: IngestSlotHistory[] | null) =>
                        setIngestSlotHistoryData(ingestSlotHistory)
                    )
                    .catch((error) => handleError(error, changeErrorMessage, changeShowError)),
                // GET Request for Booking data
                ApiService.getAllIngestSlotBookingsByIngestSlotId(currentIngestSlot.id)
                    .then((ingestSlotBooking: IngestSlotBooking[] | null) =>
                        setIngestSlotBookingData(ingestSlotBooking)
                    )
                    .catch((error) => handleError(error, changeErrorMessage, changeShowError)),
            ]).then(() => setIsLoading(false));
        }
    };

    const getIngestSlotBookingForCurrentState = () => {
        if (currentIngestSlot) {
            setIsLoading(true);
            ApiService.getCurrentIngestSlotBookingByIngestSlotId(currentIngestSlot.id)
                .then((ingestSlotBooking: IngestSlotBooking | null) => {
                    setDeleteStateModal(true);
                    setSelectedEntryId(ingestSlotBooking?.id ?? -1);
                    setIsLoading(false);
                })
                .catch((error) => {
                    handleError(error, changeErrorMessage, changeShowError);
                    setIsLoading(false);
                });
        }
    };

    const checkMediaState = (mediaState: string): boolean => {
        if (
            mediaState.toLowerCase() === "decommissioned" ||
            mediaState.toLowerCase() === "maintenance" ||
            mediaState.toLowerCase() === "alliance"
        )
            return true;
        return false;
    };

    const histroyData =
        ingestSlotHistoryData?.map((ingestSlotHistoryEntry: IngestSlotHistory) => {
            return {
                // started is the time when the state was set the first time
                created: parseFullDate(ingestSlotHistoryEntry.started),
                activity: (
                    <GenericTag
                        text={ingestSlotHistoryEntry.mediaState.mediaStateType}
                        variant="primary"
                        hasSmartHands={currentIngestStation?.smartHands}
                        mediaStateTarget="ingest-slot"
                    />
                ),
                details: generateHistoryAndCurrentDetails(
                    ingestSlotHistoryEntry,
                    userGroups,
                    navigate
                ),
                comment: ingestSlotHistoryEntry.ingestSlotDetails?.comment ?? "",
            };
        }) ?? [];

    const bookingData = [
        {
            created: parseFullDate(currentIngestSlot?.ingestSlotDetails?.created),
            activity: (
                <GenericTag
                    text={currentIngestSlot?.mediaState.mediaStateType ?? ""}
                    variant="primary"
                    hasSmartHands={currentIngestStation?.smartHands}
                    mediaStateTarget="ingest-slot"
                />
            ),
            details: currentIngestSlot
                ? generateHistoryAndCurrentDetails(currentIngestSlot, userGroups, navigate)
                : "",
            comment: currentIngestSlot?.ingestSlotDetails?.comment ?? "",
            button1: (
                <RightsWrapper
                    authorizedUserGroup={RightsManagement.EDIT_INGEST_SLOT_STATE}
                    userGroups={userGroups}
                >
                    {(checkMediaState(currentIngestSlot?.mediaState.mediaStateType ?? "") ||
                        (currentIngestSlot?.mediaState.mediaStateType.toLowerCase() ===
                            "reserved" &&
                            currentIngestSlot.ingestSlotDetails?.by.toLowerCase() != "portal")) && (
                        <GroupuiTooltip position="left">
                            <GroupuiIcon
                                name="edit-32"
                                style={styles.magnifyingGlass}
                                onClick={() => {
                                    setEditStateModal(true);
                                    setEditStateData(currentIngestSlot?.ingestSlotDetails);
                                    setEditStateModalMode(IngestSlotStateMode.EDIT_CURRENT);
                                }}
                            />
                            <div slot="tooltip">Edit</div>
                        </GroupuiTooltip>
                    )}
                </RightsWrapper>
            ),
            button2: (
                <RightsWrapper
                    authorizedUserGroup={RightsManagement.DELETE_INGEST_SLOT_STATE}
                    userGroups={userGroups}
                >
                    {(checkMediaState(
                        currentIngestSlot?.ingestSlotDetails?.mediaState.mediaStateType ?? ""
                    ) ||
                        (currentIngestSlot?.mediaState.mediaStateType.toLowerCase() ===
                            "reserved" &&
                            currentIngestSlot.ingestSlotDetails?.by.toLowerCase() != "portal")) && (
                        <GroupuiTooltip position="left">
                            <GroupuiIcon
                                name="trash-32"
                                style={styles.magnifyingGlass}
                                onClick={() => getIngestSlotBookingForCurrentState()}
                            />
                            <div slot="tooltip">Delete</div>
                        </GroupuiTooltip>
                    )}
                </RightsWrapper>
            ),
        },
        ...(ingestSlotBookingData?.map((ingestSlotBooking: IngestSlotBooking) => {
            return {
                created: parseFullDate(ingestSlotBooking.created),
                activity: (
                    <GenericTag
                        text={ingestSlotBooking.mediaState.mediaStateType}
                        variant="primary"
                        hasSmartHands={currentIngestStation?.smartHands}
                        mediaStateTarget="ingest-slot"
                    />
                ),
                details: generateBookingDetails(ingestSlotBooking),
                comment: ingestSlotBooking.comment ?? "",
                button1: (
                    <RightsWrapper
                        authorizedUserGroup={RightsManagement.EDIT_INGEST_SLOT_STATE}
                        userGroups={userGroups}
                    >
                        {(checkMediaState(ingestSlotBooking.mediaState.mediaStateType ?? "") ||
                            ingestSlotBooking.mediaState.mediaStateType.toLowerCase() ===
                                "reserved") &&
                            ingestSlotBooking.by.toLowerCase() != "portal" && (
                                <GroupuiTooltip position="left">
                                    <GroupuiIcon
                                        name="edit-32"
                                        style={styles.magnifyingGlass}
                                        onClick={() => {
                                            setEditStateModal(true);
                                            setEditStateData(ingestSlotBooking);
                                            setEditStateModalMode(IngestSlotStateMode.EDIT);
                                        }}
                                    />
                                    <div slot="tooltip">Edit</div>
                                </GroupuiTooltip>
                            )}
                    </RightsWrapper>
                ),
                button2: (
                    <RightsWrapper
                        authorizedUserGroup={RightsManagement.DELETE_INGEST_SLOT_STATE}
                        userGroups={userGroups}
                    >
                        {(checkMediaState(ingestSlotBooking.mediaState.mediaStateType ?? "") ||
                            ingestSlotBooking.mediaState.mediaStateType.toLowerCase() ===
                                "reserved") &&
                            ingestSlotBooking.by.toLowerCase() != "portal" && (
                                <GroupuiTooltip position="left">
                                    <GroupuiIcon
                                        name="trash-32"
                                        style={styles.magnifyingGlass}
                                        onClick={() => {
                                            setDeleteStateModal(true);
                                            setSelectedEntryId(ingestSlotBooking.id ?? -1);
                                        }}
                                    />
                                    <div slot="tooltip">Delete</div>
                                </GroupuiTooltip>
                            )}
                    </RightsWrapper>
                ),
            };
        }) ?? []),
    ];

    const checkIngestState = () => {
        return (
            currentIngestSlot?.mediaState.mediaStateType == "Pre-Upload" ||
            currentIngestSlot?.mediaState.mediaStateType == "Uploading" ||
            currentIngestSlot?.mediaState.mediaStateType == "Error" ||
            currentIngestSlot?.mediaState.mediaStateType == "Upload Completed"
        );
    };

    const onSend = () => {
        refreshIngestSlot();
        getData();
        handleModalClose();
    };

    const handleModalClose = () => {
        setDeleteStateModal(false);
        setEditStateModal(false);
        setSelectedEntryId(-1);
        setActiveTabId("statusInformationId");
    };

    return (
        <div>
            <GroupuiLoadingSpinner displayed={isLoading} />
            <RightsWrapper
                key={"rights-first"}
                authorizedUserGroup={RightsManagement.DELETE_INGEST_SLOT_STATE}
                userGroups={userGroups}
            >
                <DeleteStateModal
                    showModal={deleteStateModal}
                    setShowModal={setDeleteStateModal}
                    onModalClose={handleModalClose}
                    onSend={onSend}
                    selectedEntryId={selectedEntryId}
                />
                <IngestSlotStateModal
                    showModal={editStateModal}
                    setShowModal={setEditStateModal}
                    mode={editStateModalMode}
                    onModalClose={handleModalClose}
                    onSend={onSend}
                    data={editStateData}
                />
            </RightsWrapper>
            <GenericHeadline
                title="Current & Future States"
                titleHeadingType="h4"
                actionButtons={[]}
            />
            {checkIngestState() === true && (
                <DashboardDetailsWrapper
                    ingestStationId={currentIngestStation?.id ?? 0}
                    ingestSlotSerialNumber={currentIngestSlot?.serialNumber ?? ""}
                    showDetails={showDetail}
                    onClose={() => setShowDetail(false)}
                />
            )}
            <div style={styles.stateContainer}>
                <GenericTag
                    text={currentIngestSlot?.mediaState.mediaStateType ?? ""}
                    variant="primary"
                    hasSmartHands={currentIngestStation?.smartHands}
                    mediaStateTarget="ingest-slot"
                />
                {checkIngestState() ? (
                    <GroupuiIcon
                        name="search-plus-32"
                        style={styles.magnifyingGlass}
                        onClick={() => setShowDetail(true)}
                    />
                ) : (
                    ""
                )}
            </div>
            <div>
                <GenericTable
                    columns={historyColumns.concat(bookingColumns)}
                    data={bookingData}
                    header={true}
                    pageSize={5}
                />
            </div>
            <br />
            <GenericHeadline title="History" titleHeadingType="h4" actionButtons={[]} />
            <div>
                <GenericTable
                    columns={historyColumns}
                    data={histroyData}
                    header={true}
                    pageSize={5}
                />
            </div>
        </div>
    );
};

const generateBookingDetails = (ingestSlotBooking: IngestSlotBooking) => {
    const displayArray = [];

    displayArray.push("From: " + parseFullDate(ingestSlotBooking.started));

    if (ingestSlotBooking.finished)
        displayArray.push("To: " + parseFullDate(ingestSlotBooking.finished));
    if (ingestSlotBooking.by) displayArray.push("By: " + ingestSlotBooking.by);

    switch (ingestSlotBooking.mediaState.mediaStateType.toLowerCase()) {
        case "reserved":
            if (ingestSlotBooking.orderId)
                displayArray.push("Order ID: " + ingestSlotBooking.orderId);
            break;
        case "maintenance":
            if (ingestSlotBooking.ticketId)
                displayArray.push("Jira Ticket ID: " + ingestSlotBooking.ticketId);
            break;
        case "alliance":
        case "decommissioned":
            break;
        default:
            return null;
    }

    if (ingestSlotBooking.project) displayArray.push("Project: " + ingestSlotBooking.project?.name);

    return displayArray.join(", ");
};

const generateHistoryAndCurrentDetails = (
    context: any,
    userGroups: string[],
    navigate: NavigateFunction
) => {
    const displayArray = [];
    const showLinks = isInUserGroup(RightsManagement.OPERATION_INGESTS, userGroups);
    let orderIdMsg: string | JSX.Element = "";
    let databoxIdMsg: string | JSX.Element = "";
    let ingestIdMsg: string | JSX.Element = "";
    if (context.ingestSlotDetails) {
        displayArray.push("From: " + parseFullDate(context.ingestSlotDetails.started));

        if (context.ingestSlotDetails.finished)
            displayArray.push("To: " + parseFullDate(context.ingestSlotDetails.finished));
        if (context.ingestSlotDetails.by) displayArray.push("By: " + context.ingestSlotDetails.by);

        if (context.ingestSlotDetails.orderId)
            orderIdMsg = showLinks ? (
                <span>
                    Order ID:{" "}
                    <GroupuiLink
                        quiet={true}
                        onClick={() =>
                            navigate(
                                `/databox-orders/${context.ingestSlotDetails.orderId}/order-details`
                            )
                        }
                    >
                        {context.ingestSlotDetails.orderId}
                    </GroupuiLink>
                </span>
            ) : (
                `Order ID: ${context.ingestSlotDetails.orderId}`
            );
        if (context.ingestSlotDetails.databoxId) {
            databoxIdMsg = showLinks ? (
                <span>
                    Databox ID:{" "}
                    <GroupuiLink
                        quiet={true}
                        onClick={() =>
                            navigate(`/databox-pool/${context.ingestSlotDetails.databoxId}`)
                        }
                    >
                        {context.ingestSlotDetails.databoxId}
                    </GroupuiLink>
                </span>
            ) : (
                `Databox ID: ${context.ingestSlotDetails.databoxId}`
            );
        }
        if (context.ingestSlotDetails.ingestId) {
            ingestIdMsg = showLinks ? (
                <span>
                    Ingest ID:{" "}
                    <GroupuiLink
                        quiet={true}
                        onClick={() =>
                            navigate("/ingests", {
                                state: { ingestId: context.ingestSlotDetails.ingestId },
                            })
                        }
                    >
                        {context.ingestSlotDetails.ingestId}
                    </GroupuiLink>
                </span>
            ) : (
                `Ingest ID: ${context.ingestSlotDetails.ingestId}`
            );
        }
    }

    switch (context.mediaState.mediaStateType.toLowerCase()) {
        case "reserved":
            if (context.ingestSlotDetails) {
                if (context.ingestSlotDetails.orderId) displayArray.push(orderIdMsg);
                break;
            }
            return "No Details available";
        case "maintenance":
            if (context.ingestSlotDetails) {
                if (context.ingestSlotDetails.ticketId)
                    displayArray.push("Jira Ticket ID: " + context.ingestSlotDetails.ticketId);
                break;
            }
            return "No Details available";
        case "alliance":
        case "decommissioned":
            break;
        case "pre-upload":
        case "uploading":
        case "upload cancelled":
        case "upload completed":
            if (context.ingestSlotDetails) {
                const displayArrayIngest = [];

                if (context.ingestSlotDetails.databoxId) displayArrayIngest.push(databoxIdMsg);
                if (context.ingestSlotDetails.orderId) displayArrayIngest.push(orderIdMsg);
                if (context.ingestSlotDetails.ingestId) displayArrayIngest.push(ingestIdMsg);
                if (context.ingestSlotDetails.project)
                    displayArrayIngest.push("Project: " + context.ingestSlotDetails.project?.name);
                if (context.ingestSlotDetails.by)
                    displayArrayIngest.push("By: " + context.ingestSlotDetails.by);

                return IngestSlotDetailsMsg(displayArrayIngest);
            }
            return "No Details available";
        case "error":
            if (context.ingestSlotDetails) {
                const displayArrayIngestError = [];

                if (context.ingestSlotDetails.databoxId) displayArrayIngestError.push(databoxIdMsg);
                if (context.ingestSlotDetails.orderId) displayArrayIngestError.push(orderIdMsg);
                if (context.ingestSlotDetails.ingestId) displayArrayIngestError.push(ingestIdMsg);
                if (context.ingestSlotDetails.project)
                    displayArrayIngestError.push(
                        "Project: " + context.ingestSlotDetails.project?.name
                    );
                if (context.ingestSlotDetails.project)
                    displayArrayIngestError.push(
                        "Jira Ticket ID: " + context.ingestSlotDetails.ticketId
                    );
                if (context.ingestSlotDetails.by)
                    displayArrayIngestError.push("By: " + context.ingestSlotDetails.by);

                return IngestSlotDetailsMsg(displayArrayIngestError);
            }
            return "No Details available";
        default:
            return null;
    }

    if (context.ingestSlotDetails?.project)
        displayArray.push("Project: " + context.ingestSlotDetails.project?.name);

    return IngestSlotDetailsMsg(displayArray);
};

const IngestSlotDetailsMsg = (array: (string | JSX.Element)[]) => {
    return (
        <p>
            {array.map((element, index) => {
                if (index === array.length - 1) {
                    return <Fragment key={index}>{element}</Fragment>;
                }
                return <Fragment key={index}>{element}, </Fragment>;
            })}
        </p>
    );
};
