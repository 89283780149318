import React, { useEffect, useState } from "react";
import GenericFilterBar, { filterColumns } from "./GenericFilter/GenericFilterBar";
import GenericSearchBar, { searchColumns } from "./GenericSearchBar/GenericSearchBar";
import { filterData } from "./GenericFilter/FilterUtils";
import { searchData } from "./GenericSearchBar/GenericSearchBarUtils";

interface GenericFilterAndSearchBarProps {
    data: any[];
    filterColumns?: filterColumns;
    searchColumns?: searchColumns;
    setFilteredData: (data: any[]) => void;
    width?: { filters: string; search: string };
    filterHeight: string;
}
const GenericFilterAndSearchBar: React.FC<GenericFilterAndSearchBarProps> = ({
    data,
    filterColumns = [],
    searchColumns = [],
    setFilteredData,
    width = { filters: "250px", search: "300px" },
    filterHeight,
}) => {
    const [filters, setFilters] = useState<{ [key: string]: string[] }>(() => {
        const newFilters = {};

        filterColumns.forEach((filterColumn) => {
            if (filterColumn.selectedByDefault) {
                newFilters[filterColumn.column] = filterColumn.selectedByDefault;
            }
        });

        return newFilters;
    });
    const [searchQuery, setSearchQuery] = useState<{
        query: string;
        columns: searchColumns;
    }>({ query: "", columns: searchColumns });

    useEffect(() => {
        if (data && data.length > 0) {
            const newFilteredData = filterData(data, filters);
            const newFilteredData2 = searchData(newFilteredData, searchQuery);
            setFilteredData(newFilteredData2);
        }
    }, [filters, searchQuery, data]);

    return (
        <>
            {filterColumns.length > 0 && (
                <GenericFilterBar
                    data={data}
                    filterColumns={filterColumns}
                    filters={filters}
                    setFilters={setFilters}
                    showResetButton={true}
                    filtersWidth={width.filters}
                    initialFilters={filters}
                    filterHeight={filterHeight}
                >
                    {searchColumns.length > 0 ? (
                        <GenericSearchBar
                            searchColumns={searchColumns}
                            searchQuery={searchQuery}
                            setSearchQuery={setSearchQuery}
                            width={width.search}
                        />
                    ) : undefined}
                </GenericFilterBar>
            )}
            {searchColumns.length > 0 && filterColumns.length === 0 && (
                <div
                    style={{
                        paddingBottom: "var(--groupui-sys-spacing-300)",
                        alignItems: "center",
                    }}
                >
                    <GenericSearchBar
                        searchColumns={searchColumns}
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        width={width.search}
                    />
                </div>
            )}
        </>
    );
};

export default GenericFilterAndSearchBar;
