import {
    GroupuiButton,
    GroupuiHeadline,
    GroupuiLoadingSpinner,
    GroupuiModal,
    GroupuiText,
} from "@group-ui/group-ui-react";
import React, { useContext, useState } from "react";
import { Order } from "../../../models/order";
import ErrorContext from "../../context/ErrorContext";
import GenericInput from "../../generics/GenericInput";
import Snackbar from "../../generics/Snackbar";
import { ApiService } from "../../../services/apiService";
import { handleError } from "../../context/ErrorContextUtils";
import GenericHeadline from "../../generics/GenericHeadline";
interface Props {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    showModal: boolean;
    order: Order | undefined;
}

const PauseIngestJobModal: React.FC<Props> = ({ showModal, setShowModal, order }) => {
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
    const [severity, setSeverity] = useState<"success" | "danger">("success");
    const [message, setMessage] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);

    const [jiraTicketId, setJiraTicketId] = useState<string>("");
    const [reasoning, setReasoning] = useState<string>("");

    const pauseIngestJob = () => {
        if (order?.ingestJob?.id) {
            setLoading(true);

            ApiService.pauseIngestJob(order.ingestJob?.id, reasoning, jiraTicketId)
                .then((response) => {
                    if (response.startsWith("success")) {
                        setShowSnackbar(true);
                        setMessage("Successfully paused ingest job!");
                        setTimeout(() => {
                            setLoading(false);
                            setShowModal(false);
                            window.location.reload();
                        }, 2000);
                    } else {
                        setShowSnackbar(true);
                        setMessage("An error occurred during pausing of the ingest-job!");
                        setSeverity("danger");

                        setShowSnackbar(true);
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    handleError(error, changeErrorMessage, changeShowError);
                });
        }
    };

    return (
        <GroupuiModal
            closeButton={true}
            displayed={showModal}
            onGroupuiModalClose={() => setShowModal(false)}
            padding="var(--groupui-sys-spacing-800)"
            margin="150px"
            max-width="20"
            overflow={"visible"}
        >
            {showSnackbar && (
                <Snackbar
                    severity={severity}
                    message={message}
                    onClose={() => setShowSnackbar(false)}
                />
            )}
            <GroupuiLoadingSpinner displayed={loading} />
            <GenericHeadline
                title="Pause Ingest Job"
                titleHeadingType="h3"
                type="modal"
                actionButtons={[]}
            />
            <GroupuiText style={{ width: "390px" }}>
                Are you sure you want to pause this ingest job?
            </GroupuiText>
            <GroupuiText style={{ width: "390px" }}>
                Note: You have to manually cancel the first Equinix SmartHands ticket.
            </GroupuiText>
            <br />
            <GenericInput
                value={jiraTicketId}
                label="Jira Ticket ID"
                maxLength={50}
                onGroupuiChange={(event: { target: { value: React.SetStateAction<string> } }) =>
                    setJiraTicketId(event.target.value)
                }
            />
            <GenericInput
                value={reasoning}
                label="Reasoning*"
                maxLength={50}
                onGroupuiChange={(event: { target: { value: React.SetStateAction<string> } }) =>
                    setReasoning(event.target.value)
                }
            />
            <div
                style={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "var(--groupui-sys-spacing-400)",
                    marginTop: "var(--groupui-sys-spacing-600)",
                }}
            >
                <GroupuiButton variant={"secondary"} onClick={() => setShowModal(false)}>
                    Cancel
                </GroupuiButton>
                <GroupuiButton icon="check-32" disabled={reasoning === ""} onClick={pauseIngestJob}>
                    Confirm
                </GroupuiButton>
            </div>
        </GroupuiModal>
    );
};

export default PauseIngestJobModal;
