import React, { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { RightsWrapper } from "../../container/RightsWrapper";
import UserGroupContext from "../../context/UserGroupContext";
import { columnDefinition } from "../../generics/GenericTable";
import Report, { ReportType } from "../../report/Report";
import IngestsReportDetailsWrapper from "../../report/ingestsReport/IngestsReportDetailsWrapper";
import IngestsReportDetails from "../../report/ingestsReport/IngestsReportDetails";
import { RightsManagement } from "../../../config/rightsManagement";
import { IngestsReportProvider } from "../../context/provider/IngestsReportContext";

/**
 * A IngestsReportRoutes Component, which defines the Nested Routes nested under the parent for ingests-report-content
 *
 * @returns IngestsReportRoutes
 *
 */
const IngestsReportRoutes = () => {
    const { userGroups } = useContext(UserGroupContext);
    return (
        <RightsWrapper
            key={"first"}
            authorizedUserGroup={RightsManagement.INGESTS_REPORT_ROUTES}
            userGroups={userGroups}
            section={true}
        >
            <IngestsReportProvider>
                <Routes>
                    <Route
                        index
                        element={
                            <Report
                                reportType={ReportType.INGESTS_REPORTS}
                                columns={ingestTableColumns}
                            />
                        }
                    />
                    <Route path=":calenderWeek/*" element={<IngestsReportDetailsWrapper />}>
                        <Route index element={<IngestsReportDetails />} />
                        {/* Redirect the user if the Route doesn´t exist */}
                        <Route path="*" element={<Navigate to="/ingests-report" />} />
                    </Route>
                </Routes>
            </IngestsReportProvider>
        </RightsWrapper>
    );
};

const ingestTableColumns: columnDefinition[] = [
    {
        key: "reportId",
        header: "Report ID",
        width: "auto",
    },
    {
        key: "from",
        header: "From",
        width: "auto",
    },
    {
        key: "to",
        header: "To",
        width: "auto",
    },
    {
        key: "successfulIngests",
        header: "Successful Ingests",
        width: "auto",
    },
    {
        key: "uploadedVolume",
        header: "Uploaded Volume",
        width: "auto",
    },
    {
        key: "created",
        header: "Created",
        width: "auto",
    },
    {
        key: "downloadButton",
        width: "4rem",
    },
    {
        key: "detailsButton",
        width: "4rem",
    },
];

export default IngestsReportRoutes;
