import { MediaState } from "./mediaState";

export class SusHubMonitorData {
    public id;
    public state;
    public name;

    constructor({ id, state, name }: { id: number; state: MediaState; name: string }) {
        this.id = id;
        this.state = state;
        this.name = name;
    }

    private toObject() {
        return {
            id: this.id,
            state: this.state,
            name: this.name,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): SusHubMonitorData {
        if (json == null) {
            throw Error(
                `Error parsing SUS Moitor Data model for Self Upload Station Hub ${this.name}`
            );
        }

        return new SusHubMonitorData({
            id: json["id"],
            state: json["state"],
            name: json["name"],
        });
    }
}
