import React, { useContext } from "react";
import { GroupuiDivider, GroupuiLoadingSpinner, GroupuiTextarea } from "@group-ui/group-ui-react";
import GenericHeadline from "../generics/GenericHeadline";
import GenericInput from "../generics/GenericInput";
import { IngestStationContext } from "../context/provider/IngestStationContext";

const GeneralInformationTab = () => {
    const { isLoading, currentIngestStation } = useContext(IngestStationContext);

    return (
        <div>
            <GroupuiLoadingSpinner displayed={isLoading} />
            <div>
                <GenericHeadline
                    title={"General Information"}
                    titleHeadingType="h4"
                    actionButtons={[]}
                />
            </div>
            <GenericInput label={"Name"} value={currentIngestStation?.name} disabled={true} />
            <GenericInput
                label={"Type"}
                value={currentIngestStation?.ingestStationtype?.ingestStationType}
                disabled={true}
            />
            <GenericInput
                label={"Managed By"}
                value={currentIngestStation?.address?.company?.company}
                disabled={true}
            />
            <GenericInput
                label={"Delivery Provider"}
                value={currentIngestStation?.deliveryProvider ?? ""}
                disabled={true}
            />
            <GenericInput
                label={"Upload Speed"}
                value={currentIngestStation?.uploadSpeed ?? ""}
                disabled={true}
            />
            <GroupuiDivider />
            <div>
                <GenericHeadline
                    title={"Shipping Address"}
                    titleHeadingType="h4"
                    actionButtons={[]}
                />
            </div>
            <div style={{ display: "flex", gap: "var(--groupui-sys-spacing-1000)" }}>
                <div style={{ width: "50%" }}>
                    <GenericInput
                        label={"Full Name"}
                        value={currentIngestStation?.address?.fullName}
                        disabled={true}
                    />
                    <GenericInput
                        label={"Address Line 1"}
                        description={"Street Address, P.O. box, etc."}
                        value={currentIngestStation?.address?.street}
                        disabled={true}
                    />
                    <GenericInput
                        label={"Address Line 2"}
                        description={"Apartment, unit, building, floor, etc."}
                        value={currentIngestStation?.address?.addressSupplement}
                        disabled={true}
                    />
                    <GenericInput
                        label={"ZIP Code"}
                        value={currentIngestStation?.address?.zipCode}
                        disabled={true}
                    />
                    <GenericInput
                        label={"City"}
                        value={currentIngestStation?.address?.city}
                        disabled={true}
                    />
                </div>
                <div style={{ width: "50%" }}>
                    <GenericInput
                        label={"Country"}
                        value={currentIngestStation?.address?.country.country}
                        disabled={true}
                    />
                    <GenericInput
                        label={"State"}
                        value={currentIngestStation?.address?.state}
                        disabled={true}
                    />

                    <GenericInput
                        label={"Company"}
                        value={currentIngestStation?.address?.company?.company}
                        disabled={true}
                    />

                    <GenericInput
                        label={"Email"}
                        value={currentIngestStation?.address?.email}
                        disabled={true}
                    />

                    <GenericInput
                        label={"Phone"}
                        value={currentIngestStation?.address?.phone}
                        disabled={true}
                    />
                </div>
            </div>

            <GroupuiDivider />
            <div>
                <GenericHeadline
                    title={"Managed Countries"}
                    titleHeadingType="h4"
                    actionButtons={[]}
                />
            </div>

            <GroupuiTextarea
                placeholder="Placeholder"
                rows={4}
                disabled={true}
                value={currentIngestStation?.ingestStationCountries ?? ""}
            >
                <span slot="label">Countries</span>
            </GroupuiTextarea>
        </div>
    );
};

export default GeneralInformationTab;
