import { GroupuiButton, GroupuiTooltip } from "@group-ui/group-ui-react";
import React from "react";
import { Order } from "../../../models/order";
import { GenericTooltipButton } from "../../generics/GenericTooltipButton";
interface Props {
    order: Order | undefined;
    setDropDownState: React.Dispatch<React.SetStateAction<boolean>>;
    setShowPauseIngestJobModal: React.Dispatch<React.SetStateAction<boolean>>;
    setShowRestartIngestJobModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const PauseIngestJob: React.FC<Props> = ({
    order,
    setDropDownState,
    setShowPauseIngestJobModal,
    setShowRestartIngestJobModal,
}) => {
    return order?.ingestJob?.jobStatus === "On Hold" ? (
        <GroupuiButton
            variant="primary"
            icon="play-32"
            fullwidth={true}
            alignment="left"
            onClick={() => {
                setShowRestartIngestJobModal(true);
                setDropDownState(false);
            }}
        >
            Restart Ingest Job
        </GroupuiButton>
    ) : (
        <GenericTooltipButton
            buttonText={"Pause Ingest Job"}
            icon={"pause-32"}
            disabled={order?.ingestJob?.smartHandsTicket2Id != null}
            setDropDownState={setDropDownState}
            setModal={setShowPauseIngestJobModal}
            tooltipText={`Can only be paused if second Smart Hands Ticket is not created`}
        />
    );
};

export default PauseIngestJob;
