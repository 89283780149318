/**
 * Calculate a string representation of time passed since given timestamp.
 * @param timestamp Start time as string ex: 2022-11-09T12:26:19.000+00:00
 * @returns String representation of time passed since given timestamp with precision of two largest time units
 *  (days, hours, minutes, seconds) for the first non-zero time units value with seconds as smallest unit
 * Examples: Calculated time since start full: 26d 13h 54m 31s 472ms => returns 26days 13h
 * Examples: Calculated time since start full: 13h 54m 31s 472ms => returns 13h 54m
 * Examples: Calculated time since start full: 54m 31s 472ms => returns 54m 31s
 * Examples: Calculated time since start full: 31s 472ms => returns 31s
 */
export function timeSince(timestamp: string | undefined): string {
    if (timestamp === undefined) return "";

    const now = Date.now();
    const start = Date.parse(timestamp);

    const deltaMilliseconds = now - start;
    const milliseconds = deltaMilliseconds % 1000;
    const deltaSeconds = (deltaMilliseconds - milliseconds) / 1000;
    const seconds = deltaSeconds % 60;
    const deltaMinutes = (deltaSeconds - seconds) / 60;
    const minutes = deltaMinutes % 60;
    const deltaHours = (deltaMinutes - minutes) / 60;
    const hours = deltaHours % 24;
    const days = (deltaHours - hours) / 24;

    // console.log(
    //     `${deltaMilliseconds} => ${days}d ${hours}h ${minutes}m ${seconds}s ${milliseconds}ms`
    // );

    if (days > 0) return `${days} Days ${hours} h`;
    if (hours > 0) return `${hours} h ${minutes} m`;
    if (minutes > 0) return `${minutes} m ${seconds} s `;
    if (seconds > 0) return `${seconds} s`;
    return "";
}

/**
 * Calculate delta between two datetime strings
 * @param from Start time as string ex: 2022-11-09T12:26:19.000+00:00
 * @param to End time as string ex: 2022-11-09T12:26:19.000+00:00
 * @returns delta in milliseconds
 */
export function deltaInMilliseconds(from: string, to: string): number {
    return Date.parse(to) - Date.parse(from);
}

/**
 * Format date into string with the pattern "dd.MM.yyyy, HH:mm:ss"
 * @param date Date to format in string
 * @returns fomrated string
 */
export function parseFullDate(dateString: string | null | undefined): string {
    if (!dateString) {
        return "";
    }
    const date = new Date(dateString);
    return (
        date.getDate().toLocaleString("de-DE", {
            minimumIntegerDigits: 2,
        }) +
        "." +
        (date.getMonth() + 1).toLocaleString("de-DE", {
            minimumIntegerDigits: 2,
        }) +
        "." +
        date.getFullYear() +
        ", " +
        date.getHours().toLocaleString("de-DE", {
            minimumIntegerDigits: 2,
        }) +
        ":" +
        date.getMinutes().toLocaleString("de-DE", {
            minimumIntegerDigits: 2,
        }) +
        ":" +
        date.getSeconds().toLocaleString("de-DE", {
            minimumIntegerDigits: 2,
        })
    );
}
export function parseFullDateWithoutSeconds(dateString: string | null | undefined): string {
    if (!dateString) {
        return "";
    }
    const date = new Date(dateString);
    return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1).toLocaleString("de-DE", {
            minimumIntegerDigits: 2,
        }) +
        "-" +
        date.getDate().toLocaleString("de-DE", {
            minimumIntegerDigits: 2,
        }) +
        " " +
        date.getHours().toLocaleString("de-DE", {
            minimumIntegerDigits: 2,
        }) +
        ":" +
        date.getMinutes().toLocaleString("de-DE", {
            minimumIntegerDigits: 2,
        })
    );
}
/**
 * Format date into string with the pattern "dd.MM.yyyy, HH:mm:ss:ms"
 * @param date Date to format in string
 * @returns fomrated string
 */
export function parseFullDateWithMilliseconds(dateString: string | null | undefined): string {
    if (!dateString) {
        return "";
    }
    const date = new Date(dateString);
    return (
        date.getDate().toLocaleString("de-DE", {
            minimumIntegerDigits: 2,
        }) +
        "." +
        (date.getMonth() + 1).toLocaleString("de-DE", {
            minimumIntegerDigits: 2,
        }) +
        "." +
        date.getFullYear() +
        ", " +
        date.getHours().toLocaleString("de-DE", {
            minimumIntegerDigits: 2,
        }) +
        ":" +
        date.getMinutes().toLocaleString("de-DE", {
            minimumIntegerDigits: 2,
        }) +
        ":" +
        date.getSeconds().toLocaleString("de-DE", {
            minimumIntegerDigits: 2,
        }) +
        ":" +
        date.getMilliseconds().toLocaleString("de-DE", {
            minimumIntegerDigits: 2,
        })
    );
}
/**
 * Format date string into Date. String needs to be in pattern "dd.MM.yyyy, HH:mm:ss"
 * @param date string to format in Date
 * @returns Date
 */
export function parseFullDateStringToDate(date: string): Date | null {
    if (!date) return null;
    const [datePart, timePart] = date.split(" ");

    const dateParts = datePart.split("-");

    const timeParts = timePart.split(":");

    return new Date(
        +dateParts[0],
        Number(dateParts[1]) - 1,
        +dateParts[2],
        Number(timeParts[0]),
        Number(timeParts[1])
    );
}

/**
 * Format date into string with the pattern "dd.MM.yyyy"
 * @param date Date to format in string
 * @returns fomrated string
 */
export function parseDate(date: Date): string {
    return (
        date.getDate().toLocaleString("de-DE", {
            minimumIntegerDigits: 2,
        }) +
        "." +
        (date.getMonth() + 1).toLocaleString("de-DE", {
            minimumIntegerDigits: 2,
        }) +
        "." +
        date.getFullYear()
    );
}

export function parseDateBasic(date: Date): string {
    return (
        date.getFullYear() +
        (date.getMonth() + 1).toLocaleString("de-DE", {
            minimumIntegerDigits: 2,
        }) +
        date.getDate().toLocaleString("de-DE", {
            minimumIntegerDigits: 2,
        })
    );
}

export function parseDateForDatepicker(time: string | undefined): string | undefined {
    if (time !== undefined && time !== null) {
        const date = new Date(time);
        return (
            date.getFullYear() +
            "-" +
            (date.getMonth() + 1).toLocaleString("de-DE", {
                minimumIntegerDigits: 2,
            }) +
            "-" +
            date.getDate()
        );
    }
    return undefined;
}

export function parseDateForDatetimeInputField(time: string | undefined): string | undefined {
    if (time !== undefined && time !== null) {
        const date = new Date(time);
        return (
            date.getFullYear() +
            "-" +
            (date.getMonth() + 1).toLocaleString("de-DE", {
                minimumIntegerDigits: 2,
            }) +
            "-" +
            date.getDate().toLocaleString("de-DE", {
                minimumIntegerDigits: 2,
            }) +
            "T" +
            date.getHours().toLocaleString("de-DE", {
                minimumIntegerDigits: 2,
            }) +
            ":" +
            date.getMinutes().toLocaleString("de-DE", {
                minimumIntegerDigits: 2,
            })
        );
    }
    return undefined;
}

export function getTimeFromMilliseconds(deltaMilliseconds: number): string {
    const milliseconds = deltaMilliseconds % 1000;
    const deltaSeconds = (deltaMilliseconds - milliseconds) / 1000;
    const seconds = deltaSeconds % 60;
    const deltaMinutes = (deltaSeconds - seconds) / 60;
    const minutes = deltaMinutes % 60;
    const hours = (deltaMinutes - minutes) / 60;

    return `${hours.toLocaleString("de-DE", {
        minimumIntegerDigits: 2,
    })}:${minutes.toLocaleString("de-DE", {
        minimumIntegerDigits: 2,
    })} h`;
}

export function getTimeFromMillisecondsWithSeconds(deltaMilliseconds: number): string {
    const milliseconds = deltaMilliseconds % 1000;
    const deltaSeconds = (deltaMilliseconds - milliseconds) / 1000;
    const seconds = deltaSeconds % 60;
    const deltaMinutes = (deltaSeconds - seconds) / 60;
    const minutes = deltaMinutes % 60;
    const hours = (deltaMinutes - minutes) / 60;

    // TODO: optimize
    if ((hours == 0 && minutes == 0 && seconds == 0) || hours < 0 || minutes < 0 || seconds < 0) {
        return "";
    }

    return `${hours.toLocaleString("de-DE", {
        minimumIntegerDigits: 2,
    })}:${minutes.toLocaleString("de-DE", {
        minimumIntegerDigits: 2,
    })}:${seconds.toLocaleString("de-DE", {
        minimumIntegerDigits: 2,
    })} h`;
}

export function getHoursAndMinutesFromMilliseconds(deltaMilliseconds: number): string {
    const deltaMinutes = Math.floor(deltaMilliseconds / (60 * 1000));
    const minutes = deltaMinutes % 60;
    const hours = Math.floor(deltaMinutes / 60);

    // TODO: optimize
    if ((hours == 0 && minutes == 0) || hours < 0 || minutes < 0) {
        return "";
    }

    return `${hours.toLocaleString("de-DE", {
        minimumIntegerDigits: 2,
    })}:${minutes.toLocaleString("de-DE", {
        minimumIntegerDigits: 2,
    })}`;
}

export function parseFullDateFilename(date: Date): string {
    return (
        date.getDate().toLocaleString("de-DE", {
            minimumIntegerDigits: 2,
        }) +
        (date.getMonth() + 1).toLocaleString("de-DE", {
            minimumIntegerDigits: 2,
        }) +
        date.getFullYear() +
        "_" +
        date.getHours().toLocaleString("de-DE", {
            minimumIntegerDigits: 2,
        }) +
        date.getMinutes().toLocaleString("de-DE", {
            minimumIntegerDigits: 2,
        }) +
        date.getSeconds().toLocaleString("de-DE", {
            minimumIntegerDigits: 2,
        })
    );
}
export function parseDateOnly(time: string | null | undefined): string {
    if (time) {
        const date = new Date(time);
        return (
            date.getFullYear() +
            "-" +
            (date.getMonth() + 1).toLocaleString("de-DE", {
                minimumIntegerDigits: 2,
            }) +
            "-" +
            date.getDate().toLocaleString("de-DE", {
                minimumIntegerDigits: 2,
            })
        );
    }
    return "";
}

export function getDaysAndHoursFromMilliseconds(duration: number | null | undefined) {
    if (duration !== null && duration !== undefined) {
        const days = Math.floor(duration / (24 * 60 * 60 * 1000));
        const hours = Math.floor((duration % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
        if (days >= 0 && hours >= 0) {
            return `${days}.${hours} `;
        }
    }
    return "";
}
