import React, { Ref, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import UserGroupContext from "../context/UserGroupContext";
import { ApiService } from "../../services/apiService";
import ErrorContext from "../context/ErrorContext";
import { handleError } from "../context/ErrorContextUtils";
import { InCarMount } from "../../models/incarmount";
import GeneralInformationReadOnly from "./GeneralInformationReadOnly";
import { GroupuiLoadingSpinner } from "@group-ui/group-ui-react";
import GeneralInformationEdit, { RefType } from "./GeneralInformationEdit";
import ProjectHolderEditPage from "./ProjectHolderEditPage";

interface Props {
    editMode: boolean;
    editGeneralInfo: boolean;
    refEditProject: Ref<RefType>;
}

const GeneralInformationTab: React.FC<Props> = ({ editMode, editGeneralInfo, refEditProject }) => {
    const { inCarMountID } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [inCarMountsData, setInCarMountsData] = useState<InCarMount>();
    const { userGroups } = useContext(UserGroupContext);
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);

    useEffect(() => {
        ApiService.getInCarMountById(Number(inCarMountID))
            .then((response) => {
                setInCarMountsData(response);
                setIsLoading(false);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
                setIsLoading(false);
            });
    }, [inCarMountID]);

    return (
        <div>
            <GroupuiLoadingSpinner displayed={isLoading} />
            {inCarMountsData && editGeneralInfo ? (
                <GeneralInformationEdit
                    inCarMountData={inCarMountsData}
                    ref={refEditProject}
                    userGroups={userGroups}
                />
            ) : inCarMountsData && editMode ? (
                <ProjectHolderEditPage inCarMountData={inCarMountsData} ref={refEditProject} />
            ) : (
                inCarMountsData && (
                    <GeneralInformationReadOnly
                        inCarMountsData={inCarMountsData}
                        userGroups={userGroups}
                    />
                )
            )}
        </div>
    );
};

export default GeneralInformationTab;
