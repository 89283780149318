import React, {
    Ref,
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";
import { InCarMount } from "../../models/incarmount";
import {
    GroupuiLoadingSpinner,
    GroupuiButton,
    GroupuiSelect,
    GroupuiSelectOption,
} from "@group-ui/group-ui-react";
import GenericHeadline from "../generics/GenericHeadline";
import Snackbar from "../generics/Snackbar";
import GenericInput from "../generics/GenericInput";
import { ApiService } from "../../services/apiService";
import { handleError } from "../context/ErrorContextUtils";
import ErrorContext from "../context/ErrorContext";
import { Project } from "../../models/project";
interface Props {
    inCarMountData: InCarMount;
}

export interface RefType {
    sendChanges: () => void;
}

function ProjectHolderEditPage({ inCarMountData }: Props, ref: Ref<RefType>) {
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
    const [severity, setSeverity] = useState<"success" | "danger">("success");
    const [message, setMessage] = useState<string>("");

    const [isLoading, setIsLoading] = useState(false);

    useImperativeHandle(ref, () => ({ sendChanges }));

    const [project, setProject] = useState<string>(inCarMountData.project?.name ?? "");

    const [projects, setProjects] = useState<Project[]>();

    const [currentHolder, setCurrentHolder] = useState<string>(inCarMountData.currentHolder);

    useEffect(() => {
        setIsLoading(true);
        ApiService.getAllProjects()
            .then((response) => {
                setProjects(response ?? []);
                setIsLoading(false);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
            });
    }, []);

    const sendChanges = () => {
        setIsLoading(true);

        const updateInCarMount = new InCarMount(inCarMountData);

        const currentProject = projects?.find((element) => element.name === project) ?? null;

        if (currentProject == null || currentHolder == "") {
            setShowSnackbar(true);
            setMessage("You have to set a Holder and a Project!");
            setSeverity("danger");

            setShowSnackbar(true);
            setIsLoading(false);
            return;
        }

        updateInCarMount.currentHolder = currentHolder;
        updateInCarMount.project = currentProject;

        if (updateInCarMount) {
            ApiService.updateInCarMount(updateInCarMount)
                .then((response) => {
                    if (response === "success") {
                        setMessage(`In Car Mount updated successfully`);
                        setSeverity("success");
                        setShowSnackbar(true);
                        setTimeout(() => {
                            setIsLoading(false);
                            window.location.reload();
                        }, 2000);
                    } else {
                        setMessage("Failed to update In Car Mount");
                        setSeverity("danger");
                        setShowSnackbar(true);
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    handleError(error, changeErrorMessage, changeShowError);
                });
        }
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "var(--groupui-sys-spacing-400)",
            }}
        >
            <GenericHeadline
                title={"General Information"}
                titleHeadingType="h4"
                actionButtons={[]}
            />
            {projects && (
                <GroupuiSelect
                    value={project}
                    onGroupuiChange={(event) => setProject(event.target.value)}
                    maxHeight="20rem"
                >
                    {projects?.map((element, index) => {
                        return (
                            <GroupuiSelectOption
                                value={element.name}
                                key={`select-option-${index}`}
                            >
                                {element.name}
                            </GroupuiSelectOption>
                        );
                    })}
                    <span slot="label">Internal Project*</span>
                </GroupuiSelect>
            )}
            <GenericInput
                label={"Current Holder"}
                value={currentHolder ?? ""}
                onGroupuiChange={(event: any) => setCurrentHolder(event.target.value)}
                maxLength={50}
            />
            <GroupuiLoadingSpinner displayed={isLoading} />
            {showSnackbar && (
                <Snackbar
                    severity={severity}
                    message={message}
                    onClose={() => setShowSnackbar(false)}
                />
            )}
        </div>
    );
}

export default forwardRef(ProjectHolderEditPage);
