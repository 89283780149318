import React, { createContext, useContext, useEffect, useState } from "react";
import { IngestsReport } from "../../../models/reports/ingestsReport";
import { useLocation } from "react-router-dom";
import { ApiService } from "../../../services/apiService";
import { handleError } from "../ErrorContextUtils";
import ErrorContext from "../ErrorContext";

interface IIngestsReportContext {
    isLoading: boolean;
    currentIngestsReportCalendarWeek?: string;
    currentIngestsReports?: IngestsReport[];
    ingestsReports?: IngestsReport[];
}

const defaultState = {
    isLoading: false,
    currentIngestsReportCalendarWeek: undefined,
    currentIngestsReports: undefined,
    ingestsReports: undefined,
};

export const IngestsReportContext = createContext<IIngestsReportContext>(defaultState);

interface IngestsReportProviderProps {
    children?: React.ReactNode;
}

export const IngestsReportProvider: React.FC<IngestsReportProviderProps> = ({ children }) => {
    const location = useLocation();
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);

    const [isLoading, setIsLoading] = useState(true);
    const [ingestsReports, setIngestsReports] = useState<IngestsReport[]>();
    const [currentIngestsReports, setCurrentIngestsReports] = useState<IngestsReport[]>();

    const urlPathParameter = location.pathname.split("/");
    const currentIngestsReportCalendarWeek = urlPathParameter[urlPathParameter.length - 1];

    useEffect(() => {
        getData();
    }, [location.pathname]);

    const getData = async () => {
        setIsLoading(true);
        await Promise.all([
            ApiService.getAllIngestsReports()
                .then((ingestsReports: IngestsReport[] | null) => {
                    if (ingestsReports == null) {
                        setIngestsReports(undefined);
                        return undefined;
                    }
                    setIngestsReports(ingestsReports);
                })
                .catch((error) => {
                    handleError(error, changeErrorMessage, changeShowError);
                }),
            // Only load if current Ingests Report calendar week matches the pattern
            new RegExp(`^(0?[1-9]|[1-4][0-9]|5[0-3])_(\\d{4})$`).test(
                currentIngestsReportCalendarWeek
            )
                ? ApiService.getIngestsReportByCalenderWeek(currentIngestsReportCalendarWeek)
                      .then((ingestsReports: IngestsReport[] | null) => {
                          if (ingestsReports == null) {
                              setCurrentIngestsReports(undefined);
                              return undefined;
                          }
                          setCurrentIngestsReports(ingestsReports);
                      })
                      .catch((error) => {
                          handleError(error, changeErrorMessage, changeShowError);
                      })
                : undefined,
        ]).then(() => setIsLoading(false));
    };

    return (
        <IngestsReportContext.Provider
            value={{
                isLoading,
                currentIngestsReportCalendarWeek,
                currentIngestsReports,
                ingestsReports,
            }}
        >
            {children}
        </IngestsReportContext.Provider>
    );
};
