import React, { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import MyDataboxesDetailsWrapper from "../myDataboxes/MyDataboxesDetailsWrapper";
import GenericDataboxOrderOverview, {
    DataboxOrderOverview,
} from "../generics/pages/GenericDataboxOrderOverview";
import GenericDataboxDetails from "../generics/pages/GenericDataboxOrderDetails";
import { RightsWrapper } from "../container/RightsWrapper";
import UserGroupContext from "../context/UserGroupContext";
import { RightsManagement } from "../../config/rightsManagement";
import { columnDefinition } from "../generics/GenericTable";
import { OrderProvider } from "../context/provider/OrderContext";

/**
 * Returns an MyDataboxesRoutes Component, which defines the Nested Routes nested under the parent
 *
 * @returns MyDataboxesRoutes Component
 *
 */
const MyDataboxesRoutes = () => {
    const { userGroups } = useContext(UserGroupContext);

    return (
        <RightsWrapper
            key={"first"}
            authorizedUserGroup={RightsManagement.MYDATABOXES_ROUTES}
            userGroups={userGroups}
            section={true}
        >
            <OrderProvider userSpecificContext={true}>
                <Routes>
                    <Route
                        index
                        element={
                            <GenericDataboxOrderOverview
                                columns={columns}
                                type={DataboxOrderOverview.MY_DATABOXES}
                            />
                        }
                    />

                    <Route
                        path="return-other-databox/:orderID/:tab*"
                        element={<MyDataboxesDetailsWrapper />}
                    >
                        <Route
                            index
                            element={
                                <GenericDataboxDetails type={DataboxOrderOverview.MY_DATABOXES} />
                            }
                        />
                        {/* Redirect the user if the Route doesn´t exist */}
                        <Route path="*" element={<Navigate to="/my-databoxes" />} />
                    </Route>

                    <Route path=":orderID/:tab*" element={<MyDataboxesDetailsWrapper />}>
                        <Route
                            index
                            element={
                                <GenericDataboxDetails type={DataboxOrderOverview.MY_DATABOXES} />
                            }
                        />
                        {/* Redirect the user if the Route doesn´t exist */}
                        <Route path="*" element={<Navigate to="/my-databoxes" />} />
                    </Route>
                    <Route path="*" element={<Navigate to="/my-databoxes" />} />
                </Routes>
            </OrderProvider>
        </RightsWrapper>
    );
};

const columns: columnDefinition[] = [
    {
        key: "orderId",
        header: "Order ID",
        width: "auto",
    },
    {
        key: "databoxName",
        header: "Databox Name",
        width: "auto",
    },
    {
        key: "ingestLocation",
        header: "Ingest Location",
        width: "auto",
    },
    {
        key: "destinationCountry",
        header: "Destination",
        width: "auto",
    },
    {
        key: "currentState",
        header: "Current State",
        width: "auto",
    },
    {
        key: "accessPoint",
        header: "Access Point",
        width: "auto",
    },
    {
        key: "created",
        header: "Created",
        width: "auto",
    },
    {
        key: "requestedPickupDate",
        header: "Requested Pickup Date",
        width: "auto",
    },
    {
        key: "pickedUpDroppedOff",
        header: "Picked Up / Dropped Off",
        width: "auto",
    },
    {
        key: "button",
        width: "4rem",
    },
];

export default MyDataboxesRoutes;
