import React from "react";
import { GroupuiModal } from "@group-ui/group-ui-react";
import GenericHeadline from "../../generics/GenericHeadline";

interface Props {
    modal: boolean;
    setModal: () => void;
    onModalClose: () => void;
    lon: number;
    lat: number;
}

const DataboxLocationModal: React.FC<Props> = ({ modal, setModal, onModalClose, lon, lat }) => {
    const databoxLocationURL = `https://maps.google.com/maps?q=${lat},${lon}&hl=en&z=14&amp;&output=embed`;

    return (
        <GroupuiModal
            displayed={modal}
            closeButton={true}
            onGroupuiModalClose={onModalClose}
            style={{
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div style={{ textAlign: "center" }}>
                <GenericHeadline
                    title="Databox Location"
                    titleHeadingType="h4"
                    actionButtons={[]}
                    type="modal"
                />
                {lon && lat ? (
                    <iframe
                        title="Databox location"
                        width="600"
                        height="450"
                        frameBorder="0"
                        scrolling="no"
                        marginHeight={0}
                        marginWidth={0}
                        src={databoxLocationURL}
                    />
                ) : (
                    <>There is an error regarding the location of the databox</>
                )}
            </div>
        </GroupuiModal>
    );
};

export default DataboxLocationModal;
