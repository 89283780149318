import { MaintenanceSchedule } from "./maintenanceSchedule";

export class MaintenanceScheduleResponse {
    public error;
    public maintenanceSchedule;

    constructor({
        error,
        maintenanceSchedule,
    }: {
        error: string;
        maintenanceSchedule: MaintenanceSchedule | null;
    }) {
        this.error = error;
        this.maintenanceSchedule = maintenanceSchedule;
    }

    private toObject() {
        return {
            error: this.error,
            maintenanceSchedule: this.maintenanceSchedule,
        };
    }

    static fromSerialized(json: object): MaintenanceScheduleResponse | null {
        if (json == null) {
            return null;
        }

        return new MaintenanceScheduleResponse({
            error: json["error"],
            maintenanceSchedule: MaintenanceSchedule.fromSerialized(json["maintenanceSchedule"]),
        });
    }
}
