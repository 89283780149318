export class DataboxBooking {
    public databoxId;
    public created;
    public started;
    public finished;
    public mediaStateId;
    public reservedBy;
    public comment;
    public ticketId;

    constructor({
        databoxId,
        created,
        started,
        finished,
        mediaStateId,
        reservedBy,
        comment,
        ticketId,
    }: {
        databoxId: string | undefined;
        created: string;
        started: string | undefined;
        finished: string | undefined;
        mediaStateId: string | null | undefined;
        reservedBy: string | undefined;
        comment: string | null | undefined;
        ticketId: string | null | undefined;
    }) {
        this.databoxId = databoxId;
        this.created = created;
        this.started = started;
        this.finished = finished;
        this.mediaStateId = mediaStateId;
        this.reservedBy = reservedBy;
        this.comment = comment;
        this.ticketId = ticketId;
    }

    private toObject() {
        return {
            databoxId: this.databoxId,
            created: this.created,
            started: this.started,
            finished: this.finished,
            mediaStateId: this.mediaStateId,
            reservedBy: this.reservedBy,
            comment: this.comment,
            ticketId: this.ticketId,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): DataboxBooking | null {
        if (json == null) {
            return null;
        }

        return new DataboxBooking({
            databoxId: json["databoxId"],
            created: json["created"],
            started: json["started"],
            finished: json["finished"],
            mediaStateId: json["mediaStateId"],
            reservedBy: json["reservedBy"],
            comment: json["comment"],
            ticketId: json["ticketId"],
        });
    }
}
