import React, { createContext, useContext, useEffect, useState } from "react";
import { IngestStation } from "../../../models/ingestStation";
import { ApiService } from "../../../services/apiService";
import { useLocation, useNavigate } from "react-router-dom";
import { IngestSlot } from "../../../models/ingestSlot/ingestSlot";
import { handleError } from "../ErrorContextUtils";
import ErrorContext from "../ErrorContext";

interface IIngestStationContext {
    isLoading: boolean;
    loadData: () => Promise<void>;
    ingestStations?: IngestStation[];
    currentIngestStation?: IngestStation;
    currentIngestSlot?: IngestSlot;
}

const defaultState = {
    isLoading: false,
    loadData: async () => {
        await Promise<void>;
    },
    currentIngestStation: undefined,
    currentIngestSlot: undefined,
    ingestStations: undefined,
};

export const IngestStationContext = createContext<IIngestStationContext>(defaultState);

interface IngestStationProviderProps {
    children?: React.ReactNode;
}

export const IngestStationProvider: React.FC<IngestStationProviderProps> = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);

    const [isLoading, setIsLoading] = useState(true);
    const [ingestStations, setIngestStations] = useState<IngestStation[]>();
    const [currentIngestStation, setCurrentIngestStation] = useState<IngestStation>();

    const urlPathParameter = location.pathname.split("/");
    const currentIngestStationId = Number(urlPathParameter[2]);
    const currentIngestSlotId = Number(urlPathParameter[3]);

    useEffect(() => {
        loadData();
    }, [location.pathname]);

    const loadData = async () => {
        setIsLoading(true);
        await Promise.all([
            ApiService.getAllIngestStations()
                .then((ingestStations: IngestStation[]) => setIngestStations(ingestStations))
                .catch((error) => handleError(error, changeErrorMessage, changeShowError)),
            // Only load when currentIngestStation Id is set
            currentIngestStationId
                ? ApiService.getIngestStationById(currentIngestStationId)
                      .then((ingestStation: IngestStation | null) => {
                          if (ingestStation == null) {
                              setCurrentIngestStation(undefined);
                              return undefined;
                          }
                          setCurrentIngestStation(ingestStation);
                      })
                      .catch((error) => {
                          navigate("/not-found");
                          handleError(error, changeErrorMessage, changeShowError);
                      })
                : undefined,
        ]).then(() => setIsLoading(false));
    };

    const currentIngestSlot = currentIngestStation?.ingestSlots?.find(
        (ingestSlot) => ingestSlot.id == currentIngestSlotId
    );

    return (
        <IngestStationContext.Provider
            value={{
                isLoading,
                loadData,
                currentIngestStation,
                currentIngestSlot,
                ingestStations,
            }}
        >
            {children}
        </IngestStationContext.Provider>
    );
};
