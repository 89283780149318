import { MediaState } from "../mediaState";
import { IngestSlotDetails } from "./ingestSlotDetails";

/**
 * A model class to discribe an ingest slot of an ingest station, which is used to map the received json on an usable object
 */
export class IngestSlot {
    private _id;
    public serialNumber;
    public ingestStationId;
    public name;
    public mediaState;
    public portTypes;
    public ingestSlotDetails;
    public started;
    public finished;
    public softDeleted;

    constructor({
        id,
        serialNumber,
        ingestStationId,
        name,
        mediaState,
        portTypes,
        ingestSlotDetails,
        started,
        finished,
        softDeleted,
    }: {
        id: number;
        serialNumber: string;
        ingestStationId: number;
        name: string;
        mediaState: MediaState;
        portTypes: string;
        ingestSlotDetails: IngestSlotDetails | null;
        started: string;
        finished: string;
        softDeleted: boolean;
    }) {
        this._id = id;
        this.serialNumber = serialNumber;
        this.ingestStationId = ingestStationId;
        this.name = name;
        this.mediaState = mediaState;
        this.portTypes = portTypes;
        this.ingestSlotDetails = ingestSlotDetails;
        this.started = started;
        this.finished = finished;
        this.softDeleted = softDeleted;
    }

    public get id() {
        return this._id;
    }

    private toObject() {
        return {
            id: this._id,
            serialNumber: this.serialNumber,
            ingestStationId: this.ingestStationId,
            name: this.name,
            mediaState: this.mediaState,
            portTypes: this.portTypes,
            ingestSlotDetails: this.ingestSlotDetails,
            started: this.started,
            finished: this.finished,
            softDeleted: this.softDeleted,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): IngestSlot | null {
        if (json == null) {
            return null;
        }

        return new IngestSlot({
            id: json["id"],
            serialNumber: json["serialNumber"],
            ingestStationId: json["ingestStationId"],
            name: json["name"],
            mediaState: MediaState.fromSerialized(json["mediaStateType"]),
            portTypes: json["ingestSlotPortTypes"],
            ingestSlotDetails: IngestSlotDetails.fromSerialized(json["ingestSlotDetails"]),
            started: json["started"],
            finished: json["finished"],
            softDeleted: json["softDeleted"],
        });
    }
}
