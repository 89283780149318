import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
    GroupuiCard,
    GroupuiHeadline,
    GroupuiIcon,
    GroupuiInput,
    GroupuiText,
} from "@group-ui/group-ui-react";

export enum DataboxType {
    SMALL = 60,
    MIDDLE = 90,
    BIG = 120,
}

interface DataboxCardProps {
    databoxType: DataboxType;
    databoxMaxCount?: number;
    countrySelected: boolean;
    specificDataboxSelected: boolean;
    databoxCount: number;
    setDataboxCount: Dispatch<SetStateAction<number>>;
    changeIsValid: Dispatch<SetStateAction<boolean>>;
}

const DataboxCard: React.FC<DataboxCardProps> = ({
    databoxType,
    databoxMaxCount = 0,
    countrySelected,
    specificDataboxSelected,
    databoxCount = 0,
    setDataboxCount,
    changeIsValid,
}) => {
    const [dynamicNumberOfDataboxes, setDynamicNumberOfDataboxes] =
        useState<number>(databoxMaxCount);
    const [isValid, setIsValid] = useState<boolean>(true);

    useEffect(() => {
        setDynamicNumberOfDataboxes(databoxMaxCount);
    }, [databoxMaxCount]);

    useEffect(() => {
        if (databoxCount < 0) {
            setIsValid(false);
            return;
        } else {
            setIsValid(true);
        }
    }, [databoxCount, dynamicNumberOfDataboxes]);

    useEffect(() => {
        changeIsValid(isValid);
    }, [isValid]);

    const styles = {
        card: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        iconContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
        } as React.CSSProperties,
        icon:
            countrySelected && dynamicNumberOfDataboxes > 0
                ? { color: "var(--groupui-sys-color-action-basic-default)" }
                : { color: "var(--groupui-sys-color-action-basic-disabled)" },
        labelContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "300px",
        },
        label:
            countrySelected && dynamicNumberOfDataboxes > 0
                ? { color: "var(--groupui-sys-color-action-basic-default)" }
                : { color: "var(--groupui-sys-color-action-basic-disabled)" },
        contenContainer: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "var(--groupui-sys-spacing-4var(--groupui-sys-spacing-300)00)",
            justifyContent: "center",
            alignItems: "center",
        } as React.CSSProperties,
        inputField: { display: "flex", width: "5rem", justifyContent: "center" },
        descriptionText: { display: "flex", width: "100%", justifyContent: "center" },
    };

    return (
        <GroupuiCard
            elevation="0"
            background={
                countrySelected && !specificDataboxSelected && dynamicNumberOfDataboxes > 0
                    ? "#FFFFFF"
                    : "#DFE2E6"
            }
            style={styles.card}
            padding={"40px"}
            interactive={false}
        >
            <div style={styles.iconContainer}>
                <GroupuiIcon name={"save-24"} style={styles.icon} height={100} width={100} />
                <br />
                <div style={styles.labelContainer}>
                    <GroupuiHeadline heading={"h3"} style={styles.label}>
                        {databoxType} TB Databox
                    </GroupuiHeadline>
                </div>
                <br />
                <div style={styles.contenContainer}>
                    <GroupuiInput
                        value={String(databoxCount)}
                        placeholder={"0"}
                        type={"number"}
                        maxlength={16}
                        severity={isValid ? "none" : "danger"}
                        disabled={
                            !countrySelected || specificDataboxSelected || databoxMaxCount < 1
                        }
                        required={true}
                        min={0}
                        max={databoxMaxCount}
                        style={styles.inputField}
                        onGroupuiChange={(event) => {
                            // setCurrentCount(event.target.value);
                            setDataboxCount(Number(event.target.value));
                            setDynamicNumberOfDataboxes(
                                databoxMaxCount - Number(event.target.value)
                            );
                        }}
                        pattern={"[0-9]"}
                        onGroupuiInvalid={() => setIsValid(false)}
                    >
                        <span slot="description" />
                        {!isValid && <span slot="description">Invalid Input</span>}
                    </GroupuiInput>
                    {isValid && countrySelected && (
                        <GroupuiText
                            size={"body-2"}
                            weight={"regular"}
                            style={styles.descriptionText}
                        >
                            {countrySelected && dynamicNumberOfDataboxes > 0
                                ? `${dynamicNumberOfDataboxes} in Stock`
                                : dynamicNumberOfDataboxes < 0 || databoxMaxCount > 0
                                ? `Maximum of free Databoxes (${databoxMaxCount}) selected`
                                : "Currently unavailable"}
                        </GroupuiText>
                    )}
                </div>
            </div>
        </GroupuiCard>
    );
};

export default DataboxCard;
