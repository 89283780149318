// TODO catch null values
// TODO recheck login is working fine

import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_OAUTH_CLIENT_ID ?? "",
        authority: process.env.REACT_APP_OAUTH_AUTHORITY ?? "",
        redirectUri: process.env.REACT_APP_OAUTH_REDIRECT_URI ?? "/",
        postLogoutRedirectUri: process.env.REACT_APP_OAUTH_LOGOUT_REDIRECT_URI ?? "/lrportal",
    },
};

export const loginRequest = {
    scopes: ["User.Read", "User.ReadBasic.All"],
};

export const apiConfig = {
    backendURL:
        window.location.protocol +
        "//" +
        window.location.hostname +
        process.env.REACT_APP_BACKEND_PORT,
};

export const msalInstance = new PublicClientApplication(msalConfig);
