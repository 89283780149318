import React, { useState } from "react";
import Lottie from "lottie-react";
import animationData from "../../lotties/page-not-found.json";
import { GroupuiDivider, GroupuiLink } from "@group-ui/group-ui-react";
import { Order } from "../../models/order";
import GenericHeadline from "../generics/GenericHeadline";
import DropOffDetails from "./DropOffDetailsGeneralInformation";
import PickUpDetailsGeneralInformation from "./PickUpDetailsGeneralInformation";
import AccessPointAddress from "./AccessPointAddress";
import { GenericAddressInformationFields } from "../generics/GenericAddressInformationFields";
import { Address } from "../../models/address";
import PickupAddress from "./PickupAddress";

const styles = {
    content: {
        display: "flex",
        flexDirection: "column" as const,
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 30rem)",
    },
    icon: {
        cursor: "pointer",
    },
};

interface PickupDetailsProps {
    setPickUpModal: (value: boolean) => void;
    setDropOffModal: (value: boolean) => void;
    order: Order | undefined;
}

const PickupDetails: React.FC<PickupDetailsProps> = ({
    setPickUpModal,
    setDropOffModal,
    order,
}) => {
    const [pickupAddress, setPickupAddress] = useState<Address | undefined>(undefined);
    const [disabled, setDisabled] = useState<boolean>(true);

    return (
        <div>
            {!order?.upsPickup &&
                !order?.packageDropOff &&
                (order?.completed || order?.currentMediaState != "In Use" ? (
                    <div style={styles.content}>
                        <Lottie animationData={animationData} style={{ height: "40vh" }} />
                        <h2>No pickup requested</h2>
                    </div>
                ) : (
                    <div style={styles.content}>
                        <Lottie animationData={animationData} style={{ height: "40vh" }} />
                        <h2>No pickup requested yet</h2>
                        <GroupuiLink quiet={true} onClick={() => setPickUpModal(true)}>
                            Request a pickup now
                        </GroupuiLink>
                        to
                        <br />
                        <GroupuiLink quiet={true} onClick={() => setDropOffModal(true)}>
                            confirm that you have dropped off the package
                        </GroupuiLink>
                    </div>
                ))}
            {order?.packageDropOff && (
                <div>
                    <DropOffDetails order={order} />
                    <GroupuiDivider />
                    <br />
                    <AccessPointAddress order={order} />
                </div>
            )}
            {order?.upsPickup && (
                <div>
                    <PickUpDetailsGeneralInformation order={order} />
                    <GroupuiDivider />
                    <br />
                    <PickupAddress order={order} />
                </div>
            )}
        </div>
    );
};

export default PickupDetails;
