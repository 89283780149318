import React, { useEffect } from "react";
import {
    Chart,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    BarController,
    LineElement,
    PointElement,
    LineController,
} from "chart.js";
import { formatBytes, formatSpeed, getUploadSpeed } from "../../../utils/DataVolumeUtil";
import { GroupuiCard } from "@group-ui/group-ui-react";

// Register required components for the chart
Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    LineController,
    Title,
    Tooltip,
    Legend,
    BarController
);

type IngestsStatisticsData = {
    month: string;
    ingestProceeded: number;
    volumeUploaded: number;
    location: string;
    fromDate: string;
    toDate: string;
    totalIngestTimeInSeconds: number;
};

interface IngestsStatisticsGraphViewProps {
    graphType: string;
    statisticsData: IngestsStatisticsData[];
}

const styles = {
    container: {
        marginBottom: "1.5rem",
    },
};

const IngestsStatisticsGraphView: React.FC<IngestsStatisticsGraphViewProps> = ({
    graphType,
    statisticsData,
}) => {
    const root = document.documentElement;

    useEffect(() => {
        const ctx = document.getElementById("graphCanvas") as HTMLCanvasElement;
        const chartInstance = Chart.getChart(ctx);
        if (chartInstance) {
            chartInstance.destroy(); // Destroy the existing chart instance
        }

        if (graphType === "ingests-proceeded") {
            renderChart("ingestProceeded", "Ingest Proceeded");
        } else if (graphType === "volume-uploaded") {
            renderChart("volumeUploaded", "Volume Uploaded");
        } else if (graphType === "average-bandwidth") {
            renderBandwidthChart("Average Bandwidth");
        }
    }, [graphType, statisticsData]);

    // Sort the data by month to ensure it's in the correct order
    const sortedData = statisticsData.sort((a, b) => (a.month > b.month ? 1 : -1));

    const getUniqueMonths = (data: IngestsStatisticsData[]) => {
        const months = data
            .map((entry) => {
                if (!entry.month) {
                    return null; // Safeguard against undefined 'month'
                }
                const [year, month] = entry.month.split("-");
                const date = new Date(Number(year), Number(month) - 1);

                return date.toLocaleString("default", {
                    month: "short",
                    year: "numeric",
                });
            })
            .filter((month) => month !== null);

        return Array.from(new Set(months));
    };

    const getMaxValueForY = (data: IngestsStatisticsData[], metric: string) => {
        return Math.max(
            ...data.map((entry) => Number(entry[metric as keyof IngestsStatisticsData]))
        );
    };

    const getDatasetsForLocations = (data: IngestsStatisticsData[], metric: string) => {
        const months = getUniqueMonths(data);
        const locations = Array.from(new Set(data.map((entry) => entry.location)));

        return locations.map((location) => {
            const dataForLocation = data.filter((entry) => entry.location === location);

            const dataForMonths = months.map((month) => {
                const entryForMonth = dataForLocation.find((entry) => {
                    const [year, monthNum] = entry.month.split("-");
                    const date = new Date(Number(year), Number(monthNum) - 1);
                    const formattedMonth = date.toLocaleString("default", {
                        month: "short",
                        year: "numeric",
                    });
                    return formattedMonth === month;
                });

                // Return the data value for the metric if it exists, otherwise 0
                return entryForMonth ? entryForMonth[metric as keyof IngestsStatisticsData] : 0;
            });

            return {
                label: location,
                data: dataForMonths,
                backgroundColor: getColorForLocation(location),
                borderWidth: 1,
                barThickness: 55,
                borderRadius: 5,
            };
        });
    };

    const getBandwidthDatasets = (data: IngestsStatisticsData[]) => {
        const months = getUniqueMonths(data);
        const locations = Array.from(new Set(data.map((entry) => entry.location)));

        return locations.map((location) => {
            const dataForLocation = data.filter((entry) => entry.location === location);

            const dataForMonths = months.map((month) => {
                const entryForMonth = dataForLocation.find((entry) => {
                    const [year, monthNum] = entry.month.split("-");
                    const date = new Date(Number(year), Number(monthNum) - 1);
                    const formattedMonth = date.toLocaleString("default", {
                        month: "short",
                        year: "numeric",
                    });
                    return formattedMonth === month;
                });

                return entryForMonth
                    ? getUploadSpeed(
                          entryForMonth.volumeUploaded,
                          null,
                          null,
                          entryForMonth.totalIngestTimeInSeconds
                      )
                    : 0;
            });

            return {
                label: location,
                data: dataForMonths,
                borderColor: getColorForLocation(location),
                fill: false,
                tension: 0.2,
                pointRadius: 4,
            };
        });
    };

    const getColorForLocation = (location: string) => {
        const colors: { [key: string]: string } = {
            AM5: getComputedStyle(root)
                .getPropertyValue("--groupui-sys-color-background-connect")
                .trim(),
            SE3: getComputedStyle(root)
                .getPropertyValue("--groupui-sys-color-background-inform")
                .trim(),
        };
        return (
            colors[location] ||
            getComputedStyle(root).getPropertyValue("--groupui-sys-color-background-canvas").trim()
        );
    };

    const renderChart = (metric: string, title: string) => {
        const ctx = document.getElementById("graphCanvas") as HTMLCanvasElement;
        if (ctx) {
            new Chart(ctx, {
                type: "bar",
                data: {
                    labels: getUniqueMonths(sortedData),
                    datasets: getDatasetsForLocations(sortedData, metric),
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            title: {
                                display: false,
                            },
                            grid: {
                                display: false,
                            },
                        },
                        y: {
                            beginAtZero: true,
                            max: getMaxValueForY(sortedData, metric),
                            ticks: {
                                callback: (value) =>
                                    metric === "volumeUploaded"
                                        ? formatBytes(value as number)
                                        : value,
                            },
                            grid: {
                                color: getComputedStyle(root)
                                    .getPropertyValue("--groupui-sys-color-background-canvas")
                                    .trim(),
                            },
                        },
                    },
                    plugins: {
                        title: {
                            display: true,
                            text: title,
                            color: getComputedStyle(root)
                                .getPropertyValue("--groupui-sys-color-background-base")
                                .trim(),
                            font: {
                                size: 32,
                                weight: "bold",
                            },
                            padding: {
                                bottom: 40,
                            },
                            align: "start",
                        },
                        legend: {
                            position: "bottom",
                            align: "start",
                            labels: {
                                font: {
                                    size: 18,
                                },
                                padding: 20,
                                color: getComputedStyle(root)
                                    .getPropertyValue("--groupui-sys-color-background-inform")
                                    .trim(),
                                boxWidth: 12,
                                boxHeight: 12,
                            },
                        },
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem: any) => {
                                    const value = tooltipItem.raw;
                                    return metric === "volumeUploaded"
                                        ? `${formatBytes(value)}`
                                        : value;
                                },
                            },
                        },
                    },
                    layout: {
                        padding: {
                            left: 20,
                            right: 20,
                            top: 20,
                            bottom: 20,
                        },
                    },
                },
            });
        }
    };

    const renderBandwidthChart = (title: string) => {
        const ctx = document.getElementById("graphCanvas") as HTMLCanvasElement;
        if (ctx) {
            new Chart(ctx, {
                type: "line",
                data: {
                    labels: getUniqueMonths(sortedData),
                    datasets: getBandwidthDatasets(sortedData),
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            title: {
                                display: false,
                            },
                            grid: {
                                display: false,
                            },
                        },
                        y: {
                            beginAtZero: true,
                            ticks: {
                                callback: (value) => formatSpeed(value as number),
                            },
                            grid: {
                                color: getComputedStyle(root)
                                    .getPropertyValue("--groupui-sys-color-background-canvas")
                                    .trim(),
                            },
                        },
                    },
                    plugins: {
                        title: {
                            display: true,
                            text: title,
                            color: getComputedStyle(root)
                                .getPropertyValue("--groupui-sys-color-background-base")
                                .trim(),
                            font: {
                                size: 32,
                                weight: "bold",
                            },
                            padding: {
                                bottom: 40,
                            },
                            align: "start",
                        },
                        legend: {
                            position: "bottom",
                            align: "start",
                            labels: {
                                font: {
                                    size: 18,
                                },
                                padding: 20,
                                color: getComputedStyle(root)
                                    .getPropertyValue("--groupui-sys-color-background-inform")
                                    .trim(),
                                boxWidth: 12,
                                boxHeight: 12,
                            },
                        },
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem: any) => {
                                    const value = tooltipItem.raw;
                                    return formatSpeed(value as number);
                                },
                            },
                        },
                    },
                    layout: {
                        padding: {
                            left: 20,
                            right: 20,
                            top: 20,
                            bottom: 20,
                        },
                    },
                },
            });
        }
    };

    return (
        <div>
            {statisticsData.length > 0 ? (
                <GroupuiCard elevation="1" style={styles.container}>
                    <div style={{ width: "100%", height: "400px" }}>
                        <canvas id="graphCanvas" />
                    </div>
                </GroupuiCard>
            ) : (
                <p>No ingests in this time period. Please select another time range.</p>
            )}
        </div>
    );
};

export default IngestsStatisticsGraphView;
