import React, { useState } from "react";
import { Address } from "../../models/address";
import Snackbar from "../generics/Snackbar";
import {
    GroupuiModal,
    GroupuiLoadingSpinner,
    GroupuiButton,
    GroupuiDatePicker,
} from "@group-ui/group-ui-react";
import { ApiService } from "../../services/apiService";
import { GenericAddressInformationFields } from "../generics/GenericAddressInformationFields";
import GenericHeadline from "../generics/GenericHeadline";
import GenericInput from "../generics/GenericInput";
import { Company } from "../../models/company";
import { Country } from "../../models/country";
import { Countries } from "../../enums/Countries";
import { USStatesList } from "../../enums/USStatesList";
import AddressValidationModal from "./AddressValidationModal";

interface Props {
    show: boolean;
    setShow: (show: boolean, reload: boolean) => void;
}

const NonStandardRequestPickupModal: React.FC<Props> = ({ show, setShow }) => {
    const [showSnackbar, setShowSnackBar] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "danger" | "warning">(
        "success"
    );

    const [databoxName, setDataboxName] = useState<string>("");
    const [jiraTicket, setJiraTicket] = useState<string>("");

    const [pickupDate, setPickupDate] = useState<string | undefined>();

    const [pickupAddress, setPickupAddress] = useState<Address | undefined>(undefined);
    const [validatePickupAddress, setValidatePickupAddress] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState(false);

    const [addressValidation, setAddressValidation] = useState<{
        show: boolean;
        suggestedPickupAddress: Address | undefined;
    }>({ show: false, suggestedPickupAddress: undefined });

    const checkDeliveryInputs = (address: Address | undefined) => {
        const pickupDay = new Date(pickupDate!);
        const currentDate = new Date();

        return !pickupDate ||
            pickupDay.getDay() == 6 ||
            pickupDay.getDay() == 0 ||
            !address ||
            pickupDay <= currentDate ||
            pickupDay >= new Date(currentDate.setDate(currentDate.getDate() + 183)) ||
            address.fullName.length > 22 ||
            address.fullName.trim() === "" ||
            address.street.trim() === "" ||
            address.zipCode.trim() === "" ||
            address.city.trim() === "" ||
            !address.country ||
            !address.company ||
            address.email.trim() === "" ||
            address.phone.trim() === "" ||
            address.phone.trim().length < 7 ||
            address.country.country === Countries.UNITED_STATES
            ? !USStatesList.filter((usState) => usState.toLowerCase()).includes(
                  address?.state?.trim() ?? ""
              ) || address?.state?.trim() == ""
            : false;
    };

    function resetForm() {
        setDataboxName("");
        setJiraTicket("");
        setPickupAddress(
            new Address({
                id: undefined,
                fullName: "",
                street: "",
                addressSupplement: "",
                zipCode: "",
                city: "",
                state: "",
                country: new Country({ id: -1, country: "" }),
                company: new Company({ id: -1, company: "" }),
                email: "",
                phone: "",
            })
        );
    }

    const checkOnClickEvent = (address = pickupAddress) => {
        if (address) {
            const formattedPickupAddress = Address.fromSerialized({
                ...address,
                phone: address.phone.replaceAll(/[ -()-]+/g, ""),
            });
            if (formattedPickupAddress !== null && pickupDate !== undefined) {
                setIsLoading(true);
                ApiService.createNonStandardRequestPickup(
                    formattedPickupAddress,
                    validatePickupAddress,
                    databoxName,
                    jiraTicket,
                    new Date(pickupDate).toISOString()
                ).then((r) => {
                    if (r.ID !== undefined) {
                        setSnackbarMessage(
                            `Non-Standard Request was successfully created - NSR${r.ID}`
                        );
                        setSnackbarSeverity("success");
                        setShowSnackBar(true);
                        resetForm();
                        setShow(false, true);
                    } else if (r.suggestedPickupAddress !== undefined) {
                        setAddressValidation({
                            show: true,
                            suggestedPickupAddress: r.suggestedPickupAddress,
                        });
                    } else {
                        setSnackbarMessage("Unknown error!");
                        if (r.error) setSnackbarMessage(r.error);
                        setSnackbarSeverity("danger");
                        setShowSnackBar(true);
                    }
                    setIsLoading(false);
                });
            }
        }
    };

    return (
        <>
            {showSnackbar && (
                <Snackbar
                    severity={snackbarSeverity}
                    message={snackbarMessage}
                    onClose={() => setShowSnackBar(false)}
                />
            )}
            <GroupuiModal
                displayed={show}
                onGroupuiModalClose={() => {
                    setPickupDate(undefined);
                    resetForm();
                    setShow(false, false);
                }}
                closeButton={true}
                overflow="auto"
                padding="var(--groupui-sys-spacing-800)"
                margin="100px"
                l={12}
                xl={12}
                xxl={7}
                style={{
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {isLoading && (
                    <GroupuiLoadingSpinner
                        displayed={isLoading}
                        style={{
                            display: "flex",
                            position: "fixed",
                            height: "100%",
                            width: "100%",
                            zIndex: "1",
                            top: "0px",
                            left: "0px",
                            bottom: "0px",
                        }}
                    />
                )}
                <GenericHeadline
                    title="Request Pickup"
                    titleHeadingType="h3"
                    type="modal"
                    actionButtons={[]}
                />
                <div>
                    <GroupuiDatePicker
                        style={{ whiteSpace: "normal" }}
                        severity="none"
                        value={pickupDate}
                        required={true}
                        dateFormat="d.m.Y"
                        placeholder="dd.mm.yyyy"
                        minDate={(() => {
                            const date = new Date();
                            date.setDate(date.getDate() + 1);
                            return (
                                date.getDate() +
                                "." +
                                (date.getMonth() + 1).toLocaleString("de-DE", {
                                    minimumIntegerDigits: 2,
                                }) +
                                "." +
                                date.getFullYear()
                            );
                        })()}
                        maxDate={(() => {
                            const date = new Date();
                            date.setDate(date.getDate() + 183);
                            return (
                                date.getDate() +
                                "." +
                                (date.getMonth() + 1).toLocaleString("de-DE", {
                                    minimumIntegerDigits: 2,
                                }) +
                                "." +
                                date.getFullYear()
                            );
                        })()}
                        disableWeekends={true}
                        onGroupuiChange={(event) => setPickupDate(event.target.value)}
                    >
                        <span slot="label">Pickup Date *</span>
                    </GroupuiDatePicker>

                    <GenericHeadline
                        title="Pickup Address"
                        titleHeadingType="h5"
                        actionButtons={[]}
                    />
                    <GenericAddressInformationFields
                        address={pickupAddress}
                        setAddress={setPickupAddress}
                        disabled={false}
                        validateAddress={validatePickupAddress}
                        setValidateAddress={setValidatePickupAddress}
                    />
                </div>
                <GenericHeadline
                    title="Additional Information"
                    titleHeadingType="h5"
                    actionButtons={[]}
                />
                <GenericInput
                    label={"Databox Name *"}
                    value={databoxName}
                    onGroupuiChange={(event: any) => setDataboxName(event.target.value)}
                />
                <GenericInput
                    label={"Jira Ticket ID *"}
                    value={jiraTicket}
                    onGroupuiChange={(event: any) => setJiraTicket(event.target.value)}
                />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "var(--groupui-sys-spacing-400)",
                        marginTop: "var(--groupui-sys-spacing-600)",
                    }}
                >
                    <GroupuiButton variant={"secondary"} onClick={() => setShow(false, false)}>
                        Cancel
                    </GroupuiButton>
                    <GroupuiButton
                        variant="primary"
                        icon="service-bell-32"
                        fullwidth={false}
                        alignment="left"
                        onClick={() => checkOnClickEvent()}
                        disabled={
                            (!checkDeliveryInputs(pickupAddress) &&
                                databoxName.length > 0 &&
                                jiraTicket.length > 0) === false
                        }
                    >
                        Request
                    </GroupuiButton>
                    <AddressValidationModal
                        address={pickupAddress}
                        suggestedAddress={addressValidation.suggestedPickupAddress}
                        addressTitle={"Pickup Address"}
                        onDecision={function (isAccepted: boolean): void {
                            if (isAccepted) {
                                if (addressValidation.suggestedPickupAddress !== undefined) {
                                    setPickupAddress(addressValidation.suggestedPickupAddress);
                                    checkOnClickEvent(addressValidation.suggestedPickupAddress);
                                }
                            }
                            setAddressValidation({
                                show: false,
                                suggestedPickupAddress: undefined,
                            });
                        }}
                        show={addressValidation.show}
                        setShow={setAddressValidation}
                    />
                </div>
            </GroupuiModal>
        </>
    );
};

export default NonStandardRequestPickupModal;
