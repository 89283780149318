import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useTitle from "../../hooks/useTitle";
import GenericHeadline from "../generics/GenericHeadline";
import {
    GroupuiBreadcrumb,
    GroupuiBreadcrumbs,
    GroupuiDivider,
    GroupuiLoadingSpinner,
} from "@group-ui/group-ui-react";
import { NonStandardRequest } from "../../models/nonStandardRequest";
import { ApiService } from "../../services/apiService";
import { GeneralInformation } from "./GeneralInformation";
import { handleError } from "../context/ErrorContextUtils";
import ErrorContext from "../context/ErrorContext";
import { GenericAddressInformationFields } from "../generics/GenericAddressInformationFields";
import { Address } from "../../models/address";
import TrackerDataTable from "../generics/TrackerDataTable";

const NonStandardRequestDetails = () => {
    const { id } = useParams();
    const title = `Non-Standard Request Details - NSR${id}`;
    useTitle(title);
    const navigate = useNavigate();
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const [isLoading, setIsLoading] = useState(false);

    const [nonStandardRequest, setNonStandardRequest] = useState<NonStandardRequest | undefined>(
        undefined
    );
    const [pickupAddress, setPickupAddress] = useState<Address | undefined>(undefined);
    const [deliveryAddress, setDeliveryAddress] = useState<Address | undefined>(undefined);

    const [validatePickupAddress, setValidatePickupAddress] = useState<boolean>(true);
    const [validateDeliveryAddress, setValidateDeliveryAddress] = useState<boolean>(true);

    useEffect(() => {
        setIsLoading(true);
        ApiService.getNonStandardRequestById(id as unknown as number)
            .then((response) => {
                setNonStandardRequest(response);
                setIsLoading(false);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
                setIsLoading(false);
            });
    }, []);

    return (
        <div
            style={{
                padding: "var(--groupui-sys-spacing-1200) var(--groupui-sys-spacing-1400)",
                scrollBehavior: "smooth",
                whiteSpace: "pre-wrap",
            }}
        >
            {isLoading && (
                <GroupuiLoadingSpinner
                    displayed={isLoading}
                    style={{
                        display: "flex",
                        position: "fixed",
                        height: "100%",
                        width: "100%",
                        zIndex: "1",
                        top: "0px",
                        left: "0px",
                        bottom: "0px",
                    }}
                />
            )}
            <GroupuiBreadcrumbs>
                <GroupuiBreadcrumb onClick={() => navigate("../")}>
                    Non-Standard Requests
                </GroupuiBreadcrumb>
                <GroupuiBreadcrumb>{title}</GroupuiBreadcrumb>
            </GroupuiBreadcrumbs>
            <GenericHeadline title={title} actionButtons={[]} />
            <GroupuiDivider />
            <GeneralInformation nonStandardRequest={nonStandardRequest} />
            {nonStandardRequest?.pickupAddress && (
                <div>
                    <GroupuiDivider />
                    <GenericHeadline
                        title={"Pickup Address"}
                        titleHeadingType="h4"
                        actionButtons={[]}
                    />
                    <GenericAddressInformationFields
                        address={pickupAddress ? pickupAddress : nonStandardRequest.pickupAddress}
                        setAddress={setPickupAddress}
                        disabled={true}
                        validateAddress={validatePickupAddress}
                        setValidateAddress={setValidatePickupAddress}
                    />
                </div>
            )}
            {/* Conditional Rendering needed because it´s possibly undefined for pickups */}
            {nonStandardRequest?.deliveryAddress && (
                <div>
                    <GroupuiDivider />
                    <GenericHeadline
                        title={"Delivery Address"}
                        titleHeadingType="h4"
                        actionButtons={[]}
                    />
                    {nonStandardRequest && (
                        <GenericAddressInformationFields
                            address={
                                deliveryAddress
                                    ? deliveryAddress
                                    : nonStandardRequest.deliveryAddress
                            }
                            setAddress={setDeliveryAddress}
                            disabled={true}
                            validateAddress={validateDeliveryAddress}
                            setValidateAddress={setValidateDeliveryAddress}
                        />
                    )}
                </div>
            )}
            {nonStandardRequest?.type.toLowerCase().trim() === "shipment" && (
                <div>
                    <GroupuiDivider />
                    <GenericHeadline title={"Tracker"} titleHeadingType="h4" actionButtons={[]} />
                    {nonStandardRequest.databox.tracker?.name}
                    <TrackerDataTable
                        trackerData={nonStandardRequest.databox.tracker?.trackerData ?? []}
                    />
                </div>
            )}
        </div>
    );
};

export default NonStandardRequestDetails;
