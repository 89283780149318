import React, { useContext, useEffect, useState } from "react";
import GenericHeadline from "../generics/GenericHeadline";
import { ApiService } from "../../services/apiService";
import { Order } from "../../models/order";
import ErrorContext from "../context/ErrorContext";
import { handleError } from "../context/ErrorContextUtils";
import TrackerDataTable from "../generics/TrackerDataTable";
import ShipmentJobsDetailsGeneralTabGeneralInformation from "./ShipmentJobsDetailsGeneralTabGeneralInformation";
import SmartHandsTasksTable from "./SmartHandsTasksTable";
import { GroupuiNotification, GroupuiIcon } from "@group-ui/group-ui-react";

interface ShipmentJobsDetailsGeneralTabProps {
    shipmentJobId: string;
}

const ShipmentJobsDetailsGeneralTab: React.FC<ShipmentJobsDetailsGeneralTabProps> = ({
    shipmentJobId,
}) => {
    const [order, setOrder] = useState<Order | undefined>();
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);

    function loadOrder() {
        ApiService.getOrderByShipmentJobId(shipmentJobId as unknown as number)
            .then((response) => {
                setOrder(response);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
            });
    }

    useEffect(() => {
        loadOrder();
    }, []);

    const styles = {
        errorContainer: {
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "10px",
        } as React.CSSProperties,
    };

    return (
        <>
            <GenericHeadline title="General Information" titleHeadingType="h4" actionButtons={[]} />
            {order?.completed && order?.cancelled && (
                <div>
                    <GroupuiNotification severity="default" dismissible={false}>
                        <div style={styles.errorContainer}>
                            <GroupuiIcon name={"warning-filled-32"} height={25} />
                            This order has been cancelled: {order.reasonForTermination}
                        </div>
                    </GroupuiNotification>
                    <br />
                </div>
            )}
            <ShipmentJobsDetailsGeneralTabGeneralInformation order={order} />
            <GenericHeadline title="Tasks" titleHeadingType="h4" actionButtons={[]} />
            <SmartHandsTasksTable
                smartHandsTicket={order?.shipmentJob?.smartHandsTicketId}
                ingestStation={order?.databox.ingestStation?.name}
                databoxName={order?.databox.name}
            />
            <GenericHeadline title="Tracker" titleHeadingType="h4" actionButtons={[]} />
            <TrackerDataTable trackerData={order?.databox.tracker?.trackerData ?? []} />
        </>
    );
};

export default ShipmentJobsDetailsGeneralTab;
