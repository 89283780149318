import React, { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import IngestStationDetailsWrapper from "../container/IngestStationDetailsWrapper";
import { IngestSlotDetails } from "../ingestStations/ingestSlot/IngestSlotDetails";
import IngestStationDetails from "../ingestStations/IngestStationDetails";
import IngestStations from "../ingestStations/IngestStations";
import UserGroupContext from "../context/UserGroupContext";
import { IngestStationProvider } from "../context/provider/IngestStationContext";

/**
 * Returns an IngestStationRoutes Component, which defines the Nested Routes nested under the parent
 *
 * @returns IngestStationRoutes Component
 *
 */
function IngestStationRoutes() {
    const { userGroups } = useContext(UserGroupContext);
    return (
        <IngestStationProvider>
            <Routes>
                <Route index element={<IngestStations />} />
                <Route path=":ingestStationID/*" element={<IngestStationDetailsWrapper />}>
                    <Route index element={<IngestStationDetails />} />
                    <Route path=":ingestSlotId" element={<IngestSlotDetails />} />
                    {/* Redirect the user if the Route doesn´t exist */}
                    <Route path="*" element={<Navigate to="/ingest-stations" />} />
                </Route>
            </Routes>
        </IngestStationProvider>
    );
}

export default IngestStationRoutes;
