import {
    GroupuiButton,
    GroupuiDivider,
    GroupuiLoadingSpinner,
    GroupuiModal,
    GroupuiText,
} from "@group-ui/group-ui-react";
import React, { useContext, useState } from "react";
import GenericHeadline from "../../../generics/GenericHeadline";
import { parseFullDate } from "../../../../utils/DateUtil";
import GenericTag from "../../../generics/GenericTag";
import ErrorContext from "../../../context/ErrorContext";
import Snackbar from "../../../generics/Snackbar";
import { ApiService } from "../../../../services/apiService";
import { handleError } from "../../../context/ErrorContextUtils";
import { IngestSlotMonitorData } from "../../../../models/ingestSlotMonitorData";
interface Props {
    modal: boolean;
    setModal: (value: boolean) => void;
    ingestSlotMonitorData: IngestSlotMonitorData;
}

const ResetOverwriteModal: React.FC<Props> = ({ modal, setModal, ingestSlotMonitorData }) => {
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
    const [severity, setSeverity] = useState<"success" | "danger">("success");
    const [message, setMessage] = useState<string>("");

    const [loading, setLoading] = useState<boolean>(false);

    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);

    const sendRequest = () => {
        if (ingestSlotMonitorData?.ingestId) {
            setLoading(true);
            ApiService.addOverwriteStateToIngest(ingestSlotMonitorData?.ingestId, "delete", true)
                .then((response) => {
                    if (response.startsWith("success")) {
                        setShowSnackbar(true);
                        setMessage("Successfully reset overwrite state!");
                        setTimeout(() => {
                            setLoading(false);
                            setModal(false);
                            window.location.reload();
                        }, 2000);
                    } else {
                        setShowSnackbar(true);
                        setMessage("An error occurred during reset of the overwrite state!");
                        setSeverity("danger");

                        setShowSnackbar(true);
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    handleError(error, changeErrorMessage, changeShowError);
                });
        }
    };

    return (
        <GroupuiModal
            displayed={modal}
            onGroupuiModalClose={() => setModal(false)}
            closeButton={true}
            overflow="visible"
            margin="200px"
            padding="var(--groupui-sys-spacing-800)"
        >
            <GroupuiLoadingSpinner displayed={loading} />
            {showSnackbar && (
                <Snackbar
                    severity={severity}
                    message={message}
                    onClose={() => setShowSnackbar(false)}
                />
            )}
            <GenericHeadline
                title={"Reset Overwrite"}
                titleHeadingType="h3"
                actionButtons={[]}
                type="modal"
            />

            <div
                style={{
                    display: "flex",
                    gap: "var(--groupui-sys-spacing-300)",
                    flexDirection: "column",
                }}
            >
                <GroupuiDivider />
                <GroupuiText>Do you want to reset the following Overwrite-State:</GroupuiText>
                <GenericTag
                    text={ingestSlotMonitorData?.overwriteState ?? ""}
                    variant={""}
                    mediaStateTarget="databox"
                />
                <GroupuiText>
                    Active since: {parseFullDate(ingestSlotMonitorData?.overwrittenSince)}
                </GroupuiText>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "var(--groupui-sys-spacing-400)",
                        marginTop: "var(--groupui-sys-spacing-600)",
                    }}
                >
                    <GroupuiButton variant={"secondary"} onClick={() => setModal(false)}>
                        Cancel
                    </GroupuiButton>
                    <GroupuiButton icon="check-32" onClick={sendRequest}>
                        Confirm
                    </GroupuiButton>
                </div>
            </div>
        </GroupuiModal>
    );
};

export default ResetOverwriteModal;
