import React from "react";
import GenericTable, { columnDefinition } from "../generics/GenericTable";

const smartHandsTableColumns: columnDefinition[] = [
    { key: "taskName", header: "Task" },
    { key: "description", header: "Description" },
    { key: "smartHandsTicket", header: "Equinix Smart Hands Ticket" },
];

const SmartHandsTasksTable: React.FC<{
    smartHandsTicket?: string;
    ingestStation?: string;
    databoxName?: string;
}> = ({ smartHandsTicket = "", ingestStation, databoxName }) => {
    function createTableData() {
        /*
        Map the task types to the six tasks.
        Should we add unknown types to the end of the list?
        */

        return [
            {
                taskName: "Task1",
                description: `Get Databox (${databoxName})`,
                smartHandsTicket: smartHandsTicket,
            },
            {
                taskName: "Task2",
                description: `Check databox (${databoxName})`,
                smartHandsTicket: smartHandsTicket,
            },
            {
                taskName: "Task3",
                description:
                    ingestStation == "SE3"
                        ? `Check battery (${databoxName})`
                        : `Remove tracker from charger and insert in databox (${databoxName})`,
                smartHandsTicket: smartHandsTicket,
            },
            {
                taskName: "Task4",
                description:
                    ingestStation == "SE3"
                        ? `Check that the tracker is switched on`
                        : `Verify that the tracker is ON `,
                smartHandsTicket: smartHandsTicket,
            },
            {
                taskName: "Task5",
                description:
                    ingestStation == "SE3"
                        ? `Print labels, stick the delivery label on the outside of the case, put the return label inside the case (${databoxName})`
                        : `Print labels and stick destination label outside of the case and put return label inside (${databoxName})`,
                smartHandsTicket: smartHandsTicket,
            },
            {
                taskName: "Task6",
                description: `Store for collection (${databoxName})`,
                smartHandsTicket: smartHandsTicket,
            },
        ];
    }

    return <GenericTable columns={smartHandsTableColumns} data={createTableData()} header={true} />;
};

export default SmartHandsTasksTable;
