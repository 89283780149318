import { GroupuiSelect, GroupuiSelectOption } from "@group-ui/group-ui-react";
import React, { Dispatch, SetStateAction } from "react";
import { Country } from "../../../models/country";
import GenericInput from "../../generics/GenericInput";

interface Props {
    addressLine1?: string;
    setAddressLine1: Dispatch<SetStateAction<string>>;
    addressLine2?: string;
    setAddressLine2: Dispatch<SetStateAction<string>>;
    zipCode?: string;
    setZipCode: Dispatch<SetStateAction<string>>;
    state: string;
    setState: Dispatch<SetStateAction<string>>;
    city?: string;
    setCity: Dispatch<SetStateAction<string>>;
    country?: Country;
    setCountry: Dispatch<SetStateAction<Country | undefined>>;
    countries: Country[] | null;
}

const DropOffInformation: React.FC<Props> = ({
    addressLine1,
    setAddressLine1,
    addressLine2,
    setAddressLine2,
    zipCode,
    setZipCode,
    state,
    setState,
    city,
    setCity,
    country,
    setCountry,
    countries,
}) => {
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <GenericInput
                label={`Address Line 1 *`}
                description={"Street Address, P.O. box, etc."}
                value={addressLine1}
                disabled={false}
                onGroupuiChange={(event: any) => setAddressLine1(event.target.value)}
            />
            <GenericInput
                label={`Address Line 2`}
                description={"Apartment, unit, building, floor, etc."}
                value={addressLine2}
                disabled={false}
                onGroupuiChange={(event: any) => setAddressLine2(event.target.value)}
            />
            <GenericInput
                label={`ZIP Code *`}
                value={zipCode}
                disabled={false}
                onGroupuiChange={(event: any) => setZipCode(event.target.value)}
            />
            <GenericInput
                label={`City *`}
                value={city}
                disabled={false}
                onGroupuiChange={(event: any) => setCity(event.target.value)}
            />
            <GenericInput
                label={`State`}
                value={state}
                disabled={false}
                onGroupuiChange={(event: any) => setState(event.target.value)}
            />

            <div style={{ paddingBottom: "1.7rem" }}>
                {countries && (
                    <GroupuiSelect
                        value={country?.id.toString() ?? ""}
                        required={true}
                        maxHeight="300px"
                        typeof="input"
                        onGroupuiChange={(event) =>
                            setCountry(
                                countries?.find(
                                    (country) => country.id == Number(event.target.value)
                                )
                            )
                        }
                        disabled={false}
                    >
                        {(countries !== null ? countries : [country!]).map((country, index) => {
                            return (
                                <GroupuiSelectOption
                                    key={`${country.country}-option-${Math.random()}`}
                                    value={String(country.id)}
                                >
                                    {country.country}
                                </GroupuiSelectOption>
                            );
                        })}
                        <span slot="label">Country *</span>
                    </GroupuiSelect>
                )}
            </div>
        </div>
    );
};

export default DropOffInformation;
