export class IngestStationType {
    public ingestStationType;

    constructor({ ingestStationType }: { ingestStationType: string }) {
        this.ingestStationType = ingestStationType;
    }

    private toObject() {
        return {
            ingestStationType: this.ingestStationType,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): IngestStationType | null {
        if (json == null) {
            return null;
        }

        return new IngestStationType({
            ingestStationType: json["ingestStationType"],
        });
    }
}
