import { GroupuiSelect, GroupuiSelectOption } from "@group-ui/group-ui-react/dist/components";
import React, { useContext, useEffect, useState } from "react";
import { ApiService } from "../../../services/apiService";
import ErrorContext from "../../context/ErrorContext";
import { handleError } from "../../context/ErrorContextUtils";
import { SusNameAndId } from "../../../models/SusNameAndId";

interface SusLocationSelectorProps {
    value?: SusNameAndId;
    onChange?: (value: SusNameAndId | undefined) => void;
    localStorageKey?: string;
    isLoading?: (isLoading: boolean) => void;
}

/**
 * A SusLocationSelector Component, which contains the selector for choosing the sus location on the dashboard
 *
 * @param {SusNameAndId} [value] - holds current SusNameAndId
 * @param {(value: SusNameAndId) => void} [onChange] - function to be run onChange of the SusNameAndId
 * @param {string} [localStorageKey] - key where the value for the last chosen SusNameAndId can be found in the local-storage
 * @param {(isLoading: boolean) => void} [isLoading] - function to change isLoading value, so that the parent-components loadingSpinner is shown/not shown
 *
 * @returns SusLocationSelector Component
 *
 */
const SusLocationSelector: React.FC<SusLocationSelectorProps> = ({
    value,
    onChange,
    localStorageKey,
    isLoading,
}) => {
    const [options, setOptions] = useState<SusNameAndId[]>();
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const allOption = new SusNameAndId({ id: -1, name: "All" });

    useEffect(() => {
        if (options === undefined) {
            if (isLoading) isLoading(true);
            ApiService.getAllSusLocations()
                .then((locations) => {
                    setOptions([allOption, ...locations]);
                })
                .catch((error) => {
                    handleError(error, changeErrorMessage, changeShowError);
                })
                .finally(() => {
                    if (isLoading) isLoading(false);
                });
        }
    }, [options]);

    useEffect(() => {
        if (localStorageKey !== undefined && options !== undefined && value === undefined) {
            const localStorageValue = window.localStorage.getItem(localStorageKey);
            if (localStorageValue !== undefined && localStorageValue !== null) {
                const parsedValue = SusNameAndId.fromSerialized(JSON.parse(localStorageValue));
                if (onChange !== undefined) {
                    onChange(parsedValue.id === -1 ? undefined : parsedValue);
                }
            } else {
                if (onChange !== undefined) {
                    onChange(undefined);
                }
            }
        }
    }, [localStorageKey, options, value]);

    return (
        <GroupuiSelect
            value={value ? value.serialize() : allOption.serialize()}
            severity="none"
            placeholder="Location"
            onGroupuiChange={(event) => {
                if (localStorageKey !== undefined) {
                    window.localStorage.setItem(localStorageKey, event.target.value);
                }
                if (onChange !== undefined) {
                    const selectedValue = SusNameAndId.fromSerialized(
                        JSON.parse(event.target.value)
                    );
                    onChange(selectedValue.id === -1 ? undefined : selectedValue);
                }
            }}
        >
            {options
                ? options.map((element, index) => (
                      <GroupuiSelectOption
                          key={`sus-location-select-option-${index}`}
                          value={element.serialize()}
                      >
                          {element.name}
                      </GroupuiSelectOption>
                  ))
                : []}
        </GroupuiSelect>
    );
};

export default SusLocationSelector;
