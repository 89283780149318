import { MediaState } from "../mediaState";
import { Project } from "../project";

export class IngestSlotDetails {
    public id;
    public ingestSlotId;
    public created;
    public started;
    public finished;
    public mediaState;
    public by;
    public comment;
    public orderId;
    public project;
    public databoxId;
    public ingestId;
    public ticketId;

    constructor({
        id,
        ingestSlotId,
        created,
        started,
        finished,
        mediaState,
        by,
        comment,
        orderId,
        project,
        databoxId,
        ingestId,
        ticketId,
    }: {
        id: number;
        ingestSlotId: number;
        created: string;
        started: string;
        finished: string | null;
        mediaState: MediaState;
        by: string;
        comment: string | null;
        orderId: number | null;
        project: Project | null;
        databoxId: number | null;
        ingestId: number | null;
        ticketId: string | null;
    }) {
        this.id = id;
        this.ingestSlotId = ingestSlotId;
        this.created = created;
        this.started = started;
        this.finished = finished;
        this.mediaState = mediaState;
        this.by = by;
        this.comment = comment;
        this.orderId = orderId;
        this.project = project;
        this.databoxId = databoxId;
        this.ingestId = ingestId;
        this.ticketId = ticketId;
    }

    private toObject() {
        return {
            id: this.id,
            ingestSlotId: this.ingestSlotId,
            created: this.created,
            started: this.started,
            finished: this.finished,
            mediaState: this.mediaState,
            by: this.by,
            comment: this.comment,
            orderId: this.orderId,
            project: this.project,
            databoxId: this.databoxId,
            ingestId: this.ingestId,
            ticketId: this.ticketId,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): IngestSlotDetails | null {
        if (json == null) {
            return null;
        }
        return new IngestSlotDetails({
            id: json["id"],
            ingestSlotId: json["ingestSlotId"],
            created: json["created"],
            started: json["started"],
            finished: json["finished"],
            mediaState: MediaState.fromSerialized(json["mediaState"]),
            by: json["by"],
            comment: json["comment"],
            orderId: json["orderId"],
            project: Project.fromSerialized(json["project"]),
            databoxId: json["databoxId"],
            ingestId: json["ingestId"],
            ticketId: json["ticketId"],
        });
    }
}
