import {
    GroupuiButton,
    GroupuiLoadingSpinner,
    GroupuiModal,
    GroupuiCheckbox,
} from "@group-ui/group-ui-react";
import React, { useContext, useEffect, useState } from "react";
import { ApiService } from "../../../services/apiService";
import ErrorContext from "../../context/ErrorContext";
import { handleError } from "../../context/ErrorContextUtils";
import { Tracker } from "../../../models/tracker";
import GenericTable, { columnDefinition } from "../../generics/GenericTable";
import GenericHeadline from "../../generics/GenericHeadline";

const columns: columnDefinition[] = [
    {
        key: "checkbox",
        header: "",
        width: "2rem",
    },
    {
        key: "name",
        header: "Name",
        width: "auto",
    },
];

interface Props {
    showModal: boolean;
    onModalClose: () => void;
    onSelectTracker: (tracker: Tracker) => void;
}

const TrackerListModal: React.FC<Props> = ({ showModal, onModalClose, onSelectTracker }) => {
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRow, setSelectedRow] = useState<number | null>(null);
    const [selectedTracker, setSelectedTracker] = useState<Tracker>();
    const [trackerData, setTrackerData] = useState<Tracker[]>();

    const data =
        trackerData?.map((tracker: Tracker, index) => {
            return {
                checkbox: (
                    <GroupuiCheckbox
                        checked={selectedRow === index}
                        onClick={() => handleRowSelect(index)}
                        disabled={selectedRow !== null && selectedRow !== index}
                    />
                ),
                name: tracker.name,
            };
        }) ?? [];

    const getData = async () => {
        setIsLoading(true);
        ApiService.getAllTrackersWithoutData()
            .then((response) => {
                setTrackerData(response);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
            })
            .then(() => setIsLoading(false));
        return;
    };

    useEffect(() => {
        getData();
    }, []);

    const handleRowSelect = (rowIndex: number) => {
        setSelectedRow(selectedRow === rowIndex ? null : rowIndex);
        if (trackerData) {
            setSelectedTracker(trackerData[rowIndex]);
        }
    };

    const handleSelectButtonClick = () => {
        if (selectedTracker) {
            onSelectTracker(selectedTracker);
        }
    };

    return (
        <GroupuiModal
            closeButton={true}
            displayed={showModal}
            onGroupuiModalClose={onModalClose}
            padding="var(--groupui-sys-spacing-800)"
            style={{ justifyContent: "center", alignItems: "center" }}
        >
            <GroupuiLoadingSpinner displayed={isLoading} />
            <GenericHeadline
                title="Select Tracker"
                titleHeadingType="h3"
                type="modal"
                actionButtons={[]}
            />

            <GenericTable
                pageSize={5}
                columns={columns}
                data={data ?? []}
                searchColumns={[{ column: "name", displayName: "Name" }]}
            />
            <div
                style={{
                    display: "flex",
                    justifyContent: "end",
                    paddingTop: "var(--groupui-sys-spacing-600)",
                    gap: "var(--groupui-sys-spacing-400)",
                }}
            >
                <GroupuiButton onClick={onModalClose} variant={"secondary"}>
                    Cancel
                </GroupuiButton>
                <GroupuiButton
                    icon="check-24"
                    disabled={selectedRow === null}
                    onClick={handleSelectButtonClick}
                >
                    Select
                </GroupuiButton>
            </div>
        </GroupuiModal>
    );
};

export default TrackerListModal;
