export class Comment {
    public databoxId;
    public creator;
    public commentContent;
    public commentTimestamp;

    constructor({
        databoxId,
        creator,
        commentContent,
        commentTimestamp,
    }: {
        databoxId: number;
        creator: string;
        commentContent: string;
        commentTimestamp: string;
    }) {
        this.databoxId = databoxId;
        this.creator = creator;
        this.commentContent = commentContent;
        this.commentTimestamp = commentTimestamp;
    }

    private toObject() {
        return {
            databoxId: this.databoxId,
            creator: this.creator,
            commentContent: this.commentContent,
            commentTimestamp: this.commentTimestamp,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): Comment | null {
        if (json == null) {
            return null;
        }

        return new Comment({
            databoxId: json["databoxId"],
            creator: json["creator"],
            commentContent: json["commentContent"],
            commentTimestamp: json["commentTimestamp"],
        });
    }
}
