import {
    GroupuiButton,
    GroupuiIcon,
    GroupuiLoadingSpinner,
    GroupuiModal,
    GroupuiNotification,
    GroupuiSelect,
    GroupuiSelectOption,
} from "@group-ui/group-ui-react";
import React, { useContext, useEffect, useState } from "react";
import GenericHeadline from "../GenericHeadline";
import { ApiService } from "../../../services/apiService";
import { SlotStatus } from "../../../models/slotStatus";
import ErrorContext from "../../context/ErrorContext";
import Snackbar from "../Snackbar";
import GenericInput from "../GenericInput";
import { handleError } from "../../context/ErrorContextUtils";
import { IngestSlot } from "../../../models/ingestSlot/ingestSlot";
import { SwitchJobRequest } from "../../../models/switchJobRequest";

interface SwitchIngestSlotModalProps {
    modal: boolean;
    setModal: (value: boolean) => void;
    ingestStationId: number;
    orderId: number;
}

export const getVisibilityConditionSwitchIngestSlot = (
    mediaState: string,
    reservedBy: string,
    orderCompleted: boolean,
    orderCancelled: boolean
): boolean => {
    return (
        !(
            (mediaState.toLowerCase() === "reserved" && reservedBy.toLowerCase() === "portal") ||
            mediaState.toLowerCase() === "error"
        ) ||
        orderCompleted ||
        orderCancelled
    );
};

const styles = {
    errorContainer: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        gap: "var(--groupui-sys-spacing-300)",
    } as React.CSSProperties,
};

export const SwitchIngestSlotModal: React.FC<SwitchIngestSlotModalProps> = ({
    modal,
    setModal,
    ingestStationId,
    orderId,
}) => {
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const [freeIngestSlots, setFreeIngestSlots] = useState<IngestSlot[]>([]);

    const [loading, setLoading] = useState<boolean>(false);
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
    const [severity, setSeverity] = useState<"success" | "danger">("success");
    const [message, setMessage] = useState<string>("");

    const [ingestSlot, setIngestSlot] = useState<IngestSlot>();
    const [ingestSlotTargetState, setIngestSlotTargetState] = useState<string>("Free");
    const [jiraTicketId, setJiraTicketId] = useState<string>("");

    useEffect(() => {
        setLoading(true);
        ApiService.getFreeIngestSlotsByIngestStationId(ingestStationId)
            .then((response) => {
                setLoading(false);
                setFreeIngestSlots(response);
            })
            .catch((error) => {
                setLoading(false);
                handleError(error, changeErrorMessage, changeShowError);
            });
    }, []);

    const sendRequest = () => {
        if (!ingestSlot || ingestSlotTargetState === "" || orderId < 1) {
            console.log(ingestSlot, ingestStationId, orderId, ingestSlotTargetState);
            return;
        }

        setLoading(true);
        const switchJobRequest = new SwitchJobRequest(
            ingestSlot.id,
            orderId,
            ingestSlotTargetState,
            jiraTicketId
        );

        ApiService.switchIngestSlot(switchJobRequest)
            .then((response) => {
                if (response.startsWith("success")) {
                    setShowSnackbar(true);
                    setMessage("Successfully switched ingest-slot!");
                    setTimeout(() => {
                        setLoading(false);
                        setModal(false);
                        window.location.reload();
                    }, 3000);
                } else {
                    setShowSnackbar(true);
                    setMessage("An error occurred the switch of the ingest-slot!");
                    setSeverity("danger");
                    setLoading(false);
                }
            })
            .catch((error) => {
                setLoading(false);
                handleError(error, changeErrorMessage, changeShowError);
            });
    };

    return (
        <GroupuiModal
            displayed={modal}
            onGroupuiModalClose={() => setModal(false)}
            closeButton={true}
            overflow="visible"
            margin="180px"
        >
            <GroupuiLoadingSpinner displayed={loading} />
            {showSnackbar && (
                <Snackbar
                    severity={severity}
                    message={message}
                    onClose={() => setShowSnackbar(false)}
                />
            )}
            <GenericHeadline
                title={"Switch Ingest Slot"}
                titleHeadingType="h4"
                actionButtons={[]}
                type="modal"
            />
            <div
                style={{
                    display: "flex",
                    gap: "var(--groupui-sys-spacing-400)",
                    flexDirection: "column",
                }}
            >
                {orderId < 0 && (
                    <div>
                        <GroupuiNotification severity="danger" dismissible={false}>
                            <div style={styles.errorContainer}>
                                <GroupuiIcon name={"warning-filled-32"} height={25} />
                                Order relation isn´t properly defined - Contact developer team
                            </div>
                        </GroupuiNotification>
                        <br />
                    </div>
                )}
                <GroupuiSelect
                    value={ingestSlot?.name}
                    required={true}
                    typeof="input"
                    maxHeight="300px"
                    onGroupuiChange={(event) => {
                        setIngestSlot(
                            freeIngestSlots.find((slot) => {
                                return slot.name == event.target.value;
                            })
                        );
                    }}
                    disabled={false}
                >
                    {freeIngestSlots.map((slot: IngestSlot) => {
                        return (
                            <GroupuiSelectOption
                                key={`${slot.id}-option-${Math.random()}`}
                                value={slot.name}
                            >
                                {slot.name}
                            </GroupuiSelectOption>
                        );
                    })}
                    <span slot="label">New Ingest Slot*</span>
                    <span slot="description">
                        Free ingest slots that can be used for continuation of the ingest
                    </span>
                </GroupuiSelect>

                <GroupuiSelect
                    value={ingestSlotTargetState}
                    required={true}
                    typeof="input"
                    onGroupuiChange={(event) => setIngestSlotTargetState(event.target.value)}
                    disabled={false}
                    defaultValue={"Free"}
                >
                    {["Free", "Maintenance"].map((state) => {
                        return (
                            <GroupuiSelectOption
                                key={`${state}-option-${Math.random()}`}
                                value={String(state)}
                            >
                                {state}
                            </GroupuiSelectOption>
                        );
                    })}
                    <span slot="label">Target Status of original Ingest Slot</span>
                    <span slot="description">
                        The current ingest slot is changed to this state after the databox is
                        removed
                    </span>
                </GroupuiSelect>
                {ingestSlotTargetState === "Maintenance" && (
                    <GenericInput
                        label="Jira Ticket ID*"
                        value={jiraTicketId}
                        onGroupuiChange={(event: any) => setJiraTicketId(event.target.value)}
                    />
                )}

                <div
                    style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "var(--groupui-sys-spacing-400)",
                        marginTop: "var(--groupui-sys-spacing-600)",
                    }}
                >
                    <GroupuiButton variant={"secondary"} onClick={() => setModal(false)}>
                        Cancel
                    </GroupuiButton>
                    <GroupuiButton
                        icon="update-32"
                        disabled={
                            ingestSlot == null ||
                            ingestSlot == undefined ||
                            (ingestSlotTargetState === "Maintenance" && jiraTicketId === "")
                        }
                        onClick={sendRequest}
                    >
                        Switch
                    </GroupuiButton>
                </div>
            </div>
        </GroupuiModal>
    );
};
