export class MediaState {
    public id;
    public mediaStateType;
    public priority;

    constructor({
        id,
        mediaStateType,
        priority,
    }: {
        id: number;
        mediaStateType: string;
        priority: number;
    }) {
        this.id = id;
        this.mediaStateType = mediaStateType;
        this.priority = priority;
    }

    private toObject() {
        return {
            id: this.id,
            mediaStateType: this.mediaStateType,
            priority: this.priority,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): MediaState {
        if (json == null) {
            return new MediaState({
                id: -1,
                mediaStateType: "Unknown",
                priority: 999,
            });
            // Showing an unknown media state here to avoid an error
            // Due to high number of usages I did not make it nullable.
            // throw new Error(`Error parsing Media State!`);
        }

        return new MediaState({
            id: json["id"],
            mediaStateType: json["mediaStateType"],
            priority: json["priority"],
        });
    }
}
