import { IngestStationOverview } from "../../../models/ingestStationOverview";
import GenericTable, { columnDefinition } from "../../generics/GenericTable";
import React, { useContext, useEffect, useState } from "react";
import { handleError } from "../../context/ErrorContextUtils";
import ErrorContext from "../../context/ErrorContext";
import { ApiService } from "../../../services/apiService";
import { GroupuiIcon, GroupuiTooltip } from "@group-ui/group-ui-react";
import DataboxesPerStationOverview from "./DataboxPerStationOverview";

const styles = {
    magnifyingGlass: {
        cursor: "pointer",
        color: "var(--groupui-sys-color-action-basic-default)",
    },
};

const columns: columnDefinition[] = [
    {
        key: "name",
        header: "Location",
        width: "auto",
    },
    {
        key: "freeIngestSlots",
        header: "Free Ingest Slots",
        width: "auto",
    },
    {
        key: "inUseIngestSlots",
        header: "In use Ingest Slots",
        width: "auto",
    },
    {
        key: "totalIngestSlots",
        header: "Total Ingest Slots",
        width: "auto",
    },
    {
        key: "databoxesInQueue",
        header: "Databoxes in Queue",
        width: "auto",
    },
    {
        key: "button",
        width: "var(--groupui-sys-spacing-1800)",
    },
];

const IngestStationOverviews: React.FC = () => {
    const [ingestStationOverviewData, setIngestStationOverviewData] =
        useState<IngestStationOverview[]>();
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const [showDataboxPerStation, setShowDataxPerStation] = useState(false);
    const [ingestStation, setIngestStation] = useState<IngestStationOverview>();

    const toggleProposedAddress = () => {
        setShowDataxPerStation((prevState) => !prevState);
    };

    useEffect(() => {
        getData();
    }, []);

    const handleDataboxesView = (station: IngestStationOverview) => {
        setIngestStation(station);
        setShowDataxPerStation(true);
    };

    const data =
        ingestStationOverviewData?.map((ingestStationOverview: IngestStationOverview) => {
            return {
                name: ingestStationOverview.name,
                freeIngestSlots: ingestStationOverview.freeIngestSlots,
                inUseIngestSlots: ingestStationOverview.inUseIngestSlots,
                totalIngestSlots: ingestStationOverview.totalIngestSlots,
                databoxesInQueue: ingestStationOverview.databoxesInQueue,
                button: (
                    <GroupuiTooltip position="left" style={{ position: "relative" }}>
                        <GroupuiIcon
                            name="search-plus-32"
                            style={styles.magnifyingGlass}
                            onClick={() => handleDataboxesView(ingestStationOverview)}
                        />
                        <div slot="tooltip">Show Details</div>
                    </GroupuiTooltip>
                ),
            };
        }) ?? [];

    const getData = () => {
        ApiService.getAllIngestStationOverviewes()
            .then((response) => {
                setIngestStationOverviewData(response);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
            });
        return;
    };
    return (
        <div>
            <GenericTable columns={columns} data={data} header={true} />
            {showDataboxPerStation && ingestStation && (
                <DataboxesPerStationOverview
                    showModal={showDataboxPerStation}
                    station={ingestStation}
                    closedDataboxPerStatio={toggleProposedAddress}
                />
            )}
        </div>
    );
};

export default IngestStationOverviews;
