import React, { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import GenericDataboxOrderOverview, {
    DataboxOrderOverview,
} from "../generics/pages/GenericDataboxOrderOverview";
import DataboxOrdersDetailsWrapper from "../databoxOrders/DataboxOrdersDetailsWrapper";
import GenericDataboxDetails from "../generics/pages/GenericDataboxOrderDetails";
import { RightsWrapper } from "../container/RightsWrapper";
import UserGroupContext from "../context/UserGroupContext";
import { RightsManagement } from "../../config/rightsManagement";
import { columnDefinition } from "../generics/GenericTable";
import { OrderProvider } from "../context/provider/OrderContext";

/**
 * A DataboxOrdersRoutes Component, which defines the Nested Routes nested under the parent for databox-orders-content
 *
 * @returns DataboxOrdersRoutes
 *
 */
function DataboxOrdersRoutes() {
    const { userGroups } = useContext(UserGroupContext);

    return (
        <RightsWrapper
            key={"first"}
            authorizedUserGroup={RightsManagement.DATABOX_ORDERS_ROUTES}
            userGroups={userGroups}
            section={true}
        >
            <OrderProvider>
                <Routes>
                    <Route
                        index
                        element={
                            <GenericDataboxOrderOverview
                                columns={columns}
                                type={DataboxOrderOverview.DATABOX_ORDERS}
                            />
                        }
                    />
                    <Route path=":orderID/:tab" element={<DataboxOrdersDetailsWrapper />}>
                        <Route
                            index
                            element={
                                <GenericDataboxDetails type={DataboxOrderOverview.DATABOX_ORDERS} />
                            }
                        />
                        {/* Redirect the user if the Route doesn´t exist */}
                        <Route path="*" element={<Navigate to="/shipment-jobs" />} />
                    </Route>
                </Routes>
            </OrderProvider>
        </RightsWrapper>
    );
}

const columns: columnDefinition[] = [
    {
        key: "orderId",
        header: "Order ID",
        width: "auto",
    },
    {
        key: "databoxName",
        header: "Databox Name",
        width: "auto",
    },
    {
        key: "ingestLocation",
        header: "Ingest Location",
        width: "auto",
    },
    {
        key: "destinationCountry",
        header: "Destination",
        width: "auto",
    },
    {
        key: "currentState",
        header: "Current State",
        width: "auto",
    },
    {
        key: "accessPoint",
        header: "Access Point",
        width: "auto",
    },
    {
        key: "requestor",
        header: "Requestor",
        width: "auto",
    },
    {
        key: "created",
        header: "Created",
        width: "auto",
    },
    {
        key: "requestedPickupDate",
        header: "Requested Pickup Date",
        width: "auto",
    },
    {
        key: "pickedUpDroppedOff",
        header: "Picked Up / Dropped Off",
        width: "auto",
    },
    {
        key: "button",
        width: "4rem",
    },
];

export default DataboxOrdersRoutes;
