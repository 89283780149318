export class IngestsReport {
    public id;
    public from;
    public to;
    public ingestLocationName;
    public successfulIngests;
    public uploadDuration;
    public volume;
    public files;
    public created;
    public calenderWeek;
    public ingestType;

    constructor({
        id,
        from,
        to,
        ingestLocationName,
        successfulIngests,
        uploadDuration,
        volume,
        files,
        created,
        calenderWeek,
        ingestType,
    }: {
        id: number;
        from: string;
        to: string;
        ingestLocationName: string;
        successfulIngests: number;
        uploadDuration: number;
        volume: number;
        files: number;
        created: string;
        calenderWeek: string;
        ingestType: string;
    }) {
        this.id = id;
        this.from = from;
        this.to = to;
        this.ingestLocationName = ingestLocationName;
        this.successfulIngests = successfulIngests;
        this.uploadDuration = uploadDuration;
        this.volume = volume;
        this.files = files;
        this.created = created;
        this.calenderWeek = calenderWeek;
        this.ingestType = ingestType;
    }

    public get displayName() {
        return `IR${this.calenderWeek}`;
    }

    private toObject() {
        return {
            id: this.id,
            from: this.from,
            to: this.to,
            ingestLocationName: this.ingestLocationName,
            successfulIngests: this.successfulIngests,
            uploadDuration: this.uploadDuration,
            volume: this.volume,
            files: this.files,
            created: this.created,
            calenderWeek: this.calenderWeek,
            ingestType: this.ingestType,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): IngestsReport {
        if (json == null) {
            throw new Error("Something went wrong during the Ingests Report creation!");
        }

        return new IngestsReport({
            id: json["id"],
            from: json["from"],
            to: json["to"],
            ingestLocationName: json["ingestLocationName"],
            successfulIngests: json["successfulIngests"],
            uploadDuration: json["uploadDuration"],
            volume: json["volume"],
            files: json["files"],
            created: json["created"],
            calenderWeek: json["calenderWeek"],
            ingestType: json["ingestType"],
        });
    }
}
