import React, { useContext, useState } from "react";
import { useMsal } from "@azure/msal-react";
import {
    GroupuiActionArea,
    GroupuiActionAreaPopoverItem,
    GroupuiButton,
    GroupuiIcon,
    GroupuiTooltip,
} from "@group-ui/group-ui-react";
import PortalHeader from "./PortalHeader";
import RaiseTicketModal from "../RaiseTicketModal";
import ShowAboutModal from "./ShowAboutModal";
import OutsideClickHandler from "../generics/GenericOutsideClickListener";
import { AddMaintenanceMessageModal } from "../AddMaintenanceMessageModal";
import { RightsManagement } from "../../config/rightsManagement";
import { RightsWrapper } from "./RightsWrapper";
import UserGroupContext from "../context/UserGroupContext";
import { MaintenanceScheduleContext } from "../context/provider/MaintenanceScheduleContext";
import { CompleteMaintenanceMessageModal } from "../CompleteMaintenanceMessageModal";
import ShowReleaseNotesModal from "./ShowReleaseNotes";

const styles = {
    header: {
        display: "flex",
        justifyContent: "flex-end",
        height: "4rem",
        boxShadow: "rgb(0 0 0 / 24%) 0px 0.25rem 0.5rem 0.063rem",
        fontFamily: "GroupUI, sans-serif",
        width: "calc(100vw - 20rem)",
        position: "fixed",
        zIndex: "600",
        backgroundColor: "white",
    } as React.CSSProperties,
    headerIconOnly: {
        display: "flex",
        flexDirection: "row" as const,
        justifyContent: "space-between",
        height: "4rem",
        boxShadow: "rgb(0 0 0 / 24%) 0px 0.25rem 0.5rem 0.063rem",
        fontFamily: "GroupUI, sans-serif",
        width: "calc(100vw - 4.5rem)",
        position: "fixed",
        zIndex: "600",
        backgroundColor: "white",
    } as React.CSSProperties,
    avatarContainer: {
        padding: "var(--groupui-sys-spacing-0) var(--groupui-sys-spacing-1000)",
        alignItems: "center",
        alignSelf: "center",
        height: "4rem",
        display: "flex",
    } as React.CSSProperties,
    avatar: {
        borderColor: "var(--groupui-sys-color-action-basic-default)",
        backgroundColor: "white",
    } as React.CSSProperties,
    topBarEndContainer: {
        display: "flex",
        alignItems: "center",
        whiteSpace: "nowrap",
        paddingRight: "var(--groupui-sys-spacing-400)",
    } as React.CSSProperties,
    icon: {
        color: "var(--groupui-sys-color-action-basic-default)",
        cursor: "pointer",
    },
};

interface TopBarProps {
    iconOnly?: boolean;
}

/**
 * Returns an TopBar Component, which contains a Circular Avatar with management options on the right side
 * Some options are handle the logout of an user or change the role for testing purpose
 *
 *  @param {boolean} iconOnly - given to PortalHeader Component in order to change appearance of the icon in the top left corner
 *
 * @returns TopBar Component
 *
 */
const TopBar: React.FC<TopBarProps> = ({ iconOnly = false }) => {
    const { instance } = useMsal();
    const { userGroups } = useContext(UserGroupContext);
    const { currentMaintenanceMessage } = useContext(MaintenanceScheduleContext);

    // Menu dropdowns
    const [showAboutDropDown, setShowAboutDropDown] = useState<boolean>(false);
    const [showLogoutDropDown, setShowLogoutDropDown] = useState<boolean>(false);

    // Modals
    const [showRaiseTicketModal, setShowRaiseTicketModal] = useState<boolean>(false);
    const [showAboutModal, setShowAboutModal] = useState<boolean>(false);
    const [showAddMaintenanceMessageModal, setShowAddMaintenanceMessageModal] =
        useState<boolean>(false);
    const [showCompleteMaintenanceMessageModal, setShowCompleteMaintenanceMessageModal] =
        useState<boolean>(false);
    const [showReleaseNotesModal, setShowReleaseNotesModal] = useState<boolean>(false);

    const handleLogout = () => {
        instance.logoutRedirect().catch((e) => {
            console.log(e);
        });
    };

    return (
        <div style={iconOnly ? styles.headerIconOnly : styles.header} data-testid="header">
            <RaiseTicketModal showModal={showRaiseTicketModal} setModal={setShowRaiseTicketModal} />
            <ShowAboutModal showModal={showAboutModal} setModal={setShowAboutModal} />
            <ShowReleaseNotesModal
                showModal={showReleaseNotesModal}
                setModal={setShowReleaseNotesModal}
            />
            <AddMaintenanceMessageModal
                showModal={showAddMaintenanceMessageModal}
                setModal={setShowAddMaintenanceMessageModal}
            />
            <CompleteMaintenanceMessageModal
                showModal={showCompleteMaintenanceMessageModal}
                setModal={setShowCompleteMaintenanceMessageModal}
            />
            {iconOnly && <PortalHeader iconOnly={iconOnly} />}
            <div style={styles.topBarEndContainer}>
                <GroupuiActionArea>
                    <OutsideClickHandler onOutsideClick={() => setShowAboutDropDown(false)}>
                        <GroupuiActionAreaPopoverItem
                            onClick={() => {
                                setShowAboutDropDown(!showAboutDropDown);
                            }}
                            visible={showAboutDropDown}
                        >
                            <GroupuiIcon slot="parent" name="help-32" style={styles.icon} />
                        </GroupuiActionAreaPopoverItem>
                        <div
                            style={{
                                display: showAboutDropDown ? "flex" : "none",
                                flexDirection: "column",
                                gap: "var(--groupui-sys-spacing-50)",
                                position: "absolute",
                                right: "7.5rem",
                                zIndex: "5",
                                paddingTop: "var(--groupui-sys-spacing-100)",
                            }}
                            onClick={() => setShowAboutDropDown(false)}
                            onKeyDown={() => setShowAboutDropDown(false)}
                            tabIndex={0}
                            role="button"
                        >
                            <GroupuiButton
                                slot="content"
                                icon="info-32"
                                variant="primary"
                                onClick={() => {
                                    setShowAboutModal(true);
                                    setShowAboutDropDown(false);
                                }}
                            >
                                About
                            </GroupuiButton>
                            <GroupuiButton
                                slot="content"
                                icon="info-32"
                                variant="primary"
                                onClick={() => {
                                    setShowReleaseNotesModal(true);
                                }}
                            >
                                Release Notes
                            </GroupuiButton>
                        </div>
                    </OutsideClickHandler>

                    <GroupuiActionAreaPopoverItem
                        onClick={() => {
                            setShowRaiseTicketModal(true);
                        }}
                    >
                        <GroupuiTooltip slot="parent" position="left">
                            <GroupuiIcon
                                name="ticket-32"
                                style={styles.icon}
                                onClick={() => {
                                    setShowRaiseTicketModal(true);
                                }}
                            />
                            <div slot="tooltip">Raise Ticket</div>
                        </GroupuiTooltip>
                    </GroupuiActionAreaPopoverItem>
                    <OutsideClickHandler onOutsideClick={() => setShowLogoutDropDown(false)}>
                        <GroupuiActionAreaPopoverItem
                            onClick={() => {
                                setShowLogoutDropDown(!showLogoutDropDown);
                            }}
                            visible={showLogoutDropDown}
                        >
                            <GroupuiIcon slot="parent" name="user-32" style={styles.icon} />
                        </GroupuiActionAreaPopoverItem>
                        <div
                            style={{
                                display: showLogoutDropDown ? "flex" : "none",
                                flexDirection: "column",
                                gap: "var(--groupui-sys-spacing-50)",
                                position: "absolute",
                                right: "1.1rem",
                                zIndex: "5",
                                paddingTop: "var(--groupui-sys-spacing-100)",
                            }}
                            onClick={() => setShowLogoutDropDown(false)}
                            onKeyDown={() => setShowLogoutDropDown(false)}
                            tabIndex={0}
                            role="button"
                        >
                            <RightsWrapper
                                authorizedUserGroup={RightsManagement.MAINTENANCE_MESSAGE}
                                userGroups={userGroups}
                                key={"Option-1-RW"}
                            >
                                <div
                                    style={{
                                        display: showLogoutDropDown ? "flex" : "none",
                                        flexDirection: "column",
                                        gap: "var(--groupui-sys-spacing-50)",
                                    }}
                                >
                                    <GroupuiButton
                                        slot="content"
                                        variant="primary"
                                        fullwidth={true}
                                        onClick={() => setShowAddMaintenanceMessageModal(true)}
                                    >
                                        {"Add Maintenance Message"}
                                    </GroupuiButton>
                                    {currentMaintenanceMessage && (
                                        <GroupuiButton
                                            slot="content"
                                            variant="primary"
                                            fullwidth={true}
                                            onClick={() =>
                                                setShowCompleteMaintenanceMessageModal(true)
                                            }
                                        >
                                            {"Complete Maintenance Message"}
                                        </GroupuiButton>
                                    )}
                                </div>
                            </RightsWrapper>
                            <GroupuiButton
                                slot="content"
                                variant="primary"
                                fullwidth={true}
                                onClick={() => handleLogout()}
                            >
                                Logout
                            </GroupuiButton>
                        </div>
                    </OutsideClickHandler>

                    {/* Hover effect is defined in App.css */}
                </GroupuiActionArea>
            </div>
        </div>
    );
};

export default TopBar;
