import { GroupuiButton, GroupuiModal, GroupuiText } from "@group-ui/group-ui-react";
import React, { Dispatch } from "react";
import GenericHeadline from "../../generics/GenericHeadline";
import { Address } from "../../../models/address";
import AddressComparisonTable from "./AddressComparisonTable";

interface Props {
    showModal: boolean;
    setModal: (arg0: boolean) => void;
    onConfirm: () => void;
    address: Address;
    suggestedAddress: Address | undefined;
    setSuggestedAddress: Dispatch<React.SetStateAction<Address | undefined>>;
}

const AddressValidationModal: React.FC<Props> = ({
    showModal,
    setModal,
    onConfirm,
    address,
    suggestedAddress,
    setSuggestedAddress,
}) => {
    return (
        <GroupuiModal
            closeButton={true}
            padding="30px"
            maxWidth="1000px"
            overflow={"visible"}
            displayed={showModal}
            style={{
                justifyContent: "center",
                alignItems: "center",
            }}
            onGroupuiModalClose={() => {
                setModal(false);
                setSuggestedAddress(undefined);
            }}
        >
            <div>
                <GenericHeadline
                    title="Delivery Address Validation"
                    actionButtons={[]}
                    titleHeadingType="h4"
                    type="modal"
                />
                <div>
                    <GroupuiText>
                        Do you want to accept following <b>changes</b> to your <br />
                        entered delivery address?
                    </GroupuiText>
                    <br />
                    <AddressComparisonTable address={address} suggestedAddress={suggestedAddress} />
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "var(--groupui-sys-spacing-400)",
                        marginTop: "var(--groupui-sys-spacing-600)",
                    }}
                >
                    <GroupuiButton variant={"secondary"} onClick={() => setModal(false)}>
                        Cancel
                    </GroupuiButton>
                    <GroupuiButton icon="shopping-cart-32" onClick={onConfirm}>
                        Accept and Order
                    </GroupuiButton>
                </div>
            </div>
        </GroupuiModal>
    );
};

export default AddressValidationModal;
