import {
    GroupuiCard,
    GroupuiHeadline,
    GroupuiLoadingSpinner,
    GroupuiText,
} from "@group-ui/group-ui-react/dist/components";
import React, { useContext, useEffect, useState } from "react";
import { ApiService } from "../../../services/apiService";
import ErrorContext from "../../context/ErrorContext";
import { handleError } from "../../context/ErrorContextUtils";
import GenericTable, { columnDefinition } from "../../generics/GenericTable";
import GenericTag from "../../generics/GenericTag";
import SusLocationSelector from "../susMonitor/SusLocationSelector";
import { SusNameAndId } from "../../../models/SusNameAndId";
import { SusHubMonitorData } from "../../../models/SusHubMonitorData";

const styles = {
    container: {
        marginBottom: "1.5rem",
    },
    title: {
        marginBottom: "1.5rem",
        display: "flex",
        flexDirection: "row" as const,
        justifyContent: "space-between",
        marginTop: "1rem",
    },
    susLocationSelector: {
        width: "300px",
    },
    noLocationSelected: {
        textAlign: "center",
        color: "var(--groupui-vwag-color-grey-600)",
        weight: "bold",
        size: "body-1",
        margin: "1.5rem",
    },
};
const columns: columnDefinition[] = [
    {
        key: "name",
        header: "Name",
        width: "auto",
    },
    {
        key: "state",
        header: "Current State",
        width: "auto",
    },
];

interface SusHubMonitorProps {
    lastUpdated: string;
}

/**
 * A SusHubMonitor Component, which displays the table with status of the sus hubs
 *
 * @param {() => void} updateTimestamp - function to be run to update the timestamp in the parent component
 *
 * @returns SusHubMonitor Component
 *
 */
const SusHubMonitor: React.FC<SusHubMonitorProps> = ({ lastUpdated }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [susLocation, setSusLocation] = useState<SusNameAndId | undefined>(undefined);
    const [data, setData] = useState<SusHubMonitorData[]>();
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);

    useEffect(() => {
        setIsLoading(true);
        const fetchData = susLocation
            ? ApiService.getMonitorDataForSusHubsByLocation(susLocation.id)
            : ApiService.getAllMonitorDataForSusHubs();

        fetchData
            .then((data) => {
                setData(data);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [susLocation, lastUpdated]);

    return (
        <>
            <GroupuiCard elevation="1" style={styles.container}>
                <GroupuiLoadingSpinner displayed={isLoading} />
                <div style={styles.title}>
                    <GroupuiHeadline heading={"h4"}>
                        Self Upload Station Hubs Status
                    </GroupuiHeadline>
                    <div style={styles.susLocationSelector}>
                        <SusLocationSelector
                            isLoading={setIsLoading}
                            value={susLocation}
                            onChange={setSusLocation}
                            localStorageKey="dashboard-susHubMonitor"
                        />
                    </div>
                </div>
                {data !== undefined && (
                    <div className="table-live-demo container-border">
                        <GenericTable
                            pageSize={10}
                            columns={columns}
                            data={data.map((e) => ({
                                name: e.name,
                                state: (
                                    <GenericTag
                                        text={e.state.mediaStateType}
                                        variant="primary"
                                        mediaStateTarget="ingest-slot"
                                    />
                                ),
                            }))}
                            header={true}
                        />
                    </div>
                )}
                {!data && (
                    <div>
                        <GroupuiText weight="bold" style={styles.noLocationSelected}>
                            Please selected a location.
                        </GroupuiText>
                    </div>
                )}
            </GroupuiCard>
        </>
    );
};

export default SusHubMonitor;
