import { Address } from "./address";
import { Contact } from "./contact";
import { IngestSlot } from "./ingestSlot/ingestSlot";
import { IngestStationType } from "./ingestStationType";

export class IngestStation {
    private _id;
    public name;
    public address;
    public ingestSlots;
    public ingestStationContacts;
    public ingestStationCountries;
    public ingestStationtype;
    public deliveryProvider;
    public uploadSpeed;
    public smartHands;
    public logisticService;
    public ingestSlotsCount;
    public softDeleted;

    constructor({
        id,
        name,
        address,
        ingestSlots,
        ingestStationContacts,
        ingestStationCountries,
        ingestStationtype,
        deliveryProvider,
        uploadSpeed,
        smartHands,
        logisticService,
        ingestSlotsCount,
        softDeleted,
    }: {
        id: number;
        name: string;
        address: Address | null;
        ingestSlots?: IngestSlot[] | null;
        ingestStationContacts?: Contact[] | null;
        ingestStationCountries?: string | null;
        ingestStationtype: IngestStationType | null;
        deliveryProvider?: string | null;
        uploadSpeed?: string | null;
        smartHands: boolean;
        logisticService: boolean;
        ingestSlotsCount: number;
        softDeleted: boolean;
    }) {
        this._id = id;
        this.name = name;
        this.address = address;
        this.ingestSlots = ingestSlots;
        this.ingestStationContacts = ingestStationContacts;
        this.ingestStationCountries = ingestStationCountries;
        this.ingestStationtype = ingestStationtype;
        this.deliveryProvider = deliveryProvider;
        this.uploadSpeed = uploadSpeed;
        this.smartHands = smartHands;
        this.logisticService = logisticService;
        this.ingestSlotsCount = ingestSlotsCount;
        this.softDeleted = softDeleted;
    }

    public get id() {
        return this._id;
    }

    private toObject() {
        return {
            id: this._id,
            name: this.name,
            address: this.address,
            ingestSlots: this.ingestSlots,
            ingestStationContacts: this.ingestStationContacts,
            ingestStationCountries: this.ingestStationCountries,
            ingestStationtype: this.ingestStationtype,
            deliveryProvider: this.deliveryProvider,
            uploadSpeed: this.uploadSpeed,
            smartHands: this.smartHands,
            logisticService: this.logisticService,
            softDeleted: this.softDeleted,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): IngestStation | null {
        if (json == null) {
            return null;
        }
        return new IngestStation({
            id: json["id"],
            name: json["name"],
            address: Address.fromSerialized(json["address"]),
            ingestSlots: json["ingestSlots"]?.map((ingestSlot: any) =>
                IngestSlot.fromSerialized(ingestSlot)
            ),
            ingestStationContacts: json["ingestStationContacts"]?.map((contact: any) =>
                Contact.fromSerialized(contact["contact"])
            ),
            ingestStationCountries: json["ingestStationCountries"],
            ingestStationtype: IngestStationType.fromSerialized(json["ingestStationType"]),
            deliveryProvider: json["deliveryProvider"],
            uploadSpeed: json["uploadSpeed"],
            smartHands: json["smartHands"],
            logisticService: json["logisticService"],
            ingestSlotsCount: json["ingestSlotsCount"],
            softDeleted: json["softDeleted"],
        });
    }
}
