import {
    GroupuiLoadingSpinner,
    GroupuiCard,
    GroupuiText,
    GroupuiButton,
} from "@group-ui/group-ui-react";
import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import GenericHeadline from "../generics/GenericHeadline";
import AddCommentModal from "./modals/AddCommentModal";
import { parseFullDate } from "../../utils/DateUtil";
import { RightsWrapper } from "../container/RightsWrapper";
import UserGroupContext from "../context/UserGroupContext";
import { RightsManagement } from "../../config/rightsManagement";
import { InCarMountComment } from "../../models/inCarMountsComments";

interface InCarMountsCommentsProps {
    comments: InCarMountComment[] | undefined;
}

const InCarMountsComments: React.FC<InCarMountsCommentsProps> = ({ comments }) => {
    const { inCarMountID } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const { userGroups } = useContext(UserGroupContext);

    return (
        <div>
            <GroupuiLoadingSpinner displayed={isLoading} />
            <RightsWrapper
                key={"first"}
                authorizedUserGroup={RightsManagement.INCARMOUNTS_ROUTES}
                userGroups={userGroups}
            >
                <AddCommentModal
                    modal={modal}
                    inCarMount={Number(inCarMountID)}
                    setModal={setModal}
                />
            </RightsWrapper>
            <GenericHeadline
                title={"Comments"}
                titleHeadingType="h4"
                actionButtons={[
                    <RightsWrapper
                        key={"first"}
                        authorizedUserGroup={RightsManagement.INCARMOUNTS_ROUTES}
                        userGroups={userGroups}
                    >
                        <GroupuiButton
                            key={"first"}
                            icon="user-comment-32"
                            fullwidth={true}
                            alignment="left"
                            onClick={() => {
                                setModal(true);
                            }}
                        >
                            Add Comment
                        </GroupuiButton>
                    </RightsWrapper>,
                ]}
            />
            <div>
                {comments == undefined || comments.length < 1 ? (
                    <GroupuiCard
                        elevation="4"
                        style={{
                            margin: "0 var(--groupui-sys-spacing-50) var(--groupui-sys-spacing-500) var(--groupui-sys-spacing-50) ",
                        }}
                    >
                        <GroupuiText
                            weight="bold"
                            style={{
                                textAlign: "center",
                                color: "var(--groupui-vwag-color-grey-600)",
                                weight: "bold",
                                size: "body-1",
                                margin: "var(--groupui-sys-spacing-600)",
                                justifyContent: "center",
                            }}
                        >
                            {"No comments were made for this in car mount."}
                        </GroupuiText>
                    </GroupuiCard>
                ) : (
                    comments
                        ?.sort((a: any, b: any) => {
                            return Date.parse(b["created"]) - Date.parse(a["created"]);
                        })
                        .map((comment, index) => (
                            <GroupuiCard
                                elevation="2"
                                key={index}
                                style={{
                                    margin: "0 var(--groupui-sys-spacing-50) var(--groupui-sys-spacing-500) var(--groupui-sys-spacing-50) ",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "var(--groupui-sys-spacing-300)",
                                    }}
                                >
                                    <GroupuiText weight={"bold"} size={"body-1"}>
                                        {comment.userName}
                                    </GroupuiText>
                                    <GroupuiText size={"body-1"}>
                                        {parseFullDate(comment.created)}
                                    </GroupuiText>
                                </div>
                                {comment.comment}
                            </GroupuiCard>
                        ))
                )}
            </div>
        </div>
    );
};

export default InCarMountsComments;
