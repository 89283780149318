import React from "react";
import BookingDetails from "../BookingDetails";
import { IngestSlotMonitorData } from "../../../models/ingestSlotMonitorData";
import IngestDetails from "../IngestDetails";
interface Props {
    ingestSlotMonitorData: IngestSlotMonitorData;
    showDetails: boolean;
    onClose: () => void;
    calledFormIngestJob?: boolean;
}

const DashboardDetails: React.FC<Props> = ({
    ingestSlotMonitorData,
    showDetails,
    onClose,
    calledFormIngestJob = false,
}) => {
    return (
        <div>
            {(calledFormIngestJob &&
                ingestSlotMonitorData.databoxMediaState.toLowerCase() === "upload completed") ||
            ingestSlotMonitorData?.state.toLowerCase() === "uploading" ||
            ingestSlotMonitorData?.state.toLowerCase() === "pre-upload" ||
            ingestSlotMonitorData?.state.toLowerCase() === "upload" ||
            ingestSlotMonitorData?.state.toLowerCase() === "error"
                ? ingestSlotMonitorData && (
                      <IngestDetails
                          showDetails={showDetails}
                          onClose={onClose}
                          ingestSlotMonitorData={ingestSlotMonitorData}
                      />
                  )
                : ingestSlotMonitorData && (
                      <BookingDetails
                          showDetails={showDetails}
                          onClose={onClose}
                          ingestSlotMonitorData={ingestSlotMonitorData}
                      />
                  )}
        </div>
    );
};

export default DashboardDetails;
