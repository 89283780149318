export class SwitchJobRequest {
    constructor(
        public ingestSlotId: number,
        public orderId: number,
        public ingestSlotTargetState: string,
        public jiraTicketId: string
    ) {}

    private toObject() {
        return {
            ingestSlotId: this.ingestSlotId,
            orderId: this.orderId,
            ingestSlotTargetState: this.ingestSlotTargetState,
            jiraTicketId: this.jiraTicketId,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }
}
