import { Databox } from "./databox";

export class DataboxResponse {
    public error;
    public databox60TBCount;
    public databox90TBCount;
    public databox120TBCount;
    public databoxes;

    constructor({
        error,
        databox60TBCount,
        databox90TBCount,
        databox120TBCount,
        databoxes,
    }: {
        error?: string;
        databox60TBCount?: number;
        databox90TBCount?: number;
        databox120TBCount?: number;
        databoxes?: Databox[];
    }) {
        this.error = error;
        this.databox60TBCount = databox60TBCount;
        this.databox90TBCount = databox90TBCount;
        this.databox120TBCount = databox120TBCount;
        this.databoxes = databoxes;
    }

    private toObject() {
        return {
            error: this.error,
            databox60TBCount: this.databox60TBCount,
            databox90TBCount: this.databox90TBCount,
            databox120TBCount: this.databox120TBCount,
            databoxes: this.databoxes,
        };
    }

    static fromSerialized(json: object): DataboxResponse {
        if (json == null) {
            throw new Error("Something went wrong in the Databox Response mapping (json == null).");
        }
        return new DataboxResponse({
            error: json["error"],
            databox60TBCount: json["databox60TBCount"],
            databox90TBCount: json["databox90TBCount"],
            databox120TBCount: json["databox120TBCount"],
            databoxes: json["databoxes"]?.map((databox: any) => Databox.fromSerialized(databox)),
        });
    }
}
