import React from "react";
import { Order } from "../../models/order";
import GenericHeadline from "../generics/GenericHeadline";
import GenericInput from "../generics/GenericInput";
interface Props {
    order: Order;
    disabled?: boolean;
}

const AccessPointAddress: React.FC<Props> = ({ order, disabled = true }) => {
    return (
        <div>
            <GenericHeadline
                title={"Access Point Address"}
                titleHeadingType="h4"
                actionButtons={[]}
            />
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "40px",
                }}
            >
                <div style={{ width: "50%" }}>
                    <GenericInput
                        label={"Address Line 1"}
                        disabled={disabled}
                        value={order.packageDropOff?.addressLine1}
                    />
                    <GenericInput
                        label={"Address Line 2"}
                        disabled={disabled}
                        value={order.packageDropOff?.addressLine2}
                    />
                    <GenericInput
                        label={"ZIP Code"}
                        disabled={disabled}
                        value={order.packageDropOff?.zipCode}
                    />
                </div>

                <div style={{ width: "50%" }}>
                    <GenericInput
                        label={"City"}
                        disabled={disabled}
                        value={order.packageDropOff?.city}
                    />
                    <GenericInput
                        label={"State"}
                        disabled={disabled}
                        value={order.packageDropOff?.state}
                    />
                    <GenericInput
                        label={"Country"}
                        disabled={disabled}
                        value={order.packageDropOff?.country.country}
                    />
                </div>
            </div>
        </div>
    );
};

export default AccessPointAddress;
