import { Address } from "./address";
import { Databox } from "./databox";
import { IngestJob } from "./ingestJob";
import { PackageDropOff } from "./packageDropOff/packageDropOff";
import { ShipmentJob } from "./shipmentJob";
import { UpsPickup } from "./upsPickup";
import { UpsShipmentLabel } from "./upsShipmentLabel";

export class Order {
    private _id;
    public address;
    public databox;
    public created;
    public updated;
    private _azureUserId;
    public upsShipmentLabel;
    public upsReturnShipmentLabel;
    public packageDropOff;
    public shipmentJob;
    public ingestJob;
    public completed;
    public upsPickup;
    public inboundTicketId;
    public cancelled;
    public reasonForTermination;

    constructor({
        id,
        address,
        databox,
        created,
        updated,
        azureUserId,
        upsShipmentLabel,
        upsReturnShipmentLabel,
        packageDropOff,
        shipmentJob,
        ingestJob,
        completed,
        upsPickup,
        inboundTicketId,
        cancelled,
        reasonForTermination,
    }: {
        id: number;
        address: Address | null;
        databox: Databox;
        created: string;
        updated: string | null;
        azureUserId: string;
        upsShipmentLabel?: UpsShipmentLabel | null;
        upsReturnShipmentLabel?: UpsShipmentLabel | null;
        packageDropOff?: PackageDropOff | null;
        shipmentJob?: ShipmentJob | null;
        ingestJob?: IngestJob | null;
        completed: boolean;
        upsPickup: UpsPickup | null;
        inboundTicketId: string | null;
        cancelled: string | null;
        reasonForTermination: string | null;
    }) {
        this._id = id;
        this.address = address;
        this.databox = databox;
        this.created = created;
        this.updated = updated;
        this._azureUserId = azureUserId;
        this.upsShipmentLabel = upsShipmentLabel;
        this.upsReturnShipmentLabel = upsReturnShipmentLabel;
        this.packageDropOff = packageDropOff;
        this.shipmentJob = shipmentJob;
        this.ingestJob = ingestJob;
        this.completed = completed;
        this.upsPickup = upsPickup;
        this.inboundTicketId = inboundTicketId;
        this.cancelled = cancelled;
        this.reasonForTermination = reasonForTermination;
    }

    public get id() {
        return this._id;
    }

    public get displayName() {
        return `OR${this._id}`;
    }
    public get azureUserId() {
        return this._azureUserId;
    }

    public get currentMediaState() {
        return !this.completed
            ? this.databox.mediaStateType.mediaStateType
            : this.cancelled && this.reasonForTermination
            ? "Order Cancelled"
            : "Order Completed";
    }

    private toObject() {
        return {
            id: this._id,
            address: this.address,
            databox: this.databox,
            created: this.created,
            updated: this.updated,
            azureUserId: this.azureUserId,
            upsShipmentLabel: this.upsShipmentLabel,
            upsReturnShipmentLabel: this.upsReturnShipmentLabel,
            packageDropOff: this.packageDropOff,
            shipmentJob: this.shipmentJob,
            completed: this.completed,
            upsPickup: this.upsPickup,
            inboundTicketId: this.inboundTicketId,
            cancelled: this.cancelled,
            reasonForTermination: this.reasonForTermination,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): Order {
        if (json == null) {
            throw new Error(`Error parsing Order!`);
        }

        return new Order({
            id: json["id"],
            address: Address.fromSerialized(json["address"]),
            databox: Databox.fromSerialized(json["databox"]),
            created: json["created"],
            updated: json["updated"],
            azureUserId: json["azureUserId"],
            upsShipmentLabel: UpsShipmentLabel.fromSerialized(json["upsShipmentLabel"]),
            upsReturnShipmentLabel: UpsShipmentLabel.fromSerialized(json["upsReturnShipmentLabel"]),
            packageDropOff: PackageDropOff.fromSerialized(json["packageDropOff"]),
            shipmentJob: ShipmentJob.fromSerialized(json["shipmentJob"]),
            ingestJob: IngestJob.fromSerialized(json["ingestJob"]),
            completed: json["completed"],
            upsPickup: UpsPickup.fromSerialized(json["upsPickup"]),
            inboundTicketId: json["inboundTicketId"],
            cancelled: json["cancelled"],
            reasonForTermination: json["reasonForTermination"],
        });
    }
}
