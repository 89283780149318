import React, { useContext, useEffect, useState } from "react";
import { GroupuiHeadline, GroupuiButton, GroupuiText } from "@group-ui/group-ui-react";
import { RightsManagement } from "../../../config/rightsManagement";
import { parseFullDate } from "../../../utils/DateUtil";
import { RightsWrapper } from "../../container/RightsWrapper";
import OverwriteErrorModal from "./modals/OverwriteErrorModal";
import UserGroupContext from "../../context/UserGroupContext";
import ResetOverwriteModal from "./modals/ResetOverwriteModal";
import GenericMoreButton from "../../generics/GenericMoreButton";
import AddDetailsMessageModal from "./modals/AddDetailsMessageModal";
import DeleteDetailsMessageModal from "./modals/DeleteDetailsMessageModal";
import {
    SwitchIngestSlotModal,
    getVisibilityConditionSwitchIngestSlot,
} from "../../generics/modals/SwitchIngestSlotModal";
import CancelIngestModal from "./modals/CancelIngestModal";
import { IngestSlotMonitorData } from "../../../models/ingestSlotMonitorData";
import AbortIngestModal from "./modals/AbortIngestModal";

interface Props {
    ingestSlotMonitorData: IngestSlotMonitorData;
}

/**
 * A DashboardDetailsHeadline Component, which holds the headline as well as the all other functions in the top bar of the modal
 *
 * @param {IngestSlotMonitorData} ingestSlotMonitorData - holds the information to be displayed in the details
 *
 * @returns DashboardDetailsHeadline Component
 *
 */
const DashboardDetailsHeadline: React.FC<Props> = ({ ingestSlotMonitorData }) => {
    const { userGroups } = useContext(UserGroupContext);

    const [showOverwriteModal, setOverwriteModal] = useState<boolean>(false);
    const [showResetOverwriteModal, setResetOverwriteModal] = useState<boolean>(false);

    const [showAddDetailsMessageModal, setShowAddDetailsMessageModal] = useState<boolean>(false);
    const [showResetDetailsMessageModal, setShowResetDetailsMessageModal] =
        useState<boolean>(false);
    const [showAbortIngestModal, setShowAbortIngestModal] = useState<boolean>(false);
    const [showSwitchIngestSlotModal, setShowSwitchIngestSlotModal] = useState<boolean>(false);
    const [showCancelIngestModal, setShowCancelIngestModal] = useState<boolean>(false);

    const [lastUpdated, setLastUpdated] = useState<string>("");
    const [, setRefresh] = useState<boolean>();

    const [dropDownState, setDropDownState] = useState(false);

    useEffect(() => {
        updateTimestamp();
    }, []);

    const updateTimestamp = () => {
        const timestamp = Date.now();
        setLastUpdated(
            "Last Update: " +
                new Intl.DateTimeFormat("de-DE", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                }).format(timestamp)
        );
        setTimeout(() => {
            setRefresh(false);
        }, 1000);
    };

    return (
        <div>
            {showOverwriteModal && (
                <OverwriteErrorModal
                    modal={showOverwriteModal}
                    setModal={setOverwriteModal}
                    ingestSlotMonitorData={ingestSlotMonitorData}
                />
            )}
            {showResetOverwriteModal && (
                <ResetOverwriteModal
                    modal={showResetOverwriteModal}
                    setModal={setResetOverwriteModal}
                    ingestSlotMonitorData={ingestSlotMonitorData}
                />
            )}
            {showAddDetailsMessageModal && (
                <AddDetailsMessageModal
                    modal={showAddDetailsMessageModal}
                    setModal={setShowAddDetailsMessageModal}
                    ingestSlotMonitorData={ingestSlotMonitorData}
                    mode={ingestSlotMonitorData?.detailsMessage ? "Change" : "Add"}
                />
            )}
            {showResetDetailsMessageModal && (
                <DeleteDetailsMessageModal
                    modal={showResetDetailsMessageModal}
                    setModal={setShowResetDetailsMessageModal}
                    ingestSlotMonitorData={ingestSlotMonitorData}
                />
            )}
            {showAbortIngestModal && (
                <AbortIngestModal
                    showModal={showAbortIngestModal}
                    setShowModal={setShowAbortIngestModal}
                    ingestSlotMonitorData={ingestSlotMonitorData}
                />
            )}
            {showCancelIngestModal && (
                <CancelIngestModal
                    showModal={showCancelIngestModal}
                    setShowModal={setShowCancelIngestModal}
                    ingestSlotMonitorData={ingestSlotMonitorData}
                />
            )}
            {showSwitchIngestSlotModal && (
                <SwitchIngestSlotModal
                    modal={showSwitchIngestSlotModal}
                    setModal={setShowSwitchIngestSlotModal}
                    ingestStationId={ingestSlotMonitorData?.ingestStationId ?? -1}
                    orderId={ingestSlotMonitorData?.orderId ?? -1}
                />
            )}
            <div
                style={{
                    display: "flex",
                    width: "67rem",
                    gap: "var(--groupui-sys-spacing-400)",
                    alignItems: "center",
                    marginBottom: "var(--groupui-sys-spacing-150)",
                }}
            >
                <GroupuiHeadline
                    style={{
                        color: "var(--groupui-sys-color-information-basic-default)",
                        width: "50rem",
                    }}
                    heading="h4"
                >
                    Status Details
                </GroupuiHeadline>

                <RightsWrapper
                    authorizedUserGroup={RightsManagement.OVERWRITE_ERROR}
                    userGroups={userGroups}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "var(--groupui-sys-spacing-400)",
                        }}
                    >
                        {
                            <GenericMoreButton
                                dropDownState={dropDownState}
                                setDropDownState={() => {
                                    setDropDownState(!dropDownState);
                                }}
                                options={[
                                    ingestSlotMonitorData?.state === "Error" && (
                                        <GroupuiButton
                                            key={"c1"}
                                            icon="edit-24"
                                            variant="seconday"
                                            fullwidth={true}
                                            onClick={() => {
                                                setDropDownState(false);
                                                setOverwriteModal(true);
                                            }}
                                        >
                                            {ingestSlotMonitorData?.overwriteState !== undefined &&
                                            ingestSlotMonitorData?.overwrittenSince !== undefined
                                                ? "Change "
                                                : ""}
                                            Overwrite Error
                                        </GroupuiButton>
                                    ),
                                    ingestSlotMonitorData?.overwrittenSince != undefined &&
                                        ingestSlotMonitorData.overwriteState != undefined &&
                                        ingestSlotMonitorData?.state === "Error" && (
                                            <GroupuiButton
                                                key={"c2"}
                                                icon="reload-24"
                                                variant="seconday"
                                                fullwidth={true}
                                                onClick={() => {
                                                    setDropDownState(false);
                                                    setResetOverwriteModal(true);
                                                }}
                                            >
                                                Reset Overwrite State
                                            </GroupuiButton>
                                        ),
                                    ingestSlotMonitorData?.state === "Error" && (
                                        <GroupuiButton
                                            key={"c3"}
                                            icon="add-24"
                                            variant="seconday"
                                            fullwidth={true}
                                            onClick={() => {
                                                setDropDownState(false);
                                                setShowAddDetailsMessageModal(true);
                                            }}
                                        >
                                            {ingestSlotMonitorData?.detailsMessage
                                                ? "Change"
                                                : "Add"}{" "}
                                            Details Message
                                        </GroupuiButton>
                                    ),
                                    ingestSlotMonitorData?.detailsMessage !== null &&
                                        ingestSlotMonitorData?.detailsMessage !== undefined &&
                                        ingestSlotMonitorData?.state === "Error" && (
                                            <GroupuiButton
                                                key={"c4"}
                                                icon="trash-24"
                                                variant="seconday"
                                                fullwidth={true}
                                                onClick={() => {
                                                    setDropDownState(false);
                                                    setShowResetDetailsMessageModal(true);
                                                }}
                                            >
                                                Delete Details Message
                                            </GroupuiButton>
                                        ),
                                    ingestSlotMonitorData?.state === "Error" && (
                                        <GroupuiButton
                                            key={"c5"}
                                            icon="trash-24"
                                            variant="seconday"
                                            fullwidth={true}
                                            onClick={() => {
                                                setDropDownState(false);
                                                setShowAbortIngestModal(true);
                                            }}
                                        >
                                            Remove Error Status
                                        </GroupuiButton>
                                    ),
                                    // Can be assumed that the order shown on the dasboard isn´t completed or cancelled
                                    <GroupuiButton
                                        key={"c6"}
                                        icon="update-24"
                                        variant="seconday"
                                        fullwidth={true}
                                        disabled={getVisibilityConditionSwitchIngestSlot(
                                            ingestSlotMonitorData?.state ?? "",
                                            ingestSlotMonitorData?.reservedBy ?? "",
                                            false,
                                            false
                                        )}
                                        onClick={() => {
                                            setDropDownState(false);
                                            setShowSwitchIngestSlotModal(true);
                                        }}
                                    >
                                        Switch Ingest Slot
                                    </GroupuiButton>,
                                    ingestSlotMonitorData?.state === "Error" &&
                                        (ingestSlotMonitorData.ingestStationName === "AM5" ||
                                            ingestSlotMonitorData.ingestStationName === "SE3") && (
                                            <GroupuiButton
                                                key={"c7"}
                                                icon="close-24"
                                                variant="seconday"
                                                fullwidth={true}
                                                onClick={() => {
                                                    setDropDownState(false);
                                                    setShowCancelIngestModal(true);
                                                }}
                                            >
                                                Cancel Ingest
                                            </GroupuiButton>
                                        ),
                                ]}
                                title={"Support functions"}
                                icon="settings-16"
                            />
                        }

                        {ingestSlotMonitorData?.overwrittenSince &&
                            ingestSlotMonitorData.overwriteState &&
                            ingestSlotMonitorData?.state === "Error" && (
                                <GroupuiText
                                    size="body-2"
                                    weight="light"
                                    marketing={false}
                                    style={{
                                        color: "var(--groupui-sys-color-action-basic-disabled)",
                                        width: "10rem",
                                    }}
                                >
                                    Overwritten since:
                                    {parseFullDate(ingestSlotMonitorData?.overwrittenSince)}
                                </GroupuiText>
                            )}
                    </div>
                </RightsWrapper>

                <div>
                    <GroupuiText
                        size="body-2"
                        weight="light"
                        marketing={false}
                        style={{
                            color: "var(--groupui-sys-color-action-basic-disabled)",
                            with: "100%",
                        }}
                    >
                        {lastUpdated}
                    </GroupuiText>
                </div>
            </div>
        </div>
    );
};

export default DashboardDetailsHeadline;
