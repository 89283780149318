import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { RightsWrapper } from "../../container/RightsWrapper";
import UserGroupContext from "../../context/UserGroupContext";
import { RightsManagement } from "../../../config/rightsManagement";
import { JobReports } from "../../report/jobReport/JobReports";

/**
 * A JobReportRoutes Component, which defines the Nested Routes nested under the parent for ingests-report-content
 *
 * @returns JobReportRoutes
 *
 */
export const JobReportRoutes = () => {
    const { userGroups } = useContext(UserGroupContext);
    return (
        <RightsWrapper
            key={"first"}
            authorizedUserGroup={RightsManagement.JOB_REPORT_ROUTES}
            userGroups={userGroups}
            section={true}
        >
            <Routes>
                <Route index element={<JobReports />} />
            </Routes>
        </RightsWrapper>
    );
};
