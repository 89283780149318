import { GroupuiSelect, GroupuiSelectOption } from "@group-ui/group-ui-react/dist/components";
import React, { useContext, useEffect, useState } from "react";
import { ApiService } from "../../services/apiService";
import ErrorContext from "../context/ErrorContext";
import { handleError } from "../context/ErrorContextUtils";
import { IngestStationNameAndId } from "../../models/ingestStationNameAndId";

interface IngestStationSelectorProps {
    value?: IngestStationNameAndId;
    onChange?: (value: IngestStationNameAndId) => void;
    localStorageKey?: string;
    isLoading?: (isLoading: boolean) => void;
}

/**
 * A IngestStationSelector Component, which contains the selector for choosing the ingest station on the dashboard
 *
 * @param {IngestStationNameAndId} [value] - holds current IngestStationNameAndId
 * @param {(value: IngestStationNameAndId) => void} [onChange] - function to be run onChange of the IngestStationNameAndId
 * @param {string} [localStorageKey] - key where the value for the last chosen IngestStationNameAndId can be found in the local-storage
 * @param {(isLoading: boolean) => void} [isLoading] - function to change isLoading value, so that the parent-components loadingSpinner is shown/not shown
 *
 * @returns IngestStationSelector Component
 *
 */
const IngestStationSelector: React.FC<IngestStationSelectorProps> = ({
    value,
    onChange,
    localStorageKey,
    isLoading,
}) => {
    const [options, setOptions] = useState<IngestStationNameAndId[]>();
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);

    useEffect(() => {
        if (options === undefined) {
            if (isLoading) isLoading(true);
            ApiService.getIngestStationNamesAndIds()
                .then(setOptions)
                .catch((error) => {
                    handleError(error, changeErrorMessage, changeShowError);
                });
            if (isLoading) isLoading(false);
        }
    }, [options]);

    useEffect(() => {
        if (localStorageKey !== undefined && options !== undefined && value === undefined) {
            const localStorageValue = window.localStorage.getItem(localStorageKey);
            if (localStorageValue !== undefined && localStorageValue !== null) {
                if (onChange !== undefined) {
                    onChange(JSON.parse(localStorageValue) as unknown as IngestStationNameAndId);
                }
            }
        }
    }, [localStorageKey, options, value]);

    return (
        <GroupuiSelect
            value={JSON.stringify(value)}
            severity="none"
            placeholder="Ingest Station"
            onGroupuiChange={(event) => {
                if (localStorageKey !== undefined) {
                    window.localStorage.setItem(localStorageKey, event.target.value);
                }
                if (onChange !== undefined) {
                    onChange(JSON.parse(event.target.value));
                }
            }}
        >
            {options?.map((element, index) => (
                <GroupuiSelectOption
                    key={`ingest-station-select-option-${index}`}
                    value={element.serialize()}
                >
                    {element.name}
                </GroupuiSelectOption>
            ))}
        </GroupuiSelect>
    );
};

export default IngestStationSelector;
