import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ApiService } from "../../../services/apiService";
import { handleError } from "../ErrorContextUtils";
import ErrorContext from "../ErrorContext";
import { InCarMount } from "../../../models/incarmount";
import { isInUserGroup } from "../../../utils/RightsUtils";
import { RightsManagement } from "../../../config/rightsManagement";
import UserGroupContext from "../../context/UserGroupContext";

interface IInCarMountContext {
    isLoading: boolean;
    inCarMounts?: InCarMount[];
    currentInCarMount?: InCarMount;
}

const defaultState = {
    isLoading: false,
    inCarMounts: undefined,
    currentInCarMount: undefined,
};

export const InCarMountContext = createContext<IInCarMountContext>(defaultState);

interface InCarMountProviderProps {
    children?: React.ReactNode;
}

export const InCarMountProvider: React.FC<InCarMountProviderProps> = ({ children }) => {
    const location = useLocation();
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);

    const [isLoading, setIsLoading] = useState(true);
    const [inCarMounts, setInCarMounts] = useState<InCarMount[]>();

    const urlPathParameter = location.pathname.split("/");
    const currentInCarMountId = urlPathParameter[urlPathParameter.length - 1];
    const { userGroups } = useContext(UserGroupContext);

    // TODO: DXCRD-11718 improve and get from endpoint if suitable
    const currentInCarMount = inCarMounts?.find(
        (inCarMount) => inCarMount.id == Number(currentInCarMountId)
    );

    useEffect(() => {
        getData();
    }, [location.pathname]);

    const getData = async () => {
        if (isInUserGroup([RightsManagement.SUPPORT], userGroups)) {
            setIsLoading(true);
            await Promise.all([
                // TODO: DXCRD-11718 improve and handle site request from e.x. InCarMounts.tsx here
                // & maybe load comments here as well
                ApiService.getAllInCarMounts()
                    .then((inCarMounts: InCarMount[]) => {
                        setInCarMounts(inCarMounts);
                    })
                    .catch((error) => {
                        handleError(error, changeErrorMessage, changeShowError);
                    }),
            ]).then(() => setIsLoading(false));
        }
        setIsLoading(true);
        await Promise.all([
            // TODO: DXCRD-11718 improve and handle site request from e.x. InCarMounts.tsx here
            // & maybe load comments here as well
            ApiService.getAllInCarMountsForCustomer()
                .then((inCarMounts: InCarMount[]) => {
                    setInCarMounts(inCarMounts);
                })
                .catch((error) => {
                    handleError(error, changeErrorMessage, changeShowError);
                }),
        ]).then(() => setIsLoading(false));
    };

    return (
        <InCarMountContext.Provider
            value={{
                isLoading,
                inCarMounts,
                currentInCarMount,
            }}
        >
            {children}
        </InCarMountContext.Provider>
    );
};
