import React, { useContext, useEffect, useRef, useState } from "react";
import { GroupuiButton, GroupuiTab, GroupuiTabs } from "@group-ui/group-ui-react";
import GenericHeadline from "../generics/GenericHeadline";
import GeneralInformationTab from "./GeneralInformationTab";
import useTitle from "../../hooks/useTitle";
import GenericMoreButton from "../generics/GenericMoreButton";
import AddStateModal from "../databoxes/modals/AddStateModal";
import InCarMountsComments from "./InCarMountsComments";
import { ApiService } from "../../services/apiService";
import { useParams } from "react-router";
import UserGroupContext from "../context/UserGroupContext";
import { RightsWrapper } from "../container/RightsWrapper";
import ErrorContext from "../context/ErrorContext";
import { handleError } from "../context/ErrorContextUtils";
import { RightsManagement } from "../../config/rightsManagement";
import { InCarMountComment } from "../../models/inCarMountsComments";
import { RefType } from "./GeneralInformationEdit";
import { InCarMountContext } from "../context/provider/InCarMountContext";

function InCarMountDetails() {
    useTitle("In Car Mount Details");
    const { inCarMountID } = useParams();
    const { currentInCarMount } = useContext(InCarMountContext);
    const [commentData, setCommentData] = useState<InCarMountComment[]>();
    const [dropDownState, setDropDownState] = useState(false);
    const [currentTabId, setCurrentTabId] = useState<string>();
    const [editMode, setEditMode] = useState(false);
    const [editGeneralInfo, setEditGeneralInfo] = useState(false);
    const [modal, setModal] = useState(false);
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const { userGroups } = useContext(UserGroupContext);

    const refEditProject = useRef<RefType>(null);

    useEffect(() => {
        ApiService.getAllCommentsForInCarMounts(Number(inCarMountID))
            .then((response) => {
                setCommentData(response);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
            });
    }, []);

    const toggleEditMode = () => {
        setEditMode(!editMode);
    };

    const toggleEditGeneralInfo = () => {
        setEditGeneralInfo(!editGeneralInfo);
    };

    const saveEditProject = () => {
        if (refEditProject.current) {
            refEditProject.current.sendChanges();
        }
    };

    return (
        <>
            <RightsWrapper
                key={"rights-first"}
                authorizedUserGroup={RightsManagement.INCARMOUNTS_ROUTES}
                userGroups={userGroups}
            >
                <AddStateModal
                    modal={modal}
                    setModal={() => setModal(true)}
                    currentObjectId={inCarMountID}
                />
            </RightsWrapper>
            <GenericHeadline
                title={
                    currentInCarMount
                        ? `In Car Mount Details - ${currentInCarMount.name}`
                        : `In Car Mount Details`
                }
                actionButtons={[
                    !editMode && !editGeneralInfo ? (
                        <GenericMoreButton
                            key={"More-Button-2"}
                            dropDownState={dropDownState}
                            setDropDownState={() => {
                                setDropDownState(!dropDownState);
                            }}
                            options={[
                                <GroupuiButton
                                    key={"c1"}
                                    icon="edit-24"
                                    variant="primary"
                                    fullwidth={true}
                                    alignment="left"
                                    onClick={() => {
                                        setEditMode(true);
                                        setDropDownState(!dropDownState);
                                    }}
                                >
                                    Edit Project/Holder
                                </GroupuiButton>,
                                <GroupuiButton
                                    key={"c2"}
                                    icon="edit-24"
                                    variant="primary"
                                    fullwidth={true}
                                    alignment="left"
                                    onClick={() => {
                                        setEditGeneralInfo(true);
                                        setDropDownState(!dropDownState);
                                    }}
                                >
                                    Edit In Car Mount
                                </GroupuiButton>,
                            ]}
                        />
                    ) : (
                        <div style={{ display: "flex", gap: "10px" }}>
                            <GroupuiButton
                                key={"c1"}
                                icon="close-24"
                                variant="tertiary"
                                alignment="left"
                                onClick={() => {
                                    editMode ? toggleEditMode() : toggleEditGeneralInfo();
                                }}
                            >
                                Cancel
                            </GroupuiButton>
                            <GroupuiButton
                                key={"c4"}
                                icon="save-24"
                                alignment="left"
                                onClick={saveEditProject}
                            >
                                Save
                            </GroupuiButton>
                        </div>
                    ),
                ]}
            />
            <GroupuiTabs
                onGroupuiTabChange={(event) => {
                    setCurrentTabId(event.detail.tab);
                    setDropDownState(false);
                }}
            >
                <GroupuiTab tab="firstTabId" name="General">
                    <GeneralInformationTab
                        editMode={editMode}
                        editGeneralInfo={editGeneralInfo}
                        refEditProject={refEditProject}
                    />
                </GroupuiTab>

                <GroupuiTab tab="secondTabId" name={`Comments (${commentData?.length ?? "0"})`}>
                    <InCarMountsComments comments={commentData} />
                </GroupuiTab>
            </GroupuiTabs>
        </>
    );
}

export default InCarMountDetails;
