import { Project } from "./project";

export class OrderProjectHistory {
    private id;
    public orderId;
    public project;
    public holderId;
    public updated;

    constructor({
        id,
        orderId,
        project,
        holderId,
        updated,
    }: {
        id: number;
        orderId: number;
        project: Project | null;
        holderId: string;
        updated: string;
    }) {
        this.id = id;
        this.orderId = orderId;
        this.project = project;
        this.holderId = holderId;
        this.updated = updated;
    }

    public get ID() {
        return this.id;
    }

    private toObject() {
        return {
            id: this.id,
            orderId: this.orderId,
            project: this.project,
            holderId: this.holderId,
            updated: this.updated,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): OrderProjectHistory | null {
        if (json == null) {
            return null;
        }

        return new OrderProjectHistory({
            id: json["id"],
            orderId: json["orderId"],
            project: Project.fromSerialized(json["project"]),
            holderId: json["holderId"],
            updated: json["updated"],
        });
    }
}
