export class IngestsStatisticsData {
    public ingestLocation: string;
    public fromDate: string;
    public toDate: string;
    public month: string;
    public ingestProceeded: number;
    public volumeUploaded: number;
    public totalIngestTimeInSeconds: number;

    constructor({
        ingestLocation,
        fromDate,
        toDate,
        month,
        ingestProceeded,
        volumeUploaded,
        totalIngestTimeInSeconds,
    }: {
        ingestLocation: string;
        fromDate: string;
        toDate: string;
        month: string;
        ingestProceeded: number;
        volumeUploaded: number;
        totalIngestTimeInSeconds: number;
    }) {
        this.ingestLocation = ingestLocation;
        this.fromDate = fromDate;
        this.toDate = toDate;
        this.month = month;
        this.ingestProceeded = ingestProceeded;
        this.volumeUploaded = volumeUploaded;
        this.totalIngestTimeInSeconds = totalIngestTimeInSeconds;
    }

    private toObject() {
        return {
            ingestLocation: this.ingestLocation,
            fromDate: this.fromDate,
            toDate: this.toDate,
            month: this.month,
            ingestProceeded: this.ingestProceeded,
            volumeUploaded: this.volumeUploaded,
            totalIngestTimeInSeconds: this.totalIngestTimeInSeconds,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: any): IngestsStatisticsData {
        if (json == null) {
            throw new Error("Error parsing Ingest Statistics Data model: null data");
        }

        return new IngestsStatisticsData({
            ingestLocation: json["ingestLocation"] || "Unknown Location",
            fromDate: json["fromDate"] || "Unknown Date",
            toDate: json["toDate"] || "Unknown Date",
            month: json["month"] || undefined,
            ingestProceeded: json["ingestProceeded"] ?? 0,
            volumeUploaded: json["volumeUploaded"] ?? 0,
            totalIngestTimeInSeconds: json["totalIngestTimeInSeconds"] ?? 0,
        });
    }
}
