import {
    GroupuiButton,
    GroupuiLoadingSpinner,
    GroupuiModal,
    GroupuiSelect,
    GroupuiSelectOption,
    GroupuiTextarea,
} from "@group-ui/group-ui-react";
import React, { useContext, useEffect, useState } from "react";
import GenericHeadline from "./generics/GenericHeadline";
import GenericInput from "./generics/GenericInput";
import { JiraTicket } from "../models/jiraTicket";
import { ApiService } from "../services/apiService";
import Snackbar from "./generics/Snackbar";
import ErrorContext from "./context/ErrorContext";
import { msalInstance } from "../config/config";
import { handleError } from "./context/ErrorContextUtils";

interface Props {
    showModal: boolean;
    setModal: (value: boolean) => void;
}

const RaiseTicketModal: React.FC<Props> = ({ showModal, setModal }) => {
    const [category, setCategory] = useState<string>("");
    const [subject, setSubject] = useState<string>("");
    const [description, setDescription] = useState<string>("");

    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
    const [severity, setSeverity] = useState<"success" | "danger">("success");
    const [message, setMessage] = useState<string>("");

    const [loading, setLoading] = useState<boolean>(false);

    const accounts = msalInstance.getAllAccounts();

    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);

    const availableCategories = [
        "Address Validation",
        "Availability",
        "Damaged Databox",
        "Data Breach",
        "Ingest Status",
        "Login",
        "Lost Databox",
        "Ordering Process",
        "Other",
    ];

    const checkInput = () => {
        if (category == "" || subject == "" || description == "") return true;
        return false;
    };

    const sendInput = () => {
        setLoading(true);

        const email = accounts[0].username;

        const jiraTicket = new JiraTicket({ category, subject, description, email });
        ApiService.raiseJiraTicket(jiraTicket)
            .then((response) => {
                if (response.startsWith("Success")) {
                    setShowSnackbar(true);
                    setMessage(
                        `Successfully raised a ticket! Ticket-ID: ${response.split("Success")[1]}`
                    );
                    setTimeout(() => {
                        setCategory("");
                        setSubject("");
                        setDescription("");
                        setLoading(false);
                        setModal(false);
                    }, 5000);
                } else {
                    setShowSnackbar(true);
                    setMessage("An error occurred during during the ticket creation!");
                    setSeverity("danger");

                    setShowSnackbar(true);
                    setLoading(false);
                }
            })
            .catch((error) => {
                setLoading(false);
                handleError(error, changeErrorMessage, changeShowError);
            });
    };

    return (
        <GroupuiModal
            displayed={showModal}
            onGroupuiModalClose={() => setModal(false)}
            overflow="visible"
            closeButton={true}
        >
            <GroupuiLoadingSpinner displayed={loading} />
            {showSnackbar && (
                <Snackbar
                    severity={severity}
                    message={message}
                    onClose={() => setShowSnackbar(false)}
                />
            )}
            <GenericHeadline
                title={"Raise Ticket"}
                titleHeadingType="h3"
                actionButtons={[]}
                type="modal"
            />
            <div
                style={{
                    display: "flex",
                    gap: "var(--groupui-sys-spacing-400)",
                    flexDirection: "column",
                }}
            >
                <GroupuiSelect
                    value={category}
                    required={true}
                    typeof="input"
                    onGroupuiChange={(event) => setCategory(event.target.value)}
                    disabled={false}
                >
                    {availableCategories.map((category) => {
                        return (
                            <GroupuiSelectOption
                                key={`${category}-option-${Math.random()}`}
                                value={String(category)}
                            >
                                {category}
                            </GroupuiSelectOption>
                        );
                    })}
                    <span slot="label">Category *</span>
                </GroupuiSelect>
                <GenericInput
                    label={`Subject *`}
                    value={subject}
                    disabled={false}
                    onGroupuiChange={(event: any) => setSubject(event.target.value)}
                    required={true}
                />
            </div>
            <GroupuiTextarea
                value={description}
                disabled={false}
                onGroupuiChange={(event) => setDescription(event.target.value)}
                required={true}
                rows={4}
                maxlength={30000} // JIRA MAX Size is 32,767 but there should be space for additional fields
            >
                <span slot="label">Description *</span>
            </GroupuiTextarea>
            <div
                style={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "var(--groupui-sys-spacing-400)",
                    marginTop: "var(--groupui-sys-spacing-600)",
                }}
            >
                <GroupuiButton variant={"secondary"} onClick={() => setModal(false)}>
                    Cancel
                </GroupuiButton>
                <GroupuiButton icon="ticket-32" disabled={checkInput()} onClick={sendInput}>
                    Raise Ticket
                </GroupuiButton>
            </div>
        </GroupuiModal>
    );
};

export default RaiseTicketModal;
