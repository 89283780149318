import React from "react";

interface ColorDotProps {
    color: "green" | "grey" | "orange";
}

const colorDot: React.FC<ColorDotProps> = ({ color }) => {
    const mappedColor = {
        green: "var(--groupui-sys-color-success-basic-default)",
        grey: "var(--groupui-sys-color-light-basic-hover)",
        orange: "var(--groupui-sys-color-warning-basic-default)",
    };

    const dotStyle = {
        width: "12px",
        height: "12px",
        borderRadius: "50%",
        backgroundColor: mappedColor[color],
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "var(--groupui-sys-spacing-150)",
    };

    return <div style={dotStyle} />;
};

export default colorDot;
