import {
    GroupuiButton,
    GroupuiLoadingSpinner,
    GroupuiModal,
    GroupuiSelect,
    GroupuiSelectOption,
} from "@group-ui/group-ui-react";
import React, { useContext, useState } from "react";
import GenericHeadline from "../../../generics/GenericHeadline";
import { ApiService } from "../../../../services/apiService";
import ErrorContext from "../../../context/ErrorContext";
import Snackbar from "../../../generics/Snackbar";
import GenericInput from "../../../generics/GenericInput";
import { handleError } from "../../../context/ErrorContextUtils";
import { IngestSlotMonitorData } from "../../../../models/ingestSlotMonitorData";

interface Props {
    modal: boolean;
    setModal: (value: boolean) => void;
    ingestSlotMonitorData: IngestSlotMonitorData;
}

const OverwriteStates = [
    "Patching Data",
    "Mounting Issue",
    "Hardware Issue Databox",
    "Hardware Issue Slot",
    "Custom Overwrite State",
];

const OverwriteErrorModal: React.FC<Props> = ({ modal, setModal, ingestSlotMonitorData }) => {
    const [overwriteState, setOverwriteState] = useState<string>("");
    const [customOverwriteState, setCustomOverwriteState] = useState<string>("");

    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
    const [severity, setSeverity] = useState<"success" | "danger">("success");
    const [message, setMessage] = useState<string>("");

    const [loading, setLoading] = useState<boolean>(false);

    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);

    const sendRequest = () => {
        if (ingestSlotMonitorData?.ingestId) {
            setLoading(true);

            const state =
                overwriteState == "Custom Overwrite State" && customOverwriteState != ""
                    ? customOverwriteState
                    : overwriteState;

            ApiService.addOverwriteStateToIngest(ingestSlotMonitorData?.ingestId, state, false)
                .then((response) => {
                    if (response.startsWith("success")) {
                        setShowSnackbar(true);
                        setMessage("Successfully overwritten the error state!");
                        setTimeout(() => {
                            setLoading(false);
                            setModal(false);
                            window.location.reload();
                        }, 2000);
                    } else {
                        setShowSnackbar(true);
                        setMessage("An error occurred during overwriting of the error state!");
                        setSeverity("danger");

                        setShowSnackbar(true);
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    handleError(error, changeErrorMessage, changeShowError);
                });
        }
    };

    return (
        <GroupuiModal
            displayed={modal}
            onGroupuiModalClose={() => setModal(false)}
            closeButton={true}
            padding="var(--groupui-sys-spacing-1000)"
            margin="200px"
            overflow="visible"
        >
            <GroupuiLoadingSpinner displayed={loading} />
            {showSnackbar && (
                <Snackbar
                    severity={severity}
                    message={message}
                    onClose={() => setShowSnackbar(false)}
                />
            )}
            <GenericHeadline
                title={"Overwrite Error"}
                titleHeadingType="h3"
                actionButtons={[]}
                type="modal"
            />
            <div
                style={{
                    display: "flex",
                    gap: "var(--groupui-sys-spacing-400)",
                    flexDirection: "column",
                }}
            >
                <GroupuiSelect
                    value={overwriteState}
                    required={true}
                    typeof="input"
                    onGroupuiChange={(event) => setOverwriteState(event.target.value)}
                    disabled={false}
                >
                    {OverwriteStates.map((state) => {
                        return (
                            <GroupuiSelectOption
                                key={`${state}-option-${Math.random()}`}
                                value={String(state)}
                            >
                                {state}
                            </GroupuiSelectOption>
                        );
                    })}
                    <span slot="label">Overwrite State</span>
                    <span slot="description">
                        This will state will be displayed in current state column of the dashboard
                    </span>
                </GroupuiSelect>
                {overwriteState == "Custom Overwrite State" && (
                    <GenericInput
                        label="Custom Overwrite State"
                        value={customOverwriteState}
                        onGroupuiChange={(event: any) =>
                            setCustomOverwriteState(event.target.value)
                        }
                        maxLength={50}
                    />
                )}

                <div
                    style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "var(--groupui-sys-spacing-400)",
                        marginTop: "var(--groupui-sys-spacing-600)",
                    }}
                >
                    <GroupuiButton variant={"secondary"} onClick={() => setModal(false)}>
                        Cancel
                    </GroupuiButton>
                    <GroupuiButton
                        icon="check-32"
                        disabled={
                            overwriteState === "" ||
                            (overwriteState == "Custom Overwrite State" &&
                                customOverwriteState == "")
                        }
                        onClick={sendRequest}
                    >
                        Confirm
                    </GroupuiButton>
                </div>
            </div>
        </GroupuiModal>
    );
};

export default OverwriteErrorModal;
