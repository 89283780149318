import {
    GroupuiButton,
    GroupuiIcon,
    GroupuiLink,
    GroupuiNotification,
    GroupuiText,
} from "@group-ui/group-ui-react";
import React, { useEffect, useState } from "react";

interface SnackbarProps {
    severity: "success" | "danger" | "warning";
    message: string;
    onClose: () => void;
}

/**
 * A Snackbar-Component, which shows a message in different colors with different icons depending on the given severity
 *
 * @param {string} severity - can take following values: "success" | "danger" | "warning" and changes styling, if the snack dismisses automatically and is dismissible
 * @param {string} message - changes the displayed message, when the snackbar is shown
 * @param {function} onClose - the given function will be executed after the snackbar message is closed
 *
 * @returns Snackbar
 *
 */
const Snackbar: React.FC<SnackbarProps> = ({ severity, message, onClose }) => {
    const [showMore, setShowMore] = useState<boolean>(true);

    const maxLength = 180;

    const iconMap = {
        success: "success-filled-32",
        warning: "warning-filled-32",
        danger: "error-filled-32",
    };

    const styles = {
        container: {
            display: "flex",
            flexDirection: "row",
        } as React.CSSProperties,
        snackbar: {
            position: "fixed",
            width: "50vw",
            left: "25vw",
            top: "10%",
            zIndex: "1100",
        },
        snackbarContainer: {
            display: "flex",
            flexDirection: "row",
            width: "100%",
        } as React.CSSProperties,
        icon: {
            paddingRight: "5%",
            color: "white",
        },
        message: {
            color: "white",
        },
    };

    return (
        <div style={styles.container}>
            <GroupuiNotification
                style={styles.snackbar}
                severity={severity}
                autoDismiss={severity == "success" || severity == "warning" ? 3000 : 100000000}
                dismissible={severity == "success" || severity == "warning" ? false : true}
                onGroupuiClose={onClose}
                visible={true}
            >
                <div style={styles.snackbarContainer}>
                    <GroupuiIcon name={iconMap[severity]} height={25} style={styles.icon} />
                    <div style={styles.message}>
                        {message.length > maxLength && showMore && severity == "danger"
                            ? message.substring(0, maxLength) + "... "
                            : message + " "}
                        {severity == "danger" && message.length > maxLength && showMore ? (
                            <GroupuiLink
                                onClick={() => setShowMore(false)}
                                target="self"
                                variant="secondary"
                            >
                                Show more
                            </GroupuiLink>
                        ) : message.length > maxLength && severity == "danger" ? (
                            <GroupuiLink
                                onClick={() => setShowMore(true)}
                                target="self"
                                variant="secondary"
                            >
                                Show less
                            </GroupuiLink>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </GroupuiNotification>
        </div>
    );
};

export default Snackbar;
