import { Company } from "./company";
import { Country } from "./country";

export class Address {
    private _id;
    public fullName;
    public street;
    public addressSupplement;
    public zipCode;
    public city;
    public state;
    public country;
    public company;
    public email;
    public phone;

    constructor({
        id,
        fullName,
        street,
        addressSupplement,
        zipCode,
        city,
        state,
        country,
        company,
        email,
        phone,
    }: {
        id?: number;
        fullName: string;
        street: string;
        addressSupplement?: string;
        zipCode: string;
        city: string;
        state?: string;
        country: Country;
        company: Company;
        email: string;
        phone: string;
    }) {
        this._id = id;
        this.fullName = fullName;
        this.street = street;
        this.addressSupplement = addressSupplement;
        this.zipCode = zipCode;
        this.city = city;
        this.state = state;
        this.country = country;
        this.company = company;
        this.email = email;
        this.phone = phone;
    }

    public get id() {
        return this._id;
    }

    private toObject() {
        return {
            id: this._id,
            fullName: this.fullName,
            street: this.street,
            addressSupplement: this.addressSupplement,
            zipCode: this.zipCode,
            city: this.city,
            state: this.state,
            country: this.country,
            company: this.company,
            email: this.email,
            phone: this.phone,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): Address | null {
        if (json == null) {
            return null;
        }

        return new Address({
            id: json["id"],
            fullName: json["fullName"],
            street: json["street"],
            addressSupplement: json["addressSupplement"],
            zipCode: json["zipCode"],
            city: json["city"],
            state: json["state"],
            country: Country.fromSerialized(json["country"]),
            company: Company.fromSerialized(json["company"]),
            email: json["email"],
            phone: json["phone"],
        });
    }
}
