import { GroupuiButton, GroupuiModal, GroupuiText } from "@group-ui/group-ui-react";
import React from "react";
import { Address } from "../../models/address";
import GenericHeadline from "../generics/GenericHeadline";
import AddressComparisonTable from "../requests/orderDataboxes/AddressComparisonTable";

interface DualAddressValidationModalProps {
    address1: Address | undefined;
    suggestedAddress1: Address | undefined;
    address1Title: string;
    address2: Address | undefined;
    suggestedAddress2: Address | undefined;
    address2Title: string;
    onDecision: (isAccepted: boolean) => void;
    show: boolean;
    setShow: (value: {
        show: boolean;
        suggestedPickupAddress: Address | undefined;
        suggestedDeliveryAddress: Address | undefined;
    }) => void;
}

const DualAddressValidationModal: React.FC<DualAddressValidationModalProps> = ({
    address1,
    suggestedAddress1,
    address1Title,
    address2,
    suggestedAddress2,
    address2Title,
    onDecision,
    show,
    setShow,
}) => (
    <GroupuiModal
        closeButton={true}
        padding="30px"
        maxWidth="1000px"
        overflow={"visible"}
        displayed={show}
        style={{
            justifyContent: "center",
            alignItems: "center",
        }}
    >
        <div>
            <GenericHeadline
                title="Address Validation"
                actionButtons={[]}
                titleHeadingType="h4"
                type="modal"
            />
            <div>
                <GroupuiText>
                    Do you want to accept following <b>changes</b> to your <br />
                    entered addresses?
                </GroupuiText>
            </div>
            {address1 !== undefined && (
                <div>
                    <GenericHeadline
                        title={address1Title}
                        actionButtons={[]}
                        titleHeadingType="h6"
                        marginBottom={"var(--groupui-sys-spacing-200)"}
                    />
                    <AddressComparisonTable
                        address={address1}
                        suggestedAddress={suggestedAddress1 ?? address1}
                    />
                </div>
            )}
            {address2 !== undefined && (
                <div>
                    <GenericHeadline
                        title={address2Title}
                        actionButtons={[]}
                        titleHeadingType="h6"
                        marginBottom={"var(--groupui-sys-spacing-200)"}
                    />
                    <AddressComparisonTable
                        address={address2}
                        suggestedAddress={suggestedAddress2 ?? address2}
                    />
                </div>
            )}
            <div
                style={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "var(--groupui-sys-spacing-400)",
                    marginTop: "var(--groupui-sys-spacing-600)",
                }}
            >
                <GroupuiButton
                    variant={"secondary"}
                    onClick={() =>
                        setShow({
                            show: false,
                            suggestedPickupAddress: suggestedAddress1,
                            suggestedDeliveryAddress: suggestedAddress2,
                        })
                    }
                >
                    Cancel
                </GroupuiButton>
                <GroupuiButton icon="shopping-cart-32" onClick={() => onDecision(true)}>
                    Accept
                </GroupuiButton>
            </div>
        </div>
    </GroupuiModal>
);

export default DualAddressValidationModal;
