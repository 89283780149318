import React from "react";
import { msalInstance } from "../../config/config";
import { ErrorMessageProvider } from "../context/ErrorContext";
import { UserGroupProvider } from "../context/UserGroupContext";
import RoutesWrapper from "../routes/RoutesWrapper";

/**
 * Returns an Wrapper Component, which  handles the assignment of user-roles given from azure for the application
 *
 * @returns RoleWrapper Component
 *
 */
const RoleWrapper = () => {
    const accounts = msalInstance.getAllAccounts();

    let userGroups = [""];

    if (accounts) {
        if (accounts[0]["idTokenClaims"]) {
            if (accounts[0]["idTokenClaims"]["roles"]) {
                userGroups = accounts[0]["idTokenClaims"]["roles"];
            }
        }
    }

    return (
        <UserGroupProvider userGroup={userGroups}>
            <ErrorMessageProvider>
                <RoutesWrapper />
            </ErrorMessageProvider>
        </UserGroupProvider>
    );
};

export default RoleWrapper;
