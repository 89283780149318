import React, { useContext, useEffect, useState } from "react";
import {
    GroupuiButton,
    GroupuiDatePicker,
    GroupuiDivider,
    GroupuiInlineNotification,
    GroupuiLoadingSpinner,
    GroupuiModal,
} from "@group-ui/group-ui-react";
import Snackbar from "../../generics/Snackbar";
import GenericHeadline from "../../generics/GenericHeadline";
import { ApiService } from "../../../services/apiService";
import { Country } from "../../../models/country";
import ErrorContext from "../../context/ErrorContext";
import { Order } from "../../../models/order";
import { UpsPickup } from "../../../models/upsPickup";
import { Address } from "../../../models/address";
import { useParams } from "react-router";
import { USStatesList } from "../../../enums/USStatesList";
import { Countries } from "../../../enums/Countries";
import { handleError } from "../../context/ErrorContextUtils";
import {
    GenericAddressInformationFields,
    Structure,
} from "../../generics/GenericAddressInformationFields";

interface RequestPickupProps {
    modal: boolean;
    setModal: (value: boolean) => void;
    order: Order;
    onModalClose: () => void;
}

const RequestPickupModal: React.FC<RequestPickupProps> = ({
    modal,
    setModal,
    order,
    onModalClose,
}) => {
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const [disabled, setDisabled] = useState<boolean>(true);
    const { orderID } = useParams();
    const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
    const [severity, setSeverity] = useState<"danger" | "success" | "warning">();
    const [message, setMessage] = useState<string>();
    const [isLoading, setIsLoading] = useState(false);

    const [pickupAddress, setPickupAddress] = useState<Address | undefined>(undefined);

    const [countries, setCountries] = useState<Country[] | null>();

    const [pickupDate, setPickupDate] = useState<string | undefined>();

    useEffect(() => {
        ApiService.getAllCountries()
            .then((response) => {
                setCountries(response);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
            });
    }, []);

    const validate = () => {
        const pickupDay = new Date(pickupDate!);
        const currentDate = new Date();
        if (pickupDay.getDay() == 6 || pickupDay.getDay() == 0) {
            setShowSnackBar(true);
            setSeverity("danger");
            setMessage("Selected Pickup Date is on the weekend!");
            return false;
        }
        if (pickupDay <= currentDate) {
            setShowSnackBar(true);
            setSeverity("danger");
            setMessage("Selected Pickup Date is today or in the past!");
            return false;
        }
        if (pickupDay >= new Date(currentDate.setDate(currentDate.getDate() + 183))) {
            setShowSnackBar(true);
            setSeverity("danger");
            setMessage(
                "Selected Pickup Date is to far in the future. Maximum 183 days from today!"
            );
            return false;
        }
        return true;
    };

    const handleSubmit = (event: any) => {
        if (pickupDate && pickupAddress?.country && pickupAddress.company && validate()) {
            setIsLoading(true);
            ApiService.addUpsPickup(
                Number(orderID),
                new UpsPickup({
                    pickupDate: new Date(pickupDate).toISOString(),
                    created: new Date().toISOString(),
                    address: pickupAddress!,
                })
            )
                .then((response) => {
                    if (response.toLowerCase() == "failed") {
                        event.preventDefault();
                        setShowSnackBar(true);
                        setSeverity("danger");
                        setMessage("Pickup couldn´t be scheduled!");
                        setIsLoading(false);
                    } else {
                        setSeverity("success");
                        setMessage("Successfully scheduled a Pickup!");
                        setShowSnackBar(true);
                        const timeout = setTimeout(() => {
                            onModalClose();
                            setIsLoading(false);
                        }, 1000);
                    }
                })
                .catch((error) => {
                    handleError(error, changeErrorMessage, changeShowError);
                    setIsLoading(false);
                });
        }
    };

    const checkStateforUSCountries = () => {
        return (
            pickupAddress?.country?.id ==
                countries?.find((country) => country.country == Countries.UNITED_STATES)?.id &&
            (!USStatesList.filter((usState) => usState.toLowerCase()).includes(
                pickupAddress?.state ?? ""
            ) ||
                pickupAddress?.state == "")
        );
    };

    const checkDeliveryInputs = () => {
        return (
            !pickupAddress ||
            pickupAddress.fullName.length > 22 ||
            pickupAddress.fullName.trim() === "" ||
            pickupAddress.street.trim() === "" ||
            pickupAddress.zipCode.trim() === "" ||
            pickupAddress.city.trim() === "" ||
            !pickupAddress.country ||
            !pickupAddress.company ||
            checkStateforUSCountries() ||
            pickupAddress.email.trim() === "" ||
            pickupAddress.phone.trim() === "" ||
            pickupAddress.phone.trim().length < 7
        );
    };

    return (
        <GroupuiModal
            closeButton={true}
            padding="var(--groupui-sys-spacing-600)"
            overflow={"auto"}
            displayed={modal}
            maxWidth="70vw"
            l={12}
            xl={12}
            xxl={7}
            style={{
                justifyContent: "center",
                alignItems: "center",
            }}
            onGroupuiModalClose={() => {
                setModal(false);
                setDisabled(true);
                setPickupDate(undefined);
                setPickupAddress(order.address!);
            }}
        >
            <GroupuiLoadingSpinner displayed={isLoading} />
            {showSnackBar && (
                <Snackbar
                    severity={severity ?? "danger"}
                    message={message ?? "Some error occurred!"}
                    onClose={() => {
                        setShowSnackBar(false);
                    }}
                />
            )}
            <GenericHeadline
                title="Request Pickup"
                actionButtons={[]}
                titleHeadingType="h3"
                type="modal"
            />
            <form style={{ gap: "1px" }} onSubmit={handleSubmit}>
                {!disabled && order.address?.country.country !== pickupAddress?.country.country && (
                    <div>
                        <GroupuiInlineNotification
                            visible={true}
                            hideIcon={false}
                            severity="warning"
                            style={{
                                // Added because color from the component isn´t working
                                color: "var(--groupui-sys-color-warning-basic-default)",
                                backgroundColor: "var(--groupui-sys-color-warning-weak-default)",
                                // Added to avoided dropdowns to be hidden
                                zIndex: "0",
                            }}
                        >
                            <div
                                slot="headline"
                                // Added because color from the component isn´t working
                                style={{ color: "var(--groupui-sys-color-text-normal)" }}
                            >
                                Return Label
                            </div>
                            <div
                                slot="content"
                                // Added because color from the component isn´t working
                                style={{ color: "var(--groupui-sys-color-text-normal)" }}
                            >
                                A new return label will be created due to the changed country.
                            </div>
                        </GroupuiInlineNotification>
                        <br />
                    </div>
                )}
                <GroupuiDatePicker
                    style={{ whiteSpace: "normal" }}
                    severity="none"
                    value={pickupDate}
                    required={true}
                    dateFormat="d.m.Y"
                    placeholder="dd.mm.yyyy"
                    minDate={(() => {
                        const date = new Date();
                        date.setDate(date.getDate() + 1);
                        return (
                            date.getDate() +
                            "." +
                            (date.getMonth() + 1).toLocaleString("de-DE", {
                                minimumIntegerDigits: 2,
                            }) +
                            "." +
                            date.getFullYear()
                        );
                    })()}
                    maxDate={(() => {
                        const date = new Date();
                        date.setDate(date.getDate() + 183);
                        return (
                            date.getDate() +
                            "." +
                            (date.getMonth() + 1).toLocaleString("de-DE", {
                                minimumIntegerDigits: 2,
                            }) +
                            "." +
                            date.getFullYear()
                        );
                    })()}
                    disableWeekends={true}
                    onGroupuiChange={(event) => setPickupDate(event.target.value)}
                >
                    <span slot="label">Pickup Date *</span>
                </GroupuiDatePicker>
                <br />
                <GroupuiDivider />
                <br />
                <GenericAddressInformationFields
                    address={pickupAddress ? pickupAddress : order.address ?? undefined}
                    setAddress={setPickupAddress}
                    disabled={disabled}
                    setDisabled={(disabled: boolean) => {
                        setDisabled(disabled);
                        setPickupAddress(order.address!);
                    }}
                    structure={Structure.REQUEST_PICKUP}
                />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "var(--groupui-sys-spacing-400)",
                        marginTop: "var(--groupui-sys-spacing-600)",
                    }}
                >
                    <GroupuiButton variant={"secondary"} onClick={() => setModal(false)}>
                        Cancel
                    </GroupuiButton>
                    <GroupuiButton
                        icon="service-bell-32"
                        disabled={checkDeliveryInputs() || pickupDate === undefined}
                        type={"submit"}
                    >
                        Request
                    </GroupuiButton>
                </div>
            </form>
        </GroupuiModal>
    );
};

export default RequestPickupModal;
