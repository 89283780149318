import {
    GroupuiDatePicker,
    GroupuiDivider,
    GroupuiIcon,
    GroupuiLink,
} from "@group-ui/group-ui-react";
import React, { useEffect, useState } from "react";
import GenericFilter from "./GenericFilter";

export type filterColumns = {
    column: string;
    displayName: string;
    doSort?: "asc" | "desc" | "predefined" | "no";
    predefinedList?: string[];
    selectedByDefault?: string[];
    customLabels?: { [key: string]: string };
    isCustomDropdown?: boolean;
    customOptions?: Array<{ value: string; label: string }>;
    onChange?: (selectedValue: string) => void;
    useOnlyPredefinedList?: boolean;
    isDatePicker?: boolean;
    isSingleSelect?: boolean;
    hidden?: boolean; // Optional hidden property to control visibility
}[];

interface Props {
    data: any[];
    filterColumns: filterColumns;
    filters: object;
    setFilters: React.Dispatch<React.SetStateAction<any>>;
    showResetButton?: boolean;
    children?: JSX.Element;
    filtersWidth?: string;
    initialFilters?: object;
    filterHeight?: string;
    wrap?: boolean;
    onReset?: () => void;
}

const GenericFilterBar: React.FC<Props> = ({
    filterColumns,
    data,
    filters,
    setFilters,
    showResetButton = true,
    children,
    filtersWidth = "250px",
    filterHeight,
    wrap,
    onReset,
}) => {
    const [trigger, setTrigger] = useState(0);
    const [datePickerValues, setDatePickerValues] = useState<{ [key: string]: string | undefined }>(
        {}
    );

    const handleDatePickerChange = (column: string, value: string) => {
        const newFilters = { ...filters, [column]: value };
        setFilters(newFilters);
        setDatePickerValues((prevValues) => ({ ...prevValues, [column]: value }));
    };

    const handleReset = () => {
        setTrigger((trigger) => trigger + 1);
        setFilters({});
        setDatePickerValues({});
        if (onReset) {
            onReset();
        }
    };

    useEffect(() => {
        setDatePickerValues({});
    }, [trigger]);

    return (
        <div
            style={{
                paddingBottom: "var(--groupui-sys-spacing-300)",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "var(--groupui-sys-spacing-100)",
                flexWrap: wrap ? "wrap" : "nowrap",
            }}
        >
            <GroupuiIcon
                name="filter-32"
                style={{ color: "var(--groupui-sys-color-action-basic-default)" }}
            />
            {filterColumns.map((column, id) => {
                // Conditionally hide filters if `hidden` is set to true
                if (column.hidden) return null;

                if (column.isDatePicker) {
                    return (
                        <div key={id} style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <label style={{ marginRight: "5px", marginLeft: "10px" }}>
                                {column.displayName}
                            </label>
                            <GroupuiDatePicker
                                key={trigger}
                                style={{ whiteSpace: "normal" }}
                                severity="none"
                                dateFormat="d.m.Y"
                                placeholder="DD.MM.YYYY"
                                value={datePickerValues[column.column] || ""}
                                onGroupuiChange={(event) =>
                                    handleDatePickerChange(column.column, event.target.value)
                                }
                            />
                        </div>
                    );
                }
                if (column.isCustomDropdown) {
                    return (
                        <GenericFilter
                            key={id}
                            displayName={column.displayName}
                            isCustomDropdown={true}
                            customOptions={column.customOptions}
                            onChange={column.onChange}
                            width={filtersWidth ?? "250px"}
                            filterHeight={filterHeight ?? ""}
                            resetTrigger={trigger}
                        />
                    );
                }
                return (
                    <GenericFilter
                        displayName={column.displayName}
                        data={data}
                        column={column.column}
                        filters={filters}
                        setFilters={setFilters}
                        key={id}
                        resetTrigger={trigger}
                        doSort={column.doSort}
                        predefinedList={column.predefinedList}
                        selectedByDefault={column.selectedByDefault}
                        customLabels={column.customLabels}
                        useOnlyPredefinedList={column.useOnlyPredefinedList}
                        isSingleSelect={column.isSingleSelect}
                        width={filtersWidth ?? "250px"}
                        filterHeight={filterHeight ?? ""}
                    />
                );
            })}
            {children}
            {showResetButton &&
                Object.keys(filters).find((key) => filters[key].length > 0) !== undefined && (
                    <>
                        <div
                            style={{
                                backgroundColor: "var(--groupui-sys-color-action-basic-default)",
                                width: "0.125rem",
                                height: "30px",
                                marginLeft: "var(--groupui-sys-spacing-100)",
                                marginRight: "var(--groupui-sys-spacing-100)",
                            }}
                        />
                        <GroupuiDivider vertical={true} />
                        <GroupuiLink quiet={true} onClick={handleReset}>
                            Reset
                        </GroupuiLink>
                    </>
                )}
        </div>
    );
};

export default GenericFilterBar;
