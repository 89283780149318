import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ContentWrapper from "../container/ContentWrapper";
import Dashboard from "../dashboard/Dashboard";
import NotFound from "../NotFound";
import DataboxesRoutes from "../routes/DataboxesRoutes";
import IngestStationRoutes from "../routes/IngestStationRoutes";
import ManageProjectsRoutes from "../routes/ManageProjectsRoutes";
import MyDataboxesRoutes from "./MyDataboxesRoutes";
import RequestRoutes from "./RequestRoutes";
import ShipmentJobsRoutes from "./ShipmentJobsRoutes";
import DataboxOrdersRoutes from "./DataboxOrdersRoutes";
import IngestJobRoutes from "./IngestJobRoutes";
import CyclesReportRoutes from "./reports/CyclesReportRoutes";
import IngestsReportRoutes from "./reports/IngestsReportRoutes";
import IngestsRoutes from "./IngestsRoutes";
import DataboxesStatusReportRoutes from "./reports/DataboxesStatusReportRoutes";
import ReportRoutes from "./reports/ReportRoutes";
import { JobReportRoutes } from "./reports/JobReportRoutes";
import InCarMountsRoutes from "./InCarMountsRoutes";
import NonStandardRequestsRoutes from "./NonStandardRequestsRoutes";
import IngestsStatisticsRoutes from "./reports/IngestsStatisticsRoutes";
import { MaintenanceScheduleProvider } from "../context/provider/MaintenanceScheduleContext";

/**
 * Returns an Wrapper Component, which contains the basic routes for the application
 *
 *
 * @returns RoutesWrapper Component
 *
 */
const RoutesWrapper = () => {
    return (
        <MaintenanceScheduleProvider>
            <Routes>
                <Route element={<ContentWrapper />}>
                    {/* DASHBOARD ROUTES */}
                    <Route index element={<Dashboard />} />
                    {/* INGEST STATION ROUTES */}
                    <Route path="/ingest-stations/*" element={<IngestStationRoutes />} />
                    {/* INVENTORY ROUTES */}
                    <Route path="/databox-pool/*" element={<DataboxesRoutes />} />
                    <Route path="/in-car-mounts/*" element={<InCarMountsRoutes />} />
                    {/* DATABOXES ROUTES */}
                    <Route path="/create-request/*" element={<RequestRoutes />} />
                    <Route path="/my-databoxes/*" element={<MyDataboxesRoutes />} />
                    {/* OPERATION ROUTES */}
                    <Route path="/databox-orders/*" element={<DataboxOrdersRoutes />} />
                    <Route path="/ingests/*" element={<IngestsRoutes />} />
                    <Route path="/shipment-jobs/*" element={<ShipmentJobsRoutes />} />
                    <Route path="/ingest-jobs/*" element={<IngestJobRoutes />} />
                    <Route
                        path="/non-standard-requests/*"
                        element={<NonStandardRequestsRoutes />}
                    />
                    {/* REPORTS ROUTES */}
                    <Route path="/reports/*" element={<ReportRoutes />} />
                    {/* DXC REPORTS ROUTES */}
                    <Route path="/cycles-report/*" element={<CyclesReportRoutes />} />
                    <Route
                        path="/databoxes-status-report/*"
                        element={<DataboxesStatusReportRoutes />}
                    />
                    <Route path="/ingests-report/*" element={<IngestsReportRoutes />} />
                    <Route path="/job-report/*" element={<JobReportRoutes />} />
                    <Route path="/ingests-statistics/*" element={<IngestsStatisticsRoutes />} />
                    {/* ADMINISTRATION ROUTES */}
                    <Route path="/manage-projects/*" element={<ManageProjectsRoutes />} />
                    {/* Render a Not Found Page if requested data doesn´t exist */}
                    <Route path="/not-found" element={<NotFound />} />
                </Route>
                {/* Redirect the user if the Route doesn´t exist */}
                <Route path="*" element={<Navigate to="/not-found" />} />
            </Routes>
        </MaintenanceScheduleProvider>
    );
};

export default RoutesWrapper;
