import React, { useContext, useEffect, useState } from "react";
import { IngestSlotMonitorData } from "../../models/ingestSlotMonitorData";
import { ApiService } from "../../services/apiService";
import ErrorContext from "../context/ErrorContext";
import { handleError } from "../context/ErrorContextUtils";
import IngestDetails from "../dashboard/IngestDetails";

interface Props {
    ingestSlotSerialNumber: string;
    ingestStationId: number;
    showDetails: boolean;
    onClose: () => void;
}

const DashboardDetailsWrapper: React.FC<Props> = ({
    ingestSlotSerialNumber,
    ingestStationId,
    showDetails,
    onClose,
}) => {
    const [ingestSlotMonitorData, setIngestSlotMonitorData] = useState<IngestSlotMonitorData>();
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);

    useEffect(() => {
        if (ingestSlotSerialNumber && ingestStationId) {
            ApiService.getIngestSlotMonitorDataForIngestStationAndSpecificSlot(
                ingestStationId,
                ingestSlotSerialNumber
            )
                .then((data) => {
                    setIngestSlotMonitorData(data);
                })
                .catch((error) => {
                    handleError(error, changeErrorMessage, changeShowError);
                });
            onClose();
        }
    }, [ingestSlotSerialNumber, ingestStationId]);

    return (
        <div>
            {ingestSlotMonitorData && (
                <IngestDetails
                    ingestSlotMonitorData={ingestSlotMonitorData}
                    showDetails={showDetails}
                    onClose={onClose}
                />
            )}
        </div>
    );
};

export default DashboardDetailsWrapper;
