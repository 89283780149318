import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { GroupuiIcon, GroupuiLoadingSpinner, GroupuiTooltip } from "@group-ui/group-ui-react";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IngestSlot } from "../../models/ingestSlot/ingestSlot";
import { ApiService } from "../../services/apiService";
import ErrorContext from "../context/ErrorContext";
import { handleError } from "../context/ErrorContextUtils";
import GenericHeadline from "../generics/GenericHeadline";
import GenericTable, { columnDefinition } from "../generics/GenericTable";
import GenericTag from "../generics/GenericTag";
import { IngestStationContext } from "../context/provider/IngestStationContext";

const styles = {
    magnifyingGlass: {
        cursor: "pointer",
        color: "var(--groupui-sys-color-action-basic-default)",
    },
};

const columns: columnDefinition[] = [
    {
        key: "name",
        header: "Name",
        width: "auto",
    },
    {
        key: "serialNumber",
        header: "Serial Number",
        width: "auto",
    },
    {
        key: "portTypes",
        header: "Port Types",
        width: "auto",
    },
    {
        key: "mediaState",
        header: "Media State",
        width: "auto",
    },
    {
        key: "button",
        width: "4rem",
    },
];

const IngestSlotsTab = () => {
    const navigate = useNavigate();
    const { currentIngestStation } = useContext(IngestStationContext);

    const data =
        currentIngestStation?.ingestSlots?.map((ingestSlot: IngestSlot) => {
            return {
                name: ingestSlot.name,
                serialNumber: ingestSlot.serialNumber,
                portTypes: ingestSlot.portTypes,
                mediaState: (
                    <GenericTag
                        text={ingestSlot.mediaState.mediaStateType}
                        variant="primary"
                        mediaStateTarget="ingest-slot"
                        hasSmartHands={currentIngestStation.smartHands}
                    />
                ),
                button: (
                    <GroupuiTooltip position="right">
                        <GroupuiIcon
                            name="search-plus-32"
                            style={styles.magnifyingGlass}
                            onClick={() => navigate(`${ingestSlot.id}`)}
                        />
                        <div slot="tooltip">Show Details</div>
                    </GroupuiTooltip>
                ),
            };
        }) ?? [];

    return (
        <div>
            <GenericHeadline title="Ingest Slots" titleHeadingType="h4" actionButtons={[]} />
            <div>
                <GenericTable columns={columns} data={data} header={true} />
            </div>
        </div>
    );
};

export default IngestSlotsTab;
