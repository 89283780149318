import React from "react";
import Lottie from "lottie-react";
import animationData from "../lotties/page-not-found.json";
import { GroupuiLink } from "@group-ui/group-ui-react";

const styles = {
    allContent: {
        display: "flex",
        flexDirection: "row" as const,
        overflow: "auto",
    },
    contentContainer: {
        display: "flex",
        flexDirection: "column" as const,
        width: "calc(100% - 4.5rem)",
        flex: "wrap",
        overflow: "auto",
        overflowWrap: "break-word",
    } as React.CSSProperties,
    content: {
        display: "flex",
        flexDirection: "column" as const,
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 4rem)",
    },
};

const NotFound = () => {
    return (
        <div style={styles.content}>
            <Lottie animationData={animationData} />
            <h1>404 Page Not Found</h1>
            <h3>
                Go to the <GroupuiLink href="/">Dashboard</GroupuiLink>
            </h3>
        </div>
    );
};

export default NotFound;
