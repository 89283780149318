import {
    GroupuiButton,
    GroupuiInput,
    GroupuiModal,
    GroupuiSelect,
    GroupuiSelectOption,
    GroupuiLoadingSpinner,
} from "@group-ui/group-ui-react";
import React, { useContext, useEffect, useState } from "react";
import { ApiService } from "../../../services/apiService";
import GenericHeadline from "../../generics/GenericHeadline";
import Snackbar from "../../generics/Snackbar";
import { useMsal } from "@azure/msal-react";
import { IngestStation } from "../../../models/ingestStation";
import { databox } from "../../../models/types";
import ErrorContext from "../../context/ErrorContext";
import { Databox } from "../../../models/databox";
import { handleError } from "../../context/ErrorContextUtils";
import { databoxOwners } from "../../../constants/databoxOwner";

interface AddDataboxModalProps {
    modal: boolean;
    setModal: (value: boolean) => void;
    onModalClose: () => void;
}

const AddDataboxModal: React.FC<AddDataboxModalProps> = ({ modal, setModal, onModalClose }) => {
    const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
    const [severity, setSeverity] = useState<"danger" | "success" | "warning">();
    const [message, setMessage] = useState<string>();

    const [name, setName] = useState<string>("");
    const [serialNumber, setSerialNumber] = useState<string>("");
    const [ingestStationID, setIngestStationID] = useState<number>();
    const [owner, setOwner] = useState<string>("");
    const [storageCapacity, setStorageCapacity] = useState<number>();
    const [connectorType, setConnectorType] = useState<number>();
    // TODO: Why is the trackerID not send to DB?
    // Why is field for tracker mandatory, to submit, but not marked. Works if connector value is set?!
    // Keeping it here for later use.
    const [trackerID, setTrackerID] = useState<string>();
    const [isLoading, setIsLoading] = useState(false);

    const { inProgress } = useMsal();

    const [ingestStationsData, setIngestStationsData] = useState<IngestStation[]>();
    const [databoxData, setDataboxData] = useState<Databox[]>();
    const [portTypes, setPortTypes] = useState<any[]>([]);

    const [page, setPage] = useState<number>(1);
    const [isValid, setValid] = useState<boolean>(false);

    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);

    // Get Data for uniqueness validation
    useEffect(() => {
        setIsLoading(true);
        ApiService.getAllIngestStations()
            .then((response) => {
                const sortedData = response.sort((a, b) => a.name.localeCompare(b.name));
                setIngestStationsData(sortedData);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        setIsLoading(true);
        ApiService.getAllDataboxes()
            .then((response) => {
                setDataboxData(response);
                setIsLoading(false);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        setIsLoading(true);
        ApiService.getAllPortTypes()
            .then((response) => {
                setPortTypes(response);
                setIsLoading(false);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
                setIsLoading(false);
            });
    }, []);

    const handleValidation = (): boolean => {
        const ingestStation = ingestStationsData?.find((e) => e.id === ingestStationID);
        if (databoxData?.find((e) => e.name === name) !== undefined) {
            setShowSnackBar(true);
            setSeverity("danger");
            setMessage("This name is already used for another databox");
            setIsLoading(false);
            return false;
        }

        if (
            databoxData?.find(
                (e) =>
                    e.serialNumber === serialNumber &&
                    (e.ingestStation === null ||
                        (ingestStation !== undefined &&
                            e.ingestStation.name === ingestStation.name))
            ) !== undefined
        ) {
            setShowSnackBar(true);
            setSeverity("danger");
            setMessage(
                "This serial number in combination with ingest station is already used for another databox"
            );
            setIsLoading(false);
            return false;
        }

        return true;
    };

    // Keep for later use
    const changeModalpage = () => {
        if (handleValidation()) setPage(2);
    };
    const resetForm = () => {
        setName("");
        setSerialNumber("");
        setIngestStationID(undefined);
        setOwner("");
        setStorageCapacity(undefined);
        setConnectorType(undefined);
        setTrackerID("");
    };

    const handleCancel = () => {
        resetForm();
        setModal(false);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        setIsLoading(true);
        if (handleValidation()) {
            const newDatabox: databox = {
                serialNumber: serialNumber,
                name: name,
                storageCapacity: storageCapacity ?? 0,
                owner: owner,
                mediaState: 1,
                ingestStationId: ingestStationID,
                portType: connectorType,
            };

            ApiService.addDatabox(newDatabox)
                .then((response) => {
                    if (response.toLowerCase() == "failed") {
                        event.preventDefault();
                        setShowSnackBar(true);
                        setSeverity("danger");
                        setMessage("Databox couldn`t be added!");
                        setIsLoading(false);
                    } else {
                        setSeverity("success");
                        setMessage("Successfully created a databox!");
                        setShowSnackBar(true);
                        setTimeout(() => {
                            setModal(false);
                            setIsLoading(false);
                        }, 2000);
                    }
                })
                .catch((error) => {
                    handleError(error, changeErrorMessage, changeShowError);
                });
            setName("");
            setSerialNumber("");
            setIngestStationID(undefined);
            setOwner("");
            setStorageCapacity(undefined);
            setConnectorType(undefined);

            // setPage(1);
            // onModalClose();
        }
    };

    useEffect(() => {
        if (
            name == null ||
            name === "" ||
            serialNumber == null ||
            serialNumber === "" ||
            ingestStationID == null ||
            storageCapacity == null ||
            storageCapacity < 1
        ) {
            setValid(false);
        } else {
            setValid(true);
        }
    }, [name, serialNumber, ingestStationID, storageCapacity]);

    return (
        <GroupuiModal
            closeButton={true}
            padding="var(--groupui-sys-spacing-800)"
            overflow="auto"
            displayed={modal}
            style={{
                justifyContent: "center",
                alignItems: "center",
            }}
            onGroupuiModalClose={() => handleCancel()}
        >
            <GroupuiLoadingSpinner displayed={isLoading} />
            {showSnackBar && (
                <Snackbar
                    severity={severity ?? "danger"}
                    message={message ?? "Some error occurred!"}
                    onClose={() => {
                        setShowSnackBar(false);
                    }}
                />
            )}
            <GenericHeadline
                title="Add Databox"
                actionButtons={[]}
                titleHeadingType="h3"
                color="var(--groupui-sys-color-brand-core)"
                marginBottom={page == 1 ? "-0.5rem" : "0.5rem"}
                type="modal"
            />
            <form onSubmit={handleSubmit} style={{ gap: "var(--groupui-sys-spacing-25)" }}>
                {page == 1 ? (
                    <>
                        <GroupuiInput
                            value={name}
                            maxlength={50}
                            severity="none"
                            type="text"
                            pattern="[A-Za-z0-9]+"
                            required={true}
                            onGroupuiChange={(event) => setName(event.target.value)}
                            style={{ paddingLeft: "var(--groupui-sys-spacing-1000)" }}
                        >
                            <span slot="label">Name *</span>
                        </GroupuiInput>
                        <br />
                        <GroupuiInput
                            value={serialNumber}
                            maxlength={50}
                            severity="none"
                            pattern="[A-Za-z0-9]+"
                            type="text"
                            required={true}
                            onGroupuiChange={(event) => setSerialNumber(event.target.value)}
                        >
                            <span slot="label">Serial Number *</span>
                        </GroupuiInput>
                        <br />
                        <GroupuiSelect
                            value={String(ingestStationID)}
                            severity="none"
                            typeof="input"
                            required={true}
                            maxHeight="155px"
                            onGroupuiChange={(event) => {
                                setIngestStationID(Number(event.target.value));
                            }}
                        >
                            {ingestStationsData?.map((element, index) => {
                                return (
                                    <GroupuiSelectOption
                                        value={String(element.id)}
                                        key={`select-option-${index}`}
                                    >
                                        {element.name}
                                    </GroupuiSelectOption>
                                );
                            })}
                            <span slot="label">Ingest Station *</span>
                        </GroupuiSelect>
                        <br />
                        <GroupuiSelect
                            value={owner}
                            severity="none"
                            required={false}
                            onGroupuiChange={(event) => setOwner(event.target.value)}
                        >
                            {databoxOwners.map((e) => (
                                <GroupuiSelectOption value={e} key={e}>
                                    {e}
                                </GroupuiSelectOption>
                            ))}
                            <GroupuiSelectOption value={undefined} key={"empty-value"}>
                                {"<no owner>"}
                            </GroupuiSelectOption>
                            <span slot="label">Owner</span>
                        </GroupuiSelect>
                        <br />
                        <GroupuiInput
                            value={String(storageCapacity)}
                            maxlength={50}
                            severity="none"
                            type="number"
                            required={true}
                            pattern="[0-9]+"
                            onGroupuiChange={(event) =>
                                setStorageCapacity(Number(event.target.value))
                            }
                        >
                            <span slot="label">Storage Capacity (TB) *</span>
                        </GroupuiInput>
                        <br />
                        <GroupuiSelect
                            value={String(connectorType)}
                            severity="none"
                            typeof="input"
                            maxHeight="120px"
                            onGroupuiChange={(event) =>
                                setConnectorType(Number(event.target.value))
                            }
                        >
                            {portTypes?.map((element, index) => {
                                return (
                                    <GroupuiSelectOption
                                        value={String(element.id)}
                                        key={`select-option-${index}`}
                                    >
                                        {element.portType}
                                    </GroupuiSelectOption>
                                );
                            })}
                            <span slot="label">Connector Type</span>
                        </GroupuiSelect>
                        <br />

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "end",
                                gap: "var(--groupui-sys-spacing-400)",
                            }}
                        >
                            <GroupuiButton onClick={() => handleCancel()} variant={"secondary"}>
                                Cancel
                            </GroupuiButton>
                            <GroupuiButton
                                icon="add-32"
                                disabled={isValid === false}
                                // type={"submit"}
                                onClick={handleSubmit}
                            >
                                Add
                            </GroupuiButton>
                        </div>
                    </>
                ) : (
                    // Keep for later use
                    <>
                        <GroupuiInput
                            maxlength={50}
                            pattern="[A-Za-z]{3}"
                            severity="none"
                            type="text"
                            required={false}
                            onGroupuiChange={(event) => setTrackerID(event.target.value)}
                        >
                            <span slot="label">Tracker ID</span>
                        </GroupuiInput>
                        <br />
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                gap: "var(--groupui-sys-spacing-400)",
                            }}
                        >
                            <GroupuiButton
                                icon="chevron-left-32"
                                variant="secondary"
                                onClick={() => setPage(1)}
                            >
                                Back
                            </GroupuiButton>
                            <GroupuiButton
                                icon="add-32"
                                disabled={isValid === false}
                                type={"submit"}
                            >
                                Add
                            </GroupuiButton>
                        </div>
                    </>
                )}
            </form>
        </GroupuiModal>
    );
};

export default AddDataboxModal;
