import { Project } from "./project";

export class InCarMount {
    public id;
    public name;
    public updated;
    public poNumber;
    public registrationDate;
    public version;
    public status;
    public owner;
    public lastComment;
    public timestampLastComment;
    public project;
    public currentHolder;
    public shielding;

    constructor({
        id,
        name,
        updated,
        poNumber,
        registrationDate,
        version,
        owner,
        status,
        lastComment,
        timestampLastComment,
        project,
        currentHolder,
        shielding,
    }: {
        id: number;
        poNumber?: string;
        name: string;
        updated: boolean;
        registrationDate: string;
        version: string;
        status: string;
        owner: string;
        lastComment?: string;
        timestampLastComment?: string;
        project?: Project;
        currentHolder: string;
        shielding: boolean;
    }) {
        this.id = id;
        this.name = name;
        this.updated = updated;
        this.poNumber = poNumber;
        this.registrationDate = registrationDate;
        this.version = version;
        this.owner = owner;
        this.status = status;
        this.lastComment = lastComment;
        this.timestampLastComment = timestampLastComment;
        this.project = project;
        this.currentHolder = currentHolder;
        this.shielding = shielding;
    }

    private toObject() {
        return {
            id: this.id,
            name: this.name,
            updated: this.updated,
            poNumber: this.poNumber,
            registrationDate: this.registrationDate,
            version: this.version,
            owner: this.owner,
            status: this.status,
            lastComment: this.lastComment,
            timestampLastComment: this.timestampLastComment,
            project: this.project,
            currentHolder: this.currentHolder,
            shielding: this.shielding,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): InCarMount {
        if (json == null) {
            throw new Error(`Error parsing InCarMount!`);
        }

        return new InCarMount({
            id: json["id"],
            name: json["name"],
            updated: json["updated"],
            poNumber: json["poNumber"],
            owner: json["owner"],
            registrationDate: json["registrationDate"],
            version: json["version"],
            status: json["status"],
            lastComment: json["lastComment"],
            timestampLastComment: json["timestampLastComment"],
            project: json["project"],
            currentHolder: json["currentHolder"],
            shielding: json["shielding"],
        });
    }
}
