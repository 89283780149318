import React, { useEffect, useState } from "react";

import {
    GroupuiPagination,
    GroupuiTable,
    GroupuiTableCell,
    GroupuiText,
} from "@group-ui/group-ui-react";
import GenericFilterAndSearchBar from "./GenericFilterAndSearchBar";
import { searchColumns } from "./GenericSearchBar/GenericSearchBar";
import { filterColumns } from "./GenericFilter/GenericFilterBar";

const styles = {
    header: {
        borderTop: "0.0625rem solid rgb(223, 226, 230)",
        zIndex: "unset",
        color: "var(--groupui-sys-color-information-basic-default)",
    },
};

export type columnDefinition = {
    key: string;
    header?: string;
    width?: string;
    render?: (v: string | number | boolean | JSX.Element | null) => JSX.Element;
};

interface GenericTableProps {
    // columns is the definition of the column not the data!
    columns: columnDefinition[];
    // data for table => data keys need to match column definitions keys
    data: { [key: string]: string | number | boolean | JSX.Element | null }[];
    header?: boolean;
    indicator?: "alternative" | "default";
    scrollX?: boolean;
    scrollY?: boolean;
    sortable?: boolean;
    sticky?: boolean;
    striped?: boolean;
    noDataMessage?: string;
    pageSize?: number;
    filterColumns?: filterColumns;
    searchColumns?: searchColumns;
    filtersAndSearchWidth?: { filters: string; search: string };
    filterHeight?: string;
    pageSizeOptions?: string;
}

/**
 * A Table-Component, which allows you to display data in form of a table
 *
 * @param {columnDefinition[]} columns - sets the column-definition of table
 * @param {{ [key: string]: string | number | boolean | JSX.Element }[]} data - sets the data for the table, data keys need to match column definitions keys
 * @param {boolean} [header] - toggles if the column-names are displayed
 * @param {"alternative" | "default"} [indicator] - sets the icon for the indicator
 * @param {boolean} [scrollX]  - enables horizontal scrollbars
 * @param {boolean} [scrollY] - enables vertical scrollbars
 * @param {boolean} [sortable] - defines a table as sortable if header row is present (HINT: this just adds an icon to next to column names - sortable logic has to be implemented by yourself)
 * @param {boolean} [sticky] - defines all cells of the first row as sticky
 * @param {boolean} [striped] - changes table row styling in alternating colors
 * @param {string} [noDataMessage] - changes displayed message if no data is present
 * @param {number} [pageSize] - if set adds pagination and sets the default elements to be displayed per page
 * @param {filterColumns | undefined} filterColumns - optional parameter to define filters
 * @param {searchColumns | undefined} searchColumns - optional parameter to define search bar and columns to search
 * @param {{ filters: string; search: string } | undefined}filtersAndSearchWidth - optional parameter to set specific width for filters and search bar
 * @param {string} [filterHeight] - changes max-height of all filters
 * @param {string} [pageSizeOptions] - changes entries per page options
 *
 * @returns Table
 *
 */
const GenericTable: React.FC<GenericTableProps> = ({
    columns,
    data = [],
    header = false,
    indicator,
    scrollX,
    scrollY,
    sortable,
    sticky,
    striped,
    noDataMessage,
    pageSize,
    filterColumns = [],
    searchColumns = [],
    filtersAndSearchWidth,
    filterHeight = "",
    pageSizeOptions = "[5, 10, 20, 25, 50]",
}) => {
    const [page, setPage] = useState<number>(1);
    const [itemsPerPage, setItemsPerPage] = useState(pageSize);
    const [filteredData, setFilteredData] =
        useState<{ [key: string]: string | number | boolean | JSX.Element | null }[]>(data);
    const [pageData, setPageData] = useState<
        { [key: string]: string | number | boolean | JSX.Element | null }[]
    >([]);

    useEffect(() => {
        setPage(1);
        setItemsPerPage(pageSize);
    }, [filteredData.length]);

    useEffect(() => {
        if (itemsPerPage) {
            setPageData(filteredData.slice((page - 1) * itemsPerPage, page * itemsPerPage));
        }
    }, [itemsPerPage, page, filteredData]);

    return (
        <div>
            {data !== undefined && data.length > 0 && (
                <div
                    style={{
                        overflowX:
                            // Scrollable for cycles report
                            (columns[0].key === "orderId" &&
                                columns[2].key === "databoxSerialNumber") ||
                            // Scrollable for databoxes status report
                            (columns[0].key === "databoxName" &&
                                columns[1].key == "databoxSerialNumber") ||
                            // Scrollable for job report
                            (columns[0].key === "orderId" &&
                                columns[1].key === "databoxName" &&
                                columns[2].key === "carId")
                                ? "scroll"
                                : "inherit",
                    }}
                >
                    <GenericFilterAndSearchBar
                        data={data}
                        setFilteredData={setFilteredData}
                        filterColumns={filterColumns}
                        searchColumns={searchColumns}
                        width={filtersAndSearchWidth}
                        filterHeight={filterHeight ?? ""}
                    />
                    <GroupuiTable
                        columns={columns.length}
                        widths={columns.map((c) => c.width ?? "auto").join(" ")}
                        indicator={indicator}
                        scrollX={scrollX}
                        scrollY={scrollY}
                        sortable={sortable}
                        sticky={sticky}
                        striped={striped}
                        header={header}
                    >
                        {header && (
                            <>
                                {columns.map((c, j) => (
                                    <GroupuiTableCell
                                        key={`table-header-${j}`}
                                        style={styles.header}
                                    >
                                        {c.render ? c.render(c.header ?? c.key) : c.header ?? ""}
                                    </GroupuiTableCell>
                                ))}
                            </>
                        )}
                        {pageSize
                            ? pageData.map((d, i) =>
                                  columns.map((c, j) => (
                                      <GroupuiTableCell key={`table-row-${i * columns.length + j}`}>
                                          {c.render ? c.render(d[c.key]) : d[c.key]}
                                      </GroupuiTableCell>
                                  ))
                              )
                            : data.map((d, i) =>
                                  columns.map((c, j) => (
                                      <GroupuiTableCell key={`table-row-${i * columns.length + j}`}>
                                          {c.render ? c.render(d[c.key]) : d[c.key]}
                                      </GroupuiTableCell>
                                  ))
                              )}
                    </GroupuiTable>
                </div>
            )}
            {pageData.length != 0 && itemsPerPage && pageSize && pageSize < filteredData.length && (
                <GroupuiPagination
                    visibleElementsLabel="Item {displayed-items-start} to {displayed-items-end}"
                    elementsPerPageLabel={"Items per Page"}
                    pageSize={itemsPerPage}
                    selectedPage={page}
                    onGroupuiPageChange={(event) => {
                        setPage(event.detail.page);
                        setItemsPerPage(event.detail.size);
                    }}
                    pageSizeOptions={pageSizeOptions}
                    totalElements={filteredData.length}
                />
            )}
            {filteredData.length < 1 && pageData.length < 1 && (
                <GroupuiText
                    weight="bold"
                    style={{
                        textAlign: "center",
                        color: "var(--groupui-sys-color-text-weaker)",
                        weight: "bold",
                        size: "body-1",
                        margin: "var(--groupui-sys-spacing-600)",
                        justifyContent: "center",
                    }}
                >
                    {noDataMessage
                        ? noDataMessage
                        : `There is no data available${
                              data.length > 0 ? " matching search and filter criteria" : ""
                          }.`}
                </GroupuiText>
            )}
        </div>
    );
};

export default GenericTable;
