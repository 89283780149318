import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import {
    GroupuiIcon,
    GroupuiSelect,
    GroupuiSelectOption,
    GroupuiText,
    GroupuiTooltip,
} from "@group-ui/group-ui-react";
import { Country } from "../../models/country";
import { Databox } from "../../models/databox";
import { ApiService } from "../../services/apiService";
import ErrorContext from "../context/ErrorContext";
import { handleError } from "../context/ErrorContextUtils";
import DataboxCard, { DataboxType } from "./generic/DataboxCard";
import SpecificDataboxSelect from "./SpecificDataboxSelect";
import UserGroupContext from "../context/UserGroupContext";
import { RightsWrapper } from "../container/RightsWrapper";
import { RightsManagement } from "../../config/rightsManagement";
import { isInUserGroup } from "../../utils/RightsUtils";
import { DataboxResponse } from "../../models/databoxResponse";

const styles = {
    icon: {
        color: "var(--groupui-sys-color-action-basic-default)",
    },
    countrySelectionContainer: {
        display: "flex",
        flexDirection: "row",
        gap: "var(--groupui-sys-spacing-800)",
        alignItems: "end",
    } as React.CSSProperties,
    databoxSelectionContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
    } as React.CSSProperties,
};

interface DataboxSelectionProps {
    databoxes60Count: number;
    databoxes90Count: number;
    databoxes120Count: number;
    setDataboxes60Count: Dispatch<SetStateAction<number>>;
    setDataboxes90Count: Dispatch<SetStateAction<number>>;
    setDataboxes120Count: Dispatch<SetStateAction<number>>;
    setCountryID: Dispatch<SetStateAction<number>>;
    setCountryName: Dispatch<SetStateAction<string>>;
    setDataboxes60CountValid: Dispatch<SetStateAction<boolean>>;
    setDataboxes90CountValid: Dispatch<SetStateAction<boolean>>;
    setDataboxes120CountValid: Dispatch<SetStateAction<boolean>>;
    setSpecificDataboxes: Dispatch<SetStateAction<string[]>>;
    specificDataboxes: string[];
    setSpecifcDataboxDetails: Dispatch<SetStateAction<object>>;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    countryID: number;
}

const DataboxSelection: React.FC<DataboxSelectionProps> = ({
    databoxes60Count,
    databoxes90Count,
    databoxes120Count,
    setDataboxes60Count,
    setDataboxes90Count,
    setDataboxes120Count,
    setDataboxes60CountValid,
    setDataboxes90CountValid,
    setDataboxes120CountValid,
    setSpecificDataboxes,
    specificDataboxes,
    setSpecifcDataboxDetails,
    setCountryID,
    setCountryName,
    setIsLoading,
    countryID,
}) => {
    const [country, setCountry] = useState<boolean>(false);
    const [countryData, setCountryData] = useState<Country[] | null>();
    const [databoxes60, setDataboxes60] = useState<number>();
    const [databoxes90, setDataboxes90] = useState<number>();
    const [databoxes120, setDataboxes120] = useState<number>();

    const [freeDataboxes, setFreeDataboxes] = useState<Databox[]>();

    const [selectedCountryID, setSelectedCountryID] = useState<number>(0);

    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const { userGroups } = useContext(UserGroupContext);

    useEffect(() => {
        ApiService.getAllCountries()
            .then((response) => {
                setCountryData(response);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
            });
    }, []);

    useEffect(() => {
        if (countryID !== 0) {
            setSelectedCountryID(countryID);
        }
    }, [countryID]);

    const handleCountrySelectionChange = async (event: any) => {
        if (event.target.value !== undefined) {
            setIsLoading(true);
            await Promise.all([
                ApiService.getFreeDataboxesByCountryId(Number(event.target.value))
                    .then((databoxResponse: DataboxResponse) => {
                        setDataboxes60(databoxResponse.databox60TBCount);
                        setDataboxes90(databoxResponse.databox90TBCount);
                        setDataboxes120(databoxResponse.databox120TBCount);
                    })
                    .catch((error) => {
                        handleError(error, changeErrorMessage, changeShowError);
                    }),

                isInUserGroup(RightsManagement.ORDER_SPECIFIC_DATABOX, userGroups)
                    ? ApiService.getFreeMaintenanceReservedDataboxesByCountryId(
                          Number(event.target.value)
                      )
                          .then((response) => {
                              setFreeDataboxes(response ?? []);
                          })
                          .catch((error) => {
                              handleError(error, changeErrorMessage, changeShowError);
                          })
                    : setFreeDataboxes([]),
            ]);
        }
        if (countryData) {
            const country = countryData.filter((country) => {
                return country.id === Number(event.target.value);
            });
            setCountryName(country[0]["country"]);
        }

        setCountryID(Number(event.target.value));
        setCountry(true);
        setIsLoading(false);
    };

    return (
        <div>
            {!country && (
                <GroupuiText size={"body-1"} weight={"regular"}>
                    Please first select the country to which you want the databoxes delivered, then
                    you can proceed with the order.
                </GroupuiText>
            )}
            <br />
            <div style={styles.countrySelectionContainer}>
                <GroupuiSelect
                    required={true}
                    value={selectedCountryID !== 0 ? selectedCountryID.toString() : ""}
                    typeof="input"
                    onGroupuiChange={handleCountrySelectionChange}
                    onGroupuiDidLoad={(event) => {
                        if (event.target.value == undefined) setCountry(false);
                    }}
                >
                    {countryData?.map((element, index) => {
                        return (
                            <GroupuiSelectOption
                                key={`option-${index}`}
                                value={element.id.toString()}
                                onClick={() => setSelectedCountryID(element.id)}
                            >
                                {element.country}
                            </GroupuiSelectOption>
                        );
                    })}
                    <span slot="label">Country *</span>
                </GroupuiSelect>
                <GroupuiTooltip position="left">
                    <GroupuiIcon name="help-32" style={styles.icon} />
                    <div slot="tooltip">
                        If you cannot find the
                        <br />
                        country to which you
                        <br />
                        wish to send the
                        <br /> databoxes, please
                        <br />
                        contact Support.
                    </div>
                </GroupuiTooltip>
            </div>
            <br />
            <RightsWrapper
                authorizedUserGroup={RightsManagement.ORDER_SPECIFIC_DATABOX}
                userGroups={userGroups}
            >
                <SpecificDataboxSelect
                    freeDataboxes={freeDataboxes ?? []}
                    setSpecificDataboxes={setSpecificDataboxes}
                    specificDataboxes={specificDataboxes}
                    setSpecifcDataboxDetails={setSpecifcDataboxDetails}
                    disabled={
                        country == false ||
                        databoxes60Count > 0 ||
                        databoxes90Count > 0 ||
                        databoxes120Count > 0
                    }
                />
            </RightsWrapper>

            <div style={styles.databoxSelectionContainer}>
                <DataboxCard
                    databoxType={DataboxType.SMALL}
                    databoxMaxCount={databoxes60}
                    countrySelected={country}
                    specificDataboxSelected={specificDataboxes.length > 0}
                    databoxCount={databoxes60Count}
                    setDataboxCount={setDataboxes60Count}
                    changeIsValid={setDataboxes60CountValid}
                />
                <DataboxCard
                    databoxType={DataboxType.MIDDLE}
                    databoxMaxCount={databoxes90}
                    countrySelected={country}
                    specificDataboxSelected={specificDataboxes.length > 0}
                    databoxCount={databoxes90Count}
                    setDataboxCount={setDataboxes90Count}
                    changeIsValid={setDataboxes90CountValid}
                />
                <DataboxCard
                    databoxType={DataboxType.BIG}
                    databoxMaxCount={databoxes120}
                    countrySelected={country}
                    specificDataboxSelected={specificDataboxes.length > 0}
                    databoxCount={databoxes120Count}
                    setDataboxCount={setDataboxes120Count}
                    changeIsValid={setDataboxes120CountValid}
                />
            </div>
        </div>
    );
};

export default DataboxSelection;
