import React, { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { RightsWrapper } from "../container/RightsWrapper";
import UserGroupContext from "../context/UserGroupContext";
import { RightsManagement } from "../../config/rightsManagement";
import NonStandardRequestsOverview from "../nonStandardRequests/NonStandardRequestsOverview";
import NonStandardRequestDetails from "../nonStandardRequests/NonStandardRequestsDetails";

/**
 * A NonStandardRequestsRoutes Component, which defines the Nested Routes nested under the parent for non.standard requests-content
 *
 * @returns NonStandardRequestsRoutes
 *
 */
function NonStandardRequestsRoutes() {
    const { userGroups } = useContext(UserGroupContext);
    return (
        <RightsWrapper
            key={"first"}
            authorizedUserGroup={RightsManagement.NON_STANDARD_REQUESTS}
            userGroups={userGroups}
            section={true}
        >
            <Routes>
                <Route path="/:id" element={<NonStandardRequestDetails />} />
                <Route index element={<NonStandardRequestsOverview />} />
                {/* Redirect the user if the Route doesn´t exist */}
                <Route path="*" element={<Navigate to="/non-standard-requests" />} />
            </Routes>
        </RightsWrapper>
    );
}

export default NonStandardRequestsRoutes;
