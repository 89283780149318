import React from "react";
import { Navigate } from "react-router";

interface Props {
    children: React.ReactNode;
    authorizedUserGroup: string[];
    userGroups: string[];
    section?: boolean;
}

/**
 * A Rights-Wrapper Component, which displays content if the given userGroup matches one of the authorized userGroups
 *
 * @param {React.ReactNode} children - holds the given child-component
 * @param {string[]} authorizedUserGroup - array of userGroups like "admin" or "user"
 * @param {string} userGroup - "your" userGroup
 *
 * @returns Rights-Wrapper
 *
 */
export const RightsWrapper: React.FC<Props> = ({
    children,
    authorizedUserGroup,
    userGroups,
    section,
}) => {
    let foundUserGroup = false;

    userGroups.forEach((userGroup) => {
        if (authorizedUserGroup.includes(userGroup) == true) foundUserGroup = true;
    });

    return <div>{foundUserGroup ? children : section ? <Navigate to="/not-found" /> : <></>}</div>;
};
