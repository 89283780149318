import { IngestSlot } from "./ingestSlot/ingestSlot";
import { IngestSlotBooking } from "./ingestSlot/ingestSlotBooking";
import { Pause } from "./pause";

export class IngestJob {
    private _id;
    public smartHandsTicket1Id;
    public smartHandsTicket1Status;
    public smartHandsTicket2Id;
    public smartHandsTicket2Status;
    public ingestSlot;
    public jobStatus;
    public ingestSlotBooking;
    public created;
    public updated;
    public ingestId;
    public pause;

    constructor({
        id,
        smartHandsTicket1Id,
        smartHandsTicket1Status,
        smartHandsTicket2Id,
        smartHandsTicket2Status,
        ingestSlot,
        jobStatus,
        ingestSlotBooking,
        created,
        updated,
        ingestId,
        pause,
    }: {
        id: number;
        smartHandsTicket1Id: string | null;
        smartHandsTicket1Status: string | null;
        smartHandsTicket2Id: string | null;
        smartHandsTicket2Status: string | null;
        ingestSlot: IngestSlot | null;
        jobStatus: string | null;
        ingestSlotBooking: IngestSlotBooking | null;
        created: string;
        updated: string | null;
        ingestId: number | null;
        pause: Pause | null;
    }) {
        this._id = id;
        this.smartHandsTicket1Id = smartHandsTicket1Id;
        this.smartHandsTicket1Status = smartHandsTicket1Status;
        this.smartHandsTicket2Id = smartHandsTicket2Id;
        this.smartHandsTicket2Status = smartHandsTicket2Status;
        this.ingestSlot = ingestSlot;
        this.jobStatus = jobStatus;
        this.ingestSlotBooking = ingestSlotBooking;
        this.created = created;
        this.updated = updated;
        this.ingestId = ingestId;
        this.pause = pause;
    }

    public get id() {
        return this._id;
    }

    public get displayName() {
        return `IJ${this._id}`;
    }

    private toObject() {
        return {
            id: this._id,
            smartHandsTicket1Id: this.smartHandsTicket1Id,
            smartHandsTicket1Status: this.smartHandsTicket1Status,
            smartHandsTicket2Id: this.smartHandsTicket2Id,
            smartHandsTicket2Status: this.smartHandsTicket2Status,
            ingestSlot: this.ingestSlot,
            jobStatus: this.jobStatus,
            ingestSlotBooking: this.ingestSlotBooking,
            created: this.created,
            updated: this.updated,
            ingestId: this.ingestId,
            pause: this.pause,
        };
    }

    static fromSerialized(json: object): IngestJob | null {
        if (json == null) {
            return null;
        }

        return new IngestJob({
            id: json["id"],
            smartHandsTicket1Id: json["smartHandsTicket1Id"],
            smartHandsTicket1Status: json["smartHandsTicket1Status"],
            smartHandsTicket2Id: json["smartHandsTicket2Id"],
            smartHandsTicket2Status: json["smartHandsTicket2Status"],
            ingestSlot: IngestSlot.fromSerialized(json["ingestSlot"]),
            jobStatus: json["jobStatus"],
            ingestSlotBooking: IngestSlotBooking.fromSerialized(json["ingestSlotBooking"]),
            created: json["created"],
            updated: json["updated"],
            ingestId: json["ingestId"],
            pause: Pause.fromSerialized(json["pause"]),
        });
    }
}
