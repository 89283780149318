import { TrackerData } from "./trackerData";

export class Tracker {
    public id;
    public name;
    public isActive;
    public trackerData;

    constructor({
        id,
        name,
        isActive,
        trackerData,
    }: {
        id: number;
        name: string;
        isActive: boolean;
        trackerData: TrackerData[] | null;
    }) {
        this.id = id;
        this.name = name;
        this.isActive = isActive;
        this.trackerData = trackerData;
    }

    private toObject() {
        return {
            id: this.id,
            name: this.name,
            isActive: this.isActive,
            trackerData: this.trackerData,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): Tracker | null {
        if (json == null) {
            return null;
        }

        return new Tracker({
            id: json["id"],
            name: json["name"],
            isActive: json["isActive"],
            trackerData: json["trackerData"]
                ? json["trackerData"].map((trackerData: any) =>
                      TrackerData.fromSerialized(trackerData)
                  )
                : null,
        });
    }
}
