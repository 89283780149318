import React from "react";

import { GroupuiHeadline, GroupuiHeader } from "@group-ui/group-ui-react";

interface PortalHeaderProps {
    iconOnly: boolean;
}

/**
 * Returns an PortalHeader Component, which defines the shown logo on the website
 *
 * @param {boolean} iconOnly - switches between light and dark icon in the top left corner of the portal
 *
 * @returns PortalHeader Component
 *
 */
const PortalHeader: React.FC<PortalHeaderProps> = ({ iconOnly }) => {
    const styles = {
        icons: {
            height: "18px",
        },
        headerLogo: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            backgroundColor: iconOnly ? "white" : "",
            border: "none",
            boxShadow: "none",
        },
        headerTitle: {
            color: iconOnly ? "var(--groupui-sys-color-brand-core)" : "white",
        },
    };

    return (
        <div>
            <GroupuiHeader style={styles.headerLogo}>
                <img
                    style={styles.icons}
                    src={
                        iconOnly
                            ? "/static/svg/CARIAD_Logo_dark.svg"
                            : "/static/svg/CARIAD_Logo_light.svg"
                    }
                    alt="Audi logo"
                />
                <GroupuiHeadline style={styles.headerTitle} heading="h6">
                    Data Transport Portal
                </GroupuiHeadline>
            </GroupuiHeader>
        </div>
    );
};

export default PortalHeader;
