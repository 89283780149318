import React, { useEffect, useState } from "react";
import { Marker } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { useNavigate } from "react-router-dom";

const customMarkerHtmlStyles = (color: string) => `
  background-color: ${color};
`;

interface CustomMarker {
    position: [number, number];
    battery: number;
    databoxName: string;
    databoxId: number;
}

const CustomMarker: React.FC<CustomMarker> = ({ position, battery, databoxName, databoxId }) => {
    const navigate = useNavigate();
    const [color, setColor] = useState("");

    const updateColorBasedOnBattery = (battery: number) => {
        if (battery > 20) {
            return "var(--groupui-sys-color-success-basic-pressed)";
        } else if (battery > 0) {
            return "var(--groupui-sys-color-warning-basic-pressed)";
        } else {
            return "var(--groupui-sys-color-danger-basic-pressed)";
        }
    };

    useEffect(() => {
        setColor(updateColorBasedOnBattery(battery));
        updateColorBasedOnBattery(battery);
    }, [battery]);

    const icon = L.divIcon({
        className: "",
        iconAnchor: [20, 40],
        iconSize: [30, 30],
        html: `<div class="locationMarker" style="${customMarkerHtmlStyles(
            color
        )} --marker-color:${color};">${databoxName}</div>`,
    });

    const handleClick = () => {
        navigate(`/databox-pool/${databoxId}`);
    };

    return (
        <Marker
            position={position}
            icon={icon}
            eventHandlers={{
                click: handleClick,
            }}
        />
    );
};
export default CustomMarker;
