export class DataboxEditRequest {
    public owner?: string;
    public storageCapacity?: number;
    public serialNumber?: string;
    public purchaseOrderNumber?: string;
    public opxNumber?: string;
    public serviceContractId?: string;
    public ingestStationId?: number;
    public purchaseOrderDate?: string;
    public trackerId?: number;

    constructor({
        owner,
        storageCapacity,
        serialNumber,
        purchaseOrderNumber,
        opxNumber,
        serviceContractId,
        ingestStationId,
        purchaseOrderDate,
        trackerId,
    }: {
        owner?: string;
        storageCapacity?: number;
        serialNumber?: string;
        purchaseOrderNumber?: string;
        opxNumber?: string;
        serviceContractId?: string;
        ingestStationId?: number;
        purchaseOrderDate?: string;
        trackerId?: number;
    }) {
        this.owner = owner;
        this.storageCapacity = storageCapacity;
        this.serialNumber = serialNumber;
        this.purchaseOrderNumber = purchaseOrderNumber;
        this.opxNumber = opxNumber;
        this.serviceContractId = serviceContractId;
        this.ingestStationId = ingestStationId;
        this.purchaseOrderDate = purchaseOrderDate;
        this.trackerId = trackerId;
    }

    public serialize(): string {
        const payload = {
            owner: this.owner,
            storageCapacity: this.storageCapacity,
            serialNumber: this.serialNumber,
            purchaseOrderNumber: this.purchaseOrderNumber,
            opxNumber: this.opxNumber,
            serviceContractId: this.serviceContractId,
            ingestStationId: this.ingestStationId,
            purchaseOrderDate: this.purchaseOrderDate,
            trackerId: this.trackerId,
        };

        const filteredPayload = Object.entries(payload).reduce((acc, [key, value]) => {
            if (value !== undefined) {
                acc[key] = value;
            }
            return acc;
        }, {});

        return JSON.stringify(filteredPayload);
    }
}
