export class UpsShipmentLabel {
    private _id;
    public shipmentIdenfificationNumber;
    public trackingNumber;
    public label;
    public disclaimer;
    public shipmentDate;
    public pickedUp;

    constructor({
        id,
        shipmentIdenfificationNumber,
        trackingNumber,
        label,
        disclaimer,
        shipmentDate,
        pickedUp,
    }: {
        id: number;
        shipmentIdenfificationNumber: string;
        trackingNumber: string;
        label: string;
        disclaimer: string;
        shipmentDate: string;
        pickedUp: string;
    }) {
        this._id = id;
        this.shipmentIdenfificationNumber = shipmentIdenfificationNumber;
        this.trackingNumber = trackingNumber;
        this.label = label;
        this.disclaimer = disclaimer;
        this.shipmentDate = shipmentDate;
        this.pickedUp = pickedUp;
    }

    public get id() {
        return this._id;
    }

    private toObject() {
        return {
            id: this._id,
            shipmentIdenfificationNumber: this.shipmentIdenfificationNumber,
            trackingNumber: this.trackingNumber,
            label: this.label,
            disclaimer: this.disclaimer,
            shipmentDate: this.shipmentDate,
            pickedUp: this.pickedUp,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): UpsShipmentLabel | null {
        if (json == null) {
            return null;
        }

        return new UpsShipmentLabel({
            id: json["id"],
            shipmentIdenfificationNumber: json["shipmentIdentificationNumber"],
            trackingNumber: json["trackingNumber"],
            label: json["label"],
            disclaimer: json["disclaimer"],
            shipmentDate: json["shipmentDate"],
            pickedUp: json["pickedUp"],
        });
    }
}
