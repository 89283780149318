import React, { useContext, useEffect, useState } from "react";
import ErrorContext from "../../context/ErrorContext";
import { handleError } from "../../context/ErrorContextUtils";
import { ApiService } from "../../../services/apiService";
import {
    GroupuiModal,
    GroupuiLoadingSpinner,
    GroupuiIcon,
    GroupuiTooltip,
} from "@group-ui/group-ui-react";
import GenericTable, { columnDefinition } from "../../generics/GenericTable";

import GenericTag from "../../generics/GenericTag";
import { useNavigate } from "react-router-dom";
import { IngestStationOverview } from "../../../models/ingestStationOverview";
import { DataboxDashboardOverview } from "../../../models/databoxDashboardOverview";

const styles = {
    magnifyingGlass: {
        cursor: "pointer",
        color: "var(--groupui-sys-color-action-basic-default)",
    },
};
const columns: columnDefinition[] = [
    {
        key: "databoxName",
        header: "Databox Name",
        width: "auto",
    },
    {
        key: "storageCapacity",
        header: "Storage Capacity",
        width: "auto",
    },
    {
        key: "currentState",
        header: "Current State",
        width: "auto",
    },
    {
        key: "owner",
        header: "Owner",
        width: "auto",
    },
    {
        key: "tracker",
        header: "Tracker",
        width: "auto",
    },
    {
        key: "button",
        width: "var(--groupui-sys-spacing-1800)",
    },
];
interface Props {
    showModal: boolean;
    station: IngestStationOverview;
    closedDataboxPerStatio: () => void;
}
const DataboxesPerStationOverview: React.FC<Props> = ({
    showModal,
    station,
    closedDataboxPerStatio,
}) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [databoxesPerStation, setDataboxesPerStation] = useState<DataboxDashboardOverview[]>();
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);

    useEffect(() => {
        getData();
    }, [showModal]);

    const getData = () => {
        setIsLoading(true);
        ApiService.getAllDataboxesOverview(station.id)
            .then((response) => {
                setDataboxesPerStation(response);
                setIsLoading(false);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
                setIsLoading(false);
            });
    };
    const data =
        databoxesPerStation?.map((databoxDashboardOverview: DataboxDashboardOverview) => {
            return {
                id: databoxDashboardOverview.id,
                databoxName: databoxDashboardOverview.name,
                storageCapacity: databoxDashboardOverview.storageCapacity,
                currentState: (
                    <GenericTag
                        text={databoxDashboardOverview.mediaState.mediaStateType}
                        variant="primary"
                        mediaStateTarget="databox"
                    />
                ),
                owner: databoxDashboardOverview.owner,
                tracker: databoxDashboardOverview.tracker,
                button: (
                    <GroupuiTooltip position="left" style={{ position: "relative" }}>
                        <GroupuiIcon
                            name="search-plus-32"
                            style={styles.magnifyingGlass}
                            onClick={() => navigate(`/databox-pool/${databoxDashboardOverview.id}`)}
                        />
                        <div slot="tooltip">Show Details</div>
                    </GroupuiTooltip>
                ),
            };
        }) ?? [];
    return (
        <>
            <GroupuiLoadingSpinner displayed={isLoading} />
            {!isLoading && (
                <GroupuiModal
                    closeButton={true}
                    padding="45px"
                    displayed={showModal}
                    onGroupuiModalClose={closedDataboxPerStatio}
                    style={{ justifyContent: "center", alignItems: "center" }}
                    maxWidth="250"
                    overflow="visible"
                >
                    {databoxesPerStation && (
                        <div>
                            <GenericTable
                                pageSize={5}
                                columns={columns}
                                header={true}
                                pageSizeOptions={"[5, 10]"}
                                data={data}
                            />
                        </div>
                    )}
                </GroupuiModal>
            )}
        </>
    );
};
export default DataboxesPerStationOverview;
