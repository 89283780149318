import {
    GroupuiCard,
    GroupuiHeadline,
    GroupuiIcon,
    GroupuiLink,
    GroupuiLoadingSpinner,
    GroupuiText,
    GroupuiTooltip,
} from "@group-ui/group-ui-react/dist/components";
import React, { useContext, useEffect, useState } from "react";
import { ApiService } from "../../services/apiService";
import { formatSpeed, getUploadSpeed } from "../../utils/DataVolumeUtil";
import ErrorContext from "../context/ErrorContext";
import { handleError } from "../context/ErrorContextUtils";
import GenericTable, { columnDefinition } from "../generics/GenericTable";
import GenericTag from "../generics/GenericTag";
import ProgressBar from "../generics/ProgressBar";
import IngestStationSelector from "./IngestStationSelector";
import ColorDot from "../generics/ColorDot";
import { IngestStationNameAndId } from "../../models/ingestStationNameAndId";
import {
    getNextStepLabelForDatabaseName,
    getStepLabelForDatabaseName,
    getTimePassed,
} from "../../utils/DashboardUtils";
import { IngestSlotMonitorData } from "../../models/ingestSlotMonitorData";
import DashboardDetails from "./dashboardDetails/DashboardDetails";
import IngestStationOverviews from "./dashboardIngestStationOverview/IngestStationOverviews";
import { useNavigate } from "react-router-dom";

const styles = {
    title: {
        marginBottom: "var(--groupui-sys-spacing-500)",
        display: "flex",
        flexDirection: "row" as const,
        justifyContent: "space-between",
        marginTop: "var(--groupui-sys-spacing-400)",
    },
    ingestSlotSelector: {
        width: "300px",
    },
    noStationSelected: {
        textAlign: "center",
        color: "var(--groupui-vwag-color-grey-600)",
        weight: "bold",
        size: "body-1",
        margin: "var(--groupui-sys-spacing-500)",
    },
    magnifyingGlass: {
        cursor: "pointer",
        color: "var(--groupui-sys-color-action-basic-default)",
    },
    errorDetails: {
        maxWidth: "350px",
        wordWrap: "break-word",
    } as React.CSSProperties,
};
const columns: columnDefinition[] = [
    {
        key: "colorDot",
        header: "",
        width: "2rem",
    },
    {
        key: "name",
        header: "Name",
        width: "auto",
    },
    {
        key: "state",
        header: "Current State",
        width: "auto",
    },
    {
        key: "databoxName",
        header: "Databox Name",
        width: "auto",
    },
    {
        key: "details",
        header: "Details",
        width: "auto",
    },
    {
        key: "uploadSpeed",
        header: "Upload Speed",
        width: "auto",
    },
    {
        key: "uploadProgress",
        header: "Progress",
        width: "auto",
    },
    {
        key: "timePassed",
        header: "Time Passed",
        width: "auto",
    },
    {
        key: "button",
        width: "4rem",
    },
];

interface IngestSlotMonitorProps {
    lastUpdated: string;
}

function getProgressbar(entry: IngestSlotMonitorData) {
    if (entry.state.toLowerCase() === "error") {
        if (entry.uploadProgress != undefined) {
            let color = "var(--groupui-sys-color-danger-basic-default)";
            if (
                entry.overwriteState !== null &&
                entry.overwriteState !== undefined &&
                entry.overwriteState !== "Patching Data" &&
                entry.overwriteState !== "Mounting Issue" &&
                entry.overwriteState !== "Hardware Issue Databox" &&
                entry.overwriteState !== "Hardware Issue Slot"
            )
                color = "var(--groupui-sys-color-danger-basic-default)";
            if (entry.overwriteState === "Patching Data")
                color = "var(--groupui-sys-color-warning-basic-hover)";
            return (
                <ProgressBar
                    completion={
                        Math.round((entry.uploadProgress ?? 0) * 100) > 100
                            ? 100
                            : Math.round((entry.uploadProgress ?? 0) * 100)
                    }
                    color={color}
                />
            );
        } else {
            if (!entry.lastStep) return "";
            let color = "var(--groupui-sys-color-danger-basic-default)";
            const stepIndex = Number(entry.lastStep.split("_")[1]);
            if (isNaN(stepIndex)) return "";
            let progress = stepIndex - 10;
            if (progress > 100) progress = 100;
            if (
                entry.overwriteState !== null &&
                entry.overwriteState !== undefined &&
                entry.overwriteState !== "Patching Data" &&
                entry.overwriteState !== "Mounting Issue" &&
                entry.overwriteState !== "Hardware Issue Databox" &&
                entry.overwriteState !== "Hardware Issue Slot"
            )
                color = "var(--groupui-sys-color-danger-basic-default)";
            if (entry.overwriteState === "Patching Data")
                color = "var(--groupui-sys-color-warning-basic-hover)";

            return <ProgressBar completion={progress} color={color} />;
        }
    }
    if (entry.state.toLowerCase() === "uploading") {
        return (
            <ProgressBar
                completion={
                    Math.round((entry.uploadProgress ?? 0) * 100) > 100
                        ? 100
                        : Math.round((entry.uploadProgress ?? 0) * 100)
                }
                color={"var(--groupui-sys-color-success-basic-default)"}
            />
        );
    }
    if (entry.state.toLowerCase() === "pre-upload") {
        const stepIndex = Number(entry.lastStep.split("_")[1]);
        if (isNaN(stepIndex)) return "";
        let progress = stepIndex - 10;
        if (progress > 100) progress = 100;
        return (
            <ProgressBar
                completion={progress}
                color={"var(--groupui-sys-color-success-basic-default)"}
            />
        );
    }
    return "";
}
/**
 * A IngestSlotMonitor Component, which displays the table with running ingests with the connected slots and databoxes
 *
 * @param {() => void} updateTimestamp - function to be run to update the timestamp in the parent component
 *
 * @returns IngestSlotMonitor Component
 *
 */
const IngestSlotMonitor: React.FC<IngestSlotMonitorProps> = ({ lastUpdated }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [ingestStation, setIngestStation] = useState<IngestStationNameAndId>();
    const [data, setData] = useState<IngestSlotMonitorData[]>();
    const [showDetail, setShowDetail] = useState(false);
    const [specificIngestSlotMonitorData, setSpecificIngestSlotMonitorData] =
        useState<IngestSlotMonitorData>();
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);

    useEffect(() => {
        if (ingestStation !== undefined) {
            setIsLoading(true);
            ApiService.getIngestSlotMonitorDataForIngestStation(ingestStation.id)
                .then((data) => {
                    setData(data);
                })
                .catch((error) => {
                    handleError(error, changeErrorMessage, changeShowError);
                    setIsLoading(false);
                });
            setIsLoading(false);
        }
    }, [ingestStation, lastUpdated]);

    function getColorDot(status: string | undefined) {
        if (status !== undefined) {
            switch (status.toLowerCase()) {
                case "off":
                    return "grey";
                case "maintenance":
                    return "orange";
                case "on":
                    return "green";
                default:
                    return "grey";
            }
        } else {
            return "grey";
        }
    }

    function colorDotTooltip(color: string) {
        switch (color) {
            case "green":
                return "The ingest slot is switched on";
            case "orange":
                return "The ingest slot was started in maintenance mode";
            case "grey":
                return "The ingest slot is switched off / no information available";
            default:
                return "The ingest slot is switched off / no information available";
        }
    }

    return (
        <>
            <GroupuiCard elevation="1" style={{ marginBottom: "1.5rem" }}>
                <GroupuiHeadline heading={"h4"}>Ingest Station Overview</GroupuiHeadline>
                <IngestStationOverviews />
            </GroupuiCard>
            <GroupuiCard elevation="1">
                <GroupuiLoadingSpinner displayed={isLoading} />
                <div style={styles.title}>
                    <GroupuiHeadline heading={"h4"}>Ingest Slot Status</GroupuiHeadline>
                    <div style={styles.ingestSlotSelector}>
                        <IngestStationSelector
                            isLoading={setIsLoading}
                            value={ingestStation}
                            onChange={setIngestStation}
                            localStorageKey="dashboard-ingestSlotMonitor-ingestStation"
                        />
                    </div>
                </div>
                {data !== undefined && (
                    <div className="table-live-demo container-border">
                        <GenericTable
                            pageSize={10}
                            columns={columns}
                            data={data.map((e) => ({
                                colorDot: (
                                    <GroupuiTooltip
                                        position="left"
                                        style={{
                                            display: "flex",
                                            position: "relative",
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        <ColorDot
                                            color={
                                                e.status !== undefined
                                                    ? getColorDot(e.status)
                                                    : "grey"
                                            }
                                        />
                                        <div slot="tooltip">
                                            {colorDotTooltip(getColorDot(e.status))}
                                        </div>
                                    </GroupuiTooltip>
                                ),
                                name: (
                                    <GroupuiLink
                                        onClick={() =>
                                            navigate(
                                                `/ingest-stations/${e.ingestStationId}/${e.ingestSlotId}`
                                            )
                                        }
                                    >
                                        {e.name}
                                    </GroupuiLink>
                                ),
                                state: e.markedAborted ? (
                                    <GenericTag
                                        text={e.state}
                                        variant="primary"
                                        mediaStateTarget="ingest-slot"
                                        hasSmartHands={e.hasSmartHands}
                                    />
                                ) : e.overwriteState && e.overwrittenSince ? (
                                    <GenericTag
                                        text={e.overwriteState}
                                        variant="primary"
                                        mediaStateTarget="ingest-slot"
                                        hasSmartHands={e.hasSmartHands}
                                    />
                                ) : (
                                    <GenericTag
                                        text={e.state}
                                        variant="primary"
                                        mediaStateTarget="ingest-slot"
                                        hasSmartHands={e.hasSmartHands}
                                    />
                                ),
                                databoxName:
                                    e.state.toLowerCase() === "uploading" ||
                                    e.state.toLowerCase() === "pre-upload" ||
                                    e.state.toLowerCase() === "error" ||
                                    e.state.toLowerCase() === "reserved"
                                        ? e.databoxName
                                        : "",
                                details: (
                                    <GroupuiText>
                                        {e.state.toLowerCase() === "uploading" ||
                                        e.state.toLowerCase() === "pre-upload"
                                            ? getStepLabelForDatabaseName(e.lastStep)
                                            : ""}

                                        {e.state.toLowerCase() === "error" && (
                                            <>
                                                Last successful step:{" "}
                                                {getStepLabelForDatabaseName(e.lastStep)}
                                                <br />
                                                Error at:{" "}
                                                {getNextStepLabelForDatabaseName(e.lastStep)}
                                                {e.detailsMessage !== undefined &&
                                                    e.detailsMessage !== null && (
                                                        <div style={styles.errorDetails}>
                                                            <>{e.detailsMessage}</>
                                                        </div>
                                                    )}
                                            </>
                                        )}
                                    </GroupuiText>
                                ),
                                uploadSpeed:
                                    (e.state.toLowerCase() === "uploading" ||
                                        e.state.toLowerCase() === "error") &&
                                    e.uploadStart !== undefined &&
                                    e.uploadedBytes !== undefined &&
                                    e.lastStepTimestamp !== undefined
                                        ? formatSpeed(
                                              getUploadSpeed(
                                                  e.uploadedBytes,
                                                  e.uploadStart,
                                                  e.lastStepTimestamp
                                              )
                                          )
                                        : "",
                                uploadProgress: getProgressbar(e),
                                timePassed: getTimePassed(e),
                                button: e.state !== "Free" && (
                                    <div
                                        style={{
                                            display: "flex",
                                            position: "relative",
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        <GroupuiTooltip position="left">
                                            <GroupuiIcon
                                                name="search-plus-32"
                                                style={styles.magnifyingGlass}
                                                onClick={() => {
                                                    setShowDetail(true);
                                                    setSpecificIngestSlotMonitorData(e);
                                                }}
                                            />
                                            <div slot="tooltip">Show Details</div>
                                        </GroupuiTooltip>
                                    </div>
                                ),
                            }))}
                            header={true}
                        />
                    </div>
                )}
                {ingestStation === undefined && (
                    <div>
                        <GroupuiText weight="bold" style={styles.noStationSelected}>
                            Please select an ingest station.
                        </GroupuiText>
                    </div>
                )}
                {showDetail && specificIngestSlotMonitorData && (
                    <DashboardDetails
                        showDetails={showDetail}
                        onClose={() => setShowDetail(false)}
                        ingestSlotMonitorData={specificIngestSlotMonitorData}
                    />
                )}
            </GroupuiCard>
        </>
    );
};

export default IngestSlotMonitor;
