import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { RightsWrapper } from "../container/RightsWrapper";
import UserGroupContext from "../context/UserGroupContext";
import Ingests from "../ingests/Ingests";
import { RightsManagement } from "../../config/rightsManagement";

/**
 * A IngestsRoutes Component, which defines the Nested Routes nested under the parent for ingests-content
 *
 * @returns IngestsRoutes
 *
 */
function IngestsRoutes() {
    const { userGroups } = useContext(UserGroupContext);
    return (
        <RightsWrapper
            key={"first"}
            authorizedUserGroup={RightsManagement.INGESTS_ROUTES}
            userGroups={userGroups}
            section={true}
        >
            <Routes>
                <Route index element={<Ingests />} />
            </Routes>
        </RightsWrapper>
    );
}

export default IngestsRoutes;
