import {
    GroupuiModal,
    GroupuiHeadline,
    GroupuiText,
    GroupuiButton,
    GroupuiLoadingSpinner,
} from "@group-ui/group-ui-react";
import React, { useContext, useState } from "react";
import ErrorContext from "../../../context/ErrorContext";
import { ApiService } from "../../../../services/apiService";
import Snackbar from "../../../generics/Snackbar";
import { handleError } from "../../../context/ErrorContextUtils";
import GenericHeadline from "../../../generics/GenericHeadline";

interface DeleteStateModalProps {
    showModal: boolean;
    setShowModal: (value: boolean) => void;
    onModalClose: () => void;
    onSend: () => void;
    selectedEntryId: number;
}

export const DeleteStateModal: React.FC<DeleteStateModalProps> = ({
    showModal,
    setShowModal,
    onModalClose,
    onSend,
    selectedEntryId,
}) => {
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
    const [severity, setSeverity] = useState<"danger" | "success" | "warning">();
    const [message, setMessage] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const deleteBooking = () => {
        setIsLoading(true);
        ApiService.deleteIngestSlotBooking(selectedEntryId)
            .then((response) => {
                if (response.toLowerCase() === "success") {
                    setMessage("Successful deleted Ingest Slot Booking!");
                    setSeverity("success");
                    setShowSnackBar(true);
                    const timeout = setTimeout(() => {
                        onSend();
                        setIsLoading(false);
                    }, 2000);
                } else {
                    setSeverity("danger");
                    setMessage("Couldn´t delete Ingest Slot Booking!");
                    setShowSnackBar(true);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
                setIsLoading(false);
            });
    };

    return (
        <GroupuiModal
            closeButton={true}
            displayed={showModal}
            onGroupuiModalClose={onModalClose}
            padding="var(--groupui-sys-spacing-800)"
            margin="200px"
            max-width="20"
        >
            <GroupuiLoadingSpinner displayed={isLoading} />
            {showSnackBar && (
                <Snackbar
                    severity={severity ?? "danger"}
                    message={message ?? "Some error occurred!"}
                    onClose={() => {
                        setShowSnackBar(false);
                    }}
                />
            )}
            <GenericHeadline
                title="Delete State?"
                titleHeadingType="h3"
                type="modal"
                actionButtons={[]}
            />
            <GroupuiText style={{ width: "390px" }}>
                Are you sure you want to delete this state?
            </GroupuiText>
            <br />
            <div
                style={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "var(--groupui-sys-spacing-400)",
                }}
            >
                <GroupuiButton onClick={() => setShowModal(false)} variant={"secondary"}>
                    Cancel
                </GroupuiButton>
                <GroupuiButton
                    style={{ outline: "none" }}
                    size="m"
                    severity="danger"
                    icon="trash-32"
                    onClick={() => deleteBooking()}
                >
                    Delete
                </GroupuiButton>
            </div>
        </GroupuiModal>
    );
};
