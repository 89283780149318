import {
    GroupuiButton,
    GroupuiHeadline,
    GroupuiInput,
    GroupuiModal,
} from "@group-ui/group-ui-react";
import React, { useContext, useState } from "react";
import { Project } from "../../../models/project";
import { ApiService } from "../../../services/apiService";
import ErrorContext from "../../context/ErrorContext";
import Snackbar from "../../generics/Snackbar";
import { handleError } from "../../context/ErrorContextUtils";
import GenericHeadline from "../../generics/GenericHeadline";

interface Props {
    showModal: boolean;
    setShowModal: (value: boolean) => void;
    onModalClose: () => void;
    onSend: () => void;
    projects: Project[] | null;
}

const AddProjectModal: React.FC<Props> = ({
    showModal,
    setShowModal,
    onModalClose,
    onSend,
    projects,
}) => {
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const [projectName, setProjectName] = useState<string>("");
    const [responsiblePerson, setResponsiblePerson] = useState<string>("");
    const [department, setDepartment] = useState<string>("");

    const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
    const [severity, setSeverity] = useState<"danger" | "success" | "warning">();
    const [message, setMessage] = useState<string>();

    const checkValues = (): boolean => {
        if (projectName == null || responsiblePerson === "" || department == "") return true;
        else return false;
    };

    const handleSubmit = (event: any) => {
        if (handleValidation()) {
            const created = String(Date.now());
            const project = new Project({
                id: 1,
                name: projectName,
                responsiblePerson: responsiblePerson,
                department: department,
                created: created,
                updated: null,
            });
            ApiService.addProject(project)
                .then((response) => {
                    if (response.toLowerCase() == "failed") {
                        event.preventDefault();
                        handleError(
                            "Project couldn`t be added!",
                            changeErrorMessage,
                            changeShowError
                        );
                    } else {
                        setSeverity("success");
                        setMessage("Successfully created a Project!");
                        setShowSnackBar(true);
                    }
                })
                .catch((error) => {
                    handleError(error, changeErrorMessage, changeShowError);
                });
            setProjectName("");
            setResponsiblePerson("");
            setDepartment("");
            onSend();
        }
    };

    const handleValidation = (): boolean => {
        let isValid = true;
        projects?.forEach(function (project) {
            if (project.name === projectName) {
                isValid = false;
                handleError(
                    "This name is already used for another project",
                    changeErrorMessage,
                    changeShowError
                );
            }
        });

        return isValid;
    };

    return (
        <>
            {showSnackBar && (
                <Snackbar
                    severity={severity ?? "danger"}
                    message={message ?? "Some error occurred!"}
                    onClose={() => {
                        setShowSnackBar(false);
                    }}
                />
            )}
            <GroupuiModal
                closeButton={true}
                displayed={showModal}
                onGroupuiModalClose={onModalClose}
                margin="100px"
            >
                <GenericHeadline
                    title="Add Project"
                    titleHeadingType="h3"
                    type="modal"
                    actionButtons={[]}
                />
                <form
                    onSubmit={handleSubmit}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "var(--groupui-sys-spacing-400)",
                    }}
                >
                    <GroupuiInput
                        value={projectName}
                        maxlength={50}
                        pattern="[A-Za-z]{3}"
                        severity="none"
                        type="text"
                        required={true}
                        onGroupuiChange={(event) => setProjectName(event.target.value)}
                    >
                        <span slot="label">Project Name *</span>
                    </GroupuiInput>
                    <GroupuiInput
                        value={responsiblePerson}
                        maxlength={50}
                        pattern="[A-Za-z]{3}"
                        severity="none"
                        type="text"
                        required={true}
                        onGroupuiChange={(event) => setResponsiblePerson(event.target.value)}
                    >
                        <span slot="label">Responsible Person *</span>
                    </GroupuiInput>
                    <GroupuiInput
                        value={department}
                        maxlength={50}
                        pattern="[A-Za-z]{3}"
                        severity="none"
                        type="text"
                        required={true}
                        onGroupuiChange={(event) => setDepartment(event.target.value)}
                    >
                        <span slot="label">Department *</span>
                    </GroupuiInput>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "end",
                            gap: "var(--groupui-sys-spacing-400)",
                            marginTop: "var(--groupui-sys-spacing-600)",
                        }}
                    >
                        <GroupuiButton variant={"secondary"} onClick={() => setShowModal(false)}>
                            Cancel
                        </GroupuiButton>
                        <GroupuiButton icon="add-32" disabled={checkValues()} type={"submit"}>
                            Add
                        </GroupuiButton>
                    </div>
                </form>
            </GroupuiModal>
        </>
    );
};

export default AddProjectModal;
