import React, { useContext, useState } from "react";
import GenericHeadline from "../generics/GenericHeadline";
import {
    GroupuiButton,
    GroupuiDivider,
    GroupuiLoadingSpinner,
    GroupuiModal,
    GroupuiText,
} from "@group-ui/group-ui-react";
import ErrorContext from "../context/ErrorContext";
import { ApiService } from "../../services/apiService";
import { handleError } from "../context/ErrorContextUtils";
import Snackbar from "../generics/Snackbar";
interface Props {
    orderId: number;
    modal: boolean;
    setModal: (value: boolean) => void;
}

const CompleteOrderModal: React.FC<Props> = ({ modal, setModal, orderId }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
    const [severity, setSeverity] = useState<"success" | "danger">("success");
    const [message, setMessage] = useState<string>("");

    const sendRequest = () => {
        setLoading(true);
        ApiService.completeOrder(orderId)
            .then((response) => {
                if (response.startsWith("success")) {
                    setLoading(false);
                    setMessage("Successfully completed the order!");
                    setSeverity("success");
                    setShowSnackbar(true);
                    setTimeout(() => {
                        setModal(false);
                        window.location.reload();
                    }, 2000);
                } else {
                    setShowSnackbar(true);
                    setMessage("An error occurred during reset of the overwrite state!");
                    setSeverity("danger");

                    setShowSnackbar(true);
                    setLoading(false);
                }
            })
            .catch((error) => {
                setLoading(false);
                handleError(error, changeErrorMessage, changeShowError);
            });
    };

    return (
        <GroupuiModal
            displayed={modal}
            onGroupuiModalClose={() => setModal(false)}
            closeButton={true}
            overflow="visible"
            margin="200px"
            padding="var(--groupui-sys-spacing-800)"
        >
            <GroupuiLoadingSpinner displayed={loading} />
            {showSnackbar && (
                <Snackbar
                    severity={severity}
                    message={message}
                    onClose={() => setShowSnackbar(false)}
                />
            )}
            <GenericHeadline
                title={"Complete Databox Order"}
                titleHeadingType="h4"
                type="modal"
                actionButtons={[]}
            />

            <div
                style={{
                    display: "flex",
                    gap: "var(--groupui-sys-spacing-200)",
                    flexDirection: "column",
                }}
            >
                <GroupuiDivider />
                <GroupuiText>
                    When you complete the related databox order (OR{orderId}), the 2nd Equinix
                    SmartHands ticket is set to &quot;Completed&quot; on portal side, the order is
                    given the status &quot;Order completed&quot;, and the status of the databox and
                    ingest slot is set to &quot;Free&quot;.
                </GroupuiText>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "var(--groupui-sys-spacing-400)",
                        marginTop: "var(--groupui-sys-spacing-600)",
                    }}
                >
                    <GroupuiButton variant={"secondary"} onClick={() => setModal(false)}>
                        Cancel
                    </GroupuiButton>
                    <GroupuiButton icon="check-32" onClick={sendRequest}>
                        Confirm
                    </GroupuiButton>
                </div>
            </div>
        </GroupuiModal>
    );
};

export default CompleteOrderModal;
