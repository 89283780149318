import React, { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { RightsWrapper } from "../container/RightsWrapper";
import UserGroupContext from "../context/UserGroupContext";
import { RightsManagement } from "../../config/rightsManagement";
import InCarMounts from "../inCarMounts/InCarMounts";
import InCarMountDetailsWrapper from "../container/InCarMountDetailsWrapper";
import InCarMountDetails from "../inCarMounts/InCarMountsDetails";
import { InCarMountProvider } from "../context/provider/InCarMountContext";

/**
 * A InCarMountsRoutes Component, which defines the Nested Routes nested under the parent for iventory-content
 *
 * @returns InCarMountsRoutes
 *
 */
function InCarMountsRoutes() {
    const { userGroups } = useContext(UserGroupContext);
    return (
        <RightsWrapper
            key={"first"}
            authorizedUserGroup={RightsManagement.INCARMOUNTS_ROUTES}
            userGroups={userGroups}
            section={true}
        >
            <InCarMountProvider>
                <Routes>
                    <Route index element={<InCarMounts />} />
                    <Route path=":inCarMountID/*" element={<InCarMountDetailsWrapper />}>
                        <Route index element={<InCarMountDetails />} />
                        {/* Redirect the user if the Route doesn´t exist */}
                        <Route path="*" element={<Navigate to="/in-car-mounts" />} />
                    </Route>
                </Routes>
            </InCarMountProvider>
        </RightsWrapper>
    );
}

export default InCarMountsRoutes;
