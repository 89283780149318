import React, { useEffect } from "react";
import { GroupuiHeadline, GroupuiButton } from "@group-ui/group-ui-react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../config/config";
import useTitle from "../hooks/useTitle";

const styles = {
    landingpage: {
        backgroundImage: "url(/static/img/Landingpage_BG.jpg)",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "calc(100vh - 4rem)",
        display: "flex",
        flexDirection: "column" as const,
    },
    welcomeMessageTop: {
        color: "white",
        display: "flex",
        justifyContent: "flex-end",
        marginRight: "100px",
        fontSize: "80px",
        marginTop: "50px",
    },
    welcomeMessageBottom: {
        color: "white",
        display: "flex",
        justifyContent: "flex-end",
        marginRight: "100px",
        fontSize: "80px",
    },
    loginButton: {
        color: "white",
        display: "flex",
        justifyContent: "flex-end",
        marginRight: "100px",
    },
};

function LandingPage() {
    const { instance } = useMsal();

    // Hook to display CSI Portal 2.0 in Tab Header
    useTitle("");

    useEffect(() => {
        instance.acquireTokenSilent(loginRequest).catch(() => {
            console.log("acquireTokenSilent failed. User needs to login!");
        });
    }, [instance]);

    const handleLogin = () => {
        instance.loginRedirect(loginRequest).catch((e) => {
            console.log(e);
        });
    };

    return (
        <div style={styles.landingpage} data-testid="background">
            <div style={{ justifyContent: "flex-end" }}>
                <GroupuiHeadline
                    style={styles.welcomeMessageTop}
                    heading="h1"
                    weight="bold"
                    marketing={true}
                >
                    Welcome to the
                </GroupuiHeadline>
                <GroupuiHeadline
                    style={styles.welcomeMessageBottom}
                    heading="h1"
                    weight="bold"
                    marketing={true}
                >
                    Data Transport Portal
                </GroupuiHeadline>
                <GroupuiButton
                    style={styles.loginButton}
                    severity="none"
                    target="self"
                    size="l"
                    iconOnly={false}
                    alignment="center"
                    icon="login-24"
                    inverted={true}
                    onClick={() => handleLogin()}
                >
                    Login
                </GroupuiButton>
            </div>
        </div>
    );
}

export default LandingPage;
