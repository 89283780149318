import React from "react";
import { Order } from "../../models/order";
import GenericHeadline from "../generics/GenericHeadline";
import GenericInput from "../generics/GenericInput";
import { GenericPhoneInput } from "../generics/GenericPhoneInput";
interface Props {
    order: Order;
    disabled?: boolean;
}

const PickupAddress: React.FC<Props> = ({ order, disabled = true }) => {
    return (
        <div>
            <GenericHeadline title={"Pickup Address"} titleHeadingType="h4" actionButtons={[]} />
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "40px",
                }}
            >
                <div style={{ width: "50%" }}>
                    <GenericInput
                        name={"fullName"}
                        disabled={disabled}
                        value={order.upsPickup?.address?.fullName}
                        label={"Full Name"}
                    />
                    <GenericInput
                        label={`Address Line 1${disabled ? "" : " *"}`}
                        name={"street"}
                        description={"Street Address, P.O. box, etc."}
                        value={order.upsPickup?.address?.street}
                        disabled={disabled}
                    />
                    <GenericInput
                        name={"addressSupplement"}
                        description={"Apartment, unit, building, floor, etc."}
                        value={order.upsPickup?.address?.addressSupplement}
                        disabled={disabled}
                        label={"Address Line 2"}
                    />
                    <GenericInput
                        label={`ZIP Code${disabled ? "" : " *"}`}
                        name={"zipCode"}
                        value={order.upsPickup?.address?.zipCode}
                        disabled={disabled}
                    />
                    <GenericInput
                        label={`City${disabled ? "" : " *"}`}
                        name={"city"}
                        value={order.upsPickup?.address?.city}
                        disabled={disabled}
                    />
                </div>

                <div style={{ width: "50%" }}>
                    <GenericInput
                        label={"State"}
                        name={"state"}
                        value={order.upsPickup?.address?.state}
                        disabled={disabled}
                    />
                    <GenericInput
                        label={"Country"}
                        name={"country"}
                        value={order.upsPickup?.address?.country.country}
                        disabled={disabled}
                    />
                    <GenericInput
                        label={"Company"}
                        name={"company"}
                        value={order.upsPickup?.address?.company?.company}
                        disabled={disabled}
                    />
                    <GenericInput
                        label={`Email${disabled ? "" : " *"}`}
                        name={"email"}
                        value={order.upsPickup?.address?.email}
                        type={"email"}
                        disabled={disabled}
                    />

                    <GenericPhoneInput
                        label={`Phone${disabled ? "" : " *"}`}
                        name={"phone"}
                        phoneNumber={order.upsPickup?.address?.phone ?? ""}
                        disabled={disabled}
                        country={order.upsPickup?.address?.country.country}
                    />
                </div>
            </div>
        </div>
    );
};

export default PickupAddress;
