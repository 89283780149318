export class SmartHandsTaskShipping {
    public type:
        | string // string is kind of fallback
        | "shipmentTask1"
        | "shipmentTask2"
        | "shipmentTask3"
        | "shipmentTask4"
        | "shipmentTask5"
        | "shipmentTask6";
    public completed: string | undefined;

    constructor({
        type,
        completed,
    }: {
        type:
            | string // string is kind of fallback
            | "shipmentTask1"
            | "shipmentTask2"
            | "shipmentTask3"
            | "shipmentTask4"
            | "shipmentTask5"
            | "shipmentTask6";
        completed: string | undefined;
    }) {
        this.type = type;
        this.completed = completed;
    }

    public serialize() {
        return JSON.stringify(this);
    }

    static fromSerialized(json: object): SmartHandsTaskShipping {
        if (json == null) {
            throw new Error(`Error parsing SmartHandsTask!`);
        }

        return new SmartHandsTaskShipping({
            type: json["type"],
            completed: json["completed"],
        });
    }
}
