import React, { useContext, useEffect, useState } from "react";
import {
    GroupuiModal,
    GroupuiSelect,
    GroupuiSelectOption,
    GroupuiButton,
    GroupuiLoadingSpinner,
    GroupuiCheckbox,
} from "@group-ui/group-ui-react";
import { ApiService } from "../../../services/apiService";
import { Order } from "../../../models/order";
import { GraphApiService, RequestType } from "../../../services/graphApi/graphApiService";
import { GraphApiEndpoints } from "../../../constants/graphApiEndpoints";
import ErrorContext from "../../context/ErrorContext";
import { handleError } from "../../context/ErrorContextUtils";
import GenericTable, { columnDefinition } from "../../generics/GenericTable";
import GenericHeadline from "../../generics/GenericHeadline";

interface OrderSelectionModalProps {
    modal: boolean;
    setModal: (value: boolean) => void;
    onSelectOrders: (orders: Order[]) => void;
}

interface User {
    id: string;
    displayName: string;
}

const columns: columnDefinition[] = [
    {
        key: "checkbox",
        header: "",
        width: "2rem",
    },
    {
        key: "databoxName",
        header: "Name",
        width: "auto",
    },
    {
        key: "orderId",
        header: "Order ID",
        width: "auto",
    },
    {
        key: "ingestLocation",
        header: "Ingest Location",
        width: "auto",
    },
];

const OrderSelectionModal: React.FC<OrderSelectionModalProps> = ({
    modal,
    setModal,
    onSelectOrders,
}) => {
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const [users, setUsers] = useState<User[]>([]);
    const [selectedUser, setSelectedUser] = useState<string>("");
    const [orders, setOrders] = useState<Order[]>([]);
    const [selectedOrders, setSelectedOrders] = useState<number[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        ApiService.getAllUserFromOrders()
            .then(async (response: string[] | null) => {
                if (response) {
                    const uniqueUserIds = response;
                    const userPromises = uniqueUserIds.map(async (userId) => {
                        const userResponse = await GraphApiService.callMsGraph(
                            GraphApiEndpoints.userById(userId),
                            RequestType.GET
                        );
                        return { id: userId, displayName: userResponse.displayName };
                    });
                    const userResults = await Promise.all(userPromises);
                    setUsers(userResults);
                    setIsLoading(false);
                }
            })
            .catch((error) => handleError(error, changeErrorMessage, changeShowError));
    }, []);

    useEffect(() => {
        if (selectedUser != "") {
            setIsLoading(true);
            ApiService.getAllOrdersOverviewByAzureUserId(selectedUser)
                .then((response: Order[] | null) => {
                    if (response) {
                        // Filter to include orders with "In-Use" or "Pickup not successful" state
                        const filteredOrders = response.filter(
                            (order) =>
                                order.currentMediaState === "In Use" ||
                                order.currentMediaState === "Pickup not successful"
                        );
                        setOrders(filteredOrders);
                    } else {
                        setOrders([]);
                    }
                    setIsLoading(false);
                })
                .catch((error) => {
                    handleError(error, changeErrorMessage, changeShowError);
                    setIsLoading(false);
                });
        }
    }, [selectedUser]);

    const handleOrderSelect = (orderId: number) => {
        const newSelectedOrders = selectedOrders.includes(orderId)
            ? selectedOrders.filter((id) => id !== orderId)
            : [...selectedOrders, orderId];
        setSelectedOrders(newSelectedOrders);
    };

    const handleConfirmSelection = () => {
        const selectedOrderObjects = orders.filter((order) => selectedOrders.includes(order.id));
        onSelectOrders(selectedOrderObjects);
        setModal(false);
    };

    const data = orders.map((order) => ({
        checkbox: (
            <GroupuiCheckbox
                checked={selectedOrders.includes(order.id)}
                onClick={() => handleOrderSelect(order.id)}
            />
        ),
        databoxName: order.databox.name,
        orderId: `OR${order.id}`,
        ingestLocation: order.databox.ingestStation?.name || "N/A",
    }));

    return (
        <GroupuiModal
            closeButton={true}
            displayed={modal}
            padding="var(--groupui-sys-spacing-600)"
            margin="150px"
            onGroupuiModalClose={() => setModal(false)}
        >
            <GenericHeadline
                title="Select Databoxes"
                actionButtons={[]}
                titleHeadingType="h3"
                type="modal"
            />
            <GroupuiSelect
                value={selectedUser}
                severity="none"
                placeholder="Select User"
                onGroupuiChange={(event) => {
                    setSelectedUser(event.target.value);
                }}
            >
                {users.map((user, index) => (
                    <GroupuiSelectOption key={`user-select-option-${index}`} value={user.id}>
                        {user.displayName}
                    </GroupuiSelectOption>
                ))}
            </GroupuiSelect>
            {isLoading && <GroupuiLoadingSpinner displayed={isLoading} />}
            <GenericTable
                header={true}
                pageSize={5}
                pageSizeOptions={"[5, 10, 15, 20]"}
                columns={columns}
                data={data ?? []}
            />
            <div
                style={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "var(--groupui-sys-spacing-400)",
                    marginTop: "var(--groupui-sys-spacing-600)",
                }}
            >
                <GroupuiButton variant={"secondary"} onClick={() => setModal(false)}>
                    Cancel
                </GroupuiButton>
                <GroupuiButton onClick={handleConfirmSelection}>Confirm Selection</GroupuiButton>
            </div>
        </GroupuiModal>
    );
};

export default OrderSelectionModal;
