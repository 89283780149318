import React, { useContext, useEffect, useState } from "react";
import GenericHeadline from "../GenericHeadline";
import GenericTable, { columnDefinition } from "../GenericTable";
import {
    GroupuiButton,
    GroupuiIcon,
    GroupuiLoadingSpinner,
    GroupuiTooltip,
} from "@group-ui/group-ui-react";
import useTitle from "../../../hooks/useTitle";
import { msalInstance } from "../../../config/config";
import { useNavigate } from "react-router";
import ErrorContext from "../../context/ErrorContext";
import { Order } from "../../../models/order";
import { ApiService } from "../../../services/apiService";
import GenericTag from "../GenericTag";
import { parseFullDate } from "../../../utils/DateUtil";
import { GraphApiService, RequestType } from "../../../services/graphApi/graphApiService";
import { GraphApiEndpoints } from "../../../constants/graphApiEndpoints";
import DownloadReportModal from "../../report/DownloadReportModal";
import { handleError } from "../../context/ErrorContextUtils";
import GenericMoreButton from "../GenericMoreButton";
import ReturnOtherDataboxModal from "../../myDataboxes/modal/ReturnOtherDataboxModal";
import ConfirmMultipleDropOffModal from "../../myDataboxes/modal/ConfirmMultipleDropOffModal";
import RequestMultiplePickupModal from "../../myDataboxes/modal/RequstMultiplePickupsModal";
import OrderSelectionModal from "../../myDataboxes/modal/OrderSelectionModal ";

export enum DataboxOrderOverview {
    MY_DATABOXES,
    DATABOX_ORDERS,
}

interface GenericDataboxOrderOverviewProps {
    columns: columnDefinition[];
    type: DataboxOrderOverview;
}

const styles = {
    magnifyingGlass: {
        cursor: "pointer",
        color: "var(--groupui-sys-color-action-basic-default)",
    },
};

const GenericDataboxOrderOverview: React.FC<GenericDataboxOrderOverviewProps> = ({
    columns,
    type,
}) => {
    const [title, setTitle] = useState<string>();
    useTitle(title);
    const navigate = useNavigate();
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const [databoxOrders, setDataboxOrders] = useState<Order[] | null>();
    const [data, setData] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(true);

    const [modal, setModal] = useState(false);

    const [dropDownState, setDropDownState] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const [orderSelectionModal, setOrderSelectionModal] = useState(false);
    const [pickUpModal, setPickUpModal] = useState(false);
    const [dropOffModal, setDropOffModal] = useState(false);
    const [selectedOrders, setSelectedOrders] = useState<Order[]>([]);
    const [currentAction, setCurrentAction] = useState<"pickup" | "dropoff" | null>(null);

    let userOID: string | undefined = "";
    const accounts = msalInstance.getAllAccounts();

    if (accounts) {
        if (accounts[0]["idTokenClaims"]) {
            if (accounts[0]["idTokenClaims"]["roles"]) {
                userOID = accounts[0]["idTokenClaims"]["oid"];
            }
        }
    }

    useEffect(() => {
        switch (type) {
            case DataboxOrderOverview.MY_DATABOXES:
                if (userOID) {
                    ApiService.getAllOrdersOverviewByAzureUserId(userOID)
                        .then((response) => {
                            setDataboxOrders(response);
                            setIsLoading(false);
                        })
                        .catch((error) => {
                            handleError(error, changeErrorMessage, changeShowError);
                            setIsLoading(false);
                        });
                }
                setTitle("My Databoxes");
                break;
            case DataboxOrderOverview.DATABOX_ORDERS:
                ApiService.getAllOrdersOverview()
                    .then((response) => {
                        setDataboxOrders(response);
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        handleError(error, changeErrorMessage, changeShowError);
                        setIsLoading(false);
                    });
                setTitle("Databox Orders");
                break;
        }
    }, [type]);

    useEffect(() => {
        switch (type) {
            case DataboxOrderOverview.MY_DATABOXES:
                setData(
                    databoxOrders
                        ?.map((order: Order) => {
                            return {
                                orderId: "OR" + order.id,
                                databoxName: order.databox.name,
                                ingestLocation: order.databox.ingestStation?.name,
                                destinationCountry: order.address?.city,
                                currentState: (
                                    <GenericTag
                                        text={order.currentMediaState}
                                        variant="primary"
                                        mediaStateTarget="databox"
                                    />
                                ),
                                accessPoint: order.packageDropOff ? "Yes" : "",
                                created: parseFullDate(order.created),
                                requestedPickupDate: order.packageDropOff
                                    ? ""
                                    : parseFullDate(order.upsPickup?.pickupDate),
                                pickedUpDroppedOff: parseFullDate(
                                    order.upsReturnShipmentLabel?.pickedUp
                                ),
                                button: (
                                    <GroupuiTooltip position="right">
                                        <GroupuiIcon
                                            name="search-plus-32"
                                            style={styles.magnifyingGlass}
                                            onClick={() =>
                                                navigate(`/my-databoxes/${order.id}/order-details`)
                                            }
                                        />
                                        <div slot="tooltip">Show Details</div>
                                    </GroupuiTooltip>
                                ),
                            };
                        })
                        .reverse()
                );
                break;
            case DataboxOrderOverview.DATABOX_ORDERS:
                getDataWithResolvedRequestor().then((result) => {
                    if (result != undefined) {
                        setData(result);
                        setIsLoading(false);
                    }
                });
                break;
        }
    }, [databoxOrders]);

    const getDataWithResolvedRequestor = async () => {
        let dataEntries;
        if (databoxOrders != undefined) {
            const userIdNameMapping = new Map<string, string>();
            await Promise.all(
                databoxOrders
                    .filter((order, index, orderArray) => {
                        return (
                            orderArray.findIndex(
                                (value) => value.azureUserId === order.azureUserId
                            ) === index
                        );
                    })
                    .map(async (order: Order) => {
                        let result = undefined;
                        if (order.azureUserId != null) {
                            if (!userIdNameMapping.has(order.azureUserId)) {
                                result = await GraphApiService.callMsGraph(
                                    GraphApiEndpoints.userById(order.azureUserId),
                                    RequestType.GET
                                ).catch((error) => {
                                    handleError(error, changeErrorMessage, changeShowError);
                                });
                                userIdNameMapping.set(order.azureUserId, result.displayName);
                            }
                        }
                    })
            );
            dataEntries = databoxOrders
                .sort((a: Order, b: Order) => {
                    return Date.parse(b.created) - Date.parse(a.created);
                })
                .map((order: Order) => {
                    return {
                        orderId: "OR" + order.id,
                        databoxName: order.databox.name,
                        ingestLocation: order.databox.ingestStation?.name,
                        destinationCountry: order.address?.city,
                        currentState: (
                            <GenericTag
                                text={order.currentMediaState}
                                variant="primary"
                                mediaStateTarget="databox"
                            />
                        ),
                        accessPoint: order.packageDropOff ? "Yes" : "",
                        requestor: userIdNameMapping.get(order.azureUserId) ?? "",
                        created: parseFullDate(order.created),
                        requestedPickupDate: order.packageDropOff
                            ? ""
                            : parseFullDate(order.upsPickup?.pickupDate),
                        pickedUpDroppedOff: parseFullDate(order.upsReturnShipmentLabel?.pickedUp),
                        button: (
                            <GroupuiTooltip position="right">
                                <GroupuiIcon
                                    name="search-plus-32"
                                    style={styles.magnifyingGlass}
                                    onClick={() =>
                                        navigate(`/databox-orders/${order.id}/order-details`)
                                    }
                                />
                                <div slot="tooltip">Show Details</div>
                            </GroupuiTooltip>
                        ),
                    };
                });
            return dataEntries;
        }
    };

    const handleModalClose = () => {
        setIsLoading(true);
        setDropOffModal(false);
        setPickUpModal(false);
        setDropDownState(false);

        // Re-fetch the orders to have the correct state if the user reqeusts for their databoxes
        if (type === DataboxOrderOverview.MY_DATABOXES) {
            if (userOID) {
                ApiService.getAllOrdersOverviewByAzureUserId(userOID)
                    .then((response) => {
                        setDataboxOrders(response);
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        handleError(error, changeErrorMessage, changeShowError);
                        setIsLoading(false);
                    });
            }
        }
    };

    const handleSelectOrders = (orders: Order[]) => {
        setSelectedOrders(orders);
        if (currentAction === "pickup") {
            setPickUpModal(true);
        } else if (currentAction === "dropoff") {
            setDropOffModal(true);
        }
        setOrderSelectionModal(false);
    };

    return (
        <div
            style={{
                padding: "var(--groupui-sys-spacing-1200) var(--groupui-sys-spacing-1400)",
                scrollBehavior: "smooth",
                whiteSpace: "pre-wrap",
            }}
        >
            <ReturnOtherDataboxModal enabled={showModal} setEnabled={setShowModal} />
            <OrderSelectionModal
                modal={orderSelectionModal}
                setModal={setOrderSelectionModal}
                onSelectOrders={handleSelectOrders}
            />
            {selectedOrders.length > 0 && currentAction === "pickup" && (
                <RequestMultiplePickupModal
                    modal={pickUpModal}
                    setModal={setPickUpModal}
                    orders={selectedOrders}
                    onModalClose={handleModalClose}
                />
            )}
            {selectedOrders.length > 0 && currentAction === "dropoff" && (
                <ConfirmMultipleDropOffModal
                    modal={dropOffModal}
                    setModal={setDropOffModal}
                    orders={selectedOrders}
                    onModalClose={handleModalClose}
                />
            )}
            <GroupuiLoadingSpinner displayed={isLoading} />
            {modal && DataboxOrderOverview.DATABOX_ORDERS && (
                <DownloadReportModal
                    reportType={title ?? ""}
                    data={data}
                    modal={modal}
                    setModal={(value: boolean) => setModal(value)}
                />
            )}
            <GenericHeadline
                title={title ?? ""}
                actionButtons={[
                    ...(type === DataboxOrderOverview.MY_DATABOXES
                        ? [
                              <GenericMoreButton
                                  key={"More-Button-1"}
                                  dropDownState={dropDownState}
                                  setDropDownState={() => {
                                      setDropDownState(!dropDownState);
                                  }}
                                  options={[
                                      <GroupuiButton
                                          key={"Option-1-Return"}
                                          variant="primary"
                                          icon="service-bell-32"
                                          fullwidth={true}
                                          alignment="left"
                                          onClick={() => {
                                              setShowModal(true);
                                              setDropDownState(false);
                                          }}
                                      >
                                          Return Databox of another User
                                      </GroupuiButton>,
                                      <GroupuiButton
                                          key={"Option-1-Pickup"}
                                          variant="primary"
                                          icon="service-bell-32"
                                          fullwidth={true}
                                          alignment="left"
                                          onClick={() => {
                                              setCurrentAction("pickup");
                                              setOrderSelectionModal(true);
                                              setDropDownState(false);
                                          }}
                                      >
                                          Request Multiple Pickups
                                      </GroupuiButton>,
                                      <GroupuiButton
                                          key={"Option-1-Dropoff"}
                                          variant="primary"
                                          icon="service-bell-32"
                                          fullwidth={true}
                                          alignment="left"
                                          onClick={() => {
                                              setCurrentAction("dropoff");
                                              setOrderSelectionModal(true);
                                              setDropDownState(false);
                                          }}
                                      >
                                          Confirm Multiple Package Drop Off
                                      </GroupuiButton>,
                                  ]}
                              />,
                          ]
                        : []),
                ]}
            />
            <div>
                <GenericTable
                    columns={columns}
                    data={data}
                    header={true}
                    pageSize={20}
                    searchColumns={[
                        { column: "orderId", displayName: "order ID" },
                        { column: "databoxName", displayName: "databox name" },
                    ]}
                    filterColumns={[
                        { column: "ingestLocation", displayName: "Ingest Location" },
                        { column: "currentState", displayName: "Current State" },
                    ]}
                />
            </div>
        </div>
    );
};

export default GenericDataboxOrderOverview;
