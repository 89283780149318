export class Role {
    private _id;
    public roleType;

    constructor({ id, roleType }: { id: number; roleType: string }) {
        this._id = id;
        this.roleType = roleType;
    }

    private toObject() {
        return {
            id: this._id,
            roleType: this.roleType,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): Role | null {
        if (json == null) {
            return null;
        }

        return new Role({
            id: json["id"],
            roleType: json["roleType"],
        });
    }
}
