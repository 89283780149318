import { Company } from "./company";
import { Role } from "./role";

export class Contact {
    private _id;
    public firstName;
    public lastName;
    public role;
    public company;
    public email;
    public phone;

    constructor({
        id,
        firstName,
        lastName,
        role,
        company,
        email,
        phone,
    }: {
        id: number;
        firstName: string;
        lastName: string;
        role: Role | null;
        company: Company;
        email: string;
        phone: string;
    }) {
        this._id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.role = role;
        this.company = company;
        this.email = email;
        this.phone = phone;
    }

    public get id() {
        return this._id;
    }

    private toObject() {
        return {
            id: this._id,
            firstName: this.firstName,
            lastName: this.lastName,
            role: this.role,
            company: this.company,
            email: this.email,
            phone: this.phone,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): Contact | null {
        if (json == null) {
            return null;
        }

        return new Contact({
            id: json["id"],
            firstName: json["firstName"],
            lastName: json["lastName"],
            role: Role.fromSerialized(json["role"]),
            company: Company.fromSerialized(json["company"]),
            email: json["emailAddress"],
            phone: json["phoneNumber"],
        });
    }
}
