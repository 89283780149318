import React, { useContext } from "react";
import { GroupuiDivider } from "@group-ui/group-ui-react";
import GenericHeadline from "../../generics/GenericHeadline";
import GenericInput from "../../generics/GenericInput";
import { IngestStationContext } from "../../context/provider/IngestStationContext";

export const IngestSlotGeneralInformation = () => {
    const { currentIngestSlot } = useContext(IngestStationContext);

    return (
        <div>
            <GenericHeadline title="General Information" titleHeadingType="h4" actionButtons={[]} />
            <div>
                <GenericInput label={"Name"} value={currentIngestSlot?.name} disabled={true} />
                <GenericInput
                    label={"Serial Number"}
                    value={currentIngestSlot?.serialNumber}
                    disabled={true}
                />
                <GenericInput
                    label={"Port Type"}
                    value={currentIngestSlot?.portTypes}
                    disabled={true}
                />
                <GroupuiDivider />
            </div>
        </div>
    );
};
