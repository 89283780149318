import React, { useContext, useEffect, useState } from "react";
import {
    GroupuiButton,
    GroupuiInlineNotification,
    GroupuiLoadingSpinner,
    GroupuiModal,
    GroupuiText,
} from "@group-ui/group-ui-react";
import Snackbar from "../../generics/Snackbar";
import GenericHeadline from "../../generics/GenericHeadline";
import { ApiService } from "../../../services/apiService";
import { Country } from "../../../models/country";
import ErrorContext from "../../context/ErrorContext";
import { Order } from "../../../models/order";
import { useParams } from "react-router";
import DropOffInformation from "./DropOffInformation";
import { PackageDropOff } from "../../../models/packageDropOff/packageDropOff";
import { handleError } from "../../context/ErrorContextUtils";

interface ConfirmDropOffModalProps {
    modal: boolean;
    setModal: (value: boolean) => void;
    order: Order | undefined;
    onModalClose: () => void;
}

const ConfirmDropOffModal: React.FC<ConfirmDropOffModalProps> = ({
    modal,
    setModal,
    order,
    onModalClose,
}) => {
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
    const [severity, setSeverity] = useState<"danger" | "success" | "warning">();
    const [message, setMessage] = useState<string>();
    const [isLoading, setIsLoading] = useState(false);

    const [addressLine1, setAddressLine1] = useState<string>("");
    const [addressLine2, setAddressLine2] = useState<string>("");
    const [zipCode, setZipCode] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [state, setState] = useState<string>("");
    const [country, setCountry] = useState<Country>();

    const [countries, setCountries] = useState<Country[] | null>();

    useEffect(() => {
        ApiService.getAllCountries()
            .then((response) => {
                setCountries(response);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
            });
    }, []);

    const handleSubmit = (event: any) => {
        setIsLoading(true);
        event.preventDefault();

        // API REQUEST
        if (order?.id && country) {
            const packageDropOff = new PackageDropOff({
                addressLine1: addressLine1,
                addressLine2: addressLine2,
                zipCode: zipCode,
                state: state,
                city: city,
                country: country,
                created: "",
            });

            ApiService.addPackageDropOff(Number(order.id), packageDropOff)
                .then((response) => {
                    // If response is null or undefined show as failed
                    if (!response) {
                        event.preventDefault();
                        setShowSnackBar(true);
                        setSeverity("danger");
                        setMessage("Drop off couldn´t be added!");
                        setIsLoading(false);
                        return;
                    }

                    setShowSnackBar(true);
                    setSeverity("success");
                    setMessage("Successfully added a drop off location!");
                    const timeout = setTimeout(() => {
                        onModalClose();
                        setIsLoading(false);
                    }, 1000);
                })
                .catch((error) => {
                    handleError(error, changeErrorMessage, changeShowError, true);
                    setIsLoading(false);
                });
        }
    };

    const checkDeliveryInputs = () => {
        return addressLine1 === "" || zipCode === "" || city === "" || country === undefined;
    };

    return (
        <GroupuiModal
            closeButton={true}
            padding="var(--groupui-sys-spacing-600)"
            overflow={"auto"}
            displayed={modal}
            maxWidth="70vw"
            l={12}
            xl={12}
            xxl={7}
            style={{
                justifyContent: "center",
                alignItems: "center",
            }}
            onGroupuiModalClose={() => setModal(false)}
        >
            <GroupuiLoadingSpinner displayed={isLoading} />
            {showSnackBar && (
                <Snackbar
                    severity={severity ?? "danger"}
                    message={message ?? "Some error occurred!"}
                    onClose={() => {
                        setShowSnackBar(false);
                    }}
                />
            )}
            <GenericHeadline
                title="Confirm Package Drop Off"
                actionButtons={[]}
                titleHeadingType="h3"
                type="modal"
            />
            <GroupuiText>
                Please Confirm that you have dropped of the package at following access point:
            </GroupuiText>
            <form onSubmit={handleSubmit}>
                <br />
                {country && order?.address?.country.country !== country?.country && (
                    <div>
                        <GroupuiInlineNotification
                            visible={true}
                            hideIcon={false}
                            severity="warning"
                            style={{
                                // Added because color from the component isn´t working
                                color: "var(--groupui-sys-color-warning-basic-default)",
                                backgroundColor: "var(--groupui-sys-color-warning-weak-default)",
                                // Added to avoided dropdowns to be hidden
                                zIndex: "0",
                            }}
                        >
                            <div
                                slot="headline"
                                // Added because color from the component isn´t working
                                style={{ color: "var(--groupui-sys-color-text-normal)" }}
                            >
                                Return Label
                            </div>
                            <div
                                slot="content"
                                // Added because color from the component isn´t working
                                style={{ color: "var(--groupui-sys-color-text-normal)" }}
                            >
                                A new return label will be created due to the changed country.
                            </div>
                        </GroupuiInlineNotification>
                        <br />
                    </div>
                )}
                <DropOffInformation
                    addressLine1={addressLine1}
                    setAddressLine1={setAddressLine1}
                    addressLine2={addressLine2}
                    setAddressLine2={setAddressLine2}
                    zipCode={zipCode}
                    setZipCode={setZipCode}
                    city={city}
                    setCity={setCity}
                    state={state}
                    setState={setState}
                    country={country}
                    setCountry={setCountry}
                    countries={countries ?? null}
                />
                <br />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "var(--groupui-sys-spacing-400)",
                        marginTop: "var(--groupui-sys-spacing-600)",
                    }}
                >
                    <GroupuiButton variant={"secondary"} onClick={() => setModal(false)}>
                        Cancel
                    </GroupuiButton>
                    <GroupuiButton
                        icon="service-bell-32"
                        disabled={checkDeliveryInputs()}
                        type={"submit"}
                    >
                        Confirm
                    </GroupuiButton>
                </div>
            </form>
        </GroupuiModal>
    );
};

export default ConfirmDropOffModal;
