import React from "react";
import * as ReactDOMClient from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@group-ui/group-ui-react/node_modules/@group-ui/group-ui/dist/group-ui/assets/themes/vwag/vwag.css";
import {
    applyPolyfills,
    defineCustomElements,
} from "@group-ui/group-ui-react/node_modules/@group-ui/group-ui/dist/loader";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./config/config";
import { BrowserRouter as Router } from "react-router-dom";

applyPolyfills().then(() => {
    defineCustomElements();
});

const root = ReactDOMClient.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <Router>
                <App />
            </Router>
        </MsalProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
