import { IngestSlot } from "./ingestSlot/ingestSlot";
import { IngestSlotBooking } from "./ingestSlot/ingestSlotBooking";
import { MediaState } from "./mediaState";
import { Order } from "./order";

export class IngestSlotSwitchJob {
    private id;
    public order;
    public oldIngestSlot;
    public oldIngestSlotBooking;
    public newIngestSlot;
    public mediaState;
    public smartHandsTicketId;
    public jiraTicketId;
    public status;
    public created;
    public updated;
    public creationSmartHandsTicket;

    constructor({
        id,
        order,
        oldIngestSlot,
        oldIngestSlotBooking,
        newIngestSlot,
        mediaState,
        smartHandsTicketId,
        jiraTicketId,
        status,
        created,
        updated,
        creationSmartHandsTicket,
    }: {
        id: number;
        order: Order;
        oldIngestSlot: IngestSlot | null;
        oldIngestSlotBooking: IngestSlotBooking | null;
        newIngestSlot: IngestSlot | null;
        mediaState: MediaState | null;
        smartHandsTicketId: string;
        jiraTicketId: string;
        status: string;
        created: string;
        updated: string;
        creationSmartHandsTicket: string;
    }) {
        this.id = id;
        this.order = order;
        this.oldIngestSlot = oldIngestSlot;
        this.oldIngestSlotBooking = oldIngestSlotBooking;
        this.newIngestSlot = newIngestSlot;
        this.mediaState = mediaState;
        this.smartHandsTicketId = smartHandsTicketId;
        this.jiraTicketId = jiraTicketId;
        this.status = status;
        this.created = created;
        this.updated = updated;
        this.creationSmartHandsTicket = creationSmartHandsTicket;
    }

    private toObject() {
        return {
            id: this.id,
            order: this.order,
            oldIngestSlot: this.oldIngestSlot,
            oldIngestSlotBooking: this.oldIngestSlotBooking,
            newIngestSlot: this.newIngestSlot,
            mediaState: this.mediaState,
            smartHandsTicketId: this.smartHandsTicketId,
            jiraTicketId: this.jiraTicketId,
            status: this.status,
            created: this.created,
            updated: this.updated,
            creationSmartHandsTicket: this.creationSmartHandsTicket,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): IngestSlotSwitchJob | null {
        if (json == null) {
            return null;
        }

        return new IngestSlotSwitchJob({
            id: json["id"],
            order: Order.fromSerialized(json["order"]),
            oldIngestSlot: IngestSlot.fromSerialized(json["oldIngestSlot"]),
            oldIngestSlotBooking: IngestSlotBooking.fromSerialized(json["oldIngestSlotBooking"]),
            newIngestSlot: IngestSlot.fromSerialized(json["newIngestSlot"]),
            mediaState: MediaState.fromSerialized(json["mediaState"]),
            smartHandsTicketId: json["smartHandsTicketId"],
            jiraTicketId: json["jiraTicketId"],
            status: json["status"],
            created: json["created"],
            updated: json["updated"],
            creationSmartHandsTicket: json["creationSmartHandsTicket"],
        });
    }
}
