import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ApiService } from "../../../services/apiService";
import { handleError } from "../ErrorContextUtils";
import ErrorContext from "../ErrorContext";
import { Order } from "../../../models/order";
import { ShipmentJob } from "../../../models/shipmentJob";
import { IngestJob } from "../../../models/ingestJob";
import { msalInstance } from "../../../config/config";

interface IOrderContext {
    isLoading: boolean;
    orders?: Order[];
    currentOrder?: Order;
    currentShipmentJob?: ShipmentJob;
    currentIngestJob?: IngestJob;
}

const defaultState = {
    isLoading: false,
    orders: undefined,
    currentOrder: undefined,
    currentShipmentJob: undefined,
    currentIngestJob: undefined,
};

export const OrderContext = createContext<IOrderContext>(defaultState);

interface OrderProviderProps {
    children?: React.ReactNode;
    userSpecificContext?: boolean;
}

export const OrderProvider: React.FC<OrderProviderProps> = ({ children, userSpecificContext }) => {
    const location = useLocation();
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);

    const [isLoading, setIsLoading] = useState(true);
    const [orders, setOrders] = useState<Order[]>();

    const urlPathParameter = location.pathname.split("/");
    const currentOrderId = urlPathParameter[urlPathParameter.length - 2];
    const currentShipmentJobId = urlPathParameter[urlPathParameter.length - 1];
    const currentIngestJobId = urlPathParameter[urlPathParameter.length - 1];

    // TODO: DXCRD-11718 improve and get from endpoint if suitable
    const currentOrder = orders?.find((order) => order.id == Number(currentOrderId));

    // TODO: DXCRD-11718 improve and get from endpoint if suitable
    const currentShipmentJob =
        orders?.find((order) => order.shipmentJob?.id == Number(currentShipmentJobId))
            ?.shipmentJob ?? undefined;

    // TODO: DXCRD-11718 improve and get from endpoint if suitable
    const currentIngestJob =
        orders?.find((order) => order.id == Number(currentIngestJobId))?.ingestJob ?? undefined;

    let userOID: string | undefined = "";
    const accounts = msalInstance.getAllAccounts();

    if (accounts) {
        if (accounts[0]["idTokenClaims"]) {
            if (accounts[0]["idTokenClaims"]["roles"]) {
                userOID = accounts[0]["idTokenClaims"]["oid"];
            }
        }
    }

    useEffect(() => {
        getData();
    }, [location.pathname]);

    const getData = async () => {
        setIsLoading(true);
        await Promise.all([
            userSpecificContext && userOID
                ? ApiService.getAllOrdersOverviewByAzureUserId(userOID)
                      .then((orders: Order[] | null) => {
                          if (orders == null) {
                              setOrders(undefined);
                              return undefined;
                          }
                          setOrders(orders);
                      })
                      .catch((error) => handleError(error, changeErrorMessage, changeShowError))
                : // TODO: DXCRD-11718 improve and handle site request from e.x. ShipmentJobs.tsx here
                  ApiService.getAllOrdersOverview()
                      .then((orders: Order[] | null) => {
                          if (orders == null) {
                              setOrders(undefined);
                              return undefined;
                          }
                          setOrders(orders);
                      })
                      .catch((error) => handleError(error, changeErrorMessage, changeShowError)),
        ]).then(() => setIsLoading(false));
    };

    return (
        <OrderContext.Provider
            value={{
                isLoading,
                orders,
                currentOrder,
                currentShipmentJob,
                currentIngestJob,
            }}
        >
            {children}
        </OrderContext.Provider>
    );
};
