import React, { useContext, useEffect, useMemo, useState } from "react";

import { MapContainer, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import "leaflet/dist/leaflet.css";
import CustomMarker from "./CustomMarker";
import { ApiService } from "../../services/apiService";
import { handleError } from "../context/ErrorContextUtils";
import ErrorContext from "../context/ErrorContext";
import { DataboxPin } from "../../models/databoxPin";
import { GroupuiHeadline, GroupuiLoadingSpinner } from "@group-ui/group-ui-react";
import UserGroupContext from "../context/UserGroupContext";
import { RightsManagement } from "../../config/rightsManagement";
import GenericFilterBar from "../generics/GenericFilter/GenericFilterBar";
import { filterData } from "../generics/GenericFilter/FilterUtils";
import { LatLngTuple } from "leaflet";
import MapUpdater from "./MapUpdater";
import { isInUserGroup } from "../../utils/RightsUtils";

const styles = {
    container: {
        border: "0.0625rem solid rgb(223, 226, 230)",
        marginTop: "var(--groupui-sys-spacing-600)",
        backgroundColor: "white",
        height: " 100%",
        lineHeight: "1.5",
        letterSpacing: "0.0125rem",
        borderRadius: "0.125rem",
        padding: "var(--groupui-sys-spacing-600)",
    },
    title: {
        marginBottom: "var(--groupui-sys-spacing-600)",
        display: "flex",
        flexDirection: "row" as const,
        justifyContent: "space-between",
        marginTop: "var(--groupui-sys-spacing-400)",
    },
    legendStyles: {
        container: {
            display: "flex",
            flexDirection: "row" as const,
            alignItems: "center",
            padding: "var(--groupui-sys-spacing-200)",
            marginTop: "var(--groupui-sys-spacing-200)",
        },
        item: {
            display: "flex",
            alignItems: "center",
            marginRight: "var(--groupui-sys-spacing-400)",
        },
        colorBox: (color: string) => ({
            width: "20px",
            height: "20px",
            backgroundColor: color,
            marginRight: "var(--groupui-sys-spacing-200)",
        }),
        title: {
            fontSize: "1rem",
            fontWeight: "600",
        },
        text: {
            fontSize: "1rem",
        },
    },
};

interface MonitoringWidget {
    lastUpdated: string;
}

const MonitoringWidget: React.FC<MonitoringWidget> = ({ lastUpdated }) => {
    const { userGroups } = useContext(UserGroupContext);
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const [isLoading, setIsLoading] = useState(true);
    const [pins, setPins] = useState<DataboxPin[] | null>();
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [filters, setFilters] = useState({});
    const defaultCenter: LatLngTuple = [53.891574, 14.6156];
    const [mapCenter, setMapCenter] = useState<LatLngTuple>(defaultCenter);
    const [mapZoom, setMapZoom] = useState(4);

    const data = useMemo(() => {
        return (
            pins?.map((pin: DataboxPin) => {
                return {
                    databoxId: pin.databoxId,
                    databoxName: pin.databoxName,
                    databoxSerialNumber: pin.databoxSerialNumber,
                    storageCapacity: pin.storageCapacity.toString(),
                    battery: pin.battery,
                    latitude: pin.latitude,
                    longitude: pin.longitude,
                    ingestLocation: pin.ingestLocation,
                    internalProject: pin.internalProject,
                    currnetHolder: pin.currnetHolder,
                    databoxStatus: pin.databoxStatus,
                };
            }) ?? []
        );
    }, [pins]);

    useEffect(() => {
        getData();
    }, [lastUpdated]);

    useEffect(() => {
        if (data && data.length > 0) {
            const newFilteredData = filterData(data, filters);
            setFilteredData(newFilteredData);
        }
    }, [filters, data]);

    const getData = () => {
        if (isInUserGroup([RightsManagement.SUPPORT], userGroups)) {
            setIsLoading(true);
            ApiService.getAllDataboxPins()
                .then((response) => {
                    setPins(response);
                    setIsLoading(false);
                })
                .catch((error) => {
                    handleError(error, changeErrorMessage, changeShowError);
                    setIsLoading(false);
                });
            return;
        }
        setIsLoading(true);
        ApiService.getDataboxPinsForCustomer()
            .then((response) => {
                setPins(response);
                setIsLoading(false);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
                setIsLoading(false);
            });
    };

    const handleMapControlChange = (selectedValue: string) => {
        const [lat, lng, zoom] = selectedValue.split(",").map(Number);
        setMapCenter([lat, lng] as LatLngTuple);
        setMapZoom(zoom);
    };

    return (
        <div style={styles.container}>
            <GroupuiLoadingSpinner displayed={isLoading} />
            <div style={styles.title}>
                <GroupuiHeadline heading={"h4"}>Databox Locations</GroupuiHeadline>
            </div>
            <GenericFilterBar
                filterColumns={[
                    {
                        column: "mapControl",
                        displayName: "Map Control",
                        isCustomDropdown: true,
                        customOptions: [
                            { value: "9.1021,18.2812,4", label: " Africa" },
                            { value: "32.76300,103.400334,4", label: "Asia" },
                            { value: "-26.320022, 134.747853,4", label: "Australia" },
                            { value: "53.891574, 14.615600,4", label: "Europe" },
                            { value: "54.083465,-105.984549,3", label: " North America" },
                            { value: "-17.501699,-59.385499,4", label: "South America" },
                            { value: "0,0,2", label: "World Map" },
                        ],
                        onChange: handleMapControlChange,
                    },
                    {
                        column: "ingestLocation",
                        displayName: "All Ingests Locations",
                        doSort: "asc",
                    },
                    {
                        column: "databoxStatus",
                        displayName: "Current State",
                        doSort: "predefined",
                        predefinedList: [
                            "In Transit (Delivery)",
                            "In Use",
                            "Waiting for Pickup",
                            "In Transit (Return)",
                        ],
                        useOnlyPredefinedList: true,
                    },
                    {
                        column: "storageCapacity",
                        displayName: "Databox Capacity",
                        doSort: "asc",
                        customLabels: { 60: "60 TB", 90: "90 TB", 120: "120TB" },
                    },
                    {
                        column: "internalProject",
                        displayName: "Project",
                        doSort: "asc",
                    },
                    {
                        column: "currnetHolder",
                        displayName: "Current Holder",
                        doSort: "asc",
                    },
                ]}
                filters={filters}
                setFilters={setFilters}
                data={data || []}
                showResetButton={true}
                filtersWidth="200"
                wrap={true}
            />
            <MapContainer
                center={mapCenter}
                zoom={mapZoom}
                style={{ height: "700px", width: "100%", zIndex: "0" }}
            >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <MarkerClusterGroup chunkedLoading>
                    {filteredData?.map((pin, index) => (
                        <CustomMarker
                            key={pin.databoxName + index}
                            position={[pin.latitude, pin.longitude]}
                            battery={pin.battery}
                            databoxName={pin.databoxName}
                            databoxId={pin.databoxId}
                        />
                    ))}
                </MarkerClusterGroup>
                <MapUpdater center={mapCenter} zoom={mapZoom} />
                {filteredData.length === 0 && (
                    <div
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            zIndex: 1000,
                            backgroundColor: "rgba(255, 255, 255, 0.8)",
                            padding: "var(--groupui-sys-spacing-500)",
                            borderRadius: "5px",
                        }}
                    >
                        <h2>No databoxes were found matching your search</h2>
                    </div>
                )}
            </MapContainer>
            <div style={styles.legendStyles.container}>
                <div style={styles.legendStyles.item}>
                    <span style={styles.legendStyles.title}>Tracker battery:</span>
                </div>
                <div style={styles.legendStyles.item}>
                    <div
                        style={styles.legendStyles.colorBox(
                            "var(--groupui-sys-color-danger-basic-pressed)"
                        )}
                    />
                    <span style={styles.legendStyles.text}>0%</span>
                </div>
                <div style={styles.legendStyles.item}>
                    <div
                        style={styles.legendStyles.colorBox(
                            "var(--groupui-sys-color-warning-basic-pressed)"
                        )}
                    />
                    <span style={styles.legendStyles.text}>1-20%</span>
                </div>
                <div style={styles.legendStyles.item}>
                    <div
                        style={styles.legendStyles.colorBox(
                            "var(--groupui-sys-color-success-basic-pressed)"
                        )}
                    />
                    <span style={styles.legendStyles.text}>21-100%</span>
                </div>
            </div>
        </div>
    );
};

export default MonitoringWidget;
