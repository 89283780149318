export class InCarMountComment {
    public inCarMountId;
    public userName;
    public comment;
    public created;

    constructor({
        inCarMountId,
        userName,
        comment,
        created,
    }: {
        inCarMountId: number;
        userName: string;
        comment: string;
        created: string;
    }) {
        this.inCarMountId = inCarMountId;
        this.userName = userName;
        this.comment = comment;
        this.created = created;
    }

    private toObject() {
        return {
            inCarMountId: this.inCarMountId,
            userName: this.userName,
            comment: this.comment,
            created: this.created,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): InCarMountComment | null {
        if (json == null) {
            return null;
        }

        return new InCarMountComment({
            inCarMountId: json["inCarMountId"],
            userName: json["userName"],
            comment: json["comment"],
            created: json["created"],
        });
    }
}
