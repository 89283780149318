import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { Address } from "../../models/address";
import GenericInput from "./GenericInput";
import { Company } from "../../models/company";
import { Country } from "../../models/country";
import { GenericPhoneInput } from "./GenericPhoneInput";
import {
    GroupuiCheckbox,
    GroupuiDivider,
    GroupuiSearchField,
    GroupuiSearchSuggestion,
    GroupuiSelect,
    GroupuiSelectOption,
} from "@group-ui/group-ui-react";
import { ApiService } from "../../services/apiService";
import { handleError } from "../context/ErrorContextUtils";
import ErrorContext from "../context/ErrorContext";
import { Countries } from "../../enums/Countries";
import { USStatesList } from "../../enums/USStatesList";
import GenericInputWithValidityCheck from "./GenericInputWithValidityCheck";
import { GenericAddressActionButtons } from "./GenericAddressActionButtons";
import { ProposedAddress } from "../../models/proposedAddress";
import ProposedAddresses from "../ProposedAddresses";

export enum Structure {
    // Used for pickup to show a checkbox to enable/disable Address editing
    REQUEST_PICKUP,
    // Used for ordering databoxes, because the country selection is handled in another component
    ORDER_DATABOX,
}

interface GenericAddressInformationFieldsProps {
    address: Address | undefined;
    setAddress: Dispatch<SetStateAction<Address | undefined>>;
    disabled: boolean;
    setDisabled?: (disabled: boolean) => void;
    structure?: Structure;
    country?: string;
    validateAddress?: boolean;
    setValidateAddress?: Dispatch<SetStateAction<boolean>>;
    onCountryChange?: (address: ProposedAddress) => void;
}

export const GenericAddressInformationFields: React.FC<GenericAddressInformationFieldsProps> = ({
    address = new Address({
        id: -1,
        fullName: "",
        street: "",
        addressSupplement: "",
        zipCode: "",
        city: "",
        state: "",
        country: new Country({ id: -1, country: "" }),
        company: new Company({ id: -1, company: "" }),
        email: "",
        phone: "",
    }),
    setAddress,
    disabled,
    validateAddress,
    setValidateAddress,
    setDisabled,
    structure,
    country,
    onCountryChange,
}) => {
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const [countries, setCountries] = useState<Country[] | null>();
    const [companies, setCompanies] = useState<Company[] | null>([]);
    const [filteredData, setFilteredData] = useState<string[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>("");

    const [formData, setFormData] = useState({
        fullName: address.fullName,
        street: address.street,
        addressSupplement: address.addressSupplement,
        zipCode: address.zipCode,
        city: address.city,
        state: address.state,
        country: address.country,
        company: address.company,
        email: address.email,
        phone: address.phone,
    });

    useEffect(() => {
        if (
            JSON.stringify({
                ...formData,
                _id: undefined,
                phone: formData.phone.replaceAll(/[ -()-]+/g, ""),
            }) !==
            JSON.stringify({
                ...address,
                _id: undefined,
                phone: address.phone.replaceAll(/[ -()-]+/g, ""),
            })
        ) {
            setFormData({
                fullName: address.fullName,
                street: address.street,
                addressSupplement: address.addressSupplement,
                zipCode: address.zipCode,
                city: address.city,
                state: address.state,
                country: address.country,
                company: address.company,
                email: address.email,
                phone: address.phone,
            });
        }
    }, [address]);

    useEffect(() => {
        ApiService.getAllCompanies()
            .then((response) => {
                setCompanies(response);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
            });
        ApiService.getAllCountries()
            .then((response) => {
                setCountries(response);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
            });
    }, []);

    // Needed to reset the form when disabling with the checkbox (mostly Request Pickup)
    useEffect(() => {
        setFormData(address);
    }, [disabled]);

    useEffect(() => {
        if (
            JSON.stringify({
                ...formData,
                _id: undefined,
                phone: formData.phone.replaceAll(/[ -()-]+/g, ""),
            }) !==
            JSON.stringify({
                ...address,
                _id: undefined,
                phone: address.phone.replaceAll(/[ -()-]+/g, ""),
            })
        ) {
            setAddress(Address.fromSerialized(formData) ?? new Address(formData));
        }
        setAddress(Address.fromSerialized(formData) ?? new Address(formData));
    }, [formData]);

    // Needed for the custom US State search field
    useEffect(() => {
        const temp = USStatesList.filter(containsLetters);
        setFilteredData(temp);
    }, [searchQuery, formData.country]);

    const containsLetters = (usState: string) => {
        if (usState.toLowerCase().includes(searchQuery.toLowerCase())) {
            return usState;
        }
    };

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const [showProposedAddress, setShowProposedAddress] = useState(false);

    const toggleProposedAddress = () => {
        setShowProposedAddress((prevState) => !prevState);
    };

    const handleSelectAddress = (address: ProposedAddress) => {
        if (onCountryChange) {
            onCountryChange(address);
            setShowProposedAddress(false);
            return;
        }

        setFormData(
            (prevFormData) =>
                new Address({
                    ...prevFormData!,
                    street: address?.street ?? prevFormData?.street ?? "",
                    city: address?.city ?? prevFormData?.city ?? "",
                    fullName: address?.fullName ?? prevFormData?.fullName ?? "",
                    zipCode: address?.zipCode ?? prevFormData?.zipCode ?? "",
                    state: address?.state ?? prevFormData?.state ?? "",
                    email: address?.email ?? prevFormData?.email ?? "",
                    phone: address?.phone ?? prevFormData?.phone ?? "",
                    company: address?.company ?? prevFormData?.company ?? "",
                    country: address?.country ?? prevFormData?.country ?? "",
                })
        );
        setShowProposedAddress(false);
    };

    return (
        <div>
            {showProposedAddress && (
                <ProposedAddresses
                    showModal={showProposedAddress}
                    setShowModal={setShowProposedAddress}
                    onSelectAddress={handleSelectAddress}
                    closedProposedAddresses={toggleProposedAddress}
                />
            )}
            <div style={{ display: "flex", gap: "var(--groupui-sys-spacing-1000)" }}>
                <div style={{ width: "50%" }}>
                    <GenericInputWithValidityCheck
                        input={formData.fullName}
                        name={"fullName"}
                        setInput={handleInputChange}
                        disabled={disabled}
                        displayName={"Full Name"}
                        maxLength={22}
                        mandatory={true}
                    />
                    <GenericInputWithValidityCheck
                        displayName={`Address Line 1${disabled ? "" : " *"}`}
                        name={"street"}
                        description={"Street Address, P.O. box, etc."}
                        input={formData.street}
                        disabled={disabled}
                        setInput={handleInputChange}
                        maxLength={50}
                    />
                    <GenericInputWithValidityCheck
                        input={formData.addressSupplement ?? ""}
                        name={"addressSupplement"}
                        description={"Apartment, unit, building, floor, etc."}
                        setInput={handleInputChange}
                        disabled={disabled}
                        displayName={"Address Line 2"}
                        maxLength={35}
                    />
                    <GenericInput
                        label={`ZIP Code${disabled ? "" : " *"}`}
                        name={"zipCode"}
                        value={formData.zipCode}
                        disabled={disabled}
                        onGroupuiChange={handleInputChange}
                    />
                    <GenericInput
                        label={`City${disabled ? "" : " *"}`}
                        name={"city"}
                        value={formData.city}
                        disabled={disabled}
                        onGroupuiChange={handleInputChange}
                    />
                </div>
                <div style={{ width: "50%" }}>
                    {/* ------------------------ STATE ------------------------ */}
                    {/* Special exception for United States that State is required for UPS Shipment API */}
                    {formData.country.id ==
                        countries?.find((country) => country.country == Countries.UNITED_STATES)
                            ?.id || country === Countries.UNITED_STATES ? (
                        <div style={{ paddingBottom: "var(--groupui-sys-spacing-600)" }}>
                            <GroupuiSearchField
                                value={formData.state}
                                name={"state"}
                                required={true}
                                hideClearIcon={true}
                                hideSearchIcon={true}
                                disabled={disabled}
                                severity={
                                    !USStatesList.find((element) =>
                                        element
                                            .toLowerCase()
                                            .includes(formData.state?.toLowerCase() ?? "")
                                    )
                                        ? "danger"
                                        : "none"
                                }
                                maxHeight={"300px"}
                                onGroupuiInput={(event) => setSearchQuery(event.target.value)}
                                onGroupuiChange={handleInputChange}
                            >
                                {(searchQuery || searchQuery != "") &&
                                    filteredData.map((state, index) => {
                                        return (
                                            <GroupuiSearchSuggestion
                                                key={`${state}-option-${Math.random()}`}
                                                hideIcon={true}
                                            >
                                                {state}
                                            </GroupuiSearchSuggestion>
                                        );
                                    })}
                                <span slot="label">State {disabled ? "" : " *"}</span>
                                {!USStatesList.includes(formData.state ?? "") && (
                                    <span slot="description">
                                        Select a valide state (only full names allowed)
                                    </span>
                                )}
                            </GroupuiSearchField>
                        </div>
                    ) : (
                        <GenericInput
                            label={"State"}
                            name={"state"}
                            value={formData.state}
                            disabled={disabled}
                            onGroupuiChange={handleInputChange}
                        />
                    )}

                    {/* ------------------------ Country ------------------------ */}
                    {disabled && structure !== Structure.ORDER_DATABOX && (
                        <GenericInput
                            label={"Country"}
                            name={"country"}
                            value={formData.country.country}
                            disabled={disabled}
                        />
                    )}
                    {!disabled && structure !== Structure.ORDER_DATABOX && (
                        <div style={{ paddingBottom: "var(--groupui-sys-spacing-500)" }}>
                            <GroupuiSelect
                                value={formData.country.id.toString()}
                                name={"country"}
                                required={true}
                                typeof="input"
                                disabled={disabled}
                                severity={"none"}
                                maxHeight="300px"
                                onGroupuiChange={(event) => {
                                    const { name, value } = event.target;
                                    setFormData((prevFormData) => ({
                                        ...prevFormData,
                                        [name]: countries?.find(
                                            (country) => country.id == Number(value)
                                        ),
                                    }));
                                }}
                            >
                                {countries?.map((element, index) => {
                                    return (
                                        <GroupuiSelectOption
                                            key={`${element.country.trim()}-option-${Math.random()}`}
                                            value={String(element.id)}
                                        >
                                            {element.country}
                                        </GroupuiSelectOption>
                                    );
                                })}
                                <span slot="label">Country{disabled ? "" : " *"}</span>
                            </GroupuiSelect>
                        </div>
                    )}
                    {/* ------------------------ COMPANY ------------------------ */}
                    {disabled && (
                        <GenericInput
                            label={"Company"}
                            name={"company"}
                            value={formData.company?.company}
                            disabled={disabled}
                        />
                    )}
                    {!disabled && (
                        <div style={{ paddingBottom: "var(--groupui-sys-spacing-700)" }}>
                            <GroupuiSelect
                                value={formData.company.id.toString()}
                                name={"company"}
                                required={true}
                                typeof="input"
                                severity={"none"}
                                onGroupuiChange={(event) => {
                                    const { name, value } = event.target;
                                    setFormData((prevFormData) => ({
                                        ...prevFormData,
                                        [name]: companies?.find(
                                            (company) => company.id == Number(value)
                                        ),
                                    }));
                                }}
                                disabled={disabled}
                            >
                                {(companies != null ? companies : [formData.company]).map(
                                    (company, index) => {
                                        return (
                                            <GroupuiSelectOption
                                                key={`${company?.company}-option-${Math.random()}`}
                                                value={String(company.id)}
                                            >
                                                {company.company}
                                            </GroupuiSelectOption>
                                        );
                                    }
                                )}

                                <span slot="label">Company *</span>
                            </GroupuiSelect>
                        </div>
                    )}
                    <GenericInput
                        label={`Email${disabled ? "" : " *"}`}
                        name={"email"}
                        value={formData.email}
                        type={"email"}
                        disabled={disabled}
                        onGroupuiChange={handleInputChange}
                    />
                    <GenericPhoneInput
                        label={`Phone${disabled ? "" : " *"}`}
                        name={"phone"}
                        phoneNumber={formData.phone}
                        onPhoneNumberChange={(phoneNumber) => {
                            setFormData((prevFormData) => ({
                                ...prevFormData,
                                phone: phoneNumber,
                            }));
                        }}
                        disabled={disabled}
                        country={country}
                    />
                    {structure == Structure.REQUEST_PICKUP && setDisabled && (
                        <div>
                            <br />
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                }}
                            >
                                <GroupuiCheckbox
                                    checked={!disabled}
                                    onGroupuiChange={(event) => setDisabled(!event.target.checked)}
                                >
                                    Changed Pickup Address
                                </GroupuiCheckbox>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <GroupuiDivider />
            {!disabled && (
                <div>
                    <br />
                    <GenericAddressActionButtons
                        disabled={disabled}
                        validateAddress={validateAddress}
                        setValidateAddress={setValidateAddress}
                        setShowProposedAddress={setShowProposedAddress}
                        structure={structure}
                    />
                    <br />
                </div>
            )}
        </div>
    );
};
