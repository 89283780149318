export class IngestSlotMonitorData {
    public orderId;
    public ticketId;
    public ingestSlotId;
    public ingestUuid;
    public ingestId;
    public ingestStationId;
    public ingestStationName;
    public databoxSerialNumber;
    public databoxId;
    public databoxName;
    public databoxMediaState;
    public slotSerialNumber;
    public name;
    public state;
    public lastStep;
    public lastStepTimestamp;
    public ingestStart;
    public uploadProgress;
    public uploadStart;
    public uploadedBytes;
    public ingestVersion;
    public discoveryId;
    public uploadId;
    public numberOfChunks;
    public chunkNumber;
    public uploadedVolume;
    public dataVolume;
    public duration;
    public numberOfFiles;
    public numberOfUploadedFiles;
    public numberOfMeasurements;
    public lastMessageInserted;
    public messageTimestamp;
    public messageUuid;
    public error;
    public bookingStarted;
    public bookingFinished;
    public reservedBy;
    public projectName;
    public overwriteState;
    public overwrittenSince;
    public detailsMessage;
    public markedAborted;
    public ingestType;
    public softDeleted;
    public hasSmartHands;
    public csCallRetries;
    public dataId;
    public ingestAttempt;
    public ingestAttempts;
    public ingestJobId;
    public status;

    constructor({
        ticketId,
        orderId,
        chunkNumber,
        ingestUuid,
        ingestStationName,
        databoxSerialNumber,
        databoxId,
        dataVolume,
        databoxName,
        databoxMediaState,
        discoveryId,
        duration,
        ingestId,
        ingestSlotId,
        ingestStart,
        ingestStationId,
        ingestVersion,
        lastMessageInserted,
        lastStep,
        lastStepTimestamp,
        messageTimestamp,
        messageUuid,
        name,
        numberOfChunks,
        numberOfFiles,
        numberOfUploadedFiles,
        numberOfMeasurements,
        slotSerialNumber,
        state,
        uploadId,
        uploadProgress,
        uploadStart,
        uploadedBytes,
        uploadedVolume,
        error,
        bookingStarted,
        bookingFinished,
        reservedBy,
        projectName,
        overwriteState,
        overwrittenSince,
        detailsMessage,
        markedAborted,
        ingestType,
        softDeleted,
        hasSmartHands,
        csCallRetries,
        dataId,
        ingestAttempt,
        ingestAttempts,
        ingestJobId,
        status,
    }: {
        ticketId: string;
        orderId: number;
        chunkNumber: string;
        ingestUuid: string;
        ingestStationName: string;
        databoxSerialNumber: string;
        databoxId: number;
        dataVolume: number;
        databoxName: string;
        databoxMediaState: string;
        discoveryId: string;
        duration: string;
        ingestId: number;
        ingestSlotId: number;
        ingestStart: string;
        ingestStationId: number;
        ingestVersion: string;
        lastMessageInserted: string;
        lastStep: string;
        lastStepTimestamp: string;
        messageTimestamp: string;
        messageUuid: string;
        name: string;
        numberOfChunks: string;
        numberOfFiles: string;
        numberOfUploadedFiles: string;
        numberOfMeasurements: string;
        reservedBy: string;
        slotSerialNumber: string;
        state: string;
        uploadId: string;
        uploadProgress: number;
        uploadStart: string;
        uploadedBytes: number;
        uploadedVolume: string;
        error: string | null | undefined;
        bookingFinished: string | null | undefined;
        bookingStarted: string | null | undefined;
        projectName: string | null | undefined;
        overwriteState: string | null | undefined;
        overwrittenSince: string | null | undefined;
        detailsMessage: string | null | undefined;
        markedAborted: string;
        ingestType: string;
        softDeleted: string;
        hasSmartHands: boolean;
        csCallRetries: number;
        dataId: string;
        ingestAttempt: number;
        ingestAttempts: number;
        ingestJobId: number;
        status: string | undefined;
    }) {
        this.orderId = orderId;
        this.ticketId = ticketId;
        this.chunkNumber = chunkNumber;
        this.ingestUuid = ingestUuid;
        this.ingestStationName = ingestStationName;
        this.databoxSerialNumber = databoxSerialNumber;
        this.databoxId = databoxId;
        this.dataVolume = dataVolume;
        this.databoxName = databoxName;
        this.databoxMediaState = databoxMediaState;
        this.discoveryId = discoveryId;
        this.duration = duration;
        this.ingestId = ingestId;
        this.ingestSlotId = ingestSlotId;
        this.ingestStart = ingestStart;
        this.ingestStationId = ingestStationId;
        this.ingestVersion = ingestVersion;
        this.lastMessageInserted = lastMessageInserted;
        this.lastStep = lastStep;
        this.lastStepTimestamp = lastStepTimestamp;
        this.messageTimestamp = messageTimestamp;
        this.messageUuid = messageUuid;
        this.name = name;
        this.numberOfChunks = numberOfChunks;
        this.numberOfFiles = numberOfFiles;
        this.numberOfUploadedFiles = numberOfUploadedFiles;
        this.numberOfMeasurements = numberOfMeasurements;
        this.slotSerialNumber = slotSerialNumber;
        this.state = state;
        this.uploadId = uploadId;
        this.uploadProgress = uploadProgress;
        this.uploadStart = uploadStart;
        this.uploadedBytes = uploadedBytes;
        this.uploadedVolume = uploadedVolume;
        this.error = error;
        this.bookingStarted = bookingStarted;
        this.bookingFinished = bookingFinished;
        this.reservedBy = reservedBy;
        this.projectName = projectName;
        this.overwriteState = overwriteState;
        this.overwrittenSince = overwrittenSince;
        this.detailsMessage = detailsMessage;
        this.markedAborted = markedAborted;
        this.ingestType = ingestType;
        this.softDeleted = softDeleted;
        this.hasSmartHands = hasSmartHands;
        this.csCallRetries = csCallRetries;
        this.dataId = dataId;
        this.ingestAttempt = ingestAttempt;
        this.ingestAttempts = ingestAttempts;
        this.ingestJobId = ingestJobId;
        this.status = status;
    }

    private toObject() {
        return {
            ticketId: this.ticketId,
            orderId: this.orderId,
            chunkNumber: this.chunkNumber,
            ingestUuid: this.ingestUuid,
            ingestStationName: this.ingestStationName,
            databoxSerialNumber: this.databoxSerialNumber,
            databoxId: this.databoxId,
            dataVolume: this.dataVolume,
            databoxName: this.databoxName,
            databoxMediaState: this.databoxMediaState,
            discoveryId: this.discoveryId,
            duration: this.duration,
            ingestId: this.ingestId,
            ingestSlotId: this.ingestSlotId,
            ingestStart: this.ingestStart,
            ingestStationId: this.ingestStationId,
            ingestVersion: this.ingestVersion,
            lastMessageInserted: this.lastMessageInserted,
            lastStep: this.lastStep,
            lastStepTimestamp: this.lastStepTimestamp,
            messageTimestamp: this.messageTimestamp,
            messageUuid: this.messageUuid,
            name: this.name,
            numberOfChunks: this.numberOfChunks,
            numberOfFiles: this.numberOfFiles,
            numberOfUploadedFiles: this.numberOfUploadedFiles,
            numberOfMeasurements: this.numberOfMeasurements,
            slotSerialNumber: this.slotSerialNumber,
            state: this.state,
            uploadId: this.uploadId,
            uploadProgress: this.uploadProgress,
            uploadStart: this.uploadStart,
            uploadedBytes: this.uploadedBytes,
            uploadedVolume: this.uploadedVolume,
            error: this.error,
            bookingStarted: this.bookingStarted,
            bookingFinished: this.bookingFinished,
            reservedBy: this.reservedBy,
            projectName: this.projectName,
            overwriteState: this.overwriteState,
            overwrittenSince: this.overwrittenSince,
            detailsMessage: this.detailsMessage,
            markedAborted: this.markedAborted,
            ingestType: this.ingestType,
            softDeleted: this.softDeleted,
            hasSmartHands: this.hasSmartHands,
            csCallRetries: this.csCallRetries,
            dataId: this.dataId,
            ingestAttempt: this.ingestAttempt,
            ingestAttempts: this.ingestAttempts,
            ingestJobId: this.ingestJobId,
            status: this.status,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): IngestSlotMonitorData {
        return new IngestSlotMonitorData({
            orderId: json["orderId"],
            ticketId: json["ticketId"],
            chunkNumber: json["chunkNumber"],
            ingestUuid: json["ingestUuid"],
            ingestStationName: json["ingestStationName"],
            databoxSerialNumber: json["databoxSerialNumber"],
            databoxId: json["databoxId"],
            dataVolume: json["dataVolume"],
            databoxName: json["databoxName"],
            databoxMediaState: json["databoxMediaState"],
            discoveryId: json["discoveryId"],
            duration: json["duration"],
            ingestId: json["ingestId"],
            ingestSlotId: json["ingestSlotId"],
            ingestStart: json["ingestStart"],
            ingestStationId: json["ingestStationId"],
            ingestVersion: json["ingestVersion"],
            lastMessageInserted: json["lastMessageInserted"],
            lastStep: json["lastStep"],
            lastStepTimestamp: json["lastStepTimestamp"],
            messageTimestamp: json["messageTimestamp"],
            messageUuid: json["messageUuid"],
            name: json["name"],
            numberOfChunks: json["numberOfChunks"],
            numberOfFiles: json["numberOfFiles"],
            numberOfUploadedFiles: json["numberOfUploadedFiles"],
            numberOfMeasurements: json["numberOfMeasurements"],
            slotSerialNumber: json["slotSerialNumber"],
            state: json["state"],
            uploadId: json["uploadId"],
            uploadProgress: json["uploadProgress"],
            uploadStart: json["uploadStart"],
            uploadedBytes: json["uploadedBytes"],
            uploadedVolume: json["uploadedVolume"],
            error: json["error"],
            bookingStarted: json["bookingStarted"],
            bookingFinished: json["bookingFinished"],
            reservedBy: json["reservedBy"],
            projectName: json["projectName"],
            overwriteState: json["overwriteState"],
            overwrittenSince: json["overwrittenSince"],
            detailsMessage: json["detailsMessage"],
            markedAborted: json["markedAborted"],
            ingestType: json["ingestType"],
            softDeleted: json["softDeleted"],
            hasSmartHands: json["hasSmartHands"],
            csCallRetries: json["csCallRetries"],
            dataId: json["dataId"],
            ingestAttempt: json["ingestAttempt"],
            ingestAttempts: json["ingestAttempts"],
            ingestJobId: json["ingestJobId"],
            status: json["status"],
        });
    }
}
