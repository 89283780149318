import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { RightsWrapper } from "../../container/RightsWrapper";
import UserGroupContext from "../../context/UserGroupContext";
import { columnDefinition } from "../../generics/GenericTable";
import Report, { ReportType } from "../../report/Report";
import { RightsManagement } from "../../../config/rightsManagement";

/**
 * A CyclesReportRoutes Component, which defines the Nested Routes nested under the parent for cycles-report-content
 *
 * @returns CyclesReportRoutes
 *
 */
const CyclesReportRoutes = () => {
    const { userGroups } = useContext(UserGroupContext);
    return (
        <RightsWrapper
            key={"first"}
            authorizedUserGroup={RightsManagement.CYCLES_REPORT_ROUTES}
            userGroups={userGroups}
            section={true}
        >
            <Routes>
                <Route
                    index
                    element={
                        <Report reportType={ReportType.CYCLES_REPORT} columns={cycleTableColumns} />
                    }
                />
            </Routes>
        </RightsWrapper>
    );
};

const cycleTableColumns: columnDefinition[] = [
    {
        key: "orderId",
        header: "Order ID",
        width: "4vw",
    },
    {
        key: "databoxName",
        header: "Databox Name",
        width: "auto",
    },
    {
        key: "databoxSerialNumber",
        header: "Databox Serial Number",
        width: "auto",
    },
    {
        key: "databoxStatus",
        header: "Databox Status",
        width: "auto",
    },
    {
        key: "shipmentDateDelivery",
        header: "Shipment Date (Delivery)",
        width: "8.5vw",
    },
    {
        key: "shipmentDateReturn",
        header: "Shipment Date (Return)",
        width: "8.5vw",
    },
    {
        key: "trackingNumberDelivery",
        header: "Tracking Number (Delivery)",
        width: "auto",
    },
    {
        key: "trackingNumberReturn",
        header: "Tracking Number (Return)",
        width: "auto",
    },
    {
        key: "outboundTicket",
        header: "Outbound Ticket",
        width: "auto",
    },
    {
        key: "smartHandsTicketDelivery",
        header: "SmartHands Ticket (Delivery)",
        width: "auto",
    },
    {
        key: "inboundTicket",
        header: "Inbound Ticket",
        width: "auto",
    },
    {
        key: "smartHandsTicketIngest",
        header: "SmartHands Ticket (Ingest)",
        width: "auto",
    },
    {
        key: "smartHandsTicketPostIngest",
        header: "SmartHands Ticket (Post-Ingest)",
        width: "auto",
    },
    {
        key: "ingestLocation",
        header: "Ingest Location",
        width: "auto",
    },
    {
        key: "destination",
        header: "Destination",
        width: "auto",
    },
    {
        key: "requestor",
        header: "Requestor",
        width: "6vw",
    },
    {
        key: "orderCreated",
        header: "Order Created",
        width: "8.5vw",
    },
    {
        key: "uploadCompleted",
        header: "Ingest Completed",
        width: "8.5vw",
    },
];

export default CyclesReportRoutes;
