import React, {
    ForwardedRef,
    Ref,
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";
import { InCarMount } from "../../models/incarmount";
import {
    GroupuiLoadingSpinner,
    GroupuiButton,
    GroupuiSelect,
    GroupuiSelectOption,
    GroupuiDatePicker,
} from "@group-ui/group-ui-react";
import GenericHeadline from "../generics/GenericHeadline";
import Snackbar from "../generics/Snackbar";
import GenericInput from "../generics/GenericInput";
import { ApiService } from "../../services/apiService";
import { handleError } from "../context/ErrorContextUtils";
import ErrorContext from "../context/ErrorContext";
import { Project } from "../../models/project";
import { RightsWrapper } from "../container/RightsWrapper";
import { RightsManagement } from "../../config/rightsManagement";
interface Props {
    inCarMountData: InCarMount;
    userGroups: string[];
}

export interface RefType {
    sendChanges: () => void;
}

function GeneralInformationEdit({ inCarMountData, userGroups }: Props, ref: ForwardedRef<RefType>) {
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
    const [severity, setSeverity] = useState<"success" | "danger">("success");
    const [message, setMessage] = useState<string>("");

    const [isLoading, setIsLoading] = useState(false);

    useImperativeHandle(ref, () => ({ sendChanges }));

    const [name, setName] = useState<string>(inCarMountData.name ?? "");
    const [version, setVersion] = useState<string>(inCarMountData.version ?? "Original");
    const [status, setStatus] = useState<string>(inCarMountData.status ?? "Active");
    const [owner, setOwner] = useState<string>(inCarMountData.owner ?? "");
    const [registrationDate, setRegistrationDate] = useState<string>(
        inCarMountData.registrationDate
    );
    const [poNumber, setPoNumber] = useState<string>(inCarMountData.poNumber ?? "");
    const [shielding, setShielding] = useState<string>(inCarMountData.shielding ? "Yes" : "No");
    const [project, setProject] = useState<string>(inCarMountData.project?.name ?? "");
    const [currentHolder, setCurrentHolder] = useState<string>(inCarMountData.currentHolder);

    const [projects, setProjects] = useState<Project[]>();

    useEffect(() => {
        setIsLoading(true);
        ApiService.getAllProjects()
            .then((response) => {
                setProjects(response ?? []);
                setIsLoading(false);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
            });
    }, []);

    const sendChanges = () => {
        setIsLoading(true);

        const currentProject = projects?.find((element) => element.name === project) ?? null;

        if (currentProject == null || currentHolder == "") {
            setShowSnackbar(true);
            setMessage("You have to set a Holder and a Project!");
            setSeverity("danger");

            setShowSnackbar(true);
            setIsLoading(false);
            return;
        }

        const updatedTimestamp = new Date().toISOString();
        currentProject.updated = updatedTimestamp;

        const updateInCarMount = new InCarMount({
            ...inCarMountData,
            name,
            version,
            status,
            owner,
            registrationDate,
            poNumber,
            shielding: shielding === "Yes",
            currentHolder,
            project: currentProject,
        });

        if (updateInCarMount) {
            ApiService.updateInCarMount(updateInCarMount)
                .then((response) => {
                    if (response === "success") {
                        setMessage(`In Car Mount updated successfully`);
                        setSeverity("success");
                        setShowSnackbar(true);
                        setTimeout(() => {
                            setIsLoading(false);
                            window.location.reload();
                        }, 2000);
                    } else {
                        setMessage("Failed to update In Car Mount");
                        setSeverity("danger");
                        setShowSnackbar(true);
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    handleError(error, changeErrorMessage, changeShowError);
                });
        }
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "var(--groupui-sys-spacing-400)",
            }}
        >
            <RightsWrapper
                authorizedUserGroup={RightsManagement.IN_CAR_MOUNT_EDIT_GENERAL_INFO}
                userGroups={userGroups}
            >
                <GenericHeadline
                    title={"General Information"}
                    titleHeadingType="h4"
                    actionButtons={[]}
                />

                <GenericInput
                    label={"Name"}
                    value={name}
                    onGroupuiChange={(event: any) => setName(event.target.value)}
                    maxLength={50}
                    required={true}
                />

                <GroupuiSelect
                    value={version}
                    required={true}
                    onGroupuiChange={(event) => setVersion(event.target.value)}
                >
                    <GroupuiSelectOption value="Original">Original</GroupuiSelectOption>
                    <GroupuiSelectOption value="HW-rebuild-1">HW-rebuild-1</GroupuiSelectOption>
                    <GroupuiSelectOption value="HW-rebuild-2">HW-rebuild-2</GroupuiSelectOption>
                    <GroupuiSelectOption value="SW-rebuild">SW-rebuild</GroupuiSelectOption>
                    <span slot="label">Version</span>
                </GroupuiSelect>
                <br />
                <GroupuiSelect
                    value={status}
                    required={true}
                    onGroupuiChange={(event) => setStatus(event.target.value)}
                >
                    <GroupuiSelectOption value="Active">Active</GroupuiSelectOption>
                    <GroupuiSelectOption value="In Stock">In Stock</GroupuiSelectOption>
                    <GroupuiSelectOption value="Maintenance">Maintenance</GroupuiSelectOption>
                    <GroupuiSelectOption value="Decommissioned">Decommissioned</GroupuiSelectOption>
                    <span slot="label">Status</span>
                </GroupuiSelect>
                <br />
                <GroupuiSelect
                    value={owner}
                    required={true}
                    onGroupuiChange={(event: any) => setOwner(event.target.value)}
                >
                    <GroupuiSelectOption value="ASTech">ASTech</GroupuiSelectOption>
                    <GroupuiSelectOption value="China">China</GroupuiSelectOption>
                    <GroupuiSelectOption value="CSI">CSI</GroupuiSelectOption>
                    <GroupuiSelectOption value="DXC">DXC</GroupuiSelectOption>
                    <GroupuiSelectOption value="ebDL">ebDL</GroupuiSelectOption>
                    <span slot="label">Owner</span>
                </GroupuiSelect>
                <br />
                <GroupuiDatePicker
                    style={{ whiteSpace: "normal" }}
                    dateFormat="d.m.Y"
                    placeholder={"DD.MM.YYYY"}
                    value={
                        new Date(registrationDate).toLocaleDateString("de-DE", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                        }) ?? ""
                    }
                    onGroupuiChange={(event) => setRegistrationDate(event.target.value)}
                >
                    <span slot="label">Registration date</span>
                </GroupuiDatePicker>
                <br />

                <GenericInput
                    label={"PO Number"}
                    value={poNumber ?? ""}
                    required={false}
                    onGroupuiChange={(event: any) => setPoNumber(event.target.value)}
                    maxLength={50}
                />

                <GroupuiSelect
                    value={shielding}
                    onGroupuiChange={(event) => setShielding(event.target.value)}
                >
                    <GroupuiSelectOption value="Yes">Yes</GroupuiSelectOption>
                    <GroupuiSelectOption value="No">No</GroupuiSelectOption>
                    <span slot="label">Shielding</span>
                </GroupuiSelect>
            </RightsWrapper>

            <RightsWrapper
                authorizedUserGroup={RightsManagement.IN_CAR_MOUNT_EDIT_ADDITIONAL_INFO}
                userGroups={userGroups}
            >
                <GenericHeadline
                    title={"Additional Information"}
                    titleHeadingType="h4"
                    actionButtons={[]}
                />
                {projects && (
                    <GroupuiSelect
                        value={project}
                        onGroupuiChange={(event) => setProject(event.target.value)}
                        maxHeight="20rem"
                    >
                        {projects?.map((element, index) => {
                            return (
                                <GroupuiSelectOption
                                    value={element.name}
                                    key={`select-option-${index}`}
                                >
                                    {element.name}
                                </GroupuiSelectOption>
                            );
                        })}
                        <span slot="label">Internal Project*</span>
                    </GroupuiSelect>
                )}
                <br />
                <GenericInput
                    label={"Current Holder"}
                    value={currentHolder ?? ""}
                    onGroupuiChange={(event: any) => setCurrentHolder(event.target.value)}
                    maxLength={50}
                />
            </RightsWrapper>
            <GroupuiLoadingSpinner displayed={isLoading} />
            {showSnackbar && (
                <Snackbar
                    severity={severity}
                    message={message}
                    onClose={() => setShowSnackbar(false)}
                />
            )}
        </div>
    );
}

export default forwardRef<RefType, Props>(GeneralInformationEdit);
