import React from "react";
import { useNavigate } from "react-router-dom";
import GenericHeadline from "../generics/GenericHeadline";
import RequestCards from "../generics/RequestCards";
import { GroupuiTooltip } from "@group-ui/group-ui-react";
import useTitle from "../../hooks/useTitle";

const CreateRequest: React.FC = () => {
    useTitle("Create Request");
    const navigate = useNavigate();
    return (
        <div
            style={{
                padding: "var(--groupui-sys-spacing-1200) var(--groupui-sys-spacing-1400)",
                scrollBehavior: "smooth",
                whiteSpace: "pre-wrap",
            }}
        >
            <GenericHeadline title="Create Request" actionButtons={[]} />

            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "var(--groupui-sys-spacing-300)",
                    flexDirection: "column",
                }}
            >
                <GenericHeadline
                    title="Do you want to reserve or order databoxes?"
                    titleHeadingType="h4"
                    actionButtons={[]}
                />

                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "var(--groupui-sys-spacing-1200)",
                    }}
                >
                    <GroupuiTooltip position="bottom">
                        <RequestCards
                            icon={"calendar-48"}
                            title={"Reserve Databoxes"}
                            tooltip={
                                "By reserving databoxes,\n you can ensure that enough databoxes\n will be available for ordering at a future time."
                            }
                            interactive={false}
                        />

                        <div slot="tooltip">Coming soon...</div>
                    </GroupuiTooltip>
                    <RequestCards
                        icon={"shopping-cart-48"}
                        title={"Order Databoxes"}
                        tooltip={
                            "When you order databoxes, \n shipping to your delivery address is initiated."
                        }
                        onClick={() => navigate(`/create-request/order-databoxes`)}
                    />
                </div>
            </div>
        </div>
    );
};

export default CreateRequest;
