import {
    GroupuiButton,
    GroupuiDatePicker,
    GroupuiModal,
    GroupuiText,
} from "@group-ui/group-ui-react";
import React, { useEffect, useState } from "react";
import GenericHeadline from "../generics/GenericHeadline";
import { CSVLink } from "react-csv";
import { parseFullDateFilename, parseFullDateStringToDate } from "../../utils/DateUtil";

interface Props {
    reportType: string;
    data: any;
    modal: boolean;
    setModal: (value: boolean) => void;
}

const DownloadReportModal: React.FC<Props> = ({ data, reportType, modal, setModal }) => {
    const [fromDate, setFromDate] = useState<string>("");
    const [toDate, setToDate] = useState<string>("");
    const [date, setDate] = useState<string>("");
    const [filteredData, setFilteredData] = useState<any[] | undefined>([]);

    useEffect(() => {
        const dateSplit = date.split(":");
        setFromDate(dateSplit[0]);
        if (dateSplit[0] === dateSplit[1]) {
            setToDate(dateSplit[0]);
        } else {
            setToDate(dateSplit[1]);
        }
    }, [date]);

    const setFormatDate = (date: string, type: string) => {
        const newDate = new Date();
        newDate.setFullYear(Number(date?.split("-")[0]));
        newDate.setMonth(Number(date?.split("-")[1]) - 1);
        newDate.setDate(Number(date?.split("-")[2]));

        if (type === "FromDate") {
            newDate.setHours(0);
            newDate.setMinutes(0);
            newDate.setSeconds(0);
        }
        if (type === "ToDate" && new Date() > newDate) {
            newDate.setHours(23);
            newDate.setMinutes(59);
            newDate.setSeconds(59);
        }

        return newDate;
    };

    const checkInput = () => {
        if (!fromDate || !toDate) return true;

        const newFromDate = setFormatDate(fromDate, "FromDate");

        const newToDate = setFormatDate(toDate, "ToDate");

        if (newFromDate.getTime() > newToDate.getTime()) return true;

        if (newFromDate.getTime() > new Date(Date.now()).getTime()) return true;
        if (newToDate.getTime() > new Date(Date.now()).getTime()) return true;

        return false;
    };

    const dataInTimeFrame = (data: any) => {
        let dateColumn = "";
        if (reportType === "Cycles Report") dateColumn = "orderCreated";
        if (reportType === "Shipment Report") dateColumn = "created";
        if (reportType === "Job Report") dateColumn = "assignedIngestSlot";
        if (reportType === "Ingests") dateColumn = "ingest_finished";

        if (data) {
            const date = parseFullDateStringToDate(data[dateColumn]);
            if (date) {
                const newFromDate = setFormatDate(fromDate, "FromDate");

                const newToDate = setFormatDate(toDate, "ToDate");

                if (
                    date.getTime() >= newFromDate.getTime() &&
                    date.getTime() <= newToDate.getTime()
                )
                    return true;
            }
        }
    };

    useEffect(() => {
        let trimmedData;
        if (
            reportType === "Cycles Report" ||
            reportType === "Job Report" ||
            reportType === "Ingests"
        ) {
            trimmedData = data.filter(dataInTimeFrame);
        }
        if (reportType === "Job Report") {
            trimmedData = trimmedData.map((item: any) => {
                return {
                    ingestId: item.ingestId,
                    ...item,
                };
            });
        }
        if (reportType === "Shipment Report") {
            trimmedData = data.filter(dataInTimeFrame).map((item: any) => {
                return {
                    orderId: item.orderId,
                    databoxName: item.databoxName,
                    ingestLocation: item.ingestLocation,
                    destinationCountry: item.destinationCountry,
                    currentState: item.currentState.props.text,
                    accessPoint: item.accessPoint,
                    requestor: item.requestor,
                    created: item.created,
                    updated: item.updated,
                };
            });
        }
        trimmedData?.forEach((row: any) => {
            for (const element in row) {
                if (typeof row[element] === "string") {
                    row[element] = row[element].replace(/(\r\n|\n|\r)/gm, "");
                    row[element] = row[element].trim();
                }
            }
        });
        if (trimmedData.length == 0) {
            setFilteredData([
                {
                    Message: `There is no data available in the selected timeframe from ${fromDate} to ${toDate}! Please select a different Timeframe.`,
                },
            ]);
        } else {
            setFilteredData(trimmedData);
        }
    }, [fromDate, toDate]);

    return (
        <GroupuiModal
            closeButton={true}
            padding="var(--groupui-sys-spacing-600)"
            overflow={"visible"}
            displayed={modal}
            maxWidth="35vw"
            l={12}
            xl={12}
            xxl={7}
            style={{
                justifyContent: "center",
                alignItems: "center",
            }}
            onGroupuiModalClose={() => setModal(false)}
        >
            <div>
                <GenericHeadline
                    title={"Download " + reportType}
                    titleHeadingType="h4"
                    actionButtons={[]}
                    type="modal"
                />
                <GroupuiText
                    weight={"bold"}
                    style={{ marginBottom: "var(--groupui-sys-spacing-300)" }}
                >
                    Report Time Frame
                </GroupuiText>
            </div>

            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "var(--groupui-sys-spacing-500)",
                }}
            >
                <div>
                    <GroupuiDatePicker
                        severity="none"
                        value={date}
                        required={true}
                        dateFormat="Y-m-d"
                        placeholder="YYYY-MM-DD"
                        style={{ zIndex: "100", postition: "relative", whiteSpace: "normal" }}
                        onGroupuiChange={(event) => setDate(event.target.value)}
                        mode="range"
                        maxDate={
                            new Date().getFullYear() +
                            "-" +
                            (new Date().getMonth() + 1) +
                            "-" +
                            new Date().getDate()
                        }
                    >
                        <span slot="label">Timeframe*</span>
                    </GroupuiDatePicker>
                </div>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "var(--groupui-sys-spacing-400)",
                        marginTop: "var(--groupui-sys-spacing-600)",
                    }}
                >
                    <GroupuiButton variant={"secondary"} onClick={() => setModal(false)}>
                        Cancel
                    </GroupuiButton>
                    <CSVLink
                        data={filteredData ?? ["data: NO DATA AVAILABLE"]}
                        separator={";"}
                        filename={reportType + "_" + parseFullDateFilename(new Date()) + ".csv"}
                    >
                        <GroupuiButton
                            icon="download-32"
                            disabled={checkInput()}
                            onClick={() => {
                                setModal(false);
                            }}
                        >
                            Download
                        </GroupuiButton>
                    </CSVLink>
                </div>
            </div>
        </GroupuiModal>
    );
};

export default DownloadReportModal;
