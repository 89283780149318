import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ApiService } from "../../../services/apiService";
import { handleError } from "../ErrorContextUtils";
import ErrorContext from "../ErrorContext";
import { Databox } from "../../../models/databox";

interface IDataboxPoolContext {
    isLoading: boolean;
    databoxPool?: Databox[];
    currentDatabox?: Databox;
}

const defaultState = {
    isLoading: false,
    databoxPool: undefined,
    currentDatabox: undefined,
};

export const DataboxPoolContext = createContext<IDataboxPoolContext>(defaultState);

interface DataboxPoolProviderProps {
    children?: React.ReactNode;
}

export const DataboxPoolProvider: React.FC<DataboxPoolProviderProps> = ({ children }) => {
    const location = useLocation();
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);

    const [isLoading, setIsLoading] = useState(true);
    // TODO: DXCRD-11718 use the data from databoxPool for static data (maybe also remove single databox API call)
    const [databoxPool, setDataboxPool] = useState<Databox[]>();
    const [currentDatabox, setCurrentDatabox] = useState<Databox>();

    // Get current databox id from URL
    const urlPathParameter = location.pathname.split("/");
    const currentDataboxId = urlPathParameter[urlPathParameter.length - 1];

    useEffect(() => {
        getData();
    }, [location.pathname]);

    const getData = async () => {
        setIsLoading(true);
        await Promise.all([
            ApiService.getAllDataboxes()
                .then((databoxes: Databox[]) => {
                    setDataboxPool(databoxes);
                })
                .catch((error) => handleError(error, changeErrorMessage, changeShowError)),
            new RegExp(`^[1-9]\\d*$`).test(currentDataboxId)
                ? ApiService.getDataboxById(Number(currentDataboxId))
                      .then((databox: Databox) => {
                          setCurrentDatabox(databox);
                      })
                      .catch((error) => handleError(error, changeErrorMessage, changeShowError))
                : undefined,
        ]).then(() => setIsLoading(false));
    };

    return (
        <DataboxPoolContext.Provider
            value={{
                isLoading,
                databoxPool,
                currentDatabox,
            }}
        >
            {children}
        </DataboxPoolContext.Provider>
    );
};
