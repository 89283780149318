import {
    GroupuiModal,
    GroupuiLoadingSpinner,
    GroupuiButton,
    GroupuiText,
    GroupuiDivider,
} from "@group-ui/group-ui-react";
import React, { useContext, useState } from "react";
import { SlotStatus } from "../../../../models/slotStatus";
import { ApiService } from "../../../../services/apiService";
import ErrorContext from "../../../context/ErrorContext";
import GenericHeadline from "../../../generics/GenericHeadline";
import GenericInput from "../../../generics/GenericInput";
import Snackbar from "../../../generics/Snackbar";
import { handleError } from "../../../context/ErrorContextUtils";
import { IngestSlotMonitorData } from "../../../../models/ingestSlotMonitorData";
interface Props {
    modal: boolean;
    setModal: (value: boolean) => void;
    ingestSlotMonitorData: IngestSlotMonitorData;
}

const DeleteDetailsMessageModal: React.FC<Props> = ({ modal, setModal, ingestSlotMonitorData }) => {
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
    const [severity, setSeverity] = useState<"success" | "danger">("success");
    const [message, setMessage] = useState<string>("");

    const [loading, setLoading] = useState<boolean>(false);

    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);

    const sendRequest = () => {
        if (ingestSlotMonitorData?.ingestId) {
            setLoading(true);

            ApiService.addDetailsMessageToIngest(ingestSlotMonitorData?.ingestId, "delete", true)
                .then((response) => {
                    if (response.startsWith("success")) {
                        setShowSnackbar(true);
                        setMessage("Successfully deleted the details message!");
                        setTimeout(() => {
                            setLoading(false);
                            setModal(false);
                            window.location.reload();
                        }, 2000);
                    } else {
                        setShowSnackbar(true);
                        setMessage("An error occurred during deletion of the details message!");
                        setSeverity("danger");

                        setShowSnackbar(true);
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    handleError(error, changeErrorMessage, changeShowError);
                });
        }
    };

    return (
        <GroupuiModal
            displayed={modal}
            onGroupuiModalClose={() => setModal(false)}
            closeButton={true}
            padding="var(--groupui-sys-spacing-1000)"
            margin="200px"
            overflow="visible"
        >
            <GroupuiLoadingSpinner displayed={loading} />
            {showSnackbar && (
                <Snackbar
                    severity={severity}
                    message={message}
                    onClose={() => setShowSnackbar(false)}
                />
            )}
            <GenericHeadline
                title={"Delete Details Message"}
                titleHeadingType="h3"
                actionButtons={[]}
                type="modal"
            />
            <GroupuiDivider />
            <GroupuiText>
                This action will delete the message displayed in the details column on the
                dashboard!
            </GroupuiText>
            <br />
            <div style={{ display: "flex", gap: "var(--groupui-sys-spacing-150)" }}>
                <GroupuiText>Current message:</GroupuiText>
                <GroupuiText weight={"bold"}>{ingestSlotMonitorData?.detailsMessage}</GroupuiText>
            </div>

            <div
                style={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "var(--groupui-sys-spacing-400)",
                    marginTop: "var(--groupui-sys-spacing-600)",
                }}
            >
                <GroupuiButton variant={"secondary"} onClick={() => setModal(false)}>
                    Cancel
                </GroupuiButton>
                <GroupuiButton icon="check-32" onClick={sendRequest}>
                    Confirm
                </GroupuiButton>
            </div>
        </GroupuiModal>
    );
};

export default DeleteDetailsMessageModal;
