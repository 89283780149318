import React, { useContext, useEffect, useState } from "react";
import { GroupuiBreadcrumb, GroupuiBreadcrumbs } from "@group-ui/group-ui-react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ApiService } from "../../services/apiService";
import { handleError } from "../context/ErrorContextUtils";
import ErrorContext from "../context/ErrorContext";
import { Databox } from "../../models/databox";
import { DataboxPoolContext } from "../context/provider/DataboxPoolContext";

const styles = {
    contentContainer: {
        padding: "var(--groupui-sys-spacing-1200) var(--groupui-sys-spacing-1400)",
        scrollBehavior: "smooth",
        whiteSpace: "pre-wrap",
    } as React.CSSProperties,
};

/**
 * A DataboxDetailsWrapper Component, which positions the Breadcrumbs on the detail view of each databox
 *
 * @returns DataboxDetailsWrapper Component
 *
 */
function DataboxDetailsWrapper() {
    const navigate = useNavigate();
    const location = useLocation();
    const { currentDatabox } = useContext(DataboxPoolContext);

    return (
        <div style={styles.contentContainer}>
            <GroupuiBreadcrumbs>
                <GroupuiBreadcrumb onClick={() => navigate("/databox-pool")}>
                    Databox Pool
                </GroupuiBreadcrumb>
                <GroupuiBreadcrumb
                    onClick={() =>
                        navigate(location.pathname.substring(0, location.pathname.lastIndexOf("/")))
                    }
                >
                    {currentDatabox
                        ? `Databox Details - ${currentDatabox?.name}`
                        : `Databox Details`}
                </GroupuiBreadcrumb>
                {/* Displays a additional Breadcrumb if the user matches the path of the following Route */}
            </GroupuiBreadcrumbs>
            {/* Needed to display the content given by the Nested Route */}
            <Outlet />
        </div>
    );
}

export default DataboxDetailsWrapper;
