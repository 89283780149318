import {
    GroupuiButton,
    GroupuiHeadline,
    GroupuiLoadingSpinner,
    GroupuiModal,
    GroupuiText,
} from "@group-ui/group-ui-react";
import React, { useContext, useState } from "react";
import { ApiService } from "../../../services/apiService";
import ErrorContext from "../../context/ErrorContext";
import { handleError } from "../../context/ErrorContextUtils";
import Snackbar from "../../generics/Snackbar";
import GenericHeadline from "../../generics/GenericHeadline";

interface Props {
    showModal: boolean;
    setShowModal: (value: boolean) => void;
    onModalClose: () => void;
    selectedEntryId: number;
}

const DeleteStateModal: React.FC<Props> = ({
    showModal,
    setShowModal,
    onModalClose,
    selectedEntryId,
}) => {
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const [isLoading, setIsLoading] = useState(false);
    const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
    const [severity, setSeverity] = useState<"danger" | "success" | "warning">();
    const [message, setMessage] = useState<string>();

    const deleteEntry = () => {
        setIsLoading(true);
        ApiService.deleteDataboxState(selectedEntryId)
            .then((response) => {
                if (response.toLocaleLowerCase() === "success") {
                    setSeverity("success");
                    setMessage("State deleted");
                    setShowSnackBar(true);
                    setIsLoading(false);
                    window.location.reload();
                } else {
                    setSeverity("danger");
                    setMessage("Failed to delete state");
                    setShowSnackBar(true);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
            });
    };

    return (
        <GroupuiModal
            closeButton={true}
            displayed={showModal}
            onGroupuiModalClose={onModalClose}
            padding="var(--groupui-sys-spacing-800)"
            max-width="20"
            margin="240px"
        >
            <GroupuiLoadingSpinner displayed={isLoading} />
            {showSnackBar && (
                <Snackbar
                    severity={severity ?? "danger"}
                    message={message ?? "Some error occurred!"}
                    onClose={() => {
                        setShowSnackBar(false);
                    }}
                />
            )}
            <GenericHeadline
                title="Delete State?"
                titleHeadingType="h3"
                type="modal"
                actionButtons={[]}
            />
            <GroupuiText style={{ width: "390px" }}>
                Are you sure you want to delete this state?
            </GroupuiText>
            <br />
            <div
                style={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "var(--groupui-sys-spacing-400)",
                }}
            >
                <GroupuiButton onClick={() => setShowModal(false)} variant={"secondary"}>
                    Cancel
                </GroupuiButton>
                <GroupuiButton
                    style={{ outline: "none" }}
                    size="m"
                    severity="danger"
                    icon="trash-32"
                    onClick={deleteEntry}
                >
                    Delete
                </GroupuiButton>
            </div>
        </GroupuiModal>
    );
};

export default DeleteStateModal;
