import {
    GroupuiButton,
    GroupuiHeadline,
    GroupuiLoadingSpinner,
    GroupuiModal,
    GroupuiText,
} from "@group-ui/group-ui-react";
import React, { useContext, useState } from "react";
import ErrorContext from "../../context/ErrorContext";
import Snackbar from "../../generics/Snackbar";
import { Order } from "../../../models/order";
import { ApiService } from "../../../services/apiService";
import { handleError } from "../../context/ErrorContextUtils";
import GenericHeadline from "../../generics/GenericHeadline";
interface Props {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    orderId: number;
}

const RestartIngestJobModal: React.FC<Props> = ({ showModal, setShowModal, orderId }) => {
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
    const [severity, setSeverity] = useState<"success" | "danger">("success");
    const [message, setMessage] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);

    const restartIngestJob = () => {
        setLoading(true);
        ApiService.restartIngestJob(orderId)
            .then((response) => {
                if (response.startsWith("success")) {
                    setShowSnackbar(true);
                    setMessage("Successfully restarted ingest job!");
                    setTimeout(() => {
                        setLoading(false);
                        setShowModal(false);
                        window.location.reload();
                    }, 2000);
                } else {
                    setShowSnackbar(true);
                    setMessage("An error occurred during restarting of the ingest-job!");
                    setSeverity("danger");

                    setShowSnackbar(true);
                    setLoading(false);
                }
            })
            .catch((error) => {
                setLoading(false);
                handleError(error, changeErrorMessage, changeShowError);
            });
    };

    return (
        <GroupuiModal
            closeButton={true}
            displayed={showModal}
            onGroupuiModalClose={() => setShowModal(false)}
            padding="var(--groupui-sys-spacing-800)"
            margin="200px"
            max-width="20"
            overflow={"visible"}
        >
            {showSnackbar && (
                <Snackbar
                    severity={severity}
                    message={message}
                    onClose={() => setShowSnackbar(false)}
                />
            )}
            <GroupuiLoadingSpinner displayed={loading} />
            <GenericHeadline
                title="Restart Ingest Job?"
                titleHeadingType="h3"
                type="modal"
                actionButtons={[]}
            />

            <GroupuiText style={{ width: "390px" }}>
                Restarting this Ingest Job will put the order back into the queue to reserve an
                ingest slot.
            </GroupuiText>
            <br />
            <div
                style={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "var(--groupui-sys-spacing-400)",
                    marginTop: "var(--groupui-sys-spacing-600)",
                }}
            >
                <GroupuiButton variant={"secondary"} onClick={() => setShowModal(false)}>
                    Cancel
                </GroupuiButton>
                <GroupuiButton icon="check-32" onClick={restartIngestJob}>
                    Confirm
                </GroupuiButton>
            </div>
        </GroupuiModal>
    );
};

export default RestartIngestJobModal;
