import { GroupuiSelect, GroupuiSelectOption } from "@group-ui/group-ui-react";
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { Project } from "../../../models/project";
import { ApiService } from "../../../services/apiService";
import ErrorContext from "../../context/ErrorContext";
import { handleError } from "../../context/ErrorContextUtils";
interface Props {
    project?: string;
    setProject: Dispatch<SetStateAction<string | undefined>>;
    disabled: boolean;
    required: boolean;
}

const InternalProject: React.FC<Props> = ({ project, setProject, disabled, required }) => {
    const [projects, setProjects] = useState<Project[] | null>();
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);

    useEffect(() => {
        ApiService.getAllProjects()
            .then((response) => {
                setProjects(response);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
            });
    }, [projects?.length]);

    return (
        <div>
            <GroupuiSelect
                value={project}
                disabled={disabled}
                onGroupuiChange={(event) => setProject(String(event.target.value))}
                maxHeight="20rem"
            >
                {projects?.map((project, index) => {
                    return (
                        <GroupuiSelectOption
                            value={String(project.id)}
                            key={`select-option-${index}`}
                        >
                            {project.name}
                        </GroupuiSelectOption>
                    );
                })}
                <span slot="label">Internal Project{required ? " *" : ""}</span>
            </GroupuiSelect>
        </div>
    );
};

export default InternalProject;
