import { MediaState } from "../mediaState";
import { IngestSlotDetails } from "./ingestSlotDetails";

export class IngestSlotHistory {
    public id;
    public serialNumber;
    public ingestStationId;
    public name;
    public mediaState;
    public ingestSlotDetails;
    public started;
    public finished;

    constructor({
        id,
        serialNumber,
        ingestStationId,
        name,
        mediaState,
        ingestSlotDetails,
        started,
        finished,
    }: {
        id: number;
        serialNumber: string;
        ingestStationId: number;
        name: string;
        mediaState: MediaState;
        ingestSlotDetails: IngestSlotDetails | null;
        started: string;
        finished: string;
    }) {
        this.id = id;
        this.serialNumber = serialNumber;
        this.ingestStationId = ingestStationId;
        this.name = name;
        this.mediaState = mediaState;
        this.ingestSlotDetails = ingestSlotDetails;
        this.started = started;
        this.finished = finished;
    }

    private toObject() {
        return {
            id: this.id,
            serialNumber: this.serialNumber,
            ingestStationId: this.ingestStationId,
            name: this.name,
            mediaState: this.mediaState,
            ingestSlotDetails: this.ingestSlotDetails,
            started: this.started,
            finished: this.finished,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): IngestSlotHistory {
        if (json == null) {
            throw Error("There was an Error parsing Ingest Slot History!");
        }

        return new IngestSlotHistory({
            id: json["id"],
            serialNumber: json["serialNumber"],
            ingestStationId: json["ingestStationId"],
            name: json["name"],
            mediaState: json["mediaState"],
            ingestSlotDetails: IngestSlotDetails.fromSerialized(json["ingestSlotDetails"]),
            started: json["started"],
            finished: json["finished"],
        });
    }
}
