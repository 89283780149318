import React from "react";

export function filterData(data: any, filters: any) {
    const containsNameInSearchColumns = (row: any) => {
        const inRow: boolean[] = [];

        Object.keys(filters).forEach((filter) => {
            if (filters[filter].length > 0) {
                if (React.isValidElement(row[filter])) {
                    if (filters[filter].includes(row[filter]["props"]["text"])) {
                        inRow.push(true);
                    } else {
                        inRow.push(false);
                    }
                } else {
                    if (filters[filter].includes(row[filter])) {
                        inRow.push(true);
                    } else {
                        inRow.push(false);
                    }
                }
            }
        });

        return !inRow.includes(false);
    };

    let activeFilters = 0;

    Object.keys(filters).forEach((filterColumn) => {
        activeFilters += filters[filterColumn].length;
    });

    if (activeFilters > 0) {
        const newData = data?.filter(containsNameInSearchColumns);
        return newData;
    } else {
        return data;
    }
}
