import {
    GroupuiModal,
    GroupuiHeadline,
    GroupuiText,
    GroupuiButton,
    GroupuiLoadingSpinner,
} from "@group-ui/group-ui-react";
import React, { useContext, useState } from "react";
import { msalInstance } from "../../../../config/config";
import { CancelRequest } from "../../../../models/cancelRequest";
import { ApiService } from "../../../../services/apiService";
import { parseFullDateFilename } from "../../../../utils/DateUtil";
import { handleError } from "../../../context/ErrorContextUtils";
import ErrorContext from "../../../context/ErrorContext";
import Snackbar from "../../../generics/Snackbar";
import { IngestSlotMonitorData } from "../../../../models/ingestSlotMonitorData";
import GenericHeadline from "../../../generics/GenericHeadline";

interface CancelIngestModalProps {
    ingestSlotMonitorData: IngestSlotMonitorData;
    showModal: boolean;
    setShowModal: (value: boolean) => void;
}

const CancelIngestModal: React.FC<CancelIngestModalProps> = ({
    showModal,
    setShowModal,
    ingestSlotMonitorData,
}) => {
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
    const [severity, setSeverity] = useState<"success" | "danger">("success");
    const [message, setMessage] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);

    const abortIngest = () => {
        setLoading(true);
        const user = msalInstance.getAllAccounts()[0]["name"];
        const userArray = user?.split(",");
        const message = userArray
            ? userArray[0].toLowerCase() +
              "-" +
              userArray[1].trim().toLowerCase() +
              "-" +
              parseFullDateFilename(new Date())
            : user + "-" + parseFullDateFilename(new Date());
        const cancelRequest = new CancelRequest({
            ingestUuid: ingestSlotMonitorData?.ingestUuid ?? "",
            slotSerialNumber: ingestSlotMonitorData?.slotSerialNumber ?? "",
            databoxSerialNumber: ingestSlotMonitorData?.databoxSerialNumber ?? "",
            hubName: ingestSlotMonitorData?.ingestStationName ?? "",
            messageUuid: message,
            orderId: ingestSlotMonitorData?.orderId ?? -1,
        });
        ApiService.cancelIngest(cancelRequest)
            .then((response) => {
                if (response.toLocaleLowerCase() === "success") {
                    setSeverity("success");
                    setMessage("Ingest canceled!");
                    setTimeout(() => {
                        setLoading(false);
                        setShowModal(false);
                        window.location.reload();
                    }, 2000);
                } else {
                    setSeverity("danger");
                    setMessage("Failed to cancel Ingest!");
                }
                setShowSnackbar(true);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
            });
    };

    return (
        <GroupuiModal
            closeButton={true}
            displayed={showModal}
            onGroupuiModalClose={() => setShowModal(false)}
            padding="var(--groupui-sys-spacing-1000)"
            margin="120px"
            max-width="20"
            overflow={"visible"}
        >
            {showSnackbar && (
                <Snackbar
                    severity={severity}
                    message={message}
                    onClose={() => setShowSnackbar(false)}
                />
            )}
            <GroupuiLoadingSpinner displayed={loading} />
            <GenericHeadline
                title={"Cancel Ingest"}
                titleHeadingType="h3"
                actionButtons={[]}
                type="modal"
            />
            <GroupuiText weight={"bold"} style={{ width: "390px" }}>
                Do you want to mark this ingest as cancelled?
            </GroupuiText>
            <br />
            <GroupuiText style={{ width: "390px" }}>
                By canceling, this ingest will no longer be displayed on the dashboard. The ingest
                slot will be displayed as &quot;Free&quot; after the databox is removed.
            </GroupuiText>
            <br />
            <GroupuiText style={{ width: "390px" }}>
                Additionally, an Equinix SmartHands ticket is created to remove the databox from the
                ingest slot, prepare it for the next use, and set the databox to
                &quot;Reserved&quot; status. An additional treatment of the databox is needed.
            </GroupuiText>
            <br />
            <GroupuiText style={{ width: "390px" }}>
                Note: The ingest script is not affected by this action.
            </GroupuiText>
            <div
                style={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "var(--groupui-sys-spacing-400)",
                    marginTop: "var(--groupui-sys-spacing-600)",
                }}
            >
                <GroupuiButton variant={"secondary"} onClick={() => setShowModal(false)}>
                    Cancel
                </GroupuiButton>
                <GroupuiButton
                    style={{ outline: "none" }}
                    size="m"
                    severity="danger"
                    icon="close-32"
                    onClick={abortIngest}
                >
                    Cancel Ingest
                </GroupuiButton>
            </div>
        </GroupuiModal>
    );
};

export default CancelIngestModal;
