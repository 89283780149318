import React, { useContext, useEffect, useState } from "react";
import {
    GroupuiButton,
    GroupuiLoadingSpinner,
    GroupuiTab,
    GroupuiTabs,
} from "@group-ui/group-ui-react";
import GenericHeadline from "../generics/GenericHeadline";
import { ApiService } from "../../services/apiService";
import { useParams } from "react-router";
import ErrorContext from "../context/ErrorContext";
import { handleError } from "../context/ErrorContextUtils";
import { Order } from "../../models/order";
import GeneralInformationTab from "./GeneralInformationTab";
import useTitle from "../../hooks/useTitle";
import GenericMoreButton from "../generics/GenericMoreButton";
import CompleteOrderModal from "../databoxOrders/CompleteOrderModal";
import { RightsWrapper } from "../container/RightsWrapper";
import { RightsManagement } from "../../config/rightsManagement";
import UserGroupContext from "../context/UserGroupContext";
import {
    SwitchIngestSlotModal,
    getVisibilityConditionSwitchIngestSlot,
} from "../generics/modals/SwitchIngestSlotModal";
import PauseIngestJob from "./pauseIngestJob/PauseIngestJob";
import PauseIngestJobModal from "./pauseIngestJob/PauseIngestJobModal";
import RestartIngestJobModal from "./pauseIngestJob/RestartIngestJobModal";
import { OrderContext } from "../context/provider/OrderContext";

/**
 * Returns an IngestJobDetails Component, which defines the different tabs displayed on the ingest job details page
 *
 * @returns IngestJobDetails Component
 *
 */
const IngestJobDetails = () => {
    useTitle("Ingest Job Details");
    const { orderID } = useParams();
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const { currentIngestJob } = useContext(OrderContext);
    const [isLoading, setIsLoading] = useState(true);
    const [activeTabId, setActiveTabId] = useState("orderDetailsId");

    const { userGroups } = useContext(UserGroupContext);

    const [dropDownState, setDropDownState] = useState(false);
    const [showCompleteOrderModal, setShowCompleteOrderModal] = useState(false);
    const [showSwitchIngestSlotModal, setShowSwitchIngestSlotModal] = useState(false);
    const [showPauseIngestJobModal, setShowPauseIngestJobModal] = useState(false);
    const [showRestartIngestJobModal, setShowRestartIngestJobModal] = useState(false);

    const [order, setOrder] = useState<Order>();

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        ApiService.getOrderById(Number(orderID))
            .then((response) => {
                setOrder(response);
                setIsLoading(false);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
            });
    };

    return (
        <div>
            <GroupuiLoadingSpinner displayed={isLoading} />
            <CompleteOrderModal
                orderId={order?.id ?? -1}
                modal={showCompleteOrderModal}
                setModal={setShowCompleteOrderModal}
            />
            {showSwitchIngestSlotModal && (
                <SwitchIngestSlotModal
                    modal={showSwitchIngestSlotModal}
                    setModal={setShowSwitchIngestSlotModal}
                    ingestStationId={order?.databox.ingestStation?.id ?? -1}
                    orderId={order?.id ?? -1}
                />
            )}
            {showPauseIngestJobModal && (
                <PauseIngestJobModal
                    showModal={showPauseIngestJobModal}
                    setShowModal={setShowPauseIngestJobModal}
                    order={order}
                />
            )}
            {showRestartIngestJobModal && (
                <RestartIngestJobModal
                    showModal={showRestartIngestJobModal}
                    setShowModal={setShowRestartIngestJobModal}
                    orderId={order?.id ?? -1}
                />
            )}

            <GenericHeadline
                title={
                    currentIngestJob
                        ? `Ingest Job Details - ${currentIngestJob.displayName}`
                        : `Ingest Job Details`
                }
                actionButtons={[
                    <RightsWrapper
                        authorizedUserGroup={RightsManagement.COMPLETE_ORDER}
                        userGroups={userGroups}
                        key={"Option-1-RW"}
                    >
                        <GenericMoreButton
                            key={"More-Button-1"}
                            dropDownState={dropDownState}
                            setDropDownState={() => {
                                setDropDownState(!dropDownState);
                            }}
                            options={[
                                <GroupuiButton
                                    key={"Option-1"}
                                    variant="primary"
                                    icon="check-32"
                                    disabled={
                                        order?.ingestJob?.smartHandsTicket2Id == null ||
                                        order?.completed == true
                                    }
                                    fullwidth={true}
                                    alignment="left"
                                    onClick={() => {
                                        setShowCompleteOrderModal(true);
                                        setDropDownState(false);
                                    }}
                                >
                                    Complete Databox Order
                                </GroupuiButton>,
                                <GroupuiButton
                                    key={"Option-2"}
                                    variant="primary"
                                    icon="update-32"
                                    disabled={getVisibilityConditionSwitchIngestSlot(
                                        order?.ingestJob?.ingestSlot?.mediaState?.mediaStateType ??
                                            "",
                                        order?.ingestJob?.ingestSlotBooking?.by ?? "",
                                        !!order?.completed,
                                        !!order?.cancelled
                                    )}
                                    fullwidth={true}
                                    alignment="left"
                                    onClick={() => {
                                        setShowSwitchIngestSlotModal(true);
                                        setDropDownState(false);
                                    }}
                                >
                                    Switch Ingest Slot
                                </GroupuiButton>,
                                <PauseIngestJob
                                    key={"Option-3"}
                                    order={order}
                                    setDropDownState={setDropDownState}
                                    setShowPauseIngestJobModal={setShowPauseIngestJobModal}
                                    setShowRestartIngestJobModal={setShowRestartIngestJobModal}
                                />,
                            ]}
                        />
                        ,
                    </RightsWrapper>,
                ]}
            />

            <GroupuiTabs
                activeTabId={activeTabId}
                onGroupuiTabChange={(event) => {
                    setActiveTabId(event.target.activeTabId);
                }}
            >
                <GroupuiTab tab="generalId" name="General">
                    <GeneralInformationTab order={order} refreshOrder={() => getData()} />
                </GroupuiTab>
            </GroupuiTabs>
        </div>
    );
};

export default IngestJobDetails;
