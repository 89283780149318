import {
    GroupuiCard,
    GroupuiHeadline,
    GroupuiIcon,
    GroupuiTooltip,
} from "@group-ui/group-ui-react";
import React from "react";
interface Props {
    icon: string;
    title: string;
    tooltip: string;
    onClick?: () => void;
    interactive?: boolean;
}

const RequestCards: React.FC<Props> = ({ icon, title, tooltip, interactive = true, onClick }) => {
    return (
        <div>
            <GroupuiCard
                elevation="4"
                background={
                    interactive ? "var(--groupui-sys-color-action-basic-default)" : "#DFE2E6"
                }
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                padding={"40px"}
                interactive={interactive}
                onClick={onClick}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                    }}
                >
                    <GroupuiIcon
                        name={icon}
                        style={{
                            color: interactive
                                ? "#FFFFFF"
                                : "var(--groupui-sys-color-action-basic-disabled)",
                        }}
                        height={200}
                        width={200}
                    />
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "var(--groupui-sys-spacing-300)",
                        }}
                    >
                        <GroupuiHeadline
                            heading={"h3"}
                            style={{
                                color: interactive
                                    ? "#FFFFFF"
                                    : "var(--groupui-sys-color-action-basic-disabled)",
                            }}
                        >
                            {title}
                        </GroupuiHeadline>
                        <GroupuiTooltip style={{ display: "flex", alignItems: "center" }}>
                            <GroupuiIcon
                                name={"info-24"}
                                style={{
                                    color: interactive
                                        ? "#FFFFFF"
                                        : "var(--groupui-sys-color-action-basic-disabled)",
                                }}
                            />
                            <div slot="tooltip">{tooltip}</div>
                        </GroupuiTooltip>
                    </div>
                </div>
            </GroupuiCard>
        </div>
    );
};

export default RequestCards;
