import React, {
    ForwardedRef,
    Ref,
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";
import GenericInput from "../generics/GenericInput";
import { ApiService } from "../../services/apiService";
import { handleError } from "../context/ErrorContextUtils";
import ErrorContext from "../context/ErrorContext";
import { OrderProjectHistory } from "../../models/orderProjectHistory";
import { Project } from "../../models/project";
import { parseFullDate } from "../../utils/DateUtil";
import {
    GroupuiLoadingSpinner,
    GroupuiSelect,
    GroupuiSelectOption,
} from "@group-ui/group-ui-react";
import Snackbar from "../generics/Snackbar";
import GenericHeadline from "../generics/GenericHeadline";
interface Props {
    orderId: number;
}

export interface RefType {
    sendChanges: () => void;
}

function EditProjectHolderPage({ orderId }: Props, ref: ForwardedRef<RefType>) {
    const [holder, setHolder] = useState<string>();
    const [project, setProject] = useState<string>("");

    const [projects, setProjects] = useState<Project[]>();

    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const [loading, setLoading] = useState<boolean>(false);

    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
    const [severity, setSeverity] = useState<"success" | "danger">("success");
    const [message, setMessage] = useState<string>("");

    useEffect(() => {
        setLoading(true);
        ApiService.getOrderProjectHistoryByOrderId(orderId)
            .then((response) => {
                const data =
                    response
                        ?.sort((a: OrderProjectHistory, b: OrderProjectHistory) => {
                            return Date.parse(b.updated) - Date.parse(a.updated);
                        })
                        .map((orderProjectHistoryEntry: OrderProjectHistory) => {
                            return {
                                id: orderProjectHistoryEntry.ID,
                                internalProject: orderProjectHistoryEntry?.project?.name ?? "",
                                currentHolder: orderProjectHistoryEntry.holderId,
                                updated: parseFullDate(orderProjectHistoryEntry.updated),
                            };
                        }) ?? [];
                setProject(data[0].internalProject);
                setHolder(data[0].currentHolder);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
            });

        ApiService.getAllProjects()
            .then((response) => {
                setProjects(response ?? []);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
            });
    }, []);

    useEffect(() => {
        if (projects != null && project != undefined) setLoading(false);
    }, [projects, project]);

    useImperativeHandle(ref, () => ({ sendChanges }));

    const sendChanges = () => {
        setLoading(true);
        const currentProject = projects?.find((element) => element.name === project) ?? null;

        if (currentProject == null || holder == "") {
            setShowSnackbar(true);
            setMessage("You have to set a Holder!");
            setSeverity("danger");

            setShowSnackbar(true);
            setLoading(false);
            return;
        }

        const orderProjectHistory = new OrderProjectHistory({
            id: 0,
            orderId: orderId,
            project: currentProject,
            holderId: holder ?? "",
            updated: "",
        });

        ApiService.editProjectAndHolder(orderProjectHistory)
            .then((response) => {
                if (response.startsWith("success")) {
                    setSeverity("success");
                    setMessage("Successfully edited project and holder!");
                    setShowSnackbar(true);
                    setTimeout(() => {
                        setLoading(false);
                        window.location.reload();
                    }, 3000);
                } else {
                    setMessage("An error occurred editing project and holder!");
                    setSeverity("danger");

                    setShowSnackbar(true);
                    setLoading(false);
                }
            })
            .catch((error) => {
                setLoading(false);
                handleError(error, changeErrorMessage, changeShowError);
            });
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <GenericHeadline
                title={"General Information"}
                titleHeadingType="h4"
                actionButtons={[]}
            />
            {projects && (
                <GroupuiSelect
                    value={project}
                    onGroupuiChange={(event) => setProject(event.target.value)}
                    maxHeight="20rem"
                >
                    {projects?.map((element, index) => {
                        return (
                            <GroupuiSelectOption
                                value={element.name}
                                key={`select-option-${index}`}
                            >
                                {element.name}
                            </GroupuiSelectOption>
                        );
                    })}
                    <span slot="label">Internal Project*</span>
                </GroupuiSelect>
            )}

            <GenericInput
                value={holder}
                label="Current Holder*"
                onGroupuiChange={(event: any) => setHolder(event.target.value)}
            />
            <GroupuiLoadingSpinner displayed={loading} />
            {showSnackbar && (
                <Snackbar
                    severity={severity}
                    message={message}
                    onClose={() => setShowSnackbar(false)}
                />
            )}
        </div>
    );
}

export default forwardRef<RefType, Props>(EditProjectHolderPage);
